import { CoreManagement } from "@springtree/eva-services-core-management";

import { intlAccessor } from "~/util/intl-accessor";
import { mutate } from "~/util/mutate";

export const mutateUpdatePointAwardingRule = () =>
  mutate({
    service: CoreManagement.UpdatePointAwardingRule,
    messages: {
      loadingMessage: intlAccessor.formatMessage({
        id: "generic.label.save-rule.loading",
        defaultMessage: "Saving rule...",
      }),
      successMessage: intlAccessor.formatMessage({
        id: "generic.label.save-rule.success",
        defaultMessage: "Rule saved successfully.",
      }),
    },
  });
