import { useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

import { isEqual } from "lodash";
import { useRecoilState, useSetRecoilState } from "recoil";

import ProductSelector from "./product-selector/product-selector";
import ProductSetIncludedProducts from "./product-set-included-products";
import { ProductSetAvailableProductsAtom, ProductSetIncludedProductsAtom } from "./state";
import {
  BARCODE_INPUT_SIZE,
  IProductSetFilter,
  IProductSetProduct,
  LIST_ITEM_SIZE,
  PRODUCT_SET_MODAL_HEIGHT,
} from "./types";

import ErrorBoundary from "~/components/suite-ui/error-boundary";
import SplitDialog from "~/components/suite-ui/split-dialog";
import { toast } from "~/components/suite-ui/toast";
import usePrevious from "~/hooks/suite-react-hooks/use-previous";

export interface IProductSetModal {
  /**
   * Products included in the product set
   * */
  includedProducts: IProductSetProduct[];
  /**
   * Callback to update the included products
   */
  setIncludedProducts: (value: IProductSetProduct[]) => void;
  /**
   * Properties by which the products can be filtered
   */
  productProperties: string[];
  /**
   * Product filters
   */
  filters: IProductSetFilter[];
  /**
   * Callback to update the filters
   */
  setFilters: (value: IProductSetFilter[]) => void;
  /**
   * Products that can be added to the product set
   */
  availableProducts: IProductSetProduct[];
  /**
   * Indicates if the available products are being loaded
   */
  availableProductsLoading?: boolean;
  /**
   * Callback fired when closing the modal
   */
  closeModal: () => void;
  /*
   * Placeholder image to be shown when the product does not have an image. By default a gray box is shown.
   */
  placeholderImageUrl?: string;
  /**
   * Loading state for the save callback
   */
  isLoading?: boolean;
  /**
   * Whether or not to disable the Add Products button in left pane.
   * Can be used for example when a limit of products is needed.
   */
  disabledAddProductsButton?: boolean;
  subHeaderMessage?: string;
  name?: string;
  form?: string;
  value?: any;
  querySearchValue?: string;
  setQuerySearchValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  showQuantityInput?: boolean;
  hideSelectionColumn?: boolean;
  showBarcodeInput?: boolean;
  toastErrorOnExceededLimit?: boolean;
  allowedLimitOfProducts?: number;
  enableProductsSelectionQueryFilter?: boolean;
  enableClearAllProductsButton?: boolean;
}

/**
 * Component that can be used to manage a product set by searching for products and adding / removing them from the set.
 */
const ProductSetModal = ({
  allowedLimitOfProducts,
  availableProducts,
  availableProductsLoading,
  closeModal,
  disabledAddProductsButton,
  enableClearAllProductsButton = false,
  enableProductsSelectionQueryFilter,
  filters,
  form,
  hideSelectionColumn = false,
  includedProducts,
  isLoading,
  name,
  placeholderImageUrl,
  productProperties,
  querySearchValue,
  setFilters,
  setIncludedProducts,
  setQuerySearchValue,
  showBarcodeInput = false,
  showQuantityInput = false,
  subHeaderMessage,
  toastErrorOnExceededLimit = false,
  value,
}: IProductSetModal) => {
  const intl = useIntl();

  const [localIncludedProducts, setLocalIncludedProducts] = useRecoilState(
    ProductSetIncludedProductsAtom,
  );
  const setLocalAvailableProducts = useSetRecoilState(ProductSetAvailableProductsAtom);
  const previousIncludedProducts = usePrevious(includedProducts);

  const includedProductsItemSize = useMemo(() => {
    let finalListItemSize = LIST_ITEM_SIZE;

    if (showBarcodeInput) {
      finalListItemSize += BARCODE_INPUT_SIZE;
    }

    return finalListItemSize;
  }, [showBarcodeInput]);

  useEffect(() => {
    if (!isEqual(includedProducts, previousIncludedProducts))
      setLocalIncludedProducts(includedProducts);
  }, [includedProducts, previousIncludedProducts, setLocalIncludedProducts]);
  useEffect(() => {
    setLocalAvailableProducts(availableProducts);
  }, [availableProducts, setLocalAvailableProducts]);

  useEffect(() => {
    if (toastErrorOnExceededLimit) {
      toast.error(
        intl.formatMessage(
          {
            id: "generic.label.limit-selection-to-a-limit-of-products",
            defaultMessage: "Please limit your selection to {limit} products.",
          },
          {
            limit: allowedLimitOfProducts,
          },
        ),
      );
    }
  }, [allowedLimitOfProducts, intl, toastErrorOnExceededLimit]);

  return (
    <SplitDialog
      classes={{ paper: "max-w-[1600px]" }}
      open
      onClose={closeModal}
      leftPaneContent={
        <ErrorBoundary>
          <div style={{ height: `${PRODUCT_SET_MODAL_HEIGHT}px` }}>
            <ProductSelector
              productProperties={productProperties}
              filters={filters}
              setFilters={setFilters}
              availableProductsLoading={availableProductsLoading}
              placeholderImageUrl={placeholderImageUrl}
              disabledAddProductsButton={disabledAddProductsButton}
              subHeaderMessage={subHeaderMessage}
              setQuerySearchValue={setQuerySearchValue}
              querySearchValue={querySearchValue}
              showQuantityInput={showQuantityInput}
              hideSelectionColumn={hideSelectionColumn}
            />
          </div>
        </ErrorBoundary>
      }
      rightPaneContent={
        <ErrorBoundary>
          <div style={{ height: `${PRODUCT_SET_MODAL_HEIGHT}px` }}>
            <ProductSetIncludedProducts
              onSave={() => {
                setIncludedProducts(localIncludedProducts);
              }}
              onCancel={closeModal}
              placeholderImageUrl={placeholderImageUrl}
              isLoading={isLoading}
              listItemSize={includedProductsItemSize}
              name={name}
              form={form}
              value={value}
              showQuantity={showQuantityInput}
              showBarcodeInput={showBarcodeInput}
              enableQueryFilter={enableProductsSelectionQueryFilter}
              enableClearAllProductsButton={enableClearAllProductsButton}
            />
          </div>
        </ErrorBoundary>
      }
    />
  );
};
export default ProductSetModal;
