import { Core } from "@springtree/eva-services-core";

import { intlAccessor } from "~/util/intl-accessor";
import { mutate } from "~/util/mutate";

export const mutateCompleteMultipleShipFromStoreTasks = () =>
  mutate({
    service: Core.CompleteMultipleShipFromStoreTasks,
    messages: {
      loadingMessage: intlAccessor.formatMessage({
        id: "complete-tasks.loading",
        defaultMessage: "Completing task(s)...",
      }),
      successMessage: intlAccessor.formatMessage({
        id: "complete-tasks.success",
        defaultMessage: "Successfully completed tasks.",
      }),
    },
  });
