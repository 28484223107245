import { useCallback } from "react";

import { IBoundingRectangle } from "~/hooks/suite-react-hooks/use-bounding-rectangle";

const INITIAL_BOUNDING_RECTANGLE: IBoundingRectangle = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: 0,
  height: 0,
};

export const useBoundingRectangleV2 = <T extends HTMLElement>(domElement: T | null) => {
  const getBoundingRectangle = useCallback(() => {
    if (!domElement) {
      return INITIAL_BOUNDING_RECTANGLE;
    }

    const elementBoundingBox = domElement.getBoundingClientRect();

    return {
      top: elementBoundingBox.top,
      right: elementBoundingBox.right,
      left: elementBoundingBox.left,
      bottom: elementBoundingBox.bottom,
      width: elementBoundingBox.width,
      height: elementBoundingBox.height,
    };
  }, [domElement]);

  const boundingRectangle = getBoundingRectangle();

  return boundingRectangle;
};
