import { useState } from "react";
import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

import SubsetsModal, { SubsetsLyraModal } from "./subsets-modal";

import IconButton from "~/components/suite-ui/icon-button";

export interface IViewSubsetsButton {
  selectedOuSetId?: number;
}

const ViewSubsetsButton = ({ selectedOuSetId }: IViewSubsetsButton) => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);

  return selectedOuSetId ? (
    <>
      <IconButton
        size="small"
        onClick={() => setShowModal(true)}
        label={intl.formatMessage({
          id: "ouset-selector.multi.subsets.tooltip",
          defaultMessage: "Subsets",
        })}
      >
        <SvgIcon name="preview" />
      </IconButton>

      <SubsetsModal
        ouSetId={selectedOuSetId}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  ) : null;
};

export const ViewSubsetsLyraButton = ({ selectedOuSetId }: IViewSubsetsButton) => {
  const intl = useIntl();
  const [showModal, setShowModal] = useState(false);

  return selectedOuSetId ? (
    <>
      <Button
        variant="icon"
        onPress={() => setShowModal(true)}
        tooltip={intl.formatMessage({
          id: "ouset-selector.multi.subsets.tooltip",
          defaultMessage: "Subsets",
        })}
      >
        <SvgIcon name="preview" />
      </Button>

      <SubsetsLyraModal
        ouSetId={selectedOuSetId}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      />
    </>
  ) : null;
};

export default ViewSubsetsButton;
