import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getLedgerClassesLoaderQuery,
  serviceQuery: getLedgerClassesQuery,
  serviceQueryKeys: getLedgerClassesQueryKeys,
  useServiceQueryHook: useGetLedgerClassesQuery,
} = createServiceQuery(CoreManagement.GetLedgerClasses);
