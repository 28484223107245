import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Checkbox, FieldGroup, TextField } from "@new-black/lyra";
import { isEmpty } from "lodash";

import RequiredAmountOrQuantityFields from "./fields/required-amount-or-quantity-fields";
import {
  loyaltyProgramRuleProductSetConditionKeys,
  TLoyaltyProgramRuleProductSetCondition,
  TLoyaltyProgramRuleProductSetConditionErrors,
} from ".";

import {
  generateProductSearchTemplateLyraSearchListField,
  TSearchProductTemplateAutocompleteItem,
} from "~/components/suite-composite/generate-product-search-template-autocomplete";
import { TProductSet } from "~/types/product-sets";
import { removeUndefinedValues } from "~/util/helper";
import { mapEVAFilterModelToProductSetFilters } from "~/util/product-sets";

const ProductSearchTemplateSearchListField =
  generateProductSearchTemplateLyraSearchListField().SingleSearchListField;

export const AddEditLoyaltyProgramRuleProductSetConditionFields = ({
  errors,
  initialValues,
}: {
  errors?: TLoyaltyProgramRuleProductSetConditionErrors;
  initialValues?: TLoyaltyProgramRuleProductSetCondition;
}) => {
  const intl = useIntl();

  const hasInitialValues = useMemo(
    () => !isEmpty(removeUndefinedValues(initialValues)),
    [initialValues],
  );

  const [searchProductTemplate, setSearchProductTemplate] = useState<
    TSearchProductTemplateAutocompleteItem | undefined
  >();

  const mappedSearchProductTemplate = useMemo<TProductSet[] | undefined>(
    () =>
      hasInitialValues && initialValues?.ProductsSets?.[0]?.Filters
        ? [
            {
              Filters: initialValues?.ProductsSets?.[0]?.Filters,
            },
          ]
        : searchProductTemplate
        ? [
            {
              Filters: mapEVAFilterModelToProductSetFilters(searchProductTemplate?.Filters),
            },
          ]
        : undefined,
    [hasInitialValues, initialValues?.ProductsSets, searchProductTemplate],
  );

  return (
    <FieldGroup>
      <TextField
        isRequired
        name={loyaltyProgramRuleProductSetConditionKeys.Name}
        label={intl.formatMessage({
          id: "generic.label.name-of-product-set",
          defaultMessage: "Name of product set",
        })}
        errorMessage={errors?.Name}
        defaultValue={initialValues?.Name}
      />

      <RequiredAmountOrQuantityFields initialValues={initialValues} errors={errors} />

      {hasInitialValues ? null : (
        <ProductSearchTemplateSearchListField.Controlled
          isRequired
          value={searchProductTemplate}
          onChange={setSearchProductTemplate}
          errorMessage={errors?.ProductsSets}
          label={intl.formatMessage({
            id: "generic.label.initial-product-search-template",
            defaultMessage: "Initial product search template",
          })}
        />
      )}

      <Checkbox
        name={loyaltyProgramRuleProductSetConditionKeys.IdenticalProductsOnly}
        defaultSelected={initialValues?.IdenticalProductsOnly}
      >
        <FormattedMessage
          id="generic.label.identical-products-only"
          defaultMessage="Identical products only"
        />
      </Checkbox>

      {mappedSearchProductTemplate ? (
        <input
          type="hidden"
          name={loyaltyProgramRuleProductSetConditionKeys.ProductsSets}
          value={JSON.stringify(mappedSearchProductTemplate)}
        />
      ) : null}
    </FieldGroup>
  );
};
