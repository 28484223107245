import { FormattedMessage } from "react-intl";
import { CellProps, Column } from "react-table";

import { TableColumnDef } from "@new-black/lyra";
import { omit } from "lodash";

import { getTableColumnInputEditComponent } from "./table-column-helpers";
import { ColumnProps, DEFAULT_EXPANDABLE_TEXT_MAX_LENGTH } from "./table-columns.types";

import ExpandableText, { IExpandableTextProps } from "~/components/suite-ui/expandable-text";
import { intlAccessor } from "~/util/intl-accessor";

export const idColumn = <T extends { ID?: number | string }>(
  columnProps?: Partial<Column<T>>,
): Column<T> => ({
  accessor: "ID",
  Header: <FormattedMessage id="generic.label.id" defaultMessage="ID" />,
  ...omit(columnProps, "columns"),
});

export const idLyraColumn = <T extends { ID?: number | string } = { ID?: number | string }>(
  columnProps?: Partial<TableColumnDef<T, "ID">>,
) => ({
  id: "ID",
  accessorKey: "ID" as keyof T,
  header: intlAccessor.formatMessage({ id: "generic.label.id", defaultMessage: "ID" }),
  ...columnProps,
});

export const nameColumn = <T extends { Name?: string }>(
  columnProps?: ColumnProps<T>,
): Column<T> => ({
  accessor: "Name",
  Header: <FormattedMessage id="generic.label.name" defaultMessage="Name" />,
  editComponent: getTableColumnInputEditComponent({
    editable: columnProps?.editable,
    editInputProps: columnProps?.editInputProps,
  }),
  ...omit(columnProps, "columns"),
});

export const nameLyraColumn = <T extends { Name?: string } = { Name?: string }>(
  columnProps?: Partial<TableColumnDef<T, "Name">>,
) => ({
  id: "Name",
  accessorKey: "Name" as keyof T,
  header: intlAccessor.formatMessage({ id: "generic.label.name", defaultMessage: "Name" }),
  ...columnProps,
});

export const codeColumn = <T extends { Code?: string }>(
  columnProps?: ColumnProps<T>,
): Column<T> => ({
  accessor: "Code",
  Header: <FormattedMessage id="generic.label.code" defaultMessage="Code" />,
  editComponent: getTableColumnInputEditComponent({
    editable: columnProps?.editable,
    editInputProps: columnProps?.editInputProps,
  }),
  ...omit(columnProps, "columns"),
});

export const codeLyraColumn = <T extends { Code?: string } = { Code?: string }>(
  columnProps?: Partial<TableColumnDef<T, "Code">>,
) => ({
  id: "Code",
  accessorKey: "Code" as keyof T,
  header: intlAccessor.formatMessage({ id: "generic.label.code", defaultMessage: "Code" }),
  ...columnProps,
});

export const displayNameColumn = <T extends { DisplayName?: string }>(
  columnProps?: ColumnProps<T>,
): Column<T> => ({
  accessor: "DisplayName",
  Header: <FormattedMessage id="generic.label.display-name" defaultMessage="Display Name" />,
  editComponent: getTableColumnInputEditComponent({
    editable: columnProps?.editable,
    editInputProps: columnProps?.editInputProps,
  }),
  ...omit(columnProps, "columns"),
});

export const genericNameColumn = <T extends object, K extends keyof T>(
  accessor: K,
  columnProps?: ColumnProps<T>,
): Column<T> => ({
  accessor: accessor,
  Header: <FormattedMessage id="generic.label.name" defaultMessage="Name" />,
  editComponent: getTableColumnInputEditComponent({
    editable: columnProps?.editable,
    editInputProps: columnProps?.editInputProps,
  }),
  ...omit(columnProps, "columns"),
});

export const backendIDColumn = <T extends { BackendID?: string }>(
  columnProps?: ColumnProps<T>,
): Column<T> => ({
  accessor: "BackendID",
  Header: <FormattedMessage id="generic.label.backend-id" defaultMessage="Backend ID" />,
  editComponent: getTableColumnInputEditComponent({
    editable: columnProps?.editable,
    editInputProps: columnProps?.editInputProps,
  }),
  ...omit(columnProps, "columns"),
});

export const emailAddressColumn = <T extends { EmailAddress?: string }>(
  columnProps?: ColumnProps<T>,
): Column<T> => ({
  accessor: "EmailAddress",
  Header: <FormattedMessage id="generic.label.email-address" defaultMessage="Email address" />,
  editComponent: getTableColumnInputEditComponent({
    editable: columnProps?.editable,
    editInputProps: columnProps?.editInputProps,
  }),
  ...omit(columnProps, "columns"),
});

export const phoneNumberColumn = <T extends { PhoneNumber?: string }>(
  columnProps?: ColumnProps<T>,
): Column<T> => ({
  accessor: "PhoneNumber",
  Header: <FormattedMessage id="generic.label.phone-number" defaultMessage="Phone number" />,
  editComponent: getTableColumnInputEditComponent({
    editable: columnProps?.editable,
    editInputProps: columnProps?.editInputProps,
  }),
  ...omit(columnProps, "columns"),
});

export const descriptionColumn = <T extends { Description?: string }>(
  columnProps?: Partial<Column<T>> & { maxLength?: number },
  options?: {
    expandableTextVariant?: IExpandableTextProps["variant"];
    expandableTextLinkButtonVariant?: IExpandableTextProps["linkButtonVariant"];
  },
): Column<T> => ({
  accessor: "Description",
  Header: <FormattedMessage id="generic.label.description" defaultMessage="Description" />,
  Cell: ({ value }: CellProps<T>) => (
    <ExpandableText
      text={value ?? ""}
      variant={options?.expandableTextVariant ?? "inherit"}
      linkButtonVariant={options?.expandableTextLinkButtonVariant}
      maxLength={columnProps?.maxLength ?? DEFAULT_EXPANDABLE_TEXT_MAX_LENGTH}
      spaceBeforeLessAction
      spaceBeforeMoreAction
    />
  ),
  ...omit(columnProps, "columns"),
});

export const genericDateColumn = <T extends object, K extends keyof T>(
  accessor: K,
  columnProps?: ColumnProps<T>,
): Column<T> => ({
  accessor: accessor ?? "Date",
  Header: <FormattedMessage id="generic.label.date" defaultMessage="Date" />,
  editComponent: getTableColumnInputEditComponent({
    editable: columnProps?.editable,
    editInputProps: columnProps?.editInputProps,
  }),
  ...omit(columnProps, "columns"),
});
