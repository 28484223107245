import { useMemo, useState } from "react";

import { Separator } from "@new-black/lyra";
import classNames from "clsx";

import DeleteActionButton from "../action-buttons/delete-action-button";

import { CustomFieldConsumer } from "~/components/suite-composite/custom-field-consumer";
import { MaterialAutocomplete } from "~/components/suite-ui/autocomplete";
import { CustomFieldOptionsChips } from "~/features/orders/detail/side-pane/custom-fields-card/custom-field-options-chips";
import { CustomFieldPrimitiveType } from "~/types/custom-field";
import { CustomFieldDataType } from "~/types/custom-field-data-types";
import {
  getCustomFieldValueFromEVACustomFieldValue,
  mapCustomFieldPrimitiveValueToEVACustomFieldValue,
} from "~/util/custom-fields";

interface ICustomFieldRow {
  customFieldWithValueAndOptions?: EVA.Core.CustomFieldResponse;
  onChange?: (newValue: any, customFieldID?: number) => void;
  onDelete?: () => void;
  filteredOutItems?: number[];
  allCustomFields?: EVA.Core.CustomFieldResponse[];
  errors?: { customFieldAutocomplete?: string; customFieldConsumer?: string };
  autocompleteLabel?: string;
  passiveAutocomplete?: boolean;
}

export const CustomFieldEditRow = ({
  allCustomFields,
  autocompleteLabel,
  customFieldWithValueAndOptions,
  errors,
  filteredOutItems,
  onChange,
  onDelete,
  passiveAutocomplete,
}: ICustomFieldRow) => {
  const [selectedValue, setSelectedValue] = useState<CustomFieldPrimitiveType>(
    customFieldWithValueAndOptions?.Value,
  );

  const [selectedCF, setSelectedCF] = useState<{ CustomFieldID: number; Name: string } | undefined>(
    customFieldWithValueAndOptions?.CustomFieldID
      ? {
          CustomFieldID: customFieldWithValueAndOptions?.CustomFieldID,
          Name: customFieldWithValueAndOptions?.Name,
        }
      : undefined,
  );

  const items = useMemo(
    () =>
      (allCustomFields ?? []).filter((customField) =>
        filteredOutItems?.length ? !filteredOutItems.includes(customField.CustomFieldID) : true,
      ),
    [allCustomFields, filteredOutItems],
  );

  return (
    <div className="mb-5 flex w-full items-center rounded-md border border-solid border-default bg-surface-tertiary">
      <div
        className={classNames(
          "grid w-full grid-cols-3 gap-5 p-3",
          customFieldWithValueAndOptions?.DataTypeID !== (CustomFieldDataType.Bool as number) &&
            !customFieldWithValueAndOptions?.IsArray &&
            "items-end",
          customFieldWithValueAndOptions?.IsArray && "items-start",
        )}
      >
        <MaterialAutocomplete
          IDKey="CustomFieldID"
          LabelKey="Name"
          value={selectedCF}
          items={items}
          passive={passiveAutocomplete}
          label={autocompleteLabel}
          setValue={(newValue) => {
            setSelectedCF(
              newValue?.CustomFieldID && newValue.Name
                ? {
                    CustomFieldID: newValue.CustomFieldID,
                    Name: newValue.Name,
                  }
                : undefined,
            );

            const customFieldDefaultValue = allCustomFields?.find(
              (customField) => customField.CustomFieldID === newValue?.CustomFieldID,
            )?.Options?.DefaultCustomFieldValue;

            onChange?.(
              newValue?.CustomFieldID ? customFieldDefaultValue : undefined,
              newValue?.CustomFieldID,
            );

            setSelectedValue(
              customFieldDefaultValue
                ? getCustomFieldValueFromEVACustomFieldValue(customFieldDefaultValue)
                : undefined,
            );
          }}
          error={!!errors?.customFieldAutocomplete}
          helperText={errors?.customFieldAutocomplete}
        />

        <div
          className={classNames(
            "grid align-middle",
            customFieldWithValueAndOptions?.CustomFieldID ? "items-center" : "items-end",
          )}
        >
          {customFieldWithValueAndOptions && selectedCF ? (
            <CustomFieldConsumer
              customField={{
                ...customFieldWithValueAndOptions,
                TypeID: customFieldWithValueAndOptions.DataTypeID ?? 0,
                ID: customFieldWithValueAndOptions.CustomFieldID,
                DataType: customFieldWithValueAndOptions.DataTypeID,
              }}
              value={selectedValue}
              onChange={(newValue, type) => {
                setSelectedValue(newValue);
                onChange?.(
                  mapCustomFieldPrimitiveValueToEVACustomFieldValue(
                    type,
                    newValue,
                    customFieldWithValueAndOptions.Options?.IsRequired,
                  ),
                  selectedCF?.CustomFieldID ?? customFieldWithValueAndOptions.CustomFieldID,
                );
              }}
              error={errors?.customFieldConsumer}
            />
          ) : null}
        </div>

        <div
          className={classNames(
            "flex h-full",
            customFieldWithValueAndOptions?.IsArray ? "items-start" : "items-center",
          )}
        >
          <CustomFieldOptionsChips
            options={customFieldWithValueAndOptions?.Options}
            dataType={customFieldWithValueAndOptions?.DataTypeID?.valueOf() ?? 0}
          />
        </div>
      </div>

      <Separator orientation="vertical" className="h-auto self-stretch" />

      <DeleteActionButton onPress={onDelete} className="mx-4" />
    </div>
  );
};
