import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const { serviceQuery: addressAutocompleteQuery } = createServiceQuery(
  Core.AutocompleteAddress,
  true,
);

export const { serviceQuery: getAutocompleteAddressByReferenceQuery } = createServiceQuery(
  Core.GetAutocompleteAddressByReference,
  true,
);

export const {
  serviceLoaderQuery: getListAddressBookLoaderQuery,
  serviceQuery: getListAddressBookQuery,
  serviceQueryKeys: listAddressBookQueryKeys,
  useServiceQueryHook: useListAddressBookQuery,
} = createServiceQuery(Core.ListAddressBook, true);
