import { useState } from "react";
import toast from "react-hot-toast";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  ButtonGroup,
  CardActions,
  CardContent,
  CardHeader,
  Dialog,
  DialogCard,
  DialogProps,
  SvgIcon,
} from "@new-black/lyra";
import classNames from "clsx";

import Image from "~/components/suite-ui/image";
import useEventListener from "~/hooks/suite-react-hooks/use-event-listener";
import { SafeClipboard } from "~/util/clipboard";

export interface IImageGalleryItem {
  src: string;
  alt?: string;
  name: string;
}

export type ImageGalleryDialogProps = Omit<DialogProps, "children" | "maxWidth" | "placement"> & {
  items: IImageGalleryItem[];
  initialSelectedIndex?: number;
};

const ImageGalleryDialog = ({
  initialSelectedIndex = 0,
  items,
  ...dialogProps
}: ImageGalleryDialogProps) => {
  const intl = useIntl();
  const [selectedIndex, setSelectedIndex] = useState(Math.min(initialSelectedIndex, items.length));

  useEventListener("keydown", (e: KeyboardEvent) => {
    if (e.key === "ArrowLeft") {
      setSelectedIndex((current) => (current - 1 + items.length) % items.length);
    } else if (e.key === "ArrowRight") {
      setSelectedIndex((current) => (current + 1) % items.length);
    }
  });

  return (
    <Dialog maxWidth="fullScreen" {...dialogProps}>
      {({ close }) => (
        <DialogCard>
          <div className="flex h-[98dvh] flex-col">
            <CardHeader
              title={items[selectedIndex].name}
              actions={
                <CardActions>
                  <Button
                    variant="icon"
                    onPress={() => {
                      SafeClipboard.writeText(items[selectedIndex].src).then((value) => {
                        if (value === items[selectedIndex].src) {
                          toast.success(
                            intl.formatMessage({
                              id: "generic.label.image-url-copied-to-clipboard",
                              defaultMessage: "Image URL copied to clipboard",
                            }),
                          );
                        }
                      });
                    }}
                    tooltip={intl.formatMessage({
                      id: "generic.label.copy-url",
                      defaultMessage: "Copy URL",
                    })}
                  >
                    <SvgIcon name="clipboard" />
                  </Button>

                  <Button
                    variant="icon"
                    tooltip={intl.formatMessage({
                      id: "generic.label.open-in-new-tab",
                      defaultMessage: "Open in new tab",
                    })}
                    onPress={() => {
                      window.open(items[selectedIndex].src, "_blank");
                    }}
                  >
                    <SvgIcon name="open-in-new-tab" />
                  </Button>
                </CardActions>
              }
            />
            <CardContent className="flex flex-1 items-center justify-between gap-4 overflow-hidden">
              <Button
                variant="icon"
                onPress={() => {
                  setSelectedIndex((current) => (current - 1 + items.length) % items.length);
                }}
                isDisabled={items.length === 1}
              >
                <SvgIcon name="chevron-left" />
              </Button>
              <Image
                className="h-full max-h-[640px] w-full max-w-[640px] object-contain"
                src={items[selectedIndex].src}
                alt={items[selectedIndex].alt ?? items[selectedIndex].name}
                key={selectedIndex}
                enlarge={false}
              />
              <Button
                variant="icon"
                onPress={() => {
                  setSelectedIndex((current) => (current + 1) % items.length);
                }}
                isDisabled={items.length === 1}
              >
                <SvgIcon name="chevron-right" />
              </Button>
            </CardContent>
            <div className="flex flex-col items-center gap-8 overflow-hidden rounded-b-lg border-t border-default p-4 sm:grid sm:grid-cols-[1fr_auto_1fr]">
              <div className="hidden shrink-0 self-stretch sm:block" />
              <ButtonGroup className="-m-1 overflow-auto p-1">
                {items.map((item, index) => (
                  <Button
                    variant="secondary"
                    key={index}
                    onPress={() => setSelectedIndex(index)}
                    className="h-20 w-20 shrink-0 p-0"
                    onKeyDown={(e) => {
                      if (e.key === "ArrowLeft" || e.key === "ArrowRight") {
                        e.preventDefault();
                      }
                    }}
                  >
                    <Image
                      className={classNames("h-20 w-20 rounded-sm object-contain", {
                        "border border-focus shadow-highlight ": index === selectedIndex,
                      })}
                      src={item.src}
                      alt={item.alt ?? item.name}
                      enlarge={false}
                    />
                  </Button>
                ))}
              </ButtonGroup>
              <Button
                className="w-full self-stretch sm:ml-auto sm:w-[unset] sm:self-end"
                onPress={close}
              >
                <FormattedMessage id="generic.label.close" defaultMessage="Close" />
              </Button>
            </div>
          </div>
        </DialogCard>
      )}
    </Dialog>
  );
};

export default ImageGalleryDialog;
