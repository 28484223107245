import { useMemo } from "react";

import {
  ProductSetModal,
  useProductSetModal,
} from "~/components/suite-composite/product-set-modal";
import { IProductSetProduct } from "~/components/suite-composite/product-set-modal/types";

export const EvaProductSetProductIDsModal = ({
  includedProducts,
  onClose,
  productIDs,
  setProductIDs,
}: {
  onClose: () => void;
  productIDs: number[];
  setProductIDs: (newValue: number[]) => void;
  includedProducts: IProductSetProduct[];
}) => {
  const {
    availableProducts,
    filters,
    isLoading,
    querySearchValue,
    searchProductPropertyTypes,
    setFilters,
    setQuerySearchValue,
  } = useProductSetModal();

  const filteredProducts = useMemo(() => {
    if (productIDs.length) {
      return availableProducts.filter((product) => !productIDs?.includes(product.ID));
    }

    return availableProducts;
  }, [availableProducts, productIDs]);

  return (
    <ProductSetModal
      availableProducts={filteredProducts}
      productProperties={searchProductPropertyTypes?.map((item) => item.type_id) ?? []}
      filters={filters}
      setFilters={setFilters}
      includedProducts={includedProducts}
      setIncludedProducts={(newProducts) => {
        setProductIDs?.(newProducts.map((newProduct) => newProduct.ID));
        onClose();
      }}
      closeModal={onClose}
      availableProductsLoading={isLoading}
      setQuerySearchValue={setQuerySearchValue}
      querySearchValue={querySearchValue}
      enableProductsSelectionQueryFilter
    />
  );
};
