import { useMemo, useState } from "react";

import { RecoilState, useRecoilState } from "recoil";

import { OrderDetailSidePaneCardsEnum } from "~/features/orders/detail/order-detail.types";

export type OrderableCard<T> = {
  label: string;
  value: T;
};

type UseOrganizeWorkspaceItemsProps<VisibleCards, CardType> = {
  visibleItemsAtom: RecoilState<VisibleCards>;
  mappedItems: OrderableCard<CardType>[];
  hasCasesViewRights?: boolean;
};

/**
 * Use this hook to organize visible items (columns/cards) with workspaces
 */
const useOrganizeWorkspaceItems = <T = unknown>({
  hasCasesViewRights,
  mappedItems,
  visibleItemsAtom,
}: UseOrganizeWorkspaceItemsProps<T[], T>) => {
  const [isOrganizeItemsActive, setIsOrganizeItemsActive] = useState(false);
  const [visibleItems, setVisibleItems] = useRecoilState(visibleItemsAtom);

  const orderedItems = useMemo(
    () =>
      visibleItems
        .filter((entry) => entry !== OrderDetailSidePaneCardsEnum.Cases || hasCasesViewRights)
        .map((visibleFilter) => ({
          label:
            mappedItems.find((mappedFilter) => mappedFilter.value === visibleFilter)?.label ?? "",
          value: visibleFilter,
        })),
    [hasCasesViewRights, mappedItems, visibleItems],
  );

  // these should also be in the same order as the ordered filters
  const allItems = useMemo(
    () =>
      orderedItems
        .map(
          (orderedFilter) =>
            mappedItems.find((mappedFilter) => mappedFilter.value === orderedFilter.value)!,
        )
        .concat(
          mappedItems.filter(
            (filter) =>
              orderedItems.findIndex((orderedFilter) => orderedFilter.value === filter.value) ===
              -1,
          ),
        ),
    [mappedItems, orderedItems],
  );

  return {
    allItems,
    isOrganizeItemsActive,
    setIsOrganizeItemsActive,
    setVisibleItems,
    visibleItems: orderedItems,
  };
};

export default useOrganizeWorkspaceItems;
