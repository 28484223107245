import { ChangeEvent } from "react";

import { useField } from "formik";

import AmountInput, { IAmountInput } from "~/components/suite-ui/amount-input";

interface FormikAmountInputProps extends Exclude<IAmountInput, "value" | "onChange"> {
  // had to use Exclude instead of Omit, because some fields were lost using Omit
  // more info: https://github.com/microsoft/TypeScript/issues/30825
  name: string;
}

export const FormikAmountInput = ({
  helperText,
  name,
  onFieldBlur,
  ...props
}: FormikAmountInputProps) => {
  const [field, meta, { setValue }] = useField<number | undefined>(name);

  return (
    <AmountInput
      {...props}
      {...field}
      name={name}
      value={field?.value ?? ""}
      onChange={(e: ChangeEvent<HTMLInputElement>) => {
        setValue(e.target.value ? parseFloat(`${e.target.value}`) : undefined);
      }}
      error={!!meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : helperText}
      onBlur={() => onFieldBlur?.() ?? undefined}
    />
  );
};
