import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Separator,
} from "@new-black/lyra";
import { useQuery } from "@tanstack/react-query";

import {
  ISharedWorkspaceRole,
  SharedWorkspacePermissionsTable,
} from "./shared-workspace-permissions-table";
import { IWorkspaceView } from "./types";

import { generateRoleAutocomplete } from "~/components/shared/roles-input";
import { getSharedWorkspaceByIDQuery } from "~/models/workspaces";

interface IEditWorkspaceSharePermissionsState {
  roleIDs: number[];
  defaultRoleIDs: number[];
}

interface EditWorkspaceSharePermissionsModalProps
  extends IWorkspaceView<IEditWorkspaceSharePermissionsState> {
  workspaceID: number;
  labels?: { rolesInput?: string };
}

const { MultiAutocomplete: MultiRoleAutocomplete } = generateRoleAutocomplete();

const EditWorkspaceSharePermissionsModalContent = ({
  isLoading,
  labels,
  onCancel,
  onConfirm,
  open,
  title,
  workspaceID,
}: EditWorkspaceSharePermissionsModalProps) => {
  const intl = useIntl();

  const [roles, setRoles] = useState<ISharedWorkspaceRole[]>();

  const { isFetching: isWorkspaceLoading } = useQuery({
    ...getSharedWorkspaceByIDQuery({ ID: workspaceID }),
    refetchOnWindowFocus: false,
    enabled: !!open,
    onSettled: (response) => {
      if (response) {
        setRoles(
          response.RolesWithDefault.map((role) => ({
            ...role,
            Name: role.Name,
            isDefault: role.IsDefault,
          })),
        );
      }
    },
  });

  return (
    <Card>
      <div className="max-h-[calc(90dvh-130px)] overflow-auto">
        <CardHeader
          title={
            title ??
            intl.formatMessage({
              id: "generic.label.edit-share-permissions",
              defaultMessage: "Edit share permissions",
            })
          }
        />

        <CardContent>
          <MultiRoleAutocomplete.Controlled
            required
            values={roles}
            onChange={(newValues) => {
              setRoles(newValues);
            }}
            isLoading={isWorkspaceLoading}
            label={
              labels?.rolesInput ??
              intl.formatMessage({
                id: "generic.label.shared-with",
                defaultMessage: "Shared with",
              })
            }
            disablePortal
          />
        </CardContent>

        <SharedWorkspacePermissionsTable roles={roles} setRoles={setRoles} />
      </div>

      <Separator />

      <ButtonGroup className="flex justify-end p-4">
        <Button variant="secondary" onPress={onCancel} isDisabled={isLoading}>
          <FormattedMessage id="generic.label.cancel" defaultMessage="cancel" />
        </Button>

        <Button
          variant="primary"
          isDisabled={!roles?.length || isLoading}
          isLoading={isLoading}
          onPress={() =>
            roles?.length &&
            onConfirm({
              roleIDs: roles.map((role) => role.ID),
              defaultRoleIDs: roles.filter((role) => role.isDefault).map((role) => role.ID),
            })
          }
        >
          <FormattedMessage id="generic.label.save" defaultMessage="Save" />
        </Button>
      </ButtonGroup>
    </Card>
  );
};

const EditWorkspaceSharePermissionsModal = (props: EditWorkspaceSharePermissionsModalProps) => (
  <Dialog
    isOpen={props.open}
    onOpenChange={(open) => (open ? undefined : props.onCancel())}
    maxWidth="lg"
    aria-label="Edit workspace share permissions"
  >
    <EditWorkspaceSharePermissionsModalContent {...props} />
  </Dialog>
);

export default EditWorkspaceSharePermissionsModal;
