import { CoreManagement } from "@springtree/eva-services-core-management";

import { listInquiriesQuery } from "~/models/inquiries";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export interface IInquiryAutocompleteItem {
  ID: number;
  Name: string;
}

export const generateInquiryLyraSearchListField = (
  initialFilters?: Partial<EVA.Core.ListInquiriesFilter>,
) =>
  LyraSearchListFieldGenerator<
    CoreManagement.ListInquiries,
    {
      ID: number;
      Name: string;
      Description?: string;
    },
    number
  >({
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    selectRenderElements: (item) => ({
      label: item.Name,
    }),
    getItemFromResponse: (resp) =>
      resp?.Results?.map((inquiry) => ({
        ID: inquiry.ID as number,
        Name: inquiry.Name,
        Description: inquiry.Description,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.inquiry",
      defaultMessage: "Inquiry",
    }),
    useItemByID: (id, items) => ({ data: items?.find((item) => item.ID === id) }),
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listInquiriesQuery,
        getQueryRequest: (req) => req?.InitialPageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          InitialPageConfig: {
            ...req?.InitialPageConfig,
            Filter: {
              ...req?.InitialPageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
        initialRequest: { InitialPageConfig: { Limit: 10, Filter: initialFilters } },
      }),
  });
