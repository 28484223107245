import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

// ListEventLedger
export const {
  serviceLoaderQuery: listEventLedgerLoaderQuery,
  serviceQuery: listEventLedgerQuery,
  serviceQueryKeys: listEventLedgerQueryKeys,
  useServiceQueryHook: useListEventLedgerQuery,
} = createServiceQuery(Core.ListEventLedger);

export const { useServiceQueryHook: useGetEventLedgerTypesQuery } = createServiceQuery(
  Core.GetEventLedgerTypes,
);
