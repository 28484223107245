import { IntlShape } from "react-intl";

import { EntityFieldValidatorEntityTypes } from "~/types/eva-core";

export const getEntityFieldValidatorEntityTypesTranslator = (intl: IntlShape) => {
  const translationMap: Record<EVA.Core.EntityFieldValidatorEntityTypes, string> = {
    [EntityFieldValidatorEntityTypes.PriceList]: intl.formatMessage({
      id: "generic.label.price-list",
      defaultMessage: "Price list",
    }),
    [EntityFieldValidatorEntityTypes.PriceListAdjustment]: intl.formatMessage({
      id: "generic.label.price-list-adjustment",
      defaultMessage: "Price list adjustment",
    }),
  };

  return (status: EVA.Core.EntityFieldValidatorEntityTypes) => {
    return translationMap[status];
  };
};
