import { Core } from "@springtree/eva-services-core";
import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listScriptsLoaderQuery,
  serviceQuery: listScriptsQuery,
  serviceQueryKeys: listScriptsQueryKeys,
  serviceQueryWithOU,
  useServiceQueryHook: useListScriptsQuery,
} = createServiceQuery(Core.ListScripts, true);

export const {
  serviceLoaderQuery: getScriptLoaderQuery,
  serviceQuery: getScriptQuery,
  serviceQueryKeys: getScriptQueryKeys,
  useServiceQueryHook: useGetScriptQuery,
} = createServiceQuery(Core.GetScript, true);

// Probably should be a mutation
export const {
  serviceLoaderQuery: parseScriptLoaderQuery,
  serviceQuery: parseScriptQuery,
  serviceQueryKeys: parseScriptQueryKeys,
  useServiceQueryHook: useParseScriptQuery,
} = createServiceQuery(Core.ParseScript, true);

export const {
  serviceLoaderQuery: getScriptDialectsLoaderQuery,
  serviceQuery: getScriptDialectsQuery,
  serviceQueryKeys: getScriptDialectsQueryKeys,
  useServiceQueryHook: useGetScriptDialectsQuery,
} = createServiceQuery(Core.GetScriptDialects, false, true, true);

export const {
  serviceLoaderQuery: listScriptHistoryLoaderQuery,
  serviceQueryKeys: listScriptHistoryQueryKeys,
  useServiceQueryHook: useListScriptHistoryQuery,
  useServiceQueryWithRequest: useListScriptHistoryQueryWithRequest,
} = createServiceQuery(Core.ListScriptHistory, true);

export const { serviceQuery: listCustomFieldEligibilityScriptsQuery } = createServiceQuery(
  CoreManagement.ListCustomFieldEligibilityScripts,
  false,
);
