import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceQuery: listCouponsByQueryQuery,
  serviceQueryKeys: listCouponsByQueryQueryKeys,
  useServiceQueryWithRequest: useListCouponsByQueryQueryWithRequest,
} = createServiceQuery(CoreManagement.ListCouponsByQuery, true);

export const { useServiceQueryHook: useGetDiscountCouponByID } = createServiceQuery(
  CoreManagement.GetDiscountCouponByID,
  true,
);
