import { ReactNode, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  DialogTrigger,
  Separator,
  SvgIcon,
} from "@new-black/lyra";

import { SelectedProducts } from "./selected-products";

import { generateSearchProductsLyraSearchListField } from "~/components/suite-composite/generate-search-products-search-list-field";
import { ProductSearchModal } from "~/components/suite-composite/product-search-modal";
import useProductIDProperty from "~/hooks/suite-react-hooks/use-product-id-property";
import useGetAssetsUrl from "~/hooks/use-get-assets-url";
import { useSearchProductsQuery } from "~/models/products";
import { IProduct } from "~/types/product";

type ProductFilterCardProps = {
  productsIDs: number[] | undefined;
  setProductIDs: (newValues: number[] | undefined) => void;
  children?: ReactNode;
};

export const ProductFilterCard = ({
  children,
  productsIDs,
  setProductIDs,
}: ProductFilterCardProps) => {
  const intl = useIntl();

  const { getProductIDPropertyValue } = useProductIDProperty();

  const { data } = useSearchProductsQuery(
    {
      Filters: {
        product_id: {
          Values: Array.isArray(productsIDs) ? productsIDs : [],
        },
      },
      IncludedFields: ["product_id", "backend_id", "display_value", "custom_id", "primary_image"],
    },
    { enabled: !!productsIDs && productsIDs?.[0] !== null },
  );

  const selectedProducts = useMemo(() => {
    if (!data) {
      return [];
    }

    if (!productsIDs) {
      return [];
    }
    const products: IProduct[] = data.Products?.reduce((acc: IProduct[], product: IProduct) => {
      if (productsIDs.includes(product.product_id)) {
        acc.push(product);
      }
      return acc;
    }, []);

    return products;
  }, [data, productsIDs]);

  const assetUrl = useGetAssetsUrl();

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({ id: "generic.label.products", defaultMessage: "Products" })}
        actions={
          <CardActions>
            {productsIDs && productsIDs.length ? (
              <Button
                variant="secondary"
                onPress={() => {
                  setProductIDs(undefined);
                }}
              >
                <FormattedMessage id="generic.label.clear-all" defaultMessage="Clear all" />
              </Button>
            ) : null}
            <DialogTrigger>
              <Button variant="secondary">
                <FormattedMessage
                  id="generic.label.advanced-search"
                  defaultMessage="Advanced search"
                />
              </Button>
              <ProductSearchModal.Multi
                leftPaneListItemRenderElements={(product) => ({
                  title: product.display_value,
                  description: getProductIDPropertyValue(product),
                  imageBlobId: product.primary_image?.blob,
                })}
                rightPaneListItemRenderElements={(product) => ({
                  title: product.display_value,
                  description: getProductIDPropertyValue(product),
                  imageBlobId: product.primary_image?.blob,
                })}
                selectedProducts={selectedProducts}
                maxWidth="7xl"
                onSave={(newSelectedProducts) => {
                  setProductIDs(newSelectedProducts.map((product) => product.product_id));
                }}
                closeOnSave
              />
            </DialogTrigger>
            <MultiProductsField.Controlled
              values={selectedProducts}
              onChange={(newValues) => {
                setProductIDs(newValues?.map((product) => product.product_id));
              }}
              selectRenderElements={(product) => ({
                label: product.display_value,
                description: getProductIDPropertyValue(product)?.toString(),
                image:
                  assetUrl && product.primary_image?.blob
                    ? `${assetUrl}/image/48/48/${product.primary_image?.blob}.png`
                    : undefined,
              })}
            >
              <Button variant="icon" fullWidth>
                <SvgIcon name="plus" />
              </Button>
            </MultiProductsField.Controlled>
          </CardActions>
        }
      />
      <Separator />
      <SelectedProducts selectedProducts={selectedProducts} setProductIDs={setProductIDs} />
      {children ? <CardContent className="pt-4">{children}</CardContent> : null}
    </Card>
  );
};

const { MultiSearchListField: MultiProductsField } = generateSearchProductsLyraSearchListField({});
