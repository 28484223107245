import { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import { Buffer } from "buffer";

import { IFile } from "~/components/suite-ui/dropzone/types";
import { readableFileSize } from "~/util/readable-file-size";

const useDropzone = (
  setFileData?: (data?: string) => void,
  setMimeType?: (type?: string) => void,
  setFileName?: (name?: string) => void,
) => {
  const intl = useIntl();

  const [uploadedData, setUploadedData] = useState<string>();
  const [isFileDropped, setIsFileDropped] = useState(false);
  const [primaryLabel, setPrimaryLabel] = useState<string>();
  const [secondaryLabel, setSecondaryLabel] = useState<string>();

  const handleFileDrop = useCallback(
    async (files: IFile[]) => {
      if (files?.[0]?.data) {
        const fileName = files?.[0]?.path;
        const fileSize = files?.[0]?.size;

        // get encoded string data
        const base64encodedString = /base64,(.+)/.exec(files[0].data as any)?.[1];

        if (base64encodedString) {
          setIsFileDropped(true);

          setPrimaryLabel(fileName);
          setSecondaryLabel(readableFileSize(fileSize));
        } else {
          setIsFileDropped(false);

          setPrimaryLabel(
            intl.formatMessage({
              id: "generic.label.an-error-has-occured-while-uploading-the-file",
              defaultMessage: "An error occured while uploading the file.",
            }),
          );

          setSecondaryLabel(
            intl.formatMessage({
              id: "generic.label.please-try-again",
              defaultMessage: "Please try again.",
            }),
          );
        }

        setUploadedData(base64encodedString);
        setFileData?.(base64encodedString);
        setMimeType?.(files?.[0]?.type);
        setFileName?.(fileName);
      }
    },
    [intl, setFileData, setFileName, setMimeType],
  );

  return {
    isFileDropped,
    primaryLabel,
    secondaryLabel,
    handleFileDrop,
    uploadedData,
  };
};

export default useDropzone;
