import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Form } from "react-router-dom";

import { CardContent, CardHeader, Dialog, DialogCard } from "@new-black/lyra";
import { isEmpty } from "lodash";

import {
  LoyaltyProgramCustomFieldConsumerWithOperatorFields,
  TLoyaltyProgramCustomFieldConsumerWithOperatorForm,
  TLoyaltyProgramCustomFieldConsumerWithOperatorFormErrors,
} from "../../loyalty-program-custom-field-consumer-with-operator-fields";

import { FormSubmitDialogFooter } from "~/components/shared/form-submit-dialog-footer";
import { TCustomFieldAutocompleteItem } from "~/components/suite-composite/generate-custom-field-autocomplete";
import LoadingStateBox from "~/components/suite-ui/loading-state-box";
import { removeUndefinedValues } from "~/util/helper";

interface IAddEditLoyaltyProgramRuleConsumerConditionModal {
  /** Action path. */
  actionPath?: string;
  /** Form ID. */
  form: string;
  isOpen: boolean;
  onClose: () => void;
  errors?: TLoyaltyProgramCustomFieldConsumerWithOperatorFormErrors;
  initialValues?: TLoyaltyProgramCustomFieldConsumerWithOperatorForm;
  initialCustomField?: TCustomFieldAutocompleteItem;
  isLoading?: boolean;
  organizationUnitID?: number;
}

export const AddEditLoyaltyProgramRuleConsumerConditionModal = ({
  actionPath,
  errors,
  form,
  initialCustomField,
  initialValues,
  isLoading,
  isOpen,
  onClose,
  organizationUnitID,
}: IAddEditLoyaltyProgramRuleConsumerConditionModal) => {
  const intl = useIntl();

  const hasInitialValues = useMemo(
    () => !isEmpty(removeUndefinedValues(initialValues)),
    [initialValues],
  );

  const title = useMemo(
    () => intl.formatMessage({ id: "generic.label.consumer", defaultMessage: "Consumer" }),
    [intl],
  );

  return (
    <Dialog
      aria-label={title}
      isOpen={isOpen}
      onOpenChange={(open) => (!open ? onClose() : undefined)}
      maxWidth="lg"
    >
      <Form method="POST" id={form} action={actionPath}>
        <DialogCard>
          <CardHeader title={title} />

          {isLoading ? (
            <LoadingStateBox limit={2} />
          ) : (
            <CardContent className="max-h-[60vh] overflow-y-auto">
              <LoyaltyProgramCustomFieldConsumerWithOperatorFields
                organizationUnitID={organizationUnitID}
                errors={errors}
                initialValues={initialValues}
                initialCustomField={initialCustomField}
              />
            </CardContent>
          )}

          <FormSubmitDialogFooter
            isSaveDisabled={isLoading}
            formID={form}
            onCancel={onClose}
            saveLabel={
              hasInitialValues
                ? undefined
                : intl.formatMessage({
                    id: "generic.label.create",
                    defaultMessage: "Create",
                  })
            }
          />
        </DialogCard>
      </Form>
    </Dialog>
  );
};
