import { useMemo } from "react";

import { Core } from "@springtree/eva-services-core";

import { getUserAssociationTypesQuery } from "~/models/users";
import { ONE_HOUR } from "~/util/base-values";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const UserAssociationTypesSearchListField = SearchListFieldGenerator<
  Core.GetUserAssociationTypes,
  { ID: number; Name: string },
  number
>({
  getItemFromResponse: (response) =>
    response?.UserAssociationTypes?.map((item) => ({ ID: item.ID, Name: item.Name })),
  getItemId: (item) => item.ID,
  getLabel: (item) => item.Name,
  useItemByID: (id, itemsFromList) => {
    const item = useMemo(() => itemsFromList?.find((item) => item.ID === id), [id, itemsFromList]);
    return { data: item, isLoading: false };
  },
  useItemsByID: (ids, itemsFromList) => {
    const value = useMemo(
      () => ({
        data: ids
          ?.map((id) => itemsFromList?.find((item) => item.ID === id))
          ?.filter((item): item is { ID: number; Name: string } => !!item),
        isLoading: false,
      }),
      [ids, itemsFromList],
    );

    return value;
  },
  useServiceQuery: () =>
    SearchListFieldGenerator.useSearchListFieldService({
      query: getUserAssociationTypesQuery,
      initialRequest: {},
      refetchOnFocus: false,
      staleTime: ONE_HOUR,
    }),
});
