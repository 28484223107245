import { useIntl } from "react-intl";

import { CircularProgress } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { Field, FieldProps } from "formik";

import useProductPropertyTypes from "../hooks/use-product-properties";

import Input from "~/components/suite-ui/input";

const ProductPropertyField = () => {
  const intl = useIntl();

  const { productPropertyTypes, productPropertyTypesResponseLoading } = useProductPropertyTypes();

  return (
    <Field name="keyName">
      {({ field, form, meta }: FieldProps) => (
        <>
          <Autocomplete
            key="search-product-property"
            value={
              productPropertyTypes?.find((type) => type.type_id === field.value)?.type_id ?? null
            }
            options={productPropertyTypes?.map((item) => item.type_id) ?? []}
            loading={productPropertyTypesResponseLoading}
            onChange={(_, newValue) => {
              form.setFieldValue("keyName", newValue);
            }}
            getOptionSelected={(option, value) => option === value}
            id="search-product-property-type"
            autoComplete
            includeInputInList
            handleHomeEndKeys
            clearOnBlur
            selectOnFocus
            autoHighlight
            renderInput={(params) => (
              <Input
                {...params}
                label={intl.formatMessage({
                  id: "product-filter.modal.product-property-type.input",
                  defaultMessage: "Product property type",
                })}
                error={!!meta.error && meta.touched}
                helperText={!!meta.error && meta.touched ? meta.error : undefined}
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {productPropertyTypesResponseLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
        </>
      )}
    </Field>
  );
};

export default ProductPropertyField;
