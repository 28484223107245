import { useIntl } from "react-intl";

import { IWorkspaceView } from "./types";

import { ConfirmationModal } from "~/components/suite-composite/confirmation-modal";

const SaveWorkspaceFromCurrentLayoutModal = ({
  isLoading,
  onCancel,
  onConfirm,
  open,
  title,
}: IWorkspaceView<undefined>) => {
  const intl = useIntl();

  return (
    <ConfirmationModal
      maxWidth="sm"
      fullWidth
      isOpen={open}
      onCancel={onCancel}
      onConfirm={() => onConfirm(undefined)}
      messages={{
        titleMessage: title,
        descriptionMessage: intl.formatMessage({
          id: "workspaces.save.confirmation",
          defaultMessage:
            "Are you sure you want to overwrite selected workspace's settings with current settings?",
        }),
      }}
      isLoading={isLoading}
    />
  );
};

export default SaveWorkspaceFromCurrentLayoutModal;
