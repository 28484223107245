import { useCallback, useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { ButtonGroup } from "@new-black/lyra";
import { isEqual, uniqBy } from "lodash";
import { v4 as uuid } from "uuid";

import { IProductSetFilter } from "../types";

import AddActionButton from "~/components/shared/action-buttons/add-action-button";
import DeleteActionButton from "~/components/shared/action-buttons/delete-action-button";
import { Autocomplete } from "~/components/suite-ui/autocomplete";
import Grid from "~/components/suite-ui/grid";
import { removeUndefinedValues } from "~/util/helper";

export interface IProductPropertyFilter {
  productProperties: string[];
  filter: IProductSetFilter;
  onChange: (value: IProductSetFilter) => void;
  onDelete?: () => void;
  onAdd?: () => void;
}

const ProductPropertyFilter = ({
  filter,
  onAdd,
  onChange,
  onDelete,
  productProperties,
}: IProductPropertyFilter) => {
  const intl = useIntl();

  const [localProperty, setLocalProperty] = useState(filter.ProductProperty);
  const [localPropertyValues, setLocalPropertyValues] = useState<
    { value: string; uuid: string }[] | undefined
  >(filter.Values?.map((value) => ({ value, uuid: uuid() })));

  const productPropertiesAutocompleteItems = useMemo(
    () => productProperties.map((name) => ({ name, uuid: uuid() })),
    [productProperties],
  );

  useEffect(() => setLocalProperty(filter.ProductProperty), [filter.ProductProperty]);
  useEffect(() => {
    setLocalPropertyValues(filter.Values?.map((value) => ({ value, uuid: uuid() })));
  }, [filter.Values]);

  useEffect(() => {
    const newFilter: IProductSetFilter = {
      ...filter,
      Values: localPropertyValues?.map(({ value }) => value),
      ProductProperty: localProperty,
    };

    if (!isEqual(removeUndefinedValues(newFilter), removeUndefinedValues(filter))) {
      onChange(newFilter);
    }
  }, [filter, localProperty, localPropertyValues, onChange]);

  const handleSelectedPropertyValues = useCallback((items: any[]) => {
    setLocalPropertyValues((current) => {
      if (current) {
        return uniqBy([...current, ...items], "uuid");
      }
      return items;
    });
  }, []);

  const handleDeletePropertyValue = useCallback((id: string) => {
    setLocalPropertyValues((current) => current?.filter((item) => item.uuid !== id));
  }, []);

  const handleClearPropertyValues = useCallback(() => {
    setLocalPropertyValues(undefined);
  }, []);

  return (
    <Grid container spacing={3} alignItems="center">
      <Grid item xs>
        <Autocomplete
          small
          items={productPropertiesAutocompleteItems}
          matchKeys={["name"]}
          optionIDKey="uuid"
          renderOptionValueKey="name"
          controlledSelectedItem={
            productPropertiesAutocompleteItems.find((x) => x.name === filter.ProductProperty) ?? ""
          }
          handleSelectedItem={(selectedProperty) => {
            setLocalProperty(selectedProperty?.name);
            setLocalPropertyValues(undefined);
          }}
          label={intl.formatMessage({
            id: "product-set-modal.product-selector.product-property-filters.label.product-property",
            defaultMessage: "Search property",
          })}
        />
      </Grid>
      <Grid item xs>
        <Autocomplete
          small
          items={[]}
          matchKeys={["value"]}
          optionIDKey="uuid"
          renderOptionValueKey="value"
          controlledSelectedItem={localPropertyValues ?? ""}
          multi
          csv
          freeOptions
          handleDeleteItem={handleDeletePropertyValue}
          handleSelectedItems={handleSelectedPropertyValues}
          clearAllItems={handleClearPropertyValues}
          label={intl.formatMessage({
            id: "product-set-modal.product-selector.product-property-filters.label.property-values",
            defaultMessage: "Search values",
          })}
          onBlur={(e) => {
            if (e.target.value) {
              handleSelectedPropertyValues([
                {
                  value: e.target.value,
                  uuid: uuid(),
                },
              ]);
            }
          }}
        />
      </Grid>
      <Grid item>
        <ButtonGroup>
          {onDelete ? (
            <DeleteActionButton
              variant="icon-xs"
              onPress={onDelete}
              tooltip={intl.formatMessage({
                id: "product-set-modal.product-selector.product-property-filters.tooltip.delete",
                defaultMessage: "Delete filter",
              })}
            />
          ) : null}
          {onAdd ? (
            <AddActionButton
              small
              onPress={onAdd}
              tooltip={intl.formatMessage({
                id: "product-set-modal.product-selector.product-property-filters.tooltip.add",
                defaultMessage: "Add filter",
              })}
            />
          ) : null}
        </ButtonGroup>
      </Grid>
    </Grid>
  );
};

export default ProductPropertyFilter;
