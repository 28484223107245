import { toast } from "~/components/suite-ui/toast";
import { intlAccessor } from "~/util/intl-accessor";

const errorMessage = intlAccessor.formatMessage({
  id: "workspaces.parse-json-error",
  defaultMessage: "Failed to parse workspace settings. Resetting to default settings.",
});

export const safelyParseWorkspaceSettings = <TWorkspace>(
  settings: string,
  defaultWorkspaceSettings: TWorkspace,
) => {
  try {
    return {
      value: JSON.parse(settings),
      success: true,
    };
  } catch (error) {
    toast.error(errorMessage, {
      id: errorMessage,
    });
    return { value: defaultWorkspaceSettings, success: false };
  }
};
