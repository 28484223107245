import { ChangeEvent, ReactNode, useCallback, useEffect, useMemo, useState } from "react";

import { Box, Tabs as MuiTabs } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import classNames from "clsx";
import { isNil } from "lodash";

import TabWithValuesLabel from "./tab-with-values-label";

export interface TabPanelProps {
  children?: ReactNode;
  index?: number;
  value: number | string;
}
export interface ITab {
  label: string | JSX.Element;
  tabPanel: TabPanelProps;
  disabled?: boolean;
  index?: number;
  hasValues?: boolean;
  required?: boolean;
  hasError?: boolean;
}

export interface ITabs {
  tabs: ITab[];
  initialActiveTab?: number;
  activeTab?: number;
  setActiveTab?: (activeTab: number) => void;
  variant?: "standard" | "scrollable" | "fullWidth";
  headerClassName?: string;
  className?: string;
}

export const TabPanel = (props: TabPanelProps) => {
  const { children, index, value, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index ? <Box>{children}</Box> : null}
    </Typography>
  );
};

const Tabs = ({
  activeTab,
  className,
  headerClassName,
  initialActiveTab,
  setActiveTab,
  tabs,
  variant,
}: ITabs) => {
  const [value, setValue] = useState(activeTab ?? initialActiveTab ?? 0);

  const handleChange = useCallback(
    (event: ChangeEvent<any>, newValue: number) => {
      if (setActiveTab) {
        setActiveTab(newValue);
      } else {
        setValue(newValue);
      }
    },
    [setActiveTab],
  );

  useEffect(() => {
    if (!isNil(activeTab) && activeTab !== value) {
      setValue(activeTab);
    }
  }, [activeTab, value]);

  // fallback to the last tab index if the given index is higher then the lenght of the tabs list
  const selectedValue = useMemo(
    () => (value >= tabs.length ? tabs.length - 1 : value),
    [tabs.length, value],
  );

  return (
    <div
      className={classNames(
        "w-full grow",
        "[&_.MuiTab-textColorPrimary]:[color:rgba(0,0,0,0.6)]",
        "[&_.MuiTab-textColorPrimary_.Mui-disabled]:[color:rgba(0,0,0,0.3)]",
        "[&_.MuiTab-textColorPrimary_.Mui-selected]:[color:var(--legacy-eva-color-primary)]",
        "[&_.MuiTabs-scroller]:[border-left:0px] [&_.MuiTabs-scroller]:[border-right:0px]",
      )}
    >
      <AppBar
        elevation={0}
        color="inherit"
        position="static"
        className={classNames("[border-bottom:1px_solid_#e3e3e3]", headerClassName)}
      >
        <MuiTabs
          className={className}
          value={selectedValue}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant={variant || "scrollable"}
          scrollButtons="auto"
          aria-label="Tab navigation"
        >
          {tabs.map((tab, index) => (
            <Tab
              label={
                // eslint-disable-next-line no-nested-ternary
                tab.hasError || (tab.required && !tab.hasValues) ? (
                  <TabWithValuesLabel label={tab.label} color="error" />
                ) : tab.hasValues ? (
                  <TabWithValuesLabel label={tab.label} />
                ) : (
                  tab.label
                )
              }
              key={tab.tabPanel.value}
              disabled={tab.disabled ?? false}
              id={`scrollable-auto-tab-${index}`}
              aria-controls={`scrollable-auto-tabpanel-${index}`}
              className="[&_.MuiTab-wrapper]:px-2 [&_.MuiTab-wrapper]:py-0"
            />
          ))}
        </MuiTabs>
      </AppBar>

      {tabs.map((tab, index) => (
        <TabPanel value={selectedValue} index={index} key={tab.tabPanel.value}>
          {tab.tabPanel.children}
        </TabPanel>
      ))}
    </div>
  );
};

export default Tabs;
