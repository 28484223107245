import { Sync } from "@springtree/eva-services-sync";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getAvailableSynchronizationManagersLoaderQuery,
  serviceQuery: getAvailableSynchronizationManagersServiceQuery,
  serviceQueryKeys: getAvailableSynchronizationManagersQueryKeys,
  useServiceQueryHook: useGetAvailableSynchronizationManagersQuery,
} = createServiceQuery(Sync.GetAvailableSynchronizationManagers);
