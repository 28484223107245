import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getAccountLoaderQuery,
  serviceQueryKeys: getAccountLoaderQueryKeys,
  useServiceQueryHook: useGetAccountQuery,
} = createServiceQuery(CoreManagement.GetAccount);

export const {
  serviceLoaderQuery: listAccountsLoaderQuery,
  serviceQuery: listAccountsQuery,
  serviceQueryKeys: listAccountsQueryKeys,
  useServiceQueryHook: useListAccountsQuery,
} = createServiceQuery(CoreManagement.ListAccounts);
