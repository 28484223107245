import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { uniqBy } from "lodash";

import { ProducRequirementFieldProps } from "./eva-product-set-product-requirement-value-field.tsx";

import { Autocomplete } from "~/components/suite-ui/autocomplete";
import { ProductRequirementDataTypes } from "~/types/product-requirement-types.js";

const DISPLAYED_CHIPS_INCREMENT = 5;

export const EvaProductSetProductRequirementEnumValueField = ({
  errorMessage,
  fieldType,
  onFieldBlur,
  passive,
  productRequirement,
  setValue,
  value,
}: Omit<ProducRequirementFieldProps, "productRequirements"> & { fieldType?: number }) => {
  const intl = useIntl();

  const [inputValue, setInputValue] = useState<string>();
  const [maximumDisplayedSelectedItems, setMaximumDisplayedSelectedItems] =
    useState(DISPLAYED_CHIPS_INCREMENT);

  const parseAutocompleteItem = useCallback(
    (item: string | number | boolean) => {
      switch (fieldType) {
        case ProductRequirementDataTypes.Integer:
          return typeof item === "number"
            ? item
            : // Make sure the input value matches the parsed input
            // TODO: check if we could make a reusable function for this use case
            !isNaN(parseInt(item as string)) && parseInt(item as string).toString() === item
            ? parseInt(item)
            : undefined;
        case ProductRequirementDataTypes.Decimal:
          return typeof item === "number"
            ? item
            : // Make sure the input value matches the parsed input
            // TODO: check if we could make a reusable function for this use case
            !isNaN(parseFloat(item as string)) && parseFloat(item as string).toString() === item
            ? parseFloat(item)
            : undefined;
        case ProductRequirementDataTypes.Bool: {
          return item === "true";
        }
        case ProductRequirementDataTypes.String:
        case ProductRequirementDataTypes.Text:
        case ProductRequirementDataTypes.Enum:
          return item;
        default:
          return undefined;
      }
    },
    [fieldType],
  );

  const items = useMemo(
    () =>
      (productRequirement?.DataType as number) === ProductRequirementDataTypes.Enum
        ? (Object.entries(productRequirement?.RequirementOptions?.EnumValues ?? {}) ?? [])
            .map(([key, value]) => ({
              key,
              value,
            }))
            .filter((item) => item.value !== undefined)
        : [],
    [productRequirement?.DataType, productRequirement?.RequirementOptions?.EnumValues],
  );

  const controlledSelectedItem = useMemo(() => {
    return value
      ? items.filter((item) => value.map((t) => parseAutocompleteItem(t)).includes(item.key))
      : [];
  }, [items, parseAutocompleteItem, value]);

  const handleSelectedItems = useCallback(
    (selectedItems: { key: string; value: string }[]) => {
      const filteredItems = uniqBy([...controlledSelectedItem, ...selectedItems], "key")
        .map((item) => parseAutocompleteItem(item.key))
        .filter((item) => item !== undefined) as string[] | number[] | boolean[]; // We are sure that we have an array of a single type depending on `fieldType`

      setValue(filteredItems);
    },
    [controlledSelectedItem, parseAutocompleteItem, setValue],
  );

  const handleBlur = useCallback(() => {
    if (inputValue) {
      handleSelectedItems([{ key: inputValue, value: inputValue }]);
      setInputValue(undefined);
    }
    onFieldBlur();
  }, [handleSelectedItems, inputValue, onFieldBlur]);

  const handleDeleteItem = useCallback(
    (id: string) => {
      const index = controlledSelectedItem?.findIndex((item) => item.value === id);

      if (index !== undefined) {
        setValue(value?.toSpliced(index, 1));
      }
    },
    [controlledSelectedItem, setValue, value],
  );

  return (
    <Autocomplete
      passive={passive}
      label={intl.formatMessage({ id: "generic.label.values", defaultMessage: "Values" })}
      multi
      freeOptions
      csv
      items={items}
      controlledInputValue={inputValue ?? ""}
      handleInputChange={setInputValue}
      controlledSelectedItem={controlledSelectedItem?.length ? controlledSelectedItem : ""}
      handleSelectedItems={handleSelectedItems}
      handleDeleteItem={handleDeleteItem}
      clearAllItems={() => setValue([])}
      matchKeys={["value", "key"]}
      optionIDKey="value"
      renderOptionValueKey="value"
      error={!!errorMessage}
      helperText={errorMessage}
      onBlur={handleBlur}
      required
      maximumDisplayedSelectedItems={maximumDisplayedSelectedItems}
      onShowMore={() =>
        setMaximumDisplayedSelectedItems((current) => current + DISPLAYED_CHIPS_INCREMENT)
      }
      showMoreText={intl.formatMessage(
        { id: "generic.label.show-more-total", defaultMessage: "Show more ({total} total)" },
        { total: value?.length },
      )}
    />
  );
};
