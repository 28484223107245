import { useCallback, useMemo, useState } from "react";

import { useField, useFormikContext } from "formik";
import moment from "moment";

import DatePicker from "~/components/suite-ui/date-picker/date-picker";
import DatePickerField from "~/components/suite-ui/date-picker/date-picker-field";

interface IFormikSingleDatePickerProps<TForm> {
  label: string;
  helperText?: string;
  numberOfMonths?: number;
  name: keyof TForm & string;
  maxDateLabel?: keyof TForm;
  minDateLabel?: keyof TForm;
  required?: boolean;
  disabled?: boolean;
}

export const FormikSingleDatePicker = <TForm,>({
  disabled,
  helperText,
  label,
  maxDateLabel,
  minDateLabel,
  name,
  numberOfMonths = 1,
  required,
}: IFormikSingleDatePickerProps<TForm>) => {
  const { values } = useFormikContext<TForm>();
  const [field, meta, { setTouched, setValue }] = useField<string | undefined>(name);

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const minDate = useMemo(
    // @ts-ignore
    () => (minDateLabel ? moment(values[minDateLabel]) : undefined),
    [values, minDateLabel],
  );
  const maxDate = useMemo(
    // @ts-ignore
    () => (maxDateLabel ? moment(values[maxDateLabel]) : undefined),
    [values, maxDateLabel],
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      if (event && event.currentTarget) {
        setAnchorEl(event.currentTarget);
      }
      setOpen(true);
      setTouched(true, true);
    },
    [setTouched],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValue = event.target.value ? moment(event.target.value) : undefined;

      if (newValue) {
        if (minDate && newValue.isSameOrBefore(minDate)) {
          return;
        }
        if (maxDate && newValue.isSameOrAfter(maxDate)) {
          return;
        }
      }

      setValue(newValue?.format("YYYY-MM-DD"));
    },
    [maxDate, minDate, setValue],
  );

  const handleDateChange = useCallback(
    (value: moment.Moment | null) => {
      if (value !== undefined) {
        const newValue = moment(value);
        if (minDate && newValue.isSameOrBefore(minDate)) {
          return;
        }
        if (maxDate && newValue.isSameOrAfter(maxDate)) {
          return;
        }
        setValue(newValue.format("YYYY-MM-DD"));
      }
    },
    [maxDate, minDate, setValue],
  );

  const clear = useCallback(() => {
    setValue(undefined);
  }, [setValue]);

  return (
    <>
      <DatePickerField
        fullWidth
        required={required}
        label={label}
        clear={clear}
        onClick={handleClick}
        onChange={handleChange}
        error={!!meta.error && meta.touched}
        helperText={meta.error && meta.touched ? meta.error : helperText}
        value={field.value ? moment(field.value).format("YYYY-MM-DD") : ""}
        disabled={disabled}
      />
      <DatePicker
        open={open}
        focused={open}
        setOpen={setOpen}
        anchorEl={anchorEl}
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onFocusChange={() => {}}
        id={`${name}_(${label})`}
        onDateChange={handleDateChange}
        numberOfMonths={numberOfMonths}
        date={field.value ? moment(field.value) : null}
      />
    </>
  );
};

FormikSingleDatePicker.defaultProps = {
  numberOfMonths: 1,
  maxDateLabel: undefined,
  minDateLabel: undefined,
};
