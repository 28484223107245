import { isEmpty, omit, pick } from "lodash";

import {
  loyaltyProgramProductLimitationDataKeys,
  loyaltyProgramProductLimitationQuantitiesKeys,
} from "./loyalty-program-product-limitation-data";

import { LoyaltyProgramOptions } from "~/types/eva-core";

export const shouldEnableLoyaltyProgramLoyaltyPoints = (
  loyaltyProgramOptions?: EVA.Core.LoyaltyProgramOptions,
) =>
  loyaltyProgramOptions !== undefined &&
  (loyaltyProgramOptions & LoyaltyProgramOptions.EnableLoyaltyPoints) ===
    LoyaltyProgramOptions.EnableLoyaltyPoints;

export const shouldEnableLoyaltyProgramPaymentTypes = (
  loyaltyProgramOptions?: EVA.Core.LoyaltyProgramOptions,
) =>
  loyaltyProgramOptions !== undefined &&
  (loyaltyProgramOptions & LoyaltyProgramOptions.EnablePaymentMethods) ===
    LoyaltyProgramOptions.EnablePaymentMethods;

export const getProductLimitationProductInformation = (
  productLimitation?: EVA.Core.Management.LoyaltyProgramProductLimitationData,
) => pick(productLimitation, ...Object.values(loyaltyProgramProductLimitationDataKeys));

export const hasProductLimitationProductInformation = (
  productLimitation?: EVA.Core.Management.LoyaltyProgramProductLimitationData,
  ...omitKeys: (keyof EVA.Core.Management.LoyaltyProgramProductLimitationData)[]
) => !isEmpty(omit(getProductLimitationProductInformation(productLimitation), omitKeys));

export const getProductLimitationQuantityInformation = (
  productLimitation?: EVA.Core.Management.LoyaltyProgramProductLimitationData,
) => pick(productLimitation, ...Object.values(loyaltyProgramProductLimitationQuantitiesKeys));

export const hasProductLimitationQuantityInformation = (
  productLimitation?: EVA.Core.Management.LoyaltyProgramProductLimitationData,
) => !isEmpty(getProductLimitationQuantityInformation(productLimitation));

/** Throws an error if we're trying combine different tyeps of product information (product IDs, filters or product search template). */
export const handleCombinedProductLimitationProductInformationError = (
  productLimitation?: EVA.Core.Management.LoyaltyProgramProductLimitationData,
  ...omitKeys: (keyof EVA.Core.Management.LoyaltyProgramProductLimitationData)[]
) => {
  if (hasProductLimitationProductInformation(productLimitation, ...omitKeys)) {
    throw new Response("This product limitation already has product information.", { status: 400 });
  }
};
