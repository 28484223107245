import { useIntl } from "react-intl";

import { DiscountTriggers } from "~/types/eva-core";

const useDiscountTriggers = () => {
  const intl = useIntl();

  const menuItems = [
    {
      label: intl.formatMessage({
        id: "discounts.trigger-types.price-rule",
        defaultMessage: "Price rule",
      }),
      value: DiscountTriggers.Automatic,
    },
    {
      label: intl.formatMessage({
        id: "discounts.trigger-types.manual",
        defaultMessage: "Manual",
      }),
      value: DiscountTriggers.Manual,
    },
    {
      label: intl.formatMessage({
        id: "discounts.trigger-types.coupon",
        defaultMessage: "Coupon",
      }),
      value: DiscountTriggers.Coupon,
    },
    {
      label: intl.formatMessage({
        id: "generic.label.loyalty",
        defaultMessage: "Loyalty",
      }),
      value: DiscountTriggers.Loyalty,
    },
  ];

  return menuItems;
};

export default useDiscountTriggers;
