export default {
  module: {
    path: "/control-room",
  },
  settings: {
    overview: {
      path: "/control-room/settings",
    },
    create: {
      path: "/control-room/settings/:ouid/new",
    },
    unsetSettings: {
      path: "/control-room/settings/unset-settings",
    },
    details: {
      path: "/control-room/settings/:ouid/:setting",
    },
  },
  appSettings: {
    overview: {
      path: "/control-room/app-settings",
    },
    create: {
      path: "/control-room/app-settings/new",
    },
    details: {
      path: "/control-room/app-settings/:ouid/:key/:app",
    },
    edit: {
      path: "/control-room/app-settings/:ouid/:key/:app/edit",
    },
    delete: {
      path: "/control-room/app-settings/:ouid/:key/:app/delete",
    },
  },
  monitors: {
    overview: {
      path: "/control-room/monitors",
    },
    create: {
      path: "/control-room/monitors/:handlerID/new",
    },
    details: {
      path: "/control-room/monitors/:handlerID/:monitorID",

      edit: {
        path: "/control-room/monitors/:handlerID/:monitorID/edit",
      },

      delete: {
        path: "/control-room/monitors/:handlerID/:monitorID/delete",
      },

      conditions: {
        create: {
          path: "/control-room/monitors/:handlerID/:monitorID/conditions/new",
        },

        edit: {
          path: "/control-room/monitors/:handlerID/:monitorID/conditions/:conditionID/edit",
        },

        delete: {
          path: "/control-room/monitors/:handlerID/:monitorID/conditions/:conditionID/delete",
        },
      },

      permissions: {
        create: {
          path: "/control-room/monitors/:handlerID/:monitorID/permissions/new",
        },

        edit: {
          path: "/control-room/monitors/:handlerID/:monitorID/permissions/:roleID/edit",
        },

        delete: {
          path: "/control-room/monitors/:handlerID/:monitorID/permissions/:roleID/delete",
        },
      },

      notifications: {
        create: {
          path: "/control-room/monitors/:handlerID/:monitorID/notifications/new",
        },

        edit: {
          path: "/control-room/monitors/:handlerID/:monitorID/notifications/:notificationID/edit",
        },

        delete: {
          path: "/control-room/monitors/:handlerID/:monitorID/notifications/:notificationID/delete",
        },
      },
    },
  },
  synchronization: {
    wrapper: {
      path: "/control-room/synchronization",
    },
    synchronizationCreate: {
      path: "/control-room/synchronization/create",
    },
    synchronizationCompare: {
      path: "/control-room/synchronization/compare",
    },
    synchronizationCompareDetails: {
      path: "/control-room/synchronization/compare/details",
    },
  },
  apiUsers: {
    overview: {
      path: "/control-room/api-users",
    },
    details: {
      path: "/control-room/api-users/:id",
      editUserData: {
        path: "/control-room/api-users/:id/edit-user-data",
      },
      createAPIKey: {
        path: "/control-room/api-users/:id/create-api-key",
      },
    },
    create: {
      path: "/control-room/api-users/new",
    },
  },
  scripting: {
    overview: {
      path: "/control-room/scripting",
    },
    new: {
      path: "/control-room/scripting/new",
    },
    details: {
      path: "/control-room/scripting/:id",
      editableFieldConfiguration: {
        path: "/control-room/scripting/:id/editable-field-configuration",

        editField: {
          path: "/control-room/scripting/:id/editable-field-configuration/:fieldID",
        },
      },
      basicEditor: {
        path: "/control-room/scripting/:id/basic-editor",
        details: {
          path: "/control-room/scripting/:id/basic-editor/:fieldId",
        },
      },
      advancedEditor: {
        path: "/control-room/scripting/:id/advanced-editor",

        editGeneralInformation: {
          path: "/control-room/scripting/:id/advanced-editor/edit-general-information",
        },

        editSource: {
          path: "/control-room/scripting/:id/advanced-editor/edit-source",
        },
      },
    },
  },
  infrastructure: {
    overview: {
      path: "/control-room/infrastructure",
    },
    createStation: {
      path: "/control-room/infrastructure/new",
    },
    stationDetails: {
      path: "/control-room/infrastructure/:id",

      editStationDetails: {
        path: "/control-room/infrastructure/:id/edit",
      },

      createDevice: {
        path: "/control-room/infrastructure/:id/devices/new",
      },

      editDevice: {
        path: "/control-room/infrastructure/:id/devices/:deviceId/edit",
      },

      deleteDevice: {
        path: "/control-room/infrastructure/:id/devices/:deviceId/delete",
      },
    },
  },

  stencil: {
    overview: {
      path: "/control-room/stencil",
      template: {
        path: "/control-room/stencil/template",
      },
      partial: {
        path: "/control-room/stencil/partial",
      },
      layout: {
        path: "/control-room/stencil/layout",
      },
    },
    new: {
      path: "/control-room/stencil/:stencilType/new",
    },
    details: {
      path: "/control-room/stencil/:stencilType/:id",
      delete: {
        path: "/control-room/stencil/:stencilType/:id/delete",
      },

      generalInformation: {
        path: "/control-room/stencil/:stencilType/:id/general-information",
        edit: {
          path: "/control-room/stencil/:stencilType/:id/general-information/edit",
        },
        editPdfDetails: {
          path: "/control-room/stencil/:stencilType/:id/general-information/edit-pdf-details",
        },
      },
      editor: {
        path: "/control-room/stencil/:stencilType/:id/editor",
        thermalPreview: {
          path: "/control-room/stencil/:stencilType/:id/editor/thermal-preview",
        },
        sendTestEmail: {
          path: "/control-room/stencil/:stencilType/:id/editor/send-test-email",
        },
        mediaFiles: {
          path: "/control-room/stencil/:stencilType/:id/editor/media-files",
          previewBlob: {
            path: "/control-room/stencil/:stencilType/:id/editor/media-files/preview/:blobId",
          },
          deleteBlob: {
            path: "/control-room/stencil/:stencilType/:id/editor/media-files/delete/:blobId",
          },
        },
      },
    },
  },

  errorQueue: {
    overview: {
      path: "/control-room/error-queue",
      republishMessages: {
        path: "/control-room/error-queue/republish-messages",
      },
    },
    details: {
      path: "/control-room/error-queue/:id",
      information: {
        path: "/control-room/error-queue/:id/information",
      },

      stacktrace: {
        path: "/control-room/error-queue/:id/stacktrace",
      },

      messageBody: {
        path: "/control-room/error-queue/:id/message-body",
      },
    },
  },

  exportLog: {
    path: "/control-room/export-log",
    oldAdminPath: "/management/export-log",
  },

  singleSignOn: {
    overview: {
      path: "/control-room/single-sign-on",
    },

    createOpenIDProvider: {
      path: "/control-room/single-sign-on/new",
    },

    openIDProviderDetails: {
      path: "/control-room/single-sign-on/:id",

      editOpenIDProviderDetails: {
        path: "/control-room/single-sign-on/:id/edit",
      },

      editOpenIDProviderClaims: {
        path: "/control-room/single-sign-on/:id/claims/edit",
      },
    },
  },

  watchtower: {
    overview: {
      path: "/control-room/watchtower",
    },
    create: {
      path: "/control-room/watchtower/new",
    },
    details: {
      path: "/control-room/watchtower/:id",

      reassignDevice: {
        path: "/control-room/watchtower/:id/reassign-device",
      },
    },
  },

  eventExportConfiguration: {
    overview: {
      path: "/control-room/event-export-configurations",
    },
    new: {
      path: "/control-room/event-export-configurations/new",
    },
    details: {
      path: "/control-room/event-export-configurations/:id",
      delete: {
        path: "/control-room/event-export-configurations/:id/delete",
      },
      editGeneralInfo: {
        path: "/control-room/event-export-configurations/:id/edit-general-info",
      },
      editConfiguration: {
        path: "/control-room/event-export-configurations/:id/edit-configuration",
      },
      includeOUs: {
        path: "/control-room/event-export-configurations/:id/include-ous",

        delete: {
          path: "/control-room/event-export-configurations/:id/include-ous/:ouID/delete",
        },
      },
      excludeOUs: {
        path: "/control-room/event-export-configurations/:id/exclude-ous",

        delete: {
          path: "/control-room/event-export-configurations/:id/exclude-ous/:ouID/delete",
        },
      },
      addMessageType: {
        path: "/control-room/event-export-configurations/:id/add-message-type",
      },
      deleteMessageType: {
        path: "/control-room/event-export-configurations/:id/delete-message-type",
      },
      deleteLastMessageType: {
        path: "/control-room/event-export-configurations/:id/delete-last-message-type",
      },
      rotateSecretKey: {
        path: "/control-room/event-export-configurations/:id/rotate-secret-key/:keyNumber",
      },
    },
  },
  scheduledTasks: {
    overview: {
      path: "/control-room/scheduled-tasks",
    },
    create: {
      path: "/control-room/scheduled-tasks/new",
    },
    executeOnce: {
      path: "/control-room/scheduled-tasks/execute-once",
    },
    details: {
      path: "/control-room/scheduled-tasks/:id",
    },
    edit: {
      path: "/control-room/scheduled-tasks/:id/edit",
    },
    delete: {
      path: "/control-room/scheduled-tasks/:id/delete",
    },
    execute: {
      path: "/control-room/scheduled-tasks/:id/execute",
    },
  },
  asyncRequests: {
    overview: {
      path: "/control-room/async-requests",
    },
    details: {
      createZonedCycleCounts: {
        path: "/control-room/async-requests/create-zoned-cycle-counts/:id",
      },
      createZonedCycleCountsByQuery: {
        path: "/control-room/async-requests/create-zoned-cycle-counts-by-query/:id",
      },
    },
  },

  cultures: {
    overview: {
      path: "/control-room/cultures",
      assortmentContentCultures: {
        path: "/control-room/cultures/assortment-content-cultures",
        new: {
          path: "/control-room/cultures/assortment-content-cultures/new",
        },
        delete: {
          path: "/control-room/cultures/assortment-content-cultures/:assortmentId/:languageId/:countryId/delete",
        },
      },
      contentCultureMapping: {
        path: "/control-room/cultures/content-culture-mapping",
        new: {
          path: "/control-room/cultures/content-culture-mapping/new",
        },
        delete: {
          path: "/control-room/cultures/content-culture-mapping/:id/delete",
        },
      },
    },
  },

  entityFieldValidators: {
    overview: {
      path: "/control-room/entity-field-validators",
    },
    new: {
      path: "/control-room/entity-field-validators/new",
    },
    edit: {
      path: "/control-room/entity-field-validators/:id",

      delete: {
        path: "/control-room/entity-field-validators/:id/delete",
      },
    },
  },

  reasons: {
    path: "/control-room/reasons",

    openCashDrawer: {
      path: "/control-room/reasons/open-cash-drawer",

      createOpenCashDrawer: {
        path: "/control-room/reasons/open-cash-drawer/create",
      },
      editOpenCashDrawer: {
        path: "/control-room/reasons/open-cash-drawer/:id/edit",
      },
      deleteOpenCashDrawer: {
        path: "/control-room/reasons/open-cash-drawer/:id/delete",
      },
    },

    taxExemption: {
      path: "/control-room/reasons/tax-exemption",

      createTaxExemption: {
        path: "/control-room/reasons/tax-exemption/create",
      },
      editTaxExemption: {
        path: "/control-room/reasons/tax-exemption/:id/edit",
      },
      deleteTaxExemption: {
        path: "/control-room/reasons/tax-exemption/:id/delete",
      },
    },

    organizationReturn: {
      path: "/control-room/reasons/organization-return",

      createOUReturnReason: {
        path: "/control-room/reasons/organization-return/create",
      },
      editOUReturnReason: {
        path: "/control-room/reasons/organization-return/:id/edit",
      },
      deleteOUReturnReason: {
        path: "/control-room/reasons/organization-return/:id/delete",
      },
    },

    customerReturn: {
      path: "/control-room/reasons/customer-return",

      createCustomerReturnReason: {
        path: "/control-room/reasons/customer-return/create",
      },
      editCustomerReturnReason: {
        path: "/control-room/reasons/customer-return/:id/edit",
      },
      deleteCustomerReturnReason: {
        path: "/control-room/reasons/customer-return/:id/delete",
      },
    },

    cashCorrections: {
      overview: {
        path: "/control-room/reasons/cash-corrections",
        sortedView: {
          path: "/control-room/reasons/cash-corrections/sorted-view",
          new: {
            path: "/control-room/reasons/cash-corrections/sorted-view/new",
          },
          edit: {
            path: "/control-room/reasons/cash-corrections/sorted-view/:id/edit",
          },
          delete: {
            path: "/control-room/reasons/cash-corrections/sorted-view/:id/delete",
          },
        },
        sortingView: {
          path: "/control-room/reasons/cash-corrections/sorting-view",
        },
      },
    },

    refundCorrection: {
      path: "/control-room/reasons/refund-correction",

      createRefundCorrectionReason: {
        path: "/control-room/reasons/refund-correction/create",
      },
      editRefundCorrectionReason: {
        path: "/control-room/reasons/refund-correction/:id/edit",
      },
      deleteRefundCorrectionReason: {
        path: "/control-room/reasons/refund-correction/:id/delete",
      },
    },

    unitPriceCorrection: {
      path: "/control-room/reasons/unit-price-correction",

      createUnitPriceCorrectionReason: {
        path: "/control-room/reasons/unit-price-correction/create",
      },
      editUnitPriceCorrectionReason: {
        path: "/control-room/reasons/unit-price-correction/:id/edit",
      },
      deleteUnitPriceCorrectionReason: {
        path: "/control-room/reasons/unit-price-correction/:id/delete",
      },
    },

    taskDecline: {
      path: "/control-room/reasons/task-decline",

      details: {
        path: "/control-room/reasons/task-decline/:id",
        delete: {
          path: "/control-room/reasons/task-decline/:id/delete",
        },
      },

      new: {
        path: "/control-room/reasons/task-decline/new",
      },
    },
    orderVerification: {
      path: "/control-room/reasons/order-verification",

      edit: {
        path: "/control-room/reasons/order-verification/:id/edit",
      },
      delete: {
        path: "/control-room/reasons/order-verification/:id/delete",
      },
      create: {
        path: "/control-room/reasons/order-verification/create",
      },
    },
  },

  customFields: {
    path: "/control-room/custom-fields",

    order: {
      path: "/control-room/custom-fields/order",

      new: {
        path: "/control-room/custom-fields/order/new",
      },

      details: {
        path: "/control-room/custom-fields/order/:id",

        editGeneralInformation: {
          path: "/control-room/custom-fields/order/:id/edit-general-info",
        },
      },
    },

    orderLine: {
      path: "/control-room/custom-fields/order-line",

      new: {
        path: "/control-room/custom-fields/order-line/new",
      },

      details: {
        path: "/control-room/custom-fields/order-line/:id",

        editGeneralInformation: {
          path: "/control-room/custom-fields/order-line/:id/edit-general-info",
        },
      },
    },

    user: {
      path: "/control-room/custom-fields/user",

      new: {
        path: "/control-room/custom-fields/user/new",
      },

      details: {
        path: "/control-room/custom-fields/user/:id",

        editGeneralInformation: {
          path: "/control-room/custom-fields/user/:id/edit-general-info",
        },
      },
    },

    shipment: {
      path: "/control-room/custom-fields/shipment",

      new: {
        path: "/control-room/custom-fields/shipment/new",
      },

      details: {
        path: "/control-room/custom-fields/shipment/:id",

        editGeneralInformation: {
          path: "/control-room/custom-fields/shipment/:id/edit-general-info",
        },
      },
    },

    shippingMethod: {
      path: "/control-room/custom-fields/shipping-method",

      new: {
        path: "/control-room/custom-fields/shipping-method/new",
      },

      details: {
        path: "/control-room/custom-fields/shipping-method/:id",

        editGeneralInformation: {
          path: "/control-room/custom-fields/shipping-method/:id/edit-general-info",
        },
      },
    },

    organizationUnit: {
      path: "/control-room/custom-fields/organization-unit",

      new: {
        path: "/control-room/custom-fields/organization-unit/new",
      },

      details: {
        path: "/control-room/custom-fields/organization-unit/:id",

        editGeneralInformation: {
          path: "/control-room/custom-fields/organization-unit/:id/edit-general-info",
        },
      },
    },

    case: {
      path: "/control-room/custom-fields/case",

      new: {
        path: "/control-room/custom-fields/case/new",
      },

      details: {
        path: "/control-room/custom-fields/case/:id",

        editGeneralInformation: {
          path: "/control-room/custom-fields/case/:id/edit-general-info",
        },
      },
    },

    task: {
      path: "/control-room/custom-fields/task",

      new: {
        path: "/control-room/custom-fields/task/new",
      },

      details: {
        path: "/control-room/custom-fields/task/:id",

        editGeneralInformation: {
          path: "/control-room/custom-fields/task/:id/edit-general-info",
        },
      },
    },

    repair: {
      path: "/control-room/custom-fields/repair",

      new: {
        path: "/control-room/custom-fields/repair/new",
      },

      details: {
        path: "/control-room/custom-fields/repair/:id",

        editGeneralInformation: {
          path: "/control-room/custom-fields/repair/:id/edit-general-info",
        },
      },
    },

    wishlist: {
      path: "/control-room/custom-fields/wishlist",

      new: {
        path: "/control-room/custom-fields/wishlist/new",
      },

      details: {
        path: "/control-room/custom-fields/wishlist/:id",

        editGeneralInformation: {
          path: "/control-room/custom-fields/wishlist/:id/edit-general-info",
        },
      },
    },

    promotion: {
      path: "/control-room/custom-fields/promotion",

      new: {
        path: "/control-room/custom-fields/promotion/new",
      },

      details: {
        path: "/control-room/custom-fields/promotion/:id",

        editGeneralInformation: {
          path: "/control-room/custom-fields/promotion/:id/edit-general-info",
        },
      },
    },
  },
  addressRequirements: {
    overview: {
      path: "/control-room/address-requirements",
    },
    details: {
      path: "/control-room/address-requirements/:countryID",

      editRequirement: {
        path: "/control-room/address-requirements/:countryID/:requirementID",
      },
    },
  },

  serialNumberConfiguration: {
    overview: {
      path: "/control-room/serial-number-configuration",
    },
    new: {
      path: "/control-room/serial-number-configuration/new",
    },
    details: {
      path: "/control-room/serial-number-configuration/:id",
      delete: {
        path: "/control-room/serial-number-configuration/:id/delete",
      },
      editGeneralInfo: {
        path: "/control-room/serial-number-configuration/:id/edit-general-info",
      },
      addProduct: {
        path: "/control-room/serial-number-configuration/:id/add-product",
      },
      deleteProduct: {
        path: "/control-room/serial-number-configuration/:id/delete-product/:productId",
      },
    },
  },

  endpointConfigurations: {
    overview: {
      path: "/control-room/endpoint-configurations",
    },

    new: {
      path: "/control-room/endpoint-configurations/new",
    },

    details: {
      path: "/control-room/endpoint-configurations/:id",

      rotateKey: {
        path: "/control-room/endpoint-configurations/:id/rotate/:keyID",
      },

      editGeneralInformation: {
        path: "/control-room/endpoint-configurations/:id/edit-general-information",
      },

      editConfiguration: {
        path: "/control-room/endpoint-configurations/:id/edit-configuration",
      },
    },
  },
} as const;
