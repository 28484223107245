import { useMemo } from "react";

import { Badge } from "@new-black/lyra";

import Grid from "~/components/suite-ui/grid";
import { UserTypes } from "~/types/eva-core";
import enumToArray from "~/util/enum-to-array";

export function UserTypeCell({ value }: { value: number }) {
  const activeUserTypes = useMemo(
    () =>
      enumToArray(UserTypes)
        ?.filter(
          (userType) => (userType.value & value) === userType.value && userType.key !== "None",
        )
        .map((userType) => ({
          ...userType,
          // Inserts a space before every capital letter,
          // and trims the spaces from the start and end of the string.
          // e.g.: "RemovedByRequest" -> replace -> " Removed By Request" -> trim -> "Removed By Request"
          DisplayName: userType.key.replace(/([A-Z])/g, " $1").trim(),
        })),
    [value],
  );

  return (
    <div className="flex flex-wrap gap-2">
      {activeUserTypes?.map((userType) => (
        <Grid item key={`${value}-${userType.value}`}>
          <Badge variant="transparent">{userType.DisplayName}</Badge>
        </Grid>
      ))}
    </div>
  );
}
