import React, { KeyboardEventHandler, ReactNode } from "react";

import { SvgIcon } from "@new-black/lyra";
import { cva } from "class-variance-authority";
import classnames from "clsx";

export type Colors =
  | "default"
  | "primary"
  | "orange"
  | "pink"
  | "aqua"
  | "yellow"
  | "indigo"
  | "red-orange"
  | "red"
  | "green"
  | "lime";

export interface ChipProps {
  color?: Colors;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  onDelete?: (event?: React.MouseEvent) => void;
  size?: "small" | "medium";
  children: ReactNode;
  classNames?: { parent?: string };
  onKeyDown?: KeyboardEventHandler<HTMLDivElement>;
  ids?: { parent?: string };
  /** If true, the chip will show a focused state if any of the child components are focused */
  enableGroupFocus?: string;
  tabIndex?: number;
  ariaLabeledBy?: React.AriaAttributes["aria-labelledby"];
}

export * from "./chip-list-wrapper";

const tailwindTextColorMap: Record<Colors, `text-[color:var(--legacy-eva-color-${Colors})]`> = {
  default: "text-[color:var(--legacy-eva-color-default)]",
  primary: "text-[color:var(--legacy-eva-color-primary)]",
  orange: "text-[color:var(--legacy-eva-color-orange)]",
  pink: "text-[color:var(--legacy-eva-color-pink)]",
  aqua: "text-[color:var(--legacy-eva-color-aqua)]",
  yellow: "text-[color:var(--legacy-eva-color-yellow)]",
  indigo: "text-[color:var(--legacy-eva-color-indigo)]",
  "red-orange": "text-[color:var(--legacy-eva-color-red-orange)]",
  red: "text-[color:var(--legacy-eva-color-red)]",
  green: "text-[color:var(--legacy-eva-color-green)]",
  lime: "text-[color:var(--legacy-eva-color-lime)]",
};

const coloredChip = cva("coloredChip", {
  variants: {
    color: {
      default: [
        "border-[color:var(--legacy-eva-color-default)]",
        "bg-[color:var(--legacy-eva-color-default-transparent)]",
      ],
      primary: [
        "border-[color:var(--legacy-eva-color-primary)]",
        "bg-[color:var(--legacy-eva-color-primary-transparent)]",
      ],
      orange: [
        "border-[color:var(--legacy-eva-color-orange)]",
        "bg-[color:var(--legacy-eva-color-orange-transparent)]",
      ],
      pink: [
        "border-[color:var(--legacy-eva-color-pink)]",
        "bg-[color:var(--legacy-eva-color-pink-transparent)]",
      ],
      aqua: [
        "border-[color:var(--legacy-eva-color-aqua)]",
        "bg-[color:var(--legacy-eva-color-aqua-transparent)]",
      ],
      yellow: [
        "border-[color:var(--legacy-eva-color-yellow)]",
        "bg-[color:var(--legacy-eva-color-yellow-transparent)]",
      ],
      indigo: [
        "border-[color:var(--legacy-eva-color-indigo)]",
        "bg-[color:var(--legacy-eva-color-indigo-transparent)]",
      ],
      "red-orange": [
        "border-[color:var(--legacy-eva-color-red-orange)]",
        "bg-[color:var(--legacy-eva-color-red-orange-transparent)]",
      ],
      red: [
        "border-[color:var(--legacy-eva-color-red)]",
        "bg-[color:var(--legacy-eva-color-red-transparent)]",
      ],
      green: [
        "border-[color:var(--legacy-eva-color-green)]",
        "bg-[color:var(--legacy-eva-color-green-transparent)]",
      ],
      lime: [
        "border-[color:var(--legacy-eva-color-lime)]",
        "bg-[color:var(--legacy-eva-color-lime-transparent)]",
      ],
    },
    size: {
      small: ["text-[10px]"],
      medium: ["text-legacy-sm"],
    },
    clickable: { yes: "cursor-pointer", no: "cursor-auto" },
  },
  defaultVariants: {
    color: "default",
    size: "medium",
    clickable: "no",
  },
});

const Chip = ({
  ariaLabeledBy,
  children,
  classNames,
  color = "default",
  ids,
  onClick,
  onDelete,
  onKeyDown,
  size = "medium",
  tabIndex,
}: ChipProps) => {
  return (
    <div
      className={classnames(
        "relative inline-block rounded-full border border-solid font-sans leading-[14px] tracking-[0.25px] text-[color:rgba(0,_0,_0,_0.87)]",
        coloredChip({ clickable: onClick ? "yes" : "no", size: size, color: color }),
        classNames?.parent,
      )}
      onClick={onClick}
      style={{
        padding: onDelete ? "8px 28px 8px 16px" : size === "small" ? "4px 8px" : "8px 16px",
      }}
      tabIndex={tabIndex}
      onKeyDown={onKeyDown}
      id={ids?.parent}
      aria-labelledby={ariaLabeledBy}
    >
      {children}

      {onDelete && (
        <div
          className={classnames(
            "absolute right-[3px] top-2/4 -my-2.5 flex h-[20px] w-[20px] cursor-pointer items-center justify-center text-xl leading-[14px] transition ease-in-out hover:scale-105 ",
            color !== "default" ? tailwindTextColorMap[color] : "text-[color:rgba(0,0,0,0.53)]",
          )}
          role="button"
        >
          <SvgIcon name="clear" onClick={(event) => onDelete(event)} />
        </div>
      )}
    </div>
  );
};

export default Chip;
