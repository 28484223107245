import { Dispatch, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigation } from "react-router-dom";

import { Button, Text } from "@new-black/lyra";

import { LoginAction, LoginState } from "./login.state";
import { LoginActionData, PreferredLoginMethod } from "./login.types";
import { LoginCredentialsFormFields } from "./login-credentials-form-fields";
import { LoginWithSSO } from "./login-sso-form";

import { Link } from "~/components/routing";
import { TextDivider } from "~/components/suite-ui/text-divider/text-divider";
import routeDefinitions from "~/routes/route-definitions";
import { UserTypes } from "~/types/eva-core";
import { useAppSetting } from "~/util/application-configuration";

const PREFERRED_LOGIN_METHOD_SETTING = "App:Login:PreferredMethod";

type MainViewProps = {
  providers?: EVA.Authentication.OpenID.AvailableOpenIDConfiguration[];
  dispatchLoginState: Dispatch<LoginAction>;
  loginActionData?: LoginActionData;
  loginState?: LoginState;
  onLogin?: () => void;
  showBackButton?: boolean;
  ignorePreferredLoginMethod?: boolean;
  applicationConfiguration: { [key: string]: any } | undefined;
};

export function MainView({
  applicationConfiguration,
  dispatchLoginState,
  ignorePreferredLoginMethod = false,
  loginActionData,
  loginState,
  onLogin,
  providers,
  showBackButton,
}: MainViewProps) {
  const { state } = useNavigation();
  const [showAllProviders, setShowAllProviders] = useState(false);

  const preferredLoginMethod = useAppSetting<PreferredLoginMethod>(
    PREFERRED_LOGIN_METHOD_SETTING,
    "Email",
    applicationConfiguration,
  );

  const processing = useMemo(() => {
    return state !== "idle";
  }, [state]);

  const employeesProviders = useMemo(() => {
    return providers?.filter((provider) => provider.UserType === (UserTypes.Employee as number));
  }, [providers]);

  return (
    <div className="flex flex-col">
      {preferredLoginMethod === "SSO" && !ignorePreferredLoginMethod ? (
        <>
          <LoginWithSSO
            primary
            onLogin={onLogin}
            providers={employeesProviders}
            processingProvider={loginState?.processingSSOProvider}
            limitProvidersShown={showAllProviders ? undefined : 1}
            onProcessing={(processingProvider) =>
              dispatchLoginState({
                type: "setAll",
                state: { status: "processing", processingSSOProvider: processingProvider },
              })
            }
          />
          {employeesProviders &&
          employeesProviders?.length > 1 &&
          preferredLoginMethod === "SSO" &&
          !showAllProviders ? (
            <Button
              className="mt-2"
              fullWidth
              variant="secondary"
              onPress={() => setShowAllProviders(true)}
            >
              <FormattedMessage id="generic.label.show-more" defaultMessage="Show more" />
            </Button>
          ) : null}
        </>
      ) : (
        <>
          <LoginCredentialsFormFields errors={loginActionData?.formErrors} />
          {loginActionData?.serviceError && (
            <Text className="my-3" variant="body-small" color="error">
              {loginActionData?.serviceError}
            </Text>
          )}
          <Link
            to={routeDefinitions.auth.forgotPassword.path}
            className="mb-6 mt-1 no-underline focus-visible:shadow-highlight"
          >
            <Text variant="body-medium" className="underline" color="primary">
              <FormattedMessage id="login.forgotPassword" defaultMessage="Forgot password?" />
            </Text>
          </Link>
          <Button
            type="submit"
            fullWidth
            isDisabled={processing}
            isLoading={state === "submitting"}
            name="intent"
            value="credentialsLogin"
          >
            <FormattedMessage id="generic.label.sign-in" defaultMessage="Sign in" />
          </Button>
          {showBackButton && (
            <Button
              className="mt-2"
              variant="secondary"
              fullWidth
              onPress={() => dispatchLoginState({ type: "setView", view: "main" })}
            >
              <FormattedMessage id="generic.label.back" defaultMessage="Back" />
            </Button>
          )}
        </>
      )}
      {employeesProviders?.length ? (
        <>
          <div className="mt-6">
            <TextDivider>
              <FormattedMessage id="generic.label.or" defaultMessage="Or" />
            </TextDivider>
          </div>
          <div className="mt-6">
            {preferredLoginMethod === "SSO" ? (
              <Button
                fullWidth
                isDisabled={loginState?.status === "processing"}
                variant="secondary"
                onPress={() => dispatchLoginState({ type: "setView", view: "credentialsLogin" })}
              >
                <FormattedMessage
                  id="login.login-with-password"
                  defaultMessage="Login with password"
                />
              </Button>
            ) : (
              <LoginWithSSO
                providers={employeesProviders}
                processingProvider={loginState?.processingSSOProvider}
                limitProvidersShown={showAllProviders ? undefined : 1}
                onLogin={onLogin}
                onProcessing={() => dispatchLoginState({ type: "setStatus", status: "processing" })}
              />
            )}
            {employeesProviders?.length > 1 &&
            preferredLoginMethod !== "SSO" &&
            !showAllProviders ? (
              <Button
                className="mt-2"
                fullWidth
                variant="secondary"
                onPress={() => setShowAllProviders(true)}
              >
                <FormattedMessage id="generic.label.show-more" defaultMessage="Show more" />
              </Button>
            ) : null}
          </div>
        </>
      ) : null}
    </div>
  );
}
