import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listCarriersLoaderQuery,
  serviceQuery: listCarriersService,
  serviceQueryKeys: listCarriersQueryKeys,
  useServiceQueryHook: useListCarriersQuery,
} = createServiceQuery(CoreManagement.ListCarriers);

export const {
  serviceLoaderQuery: getCarrierByIDLoaderQuery,
  serviceQuery: getCarrierByIDService,
  serviceQueryKeys: getCarrierByIDQueryKeys,
  useServiceQueryHook: useGetCarrierByIDQuery,
} = createServiceQuery(CoreManagement.GetCarrierByID);
