import { useState } from "react";
import { useIntl } from "react-intl";

import { Button } from "@new-black/lyra";

import { Authorized } from "../authorized";
import { DeleteModal } from "../confirmation-modal/delete-modal";

import useDeleteCustomField from "./use-delete-custom-field";

import { Functionalities, FunctionalityScope } from "~/types/functionalities";

interface IDeleteCustomFieldAction {
  ID: number;
  onSuccess?: () => void;
}

export const DeleteCustomFieldAction = ({ ID, onSuccess }: IDeleteCustomFieldAction) => {
  const intl = useIntl();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const { handleDeleteCustomField, isLoading } = useDeleteCustomField(() => {
    onSuccess?.();
    setIsDeleteModalOpen(false);
  });

  return (
    <Authorized
      functionality={Functionalities.CUSTOM_FIELDS}
      scope={FunctionalityScope.Delete}
      disableRedirect
    >
      <Button variant="danger" onPress={() => setIsDeleteModalOpen(true)}>
        {intl.formatMessage({
          id: "generic.label.delete",
          defaultMessage: "Delete",
        })}
      </Button>

      <DeleteModal
        isLoading={isLoading}
        isOpen={isDeleteModalOpen}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={() => ID && handleDeleteCustomField({ ID })}
      />
    </Authorized>
  );
};
