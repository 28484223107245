import { useCallback } from "react";

import { useMonaco } from "@monaco-editor/react";

import { formatTables } from "../helpers/format-tables";
import { formatExtendLines } from "../helpers/formatters";

import { ScriptEditorLanguage } from "~/types/monaco-editor-language";

export function useRegisterScriptLanguageDocumentFormatter(dialect: ScriptEditorLanguage) {
  const monaco = useMonaco();

  const registerScriptLanguageDocumentFormatter = useCallback(() => {
    return monaco?.languages.registerDocumentFormattingEditProvider(dialect, {
      provideDocumentFormattingEdits: (model) => {
        return [...formatTables(model), ...formatExtendLines(model)];
      },
    });
  }, [dialect, monaco]);

  return registerScriptLanguageDocumentFormatter;
}
