import { Core } from "@springtree/eva-services-core";

import { listTaxRatesQuery } from "~/models/organization-units";
import { AutocompleteGenerator } from "~/util/autocomplete-generator";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateTaxRateAutocomplete = (
  initialFilters?: Partial<EVA.Core.ListTaxRateModelFilters>,
) => {
  const { MultiAutocomplete: TaxrateMultiAutocomplete, SingleAutocomplete: TaxrateAutocomplete } =
    AutocompleteGenerator<Core.ListTaxRates, { ID: number; Name: string; Rate: number }>({
      idKey: "ID",
      labelKey: "Name",
      getItemFromResponse: (resp) =>
        resp?.Result?.Page?.map((taxrate) => ({
          ID: taxrate.ID,
          Name: `${taxrate.TaxCodeName} - ${intlAccessor.formatNumber(taxrate.Rate - 1, {
            style: "percent",
          })}`,
          Rate: taxrate.Rate,
        })),
      defaultLabel: intlAccessor.formatMessage({
        id: "generic.label.tax-rate",
        defaultMessage: "Tax rate",
      }),
      useServiceQuery: () =>
        AutocompleteGenerator.useAutocompleteService({
          refetchOnFocus: false,
          query: listTaxRatesQuery,
          initialRequest: { PageConfig: { Start: 0, Limit: 10, Filter: initialFilters } },
        }),
    });

  return {
    TaxrateMultiAutocomplete,
    TaxrateAutocomplete,
  };
};

export const generateTaxRateSearchListField = (baseRequest: EVA.Core.ListTaxRates) =>
  SearchListFieldGenerator<Core.ListTaxRates, { Name: string; ID: number; Rate: number }, number>({
    getLabel: (item) => `${item.Name}`,
    getItemId: (item) => item.ID,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.tax-rate",
      defaultMessage: "Tax rate",
    }),
    getItemFromResponse: (resp) => {
      return resp?.Result?.Page?.map((taxrate) => ({
        ID: taxrate.ID,
        Name: `${taxrate.TaxCodeName} - ${intlAccessor.formatNumber(taxrate.Rate - 1, {
          style: "percent",
        })}`,
        Rate: taxrate.Rate,
      }));
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listTaxRatesQuery,
        initialRequest: {
          ...baseRequest,
          PageConfig: { Start: 0, Limit: 25, ...baseRequest.PageConfig },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.TaxCodeName,
        setQueryRequest: (req, value) => ({
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              TaxCodeName: value,
            },
          },
        }),
        refetchOnFocus: false,
      }),
  });
