import { useMemo } from "react";

import { QueryClient } from "@tanstack/react-query";

import {
  getEntityTranslationLoaderQuery,
  useGetEntityTranslationQuery,
} from "~/models/entity-translations";

interface IUseEntityTranslationProps {
  fallback: string;
  entityID?: string;
  entityType?: string;
  entityField?: string;
  languageID?: string;
  countryID?: string;
}

const useEntityTranslation = ({
  countryID = "US",
  entityField = "DisplayName",
  entityID,
  entityType = "CustomField",
  fallback,
  languageID = "en",
}: IUseEntityTranslationProps) => {
  const request = useMemo<EVA.Core.GetEntityTranslation | undefined>(
    () =>
      entityID !== undefined
        ? {
            EntityID: entityID,
            EntityType: entityType,
            EntityField: entityField,
            LanguageID: languageID,
            CountryID: countryID,
          }
        : undefined,
    [entityID, entityType, entityField, languageID, countryID],
  );

  const { data: entityTranslationValuFromResponse } = useGetEntityTranslationQuery(request, {});

  const value = useMemo(
    () => entityTranslationValuFromResponse?.Value ?? fallback,
    [entityTranslationValuFromResponse, fallback],
  );

  return value;
};

export const getEntityTranslation =
  (queryClient: QueryClient) =>
  async ({
    countryID = "US",
    entityField = "DisplayName",
    entityID,
    entityType = "CustomField",
    fallback,
    languageID = "en",
  }: IUseEntityTranslationProps) => {
    const request: EVA.Core.GetEntityTranslation | undefined =
      entityID !== undefined
        ? {
            EntityID: entityID,
            EntityType: entityType,
            EntityField: entityField,
            LanguageID: languageID,
            CountryID: countryID,
          }
        : undefined;

    const preparedGetEntityTranslationRequest = getEntityTranslationLoaderQuery(
      queryClient,
      request,
    );
    const { value: entityTranslationResponse } = await preparedGetEntityTranslationRequest();
    return entityTranslationResponse?.Value ?? fallback;
  };

export default useEntityTranslation;
