import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

// GetUserTaskTypes
export const {
  serviceLoaderQuery: getUserTaskTypesLoaderQuery,
  serviceQuery: getUserTaskTypesQuery,
  serviceQueryKeys: getUserTaskTypesQueryKeys,
  useServiceQueryHook: useGetUserTaskTypesQuery,
} = createServiceQuery(Core.GetUserTaskTypes);
