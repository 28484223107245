import { useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useIntl } from "react-intl";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import { SvgIcon } from "@new-black/lyra";
import classNames from "clsx";
import { isEqual } from "lodash";

import Text from "../text";

import { DropzoneWrapper } from "./helper-components";
import { IDropzoneProps, IFile } from "./types";

import usePrevious from "~/hooks/suite-react-hooks/use-previous";

const Dropzone = (props: IDropzoneProps) => {
  const intl = useIntl();
  const {
    accept,
    className,
    disabled,
    dropzoneStrings,
    handleFileDrop,
    id,
    maxSize,
    multiple,
    name,
    readAsDataUrl,
    skipFileRead,
  } = props;

  const { acceptedFiles, getInputProps, getRootProps, isDragActive, isDragReject, open } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      accept,
      multiple,
      disabled,
      maxSize,
    });

  const previousAcceptedFiles = usePrevious(acceptedFiles);

  useEffect(() => {
    // if handleFileDrop is not memoized outside this component then this effect will be re-ran on each render of the parent component
    // so we need to make sure the files actually changed
    if (isEqual(previousAcceptedFiles, acceptedFiles)) {
      return;
    }
    const allFilesRead: Promise<IFile>[] = [];
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();
      allFilesRead.push(
        new Promise<IFile>((resolve, reject) => {
          if (skipFileRead) {
            resolve(file);
            return;
          }
          reader.onabort = (error) => reject(error);
          reader.onerror = (error) => reject(error);
          reader.onload = () => {
            resolve({ ...file, data: reader.result, type: file.type, size: file.size });
          };
          if (readAsDataUrl) {
            reader.readAsDataURL(file);
          } else {
            reader.readAsArrayBuffer(file);
          }
        }),
      );
    });

    Promise.all(allFilesRead).then((files) => {
      handleFileDrop(files);
    });
  }, [acceptedFiles, handleFileDrop, readAsDataUrl, previousAcceptedFiles, skipFileRead]);

  return (
    <DropzoneWrapper
      className={classNames("h-[320px]", className)}
      isDragActive={isDragActive}
      isDragReject={isDragReject}
    >
      <div {...getRootProps({ className: "dropzone" })}>
        <div className="w-full cursor-pointer" onClick={open}>
          <div className="flex h-full w-full flex-col items-center justify-center p-5">
            <input disabled={disabled} {...getInputProps()} name={name} id={id} />

            <div className="mb-7">
              {props.isFileDropped ? (
                <SvgIcon
                  name="document"
                  className={classNames(
                    "text-[67px]",
                    isDragReject
                      ? "text-[color:var(--legacy-eva-color-error)]"
                      : "text-[color:var(--legacy-eva-color-dark-2)]",
                  )}
                />
              ) : (
                <CloudUploadIcon
                  className={classNames(
                    "text-[67px]",
                    isDragReject
                      ? "text-[color:var(--legacy-eva-color-error)]"
                      : "text-[color:var(--legacy-eva-color-primary)]",
                  )}
                />
              )}
            </div>

            <div className="flex flex-col items-center gap-2">
              <Text variant="h2" color="textPrimary">
                {dropzoneStrings?.primaryLabel ??
                  intl.formatMessage({
                    id: "generic.label.select-a-file-to-upload",
                    defaultMessage: "Select a file to upload",
                  })}
              </Text>

              {dropzoneStrings?.primaryLabel && !dropzoneStrings.secondaryLabel ? null : (
                <Text className="text-[rgba(0,0,0,0.3)]" variant="body1">
                  {dropzoneStrings?.secondaryLabel ??
                    intl.formatMessage({
                      id: "generic.label.or-drop-it-like-its-hot",
                      defaultMessage: "or drop it like it's hot!",
                    })}
                </Text>
              )}
            </div>
          </div>
        </div>
      </div>
    </DropzoneWrapper>
  );
};

export default Dropzone;
