import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { SegmentedControl, SegmentedControlItem, Text as LyraText } from "@new-black/lyra";
import classNames from "clsx";

import { ConfirmationModal } from "../confirmation-modal";
import {
  generateLyraScriptSearchListField,
  generateScriptSearchListField,
} from "../script-autocomplete";

import { useScriptSelectorContext } from "./script-selector-provider";
import { ScriptSelectorOption } from "./types";

import LoadingStateBox from "~/components/shared/loading-state-box";
import { ScriptEditorWithoutProvider } from "~/components/shared/script-editor/script-editor";
import Text from "~/components/suite-ui/text";
import { ScriptEditorLanguage } from "~/types/monaco-editor-language";

export interface IScriptSelectorProps {
  onShowFullScreenEditor?: () => void;
  error?: boolean;
  helperText?: string;
  disabled?: boolean;
  optionsLabels?: {
    existingScript?: string;
    privateScript?: string;
  };
  disablePopoverPortals?: boolean;
  variant?: "lyra" | "material";
}

const ScriptSelector = ({
  disabled,
  disablePopoverPortals,
  error,
  helperText,
  onShowFullScreenEditor,
  optionsLabels,
  variant = "material",
}: IScriptSelectorProps) => {
  const intl = useIntl();

  const {
    activeOption,
    customScriptRevisionComment,
    customScriptSource,
    editorType,
    initialCustomScript,
    isCreatingNewCustomScript,
    isLoading,
    isScriptHistoryLoading,
    ModelProps,
    onCustomScriptRevisionCommentChange,
    onCustomScriptSourceChange,
    onLoadMoreHistory,
    onScriptIdChanged,
    scriptHistory,
    scriptId,
    scriptType,
    setActiveOption,
    setEditorType,
  } = useScriptSelectorContext();

  const ScriptSearchListField = useMemo(() => {
    const generateField =
      variant === "lyra" ? generateLyraScriptSearchListField : generateScriptSearchListField;

    return generateField({
      InitialPageConfig: {
        Filter: {
          Type: scriptType,
        },
      },
    }).SingleIDSearchListField.Controlled;
  }, [variant, scriptType]);

  const ScriptLyraSearchListField = useMemo(
    () =>
      generateLyraScriptSearchListField({
        InitialPageConfig: {
          Filter: {
            Type: scriptType,
          },
        },
      }).SingleIDSearchListField.Controlled,
    [scriptType],
  );

  const [showActiveOptionConfirmationModal, setShowActiveOptionConfirmationModal] = useState(false);

  return isLoading ? (
    <LoadingStateBox limit={5} />
  ) : (
    <div className={classNames("space-y-2", { "-mb-6": activeOption === "custom" })}>
      <SegmentedControl
        value={activeOption}
        onChange={(value) => {
          if (activeOption === "custom" && value === "existing") {
            setShowActiveOptionConfirmationModal(true);
          } else {
            setActiveOption(value as ScriptSelectorOption);
          }
        }}
        label={intl.formatMessage({ id: "generic.label.script", defaultMessage: "Script" })}
        name="active-option"
        description="test"
        disabledKeys={disabled ? ["existing", "custom"] : undefined}
      >
        <SegmentedControlItem
          value="existing"
          textValue={
            optionsLabels?.existingScript ??
            intl.formatMessage({
              id: "generic.label.existing-script",
              defaultMessage: "Existing script",
            })
          }
        >
          {optionsLabels?.existingScript ??
            intl.formatMessage({
              id: "generic.label.existing-script",
              defaultMessage: "Existing script",
            })}
        </SegmentedControlItem>
        <SegmentedControlItem
          value="custom"
          textValue={
            optionsLabels?.privateScript ??
            intl.formatMessage({
              id: "generic.label.new-script",
              defaultMessage: "New script",
            })
          }
        >
          {optionsLabels?.privateScript ??
            intl.formatMessage({
              id: "generic.label.new-script",
              defaultMessage: "New script",
            })}
        </SegmentedControlItem>
      </SegmentedControl>

      {activeOption === "existing" ? (
        <ScriptSearchListField
          value={scriptId}
          onChange={(value) => {
            onScriptIdChanged(value);
          }}
          label={intl.formatMessage({
            id: "generic.label.select-script",
            defaultMessage: "Select script",
          })}
          errorMessage={helperText}
          isDisabled={disabled}
          isRequired
        />
      ) : (
        <div className="pt-4">
          {initialCustomScript ? (
            variant === "lyra" ? (
              <LyraText variant="heading-3">
                <FormattedMessage id="generic.label.editor" defaultMessage="Editor" />
              </LyraText>
            ) : (
              <Text variant="h3">
                <FormattedMessage id="generic.label.editor" defaultMessage="Editor" />
              </Text>
            )
          ) : undefined}

          <div className="-mx-4">
            <ScriptEditorWithoutProvider
              value={customScriptSource}
              dialect={ScriptEditorLanguage.Extension}
              onChange={onCustomScriptSourceChange}
              height="40vh"
              title={
                initialCustomScript
                  ? undefined
                  : intl.formatMessage({
                      id: "generic.label.editor",
                      defaultMessage: "Editor",
                    })
              }
              initialValue={initialCustomScript?.Source}
              hideCurrentRevisionComment={isCreatingNewCustomScript}
              revisionCommentValue={initialCustomScript ? customScriptRevisionComment : undefined}
              onRevisionCommentValueChange={
                initialCustomScript ? onCustomScriptRevisionCommentChange : undefined
              }
              currentRevisionComment={initialCustomScript?.RevisionComment}
              customActionsPosition="end"
              readonly={disabled}
              disableFirstLineEditing
              onShowFullScreenEditor={onShowFullScreenEditor}
              isHistoryLoading={isScriptHistoryLoading}
              scriptHistory={scriptHistory}
              onLoadMoreHistory={onLoadMoreHistory}
              showEditorTypeSwitch={false}
              ModelProps={ModelProps}
              editorType={editorType}
              setEditorType={setEditorType}
            />
          </div>

          {helperText ? (
            variant === "lyra" ? (
              <LyraText color={error ? "error" : undefined}>{helperText}</LyraText>
            ) : (
              <Text variant="body2" color={error ? "error" : undefined}>
                {helperText}
              </Text>
            )
          ) : null}
        </div>
      )}

      <ConfirmationModal
        disablePortal={disablePopoverPortals}
        isOpen={showActiveOptionConfirmationModal}
        onCancel={() => {
          setShowActiveOptionConfirmationModal(false);
        }}
        onConfirm={() => {
          setShowActiveOptionConfirmationModal(false);
          setActiveOption("existing");
        }}
        messages={{
          descriptionMessage: (
            <FormattedMessage
              id="generic.label.unsaved-custom-script-warning"
              defaultMessage="Switching tabs will clear any unsaved script."
            />
          ),
        }}
        variant={variant}
      />
    </div>
  );
};

export default ScriptSelector;
