import { useMemo } from "react";

import classNames from "clsx";

interface ILinearProgress {
  value?: number;
  showLabel?: boolean;
  /** Class name for progress bar height (defaults to `h-1.5`).  */
  height?: string;
  /** Class name for progress bar color (defaults to `bg-action-default`). */
  customProgressColor?: string;
}

const LinearProgress = (props: ILinearProgress) => {
  const widthPercentage = useMemo(
    () => ((props.value ?? 0) > 100 ? 100 : props.value ?? 0),
    [props.value],
  );

  return (
    <div className="flex w-full items-center gap-2">
      {props.showLabel ? <span>{props.value ?? 0}%</span> : null}
      <div
        className={classNames(
          "relative w-full rounded-full bg-[rgba(0,0,0,.05)]",
          props.height ?? "h-1.5",
        )}
      >
        <div
          className={classNames(
            "absolute rounded-full",
            props.height ?? "h-1.5",
            props.customProgressColor ?? "bg-action-default",
          )}
          style={{ width: `${widthPercentage}%` }}
        />
      </div>
    </div>
  );
};

export default LinearProgress;
