import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selector, selectorFamily } from "recoil";

import { EvaDisputeType } from "~/features/invoices/details/invoices-details.types";
import {
  currentInvoiceAtom,
  disputeTypeAtom,
  selectedAdditionalAmountAtom,
} from "~/features/invoices/details/state/current-invoice.state";
import { currentDisputeAtom } from "~/features/invoices/details/view-invoice/disputes/dispute-dialog.state";

export const getAdditionalAmountsForInvoiceServiceState = builders.buildServiceFamilyState({
  requireLoggedIn: true,
  key: "Core:GetInvoiceAdditionalAmountsForInvoice",
  allowEmptyRequest: false,
  service: Core.GetInvoiceAdditionalAmountsForInvoice,
});

export const getAdditionalAmountsForInvoice = selectorFamily({
  key: "Core:GetInvoiceAdditionalAmountsForInvoice:selector",
  get:
    (parameter) =>
    ({ get }) => {
      const response = get(getAdditionalAmountsForInvoiceServiceState.response(parameter));

      return response?.InvoiceAdditionalAmounts;
    },
});

export const getAdditionalAmountsWithConflictsSelector = selector({
  key: "Core:GetInvoiceAdditionalAmountsWithConflicts:selector",
  get: ({ get }) => {
    const currentInvoice = get(currentInvoiceAtom);

    const additionalAmounts = get(getAdditionalAmountsForInvoice(currentInvoice?.ID));

    return additionalAmounts?.filter((item) => item.Amount !== item.OriginalAmount) ?? [];
  },
});

export const selectedOrInitialAdditionalAmountSelector = selector<
  EVA.Core.GetInvoiceAdditionalAmountsForInvoiceResponse.InvoiceAdditionalAmountDto | undefined
>({
  key: "selectedOrInitialAdditionalAmountSelector",
  get: ({ get }) => {
    const selectedAdditionalAmount = get(selectedAdditionalAmountAtom);
    const disputeType = get(disputeTypeAtom);

    if (disputeType !== EvaDisputeType.ADDITIONAL_AMOUNT) {
      return undefined;
    }

    if (selectedAdditionalAmount) {
      return selectedAdditionalAmount;
    }

    const dispute = get(currentDisputeAtom);
    const conflictingAdditionalAmounts = get(getAdditionalAmountsWithConflictsSelector);

    if (dispute && dispute.InvoiceAdditionalAmountID) {
      return (
        conflictingAdditionalAmounts.find(
          (amount) => amount.ID === dispute.InvoiceAdditionalAmountID,
        ) || undefined
      );
    }

    return conflictingAdditionalAmounts.length > 0 ? conflictingAdditionalAmounts[0] : undefined;
  },
  set: ({ set }, newValue) => {
    set(selectedAdditionalAmountAtom, newValue);
  },
});
