import { useEffect, useMemo, useState } from "react";

import useOrganizationUnitDetailed from "~/store/organization-unit-details/use-organization-unit-detailed";

const useDestinationInformation = (order?: EVA.Core.OrderDto) => {
  const [customerInfo, setCustomerInfo] = useState<EVA.Core.UserDto | undefined>();
  const [customerShippingAddress, setCustomerShippingAddress] = useState<
    EVA.Core.AddressDto | undefined
  >();

  const [shipToOUID, setShipToOUID] = useState<number | undefined>();

  const { isLoading: organizationInfoLoading, response: organizationInfo } =
    useOrganizationUnitDetailed(shipToOUID);

  const organizationLoadingWithoutResponse = useMemo(
    () => organizationInfoLoading && !organizationInfo,
    [organizationInfo, organizationInfoLoading],
  );

  useEffect(() => {
    if (order) {
      setCustomerInfo(order?.Customer);
      setCustomerShippingAddress(order?.ShippingAddress);
      if (order?.ShipToOrganizationUnitID && shipToOUID !== order.ShipToOrganizationUnitID) {
        setShipToOUID(order.ShipToOrganizationUnitID);
      }
    }
  }, [order, shipToOUID]);

  return {
    customerInfo,
    customerShippingAddress,
    organizationInfo,
    organizationInfoLoading,
    organizationLoadingWithoutResponse,
  };
};

export default useDestinationInformation;
