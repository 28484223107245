import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  Checkbox,
  Dialog,
  DialogCard,
  DialogProps,
  FieldGroup,
  Separator,
  TextField,
} from "@new-black/lyra";

import { OrderLinesList } from "./orderlines-list";

import { generateListOrdersForCustomerSearchListField } from "~/components/suite-composite/generate-list-orders-for-customer-search-list-field";
import { generateSearchOrdersSearchListField } from "~/components/suite-composite/generate-search-orders-search-list-field";
import { useGetOrderQuery, useListOrderLinesQuery } from "~/models/orders";
import { useProductDetailQuery } from "~/models/products";

export type CaseOrderLine = {
  orderId: number;
  orderLineId: number;
  serialNumber?: string;
};

type SetCaseOrderLineModalProps = {
  isOpen?: DialogProps["isOpen"];
  onOpenChange: DialogProps["onOpenChange"];
  onSave?: (selectedOrderLine?: CaseOrderLine) => void;
  form?: string;
  userId: number;
  onChange?: (newValue?: CaseOrderLine) => void;
  initialValues?: {
    orderId?: number;
    orderLineId?: number;
    serialNumber?: string;
  };
};

export const SetCaseOrderLineModal = ({
  form,
  initialValues,
  isOpen,
  onChange,
  onOpenChange,
  onSave,
  userId,
}: SetCaseOrderLineModalProps) => {
  const intl = useIntl();

  const [orderId, setOrderId] = useState<number | undefined>(initialValues?.orderId);
  const [orderLineId, setOrderLineId] = useState<number | undefined>(initialValues?.orderLineId);
  const [serialNumber, setSerialNumber] = useState<string | undefined>(initialValues?.serialNumber);

  const [onlyCustomerOrders, setOnlyCustomerOrders] = useState<boolean>(true);

  const [orderLinesLimit, setOrderLinesLimit] = useState<number>(5);

  const onChangeOrderID = useCallback((newValue?: number) => {
    setOrderId(newValue);
    setOrderLineId(undefined);
    setSerialNumber(undefined);
  }, []);

  const onChangeOrderLineID = useCallback((newValue?: number) => {
    setOrderLineId(newValue);
    setSerialNumber(undefined);
  }, []);

  const { data: orderDetails } = useGetOrderQuery(
    orderId
      ? {
          OrderID: orderId,
        }
      : undefined,
    {},
  );

  const { data: orderLines, isFetching: isOrderLinesResponeLoading } = useListOrderLinesQuery(
    orderId
      ? {
          OrderID: orderId,
          ProductProperties: ["display_value", "primary_image"],
          PageConfig: {
            Start: 0,
            Limit: orderLinesLimit,
          },
        }
      : undefined,
    {},
  );

  const ListCustomerOdersSearchListField = useMemo(
    () =>
      generateListOrdersForCustomerSearchListField({
        customerID: userId,
      }),
    [userId],
  );

  const productID = useMemo(
    () =>
      orderLines?.Result.Page.length && orderLineId
        ? orderLines.Result.Page.find((orderLine) => orderLine.ID === orderLineId)?.ProductID
        : undefined,
    [orderLineId, orderLines?.Result.Page],
  );

  const { data: productDetail, isFetching: isProductDetailLoading } = useProductDetailQuery(
    productID ? { ID: productID } : undefined,
    { refetchOnMount: false, keepPreviousData: false },
  );

  return (
    <Dialog isOpen={isOpen} onOpenChange={onOpenChange} maxWidth="lg">
      <DialogCard>
        <CardHeader
          title={intl.formatMessage({
            id: "generic.label.search-product-in-order",
            defaultMessage: "Search product in order",
          })}
        />

        <CardContent>
          <FieldGroup>
            {onlyCustomerOrders ? (
              <ListCustomerOdersSearchListField.SingleIDSearchListField.Controlled
                value={orderId}
                onChange={onChangeOrderID}
                hideHintLabel
              />
            ) : (
              <SearchOrdersSearchListField.SingleIDSearchListField.Controlled
                value={orderId}
                onChange={onChangeOrderID}
                hideHintLabel
              />
            )}

            <Checkbox
              isSelected={onlyCustomerOrders}
              onChange={(isSelected) => {
                setOnlyCustomerOrders(isSelected);
                setOrderId(undefined);
              }}
            >
              <FormattedMessage
                id="generic.label.only-orders-for-current-consumer"
                defaultMessage="Only orders for current consumer"
              />
            </Checkbox>

            {orderId ? (
              <div className="flex flex-col gap-4">
                <Card variant="secondary">
                  <CardHeader
                    title={`${intl.formatMessage({
                      id: "generic.label.order",
                      defaultMessage: "Order",
                    })} #${orderId}`}
                    description={intl.formatDate(orderDetails?.Result?.CreationTime)}
                  />

                  <Separator />

                  <OrderLinesList
                    selectedOrderLineId={orderLineId}
                    setSelectedOrderLineId={onChangeOrderLineID}
                    orderLinesResponse={orderLines}
                    orderLinesLimit={orderLinesLimit}
                    setOrderLinesLimit={setOrderLinesLimit}
                    isOrderLinesResponseLoading={isOrderLinesResponeLoading}
                    onChange={(newSelection) =>
                      onChange?.(
                        newSelection
                          ? {
                              orderLineId: newSelection,
                              orderId,
                              serialNumber,
                            }
                          : undefined,
                      )
                    }
                  />
                </Card>

                {productDetail?.SerialNumberConfigurationID ? (
                  <FieldGroup>
                    <TextField
                      label={intl.formatMessage({
                        id: "generic.label.serial-number",
                        defaultMessage: "Serial number",
                      })}
                      value={serialNumber || ""}
                      onChange={(newValue) => {
                        setSerialNumber(newValue);
                        onChange?.(
                          orderLineId && orderId
                            ? {
                                orderId,
                                orderLineId,
                                serialNumber: newValue,
                              }
                            : undefined,
                        );
                      }}
                    />
                  </FieldGroup>
                ) : null}
              </div>
            ) : null}
          </FieldGroup>
        </CardContent>
        <CardFooter>
          <ButtonGroup>
            <Button
              onPress={() => onOpenChange?.(false)}
              variant="secondary"
              isLoading={isOrderLinesResponeLoading}
            >
              <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
            </Button>

            <Button
              type={form ? "submit" : "button"}
              form={form}
              onPress={() =>
                onSave?.(
                  orderLineId && orderId
                    ? {
                        orderId,
                        orderLineId,
                        serialNumber,
                      }
                    : undefined,
                )
              }
              isLoading={isOrderLinesResponeLoading || isProductDetailLoading}
            >
              <FormattedMessage id="generic.label.save" defaultMessage="Save" />
            </Button>
          </ButtonGroup>
        </CardFooter>
      </DialogCard>
    </Dialog>
  );
};

const SearchOrdersSearchListField = generateSearchOrdersSearchListField({});
