import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listManualInvoicesLoaderQuery,
  serviceQuery: listManualInvoicesQuery,
  serviceQueryKeys: listManualInvoicesQueryKeys,
  useServiceQueryHook: useListManualInvoicesQuery,
} = createServiceQuery(Core.ListManualInvoices, true);

export const {
  serviceLoaderQuery: listControlDocumentsLoaderQuery,
  serviceQuery: listControlDocumentsQuery,
  serviceQueryKeys: listControlDocumentsQueryKeys,
  useServiceQueryHook: useListControlDocumentsQuery,
} = createServiceQuery(Core.ListControlDocuments, true);

export const {
  serviceLoaderQuery: getInvoiceLoaderQuery,
  serviceQuery: getInvoiceQuery,
  serviceQueryKeys: getInvoiceQueryKeys,
  useServiceQueryHook: useGetInvoiceQuery,
} = createServiceQuery(Core.GetInvoice, true);

export const {
  serviceLoaderQuery: listBlobsForInvoiceLoaderQuery,
  serviceQuery: listBlobsForInvoiceQuery,
  serviceQueryKeys: listBlobsForInvoiceQueryKeys,
  useServiceQueryHook: useListBlobsForInvoiceQuery,
} = createServiceQuery(Core.ListBlobsForInvoice, true);

export const {
  serviceLoaderQuery: listInvoiceLedgerLoaderQuery,
  serviceQuery: listInvoiceLedgerQuery,
  serviceQueryKeys: listInvoiceLedgerQueryKeys,
  useServiceQueryHook: useListInvoiceLedgerQuery,
} = createServiceQuery(Core.ListInvoiceLedger, true);

export const {
  serviceLoaderQuery: listInvoiceExportsForInvoiceLoaderQuery,
  serviceQuery: listInvoiceExportsForInvoiceQuery,
  serviceQueryKeys: listInvoiceExportsForInvoiceQueryKeys,
  useServiceQueryHook: useListInvoiceExportsForInvoiceQuery,
} = createServiceQuery(Core.ListInvoiceExportsForInvoice, true);

export const {
  serviceLoaderQuery: getInvoiceExportDocumentsLoaderQuery,
  serviceQuery: getInvoiceExportDocumentsQuery,
  serviceQueryKeys: getInvoiceExportDocumentsQueryKeys,
  useServiceQueryHook: useGetInvoiceExportDocumentsQuery,
} = createServiceQuery(Core.GetInvoiceExportDocuments, true);

export const { useServiceQueryHook: useListShipmentsToInvoiceQuery } = createServiceQuery(
  Core.ListShipmentsToInvoice,
  true,
);
