import { IntlShape } from "react-intl";

import { VisibilityType } from "~/types/eva-core";

export const getAddressFieldVisibilityTypeTranslator = (intl: IntlShape) => {
  const translationMap: Record<VisibilityType, string> = {
    [VisibilityType.NotVisible]: intl.formatMessage({
      id: "generic.label.not-visible",
      defaultMessage: "Not visible",
    }),
    [VisibilityType.Visible]: intl.formatMessage({
      id: "generic.label.optional",
      defaultMessage: "Optional",
    }),
    [VisibilityType.VisibleAndRequired]: intl.formatMessage({
      id: "generic.label.required",
      defaultMessage: "Required",
    }),
  };

  return (visibilityType: VisibilityType) => {
    return translationMap[visibilityType];
  };
};
