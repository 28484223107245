import { useMemo } from "react";

import { Core } from "@springtree/eva-services-core";

import { listOrdersForCustomerQuery, useListOrdersForCustomerQuery } from "~/models/orders";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateListOrdersForCustomerSearchListField = ({
  customerID,
}: {
  customerID?: number;
}) =>
  SearchListFieldGenerator<
    Core.ListOrdersForCustomer,
    { OrderId: number; CreationDate?: string },
    number
  >({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listOrdersForCustomerQuery,
        refetchOnFocus: false,
        initialRequest: customerID
          ? {
              ID: customerID,
              PageConfig: {
                Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
                SortProperty: "CreationTime",
                SortDirection: 1,
              },
            }
          : undefined,
        getQueryRequest: (req) => req?.PageConfig?.Filter?.OrderID?.toString(),
        setQueryRequest: (req, newValue) =>
          customerID
            ? {
                ...req,
                ID: customerID,
                PageConfig: {
                  ...req?.PageConfig,
                  Filter: newValue
                    ? {
                        ...req?.PageConfig?.Filter,
                        OrderID: newValue ? parseInt(newValue, 10) : undefined,
                      }
                    : undefined,
                },
              }
            : undefined,
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) <=
            (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            ID: customerID!,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
    getItemId: (item) => item?.OrderId,
    getLabel: (item) => item.OrderId.toString() ?? "",
    selectRenderElements: (item) => ({
      label: item.OrderId.toString(),
      description: intlAccessor.formatDate(item.CreationDate),
    }),
    getItemFromResponse: (response) =>
      response?.Result?.Page.map((order) => ({
        OrderId: order.ID,
        CreationDate: order.CreationTime,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.order-id",
      defaultMessage: "Order ID",
    }),
    useItemByID: (id) => {
      const response = useListOrdersForCustomerQuery(
        id && customerID
          ? {
              ID: customerID,
              PageConfig: {
                Filter: {
                  OrderID: id,
                },
              },
            }
          : undefined,
        {},
      );

      const data = useMemo(() => {
        const item = response.data?.Result?.Page?.[0];
        return item && id
          ? {
              OrderId: item.ID,
              CreationDate: item.CreationTime,
            }
          : undefined;
      }, [id, response.data?.Result?.Page]);

      return {
        data,
        isLoading: false,
      };
    },
  });
