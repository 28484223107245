import { useMemo } from "react";
import { FormatDisplayNameOptions } from "react-intl";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { getLanguagesQuery } from "~/models/application";
import { isoLanguages } from "~/types/iso-languages";
import { AutocompleteGenerator } from "~/util/autocomplete-generator";
import { IAutocompleteGeneratorProps } from "~/util/autocomplete-generator/autocomplete-generator.types";
import { ONE_HOUR } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";
import { ISearchListFieldGeneratorProps } from "~/util/lyra-search-list-field-generator/search-list-field-generator.types";
import { SearchListFieldGenerator } from "~/util/search-list-field-generator";

type BaseAutocompleteProps = IAutocompleteGeneratorProps<
  CoreManagement.GetLanguages,
  {
    ID: string;
    Name: string;
  },
  "ID" | "Name"
>;

export const generateLanguageAutocomplete = ({
  frontendFilter,
}: {
  frontendFilter?: BaseAutocompleteProps["frontendFilter"];
}) =>
  AutocompleteGenerator<CoreManagement.GetLanguages, { ID: string; Name: string }>({
    getItemFromResponse: (response) =>
      response?.Languages?.map((item) => ({
        Name:
          intlAccessor.formatDisplayName(item, LANGUAGE_AUTOCOMPLETE_FORMAT_DISPLAY_NAME_OPTIONS) ??
          isoLanguages[item as keyof typeof isoLanguages]?.name ??
          item,
        ID: item,
      })) ?? [],
    idKey: "ID",
    labelKey: "Name",
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.language",
      defaultMessage: "Language",
    }),
    useItemByID: (id, itemsFromList) => {
      const item = useMemo(
        () => itemsFromList?.find((item) => item.ID === id),
        [id, itemsFromList],
      );
      return { data: item, isLoading: false };
    },
    useItemsByID: (ids, itemsFromList) => {
      const items = useMemo(
        () =>
          ids
            ?.map((id) => itemsFromList?.find((item) => item.ID === id))
            ?.filter((item): item is { ID: string; Name: string } => !!item),
        [ids, itemsFromList],
      );
      return { data: items, isLoading: false };
    },
    useServiceQuery: () =>
      AutocompleteGenerator.useAutocompleteService({
        query: getLanguagesQuery,
        initialRequest: {},
        refetchOnFocus: false,
        staleTime: ONE_HOUR,
      }),
    frontendFilter,
  });

export const LanguageAutocomplete = generateLanguageAutocomplete({});

export const generateLanguageSearchListField = ({
  frontendFilter,
}: {
  frontendFilter?: BaseAutocompleteProps["frontendFilter"];
}) =>
  SearchListFieldGenerator<CoreManagement.GetLanguages, { ID: string; Name: string }>({
    getItemFromResponse: (response) =>
      response?.Languages?.map((item) => ({
        Name:
          intlAccessor.formatDisplayName(item, LANGUAGE_AUTOCOMPLETE_FORMAT_DISPLAY_NAME_OPTIONS) ??
          isoLanguages[item as keyof typeof isoLanguages]?.name ??
          item,
        ID: item,
      })) ?? [],
    idKey: "ID",
    labelKey: "Name",
    filterOptions: { filterKeys: ["ID", "Name"] },
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.language",
      defaultMessage: "Language",
    }),
    useItemByID: (id, itemsFromList) => {
      const item = useMemo(
        () => itemsFromList?.find((item) => item.ID === id),
        [id, itemsFromList],
      );
      return { data: item, isLoading: false };
    },
    useItemsByID: (ids, itemsFromList) => {
      const items = useMemo(
        () =>
          ids
            ?.map((id) => itemsFromList?.find((item) => item.ID === id))
            ?.filter((item): item is { ID: string; Name: string } => !!item),
        [ids, itemsFromList],
      );
      return { data: items, isLoading: false };
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: getLanguagesQuery,
        initialRequest: {},
        refetchOnFocus: false,
        staleTime: ONE_HOUR,
      }),
    frontendFilter,
  });

export const LanguageSearchListField = generateLanguageSearchListField({});

export const generateLanguageLyraSearchListField = ({
  frontendFilter,
  ...props
}: {
  frontendFilter?: BaseAutocompleteProps["frontendFilter"];
} & Partial<
  ISearchListFieldGeneratorProps<CoreManagement.GetLanguages, { ID: string; Name: string }, string>
>) =>
  LyraSearchListFieldGenerator<CoreManagement.GetLanguages, { ID: string; Name: string }, string>({
    getItemFromResponse: (response) =>
      response?.Languages?.map((item) => ({
        Name:
          intlAccessor.formatDisplayName(item, LANGUAGE_AUTOCOMPLETE_FORMAT_DISPLAY_NAME_OPTIONS) ??
          isoLanguages[item as keyof typeof isoLanguages]?.name ??
          item,
        ID: item,
      })) ?? [],
    getItemId: props.getItemId ?? ((item) => item.ID),
    getLabel: props.getLabel ?? ((item) => item.Name),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.language",
      defaultMessage: "Language",
    }),
    useItemByID: (id, itemsFromList) => {
      const item = useMemo(
        () => itemsFromList?.find((item) => item.ID === id),
        [id, itemsFromList],
      );
      return { data: item, isLoading: false };
    },
    useItemsByID: (ids, itemsFromList) => {
      const items = useMemo(
        () =>
          ids
            ?.map((id) => itemsFromList?.find((item) => item.ID === id))
            ?.filter((item): item is { ID: string; Name: string } => !!item),
        [ids, itemsFromList],
      );
      return { data: items, isLoading: false };
    },
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        query: getLanguagesQuery,
        initialRequest: {},
        refetchOnFocus: false,
        staleTime: ONE_HOUR,
      }),
    frontendFilter,
    ...props,
  });

export const getFormattedLanguageLabel = (languageId: string, languageName?: string) => {
  const languageDisplayName =
    languageName ??
    intlAccessor.formatDisplayName(languageId, LANGUAGE_AUTOCOMPLETE_FORMAT_DISPLAY_NAME_OPTIONS) ??
    isoLanguages[languageId as keyof typeof isoLanguages]?.name ??
    languageId;
  const formattedName = intlAccessor.formatDisplayName(languageId, { type: "language" });
  return `${formattedName === languageId ? languageDisplayName : formattedName} (${languageId})`;
};

export const LanguageLyraSearchListField = generateLanguageLyraSearchListField({});
export const LanguageLyraSearchListFieldWithFormattedNames = generateLanguageLyraSearchListField({
  getLabel: (language) => getFormattedLanguageLabel(language.ID, language.Name),
});

export const LANGUAGE_AUTOCOMPLETE_FORMAT_DISPLAY_NAME_OPTIONS: FormatDisplayNameOptions = {
  style: "long",
  type: "language",
  fallback: "none",
};
