import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getLoyaltyProgramLoaderQuery,
  serviceQuery: getLoyaltyProgramQuery,
  serviceQueryKeys: getLoyaltyProgramQueryKeys,
  useServiceQueryHook: useGetLoyaltyProgramQuery,
  useServiceQueryWithRequest: useGetLoyaltyProgramQueryWithRequest,
} = createServiceQuery(CoreManagement.GetLoyaltyProgram, true);

export const {
  serviceLoaderQuery: listLoyaltyProgramsLoaderQuery,
  serviceQuery: listLoyaltyProgramsQuery,
  serviceQueryKeys: listLoyaltyProgramsQueryKeys,
  useServiceQueryHook: useListLoyaltyProgramsQuery,
} = createServiceQuery(CoreManagement.ListLoyaltyPrograms, true);

export const {
  serviceLoaderQuery: listPointAwardingRulesLoaderQuery,
  serviceQueryKeys: listPointAwardingRulesQueryKeys,
  useServiceQueryHook: useListPointAwardingRulesQuery,
} = createServiceQuery(CoreManagement.ListPointAwardingRules);

export const {
  serviceLoaderQuery: listLoyaltyProgramPriceListsLoaderQuery,
  serviceQueryKeys: listLoyaltyProgramPriceListsQueryKeys,
  useServiceQueryHook: useListLoyaltyProgramPriceListsQuery,
} = createServiceQuery(CoreManagement.ListLoyaltyProgramPriceLists, true);

export const {
  serviceLoaderQuery: listLoyaltyProgramConditionsLoaderQuery,
  serviceQueryKeys: listLoyaltyProgramConditionsQueryKeys,
  useServiceQueryHook: useListLoyaltyProgramConditionsQuery,
} = createServiceQuery(CoreManagement.ListLoyaltyProgramConditions, true);

export const {
  serviceLoaderQuery: getLoyaltyProgramHandlersLoaderQuery,
  serviceQuery: getLoyaltyProgramHandlersQuery,
} = createServiceQuery(CoreManagement.GetLoyaltyProgramHandlers, true);

export const { serviceQuery: getLoyaltyPointPoliciesQuery } = createServiceQuery(
  CoreManagement.GetLoyaltyPointPolicies,
  true,
);

export const {
  serviceLoaderQuery: getPointAwardingRuleLoaderQuery,
  serviceQuery: getPointAwardingRuleQuery,
  serviceQueryKeys: getPointAwardingRuleQueryKeys,
  useServiceQueryHook: useGetPointAwardingRuleQuery,
} = createServiceQuery(CoreManagement.GetPointAwardingRule, true);

export const {
  serviceLoaderQuery: listLoyaltyProgramPaymentTypesLoaderQuery,
  serviceQueryKeys: listLoyaltyProgramPaymentTypesQueryKeys,
  useServiceQueryHook: useListLoyaltyProgramPaymentTypesQuery,
} = createServiceQuery(CoreManagement.ListLoyaltyProgramPaymentTypes, true);

export const {
  serviceLoaderQuery: getLoyaltyProgramPaymentTypeLoaderQuery,
  serviceQuery: getLoyaltyProgramPaymentTypeQuery,
  serviceQueryKeys: getLoyaltyProgramPaymentTypeQueryKeys,
} = createServiceQuery(CoreManagement.GetLoyaltyProgramPaymentType, true);

export const {
  serviceLoaderQuery: getLoyaltyProgramConditionLoaderQuery,
  serviceQuery: getLoyaltyProgramConditionQuery,
  serviceQueryKeys: getLoyaltyProgramConditionQueryKeys,
} = createServiceQuery(CoreManagement.GetLoyaltyProgramCondition, true);

export const {
  serviceLoaderQuery: getLoyaltyProgramProductLimitationLoaderQuery,
  serviceQuery: getLoyaltyProgramProductLimitationQuery,
  serviceQueryKeys: getLoyaltyProgramProductLimitationQueryKeys,
} = createServiceQuery(CoreManagement.GetLoyaltyProgramProductLimitation, true);

export const {
  serviceLoaderQuery: listLoyaltyProgramProductLimitationsLoaderQuery,
  serviceQueryKeys: listLoyaltyProgramProductLimitationsQueryKeys,
  useServiceQueryHook: useListLoyaltyProgramProductLimitationsQuery,
} = createServiceQuery(CoreManagement.ListLoyaltyProgramProductLimitations, true);

export const {
  serviceLoaderQuery: listLoyaltyProgramGroupsLoaderQuery,
  serviceQuery: listLoyaltyProgramGroupsQuery,
  serviceQueryKeys: listLoyaltyProgramGroupsQueryKeys,
  useServiceQueryHook: useListLoyaltyProgramGroupsQuery,
} = createServiceQuery(CoreManagement.ListLoyaltyProgramGroups, true);

export const {
  serviceLoaderQuery: getLoyaltyProgramGroupLoaderQuery,
  serviceQuery: getLoyaltyProgramGroupQuery,
  useServiceQueryHook: useGetLoyaltyProgramGroupQuery,
} = createServiceQuery(CoreManagement.GetLoyaltyProgramGroup, true);

export const {
  serviceLoaderQuery: listLoyaltyProgramRequiredCustomFieldsLoaderQuery,
  serviceQueryKeys: listLoyaltyProgramRequiredCustomFieldsQueryKeys,
  useServiceQueryHook: useListLoyaltyProgramRequiredCustomFieldsQuery,
} = createServiceQuery(CoreManagement.ListLoyaltyProgramRequiredCustomFields, true);
