import { Core } from "@springtree/eva-services-core";

import { createEVAService } from "~/util/http";
import { createServiceQuery } from "~/util/query";

// GetAuthorizationStructure
export const getAuthorizationStructureService = createEVAService(Core.GetAuthorizationStructure);

export const {
  serviceLoaderQuery: getAuthorizationStructureLoaderQuery,
  serviceQuery: getAuthorizationStructureQuery,
  serviceQueryKeys: getAuthorizationStructureKeys,
  useServiceQueryHook: useGetAuthorizationStructureQuery,
} = createServiceQuery(Core.GetAuthorizationStructure);

// Logout
export const logoutService = createEVAService(Core.Logout);
