import { IBoundingRectangle } from "~/hooks/suite-react-hooks/use-bounding-rectangle";

const INITIAL_BOUNDING_RECTANGLE: IBoundingRectangle = {
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  width: 0,
  height: 0,
};

export const useBoundingRectangleWithScroll = <T extends HTMLElement>(domElement: T | null) => {
  const getBoundingRectangle = () => {
    if (!domElement) {
      return INITIAL_BOUNDING_RECTANGLE;
    }

    const elementBoundingBox = domElement.getBoundingClientRect();

    return {
      top: elementBoundingBox.top + window.scrollY,
      right: elementBoundingBox.right + window.scrollX,
      left: elementBoundingBox.left + window.scrollX,
      bottom: elementBoundingBox.bottom + window.scrollY,
      width: elementBoundingBox.width,
      height: elementBoundingBox.height,
    };
  };

  const boundingRectangle = getBoundingRectangle();

  return boundingRectangle;
};
