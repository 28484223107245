import { useMemo } from "react";

import { Core } from "@springtree/eva-services-core";

import { getAvailableCurrenciesQuery } from "~/models/application";
import { ONE_HOUR } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateCurrencyLyraSearchListField = () =>
  SearchListFieldGenerator<Core.GetAvailableCurrencies, { ID: string; Name: string }, string>({
    getItemFromResponse: (response) =>
      response?.Currencies?.sort((a, b) => {
        return a.Name.localeCompare(b.Name);
      }) ?? [],
    getItemId: (item) => item.ID,
    getLabel: (item) => `${item.Name} (${item.ID})`,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.currency",
      defaultMessage: "Currency",
    }),
    useItemByID: (id, itemsFromList) => {
      const item = useMemo(
        () => itemsFromList?.find((item) => item.ID === id),
        [id, itemsFromList],
      );
      return { data: item, isLoading: false };
    },
    useItemsByID: (ids, itemsFromList) => {
      const items = useMemo(
        () =>
          ids
            ?.map((id) => itemsFromList?.find((item) => item.ID === id))
            ?.filter((item): item is { ID: string; Name: string } => !!item),
        [ids, itemsFromList],
      );
      return { data: items, isLoading: false };
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: getAvailableCurrenciesQuery,
        initialRequest: {},
        refetchOnFocus: false,
        staleTime: ONE_HOUR,
      }),
  });
