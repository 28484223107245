import { useField } from "formik";

import { Switch, SwitchProps } from "~/components/ui/switch";

interface IFormikSwitchProps extends Omit<SwitchProps, "value" | "onChange"> {
  name: string;
}

export const FormikSwitch = ({ name, ...props }: IFormikSwitchProps) => {
  const [field, , { setValue }] = useField<boolean | undefined>(name);
  return (
    <Switch
      {...props}
      {...field}
      checked={field.value ?? false}
      onChange={(newValue) => setValue(newValue)}
    />
  );
};
