import { useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Button, Separator } from "@new-black/lyra";
import { useRecoilState } from "recoil";

import CustomQuery from "../products-overview/custom-query";

import { FullHeightGrid, Heading } from "./helper-components";
import ProductSetListItem from "./product-set-list-item";
import ProductsList from "./products-list";
import { ProductSetIncludedProductsAtom } from "./state";

import Grid from "~/components/suite-ui/grid";
import LinkButton from "~/components/suite-ui/link-button";
import useFuseInstance from "~/hooks/use-fuse-instance";

export interface IProductSetIncludedProducts {
  onSave: () => void;
  onCancel: () => void;
  placeholderImageUrl?: string;
  isLoading?: boolean;
  listItemSize: number;
  name?: string;
  form?: string;
  value?: any;
  showQuantity?: boolean;
  showBarcodeInput?: boolean;
  enableQueryFilter?: boolean;
  enableClearAllProductsButton?: boolean;
}

const ProductSetIncludedProducts = ({
  enableClearAllProductsButton,
  enableQueryFilter,
  form,
  isLoading,
  listItemSize,
  name,
  onCancel,
  onSave,
  placeholderImageUrl,
  showBarcodeInput,
  showQuantity,
  value,
}: IProductSetIncludedProducts) => {
  const [includedProducts, setIncludedProducts] = useRecoilState(ProductSetIncludedProductsAtom);

  const [query, setQuery] = useState<string>();

  const fuseInstance = useFuseInstance(
    ["ID", "BackendID", "CustomID", "Name", "Barcode"],
    includedProducts,
  );

  const filteredIncludedProducts = useMemo(() => {
    if (fuseInstance && query) {
      return fuseInstance.search(query).map((match) => match.item);
    }

    return includedProducts;
  }, [query, fuseInstance, includedProducts]);

  // the virtualized list needs to know how much space it has available so we store the height of it's parent here
  const [listHeight, setListHeight] = useState(0);

  return (
    <FullHeightGrid container direction="column" wrap="nowrap">
      <Grid item>
        <div className="flex flex-col justify-between p-5 md:flex-row">
          <Heading variant="h2">
            <FormattedMessage
              id="product-set-modal.selected-products.title"
              defaultMessage="Selection"
            />
          </Heading>

          <>
            {enableQueryFilter && includedProducts?.length ? (
              <CustomQuery query={query} updateQuery={setQuery} />
            ) : null}

            {enableClearAllProductsButton && includedProducts?.length ? (
              <LinkButton onClick={() => setIncludedProducts([])} className="!text-[13px]">
                <FormattedMessage
                  id="generic.label.clear-all-products"
                  defaultMessage="Clear all products"
                />
              </LinkButton>
            ) : null}
          </>
        </div>
      </Grid>
      <Separator />
      <Grid item xs innerRef={(instance) => setListHeight(instance?.clientHeight ?? 0)}>
        <ProductsList
          products={filteredIncludedProducts}
          listHeight={listHeight}
          itemSize={listItemSize}
          itemComponent={(props) => (
            <ProductSetListItem
              {...props}
              onDelete={(ID) =>
                setIncludedProducts((current) => current.filter((item) => item.ID !== ID))
              }
              listHeight={listHeight}
              itemSize={listItemSize}
              placeholderImageUrl={placeholderImageUrl}
              showQuantity={showQuantity}
              onAddBarcode={(productID, barcode) =>
                setIncludedProducts((current) =>
                  current.map((item) =>
                    item.ID === productID ? { ...item, Barcode: barcode } : item,
                  ),
                )
              }
              showBarcodeInput={showBarcodeInput}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Separator className="my-0" />
        <div className="p-5">
          <Grid container justifyContent="flex-end">
            <Grid item>
              <div className="mr-5">
                <Button onPress={onCancel} isDisabled={isLoading} variant="secondary">
                  <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
                </Button>
              </div>
            </Grid>
            <Grid item>
              <Button
                variant="primary"
                onPress={onSave}
                isLoading={isLoading}
                isDisabled={isLoading || !includedProducts?.length}
                type={form ? "submit" : "button"}
                name={name}
                form={form}
                value={value}
              >
                <FormattedMessage id="generic.label.save" defaultMessage="Save" />
              </Button>
            </Grid>
          </Grid>
        </div>
      </Grid>
    </FullHeightGrid>
  );
};

export default ProductSetIncludedProducts;
