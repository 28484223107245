import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Select } from "@new-black/lyra";

import { controlDocumentsDefaultMessages } from "~/types/default-messages-enums/control-document-types-default-messages";
import { ControlDocumentTypes } from "~/types/translations-enums";

interface ControlDocumentsTypeSelectProps {
  value?: ControlDocumentTypes;
  onChange?: (value?: ControlDocumentTypes) => void;
  name?: string;
  label?: string;
}

export const ControlDocumentsTypeSelect = ({
  label,
  name,
  onChange,
  value,
}: ControlDocumentsTypeSelectProps) => {
  const intl = useIntl();

  const items = useMemo(() => {
    return [
      {
        label: intl.formatMessage({
          id: "control-document.type.2",
          defaultMessage: controlDocumentsDefaultMessages[ControlDocumentTypes.ControlDocument],
        }),
        value: ControlDocumentTypes.ControlDocument,
      },
      {
        label: intl.formatMessage({
          id: "control-document.type.3",
          defaultMessage:
            controlDocumentsDefaultMessages[ControlDocumentTypes.InitializationDocument],
        }),
        value: ControlDocumentTypes.InitializationDocument,
      },
      {
        label: intl.formatMessage({
          id: "control-document.type.4",
          defaultMessage: controlDocumentsDefaultMessages[ControlDocumentTypes.ClosingDocument],
        }),
        value: ControlDocumentTypes.ClosingDocument,
      },
      {
        label: intl.formatMessage({
          id: "control-document.type.5",
          defaultMessage:
            controlDocumentsDefaultMessages[ControlDocumentTypes.MonthlyControlDocument],
        }),
        value: ControlDocumentTypes.MonthlyControlDocument,
      },
      {
        label: intl.formatMessage({
          id: "control-document.type.6",
          defaultMessage:
            controlDocumentsDefaultMessages[ControlDocumentTypes.YearlyControlDocument],
        }),
        value: ControlDocumentTypes.YearlyControlDocument,
      },
      {
        label: intl.formatMessage({
          id: "control-document.type.7",
          defaultMessage:
            controlDocumentsDefaultMessages[ControlDocumentTypes.IntegrityCheckDocument],
        }),
        value: ControlDocumentTypes.IntegrityCheckDocument,
      },
      {
        label: intl.formatMessage({
          id: "control-document.type.8",
          defaultMessage: controlDocumentsDefaultMessages[ControlDocumentTypes.Unknown],
        }),
        value: ControlDocumentTypes.Unknown,
      },
    ];
  }, [intl]);

  const itemValue = useMemo(() => {
    if (value === undefined) {
      return undefined;
    }

    const item = items.find((item) => item.value === value);

    return item ? item : undefined;
  }, [value, items]);

  return (
    <Select
      items={items}
      getItemId={(item) => item.value.toString()}
      getLabel={(item) => item.label}
      selectRenderElements={(item) => ({ label: item.label })}
      hideHintLabel
      label={
        label ??
        intl.formatMessage({
          id: "generic.label.type",
          defaultMessage: "Type",
        })
      }
      value={itemValue}
      onChange={(newValue) => onChange?.(newValue?.value as number | undefined)}
      name={name ?? "controlDocumentsType"}
    />
  );
};
