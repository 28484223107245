import { useMemo } from "react";

import { IWorkspaceSelectorItem } from "~/components/shared/menu/workspace/workspace-selector";

const useWorkspaceSelectorItems = (
  workspaces?: IWorkspaceSelectorItem[],
  selectedWorkspaceID?: IWorkspaceSelectorItem["ID"],
  workspacesVisible?: boolean,
) => {
  const workspaceSelectorItems = useMemo<IWorkspaceSelectorItem[]>(
    () => (workspacesVisible && workspaces?.length ? workspaces : []),
    [workspaces, workspacesVisible],
  );

  const selectedWorkspaceSelectorItem = useMemo(
    () => workspaceSelectorItems.find((workspace) => workspace.ID === selectedWorkspaceID),
    [selectedWorkspaceID, workspaceSelectorItems],
  );

  return { workspaceSelectorItems, selectedWorkspaceSelectorItem };
};

export default useWorkspaceSelectorItems;
