import { Blobs } from "@springtree/eva-services-blobs";

import { createServiceQuery } from "~/util/query";

export const { serviceLoaderQuery: getBlobInfoLoaderQuery, serviceQuery: getBlobInfoQuery } =
  createServiceQuery(Blobs.GetBlobInfo, true);

export const {
  serviceLoaderQuery: listBlobsForStencilLoaderQuery,
  serviceQueryKeys: listBlobsForStencilQueryKeys,
  useServiceQueryHook: useListBlobsForStencilQuery,
} = createServiceQuery(Blobs.ListBlobsForStencil, false);
