import {
  DEPRECATED_SUPPLIER_PRICING_TYPE,
  PriceListAdjustmentType,
} from "~/features/price-lists/adjustments/price-list-adjustments.types";

export const priceListAdjustmentTypesDefaultMessages: Record<
  PriceListAdjustmentType | typeof DEPRECATED_SUPPLIER_PRICING_TYPE,
  string
> = {
  [PriceListAdjustmentType.Manual]: "Manual adjustment",
  [PriceListAdjustmentType.Pricelist]: "Existing pricelist",
  [PriceListAdjustmentType.Markup]: "Value and percentage change",
  [PriceListAdjustmentType.SupplierPricing]: "Supplier prices",
  [PriceListAdjustmentType.Set]: "Combined adjustments",
  [PriceListAdjustmentType.Rounding]: "Rounding",
  [DEPRECATED_SUPPLIER_PRICING_TYPE]: "Supplier prices",
};
