import { useMemo } from "react";

import { CustomFieldType } from "~/types/custom-field";

export const useEditableCustomFields = (
  customFieldType?: CustomFieldType,
  customFieldOptions?: EVA.Core.Management.GetCustomFieldsResponse["CustomFieldOptions"],
  customFieldMetadata?: EVA.Core.Management.GetCustomFieldMetadataResponse,
) => {
  const editableCustomFields = useMemo(
    () =>
      customFieldOptions && customFieldType !== undefined
        ? Object.keys(customFieldOptions).reduce((accumulator, current) => {
            const customFieldID = parseInt(current);
            const options = customFieldOptions[customFieldID];

            const metaData = customFieldMetadata?.CustomFieldsMetadata?.[customFieldType]?.find(
              (customField) => customField.CustomFieldID === customFieldID,
            );

            if (isNaN(customFieldID) || !options.IsEditable || !metaData) {
              return accumulator;
            }

            return [
              ...accumulator,
              {
                ...metaData,
                IsEditableByUser: options.IsEditable,
                Options: options,
              },
            ];
          }, [] as EVA.Core.CustomFieldResponse[])
        : [],
    [customFieldMetadata?.CustomFieldsMetadata, customFieldOptions, customFieldType],
  );

  return editableCustomFields;
};
