import { useGetCurrentUserQuery } from "~/models/users";
import { useRootRouteData } from "~/routes/root";

const useCurrentUser = () => {
  const { currentUser: currentUserQuery } = useRootRouteData();

  const { data } = useGetCurrentUserQuery(currentUserQuery?.request ?? {}, {
    ...currentUserQuery?.queryProps,
  });

  return data?.User;
};

export default useCurrentUser;
