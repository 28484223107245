import { useCallback, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, ButtonGroup, Separator } from "@new-black/lyra";
import { Form, FormikProps } from "formik";
import { produce } from "immer";

import { FormikInput } from "../../../formik-inputs";
import CollapseButton from "../../collapse-button";
import useRangePropertyValidationSchema from "../../hooks/use-range-property-validation-schema";
import { useProductFilterContext } from "../../product-filter-context";
import {
  PropertyClashConfirmationModal,
  usePropertyClashConfirmationModalState,
} from "../../property-clash-confirmation-modal";
import { IProductFilterModalValues } from "../../types";
import AdvancedSettings from "../advanced-settings";
import ProductPropertyField from "../product-property-field";

import { EVAFormik } from "~/components/suite-composite/eva-formik";

const DialogTabRange = () => {
  const intl = useIntl();
  const {
    currentProductFilterModalValues,
    isUpdateProductFilterLoading,
    productFilter,
    setCurrentProductFilterModalValues,
    setOpenProductFilterModal,
    updateProductFilter,
  } = useProductFilterContext();
  const [advancedSettingsShown, setAdvancedSettingsShown] = useState(
    currentProductFilterModalValues?.ExactMatch ||
      currentProductFilterModalValues?.IncludeMissing ||
      currentProductFilterModalValues?.Negation ||
      false,
  );

  const { propertyClashConfirmationModalState, setPropertyClashConfirmationModalState } =
    usePropertyClashConfirmationModalState();

  const initialValues = useMemo<IProductFilterModalValues>(
    () => ({
      keyName: currentProductFilterModalValues?.keyName,
      From: currentProductFilterModalValues?.From,
      To: currentProductFilterModalValues?.To,
      ExactMatch: currentProductFilterModalValues?.ExactMatch,
      Negation: currentProductFilterModalValues?.Negation,
      IncludeMissing: currentProductFilterModalValues?.IncludeMissing,
    }),
    [
      currentProductFilterModalValues?.ExactMatch,
      currentProductFilterModalValues?.From,
      currentProductFilterModalValues?.IncludeMissing,
      currentProductFilterModalValues?.Negation,
      currentProductFilterModalValues?.To,
      currentProductFilterModalValues?.keyName,
    ],
  );

  const validationSchema = useRangePropertyValidationSchema();

  const onSubmit = useCallback(
    (values: IProductFilterModalValues) => {
      const newProductFilter = produce(productFilter, (draft) => {
        // Delete old keyname if it's not the same anymore.
        if (
          initialValues.keyName &&
          draft?.[initialValues.keyName] &&
          initialValues.keyName !== values.keyName
        ) {
          delete draft?.[initialValues?.keyName];
        }

        if (!draft) {
          draft = {};
        }
        // constructing newly configered filter
        if (values.keyName) {
          draft[values.keyName] = {
            From: values.From,
            To: values.To,
            ExactMatch: values.ExactMatch,
            Negation: values.Negation,
            IncludeMissing: values.IncludeMissing,
            Values: undefined,
          };
        }
        return draft;
      });

      updateProductFilter(newProductFilter).then(() => {
        setCurrentProductFilterModalValues(undefined);
        setOpenProductFilterModal(false);
      });
    },
    [
      initialValues.keyName,
      productFilter,
      setCurrentProductFilterModalValues,
      setOpenProductFilterModal,
      updateProductFilter,
    ],
  );

  const checkClashingProperties = useCallback(
    (values: IProductFilterModalValues) => {
      // If the keyName is the same as the initial value, we don't need to check for clashing properties.
      if (initialValues.keyName !== values.keyName) {
        if (values.keyName && Object.keys(productFilter ?? {}).includes(values.keyName)) {
          const msg = intl.formatMessage(
            {
              id: "generic.question.overwrite-clashing-properties.singular",
              defaultMessage: "Are you sure you want to overwrite this property: {property}?",
            },
            { property: values.keyName },
          );
          setPropertyClashConfirmationModalState({
            show: true,
            message: msg,
            onSubmit: () => {
              onSubmit(values);
              setPropertyClashConfirmationModalState({ show: false });
            },
            onCancel: () => {
              setPropertyClashConfirmationModalState({ show: false });
            },
          });
          return;
        }
      }
      onSubmit(values);
    },
    [initialValues.keyName, intl, onSubmit, productFilter, setPropertyClashConfirmationModalState],
  );

  return (
    <>
      <EVAFormik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={checkClashingProperties}
        enableReinitialize
      >
        {(form: FormikProps<any>) => (
          <Form>
            <div className="p-5 pb-0">
              <ProductPropertyField />
              <FormikInput
                name="From"
                label={intl.formatMessage({
                  id: "product-filter.modal.range.input.from",
                  defaultMessage: "From",
                })}
              />
              <FormikInput
                name="To"
                label={intl.formatMessage({
                  id: "product-filter.modal.range.input.to",
                  defaultMessage: "To",
                })}
              />
            </div>
            <CollapseButton
              collapsedLabel={intl.formatMessage({
                id: "product-filter.modal.advanced-settings.collapsed",
                defaultMessage: "close",
              })}
              openLabel={intl.formatMessage({
                id: "product-filter.modal.advanced-settings.advanced-settings",
                defaultMessage: "advanced settings",
              })}
              state={advancedSettingsShown}
              onClick={() => {
                setAdvancedSettingsShown((current) => {
                  if (current) {
                    form.setFieldValue("ExactMatch", undefined);
                    form.setFieldValue("IncludeMissing", undefined);
                    form.setFieldValue("Negation", undefined);
                    return !current;
                  }
                  return !current;
                });
              }}
            />
            <AdvancedSettings shown={advancedSettingsShown} />
            <Separator />
            <ButtonGroup className="flex justify-end p-4">
              <Button
                variant="secondary"
                onPress={() => {
                  setCurrentProductFilterModalValues(undefined);
                  setOpenProductFilterModal(false);
                }}
                isDisabled={isUpdateProductFilterLoading}
              >
                <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
              </Button>
              <Button
                variant="primary"
                onPress={() => form.submitForm()}
                isLoading={isUpdateProductFilterLoading}
              >
                <FormattedMessage id="generic.button.save" defaultMessage="Save" />
              </Button>
            </ButtonGroup>
          </Form>
        )}
      </EVAFormik>
      <PropertyClashConfirmationModal {...propertyClashConfirmationModalState} />
    </>
  );
};

export default DialogTabRange;
