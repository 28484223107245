import { useEffect, useMemo, useState } from "react";

import { state } from "@springtree/eva-sdk-react-recoil";
import { useRecoilState, useRecoilValue } from "recoil";

import { selectedOrganizationUnitAtom } from "./state";

import { useSelectedOrganizationUnit } from "~/components/suite-composite/organization-unit-selector";

const useResetOrganizationUnitHeader = (useOUSelector?: boolean) => {
  const [latestSelectedOUID, setLatestSelectedOU] = useRecoilState(selectedOrganizationUnitAtom);
  const [isRestored, setIsRestored] = useState(false);
  const currentUserResponse = useRecoilValue(state.currentUser.currentUserState.response);
  const { resetSelectedOrganization, selectedOrganizationUnit, setSelectedOrganizationUnitById } =
    useSelectedOrganizationUnit();
  const sdkOrganizationUnitID = useRecoilValue(state.core.requestedOrganizationUnitIdState);

  // If the selector is NOT being used, revert back the user OU
  useEffect(() => {
    if (
      !useOUSelector &&
      currentUserResponse?.User.CurrentOrganizationID &&
      selectedOrganizationUnit?.ID !== currentUserResponse?.User.CurrentOrganizationID
    ) {
      // Remember which OU was selected in order to restore it later
      setLatestSelectedOU(sdkOrganizationUnitID);

      // Revert to user OU
      resetSelectedOrganization();
    }
  }, [
    currentUserResponse?.User.CurrentOrganizationID,
    resetSelectedOrganization,
    sdkOrganizationUnitID,
    selectedOrganizationUnit?.ID,
    setLatestSelectedOU,
    useOUSelector,
  ]);

  // If the selector is being used, revert back to the OU that was previously selected in the dropdown
  useEffect(() => {
    if (useOUSelector && !isRestored) {
      if (
        latestSelectedOUID &&
        selectedOrganizationUnit?.ID === currentUserResponse?.User.CurrentOrganizationID &&
        selectedOrganizationUnit?.ID !== latestSelectedOUID
      ) {
        setSelectedOrganizationUnitById(latestSelectedOUID);
      }

      if (selectedOrganizationUnit?.ID === latestSelectedOUID) {
        setLatestSelectedOU(undefined);
        setIsRestored(true);
      }
    }
  }, [
    currentUserResponse?.User.CurrentOrganizationID,
    isRestored,
    latestSelectedOUID,
    selectedOrganizationUnit?.ID,
    setLatestSelectedOU,
    setSelectedOrganizationUnitById,
    useOUSelector,
  ]);

  const isReset = useMemo(
    () =>
      (useOUSelector &&
        (!latestSelectedOUID || isRestored || sdkOrganizationUnitID === latestSelectedOUID)) ||
      (!useOUSelector &&
        (!sdkOrganizationUnitID ||
          sdkOrganizationUnitID === currentUserResponse?.User.CurrentOrganizationID)),
    [
      useOUSelector,
      latestSelectedOUID,
      isRestored,
      sdkOrganizationUnitID,
      currentUserResponse?.User.CurrentOrganizationID,
    ],
  );

  return isReset;
};

export default useResetOrganizationUnitHeader;
