import { useCallback } from "react";
import { NavLinkProps } from "react-router-dom";

import { tabStyles } from "@new-black/lyra";

import { DefinedRouteNavLink, DefinedRouteNavLinkProps, NavLink } from "~/components/routing";

export interface NavLinkButtonProps extends NavLinkProps {
  isDisabled?: boolean;
}

export const NavLinkButton = ({ className, isDisabled, ...props }: NavLinkButtonProps) => {
  const { tab } = tabStyles();
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (isDisabled) e.preventDefault();
    },
    [isDisabled],
  );

  return (
    <NavLink
      className={({ isActive, isPending, isTransitioning }) =>
        tab({
          isCurrent: isActive,
          orientation: "horizontal",
          isPending,
          isTransitioning,
          isDisabled,
          className:
            typeof className === "function"
              ? className({ isActive, isPending, isTransitioning })
              : className,
        })
      }
      onClick={handleClick}
      aria-disabled={isDisabled}
      {...props}
    />
  );
};

export const DefinedRouteNavLinkButton = ({
  className,
  isDisabled,
  ...props
}: DefinedRouteNavLinkProps & { isDisabled?: boolean }) => {
  const { tab } = tabStyles();
  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>) => {
      if (isDisabled) e.preventDefault();
    },
    [isDisabled],
  );

  return (
    <DefinedRouteNavLink
      className={({ isActive, isPending, isTransitioning }) =>
        tab({
          isCurrent: isActive,
          orientation: "horizontal",
          isPending,
          isTransitioning,
          isDisabled,
          className:
            typeof className === "function"
              ? className({ isActive, isPending, isTransitioning })
              : className,
        })
      }
      aria-disabled={isDisabled}
      onClick={handleClick}
      {...props}
    />
  );
};
