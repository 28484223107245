import { useRef } from "react";
import { FormattedMessage } from "react-intl";

import { Separator, Text } from "@new-black/lyra";
import { useVirtualizer } from "@tanstack/react-virtual";

import { LyraProductSummary } from "~/components/suite-composite/product-summary";
import { IProduct } from "~/types/product";

type SelectedProductsProps = {
  selectedProducts: IProduct[];
  setProductIDs: (newValues: number[] | undefined) => void;
};

export const SelectedProducts = ({ selectedProducts, setProductIDs }: SelectedProductsProps) => {
  const parentRef = useRef<HTMLDivElement>(null);

  const count = selectedProducts.length;
  const virtualizer = useVirtualizer({
    count,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 66,
  });

  const items = virtualizer.getVirtualItems();

  if (selectedProducts && count) {
    return (
      <div
        ref={parentRef}
        style={{
          overflowY: "auto",
          height: Math.min(336, virtualizer.getTotalSize() ?? 0),
          contain: "strict",
        }}
      >
        <div className="relative w-full" style={{ height: virtualizer.getTotalSize() }}>
          <div
            className="absolute start-0 top-0 w-full"
            style={{
              transform: `translateY(${items[0]?.start ?? 0}px)`,
            }}
          >
            {items.map(({ index, key }) => {
              const product = selectedProducts[index];
              return (
                <div
                  key={key}
                  data-index={index}
                  ref={virtualizer.measureElement}
                  className="start-0 top-0 w-full"
                >
                  <LyraProductSummary
                    productName={product.display_value ?? "-"}
                    productImageBlobID={product.primary_image?.blob}
                    productID={product.product_id}
                    backendID={product.backend_id}
                    customID={product.custom_id}
                    expandableProductName
                    className="px-4 py-2"
                    onDeleteProduct={(id) => {
                      const newProductIds = selectedProducts
                        .filter((product) => product.product_id !== id)
                        .map((product) => product.product_id);

                      setProductIDs(newProductIds.length ? newProductIds : undefined);
                    }}
                  />
                  {index !== items.length - 1 ? <Separator /> : null}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="p-4">
        <Text>
          <FormattedMessage
            id="generic.label.no-products-selected"
            defaultMessage="No products selected"
          />
        </Text>
      </div>
    </>
  );
};
