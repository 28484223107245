import { Skeleton } from "@material-ui/lab";
import { Card } from "@new-black/lyra";
import classNames from "clsx";

import { Pane } from "../pane";

import LoadingStateBox from "~/components/shared/loading-state-box";

interface IPaneFallback {
  centered?: boolean;
  /** Show placeholder loading box for breadcrumbs. Defaults to `true`  */
  showBreadcrumbsPlaceholder?: boolean;
}

export const PaneFallback = ({ centered, showBreadcrumbsPlaceholder = true }: IPaneFallback) => {
  return (
    <Pane>
      <div className={classNames("p-5", centered && "mx-auto max-w-[960px]")}>
        {showBreadcrumbsPlaceholder ? (
          <Skeleton className="mb-5" variant="rect" width="320px" height="36px" />
        ) : null}
        <Card className="pt-5">
          <LoadingStateBox limit={8} headerAvailable footerAvailable />
        </Card>
      </div>
    </Pane>
  );
};
