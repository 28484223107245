import { useCallback } from "react";
import { useIntl } from "react-intl";

import { IPageConfiguration } from "../workspaces.types";

import useManageWorkspace from "./use-manage-workspace";

import {
  DEFAULT_WORKSPACE_ID,
  IWorkspaceSelectorItem,
} from "~/components/shared/menu/workspace/workspace-selector";
import { toast } from "~/components/suite-ui/toast";

export const useWorkspaceHandlers = <TWorkspace>(
  applyWorkspaceSettings: (workspaceSettings: string) => void,
  setSelectedWorkspaceId: (id: IWorkspaceSelectorItem["ID"]) => void,
  refreshWorkspaces: () => void,
  getNextWorkspace: (
    keyName: string,
    workspaces: IWorkspaceSelectorItem[],
  ) => IWorkspaceSelectorItem,
  currentPageConfiguration?: IPageConfiguration<TWorkspace>,
  workspaceState?: Partial<TWorkspace>,
  defaultWorkspaceSettings?: { [key: string]: any },
  selectedWorkspaceID?: IWorkspaceSelectorItem["ID"],
  keyName?: string,
  workspaces?: IWorkspaceSelectorItem[],
) => {
  const intl = useIntl();

  const {
    createSharedWorkspace,
    createWorkspace,
    deleteSharedWorkspace,
    deleteWorkspace,
    getSharedWorkspaceByID,
    setActiveWorkspace,
    setDefaultSharedWorkspaceForRoles,
    updateSharedWorkspace,
    updateWorkspace,
    updateWorkspaceRoles,
  } = useManageWorkspace();

  const changeWorkspace = useCallback(
    async (workspace: IWorkspaceSelectorItem) => {
      setSelectedWorkspaceId(workspace.ID);
      applyWorkspaceSettings(workspace.Settings);

      if (typeof workspace.ID === "number") {
        await setActiveWorkspace()({ ID: workspace.ID });
      }
    },
    [applyWorkspaceSettings, setActiveWorkspace, setSelectedWorkspaceId],
  );

  const updateWorkspaceSettings = useCallback(
    (workspace: IWorkspaceSelectorItem, settings: Partial<TWorkspace>) => {
      if (typeof workspace.ID === "number") {
        const updateWorkspaceMutation = workspace.IsShared
          ? updateSharedWorkspace
          : updateWorkspace;

        return updateWorkspaceMutation({
          ID: workspace.ID,
          Name: workspace.Name,
          Settings: JSON.stringify({ ...settings }),
        });
      }
    },
    [updateSharedWorkspace, updateWorkspace],
  );

  const handleSetSelectedWorkspace = useCallback(
    async (workspace: IWorkspaceSelectorItem) => {
      if (typeof workspace.ID === "number") {
        await changeWorkspace(workspace);
      }
    },
    [changeWorkspace],
  );

  const handleDeleteWorkspace = useCallback(
    async (workspace: IWorkspaceSelectorItem) => {
      if (typeof workspace.ID === "number" && workspaces?.length && keyName) {
        const deleteWorkspaceMutation = workspace.IsShared
          ? deleteSharedWorkspace
          : deleteWorkspace;

        // Changing to another workspace before deletion is required on shared workspaces
        // since, in that case, it is allowed to delete your active workspace
        if (workspace.IsShared && workspace.ID === selectedWorkspaceID) {
          const nextWorkspace = getNextWorkspace(
            keyName,
            workspaces?.filter((item) => item.ID !== workspace.ID),
          );

          await changeWorkspace(nextWorkspace);
        }

        const result = await deleteWorkspaceMutation({ ID: workspace.ID });

        if (!result?.response?.Error) {
          refreshWorkspaces();

          return { success: true };
        }
      }

      return { success: false };
    },
    [
      changeWorkspace,
      deleteSharedWorkspace,
      deleteWorkspace,
      getNextWorkspace,
      keyName,
      refreshWorkspaces,
      selectedWorkspaceID,
      workspaces,
    ],
  );

  const handleSaveWorkspace = useCallback(
    async (workspace: IWorkspaceSelectorItem) => {
      if (workspaceState && currentPageConfiguration) {
        const result = await updateWorkspaceSettings(workspace, workspaceState);

        if (!result?.response?.Error) {
          refreshWorkspaces();
        }

        return { success: true };
      }

      return { success: false };
    },
    [currentPageConfiguration, refreshWorkspaces, updateWorkspaceSettings, workspaceState],
  );

  const handleAddWorkspace = useCallback(
    async (workspaceName: string, workspaceSettings?: string, fromDefaultSettings?: boolean) => {
      if (keyName && workspaceState) {
        const workspaceToCreate = {
          Name: workspaceName,
          Key: keyName,
          Settings:
            workspaceSettings ||
            (fromDefaultSettings
              ? JSON.stringify({ ...defaultWorkspaceSettings })
              : JSON.stringify({ ...workspaceState })),
        };

        const result = await createWorkspace()(workspaceToCreate);

        if (result?.response?.ID) {
          refreshWorkspaces();

          await changeWorkspace({
            ...workspaceToCreate,
            ID: result.response.ID,
            IsActive: true,
            IsShared: false,
            RolesWithDefault: [],
            Key: keyName,
          });

          return { success: true };
        }
      }

      return { success: false };
    },
    [
      changeWorkspace,
      createWorkspace,
      defaultWorkspaceSettings,
      keyName,
      refreshWorkspaces,
      workspaceState,
    ],
  );

  const editSharePermissions = useCallback(
    async (workspaceID: number, roleIDs: number[], defaultRoleIDs: number[], toastID?: string) => {
      const notDefaultRoles = roleIDs.filter((id) => !defaultRoleIDs.includes(id));

      let setDefaultRolesResult: { success: boolean } | undefined = { success: true };
      let setNotDefaultRolesResult: { success: boolean } | undefined = { success: true };

      if (defaultRoleIDs.length) {
        setDefaultRolesResult = await setDefaultSharedWorkspaceForRoles(true)({
          ID: workspaceID,
          RoleIDs: defaultRoleIDs,
          IsDefault: true,
        });
      }

      if (notDefaultRoles.length) {
        setNotDefaultRolesResult = await setDefaultSharedWorkspaceForRoles(true)({
          ID: workspaceID,
          RoleIDs: notDefaultRoles,
          IsDefault: false,
        });
      }

      if (!setDefaultRolesResult?.success || !setNotDefaultRolesResult?.success) {
        toast.error(
          intl.formatMessage({
            id: "workspaces.shared.set-default-roles.error",
            defaultMessage: "An error occured while setting the default workspaces for roles.",
          }),
          toastID ? { id: toastID } : undefined,
        );
      } else {
        toast.success(
          intl.formatMessage({
            id: "workspaces.shared.set-default-roles.success",
            defaultMessage: "Default workspaces for roles successfully updated.",
          }),
          toastID ? { id: toastID } : undefined,
        );

        return { success: true };
      }
    },
    [intl, setDefaultSharedWorkspaceForRoles],
  );

  const handleEditSharePermissions = useCallback(
    async (workspaceID: number, roleIDs: number[], defaultRoleIDs: number[]) => {
      const toastID = toast.loading(
        intl.formatMessage({ id: "generic.label.loading", defaultMessage: "Loading..." }),
      );

      const result = await updateWorkspaceRoles(workspaceID, roleIDs);

      if (typeof result === "string") {
        toast.error(result, { id: toastID });

        return { success: false };
      }

      if (result?.addRolesResponse?.success && result?.removeRolesResponse?.success) {
        await editSharePermissions(workspaceID, roleIDs, defaultRoleIDs, toastID);
      }

      // If at least one of adding / removing roles requests is successfull, refresh the workspaces
      if (result?.addRolesResponse?.success || result?.removeRolesResponse?.success) {
        refreshWorkspaces();
      }

      return { success: false };
    },
    [editSharePermissions, intl, refreshWorkspaces, updateWorkspaceRoles],
  );

  const handleAddSharedWorkspace = useCallback(
    async (
      workspaceName: string,
      roleIDs: number[],
      defaultRoleIDs: number[],
      fromDefaultSettings?: boolean,
    ) => {
      if (keyName && workspaceState) {
        const createSharedWorkspaceResult = await createSharedWorkspace({
          Key: keyName,
          Name: workspaceName,
          Settings: fromDefaultSettings
            ? JSON.stringify({ ...defaultWorkspaceSettings })
            : JSON.stringify({ ...workspaceState }),
          RoleIDs: roleIDs,
        });

        if (
          createSharedWorkspaceResult?.response?.ID &&
          !createSharedWorkspaceResult?.response?.Error
        ) {
          const toastID = toast.loading(
            intl.formatMessage({ id: "generic.label.loading", defaultMessage: "Loading..." }),
          );

          await editSharePermissions(
            createSharedWorkspaceResult?.response?.ID,
            roleIDs,
            defaultRoleIDs,
            toastID,
          );

          refreshWorkspaces();

          if (workspaces?.length === 1 && workspaces[0]?.ID === DEFAULT_WORKSPACE_ID) {
            const newWorkspace = await getSharedWorkspaceByID({
              ID: createSharedWorkspaceResult.response.ID,
            });

            if (newWorkspace?.response) {
              await changeWorkspace({ ...newWorkspace.response, IsShared: true, IsActive: false });
            }
          }

          return { success: true };
        }
      }

      return { success: false };
    },
    [
      changeWorkspace,
      createSharedWorkspace,
      defaultWorkspaceSettings,
      editSharePermissions,
      getSharedWorkspaceByID,
      intl,
      keyName,
      refreshWorkspaces,
      workspaceState,
      workspaces,
    ],
  );

  return {
    handleAddSharedWorkspace,
    handleDeleteWorkspace,
    handleEditSharePermissions,
    handleSaveWorkspace,
    handleSetSelectedWorkspace,
    handleAddWorkspace,
  };
};
