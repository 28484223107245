import { IBaseProductAutocompleteItem } from "~/components/suite-composite/product-autocomplete";
import {
  EEvaDiscountProductSetType,
  IEvaDiscountProductFilter,
  IEvaDiscountProductSet,
  IEvaDiscountProductSetProductRequirement,
} from "~/features/discount-edit/edit-discount.types";

export interface IEvaProductSet {
  productSet: IEvaDiscountProductSet[];
  productSetUpdated: (set: IEvaDiscountProductSet[]) => void;
  productSetValid?: (valid: boolean) => void;
  title?: string;
  max?: number;
  min?: number;
  initWithEmpyProductSet?: boolean;
  passive?: boolean;
}

export interface IEvaProductSetOptions {
  requiredProductSetName?: boolean;
  requiredAmountOption?: boolean;
  requiredAmountSpentOption?: boolean;
  amountOfProductsOption?: boolean;
  defaultProductOption?: boolean;
  isDiscountableProductSetOption?: boolean;
  productRequirementsOption?: boolean;
}

export interface IUpdateEvaProductSetOptions {
  filters?: IEvaDiscountProductFilter[];
  setName?: string;
  requiredQuantityOfProducts?: number;
  requiredAmountSpentOnProducts?: number;
  isDiscountableProductSet?: boolean;
  discountableQuantityOfProducts?: number;
  defaultProduct?: IBaseProductAutocompleteItem;
  personalizedPromotionID?: number;
  productSearchID?: number;
  productIDs?: number[];
  type?: EEvaDiscountProductSetType;
  productRequirements?: IEvaDiscountProductSetProductRequirement[];
}

export interface ProductSetItem {
  key: string;
  isValid: boolean;
  type: EEvaDiscountProductSetType;
  set: IEvaDiscountProductSet;
}

export enum IRequiredAmountRadioOption {
  QuantityOfProducts = "QuantityOfProducts",
  AmountSpentOnProduct = "AmountSpentOnProduct",
}
