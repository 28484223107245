import { z } from "zod";
import { zfd } from "zod-form-data";

import { productSetSchema } from "~/types/product-sets";
import { intlAccessor } from "~/util/intl-accessor";

export const loyaltyProgramRuleProductSetConditionSchema = (requireProductSets = true) =>
  z
    .object({
      Name: z.string(),
      ProductsSets: zfd.json(
        requireProductSets ? z.array(productSetSchema) : z.array(productSetSchema).optional(),
      ),
      RequiredQuantityOfProducts: zfd.numeric(z.number().optional()),
      RequiredProductAmount: zfd.numeric(z.number().optional()),
      IdenticalProductsOnly: zfd.checkbox(),
    })
    // at least one of RequiredProductAmount or RequiredQuantityOfProducts is required
    .refine(
      ({ RequiredProductAmount, RequiredQuantityOfProducts }) =>
        RequiredProductAmount || RequiredQuantityOfProducts,
      {
        message: intlAccessor.formatMessage({
          id: "generic.label.error.required",
          defaultMessage: "This field is required",
        }),
        path: ["RequiredProductAmount"],
      },
    )
    .refine(
      ({ RequiredProductAmount, RequiredQuantityOfProducts }) =>
        RequiredProductAmount || RequiredQuantityOfProducts,
      {
        message: intlAccessor.formatMessage({
          id: "generic.label.error.required",
          defaultMessage: "This field is required",
        }),
        path: ["RequiredQuantityOfProducts"],
      },
    );

export const loyaltyProgramRuleProductSetConditionKeys =
  loyaltyProgramRuleProductSetConditionSchema().innerType().innerType().keyof().Values;

export type TLoyaltyProgramRuleProductSetCondition = z.infer<
  ReturnType<typeof loyaltyProgramRuleProductSetConditionSchema>
>;

export type TLoyaltyProgramRuleProductSetConditionErrors = Partial<
  Record<keyof TLoyaltyProgramRuleProductSetCondition, string>
>;
