import { PaymentCore } from "@springtree/eva-services-payment-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getUserCardsForUserLoaderQuery,
  serviceQuery: getUserCardsForUserQuery,
  serviceQueryKeys: getUserCardsForUserQueryKeys,
  useServiceQueryHook: useGetUserCardsForUserQuery,
} = createServiceQuery(PaymentCore.GetUserCardsForUser);

export const {
  serviceLoaderQuery: listUserCardsMutationsLoaderQuery,
  serviceQuery: listUserCardsMutationsQuery,
  serviceQueryKeys: listUserCardsMutationsQueryKeys,
  useServiceQueryHook: useListUserCardMutationsQuery,
  useServiceQueryWithRequest: useListUserCardMutationsQueryWithRequest,
} = createServiceQuery(PaymentCore.ListUserCardMutations);
