import { useMemo } from "react";

import { ScriptEditorLanguage } from "~/types/monaco-editor-language";

export const useScriptEditorDialectKeywords = (
  dialect: ScriptEditorLanguage,
  scriptDialects?: EVA.Core.GetScriptDialectsResponse,
) => {
  const dialectKeywords = useMemo(() => {
    const currentDialect = scriptDialects?.Dialects?.find(
      (scriptDialect) => scriptDialect.Name === dialect,
    );

    return currentDialect?.Keywords ?? [];
  }, [dialect, scriptDialects?.Dialects]);

  return dialectKeywords;
};
