import { useMemo } from "react";

import { useQuery } from "@tanstack/react-query";

import useGetCustomFieldTypes from "./use-get-custom-field-types";

import { getCustomFieldTypesQueryKeys } from "~/models/custom-fields";
import { CustomFieldType } from "~/types/custom-field";
import {
  getCustomFieldTypeID,
  getCustomFieldTypeIDLoaderHelper,
} from "~/util/get-custom-field-type-id";
import { queryClient } from "~/util/query-client";

const useCustomFieldsTypeId = (customFieldType: CustomFieldType) => {
  const { data: customFieldTypesResponse, isLoading } = useGetCustomFieldTypes();

  const customFieldTypeID = useMemo(
    () => getCustomFieldTypeID(customFieldType, customFieldTypesResponse),
    [customFieldType, customFieldTypesResponse],
  );

  return { customFieldTypeID, isLoading };
};

export default useCustomFieldsTypeId;

export function useGetCustomFieldTypeId(type: CustomFieldType) {
  const { data: customFieldTypeID, isFetching: isLoading } = useQuery({
    queryFn: async () => getCustomFieldTypeIDLoaderHelper(queryClient, type),
    queryKey: getCustomFieldTypesQueryKeys.withKey([type]),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });
  return { customFieldTypeID, isLoading };
}
