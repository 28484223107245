import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

// GetCurrentCashJournals
export const {
  serviceLoaderQuery: getCurrentCashJournalsLoaderQuery,
  serviceQuery: getCurrentCashJournalsQuery,
  serviceQueryKeys: getCurrentCashJournalsQueryKeys,
  useServiceQueryHook: useGetCurrentCashJournalsQuery,
  useServiceQueryWithRequest: useGetCurrentCashJournalsQueryWithRequest,
} = createServiceQuery(Core.GetCurrentCashJournals, true);

// GetCashJournalDenominations
export const {
  serviceLoaderQuery: getCashJournalDenominationsLoaderQuery,
  serviceQuery: getCashJournalDenominationsQuery,
  serviceQueryKeys: getCashJournalDenominationsQueryKeys,
  useServiceQueryHook: useGetCashJournalDenominationsQuery,
  useServiceQueryWithRequest: useGetCashJournalDenominationsQueryWithRequest,
} = createServiceQuery(Core.GetCashJournalDenominations);

// StartOpenCashJournal
export const {
  serviceLoaderQuery: startOpenCashJournalLoaderQuery,
  serviceQuery: startOpenCashJournalQuery,
  serviceQueryKeys: startOpenCashJournalQueryKeys,
  useServiceQueryHook: useStartOpenCashJournalQuery,
} = createServiceQuery(Core.StartOpenCashJournal, true);

// StartCloseCashJournal
export const {
  serviceLoaderQuery: startCloseCashJournalLoaderQuery,
  serviceQuery: startCloseCashJournalQuery,
  serviceQueryKeys: startCloseCashJournalQueryKeys,
  useServiceQueryHook: useStartCloseCashJournalQuery,
} = createServiceQuery(Core.StartCloseCashJournal, true);

// GetCashCorrectionsReasons
export const {
  serviceLoaderQuery: getCashCorrectionsReasonsLoaderQuery,
  serviceQuery: getCashCorrectionsReasonsQuery,
  serviceQueryKeys: getCashCorrectionsReasonsQueryKeys,
  useServiceQueryHook: useGetCashCorrectionsReasonsQuery,
} = createServiceQuery(Core.GetCashCorrectionReasons);

// GetCashExpenseTypes
export const {
  serviceLoaderQuery: getCashExpenseTypesLoaderQuery,
  serviceQuery: getCashExpenseTypesQuery,
  serviceQueryKeys: getCashExpenseTypesQueryKeys,
  useServiceQueryHook: useGetCashExpenseTypesQuery,
} = createServiceQuery(Core.GetCashExpenseTypes, true);
