import { FormattedMessage } from "react-intl";

import { Button, CardActions, CardHeader, SvgIcon, Text } from "@new-black/lyra";

export interface ProductInformationHeaderProps {
  onOpenCallback?: () => void;
}

const ProductInformationHeader = ({ onOpenCallback }: ProductInformationHeaderProps) => (
  <CardHeader
    className="-ml-5"
    title={
      <>
        <SvgIcon name="product-information" className="h-5 w-5" />
        <Text variant="heading-2">
          <FormattedMessage
            id="generic.label.product-information"
            defaultMessage="Product information"
          />
        </Text>
      </>
    }
    actions={
      onOpenCallback ? (
        <CardActions>
          <Button variant="icon" onPress={onOpenCallback}>
            <SvgIcon name="open-in-new-tab" />
          </Button>
        </CardActions>
      ) : null
    }
  />
);

export default ProductInformationHeader;
