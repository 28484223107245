import { useMemo } from "react";

import { useRecoilValue } from "recoil";

import {
  discountOrTemplateSelector,
  isDiscountTemplateAtom,
  templateIdForDiscountAtom,
} from "../edit-discount.state";

import useAllowedFunctionalities from "~/hooks/suite-react-hooks/use-allowed-functionalities";
import useParsedRouteParam from "~/hooks/suite-react-hooks/use-parsed-route-param";
import { Functionalities } from "~/types/functionalities";

const useIsDiscountEditDisabled = () => {
  const discountId = useParsedRouteParam();
  const disocuntDetails = useRecoilValue(discountOrTemplateSelector(discountId));
  const isDiscountTemplate = useRecoilValue(isDiscountTemplateAtom);
  const { editFunctionalityAllowed: editDiscountsAllowed } = useAllowedFunctionalities(
    Functionalities.DISCOUNTS,
  );
  const { editFunctionalityAllowed: editDiscountTemplatesAllowed } = useAllowedFunctionalities(
    Functionalities.DISCOUNTS_TEMPLATES,
  );

  // This value means the current flow is for creating a discount from a template
  const templateIdForDiscount = useRecoilValue(templateIdForDiscountAtom);

  // Cannot edit anything if it's an active template or a discount generated/being created from template or the user
  // does not have the edit functionality for the current flow (discount / discount template)
  const isEditDiscountDisabled = useMemo(
    () =>
      (isDiscountTemplate && (disocuntDetails?.isActiveInEVA || !editDiscountTemplatesAllowed)) ||
      (!isDiscountTemplate && !editDiscountsAllowed) ||
      disocuntDetails?.generatedFromTemplate ||
      !!templateIdForDiscount,
    [
      editDiscountTemplatesAllowed,
      editDiscountsAllowed,
      disocuntDetails?.isActiveInEVA,
      disocuntDetails?.generatedFromTemplate,
      isDiscountTemplate,
      templateIdForDiscount,
    ],
  );

  return isEditDiscountDisabled;
};

export default useIsDiscountEditDisabled;
