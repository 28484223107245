import { FormattedMessage } from "react-intl";

import { useSetRecoilState } from "recoil";

import { FullHeightGrid, Heading } from "../helper-components";
import { productSetModalFiltersHeightAtom } from "../state";
import { IProductSetFilter } from "../types";

import AvailableProductsList from "./available-products-list";
import ProductPropertyFiltersList from "./product-property-filters-list";

import Grid from "~/components/suite-ui/grid";
import LoadingStateBox from "~/components/suite-ui/loading-state-box";
import Text from "~/components/suite-ui/text";

export interface IProductFinder {
  productProperties: string[];
  filters: IProductSetFilter[];
  setFilters: (value: IProductSetFilter[]) => void;
  availableProductsLoading?: boolean;
  placeholderImageUrl?: string;
  disabledAddProductsButton?: boolean;
  subHeaderMessage?: string;
  querySearchValue?: string;
  setQuerySearchValue: React.Dispatch<React.SetStateAction<string | undefined>>;
  showQuantityInput?: boolean;
  hideSelectionColumn?: boolean;
}

const ProductSelector = ({
  availableProductsLoading,
  disabledAddProductsButton,
  filters,
  hideSelectionColumn,
  placeholderImageUrl,
  productProperties,
  querySearchValue,
  setFilters,
  setQuerySearchValue,
  showQuantityInput,
  subHeaderMessage,
}: IProductFinder) => {
  const setFiltersHeight = useSetRecoilState(productSetModalFiltersHeightAtom);

  return (
    <FullHeightGrid container direction="column" wrap="nowrap">
      <Grid item>
        <div className="p-5">
          <Heading variant="h2">
            <FormattedMessage
              id="product-set-modal.product-selector.title"
              defaultMessage="Add products"
            />
          </Heading>
        </div>
      </Grid>

      {subHeaderMessage ? (
        <Grid item className="px-5">
          <div className="pb-5">
            <Text variant="caption">{subHeaderMessage}</Text>
          </div>
        </Grid>
      ) : null}

      <Grid item innerRef={(instance) => setFiltersHeight(instance?.clientHeight ?? 0)}>
        <ProductPropertyFiltersList
          productProperties={productProperties}
          filters={filters}
          setFilters={setFilters}
          querySearchValue={querySearchValue}
          setQuerySearchValue={setQuerySearchValue}
        />
      </Grid>
      <Grid item xs>
        {availableProductsLoading ? (
          <LoadingStateBox limit={9} />
        ) : (
          <AvailableProductsList
            placeholderImageUrl={placeholderImageUrl}
            disabledAddProductsButton={disabledAddProductsButton}
            enlargedHeader={subHeaderMessage ? true : false}
            showQuantityInput={showQuantityInput}
            hideSelectionColumn={hideSelectionColumn}
          />
        )}
      </Grid>
    </FullHeightGrid>
  );
};

export default ProductSelector;
