import { useCallback, useMemo } from "react";

import { isEmpty } from "lodash";

import useAddressRequirements from "~/hooks/use-address-requirements";
import { useGetCountrySubdivisionsQuery, useListCountriesQuery } from "~/models/countries";

type AddressPartFields = (keyof EVA.Core.AddressDto)[];

const namePartFields: AddressPartFields = ["FirstName", "LastName"];
const part1Fields: AddressPartFields = ["Address1", "HouseNumber", "Address2"];
const part2Fields: AddressPartFields = ["ZipCode", "City", "State", "Region"];
const countryPartFields: AddressPartFields = ["CountryID"];

const useFormattedAddressParts = (
  address: Partial<EVA.Core.AddressDto> | undefined,
  fieldsSeparator = " ",
) => {
  const countries = useListCountriesQuery({ FetchAll: true }, { refetchOnMount: false });
  const {
    isFieldVisible,
    isLoading: isAddressRequirementsLoading,
    isLoadingWithoutResponse: isAddressRequirementsLoadingWithoutResponse,
  } = useAddressRequirements(address?.CountryID);
  const countrySubdivisions = useGetCountrySubdivisionsQuery(
    address?.CountryID ? { CountryID: address.CountryID } : undefined,
    { refetchOnMount: false },
  );

  const getFormattedField = useCallback(
    (field: keyof EVA.Core.AddressDto) => {
      switch (field) {
        case "CountryID":
          return (
            countries?.data?.Result?.find((country) => country.ID === address?.CountryID)?.Name ??
            address?.CountryID
          );
        case "Region":
          return (
            countrySubdivisions?.data?.CountrySubdivisions?.find(
              (subdivision) => subdivision.ID === address?.Region,
            )?.Name ?? address?.Region
          );
        default:
          return address?.[field];
      }
    },
    [address, countries?.data?.Result, countrySubdivisions?.data?.CountrySubdivisions],
  );

  const getAddressPart = useCallback(
    (fields: AddressPartFields, separator: string) => {
      return fields
        .map((field) => (isFieldVisible(field) ? getFormattedField(field) : undefined))
        .filter((formattedField) => !isEmpty(formattedField))
        ?.join(separator);
    },
    [getFormattedField, isFieldVisible],
  );

  const addressParts = useMemo(
    () => ({
      namePart: getAddressPart(namePartFields, " "),
      part1: getAddressPart(part1Fields, fieldsSeparator),
      part2: getAddressPart(part2Fields, fieldsSeparator),
      countryPart: getAddressPart(countryPartFields, fieldsSeparator),
    }),
    [fieldsSeparator, getAddressPart],
  );

  const isLoading = useMemo(
    () => isAddressRequirementsLoading || countrySubdivisions.isFetching,
    [countrySubdivisions.isFetching, isAddressRequirementsLoading],
  );
  const isLoadingWithoutResponse = useMemo(
    () => isAddressRequirementsLoadingWithoutResponse || countrySubdivisions.isLoading,
    [countrySubdivisions.isLoading, isAddressRequirementsLoadingWithoutResponse],
  );

  return { ...addressParts, isLoading, isLoadingWithoutResponse };
};

export default useFormattedAddressParts;
