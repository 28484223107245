import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listMessageTemplatesLoaderQuery,
  serviceQuery: listMessageTemplatesQuery,
  serviceQueryKeys: listMessageTemplatesQueryKeys,
  useServiceQueryHook: useListMessageTemplatesQuery,
} = createServiceQuery(CoreManagement.ListMessageTemplates);

export const {
  serviceLoaderQuery: getMessageTemplateByIDLoaderQuery,
  serviceQueryKeys: getMessageTemplateByIDQueryKeys,
  useServiceQueryHook: useGetMessageTemplateByIDQuery,
} = createServiceQuery(CoreManagement.GetMessageTemplateByID);

export const {
  serviceLoaderQuery: getDataModelForTemplateHandlerLoaderQuery,
  serviceQueryKeys: getDataModelForTemplateHandlerQueryKeys,
  useServiceQueryHook: useGetDataModelForTemplateHandlerQuery,
} = createServiceQuery(CoreManagement.GetDataModelForTemplateHandler, true);

export const { serviceQuery: listMessageTemplatesLayoutsQuery } = createServiceQuery(
  CoreManagement.ListMessageTemplateLayouts,
);

export const {
  serviceLoaderQuery: previewMessageTemplateLoaderQuery,
  serviceQueryKeys: previewMessageTemplateQueryKeys,
  useServiceQueryHook: usePreviewMessageTemplateQuery,
  useServiceQueryWithRequest: usePreviewMessageTemplateQueryWithRequest,
} = createServiceQuery(CoreManagement.PreviewMessageTemplate);
