import { IntlShape } from "react-intl";

import { OrderProperties } from "~/types/eva-core";
import enumToArray from "~/util/enum-to-array";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";
import { SearchListFieldGenerator } from "~/util/search-list-field-generator";

export interface IOrderPropertiesSearchListFieldItem {
  value: number;
  key: string;
}

export const generateOrderPropertiesSearchListField = (
  frontendFilter?: (value: IOrderPropertiesSearchListFieldItem) => boolean,
  intlObject?: IntlShape,
) =>
  SearchListFieldGenerator.LocalSearchListFieldGenerator<
    IOrderPropertiesSearchListFieldItem,
    "value"
  >({
    idKey: "value",
    labelKey: "key",
    defaultLabel: (intlObject ?? intlAccessor).formatMessage({
      id: "generic.label.order-properties",
      defaultMessage: "Order properties",
    }),
    useItemsByID: (ids, currentListItems) => ({
      data: currentListItems?.filter((entry) => ids?.includes(entry.value)),
    }),
    items: frontendFilter
      ? enumToArray(OrderProperties).filter(frontendFilter)
      : enumToArray(OrderProperties),
  });

export const generateOrderPropertiesLyraSearchListField = (
  frontendFilter?: (value: IOrderPropertiesSearchListFieldItem) => boolean,
  intlObject?: IntlShape,
) =>
  LyraSearchListFieldGenerator.LocalSearchListFieldGenerator<
    IOrderPropertiesSearchListFieldItem,
    number
  >({
    getItemId: (item) => item.value,
    getLabel: (item) => item.key,
    defaultLabel: (intlObject ?? intlAccessor).formatMessage({
      id: "generic.label.order-properties",
      defaultMessage: "Order properties",
    }),
    useItemsByID: (ids, currentListItems) => ({
      data: currentListItems?.filter((entry) => ids?.includes(entry.value)),
    }),
    items: frontendFilter
      ? enumToArray(OrderProperties).filter(frontendFilter)
      : enumToArray(OrderProperties),
  });
