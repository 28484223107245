import { ReactNode, useMemo } from "react";
import { FormattedMessage } from "react-intl";

import { Combobox } from "@headlessui/react";
import { Button, SvgIcon } from "@new-black/lyra";
import classnames from "clsx";

import {
  ISearchListFieldOptionsProps,
  SEARCH_LIST_FIELD_COMBOBOX_OPTION_HEIGHT,
  SEARCH_LIST_FIELD_COMBOBOX_OPTIONS_HEIGHT,
  TSearchListFieldKeyType,
} from "../types";

import ProgressBar from "~/components/suite-ui/progress-bar";
import Text from "~/components/suite-ui/text";

const SearchListFieldComboboxOption = <T,>({
  getSecondaryLabel,
  isOptionDisabled,
  item,
  labelKey,
  showOptionEndAdornment,
}: {
  item: T;
  labelKey: keyof T;
  getSecondaryLabel?: (item: T) => string | undefined;
  isOptionDisabled?: (item: T) => boolean;
  showOptionEndAdornment?: (item: T) => ReactNode;
}) => {
  const disabled = useMemo(() => isOptionDisabled?.(item), [isOptionDisabled, item]);

  const optionEndAdornment = useMemo(
    () => showOptionEndAdornment?.(item),
    [item, showOptionEndAdornment],
  );

  return (
    <Combobox.Option
      disabled={disabled}
      value={item}
      className={({ active }) =>
        classnames(
          "cursor-default select-none px-4 transition-colors",
          active && "bg-[#F6F6F6]",
          "active:bg-[rgba(0,123,255,.1)]",
        )
      }
      style={{ height: SEARCH_LIST_FIELD_COMBOBOX_OPTION_HEIGHT }}
    >
      {({ disabled, selected }) => (
        <span className="flex h-full items-center gap-4 text-[.8125rem]">
          <span className="flex h-5 w-5 shrink-0 items-center">
            {selected ? <SvgIcon name="alert-success" className="h-5 w-5 text-secondary" /> : null}
          </span>

          <div className="flex w-full min-w-0 items-center justify-between">
            <span className="flex min-w-0 flex-col">
              <Text
                color="textPrimary"
                className={classnames(
                  "truncate overflow-ellipsis",
                  disabled ? "text-[color:rgba(0,0,0,.3)]" : undefined,
                )}
              >{`${item?.[labelKey] ?? ""}`}</Text>

              {getSecondaryLabel ? (
                <Text
                  color="textSecondary"
                  variant="caption"
                  className={classnames(
                    "truncate overflow-ellipsis text-[.65rem]",
                    disabled && "text-[color:rgba(0,0,0,.3)]",
                  )}
                >
                  {`${getSecondaryLabel(item) ?? ""}`}
                </Text>
              ) : null}
            </span>

            {optionEndAdornment}
          </div>
        </span>
      )}
    </Combobox.Option>
  );
};

export const SearchListFieldComboboxOptions = <T,>({
  classNames,
  endOfListRef,
  getSecondaryLabel,
  inputRef,
  isLoading,
  isOptionDisabled,
  items,
  labelKey,
  loadMoreButtonRef,
  onLoadMore,
  optionsRef,
  showOptionEndAdornment,
  style,
  topOfListRef,
}: Pick<
  ISearchListFieldOptionsProps<T, TSearchListFieldKeyType<T>, TSearchListFieldKeyType<T>>,
  | "items"
  | "labelKey"
  | "getSecondaryLabel"
  | "isOptionDisabled"
  | "onLoadMore"
  | "showOptionEndAdornment"
> & {
  isLoading?: boolean;
  style?: React.CSSProperties;
  optionsRef: React.MutableRefObject<HTMLUListElement | null>;
  endOfListRef?: React.MutableRefObject<HTMLDivElement | null>;
  topOfListRef?: React.MutableRefObject<HTMLDivElement | null>;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  loadMoreButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
  classNames?: { options?: string };
}) => (
  <div className="relative h-full">
    <ProgressBar loading={isLoading ?? false} />

    <Combobox.Options
      ref={optionsRef}
      className={classnames(
        "my-0 h-fit w-full list-none overflow-y-auto rounded-b bg-surface-primary pl-0",
        classNames?.options,
      )}
      style={style}
      static
    >
      <div ref={topOfListRef} />

      {items?.length ? (
        items?.map((item, index) => (
          <SearchListFieldComboboxOption
            key={index}
            item={item}
            labelKey={labelKey}
            getSecondaryLabel={getSecondaryLabel}
            isOptionDisabled={isOptionDisabled}
            showOptionEndAdornment={showOptionEndAdornment}
          />
        ))
      ) : isLoading ? (
        <Text className="flex cursor-default select-none items-center gap-2 px-5 py-3 text-[.8125rem]">
          <span className="h-5 w-5"></span>

          <span>
            <FormattedMessage id="generic.label.loading" defaultMessage="Loading" />
          </span>
        </Text>
      ) : (
        <div
          style={{ height: SEARCH_LIST_FIELD_COMBOBOX_OPTIONS_HEIGHT }}
          className="flex flex-col items-center justify-center p-5"
        >
          <SvgIcon name="search" className="mb-5 h-20 w-20 text-tertiary" />
          <h3 className="text-legacy-base mb-2 text-center text-primary">
            <FormattedMessage
              id="chapter-finder.no-results"
              defaultMessage="Sorry, no matches were found."
            />
          </h3>
          <span className="text-center text-xs text-secondary">
            <FormattedMessage
              id="chapter-finder.no-results-description"
              defaultMessage="Please try a less specific search term."
            />
          </span>
        </div>
      )}

      {onLoadMore ? (
        <div className="w-full">
          <div className="w-full p-3" style={{ height: SEARCH_LIST_FIELD_COMBOBOX_OPTION_HEIGHT }}>
            <Button
              ref={loadMoreButtonRef}
              className="w-full"
              onPress={() => {
                onLoadMore();

                inputRef?.current?.focus();
              }}
              variant="secondary"
              onKeyDown={(event) => {
                if (event.code === "Tab") {
                  event.preventDefault();

                  inputRef?.current?.focus();
                }
              }}
              isDisabled={isLoading}
            >
              <FormattedMessage id="generic.label.load-more" defaultMessage="Load more" />
            </Button>
          </div>
        </div>
      ) : null}

      <div ref={endOfListRef} />
    </Combobox.Options>
  </div>
);
