import { useMemo } from "react";

import { useGetAuthorizationStructureQuery } from "~/models/auth";
import { useRootRouteData } from "~/routes/root";
import { Functionalities } from "~/types/functionalities";

/**
 * Hook to check if a funnctionality is available regardless of its scope
 * (we only need the existence of the functionality name within
 * the ScopedFunctionalities of the AuthorizationStructure)
 *
 * This can be used whenever a service needs a given functionality with a scope of
 * `0 (None)` - e.g. `EVA.Core.Services.Orders.GetOrderExportDocuments`
 */
export const useHasFunctionalityWithoutScope = (
  functionality: Functionalities,
  /* Using the organizationUnitId will filter the functionalities a user has on a certain organization unit */
  organizationUnitId?: number,
) => {
  const { authorizationStructure: authorizationStructureQuery } = useRootRouteData();

  const {
    data: authorizationStructure,
    isFetching: loading,
    isLoading: loadingWithoutResponse,
  } = useGetAuthorizationStructureQuery(authorizationStructureQuery?.request, {
    ...authorizationStructureQuery?.queryProps,
  });

  const functionalityAllowed = useMemo(() => {
    if (authorizationStructure?.Roles) {
      const matchingFunctionalities = authorizationStructure.Roles.filter((role) => {
        if (organizationUnitId && role.OrganizationUnitIDs.indexOf(organizationUnitId) === -1) {
          return false;
        }
        return Object.keys(role.ScopedFunctionalities).includes(functionality);
      });

      return matchingFunctionalities.length > 0;
    }
    return false;
  }, [authorizationStructure, functionality, organizationUnitId]);

  return { functionalityAllowed, loading, loadingWithoutResponse };
};
