import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listInvoiceExportsLoaderQuery,
  serviceQuery: listInvoiceExportsQuery,
  serviceQueryKeys: listInvoiceExportsQueryKeys,
  useServiceQueryHook: useListInvoiceExportsQuery,
} = createServiceQuery(Core.ListInvoiceExports, true);

export const {
  serviceLoaderQuery: getInvoiceExportLoaderQuery,
  serviceQuery: getInvoiceExportQuery,
  serviceQueryKeys: getInvoiceExportQueryKeys,
  useServiceQueryHook: useGetInvoiceExportQuery,
} = createServiceQuery(Core.GetInvoiceExport, true);
