import { CoreManagement } from "@springtree/eva-services-core-management";

import { getLoyaltyPointPoliciesQuery } from "~/models/loyalty-programs";
import { AcceptedExpirationPolicies } from "~/types/loyalty-program-policies";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateLoyaltyPointExpirationPolicySearchListField = () =>
  SearchListFieldGenerator<
    CoreManagement.GetLoyaltyPointPolicies,
    {
      Name: string;
    },
    string
  >({
    getItemId: (item) => item.Name,
    getLabel: (item) => item.Name,
    selectRenderElements: (item) => ({ label: item.Name }),
    getItemFromResponse: (resp) =>
      resp?.ExpirationPolicies?.filter((policy) =>
        Object.values(AcceptedExpirationPolicies).includes(policy),
      )?.map((policy) => ({
        Name: policy,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.expiration-span",
      defaultMessage: "Expiration span",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: getLoyaltyPointPoliciesQuery,
      }),
  });
