import { MenuLogo } from "../menu/menu-logo";
import { Page } from "../page";
import PageCenter from "../page-center";

import { Logo } from "~/assets/icons/components/logo";

interface AppFallbackScreenProps {
  disableHeader?: boolean;
}

const AppFallbackScreen = ({ disableHeader }: AppFallbackScreenProps) => (
  <Page>
    {!disableHeader && (
      <header className="flex h-[70px] items-center justify-between border-0 border-b border-solid border-b-default px-5">
        <div className="-ml-1 mt-[1px]">
          <MenuLogo />
        </div>
      </header>
    )}
    <PageCenter>
      <Logo />
    </PageCenter>
  </Page>
);

export default AppFallbackScreen;
