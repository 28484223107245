import { z } from "zod";
import { zfd } from "zod-form-data";

import { CustomFieldValueOperator, customFieldValueWithArraysSchema } from "~/types/custom-field";

export const loyaltyProgramCustomFieldConsumerWithOperatorFormSchema = z.object({
  CustomFieldId: zfd.numeric(z.number()),
  CustomFieldValue: zfd.json(
    customFieldValueWithArraysSchema.extend({ HasValue: z.boolean().optional() }).optional(),
  ),
  Operator: zfd.numeric(z.nativeEnum(CustomFieldValueOperator)),
});

export type TLoyaltyProgramCustomFieldConsumerWithOperatorForm = z.infer<
  typeof loyaltyProgramCustomFieldConsumerWithOperatorFormSchema
>;

export type TLoyaltyProgramCustomFieldConsumerWithOperatorFormErrors = Partial<
  Record<
    Exclude<keyof TLoyaltyProgramCustomFieldConsumerWithOperatorForm, "CustomFieldValue">,
    string
  > & {
    CustomFieldValue?: TLoyaltyProgramCustomFieldValueError;
  }
>;

export type TLoyaltyProgramCustomFieldValueError = string | (string | undefined)[] | undefined;

export const loyaltyProgramCustomFieldConsumerWithOperatorKeys =
  loyaltyProgramCustomFieldConsumerWithOperatorFormSchema.keyof().Values;
