import { useEffect } from "react";

import { IEvaServiceDefinition } from "@springtree/eva-services-core";

import { IServiceFamilyStateArgs, IServiceStateArgs, useServiceError } from "../use-service-error";

import { useNavigate } from "~/components/routing";
import routeDefinitions from "~/routes/route-definitions";
import { ErrorTypes } from "~/types/error-types";

/**
 * Hook for handling 404 errors thrown by the given `state`.
 * It will redirect the user to a 404 page.
 */
const useHandle404 = <T extends IEvaServiceDefinition>(
  state: IServiceStateArgs<T> | IServiceFamilyStateArgs<T>,
) => {
  const navigate = useNavigate();

  const serviceStateError = useServiceError(state);

  useEffect(() => {
    if (
      serviceStateError &&
      !(serviceStateError instanceof Error) &&
      typeof serviceStateError !== "string" &&
      (serviceStateError as any)?.Error?.Type === ErrorTypes.EntityNotFound
    ) {
      navigate(routeDefinitions.auth.notFound.path, { replace: true });
    }
  }, [navigate, serviceStateError]);
};

export default useHandle404;
