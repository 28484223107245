import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listMessageQueueErrorsLoaderQuery,
  serviceQuery: listMessageQueueErrorsQuery,
  serviceQueryKeys: listMessageQueueErrorsQueryKeys,
  useServiceQueryHook: useListMessageQueueErrorsQuery,
} = createServiceQuery(Core.ListMessageQueueErrors, true);
