import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getAsyncRequestsForUserLoaderQuery,
  serviceQueryKeys: getAsyncRequestsForUserQueryKeys,
  useServiceQueryHook: useGetAsyncRequestsForUserQuery,
} = createServiceQuery(Core.GetAsyncRequestsForUser);

export const {
  serviceLoaderQuery: getAsyncRequestForUserLoaderQuery,
  serviceQueryKeys: getAsyncRequestForUserQueryKeys,
  useServiceQueryHook: useGetAsyncRequestForUserQuery,
} = createServiceQuery(Core.GetAsyncRequestForUser);

export const {
  serviceLoaderQuery: getCreateZonedCycleCountAsyncResultLoaderQuery,
  serviceQueryKeys: getCreateZonedCycleCountAsyncResultQueryKeys,
  useServiceQueryHook: useCreateZonedCycleCountAsyncResultQuery,
} = createServiceQuery(
  Core.CreateZonedCycleCounts_AsyncResult,
  undefined,
  undefined,
  undefined,
  undefined,
);

export const {
  serviceLoaderQuery: getCreateZonedCycleCountsByQueryAsyncResultLoaderQuery,
  serviceQueryKeys: getCreateZonedCycleCountsByQueryAsyncResultQueryKeys,
  useServiceQueryHook: useCreateZonedCycleCountsByQueryAsyncResultQuery,
} = createServiceQuery(
  Core.CreateZonedCycleCountsByQuery_AsyncResult,
  undefined,
  undefined,
  undefined,
  undefined,
);
