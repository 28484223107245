import { ComponentProps, useCallback, useEffect, useState } from "react";

import { useField, useFormikContext } from "formik";

import {
  SingleSearchListField,
  SpyCallback,
  SpyFunction,
  TSearchListFieldGeneratorOptionsProps,
  TUncontrolledValueSelectorProp,
} from "./search-list-field-generator.types";

import useRecoilDebouncedValue from "~/hooks/suite-react-hooks/use-recoil-debounced-value";

export interface ISingleSearchListFieldWrapperReturnValues<Item> {
  Controlled: (
    inputProps: SingleSearchListField<Item>["Props"] & TSearchListFieldGeneratorOptionsProps<Item>,
  ) => JSX.Element;
  Uncontrolled: (
    inputProps: SingleSearchListField<Item>["Uncontrolled"] &
      TUncontrolledValueSelectorProp<Item> &
      TSearchListFieldGeneratorOptionsProps<Item>,
  ) => JSX.Element;
  Formik: (
    inputProps: SingleSearchListField<Item>["Formik"] & TSearchListFieldGeneratorOptionsProps<Item>,
  ) => JSX.Element;
  Recoil: (
    inputProps: SingleSearchListField<Item>["Recoil"] & TSearchListFieldGeneratorOptionsProps<Item>,
  ) => JSX.Element;
  RecoilIDOnlyWithQuery: <IDKey extends keyof Item>(
    inputProps: SingleSearchListField<Item, IDKey>["RecoilIDOnlyWithQuery"] &
      TSearchListFieldGeneratorOptionsProps<Item>,
  ) => JSX.Element;
}

/** Wrapper component that based on a basic SingleSearchListField
 * generates Controlled, Uncontrolled, Formik and Recoil variants */
export const SingleSearchListFieldWrapper = <Item,>(
  SearchListField: (props: SingleSearchListField<Item>["Props"]) => JSX.Element,
): ISingleSearchListFieldWrapperReturnValues<Item> => {
  const Controlled = (
    inputProps: ComponentProps<ISingleSearchListFieldWrapperReturnValues<Item>["Controlled"]>,
  ) => <SearchListField {...inputProps} />;

  const Uncontrolled = ({
    defaultValue,
    ...inputProps
  }: ComponentProps<ISingleSearchListFieldWrapperReturnValues<Item>["Uncontrolled"]>) => {
    const [value, setValue] = useState(defaultValue);
    return (
      <SearchListField
        {...inputProps}
        value={value}
        onChange={(newValue) => {
          setValue(newValue);
          inputProps?.onChange?.(newValue);
        }}
      />
    );
  };

  const Formik = ({
    name,
    ...inputProps
  }: ComponentProps<ISingleSearchListFieldWrapperReturnValues<Item>["Formik"]>) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField<Item | undefined>(name);
    return (
      <SearchListField
        {...inputProps}
        value={field.value}
        error={meta.touched && !!meta.error}
        helperText={meta.touched ? meta.error : undefined}
        onChange={(newValue) => {
          setFieldValue(name, newValue);
          inputProps?.onChange?.(newValue);
        }}
      />
    );
  };

  const Recoil = ({
    customOnChange,
    recoilState,
    ...inputProps
  }: ComponentProps<ISingleSearchListFieldWrapperReturnValues<Item>["Recoil"]>) => {
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);
    return (
      <Controlled
        value={recoilValue}
        onChange={(newValue) =>
          customOnChange ? customOnChange(setRecoilValue, newValue) : setRecoilValue(newValue)
        }
        {...inputProps}
      />
    );
  };

  /** Same as Recoil, but it is based only on the ID and it also allows to pass a hook that retrievs an item by ID */
  const RecoilIDOnlyWithQuery = <IDKey extends keyof Item>({
    customOnChange,
    idKey,
    recoilState,
    useItem,
    ...inputProps
  }: SingleSearchListField<Item, IDKey>["RecoilIDOnlyWithQuery"]) => {
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);

    const { isLoading, item } = useItem(recoilValue);

    const handleChange = useCallback(
      (newValue: Item | undefined) => {
        setRecoilValue(newValue?.[idKey]);
      },
      [idKey, setRecoilValue],
    );

    return (
      <Controlled
        value={item}
        isLoading={{ initialValue: recoilValue ? isLoading : false, ...inputProps.isLoading }}
        onChange={(newValue) =>
          customOnChange ? customOnChange(setRecoilValue, newValue) : handleChange(newValue)
        }
        {...inputProps}
      />
    );
  };

  return { Controlled, Uncontrolled, Formik, Recoil, RecoilIDOnlyWithQuery };
};

export interface ISingleSearchListFieldWrapperWithIDReturnValues<Item, IDKey extends keyof Item> {
  Controlled: (
    inputProps: SingleSearchListField<Item[IDKey]>["Props"] &
      TSearchListFieldGeneratorOptionsProps<Item>,
  ) => JSX.Element;
  Uncontrolled: (
    inputProps: SingleSearchListField<Item[IDKey]>["Uncontrolled"] &
      TUncontrolledValueSelectorProp<Item> &
      TSearchListFieldGeneratorOptionsProps<Item>,
  ) => JSX.Element;
  Formik: (
    inputProps: SingleSearchListField<Item[IDKey]>["Formik"] &
      TSearchListFieldGeneratorOptionsProps<Item>,
  ) => JSX.Element;
  Recoil: (
    inputProps: SingleSearchListField<Item[IDKey]>["Recoil"] &
      TSearchListFieldGeneratorOptionsProps<Item>,
  ) => JSX.Element;
}

/** Wrapper component that is based on a basic, ID-based SingleSearchListField
 * that allows to pass a hook that retrievs an item by ID */
export const SingleSearchListFieldIDWrapper = <Item, IDKey extends keyof Item>(
  SearchListField: (props: SingleSearchListField<Item>["Props"] & SpyCallback<Item>) => JSX.Element,
  idKey: IDKey,
  useItemByID: (
    id: Item[IDKey] | undefined,
    listItems: Item[] | undefined,
  ) => { data: Item | undefined; isLoading?: boolean },
): ISingleSearchListFieldWrapperWithIDReturnValues<Item, IDKey> => {
  const Controlled = (
    inputProps: ComponentProps<
      ISingleSearchListFieldWrapperWithIDReturnValues<Item, IDKey>["Controlled"]
    >,
  ) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const { data: currentItem, isLoading } = useItemByID(inputProps.value, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      ({ listItems }) => setListItems(listItems),
      [],
    );
    return (
      <SearchListField
        {...inputProps}
        value={currentItem}
        spyCallback={invokeSpy}
        isLoading={{ initialValue: isLoading, ...inputProps.isLoading }}
        onChange={(newValue) => inputProps.onChange?.(newValue?.[idKey])}
      />
    );
  };

  const Uncontrolled = ({
    defaultValue,
    ...inputProps
  }: ComponentProps<
    ISingleSearchListFieldWrapperWithIDReturnValues<Item, IDKey>["Uncontrolled"]
  >) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const [currentItemId, setCurrentItemId] = useState(defaultValue);

    useEffect(() => setCurrentItemId(defaultValue), [defaultValue]);

    const { data: currentItem, isLoading } = useItemByID(currentItemId, listItems);

    const invokeSpy = useCallback<SpyFunction<Item>>(
      ({ listItems }) => setListItems(listItems),
      [],
    );

    return (
      <SearchListField
        {...inputProps}
        value={currentItem}
        onChange={(newValue) => {
          setCurrentItemId(newValue?.[idKey]);
          inputProps.onChange?.(newValue?.[idKey]);
        }}
        spyCallback={invokeSpy}
        isLoading={{ initialValue: isLoading, ...inputProps.isLoading }}
      />
    );
  };

  const Formik = ({
    name,
    ...inputProps
  }: ComponentProps<ISingleSearchListFieldWrapperWithIDReturnValues<Item, IDKey>["Formik"]>) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField<Item[IDKey] | undefined>(name);
    const { data: currentItem, isLoading } = useItemByID(field.value, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      ({ listItems }) => setListItems(listItems),
      [],
    );
    return (
      <SearchListField
        {...inputProps}
        value={currentItem}
        spyCallback={invokeSpy}
        error={meta.touched && !!meta.error}
        isLoading={{ initialValue: isLoading, ...inputProps.isLoading }}
        helperText={meta.touched ? meta.error : undefined}
        onChange={(newValue) => {
          setFieldValue(name, newValue?.[idKey]);
          inputProps?.onChange?.(newValue?.[idKey]);
        }}
      />
    );
  };

  const Recoil = ({
    customOnChange,
    recoilState,
    ...inputProps
  }: ComponentProps<ISingleSearchListFieldWrapperWithIDReturnValues<Item, IDKey>["Recoil"]>) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);
    const { data: currentItem, isLoading } = useItemByID(recoilValue, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      ({ listItems }) => setListItems(listItems),
      [],
    );
    return (
      <SearchListField
        {...inputProps}
        value={currentItem}
        spyCallback={invokeSpy}
        isLoading={{ initialValue: isLoading, ...inputProps.isLoading }}
        onChange={(newValue) =>
          customOnChange
            ? customOnChange(setRecoilValue, newValue?.[idKey])
            : setRecoilValue(newValue?.[idKey])
        }
      />
    );
  };

  return { Controlled, Uncontrolled, Formik, Recoil };
};
