import { ComponentProps } from "react";

// TODO: replace MUI icon
import MuiWarningIcon from "@material-ui/icons/Warning";
import { SvgIcon } from "@new-black/lyra";
import classNames from "clsx";

import Tooltip from "~/components/ui/tooltip";

export interface IWarningIconProps {
  tooltipMessage?: string;
  className?: string;
  disableColor?: boolean;
  color?: "error" | "warning" | "black";
  size?: ComponentProps<typeof MuiWarningIcon>["fontSize"];
}

const WarningIcon = ({ className, color, disableColor, size, tooltipMessage }: IWarningIconProps) =>
  tooltipMessage ? (
    <Tooltip title={tooltipMessage}>
      <MuiWarningIcon
        fontSize={size}
        color={!disableColor && color === "error" ? "error" : undefined}
        className={classNames(
          "mt-[3px]",
          color === "warning" ? "text-[color:var(--color-warning-3)]" : "",
          color === "black" ? "text-[#202020]" : "",
          className,
        )}
      />
    </Tooltip>
  ) : (
    <MuiWarningIcon
      fontSize={size}
      color={!disableColor && color === "error" ? "error" : undefined}
      className={classNames(
        color === "warning" ? "text-[color:var(--color-warning-3)]" : "",
        color === "black" ? "text-[#202020]" : "",
        className,
      )}
    />
  );

export const LyraWarningIcon = ({
  className,
  color,
  disableColor,
  size,
  tooltipMessage,
}: IWarningIconProps) =>
  tooltipMessage ? (
    <Tooltip title={tooltipMessage}>
      <SvgIcon
        name="warning"
        fontSize={size}
        color={!disableColor && color === "error" ? "error" : undefined}
        className={classNames(
          "mt-[3px]",
          color === "warning" ? "text-[color:var(--color-warning-3)]" : "",
          color === "black" ? "text-[#202020]" : "",
          className,
        )}
      />
    </Tooltip>
  ) : (
    <SvgIcon
      name="warning"
      fontSize={size}
      color={!disableColor && color === "error" ? "error" : undefined}
      className={classNames(
        color === "warning" ? "text-[color:var(--color-warning-3)]" : "",
        color === "black" ? "text-[#202020]" : "",
        className,
      )}
    />
  );

export default WarningIcon;
