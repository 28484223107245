import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  ButtonGroup,
  CardFooter,
  CardHeader,
  Dialog,
  DialogCard,
  DialogProps,
  Table,
} from "@new-black/lyra";

import { useOwnedProductsTableColumns } from "./use-owned-products-table-columns";

import { useListUserBoughtProductsQueryWithRequest } from "~/models/consumers";
import { useUncountablePaginationHelper } from "~/util/hooks/use-uncountable-pagination-helper";

type SetCaseOwnedProductsModalProps = {
  isOpen?: DialogProps["isOpen"];
  onOpenChange: DialogProps["onOpenChange"];
  userId: number;
  onChange?: (newValue?: EVA.Core.Management.ListUserBoughtProductsResponse.BoughtProduct) => void;
  onSave?: (
    selectedBoughtProduct?: EVA.Core.Management.ListUserBoughtProductsResponse.BoughtProduct,
  ) => void;
  form?: string;
  initialValue?: EVA.Core.Management.ListUserBoughtProductsResponse.BoughtProduct;
};

export const SetCaseOwnedProductsModal = ({
  form,
  initialValue,
  isOpen,
  onChange,
  onOpenChange,
  onSave,
  userId,
}: SetCaseOwnedProductsModalProps) => {
  const intl = useIntl();

  const [localSelection, setLocalSelection] = useState<
    EVA.Core.Management.ListUserBoughtProductsResponse.BoughtProduct | undefined
  >(initialValue);

  const columns = useOwnedProductsTableColumns();

  const boughtProductsQuery = useListUserBoughtProductsQueryWithRequest(
    {
      InitialPageConfig: {
        Filter: {
          UserID: userId,
        },
        Limit: 5,
      },
    },
    {},
  );

  const { data, isLoading, limit, onNext, onPrevious, setLimit, showNext, showPrevious } =
    useUncountablePaginationHelper(boughtProductsQuery, {
      InitialPageConfig: {
        Limit: 5,
      },
    });

  return (
    <Dialog isOpen={isOpen} onOpenChange={onOpenChange} maxWidth="5xl">
      <DialogCard>
        <CardHeader
          title={intl.formatMessage({
            id: "generic.label.bought-products",
            defaultMessage: "Bought products",
          })}
        />

        <Table
          aria-label={intl.formatMessage({
            id: "generic.label.bought-products",
            defaultMessage: "Bought products",
          })}
          columns={columns}
          data={data}
          limit={limit}
          setLimit={setLimit}
          isLoading={isLoading}
          onNext={onNext}
          onPrevious={onPrevious}
          showNext={showNext}
          showPrevious={showPrevious}
          selectionMode="single"
          getRowId={(row) => row.ID.toString()}
          selectedRowIds={localSelection ? [localSelection.ID.toString()] : undefined}
          onRowSelectionChange={(newSelectionState) => {
            const selectedBoughtProduct = data.find(
              (boughtProduct) => newSelectionState?.[0] === boughtProduct.ID.toString(),
            );

            setLocalSelection(selectedBoughtProduct);
            onChange?.(selectedBoughtProduct);
          }}
        />

        <CardFooter>
          <ButtonGroup>
            <Button onPress={() => onOpenChange?.(false)} variant="secondary">
              <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
            </Button>

            <Button
              type={form ? "submit" : "button"}
              form={form}
              onPress={() => {
                onSave?.(localSelection);
              }}
            >
              <FormattedMessage id="generic.label.save" defaultMessage="Save" />
            </Button>
          </ButtonGroup>
        </CardFooter>
      </DialogCard>
    </Dialog>
  );
};
