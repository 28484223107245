import { Button } from "react-aria-components";

import { Transition } from "@headlessui/react";
import { Text } from "@new-black/lyra";
import classNames from "clsx";

import { MenuButtonProps } from "./menu.types";

export const MenuButton = ({
  className,
  end,
  icon,
  labelKey,
  labelValue,
  menuState: navbarState,
  onClick,
  setIsFocused,
  setMouseEntered,
}: MenuButtonProps) => {
  return (
    <Button
      type="button"
      className={classNames(
        "flex w-full items-center justify-between border-0 bg-transparent p-2 text-primary outline-none hover:bg-overlay-subtle",
        "rounded-md",
        "min-w-[32px]",
        "h-8",
        "w-full",
        "select-none",
        "font-normal",
        "font-sans",
        "text-base",
        "solid border-focus focus-visible:border focus-visible:shadow-highlight",
        "group",
        "cursor-pointer",
        className,
      )}
      onPress={() => {
        onClick?.();
        setMouseEntered(false);
        setIsFocused(false);
      }}
      onHoverStart={() => {
        if (navbarState === "closed") {
          setMouseEntered(true);
        }
      }}
      onHoverEnd={() => {
        // If navbarState is closed, we make sure that mouse entered also is set back to false.
        // This handles the case where the user hovers an link for a brief moment and then moves the mouse away. We don't want to expand the sidebar in this case.
        if (navbarState === "closed") {
          setMouseEntered(false);
        }
      }}
      onFocus={() => {
        setIsFocused(true);
      }}
      onBlur={() => {
        setIsFocused(false);
      }}
    >
      <div className="flex w-full items-center">
        <div className="text-current relative flex h-4 w-4 items-center justify-center">{icon}</div>
        <div className="ml-2 flex w-full flex-1 justify-between gap-2 overflow-x-hidden">
          <Text variant="body-medium" className="truncate font-semibold">
            {labelKey}
          </Text>
          {!end ? (
            <Text variant="body-medium" className="truncate text-primary">
              {labelValue}
            </Text>
          ) : null}
        </div>
      </div>
      <Transition
        show={navbarState !== "closed"}
        enter="transition-opacity duration-300 delay-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div>{end}</div>
      </Transition>
    </Button>
  );
};
