import { IntlShape } from "react-intl";

import { EndpointConfigurationStatus } from "../eva-core";

export const getEndpointConfigurationStatusTranslator = (intl: IntlShape) => {
  const translationMap: Record<EVA.Core.EndpointConfigurationStatus, string> = {
    [EndpointConfigurationStatus.Enabled]: intl.formatMessage({
      id: "generic.label.enabled",
      defaultMessage: "Enabled",
    }),
    [EndpointConfigurationStatus.Error]: intl.formatMessage({
      id: "generic.label.error",
      defaultMessage: "Error",
    }),
    [EndpointConfigurationStatus.Disabled]: intl.formatMessage({
      id: "generic.label.disabled",
      defaultMessage: "Disabled",
    }),
  };

  return (status: EVA.Core.EndpointConfigurationStatus) => {
    return translationMap[status];
  };
};
