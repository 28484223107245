import { useField } from "formik";

import TimePickerField, { TimePickerFieldProps } from "~/components/suite-ui/time-picker";

interface IFormikTimePickerFieldProps extends Omit<TimePickerFieldProps, "value" | "onChange"> {
  name: string;
}

export const FormikTimePickerField = ({
  helperText,
  name,
  ...props
}: IFormikTimePickerFieldProps) => {
  const [field, meta] = useField<string | undefined>(name);
  return (
    <TimePickerField
      {...field}
      {...props}
      name={name}
      value={field.value ?? ""}
      error={!!meta.error && meta.touched}
      helperText={meta.error && meta.touched ? meta.error : helperText}
    />
  );
};
