import { ReactNode, useMemo, useState } from "react";

import { SvgIcon } from "@new-black/lyra";
import classNames from "clsx";

import { ChapterFinderDialog } from "../chapter-finder-dialog";
import { MenuLogo } from "../menu/menu-logo";
import { ProfileButton } from "../profile-button";

import { DefinedRouteLink } from "~/components/routing";
import ProgressBar from "~/components/suite-ui/progress-bar";
import { EModuleCode } from "~/features/chapter-finder/chapter-finder.types";
import { useChapterFinderData } from "~/features/chapter-finder/use-chapter-finder-data";
import useEventListener from "~/hooks/suite-react-hooks/use-event-listener";
import routeDefinitions from "~/routes/route-definitions";

interface HeaderProps {
  moduleCode?: EModuleCode;
  customCenterComponent?: ReactNode;
  rightComponent?: ReactNode;
  isLoading?: boolean;
  disableLinks?: boolean;
  disableProfileButton?: boolean;
  disableSearch?: boolean;
}

export const Header = ({
  customCenterComponent,
  disableProfileButton = false,
  disableSearch = false,
  isLoading,
  moduleCode,
  rightComponent,
}: HeaderProps) => {
  const [isOpen, setIsOpen] = useState(false);

  useEventListener("keydown", (e: KeyboardEvent) => {
    if (e.key === "k" && e.metaKey) {
      setIsOpen((open) => !open);
    }
  });

  const modules = useChapterFinderData();

  const module = useMemo(
    () => modules?.find((module) => module.code === moduleCode),
    [moduleCode, modules],
  );

  return (
    <>
      <header className="relative z-10 flex h-[70px] flex-nowrap items-center justify-between gap-5 overflow-x-auto border-0 border-b border-solid border-b-default px-5">
        <ProgressBar loading={isLoading ?? false} position="top" />
        <div className="-ml-1 mt-[1px]">
          <DefinedRouteLink routeDefinition={routeDefinitions.dashboard.search}>
            <MenuLogo />
          </DefinedRouteLink>
        </div>

        <div>
          {customCenterComponent ? (
            customCenterComponent
          ) : module?.title ? (
            <div className="flex items-center gap-2">
              {module?.icon ? (
                <svg width="20" height="20" viewBox="0 0 56 56">
                  {module?.icon}
                </svg>
              ) : null}

              <span className="min-w-max font-semibold text-primary">{module?.title}</span>
            </div>
          ) : null}
        </div>

        <div className="flex items-center justify-end gap-[22px]">
          {rightComponent ? rightComponent : null}

          {!disableSearch ? (
            <span onClick={() => setIsOpen(true)}>
              <SvgIcon
                name="search"
                className={classNames(
                  "h-5 w-5",
                  "cursor-pointer rounded-md border-0 bg-transparent p-0 outline-0",
                  "text-[color:var(--color-primary-3)] active:text-[color:var(--color-primary-4)] hover:text-[color:var(--color-primary-2)] focus-visible:text-[color:var(--color-primary-2)]",
                  "ring-[color:var(--color-primary-3)]-1 ring-offset-4 ring-offset-[color:var(--color-neutral-3)] focus-visible:ring-2",
                  "transition-colors duration-200 ease-in-out",
                )}
              />
            </span>
          ) : null}

          {!disableProfileButton ? <ProfileButton /> : null}
        </div>
      </header>

      <div className="w-full max-w-md">
        <ChapterFinderDialog isOpen={isOpen} closeModal={() => setIsOpen(false)} />
      </div>
    </>
  );
};
