import { isNil } from "lodash";

import { VisibilityType } from "~/types/eva-core";

export const isAddressFieldVisible = (
  addressRequirements: EVA.Core.GetAddressRequirementsResponse | undefined,
  field: keyof EVA.Core.AddressDto,
) => {
  const fieldRequirements = addressRequirements?.AddressRequirements?.[field];

  return (
    !isNil(fieldRequirements) &&
    fieldRequirements?.Visible !== (VisibilityType.NotVisible as number)
  );
};

export const isAddressFieldRequired = (
  addressRequirements: EVA.Core.GetAddressRequirementsResponse | undefined,
  field: keyof EVA.Core.AddressDto,
) => {
  const fieldRequirements = addressRequirements?.AddressRequirements?.[field];

  return fieldRequirements?.Visible === (VisibilityType.VisibleAndRequired as number);
};
