import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

// GetFinancialPeriodDetails
export const {
  serviceLoaderQuery: getFinancialPeriodDetailsLoaderQuery,
  serviceQuery: getFinancialPeriodDetailsQuery,
  serviceQueryKeys: getFinancialPeriodDetailsQueryKeys,
  useServiceQueryHook: useGetFinancialPeriodDetailsQuery,
} = createServiceQuery(Core.GetFinancialPeriodDetails);

// GetFinancialPeriodInformation
export const {
  serviceLoaderQuery: getFinancialPeriodInformationLoaderQuery,
  serviceQuery: getFinancialPeriodInformationQuery,
  serviceQueryKeys: getFinancialPeriodInformationQueryKeys,
  useServiceQueryHook: useGetFinancialPeriodInformationQuery,
} = createServiceQuery(Core.GetFinancialPeriodInformation);

// GetFinancialPeriodClosingImpediments
export const {
  serviceLoaderQuery: getFinancialPeriodClosingImpedimentsLoaderQuery,
  serviceQuery: getFinancialPeriodClosingImpedimentsQuery,
  serviceQueryKeys: getFinancialPeriodClosingImpedimentsQueryKeys,
  useServiceQueryHook: useGetFinancialPeriodClosingImpedimentsQuery,
} = createServiceQuery(Core.GetFinancialPeriodClosingImpediments);

// ListCashTransactionLedger
export const {
  serviceLoaderQuery: listCashTransactionLedgerLoaderQuery,
  serviceQuery: listCashTransactionLedgerQuery,
  serviceQueryKeys: listCashTransactionLedgerQueryKeys,
  useServiceQueryHook: useListCashTransactionLedgerQuery,
  useServiceQueryWithRequest: useListCashTransactionLedgerQueryWithRequest,
} = createServiceQuery(Core.ListCashTransactionLedger);

// ListCashDeposits
export const {
  serviceLoaderQuery: listCashDepositsLoaderQuery,
  serviceQuery: listCashDepositsQuery,
  serviceQueryKeys: listCashDepositsQueryKeys,
  useServiceQueryHook: useListCashDepositsQuery,
  useServiceQueryWithRequest: useListCashDepositsQueryWithRequest,
} = createServiceQuery(Core.ListCashDeposits);

// ListCashExpenses
export const {
  serviceLoaderQuery: listCashExpensesLoaderQuery,
  serviceQuery: listCashExpensesQuery,
  serviceQueryKeys: listCashExpensesQueryKeys,
  useServiceQueryHook: useListCashExpensesQuery,
  useServiceQueryWithRequest: useListCashExpensesQueryWithRequest,
} = createServiceQuery(Core.ListCashExpenses);

// GetFinancialPeriodCashJournalDeviceSummary
export const {
  serviceLoaderQuery: getFinancialPeriodCashJournalDeviceSummaryLoaderQuery,
  serviceQuery: getFinancialPeriodCashJournalDeviceSummaryQuery,
  serviceQueryKeys: getFinancialPeriodCashJournalDeviceSummaryQueryKeys,
  useServiceQueryHook: useGetFinancialPeriodCashJournalDeviceSummaryQuery,
} = createServiceQuery(Core.GetFinancialPeriodCashJournalDeviceSummary);

// GetFinancialPeriodAccountsSummary
export const {
  serviceLoaderQuery: getFinancialPeriodAccountsSummaryLoaderQuery,
  serviceQuery: getFinancialPeriodAccountsSummaryQuery,
  serviceQueryKeys: getFinancialPeriodAccountsSummaryQueryKeys,
  useServiceQueryHook: useGetFinancialPeriodAccountsSummaryQuery,
  useServiceQueryWithRequest: useGetFinancialPeriodAccountsSummaryQueryWithRequest,
} = createServiceQuery(Core.GetFinancialPeriodAccountsSummary);

// GetCurrentFinancialPeriodSummary
export const {
  serviceLoaderQuery: getCurrentFinancialPeriodSummaryLoaderQuery,
  serviceQuery: getCurrentFinancialPeriodSummaryQuery,
  serviceQueryKeys: getCurrentFinancialPeriodSummaryQueryKeys,
  useServiceQueryHook: useGetCurrentFinancialPeriodSummaryQuery,
} = createServiceQuery(Core.GetCurrentFinancialPeriodSummary);

// ListFinancialPeriods
export const {
  serviceLoaderQuery: listFinancialPeriodsLoaderQuery,
  serviceQuery: listFinancialPeriodsQuery,
  serviceQueryKeys: listFinancialPeriodsQueryKeys,
  useServiceQueryHook: useListFinancialPeriodsQuery,
} = createServiceQuery(Core.ListFinancialPeriods);

// ListBlobsForFinancialPeriod
export const {
  serviceLoaderQuery: listBlobsForFinancialPeriodLoaderQuery,
  useServiceQueryHook: useListBlobsForFinancialPeriodQuery,
} = createServiceQuery(Core.ListBlobsForFinancialPeriod, true);

// ListFinancialPeriodExports2
export const {
  serviceLoaderQuery: listFinancialPeriodExportsLoaderQuery,
  serviceQueryKeys: listFinancialPeriodExportsQueryKeys,
  useServiceQueryHook: useListFinancialPeriodExportsQuery,
  useServiceQueryWithRequest: useListFinancialPeriodExportsQueryWithRequest,
} = createServiceQuery(Core.ListFinancialPeriodExports2, true);

// GetFinancialPeriodExportDocuments
export const {
  serviceLoaderQuery: getFinancialPeriodExportDocumentsLoaderQuery,
  useServiceQueryHook: useGetFinancialPeriodExportDocumentsQuery,
} = createServiceQuery(Core.GetFinancialPeriodExportDocuments, true);
