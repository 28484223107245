import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listRoleSetsLoaderQuery,
  serviceQuery: listRoleSetsQuery,
  serviceQueryKeys: listRoleSetsQueryKeys,
  useServiceQueryHook: useListRoleSetsQuery,
} = createServiceQuery(CoreManagement.ListRoleSets);

export const {
  serviceLoaderQuery: getRoleSetByIDLoaderQuery,
  serviceQuery: getRoleSetByIDQuery,
  serviceQueryKeys: getRoleSetByIDQueryKeys,
  useServiceQueryHook: useGetRoleSetByIDQuery,
} = createServiceQuery(CoreManagement.GetRoleSetByID, true);

export const {
  serviceLoaderQuery: getRolesForOrganizationUnitSetLoaderQuery,
  serviceQuery: getRolesForOrganizationUnitSetQuery,
  serviceQueryKeys: getRolesForOrganizationUnitSetQueryKeys,
  useServiceQueryHook: useGetRolesForOrganizationUnitSetQuery,
} = createServiceQuery(CoreManagement.GetRolesForOrganizationUnitSet);

export const {
  serviceLoaderQuery: listRolesLoaderQuery,
  serviceQuery: listRolesQuery,
  serviceQueryKeys: listRolesQueryKeys,
  useServiceQueryHook: useListRolesQuery,
  useServiceQueryWithRequest: useListRolesQueryWithRequest,
} = createServiceQuery(CoreManagement.ListRoles);

export const { serviceQuery: getRoleServiceQuery } = createServiceQuery(
  CoreManagement.GetRole,
  true,
);
