import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { useSetRecoilState } from "recoil";

import { ProductSetAvailableProductsAtom } from "~/components/suite-composite/product-set-modal/state";
import { IProductSetProduct } from "~/components/suite-composite/product-set-modal/types";
import NumberInput from "~/components/suite-ui/number-input";
import useDebounce from "~/hooks/suite-react-hooks/use-debounce";

export interface IAddOrderLinesAvailableProductsListItemQuantityProps {
  product: IProductSetProduct;
  disabled: boolean;
}

const ProductSetItemQuantityInput = ({
  disabled,
  product,
}: IAddOrderLinesAvailableProductsListItemQuantityProps) => {
  const intl = useIntl();

  const setAvailableProducts = useSetRecoilState(ProductSetAvailableProductsAtom);

  const [quantity, setQuantity] = useState<number | undefined>(product.Quantity);
  const debouncedQuantity = useDebounce(quantity, 500);

  useEffect(() => {
    if (debouncedQuantity !== product.Quantity) {
      setAvailableProducts((prev) =>
        prev.map((availableProduct) => {
          if (availableProduct.ID !== product.ID) {
            return availableProduct;
          } else {
            return {
              ...availableProduct,
              Quantity: debouncedQuantity,
            };
          }
        }),
      );
    }
  }, [debouncedQuantity, product.ID, product.Quantity, setAvailableProducts]);

  return (
    <NumberInput
      small
      placeholder={intl.formatMessage({
        id: "generic.label.quantity",
        defaultMessage: "Quantity",
      })}
      onFocus={(e: React.FocusEvent<HTMLInputElement>) => e.target.select()}
      value={quantity}
      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = isNaN(parseInt(e.target.value)) ? undefined : parseInt(e.target.value);

        if (newValue !== quantity) {
          setQuantity(newValue);
        }
      }}
      disabled={disabled}
      allowNegative={false}
      precision={0}
      thousandSeparator={false}
    />
  );
};

export default ProductSetItemQuantityInput;
