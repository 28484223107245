import { useCallback, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

import ExpanseCard from "~/components/suite-ui/expanse-card";
import { RawCodeBox } from "~/components/ui/raw-code-box";
import { useFormattedDateTime } from "~/util/hooks/use-formatted-date";

interface ITransputJobDocumentProps {
  document: EVA.Core.TransputJobDocument;
}

const TransputJobDocument = ({ document }: ITransputJobDocumentProps) => {
  const intl = useIntl();
  const [isOpen, setIsOpen] = useState(false);

  const formattedDocument = useMemo(() => {
    try {
      const parsedDocument = JSON.parse(document.Document);
      return JSON.stringify(parsedDocument, null, 2);
    } catch {
      return document.Document;
    }
  }, [document.Document]);

  const handleCopy = useCallback(() => {
    if (navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(formattedDocument)
        .then(() =>
          toast.success(
            intl.formatMessage({
              id: "generic.label.document-copied-to-clipboard",
              defaultMessage: "Document copied to clipboard!",
            }),
          ),
        )
        .catch((err) => console.error(err));
    }
  }, [formattedDocument, intl]);

  return (
    <ExpanseCard
      variant="secondary"
      open={isOpen}
      setOpen={setIsOpen}
      toggleOnHeaderClick
      title={useFormattedDateTime(document.CreationTime)}
      customActions={
        isOpen ? (
          <Button
            variant="icon"
            onPress={handleCopy}
            tooltip={intl.formatMessage({
              id: "generic.label.copy",
              defaultMessage: "Copy",
            })}
          >
            <SvgIcon name="copy" />
          </Button>
        ) : undefined
      }
    >
      <code>
        <RawCodeBox className="max-h-[40vh] overflow-x-auto">{formattedDocument}</RawCodeBox>
      </code>
    </ExpanseCard>
  );
};

export default TransputJobDocument;
