import { CoreManagement } from "@springtree/eva-services-core-management";

import { listCaseTopicsQuery, useGetCaseTopicQuery } from "~/models/cases";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateCaseTopicSearchListField = ({
  filters,
  requestedOuId,
}: {
  filters?: Partial<EVA.Core.ListCaseTopicsFilter>;
  requestedOuId?: number;
}) =>
  SearchListFieldGenerator<
    CoreManagement.ListCaseTopics,
    {
      ID?: number;
      Name?: string;
    },
    number
  >({
    getItemId: (item) => item.ID!,
    getLabel: (item) => item.Name ?? "",
    getItemFromResponse: (response) =>
      response?.Results?.map((caseTopic) => ({ ID: caseTopic.ID, Name: caseTopic.Name })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.case-topic",
      defaultMessage: "Case topic",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listCaseTopicsQuery,
        initialRequest: { InitialPageConfig: { Limit: 10, Filter: filters } },
        getQueryRequest: (req) => req?.InitialPageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          InitialPageConfig: {
            ...req?.InitialPageConfig,
            Filter: {
              ...req?.InitialPageConfig?.Filter,
              Name: newValue ?? undefined,
            },
          },
        }),
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton: !!response?.NextPageToken,
          onLoadMore: (request) => ({
            ...request,
            InitialPageConfig: {
              ...request?.InitialPageConfig,
              Limit:
                (request?.InitialPageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        ouId: requestedOuId,
      }),
    useItemByID: (id) => {
      const { data, isFetching } = useGetCaseTopicQuery(id ? { ID: id } : undefined, {
        refetchOnWindowFocus: false,
      });

      return {
        data: id ? data : undefined,
        isLoading: isFetching,
      };
    },
  });

export const CaseTopicSearchListField = generateCaseTopicSearchListField({});
