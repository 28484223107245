import { IntlShape } from "react-intl";

import { OrganizationUnitType } from "~/types/organization-unit-types";

export const getOrganizationUnitTypeTranslator = (intl: IntlShape) => {
  const translationMap: Record<OrganizationUnitType, string> = {
    [OrganizationUnitType.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
    [OrganizationUnitType.Shop]: intl.formatMessage({
      id: "generic.label.shop",
      defaultMessage: "Shop",
    }),
    [OrganizationUnitType.WebShop]: intl.formatMessage({
      id: "generic.label.web-shop",
      defaultMessage: "Web Shop",
    }),
    [OrganizationUnitType.Container]: intl.formatMessage({
      id: "generic.label.container",
      defaultMessage: "Container",
    }),
    [OrganizationUnitType.Pickup]: intl.formatMessage({
      id: "generic.label.pickup",
      defaultMessage: "Pickup",
    }),
    [OrganizationUnitType.Warehouse]: intl.formatMessage({
      id: "generic.label.warehouse",
      defaultMessage: "Warehouse",
    }),
    [OrganizationUnitType.Country]: intl.formatMessage({
      id: "generic.label.country",
      defaultMessage: "Country",
    }),
    [OrganizationUnitType.Franchise]: intl.formatMessage({
      id: "generic.label.franchise",
      defaultMessage: "Franchise",
    }),
    [OrganizationUnitType.EVA]: intl.formatMessage({
      id: "generic.label.eva",
      defaultMessage: "EVA",
    }),
    [OrganizationUnitType.TestOrganizationUnit]: intl.formatMessage({
      id: "generic.label.test-organization-unit",
      defaultMessage: "Test Organization Unit",
    }),
    [OrganizationUnitType.DisableLogin]: intl.formatMessage({
      id: "generic.label.disable-login",
      defaultMessage: "Disable Login",
    }),
    [OrganizationUnitType.ExternalSupplier]: intl.formatMessage({
      id: "generic.label.external-supplier",
      defaultMessage: "External supplier",
    }),
    [OrganizationUnitType.Consignment]: intl.formatMessage({
      id: "generic.label.consignment",
      defaultMessage: "Consignment",
    }),
    [OrganizationUnitType.B2b]: intl.formatMessage({
      id: "generic.label.b2b",
      defaultMessage: "B2B",
    }),
    [OrganizationUnitType.Region]: intl.formatMessage({
      id: "generic.label.region",
      defaultMessage: "Region",
    }),
    [OrganizationUnitType.ReturnsPortal]: intl.formatMessage({
      id: "generic.label.returns-portal",
      defaultMessage: "Returns Portal",
    }),
    [OrganizationUnitType.Restitution]: intl.formatMessage({
      id: "generic.label.restitution",
      defaultMessage: "Restitution",
    }),
    [OrganizationUnitType.RepairCenter]: intl.formatMessage({
      id: "generic.label.repair-center",
      defaultMessage: "Repair Center",
    }),
    [OrganizationUnitType.Mobile]: intl.formatMessage({
      id: "generic.label.mobile",
      defaultMessage: "Mobile",
    }),
    [OrganizationUnitType.ConcessionStore]: intl.formatMessage({
      id: "generic.label.concession-store",
      defaultMessage: "Concession Store",
    }),
  };

  return (status: OrganizationUnitType) => {
    return translationMap[status];
  };
};
