import { Separator } from "@new-black/lyra";

import LoadingStateBox from "~/components/shared/loading-state-box";
import { ProductSummary } from "~/components/suite-composite/product-summary";
import { useGetProductsQuery } from "~/models/products";

type SidePaneProductsFilterProductsSummariesProps = {
  productsIDs: number[];
  onDeleteProduct?: (productID: number) => void;
};

export const SidePaneProductsFilterProductsSummaries = ({
  productsIDs,
}: SidePaneProductsFilterProductsSummariesProps) => {
  const { data: productsDetails, isLoading } = useGetProductsQuery(
    {
      ProductIDs: productsIDs,
      IncludedFields: ["product_id", "backend_id", "display_value", "custom_id", "primary_image"],
    },
    {},
  );

  return isLoading ? (
    <LoadingStateBox limit={productsIDs.length} />
  ) : (
    <div className="flex flex-col">
      {productsIDs.map((productID, idx) => {
        const productDetails = productsDetails?.Products?.[productID];

        return (
          <div key={productID}>
            <div className="flex items-center px-5">
              <ProductSummary
                productName={productDetails?.display_value ?? "-"}
                productImageBlobID={productDetails?.primary_image?.blob}
                productID={productDetails?.product_id}
                backendID={productDetails?.backend_id}
                customID={productDetails?.custom_id}
                className="py-3"
                expandableProductName
              />
            </div>

            {idx !== productsIDs.length - 1 ? <Separator /> : null}
          </div>
        );
      })}
    </div>
  );
};
