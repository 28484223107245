import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selector } from "recoil";

import { ALL_ROWS_LIMIT } from "~/util/base-values";

export const discountCampaignsServiceState = builders.buildServiceState({
  service: CoreManagement.ListDiscountCampaigns,
  key: "CoreManagement:ListDiscountCampaigns",
  requireEmployee: true,
  allowEmptyRequest: false,
  defaultRequest: {
    PageConfig: {
      Start: 0,
      Limit: ALL_ROWS_LIMIT,
    },
  },
});

export const discountCampaignsResultSelector = selector({
  key: "CoreManagement:ListDiscountCampaigns:Result",
  get: ({ get }) => get(discountCampaignsServiceState.response)?.Result?.Page,
});
