import { useMemo } from "react";
import { useIntl } from "react-intl";

import { MultiSearchListField } from "@new-black/lyra";

import { LoyaltyPointUsageOptions } from "~/types/eva-core";
import enumToArray, { EnumArray } from "~/util/enum-to-array";

interface LoyaltyPointUsageOptionsSearchListField {
  value?: number[];
  onChange: (val: number[]) => void;
  hideHintLabel?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
}

export const LoyaltyPointUsageOptionsSearchListField = ({
  errorMessage,
  hideHintLabel,
  isRequired,
  onChange,
  value,
}: LoyaltyPointUsageOptionsSearchListField) => {
  const intl = useIntl();

  const items = useMemo(() => enumToArray(LoyaltyPointUsageOptions), []);

  const selectedValue = useMemo<EnumArray>(
    () => items?.filter((item) => value?.includes(item.value)) ?? [],
    [items, value],
  );

  return (
    <MultiSearchListField
      isRequired={isRequired}
      hideHintLabel={hideHintLabel}
      getItemId={(item) => item.value}
      getLabel={(item) => item.key}
      selectRenderElements={(item) => ({ label: item.key })}
      value={selectedValue}
      items={items ?? []}
      onChange={(newValue) => onChange(newValue?.map((item) => item.value) ?? [])}
      label={intl.formatMessage({
        id: "generic.label.point-usage-types",
        defaultMessage: "Point usage types",
      })}
      errorMessage={errorMessage}
    />
  );
};
