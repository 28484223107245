import { useMemo } from "react";
import { useIntl } from "react-intl";

import { EvaProductSetProductRequirementEnumValueField } from "./eva-product-set-product-requirement-enum-value-field";
import { EvaProductSetProductRequirementStringValueField } from "./eva-product-set-product-requirement-string-value-field";

import RadioButtonList from "~/components/suite-ui/radio-button-list";
import RadioButtonLabel from "~/components/suite-ui/radio-button-list/radio-button-label";
import { ProductRequirementDataTypes } from "~/types/product-requirement-types";

export type ProducRequirementFieldProps = {
  productRequirement: EVA.Core.Management.ProductRequirementDto;
  value?: string[] | number[] | boolean[];
  setValue: (newValue?: string[] | number[] | boolean[]) => void;
  passive?: boolean;
  errorMessage?: string;
  onFieldBlur: () => void;
};

export const EvaProductSetProductRequirementValueField = ({
  errorMessage,
  onFieldBlur,
  passive,
  productRequirement,
  setValue,
  value,
}: ProducRequirementFieldProps) => {
  const intl = useIntl();

  const fieldType = useMemo(
    () => (productRequirement ? (productRequirement.DataType as number) : undefined),
    [productRequirement],
  );

  const ProductRequirementValueComponent = useMemo(() => {
    switch (fieldType) {
      case ProductRequirementDataTypes.String:
      case ProductRequirementDataTypes.Text:
        return (
          <EvaProductSetProductRequirementStringValueField
            onFieldBlur={onFieldBlur}
            value={value}
            setValue={setValue}
            errorMessage={errorMessage}
            passive={passive}
          />
        );
      case ProductRequirementDataTypes.Integer:
      case ProductRequirementDataTypes.Decimal:
      case ProductRequirementDataTypes.Enum:
        return (
          <EvaProductSetProductRequirementEnumValueField
            passive={passive}
            fieldType={fieldType}
            onFieldBlur={onFieldBlur}
            productRequirement={productRequirement}
            errorMessage={errorMessage}
            value={value}
            setValue={setValue}
          />
        );
      case ProductRequirementDataTypes.Bool: {
        const selectedValue = value?.[0] === true ? "true" : "false";

        return (
          <RadioButtonList
            name="bool-product-requirement"
            label={intl.formatMessage({
              id: "generic.label.required-value",
              defaultMessage: "Required value",
            })}
            disabled={passive}
            selectedValue={selectedValue ?? "false"}
            handleSelectedValueChange={(_, value) => setValue([value === "true"])}
          >
            <RadioButtonLabel
              label={intl.formatMessage({
                id: "generic.label.true",
                defaultMessage: "True",
              })}
              value="true"
              activeValue={selectedValue}
            />
            <RadioButtonLabel
              label={intl.formatMessage({
                id: "generic.label.false",
                defaultMessage: "False",
              })}
              value="false"
              activeValue={selectedValue}
            />
          </RadioButtonList>
        );
      }

      default:
        return null;
    }
  }, [errorMessage, fieldType, intl, onFieldBlur, passive, productRequirement, setValue, value]);

  return ProductRequirementValueComponent;
};
