import { useIntl } from "react-intl";

import { motion, Variants } from "framer-motion";

import { FormikCheckboxV2 } from "~/components/suite-composite/formik-inputs";

interface IAdvancedSettings {
  shown: boolean;
}

const variants: Variants = {
  open: {
    opacity: 1,
    height: "auto",
  },
  closed: { opacity: 0, height: 0, overflow: "hidden" },
};

const AdvancedSettings = ({ shown }: IAdvancedSettings) => {
  const intl = useIntl();

  return (
    <motion.div
      animate={shown ? "open" : "closed"}
      variants={variants}
      transition={{ duration: 0.3 }}
      initial="closed"
    >
      <div className="flex flex-col gap-2 p-5 pt-0">
        <FormikCheckboxV2
          name="ExactMatch"
          label={intl.formatMessage({
            id: "product-filter.modal.advanced-settings.input.exact-match",
            defaultMessage: "Exact match",
          })}
        />

        <FormikCheckboxV2
          name="Negation"
          label={intl.formatMessage({
            id: "product-filter.modal.advanced-settings.input.negation",
            defaultMessage: "Negation",
          })}
        />

        <FormikCheckboxV2
          name="IncludeMissing"
          label={intl.formatMessage({
            id: "product-filter.modal.advanced-settings.input.include-missing",
            defaultMessage: "Include missing",
          })}
        />
      </div>
    </motion.div>
  );
};

export default AdvancedSettings;
