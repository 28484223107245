import { useCallback, useMemo, useRef, useState } from "react";
import { useIntl } from "react-intl";

import { produce } from "immer";
import { isNumber } from "lodash";

import useEntityTranslationFieldTableColumns from "./entity-translation-single-field/hooks/use-entity-translation-field-table-columns";
import useGetEntityTranslationsState from "./hooks/use-get-translations-state";
import useManageEntityTranslations from "./hooks/use-manage-entity-translations";
import EntityTranslationFieldCustomActions from "./entity-translation-field-custom-actions";
import { EntityTranslationFieldIdentifiers, EntityTranslationFieldTranslations } from "./types";

import Table from "~/components/suite-ui/table";
import { ITableConfig } from "~/components/suite-ui/table/table-types";
import { toast } from "~/components/suite-ui/toast";
import useAllowedFunctionalities from "~/hooks/suite-react-hooks/use-allowed-functionalities";
import { Functionalities } from "~/types/functionalities";

const EntityTranslationFieldTable = ({
  entityField,
  entityID,
  entityType,
  translations,
}: EntityTranslationFieldIdentifiers & {
  translations?: EntityTranslationFieldTranslations;
}) => {
  const intl = useIntl();

  const [shouldValidate, setShouldValidate] = useState(false);

  const addButtonRef = useRef<HTMLButtonElement | null>(null);
  // Disable table actions when updating / adding rows to the table
  const [disabledTableActions, setDisabledTableActions] = useState(false);

  const { filteredResults, setResults } = useGetEntityTranslationsState({
    entityField,
    entityID,
    entityType,
  });

  const {
    deleteEntityTranslation,
    replaceOrCreateEntityTranslation,
    updateEntityTranslationWithoutInitialCountryId,
  } = useManageEntityTranslations({
    entityField,
    entityID,
    entityType,
    translations: translations?.editField,
  });

  const { deleteFunctionalityAllowed, editFunctionalityAllowed } = useAllowedFunctionalities(
    Functionalities.TRANSLATIONS,
  );

  const handleUpdateRow = useCallback(
    (values: EVA.Core.ListEntityTranslation, rowIndex: number) => {
      setShouldValidate(true);

      const resultIndex = filteredResults?.findIndex(
        (result) =>
          result?.CountryID === values.CountryID && result?.LanguageID === values?.LanguageID,
      );

      if (resultIndex !== -1 && resultIndex !== rowIndex) {
        toast.error(
          intl.formatMessage({
            id: "entity-translations.error.duplicate",
            defaultMessage: "An entry for the selected country-language pair already exists.",
          }),
        );

        return false;
      }

      if (!values.CountryID || !values.LanguageID || !values.Value) {
        return false;
      }

      const isNewTranslation =
        filteredResults[rowIndex].CountryID === "" &&
        filteredResults[rowIndex].LanguageID === "" &&
        filteredResults[rowIndex].Value === "";

      // Our UI allows updating an entity translation from not having a country ID set to having a country ID set.
      // However the ReplaceEntityTranslation service will create a new translation because it uses the country + language ID pair as identifiers.
      // To keep the impression that an edit was actually done, we also delete the old entity translation (the one without the country ID) so in the end the number of translations remains the same.
      if (!filteredResults[rowIndex].CountryID && !isNewTranslation) {
        updateEntityTranslationWithoutInitialCountryId(
          values.CountryID,
          values.LanguageID,
          values.Value,
        );
      } else {
        replaceOrCreateEntityTranslation(values.CountryID, values.LanguageID, values.Value);
      }

      setDisabledTableActions(false);
      setShouldValidate(false);

      return true;
    },
    [
      filteredResults,
      intl,
      replaceOrCreateEntityTranslation,
      updateEntityTranslationWithoutInitialCountryId,
    ],
  );

  const handleCreateRow = useCallback(() => {
    const newEntry: EVA.Core.ListEntityTranslation = {
      EntityField: entityField,
      EntityID: entityID,
      EntityType: entityType,
      CountryID: "",
      LanguageID: "",
      Value: "",
    };
    setResults((prev) => [...prev, newEntry]);
    setDisabledTableActions(true);
  }, [entityField, entityID, entityType, setResults]);

  const removeByIndex = useCallback(
    (indexes: number[]) => {
      const index = indexes[0];
      const row = filteredResults?.[index];

      setResults((prev) =>
        produce(prev, (draft) => {
          const indexOfRowInResults = draft.findIndex(
            (item) => item.CountryID === row.CountryID && item.LanguageID === row.LanguageID,
          );

          if (isNumber(indexOfRowInResults)) {
            draft.splice(indexOfRowInResults, 1);
          }
        }),
      );
      if (row.CountryID && row.LanguageID) {
        deleteEntityTranslation(row.CountryID, row.LanguageID);
      }
      setDisabledTableActions(false);
    },
    [deleteEntityTranslation, filteredResults, setResults],
  );

  const columns = useEntityTranslationFieldTableColumns(shouldValidate);

  const tableConfig = useMemo<ITableConfig<any>>(
    () => ({
      options: {
        title:
          translations?.table?.title ??
          intl.formatMessage({ id: "generic.label.results", defaultMessage: "Results" }),
        editRows: editFunctionalityAllowed,
        deleteRow: deleteFunctionalityAllowed,
        hidePagination: true,
      },
      columns,
    }),
    [
      intl,
      columns,
      editFunctionalityAllowed,
      translations?.table?.title,
      deleteFunctionalityAllowed,
    ],
  );

  return (
    // @ts-ignore
    <Table
      data={(filteredResults ?? []).map((result) => ({ ...result }))}
      limit={10}
      skip={0}
      total={filteredResults?.length ?? 0}
      createRow={handleCreateRow}
      updateRow={handleUpdateRow}
      removeByIndex={removeByIndex}
      customActions={
        <EntityTranslationFieldCustomActions
          disabled={disabledTableActions}
          addButtonRef={addButtonRef}
          entityField={entityField}
          entityID={entityID}
          entityType={entityType}
          translations={translations}
        />
      }
      externalAddButtonRef={addButtonRef}
      {...tableConfig}
    />
  );
};

export default EntityTranslationFieldTable;
