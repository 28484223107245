import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { Card, CardActions, CardHeader, Table, TableColumnDef } from "@new-black/lyra";
import { useQuery } from "@tanstack/react-query";

import { PRODUCT_LIMITATION_PRODUCT_INCLUDED_FIELDS } from "./types";

import AddActionButton from "~/components/shared/action-buttons/add-action-button";
import DeleteActionButton from "~/components/shared/action-buttons/delete-action-button";
import LoadingStateBox from "~/components/shared/loading-state-box";
import { ProductSummary } from "~/components/suite-composite/product-summary/lyra";
import { searchProductsQuery } from "~/models/products";
import { IProduct } from "~/types/product";

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 5;

export const LoyaltyProgramProductLimitationDataProductIDsCard = ({
  onAdd,
  onDelete,
  onDeleteProduct,
  productIDs,
  queryKey,
}: {
  productIDs?: number[];
  onAdd?: () => void;
  onDelete?: () => void;
  onDeleteProduct?: (productID: number) => void;
  queryKey: string | number;
}) => {
  const intl = useIntl();

  const [page, setPage] = useState(DEFAULT_PAGE);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);

  const request = useMemo<EVA.Core.SearchProducts>(
    () => ({
      Filters: {
        product_id: {
          Values: productIDs,
        },
      },
      IncludedFields: PRODUCT_LIMITATION_PRODUCT_INCLUDED_FIELDS,
      Page: page,
      PageSize: pageSize,
    }),
    [page, pageSize, productIDs],
  );

  const { data: products, isLoading: isProductsLoadingWithoutPreviousResponse } = useQuery({
    ...searchProductsQuery(request, [queryKey]),
    enabled: !!productIDs?.length,
    refetchOnMount: true,
    refetchOnWindowFocus: false,
  });

  const limit = useMemo(() => request?.PageSize ?? DEFAULT_PAGE_SIZE, [request?.PageSize]);

  const setLimit = useCallback((newValue: number) => {
    setPageSize(newValue);
    setPage(DEFAULT_PAGE);
  }, []);

  const start = useMemo(
    () => ((request?.Page ?? DEFAULT_PAGE) - 1) * (request?.PageSize ?? DEFAULT_PAGE_SIZE),
    [request?.Page, request?.PageSize],
  );

  const setStart = useCallback(
    (newValue: number) => {
      setPage(Math.floor(newValue / limit) + 1);
    },
    [limit],
  );

  const columns = useMemo(() => {
    const result: TableColumnDef<IProduct>[] = [
      {
        id: "product_id",
        header: intl.formatMessage({ id: "generic.label.product", defaultMessage: "Product" }),
        cell: ({ row }) => (
          <ProductSummary
            productName={row.original.product_name ?? "-"}
            productID={row.original.product_id}
            customID={row.original.custom_id}
            backendID={row.original.backend_id}
            productImageBlobID={row.original.primary_image?.blob}
            imageSize={30}
          />
        ),
      },
    ];

    if (onDeleteProduct && (products?.Total ?? 0) > 1) {
      result.push({
        id: "actions",
        header: "",
        align: "end",
        cellType: "button",
        cell: ({ row }) => (
          <DeleteActionButton onPress={() => onDeleteProduct(row.original.product_id)} />
        ),
        width: "50px",
      });
    }

    return result;
  }, [intl, onDeleteProduct, products?.Total]);

  const title = useMemo(
    () =>
      intl.formatMessage({
        id: "generic.label.products",
        defaultMessage: "Products",
      }),
    [intl],
  );

  return (
    <Card>
      <CardHeader
        title={title}
        actions={
          onDelete || onAdd ? (
            <CardActions>
              {onDelete ? <DeleteActionButton onPress={onDelete} /> : null}

              {onAdd ? <AddActionButton onPress={onAdd} /> : null}
            </CardActions>
          ) : undefined
        }
      />

      {isProductsLoadingWithoutPreviousResponse ? (
        <LoadingStateBox limit={1} />
      ) : (
        <Table
          aria-label={title}
          columns={columns}
          data={products?.Products ?? []}
          start={start}
          setStart={setStart}
          limit={limit}
          setLimit={setLimit}
          total={products?.Total}
        />
      )}
    </Card>
  );
};
