import { useCallback } from "react";
import { FormattedMessage } from "react-intl";

import { Checkbox } from "@material-ui/core";
import { Button } from "@new-black/lyra";
import { cva } from "class-variance-authority";
import { isEqual } from "lodash";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";

import { ProductSummary } from "../../product-summary";
import { ProductIncludedInSetIndicator } from "../helper-components";
import {
  IsProductIncludedInSetSelector,
  IsProductSelectedSelector,
  ProductSetIncludedProductsAtom,
  ProductSetSelectedAvailableProductsAtom,
} from "../state";
import { IProductSetProduct } from "../types";

import ProductSetItemQuantityInput from "./product-set-item-quantity-input";

import Grid from "~/components/suite-ui/grid";

export interface IAvailableProductsListItem {
  product: IProductSetProduct;
  placeholderImageUrl?: string;
  addButtonDisabled?: boolean;
  showQuantityInput?: boolean;
  hideCheckbox?: boolean;
}

const wrapperStyle = cva("px-5", {
  variants: {
    highlighted: {
      true: ["bg-[rgba(0,122,255,0.1)]"],
      false: [],
    },
  },
});

const AvailableProductsListItem = ({
  addButtonDisabled,
  hideCheckbox,
  placeholderImageUrl,
  product,
  showQuantityInput,
}: IAvailableProductsListItem) => {
  const isSelected = useRecoilValue(IsProductSelectedSelector(product.ID));
  const [selectedProducts, setSelectedProducts] = useRecoilState(
    ProductSetSelectedAvailableProductsAtom,
  );
  const isIncludedInProductSet = useRecoilValue(IsProductIncludedInSetSelector(product.ID));
  const setIncludedProducts = useSetRecoilState(ProductSetIncludedProductsAtom);

  const toggleItemSelected = useCallback(() => {
    setSelectedProducts((current) => {
      if (current.some((selectedProduct) => selectedProduct.ID === product.ID)) {
        return current.filter((selectedProduct) => selectedProduct.ID !== product.ID);
      }
      return [...current, product];
    });
  }, [product, setSelectedProducts]);

  return (
    <div className={wrapperStyle({ highlighted: isSelected && !isIncludedInProductSet })}>
      <Grid container alignItems="center">
        {!hideCheckbox ? (
          <Grid item>
            <div className="mr-5">
              <Checkbox
                color="primary"
                checked={isSelected && !isIncludedInProductSet}
                onChange={toggleItemSelected}
                disabled={isIncludedInProductSet}
              />
            </div>
          </Grid>
        ) : null}

        <Grid zeroMinWidth item xs>
          <div className="py-[5px]">
            <ProductSummary
              productID={product?.ID}
              customID={product?.CustomID}
              backendID={product?.BackendID}
              productName={product.Name}
              productImageBlobID={product.ImageBlobID}
              strikeTrough={isIncludedInProductSet}
              placeholderImageUrl={placeholderImageUrl}
            />
          </div>
        </Grid>
        <Grid item>
          <div className="flex gap-10">
            {showQuantityInput ? (
              <div className="max-w-[80px]">
                <ProductSetItemQuantityInput product={product} disabled={isIncludedInProductSet} />
              </div>
            ) : null}

            <div className="ml-5 min-w-[75px]">
              {isIncludedInProductSet ? (
                <ProductIncludedInSetIndicator />
              ) : (
                <Button
                  isDisabled={addButtonDisabled}
                  fullWidth
                  onPress={() => {
                    setIncludedProducts((current) => [...current, product]);

                    // it's possible for the included product to have been selected beforehand
                    // so we filter it from the selected products
                    const filteredSelectedProducts = selectedProducts.filter(
                      (selectedProduct) => selectedProduct.ID !== product.ID,
                    );

                    if (!isEqual(selectedProducts, filteredSelectedProducts)) {
                      setSelectedProducts(filteredSelectedProducts);
                    }
                  }}
                >
                  <FormattedMessage id="generic.label.add" defaultMessage="Add" />
                </Button>
              )}
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AvailableProductsListItem;
