import { ComponentProps, ReactNode } from "react";
import { FormattedMessage } from "react-intl";

import { Button, ButtonProps } from "@new-black/lyra";

import Grid from "~/components/suite-ui/grid";
import Text, { TextProps } from "~/components/suite-ui/text";

export interface IConfirmationModalContentMessages {
  cancelButtonMessage?: ReactNode;
  confirmButtonMessage?: ReactNode;
  titleMessage?: ReactNode;
  descriptionMessage?: ReactNode;
}

export interface IConfirmationModalContentProps {
  onCancel: () => void;
  onConfirm: () => void;
  isLoading?: boolean;
  messages?: IConfirmationModalContentMessages;
  cancelButtonVariant?: ButtonProps["variant"];
  confirmButtonVariant?: ButtonProps["variant"];
  textDescriptionVariant?: TextProps["variant"];
}

/**
 * @deprecated Import from `src/components/suite-composite/confirmation-modal` instead.
 */
const ConfirmationModalContent = ({
  cancelButtonVariant,
  confirmButtonVariant,
  isLoading,
  messages,
  onCancel,
  onConfirm,
  textDescriptionVariant = "subtitle1",
}: IConfirmationModalContentProps) => (
  <div className="p-5">
    <div className="mb-5">
      <Text variant="h2">
        {messages?.titleMessage ?? (
          <FormattedMessage id="generic.question.are-you-sure" defaultMessage="Are you sure?" />
        )}
      </Text>
    </div>

    <div className="mb-5">
      <Text variant={textDescriptionVariant}>
        {messages?.descriptionMessage ?? (
          <FormattedMessage
            id="generic.confirmation-modal.description"
            defaultMessage="Are you sure you want to proceed?"
          />
        )}
      </Text>
    </div>

    <div>
      <Grid container justifyContent="flex-end" spacing={3}>
        <Grid item>
          <Button
            onPress={onCancel}
            isDisabled={isLoading}
            variant={cancelButtonVariant ?? "secondary"}
          >
            {messages?.cancelButtonMessage ?? (
              <FormattedMessage id="generic.action.cancel" defaultMessage="Cancel" />
            )}
          </Button>
        </Grid>
        <Grid item>
          <Button
            onPress={onConfirm}
            isLoading={isLoading}
            variant={confirmButtonVariant ?? "primary"}
          >
            {messages?.confirmButtonMessage ?? (
              <FormattedMessage id="generic.action.confirm" defaultMessage="Confirm" />
            )}
          </Button>
        </Grid>
      </Grid>
    </div>
  </div>
);

export default ConfirmationModalContent;
