import { IntlShape } from "react-intl";

import { z } from "zod";

import { SerialNumberConfigurationRequirement } from "../eva-core";

export const serialNumberConfigurationRequirementSchema = z.nativeEnum(
  SerialNumberConfigurationRequirement,
);

export function getSerialNumberConfigurationRequirementLabelTranslator(
  intl: IntlShape,
  ignoreNone?: boolean,
) {
  const lut: Record<SerialNumberConfigurationRequirement, string> = {
    [SerialNumberConfigurationRequirement.Sales]: intl.formatMessage({
      id: "generic.label.sales",
      defaultMessage: "Sales",
    }),
    [SerialNumberConfigurationRequirement.OwnershipChange]: intl.formatMessage({
      id: "generic.label.ownership-change",
      defaultMessage: "Ownership change",
    }),
    [SerialNumberConfigurationRequirement.StockMovements]: intl.formatMessage({
      id: "generic.label.stock-movements",
      defaultMessage: "Stock movements",
    }),
    [SerialNumberConfigurationRequirement.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
  };

  return (requirement: SerialNumberConfigurationRequirement) => {
    if (requirement in lut) {
      if (ignoreNone && (requirement as number) === SerialNumberConfigurationRequirement.None) {
        return undefined;
      }
      return lut[requirement];
    }
    return undefined;
  };
}
