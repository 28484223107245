import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Form } from "react-router-dom";

import { CardContent, CardHeader, Dialog, DialogCard, NumberField, Text } from "@new-black/lyra";
import { isEmpty } from "lodash";

import {
  loyaltyProgramOrderAmountKeys,
  TLoyaltyProgramOrderAmountForm,
  TLoyaltyProgramOrderAmountFormErrors,
} from "./add-edit-loyalty-program-rule-order-amonut-condition.types";

import { FormSubmitDialogFooter } from "~/components/shared/form-submit-dialog-footer";
import LoadingStateBox from "~/components/suite-ui/loading-state-box";
import { removeUndefinedValues } from "~/util/helper";
import { useCurrencyPrecision } from "~/util/hooks/use-currency-precision";

interface IAddEditLoyaltyProgramRuleOrderAmountConditionModal {
  /** Action path. */
  actionPath?: string;
  /** Form ID. */
  form: string;
  isOpen: boolean;
  onClose: () => void;
  errors?: TLoyaltyProgramOrderAmountFormErrors;
  initialValues?: Partial<TLoyaltyProgramOrderAmountForm>;
  isLoading?: boolean;
}

export const AddEditLoyaltyProgramRuleOrderAmountConditionModal = ({
  actionPath,
  errors,
  form,
  initialValues,
  isLoading,
  isOpen,
  onClose,
}: IAddEditLoyaltyProgramRuleOrderAmountConditionModal) => {
  const intl = useIntl();

  const precision = useCurrencyPrecision();

  const hasInitialValues = useMemo(
    () => !isEmpty(removeUndefinedValues(initialValues)),
    [initialValues],
  );

  const title = useMemo(
    () =>
      intl.formatMessage({
        id: "generic.label.order-amount",
        defaultMessage: "Order amount",
      }),
    [intl],
  );

  const formatOptions = useMemo(() => ({ maximumFractionDigits: precision }), [precision]);

  return (
    <Dialog
      aria-label={title}
      isOpen={isOpen}
      onOpenChange={(open) => (!open ? onClose() : undefined)}
      maxWidth="lg"
    >
      <Form method="POST" id={form} action={actionPath}>
        <DialogCard>
          <CardHeader title={title} />

          {isLoading ? (
            <LoadingStateBox limit={2} />
          ) : (
            <div className="max-h-[60vh] overflow-y-auto">
              <CardContent>
                <div className="flex flex-col gap-4">
                  <Text variant="body-medium">
                    <FormattedMessage
                      id="generic.label.when-the-order-amount-is-greater-than"
                      defaultMessage="When the order amount is greater than"
                    />
                  </Text>

                  <NumberField
                    isRequired
                    name={loyaltyProgramOrderAmountKeys.Amount}
                    label={intl.formatMessage({
                      id: "generic.label.value",
                      defaultMessage: "Value",
                    })}
                    defaultValue={initialValues?.Amount}
                    errorMessage={errors?.Amount}
                    formatOptions={formatOptions}
                    hideStepper
                  />
                </div>
              </CardContent>
            </div>
          )}

          <FormSubmitDialogFooter
            isSaveDisabled={isLoading}
            formID={form}
            onCancel={onClose}
            saveLabel={
              hasInitialValues
                ? undefined
                : intl.formatMessage({
                    id: "generic.label.create",
                    defaultMessage: "Create",
                  })
            }
          />
        </DialogCard>
      </Form>
    </Dialog>
  );
};
