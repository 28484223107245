import { useMemo } from "react";
import { IntlShape, useIntl } from "react-intl";

import useEnumToArray from "./suite-react-hooks/use-enum-to-array";

import { MenuItemProps } from "~/components/suite-ui/select";
import { DiscountDeactivationReason } from "~/types/discount-deactivation-reason";

export function getDeactivationReasonLabelTranslationFunction(intl: IntlShape) {
  const translationMap: Record<DiscountDeactivationReason, string> = {
    [DiscountDeactivationReason.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
    [DiscountDeactivationReason.Unverified]: intl.formatMessage({
      id: "generic.label.deactivation-reasons.1",
      defaultMessage: "Unverified",
    }),
    [DiscountDeactivationReason.DisabledByUser]: intl.formatMessage({
      id: "generic.label.deactivation-reasons.2",
      defaultMessage: "Disabled by user",
    }),
    [DiscountDeactivationReason.ByDiscountUsage]: intl.formatMessage({
      id: "generic.label.deactivation-reasons.4",
      defaultMessage: "Discount usage",
    }),
    [DiscountDeactivationReason.ByDiscountBudget]: intl.formatMessage({
      id: "generic.label.deactivation-reasons.8",
      defaultMessage: "Discount budget",
    }),
    [DiscountDeactivationReason.ByDiscountTimeFrame]: intl.formatMessage({
      id: "generic.label.deactivation-reasons.16",
      defaultMessage: "Discount time frame",
    }),
    [DiscountDeactivationReason.ByDiscountCampaignBudget]: intl.formatMessage({
      id: "generic.label.deactivation-reasons.32",
      defaultMessage: "Campaign budget",
    }),
    [DiscountDeactivationReason.ByDiscountCampaignTimeFrame]: intl.formatMessage({
      id: "generic.label.deactivation-reasons.64",
      defaultMessage: "Campaign time frame",
    }),
  };

  return (deactivationReason: DiscountDeactivationReason) => translationMap[deactivationReason];
}

const useDiscountDeactivationReasons = () => {
  const intl = useIntl();
  const deactivationReasonsEnumEntries = useEnumToArray(DiscountDeactivationReason);

  const getDeactivationReasonLabel = useMemo(
    () => getDeactivationReasonLabelTranslationFunction(intl),
    [intl],
  );

  const deactivationReasons = useMemo<MenuItemProps[]>(
    () =>
      deactivationReasonsEnumEntries?.map((deactionReasonEntry) => ({
        label: getDeactivationReasonLabel(deactionReasonEntry.value),
        value: deactionReasonEntry.value,
      })) ?? [],
    [deactivationReasonsEnumEntries, getDeactivationReasonLabel],
  );

  return { deactivationReasons };
};

export default useDiscountDeactivationReasons;
