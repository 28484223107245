import { ReactNode, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, CardFooter, Dialog, DialogCard, Separator } from "@new-black/lyra";

import { EditorType } from "../segmented-button-editor-switch";
import ToggleFullScreenActionButton from "../toggle-full-screen-action-button";

import useGetScriptErrors from "./hooks/use-get-script-sheet-errors";
import ScriptCodeEditor from "./script-code-editor";
import {
  IMonacoEditorModelProps,
  IScriptCodeEditorBaseProps,
  IScriptEditorModelProps,
  IScriptHistoryItem,
} from "./script-code-editor.types";
import { ScriptCodeEditorProvider } from "./script-code-editor-provider";
import ScriptDiffEditor from "./script-diff-editor";
import { ScriptDiffEditorVersionHeader } from "./script-diff-editor-version-header";
import { ScriptEditorHeader, ScriptEditorHeaderClassNames } from "./script-editor-header";
import { DEFAULT_SCRIPT_EDITOR_IN_MODAL_HEIGHT, useEditorTheme, useInitialEditorModels } from ".";

import { ScriptEditorLanguage } from "~/types/monaco-editor-language";

export type ScriptEditorProps = IScriptCodeEditorBaseProps & {
  title?: string;
  initialValue?: string;
  value?: string;
  dialect: ScriptEditorLanguage;
  dialectKeywords: string[];
  customActions?: ReactNode;
  customActionsPosition?: "start" | "end";
  revisionCommentValue?: string;
  onRevisionCommentValueChange?: (comment: string) => void;
  hideCurrentRevisionComment?: boolean;
  currentRevisionComment?: string;
  currentRevisionDate?: string;
  hideFullScreenAction?: boolean;
  onShowFullScreenEditor?: () => void;
  editorType?: EditorType;
  setEditorType?: (type: EditorType) => void;
  scriptHistory?: IScriptHistoryItem[];
  onLoadMoreHistory?: () => void;
  isHistoryLoading?: boolean;
  editorHeaderClassNames?: ScriptEditorHeaderClassNames;
  /** Defaults to `true`. */
  showEditorTypeSwitch?: boolean;
  ModelProps?: IMonacoEditorModelProps;
};

export function ScriptEditor({
  showEditorTypeSwitch = true,
  ...props
}: ScriptEditorProps & Partial<Pick<ScriptEditorProps, "dialect" | "dialectKeywords">>) {
  const { dialect, dialectKeywords } = props;

  if (dialect && dialectKeywords) {
    return (
      <ScriptEditorWrapper
        {...props}
        dialect={dialect}
        dialectKeywords={dialectKeywords}
        showEditorTypeSwitch={showEditorTypeSwitch}
      />
    );
  }

  return null;
}

function ScriptEditorWrapper(props: ScriptEditorProps) {
  const { dialect, dialectKeywords } = props;

  return (
    <ScriptCodeEditorProvider dialect={dialect} dialectKeywords={dialectKeywords}>
      <ScriptEditorWithoutProvider {...props} />
    </ScriptCodeEditorProvider>
  );
}

export function ScriptEditorWithoutProvider({
  showEditorTypeSwitch = true,
  ...props
}: Omit<ScriptEditorProps, "dialectKeywords">) {
  const intl = useIntl();

  const { editorType, ModelProps, setEditorType } = props;

  const [showFullScreenEditor, setShowFullScreenEditor] = useState(false);

  const [localEditorType, setLocalEditorType] = useState<EditorType>(editorType ?? "normal");

  // Sync local editor type state with the external one
  useEffect(() => setLocalEditorType(editorType ?? "normal"), [editorType]);

  const onEditorTypeChange = useCallback(
    (value: EditorType) => {
      setLocalEditorType(value);
      setEditorType?.(value);
    },
    [setEditorType],
  );

  const {
    initialDiffEditorModel,
    initialNormalEditorModel,
    setInitialDiffEditorModel,
    setInitialNormalEditorModel,
  } = useInitialEditorModels(localEditorType);

  const scriptEditorModelProps = useMemo<IScriptEditorModelProps>(
    () => ({
      showFullScreenEditor,
      setShowFullScreenEditor,
      ModelProps: {
        normalEditor: ModelProps?.normalEditor ?? {
          initialModel: initialNormalEditorModel,
          onBlur: (model) => setInitialNormalEditorModel(model ?? null),
        },
        diffEditor: ModelProps?.diffEditor ?? {
          initialModel: initialDiffEditorModel,
          onBlur: (model) => setInitialDiffEditorModel(model ?? null),
        },
      },
    }),
    [
      ModelProps?.diffEditor,
      ModelProps?.normalEditor,
      initialDiffEditorModel,
      initialNormalEditorModel,
      setInitialDiffEditorModel,
      setInitialNormalEditorModel,
      showFullScreenEditor,
    ],
  );

  return (
    <>
      <ScriptEditorContent
        {...props}
        {...scriptEditorModelProps}
        editorType={localEditorType}
        setEditorType={onEditorTypeChange}
        showFullScreenEditor={showFullScreenEditor}
        setShowFullScreenEditor={setShowFullScreenEditor}
        showEditorTypeSwitch={showEditorTypeSwitch}
      />

      <Dialog
        aria-label={intl.formatMessage({ id: "generic.label.edit", defaultMessage: "Edit" })}
        maxWidth="fullScreen"
        isOpen={showFullScreenEditor}
        onOpenChange={(open) => (!open ? setShowFullScreenEditor(false) : undefined)}
      >
        <DialogCard stickyFooter className="flex h-full flex-col">
          <ScriptEditorContent
            {...props}
            {...scriptEditorModelProps}
            editorType={localEditorType}
            setEditorType={onEditorTypeChange}
            showFullScreenEditor={showFullScreenEditor}
            setShowFullScreenEditor={setShowFullScreenEditor}
            showEditorTypeSwitch={showEditorTypeSwitch}
            height={DEFAULT_SCRIPT_EDITOR_IN_MODAL_HEIGHT}
            hideFullScreenAction
            customActionsPosition="end"
            customActions={
              <ToggleFullScreenActionButton
                isFullScreen={showFullScreenEditor}
                onFullScreenChange={() => setShowFullScreenEditor(false)}
              />
            }
          />

          <CardFooter>
            <Button variant="secondary" onPress={() => setShowFullScreenEditor(false)}>
              <FormattedMessage id="generic.label.close" defaultMessage="Close" />
            </Button>
          </CardFooter>
        </DialogCard>
      </Dialog>
    </>
  );
}

const ScriptEditorContent = (
  props: Omit<ScriptEditorProps, "dialectKeywords"> &
    Required<Pick<ScriptEditorProps, "editorType" | "setEditorType">> &
    IScriptEditorModelProps,
) => {
  const {
    currentRevisionComment,
    dialect,
    disableFirstLineEditing,
    editorHeaderClassNames,
    editorType,
    height,
    hideCurrentRevisionComment,
    initialValue,
    isHistoryLoading,
    ModelProps,
    onChange,
    onLoadMoreHistory,
    onSaveShortcut,
    readonly,
    scriptHistory,
    setEditorType,
    value,
  } = props;

  const { editorTheme, setEditorTheme } = useEditorTheme();

  const [selectedHistoryItem, setSelectedHistoryItem] = useState<IScriptHistoryItem>();

  const { errors } = useGetScriptErrors(dialect, value);

  return (
    <div style={{ height: height ?? "100%" }} className="flex flex-col">
      <ScriptEditorHeader
        {...props}
        editorTheme={editorTheme}
        setEditorTheme={setEditorTheme}
        isEditorTypeSwitchDisabled={!value && !initialValue}
        classNames={editorHeaderClassNames}
      />

      <Separator />

      <div className="flex flex-1 flex-col">
        {editorType === "normal" ? (
          <ScriptCodeEditor
            source={value}
            onChange={onChange}
            errors={errors}
            onSaveShortcut={onSaveShortcut}
            theme={editorTheme}
            readonly={readonly}
            disableFirstLineEditing={disableFirstLineEditing}
            initialModel={ModelProps?.normalEditor?.initialModel}
            onBlur={ModelProps?.normalEditor?.onBlur}
          />
        ) : (
          <>
            <ScriptDiffEditorVersionHeader
              currentRevisionComment={currentRevisionComment}
              hideCurrentRevisionComment={hideCurrentRevisionComment}
              isHistoryLoading={isHistoryLoading}
              onLoadMoreHistory={onLoadMoreHistory}
              selectedHistoryItem={selectedHistoryItem}
              setSelectedHistoryItem={setSelectedHistoryItem}
              scriptHistory={scriptHistory}
              onSelectHistoryItem={() => setEditorType("diff")}
            />

            <div className="flex-1">
              <ScriptDiffEditor
                originalSource={selectedHistoryItem?.Source ?? initialValue}
                modifiedSource={value}
                onChange={onChange}
                errors={errors}
                onSaveShortcut={onSaveShortcut}
                theme={editorTheme}
                readonly={readonly}
                disableFirstLineEditing={disableFirstLineEditing}
                initialModel={ModelProps?.diffEditor?.initialModel}
                onBlur={ModelProps?.diffEditor?.onBlur}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
