import { useState } from "react";
import { useIntl } from "react-intl";

import { Text as LyraText, TextProps as LyraTextProps } from "@new-black/lyra";
import classNames from "clsx";

import LinkButton, { LinkButtonProps, LyraLinkButton } from "../link-button";
import Text, { TextProps } from "../text";

export interface IExpandableTextProps {
  /** Text to display */
  text: string;
  /** Maximum text length before truncating */
  maxLength: number;
  /** Text to display when text is truncated */
  lessText?: string;
  /** Text to display when text is expanded */
  moreText?: string;
  /** Text suffix to display when text is truncated */
  textEllipsis?: string;
  /** If true, insert space before expansion controller (LinkButton) when expanded */
  spaceBeforeLessAction?: boolean;
  /** If true, insert space before expansion controller (LinkButton) when truncated */
  spaceBeforeMoreAction?: boolean;
  /** Choose the displayed text variant */
  variant?: TextProps["variant"];
  linkButtonVariant?: LinkButtonProps["variant"];
  /** Add className to the text */
  className?: string;
}

/** Component to display text with an expandable controller when it exceeds a set length
 *
 * @param text Text to display
 * @param maxLength Maximum text length before truncating (if the text is less than this length, it will not be truncated)
 * @param lessText Text to display when text is truncated
 * @param moreText Text to display when text is expanded
 * @param textEllipsis Text suffix to display when text is truncated
 * @param spaceBeforeLessAction If true, insert space before expansion controller (LinkButton) when expanded
 * @param spaceBeforeMoreAction If true, insert space before expansion controller (LinkButton) when truncated
 * @param variant Choose the displayed text variant
 * @param className Add className to the text
 */
const ExpandableText = ({
  className,
  lessText,
  linkButtonVariant,
  maxLength,
  moreText,
  spaceBeforeLessAction = false,
  spaceBeforeMoreAction = false,
  text,
  textEllipsis = "...",
  variant,
}: IExpandableTextProps) => {
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);

  if (text.length <= maxLength) {
    return (
      <Text variant={variant} className={className}>
        {text}
      </Text>
    );
  }

  return (
    <Text
      variant={variant}
      className={classNames(
        text.split(splitWordsOnRegex).some((val) => val.length > 50) && "break-words",
        className,
      )}
    >
      {isExpanded ? text : `${text.substring(0, maxLength).trimEnd()}${textEllipsis}`}
      {spaceBeforeLessAction && isExpanded ? <>&nbsp;</> : null}
      {spaceBeforeMoreAction && !isExpanded ? <>&nbsp;</> : null}
      <LinkButton
        variant={linkButtonVariant}
        className="font-[inherit] leading-[inherit] tracking-[inherit]"
        onClick={(event) => {
          event.stopPropagation();
          setIsExpanded((state) => !state);
        }}
      >
        {isExpanded
          ? lessText ??
            intl.formatMessage({ id: "generic.label.show-less", defaultMessage: "Show less" })
          : moreText ??
            intl.formatMessage({ id: "generic.label.show-more", defaultMessage: "Show more" })}
      </LinkButton>
    </Text>
  );
};

const splitWordsOnRegex = /[,.!?:; ]/;

export interface IExpandableLyraTextProps {
  /** Text to display */
  text: string;
  /** Maximum text length before truncating */
  maxLength: number;
  /** Text to display when text is truncated */
  lessText?: string;
  /** Text to display when text is expanded */
  moreText?: string;
  /** Text suffix to display when text is truncated */
  textEllipsis?: string;
  /** If true, insert space before expansion controller (LinkButton) when expanded */
  spaceBeforeLessAction?: boolean;
  /** If true, insert space before expansion controller (LinkButton) when truncated */
  spaceBeforeMoreAction?: boolean;
  /** Choose the displayed text variant */
  variant?: LyraTextProps["variant"];
  linkButtonVariant?: LyraTextProps["variant"];
  /** Add className to the text */
  className?: string;
}

/** Component to display text with an expandable controller when it exceeds a set length
 *
 * @param text Text to display
 * @param maxLength Maximum text length before truncating (if the text is less than this length, it will not be truncated)
 * @param lessText Text to display when text is truncated
 * @param moreText Text to display when text is expanded
 * @param textEllipsis Text suffix to display when text is truncated
 * @param spaceBeforeLessAction If true, insert space before expansion controller (LinkButton) when expanded
 * @param spaceBeforeMoreAction If true, insert space before expansion controller (LinkButton) when truncated
 * @param variant Choose the displayed text variant
 * @param className Add className to the text
 */
export const ExpandableLyraText = ({
  className,
  lessText,
  linkButtonVariant,
  maxLength,
  moreText,
  spaceBeforeLessAction = false,
  spaceBeforeMoreAction = false,
  text,
  textEllipsis = "...",
  variant,
}: IExpandableLyraTextProps) => {
  const intl = useIntl();
  const [isExpanded, setIsExpanded] = useState(false);

  if (text.length <= maxLength) {
    return (
      <LyraText variant={variant} className={className}>
        {text}
      </LyraText>
    );
  }

  return (
    <LyraText
      variant={variant}
      className={classNames(
        text.split(splitWordsOnRegex).some((val) => val.length > 50) && "break-words",
        className,
      )}
    >
      {isExpanded ? text : `${text.substring(0, maxLength).trimEnd()}${textEllipsis}`}
      {spaceBeforeLessAction && isExpanded ? <>&nbsp;</> : null}
      {spaceBeforeMoreAction && !isExpanded ? <>&nbsp;</> : null}
      <LyraLinkButton
        variant={linkButtonVariant}
        className="font-[inherit] leading-[inherit] tracking-[inherit]"
        onPress={() => {
          setIsExpanded((state) => !state);
        }}
      >
        {isExpanded
          ? lessText ??
            intl.formatMessage({ id: "generic.label.show-less", defaultMessage: "Show less" })
          : moreText ??
            intl.formatMessage({ id: "generic.label.show-more", defaultMessage: "Show more" })}
      </LyraLinkButton>
    </LyraText>
  );
};

export default ExpandableText;
