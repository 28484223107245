import { useMemo } from "react";

import { Core } from "@springtree/eva-services-core";

import { getCycleCountOriginTypesQuery } from "~/models/zoned-cycle-counts";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const CycleCountOriginTypesSearchListField = SearchListFieldGenerator<
  Core.GetCycleCountOriginTypes,
  EVA.Core.EnumDto,
  EVA.Core.EnumDto["ID"]
>({
  getItemId: (item) => item.ID,
  getLabel: (item) => item.Name,
  getItemFromResponse: (resp) => resp?.Result,
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.origin-type",
    defaultMessage: "Origin type",
  }),
  useServiceQuery: () =>
    SearchListFieldGenerator.useSearchListFieldService({
      refetchOnFocus: false,
      query: getCycleCountOriginTypesQuery,
      initialRequest: {},
    }),
  useItemByID: (id, listItems) => {
    const data = useMemo(() => {
      if (!id) {
        return undefined;
      }
      return listItems?.find((item) => item.ID === id);
    }, [id, listItems]);
    return { data, isLoading: false };
  },
  useItemsByID: (ids, listItems) => {
    const data = useMemo(() => {
      if (!ids || !ids.length) {
        return undefined;
      }
      return ids
        .map((id) => listItems?.find((item) => item.ID === id))
        .filter((item): item is NonNullable<typeof item> => item !== undefined);
    }, [ids, listItems]);
    return { data, isLoading: false };
  },
});
