import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, CardActions, CardFooter, CardHeader, Dialog, DialogCard } from "@new-black/lyra";

import { useProductFilterContext } from "../product-filter-context";

import { LyraProductsOverview } from "~/components/suite-composite/lyra-products-overview";
import { LyraCustomQuery } from "~/components/suite-composite/lyra-products-overview/custom-query";

export const LyraProductOverviewModal = () => {
  const intl = useIntl();

  const {
    openProductOverviewModal,
    placeholderImageUrl,
    productFilter,
    setOpenProductOverviewModal,
  } = useProductFilterContext();

  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(10);
  const [query, setQuery] = useState<string>();

  const updateQuery = useCallback((value: string | undefined) => {
    setQuery(value);
    setStart(0);
  }, []);

  return (
    <Dialog
      maxWidth="lg"
      isOpen={openProductOverviewModal}
      onOpenChange={setOpenProductOverviewModal}
    >
      <DialogCard stickyHeader stickyFooter>
        <CardHeader
          title={intl.formatMessage({ id: "generic.label.products", defaultMessage: "Products" })}
          actions={
            <CardActions>
              <LyraCustomQuery query={query} updateQuery={updateQuery} />
            </CardActions>
          }
        />

        <LyraProductsOverview
          filter={productFilter}
          placeholderImageUrl={placeholderImageUrl}
          start={start}
          setStart={setStart}
          limit={limit}
          setLimit={setLimit}
          query={query}
        />

        <CardFooter>
          <Button onPress={() => setOpenProductOverviewModal(false)}>
            <FormattedMessage id="generic.label.close" defaultMessage="Close" />
          </Button>
        </CardFooter>
      </DialogCard>
    </Dialog>
  );
};
