import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getRepairLoaderQuery,
  serviceQueryKeys: getRepairQueryKeys,
  useServiceQueryHook: useGetRepairQuery,
  useServiceQueryWithRequest: useGetRepairQueryWithRequest,
} = createServiceQuery(Core.GetRepair, true);

export const {
  serviceLoaderQuery: listRepairInteractionsLoaderQuery,
  serviceQueryKeys: listRepairInteractionsQueryKeys,
  useServiceQueryHook: useListRepairInteractionsQuery,
} = createServiceQuery(Core.ListRepairInteractions, true);

export const {
  serviceLoaderQuery: listRepairsLoaderQuery,
  serviceQueryKeys: listRepairsQueryKeys,
  useServiceQueryHook: useListRepairsQuery,
  useServiceQueryWithRequest: useListRepairsQueryWithRequest,
} = createServiceQuery(Core.ListRepairs, true);
