import { IntlShape } from "react-intl";

import { FleetManagerDeviceStatus } from "~/features/watchtower-devices/watchtower-devices.types";

export const getFleetManagerStatusTranslator = (
  intl: IntlShape,
): ((status: number) => string | undefined) => {
  const translationMap: Record<EVA.Core.LiveGuard.FleetManagerDeviceStatus | number, string> = {
    [FleetManagerDeviceStatus.Accepted]: intl.formatMessage({
      id: "generic.label.accepted",
      defaultMessage: "Accepted",
    }),
    [FleetManagerDeviceStatus.Pending]: intl.formatMessage({
      id: "generic.label.pending",
      defaultMessage: "Pending",
    }),
    [FleetManagerDeviceStatus.Rejected]: intl.formatMessage({
      id: "generic.label.rejected",
      defaultMessage: "Rejected",
    }),
    [FleetManagerDeviceStatus.Preauthorized]: intl.formatMessage({
      id: "generic.label.pre-authorized",
      defaultMessage: "Pre-authorized",
    }),
    [FleetManagerDeviceStatus.Noauth]: intl.formatMessage({
      id: "generic.label.no-auth",
      defaultMessage: "No-auth",
    }),
  };

  return (status) => {
    return translationMap[status];
  };
};
