import { TextFieldProps } from "@new-black/lyra";
import { useField } from "formik";
import { useRecoilState } from "recoil";

import FiscalIDValidatedBaseInput from "./fiscal-id-validated-base-input";
import { TFiscalIDValidatedInput } from "./fiscal-id-validated-input.types";
import LyraFiscalIDValidatedBaseInput from "./lyra-fiscal-id-validated-base-input";

import {
  ControlledInputProps,
  FormikInputProps,
  RecoilInputProps,
  UncontrolledInputProps,
} from "~/components/shared/inputs/inputs.types";

export const FiscalIDValidatedInput = {
  Controlled: (props: TFiscalIDValidatedInput<ControlledInputProps>) => (
    <FiscalIDValidatedBaseInput {...props} />
  ),
  Uncontrolled: (props: TFiscalIDValidatedInput<UncontrolledInputProps>) => (
    <FiscalIDValidatedBaseInput {...props} />
  ),
  /** Same functionality as `FormikInput` */
  Formik: (props: TFiscalIDValidatedInput<FormikInputProps>) => {
    const [field, meta] = useField<string | undefined>(props.name ?? "FiscalID");

    return (
      <FiscalIDValidatedBaseInput
        {...props}
        {...field}
        error={props.error || (!!meta.error && meta.touched)}
        helperText={meta.error && meta.touched ? meta.error : props.helperText}
        inputProps={{
          ...props.inputProps,
          onBlur: (event) => {
            props.inputProps?.onBlur?.(event);
            props.onBlur?.(event);
          },
        }}
      />
    );
  },
  Recoil: (props: TFiscalIDValidatedInput<RecoilInputProps>) => {
    const [value, setValue] = useRecoilState(props.recoilState);

    return (
      <FiscalIDValidatedBaseInput
        {...props}
        value={value}
        onChange={(e) => setValue(e.target.value)}
      />
    );
  },
};

export const LyraFiscalIDValidatedInput = {
  Controlled: (props: Omit<TFiscalIDValidatedInput<TextFieldProps>, "defaultValue">) => (
    <LyraFiscalIDValidatedBaseInput {...props} />
  ),
  Uncontrolled: (props: Omit<TFiscalIDValidatedInput<TextFieldProps>, "value" | "onChange">) => (
    <LyraFiscalIDValidatedBaseInput {...props} />
  ),
};
