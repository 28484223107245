import classNames from "clsx";

type CenterPageContainerProps = {
  children?: React.ReactNode;
  className?: string;
};

export const CenterPageContainer = ({ children, className }: CenterPageContainerProps) => {
  return (
    <div className={classNames("flex w-full justify-center", className)}>
      <div className="w-full max-w-[960px]">{children}</div>
    </div>
  );
};
