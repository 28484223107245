import { FormattedMessage, useIntl } from "react-intl";

import { SvgIcon } from "@new-black/lyra";

import Text from "~/components/suite-ui/text";

// TODO: refactor
const SerialNumberWarrantyDataInformation = ({
  hasWarrantyEndpoint,
  isLoading,
  productWarranty,
  shouldDisplayWarrantyInformation,
}: {
  isLoading?: boolean;
  shouldDisplayWarrantyInformation?: boolean;
  hasWarrantyEndpoint?: boolean;
  productWarranty?: EVA.Core.GetProductWarrantyResponse;
}) => {
  const intl = useIntl();

  return isLoading ? null : hasWarrantyEndpoint ? (
    // We don't have an endpoint set for warranty validation
    <Text className="text-xs text-error">
      <FormattedMessage
        id="generic.label.no-warranty-endpoint"
        defaultMessage="There is no endpoint set for the warranty check."
      />
    </Text>
  ) : shouldDisplayWarrantyInformation ? (
    productWarranty?.Error?.Message ? (
      // We have an error message returned by `GetProductWarranty`
      <Text className="text-xs text-error">
        <FormattedMessage
          id="generic.label.warranty-endpoint-error"
          defaultMessage="Could not get endpoint for the warranty check."
        />
      </Text>
    ) : (
      // We have warranty information
      <Text className="flex flex-col gap-1 text-xs">
        <div className="flex items-center gap-1">
          {productWarranty?.IsUnderWarranty ? (
            <SvgIcon name="verified-checkmark" className="text-success" />
          ) : (
            <SvgIcon name="clear" className="text-error" />
          )}
          <FormattedMessage id="generic.label.warranty" defaultMessage="Warranty" />
        </div>

        {productWarranty?.WarrantyExpiryDate ? (
          <div>
            <FormattedMessage
              id="generic.key-value.warranty-expiry-date"
              defaultMessage="Warranty expiry date: {value}"
              values={{
                value: intl.formatDate(productWarranty?.WarrantyExpiryDate),
              }}
            />
          </div>
        ) : null}
      </Text>
    )
  ) : null;
};

export default SerialNumberWarrantyDataInformation;
