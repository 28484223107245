import { useMemo } from "react";
import { useIntl } from "react-intl";

import Chip from "~/components/suite-ui/chip";
import Grid from "~/components/suite-ui/grid";
import { ICustomFieldOptions } from "~/types/custom-field";

interface ICustomFieldOptionsChips {
  dataType: number;
  options?: ICustomFieldOptions;
}
export const CustomFieldOptionsChips = ({ dataType, options }: ICustomFieldOptionsChips) => {
  const pointerList = useGeneratePointers(options, dataType);

  return (
    <Grid container alignItems="center" spacing={1}>
      {pointerList.map((pointer, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid item key={index}>
          <Chip color="default" size="small">
            {pointer}
          </Chip>
        </Grid>
      ))}
    </Grid>
  );
};

const useGeneratePointers = (
  options:
    | EVA.Core.Management.ListCustomFieldOptionsResponse.CustomFieldOptionsDto
    | EVA.Core.CustomFieldOptions
    | undefined,
  dataType: number,
) => {
  const intl = useIntl();

  const pointers = useMemo(() => {
    const arr = [];

    if (options?.IsRequired) {
      arr.push(
        intl.formatMessage({
          id: "organizations.details.tabs.custom-fields.pointers.required",
          defaultMessage: "Field is required",
        }),
      );
    }

    if (options?.MinimumLength) {
      arr.push(
        intl.formatMessage(
          {
            id: "organizations.details.tabs.custom-fields.pointers.min-length",
            defaultMessage: "Min. {characters} characters",
          },
          { characters: options.MinimumLength },
        ),
      );
    }

    if (options?.MaximumLength) {
      arr.push(
        intl.formatMessage(
          {
            id: "organizations.details.tabs.custom-fields.pointers.max-length",
            defaultMessage: "Max. {characters} characters",
          },
          { characters: options.MaximumLength },
        ),
      );
    }

    if (options?.MinimumValue) {
      arr.push(
        intl.formatMessage(
          {
            id: "organizations.details.tabs.custom-fields.pointers.min-value",
            defaultMessage: "Min. value of {value}",
          },
          { value: options.MinimumValue },
        ),
      );
    }

    if (options?.MaximumValue) {
      arr.push(
        intl.formatMessage(
          {
            id: "organizations.details.tabs.custom-fields.pointers.max-value",
            defaultMessage: "Max. value of {value}",
          },
          { value: options.MaximumValue },
        ),
      );
    }

    if (options?.MinimumDate) {
      arr.push(
        intl.formatMessage(
          {
            id: "organizations.details.tabs.custom-fields.pointers.min-date",
            defaultMessage: "Min. date: {date}",
          },
          {
            date: [
              intl.formatDate(options.MinimumDate),
              ...(dataType === 6 ? [intl.formatTime(options.MinimumDate)] : []),
            ].join(" "),
          },
        ),
      );
    }

    if (options?.MaximumDate) {
      arr.push(
        intl.formatMessage(
          {
            id: "organizations.details.tabs.custom-fields.pointers.max-date",
            defaultMessage: "Max. date: {date}",
          },
          {
            date: [
              intl.formatDate(options.MaximumDate),
              ...(dataType === 6 ? [intl.formatTime(options.MaximumDate)] : []),
            ].join(" "),
          },
        ),
      );
    }

    return arr;
  }, [dataType, intl, options]);

  return pointers;
};
