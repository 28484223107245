import { CellProps } from "react-table";

import { EditableCellInputProps } from "./table-columns.types";

import Input from "~/components/suite-ui/input";

/**
 *
 * @param editable indicates whether or not an edit component needs to be available for render
 * @param editInputProps props passed to the input field
 * @returns an string input as an editComponent for a table column definition
 */
export const getTableColumnInputEditComponent = <T extends object>({
  editable,
  editInputProps,
}: EditableCellInputProps) => {
  const EditComponent = editable
    ? ({ cell, onFieldUpdate }: CellProps<T>) => (
        <Input
          defaultValue={cell.value}
          onChange={(event) => onFieldUpdate(event.target.value || undefined)}
          small
          {...editInputProps}
        />
      )
    : undefined;

  return EditComponent;
};
