import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  ButtonGroup,
  CardContent,
  CardFooter,
  CardHeader,
  Checkbox,
  Dialog,
  DialogCard,
  DialogProps,
  FieldGroup,
} from "@new-black/lyra";

import { generateListOrdersForCustomerSearchListField } from "~/components/suite-composite/generate-list-orders-for-customer-search-list-field";
import { generateSearchOrdersSearchListField } from "~/components/suite-composite/generate-search-orders-search-list-field";

type SetCaseOrderModalProps = {
  isOpen?: DialogProps["isOpen"];
  onOpenChange: DialogProps["onOpenChange"];
  onSave?: (orderId?: number) => void;
  form?: string;
  userId: number;
  onChange?: (newValue?: number) => void;
  initialValue?: number;
};

export const SetCaseOrderModal = ({
  form,
  initialValue,
  isOpen,
  onChange,
  onOpenChange,
  onSave,
  userId,
}: SetCaseOrderModalProps) => {
  const intl = useIntl();

  const [orderId, setOrderId] = useState<number | undefined>(initialValue);

  const [onlyCustomerOrders, setOnlyCustomerOrders] = useState<boolean>(!initialValue);

  const ListCustomerOdersSearchListField = useMemo(
    () =>
      generateListOrdersForCustomerSearchListField({
        customerID: userId,
      }),
    [userId],
  );

  return (
    <Dialog isOpen={isOpen} onOpenChange={onOpenChange} maxWidth="lg">
      <DialogCard>
        <CardHeader
          title={intl.formatMessage({
            id: "generic.label.search-order",
            defaultMessage: "Search order",
          })}
        />

        <CardContent>
          <FieldGroup>
            {onlyCustomerOrders ? (
              <ListCustomerOdersSearchListField.SingleIDSearchListField.Controlled
                value={orderId}
                onChange={(value) => {
                  setOrderId(value);
                  onChange?.(value);
                }}
                hideHintLabel
              />
            ) : (
              <SearchOrdersSearchListField.SingleIDSearchListField.Controlled
                value={orderId}
                onChange={(value) => {
                  setOrderId(value);
                  onChange?.(value);
                }}
                hideHintLabel
              />
            )}

            <Checkbox
              isSelected={onlyCustomerOrders}
              onChange={(isSelected) => {
                setOnlyCustomerOrders(isSelected);
                setOrderId(undefined);
              }}
            >
              <FormattedMessage
                id="generic.label.only-orders-for-current-consumer"
                defaultMessage="Only orders for current consumer"
              />
            </Checkbox>
          </FieldGroup>
        </CardContent>
        <CardFooter>
          <ButtonGroup>
            <Button onPress={() => onOpenChange?.(false)} variant="secondary">
              <FormattedMessage id="generic.label.cancel" defaultMessage="Cancel" />
            </Button>

            <Button type={form ? "submit" : "button"} form={form} onPress={() => onSave?.(orderId)}>
              <FormattedMessage id="generic.label.save" defaultMessage="Save" />
            </Button>
          </ButtonGroup>
        </CardFooter>
      </DialogCard>
    </Dialog>
  );
};

const SearchOrdersSearchListField = generateSearchOrdersSearchListField({});
