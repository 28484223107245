import { useEffect, useMemo } from "react";

import { Separator } from "@new-black/lyra";
import { useRecoilValue, useResetRecoilState } from "recoil";

import { FullHeightGrid } from "../helper-components";
import ProductsList from "../products-list";
import {
  ProductSetAvailableProductsAtom,
  productSetModalAvailableItemsListHeightSelector,
  ProductSetSelectedAvailableProductsAtom,
} from "../state";
import { LIST_ITEM_SIZE, PRODUCT_SET_MODAL_SUB_HEADER_HEIGHT } from "../types";

import AvailableProductsListHeader from "./available-products-list-header";
import AvailableProductsListItemWrapper from "./available-products-list-item-wrapper";

import Grid from "~/components/suite-ui/grid";
import usePrevious from "~/hooks/suite-react-hooks/use-previous";

export interface IAvailableProductsList {
  placeholderImageUrl?: string;
  disabledAddProductsButton?: boolean;
  enlargedHeader: boolean;
  showQuantityInput?: boolean;
  hideSelectionColumn?: boolean;
}

const AvailableProductsList = ({
  disabledAddProductsButton,
  enlargedHeader,
  hideSelectionColumn,
  placeholderImageUrl,
  showQuantityInput,
}: IAvailableProductsList) => {
  const availableProducts = useRecoilValue(ProductSetAvailableProductsAtom);
  const previousAvailableProducts = usePrevious(availableProducts);

  const resetSelectedProducts = useResetRecoilState(ProductSetSelectedAvailableProductsAtom);

  // reset the selected products when the available products change
  useEffect(() => {
    // condition is necessary because otherwise we will reset the selected products
    // whenever we update the state of the available products
    // for example when updating the quantity of a product, we don't want to deselect the current selection
    // only because the state of the products has changed

    const isAvaliableProducsArrayTheSame =
      availableProducts.length == previousAvailableProducts?.length &&
      availableProducts.every(function ({ ID: availableProductID }, index) {
        return availableProductID === previousAvailableProducts[index].ID;
      });

    if (!isAvaliableProducsArrayTheSame) {
      resetSelectedProducts();
    }
  }, [resetSelectedProducts, availableProducts, previousAvailableProducts]);

  // get the available space for the list
  const listHeight = useRecoilValue(productSetModalAvailableItemsListHeightSelector);
  const listHeightForEnlargedHeader = useMemo(
    () => listHeight - PRODUCT_SET_MODAL_SUB_HEADER_HEIGHT,
    [listHeight],
  );

  return (
    <>
      {availableProducts.length ? (
        <FullHeightGrid container direction="column" wrap="nowrap">
          <Grid item>
            <AvailableProductsListHeader
              disabledAddProductsButton={disabledAddProductsButton}
              showQuantityHeader={showQuantityInput}
              hideCheckbox={hideSelectionColumn}
            />
            <Separator className="my-0" />
          </Grid>
          <Grid item xs>
            <ProductsList
              products={availableProducts}
              listHeight={enlargedHeader ? listHeightForEnlargedHeader : listHeight}
              itemSize={LIST_ITEM_SIZE}
              itemComponent={(props) => (
                <AvailableProductsListItemWrapper
                  {...props}
                  placeholderImageUrl={placeholderImageUrl}
                  disabledAddProductsButton={disabledAddProductsButton}
                  showQuantityInput={showQuantityInput}
                  hideCheckbox={hideSelectionColumn}
                />
              )}
            />
          </Grid>
        </FullHeightGrid>
      ) : null}
    </>
  );
};

export default AvailableProductsList;
