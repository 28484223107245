import classNames from "clsx";

import { PaneMainProps } from "./pane.types";
import { PaneFooter } from "./pane-footer";

export const PaneMain = ({ children, expandFooterOnSidebar, footer }: PaneMainProps) => {
  return (
    <>
      <main className={classNames("flex-1")}>{children}</main>
      {!!footer && !expandFooterOnSidebar ? <PaneFooter>{footer}</PaneFooter> : null}
    </>
  );
};
