import { isNil } from "lodash";

import type { TableRowDeleteActionProps } from "./table-types";

import DeleteActionButton from "~/components/shared/action-buttons/delete-action-button";

const TableRowDeleteAction = ({
  disabled,
  onAfterDelete,
  removeByIndex,
  rowIndex,
  tooltipTitle,
}: TableRowDeleteActionProps) => (
  <DeleteActionButton
    tooltip={tooltipTitle}
    isDisabled={disabled}
    onPress={() => {
      if (!isNil(rowIndex) && removeByIndex) {
        removeByIndex([rowIndex]);
        onAfterDelete?.();
      }
    }}
  />
);

export default TableRowDeleteAction;
