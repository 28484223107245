import { useMemo } from "react";
import { useIntl } from "react-intl";

import { IChapter } from "../chapter-finder.types";

import { useHasFunctionality } from "~/hooks/suite-react-hooks/use-has-functionality";
import { Functionalities, FunctionalityScope } from "~/types/functionalities";

export const usePromotionsChapters = (): IChapter[] => {
  const intl = useIntl();

  const { functionalityAllowed: discountsFunctionalityAllowed } = useHasFunctionality(
    Functionalities.DISCOUNTS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: discountsLayersFunctionalityAllowed } = useHasFunctionality(
    Functionalities.DISCOUNTS_LAYERS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: discountsCampaignsFunctionalityAllowed } = useHasFunctionality(
    Functionalities.DISCOUNTS_CAMPAIGNS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: discountsTemplatesFunctionalityAllowed } = useHasFunctionality(
    Functionalities.DISCOUNTS_TEMPLATES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: organizationUnitSetsFunctionalityAllowed } = useHasFunctionality(
    Functionalities.ORGANIZATION_UNIT_SETS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: loyaltyProgramsAllowed } = useHasFunctionality(
    Functionalities.LOYALTY_PROGRAMS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: loyaltyProgramGroupsAllowed } = useHasFunctionality(
    Functionalities.LOYALTY_PROGRAMS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: personalizedPromotionsAllowed } = useHasFunctionality(
    Functionalities.PERSONALIZED_PROMOTIONS,
    FunctionalityScope.View,
  );

  const promotionsChapters = useMemo<IChapter[]>(() => {
    let chapters: IChapter[] = [];

    if (
      discountsFunctionalityAllowed &&
      discountsLayersFunctionalityAllowed &&
      organizationUnitSetsFunctionalityAllowed &&
      discountsCampaignsFunctionalityAllowed &&
      discountsTemplatesFunctionalityAllowed
    ) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.promotions-engine",
            defaultMessage: "Promotion Engine",
          }),
          path: "/promotions",
        },
      ];
    }

    if (personalizedPromotionsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.personalized-promotions",
          defaultMessage: "Personalized Promotions",
        }),
        path: "/personalized-promotions",
      });
    }

    if (discountsCampaignsFunctionalityAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.promotions.chapters.campaigns",
            defaultMessage: "Campaigns",
          }),
          path: "/campaigns",
        },
      ];
    }

    if (discountsTemplatesFunctionalityAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "module.promotions.chapters.templates",
            defaultMessage: "Templates",
          }),
          path: "/discount-templates",
        },
      ];
    }

    if (loyaltyProgramsAllowed || loyaltyProgramGroupsAllowed) {
      chapters = [
        ...chapters,
        {
          title: intl.formatMessage({
            id: "generic.title.loyalty-programs",
            defaultMessage: "Loyalty Programs",
          }),
          path: "/loyalty-programs",
        },
      ];
    }

    return chapters;
  }, [
    discountsFunctionalityAllowed,
    discountsLayersFunctionalityAllowed,
    organizationUnitSetsFunctionalityAllowed,
    discountsCampaignsFunctionalityAllowed,
    discountsTemplatesFunctionalityAllowed,
    personalizedPromotionsAllowed,
    loyaltyProgramsAllowed,
    loyaltyProgramGroupsAllowed,
    intl,
  ]);

  return promotionsChapters;
};
