import { useCallback, useMemo, useState } from "react";

import { IEvaServiceDefinition } from "@springtree/eva-services-core";
import { useQuery } from "@tanstack/react-query";
import ky from "ky";

import {
  IUseSearchListFieldServiceProps,
  SearchListFieldService,
} from "./search-list-field-generator.types";

import useDebounce from "~/hooks/suite-react-hooks/use-debounce";
import { FIVE_MINUTES } from "~/util/base-values";

const defaultDisableErrorNotificationsPredicate = (serviceError?: Error) => {
  if (serviceError?.name === "HTTPError") {
    const httpError = serviceError as ky.HTTPError;
    return (
      httpError.response.status === 403 ||
      httpError.response.status === 404 ||
      httpError.response.status === 500
    );
  }

  return false;
};

export const useSearchListFieldService = <SVC extends IEvaServiceDefinition>({
  cacheTime,
  configureLoadMoreButton,
  disableErrorNotifications = defaultDisableErrorNotificationsPredicate,
  enabled = true,
  getQueryRequest,
  initialRequest,
  loaderProps,
  ouId,
  query: baseQuery,
  queryKey,
  refetchOnFocus = false,
  setQueryRequest,
  staleTime,
}: IUseSearchListFieldServiceProps<SVC>): SearchListFieldService<SVC> => {
  const [localQuery, setLocalQuery] = useState<string | undefined>();

  // Memoized query args
  // All prop values have precedence over loader values,
  // so the loader values can be overridden

  const getEffectiveQueryKey = useCallback(
    (req: SVC["request"]) => {
      if (queryKey) {
        return typeof queryKey === "function" ? queryKey(req) : queryKey;
      }
      return loaderProps?.queryProps.loaderKey;
    },
    [loaderProps?.queryProps.loaderKey, queryKey],
  );

  const effectiveOuId = useMemo(
    () => ouId ?? loaderProps?.queryProps.ouId,
    [loaderProps?.queryProps.ouId, ouId],
  );

  const effectiveDisableErrorNotifications = useMemo(
    () => disableErrorNotifications ?? loaderProps?.queryProps.disableErrorNotification,
    [disableErrorNotifications, loaderProps?.queryProps.disableErrorNotification],
  );

  const effectiveRefetchOnFocus = useMemo(
    () => refetchOnFocus ?? loaderProps?.queryProps.refetchOnWindowFocus,
    [loaderProps?.queryProps.refetchOnWindowFocus, refetchOnFocus],
  );

  const effectiveStaleTime = useMemo(
    () => staleTime ?? loaderProps?.queryProps.staleTime ?? 0,
    [loaderProps?.queryProps.staleTime, staleTime],
  );

  // cacheTime > staleTime > loaderProps?.queryProps.cacheTime > loaderProps?.queryProps.staleTime > 0
  const effectiveCacheTime = useMemo(
    () =>
      Math.max(
        cacheTime ??
          staleTime ??
          loaderProps?.queryProps.cacheTime ??
          loaderProps?.queryProps.staleTime ??
          0,
        FIVE_MINUTES,
      ),
    [cacheTime, loaderProps?.queryProps.cacheTime, loaderProps?.queryProps.staleTime, staleTime],
  );

  const effectiveInitialRequest = useMemo(
    () => initialRequest ?? loaderProps?.request,
    [initialRequest, loaderProps?.request],
  );

  // Local request state
  const [request, setRequest] = useState<SVC["request"]>(effectiveInitialRequest);
  const debouncedRequest = useDebounce(request, 500);

  // Since the request is debounced, we want to keep a local loading state which is set to `true`
  // right after the user clicks on the "Load more" button, and set to `false` when the query is settled
  const [isLoadMoreLoading, setIsLoadMoreLoading] = useState(false);

  const query = useQuery({
    ...baseQuery(
      debouncedRequest,
      getEffectiveQueryKey(debouncedRequest),
      effectiveOuId,
      effectiveDisableErrorNotifications,
    ),
    refetchOnWindowFocus: effectiveRefetchOnFocus,
    staleTime: effectiveStaleTime,
    cacheTime: effectiveCacheTime,
    keepPreviousData: true,
    onSettled: () => setIsLoadMoreLoading(false),
    enabled,
  });

  const input = useMemo(
    () => (getQueryRequest ? getQueryRequest(request) : localQuery),
    [getQueryRequest, localQuery, request],
  );

  const setInput = useCallback(
    (newValue: string | undefined) => {
      if (newValue !== input) {
        if (setQueryRequest) {
          setRequest((prev) => setQueryRequest(prev, newValue));
          return;
        }
        setLocalQuery(newValue);
      }
    },
    [input, setQueryRequest],
  );

  return [
    [input, setInput],
    query,
    [request, setRequest],
    [configureLoadMoreButton, isLoadMoreLoading, setIsLoadMoreLoading],
  ];
};
