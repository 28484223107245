import { useCallback } from "react";
import { useLocation } from "react-router-dom";

import { NavigateOptionsWithForce, useNavigate } from "~/components/routing";

/**
 * Custom hook that provides a function to navigate back to the parent route, using the current location as a reference.
 *
 * @param {number} steps The number of steps to go back.
 *
 * @returns {Function} The navigateBackToParent function.
 */
export function useNavigateBackRelatively(steps = 1) {
  const navigate = useNavigate();
  const location = useLocation();

  const navigateBackToParent = useCallback(
    (options?: NavigateOptionsWithForce | undefined) =>
      navigate(
        location.pathname
          .split("/")
          .slice(0, -1 * steps)
          .join("/"),
        options,
      ),
    [location.pathname, navigate, steps],
  );

  return navigateBackToParent;
}
