import { useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";

import { getDiscountTemplateByIDResultSelector, getDiscountTemplateByIDServiceState } from ".";

import { useHandleSWRFamily } from "~/hooks/suite-react-hooks/use-handle-swr";

const useGetDiscountTemplateById = (discountTemplateId?: number) => {
  const data = hooks.useGetState(getDiscountTemplateByIDResultSelector(discountTemplateId));

  const isLoading = hooks.useIsLoading({
    state: getDiscountTemplateByIDServiceState.response(discountTemplateId),
  });
  const isLoadingWithoutResponse = useMemo(() => !data && isLoading, [data, isLoading]);

  const refetch = hooks.useSetStale({
    staleState: getDiscountTemplateByIDServiceState.stale(discountTemplateId),
  });

  useHandleSWRFamily(getDiscountTemplateByIDServiceState, discountTemplateId);

  return { data, isLoading, isLoadingWithoutResponse, refetch };
};

export default useGetDiscountTemplateById;
