/* eslint-disable react/display-name */
import { Fragment, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import type { Cell, Column, Row } from "react-table";

import classNames from "clsx";

import {
  BodyTableCell,
  BodyTableCellContainer,
  BodyTableRow,
  TableBox,
} from "../table-helper-components";
import { ITableColumn, TABLE_EXPANDER_COLUMN_ID, TableRendererOptions } from "../table-types";

import LinkButton from "~/components/suite-ui/link-button";
import Text from "~/components/suite-ui/text";

const useRenderTableRows = ({
  childPropKey,
  columns,
  columnVerticalAlignment = "middle",
  contextClickState,
  contextMenu,
  data,
  editMode,
  expandedState,
  handleContextRightClick,
  hasCustomRowActions,
  isPaginationNeeded,
  lastRowRef,
  onRowClick,
  page,
  prepareRow,
  rows,
  setActiveContextRow,
  tableOptions,
  toggleRowExpanded,
}: TableRendererOptions) => {
  const intl = useIntl();
  /**
   * This method is responsible for what we display in the table cell
   * @param cell - The Table cell to render
   */
  const renderTableCell = (
    row: Row,
    cell: Cell,
    disabled?: boolean,
    rowValue?: any,
    idx?: number,
    rowId?: string[],
  ) => {
    /**
     * First we check if we need to render this cell in it's edit mode
     * if there is an edit mode configured in the column
     */
    const { availableOptions = [], editComponent } = cell.column;
    if (editComponent && (cell.row.state.editing === true || editMode)) {
      const EditComponent = editComponent;
      return (
        <TableBox className="py-0">
          <EditComponent
            cell={cell}
            onFieldUpdate={(state) => {
              const row = data[cell.row.index];
              row[cell.column.id] = state as string;
            }}
          />
        </TableBox>
      );
    }

    /**
     * If it's not in edit mode it can still be an option from a list of
     * available  In that case we need to display the label instead
     * of the raw value.
     */
    const displayOption = availableOptions.find((option) => option.value === cell.value);

    if (displayOption) {
      return displayOption.label;
    }

    const getData = () => {
      const accessor = (cell.column as Column).accessor;
      if (!accessor) {
        return undefined;
      }
      if (typeof accessor === "string") {
        return rowValue?.[accessor];
      }
      if (!idx) {
        return undefined;
      }
      return accessor(rowValue, idx, { subRows: [], depth: rowId?.length ?? 0, data: [] });
    };

    /**
     * If this is just a plain column value we fallback to the default
     * Cell renderer of react-table
     */
    return (
      <BodyTableCell
        align={(cell.column as Partial<ITableColumn<any>>).align ?? "left"}
        columnVerticalAlignment={columnVerticalAlignment}
        disabled={disabled}
        className={
          typeof tableOptions?.tableCellClassName === "function"
            ? tableOptions?.tableCellClassName(row, cell)
            : tableOptions?.tableCellClassName
        }
      >
        {cell.render(
          "Cell",
          rowValue
            ? {
                value: getData(),
                row: {
                  ...cell.row,
                  original: rowValue,
                  id: rowId?.join("."),
                  depth: rowId?.length ?? 0,
                  isExpanded: expandedState?.[rowId?.join(".") ?? ""],
                  toggleRowExpanded: (value?: boolean) =>
                    toggleRowExpanded(
                      [rowId?.join(".") ?? ""],
                      value ?? !expandedState?.[rowId?.join(".") ?? ""],
                    ),
                },
              }
            : undefined,
        )}
      </BodyTableCell>
    );
  };

  const renderRowFooter = (row: Row<any>) => {
    const renderRowFooterInTD = !(tableOptions?.renderRowFooterWithoutTD ?? false);
    const renderMultipleRowFooters = tableOptions?.renderMultipleFooterRows ?? false;
    const rowFooter = tableOptions?.rowFooter;
    if (!rowFooter) {
      return null;
    }
    const trProps = {
      ...row.getRowProps(),
      id: `row-${row.id}-row-footer`,
      key: `row-${row.id}-row-footer`,
      className: classNames(
        "[border-bottom:1px_solid_var(--legacy-eva-color-light-3)]",
        tableOptions.rowFooterClassName,
      ),
    };
    const footer = rowFooter(row, trProps);
    if (!footer) {
      return null;
    }
    const footerComponent = renderRowFooterInTD ? (
      <td id={`row-${row.id}-row-footer-col-0`} colSpan={row.cells.length} className="p-0">
        {footer}
      </td>
    ) : (
      footer
    );
    return !renderMultipleRowFooters ? <tr {...trProps}>{footerComponent}</tr> : footerComponent;
  };

  const RenderRecursiveTableRows = ({
    data,
    depth,
    parentId,
    row,
  }: {
    row: Row<any>;
    data: any;
    depth: number;
    parentId: string[];
  }): React.ReactNode => {
    const [isExpanded, setIsExpanded] = useState(false);
    const maxChildrenBeforeCollapse = tableOptions?.maxChildRowsBeforeShowMore ?? "all";
    const shouldRowBeDisabled =
      tableOptions?.disableRow && !!tableOptions?.disableRow(row.original);

    if (
      !childPropKey ||
      (tableOptions?.recursionCutoffDepth !== undefined &&
        tableOptions?.recursionCutoffDepth === depth)
    ) {
      return null;
    }
    const childValues = data?.[childPropKey];
    if (!childValues || !Array.isArray(childValues) || childValues.length === 0) {
      return null;
    }
    const collapsedChildren =
      maxChildrenBeforeCollapse === "all"
        ? childValues
        : childValues.slice(0, maxChildrenBeforeCollapse);

    return (
      <>
        {(isExpanded ? childValues : collapsedChildren).map((value, childIdx) => (
          <Fragment key={`${parentId.join(".")}-recursive-${childIdx}`}>
            <BodyTableRow
              isPaginationNeeded={isPaginationNeeded}
              selected={row.isSelected}
              {...row.getRowProps()}
              ref={row.index === rows.length - 1 ? lastRowRef : null}
              disabled={shouldRowBeDisabled}
              disableHoverHighlightEffect={
                typeof tableOptions?.disableHoverHighlightEffect === "function"
                  ? tableOptions?.disableHoverHighlightEffect(value)
                  : tableOptions?.disableHoverHighlightEffect
              }
              className={
                typeof tableOptions?.subRowContainerClassName === "function"
                  ? tableOptions?.subRowContainerClassName(value)
                  : tableOptions?.subRowContainerClassName
              }
              // TODO: Designer opinion needed on shading subrows
              // style={{ backgroundColor: `rgba(0,0,0, ${Math.min(depth * 0.03, 100)})` }}
            >
              {row.cells.map((rowCell, idx, allCells) => (
                <BodyTableCellContainer
                  onContextMenu={(e: any) => {
                    if (!editMode && contextMenu && rowCell.column.id !== "selection") {
                      e.preventDefault();
                      setActiveContextRow(value);
                      handleContextRightClick(e);
                    }
                  }}
                  onClick={() => {
                    if (
                      onRowClick &&
                      !editMode &&
                      contextClickState.mouseY === null &&
                      rowCell.column.id !== "selection"
                    ) {
                      onRowClick(value, idx);
                    }
                  }}
                  clickable={!!onRowClick && rowCell.column.id !== "selection"}
                  minWidth={rowCell.column.minWidth}
                  align={(rowCell.column as Partial<ITableColumn<any>>).align ?? "left"}
                  columnVerticalAlignment={columnVerticalAlignment}
                  {...rowCell.getCellProps()}
                  key={`${rowCell.getCellProps().key}_subcomponent`}
                  style={
                    idx ===
                    allCells.findIndex((cell) => {
                      const isExpandCell = cell.column.id === TABLE_EXPANDER_COLUMN_ID;

                      return tableOptions?.indentOnlyExpandCell ? isExpandCell : !isExpandCell;
                    })
                      ? {
                          paddingLeft: `${
                            depth * (tableOptions?.expandableRowsIndent ?? 70) +
                            (tableOptions?.indentOnlyExpandCell && value?.[childPropKey]?.length
                              ? 0
                              : 40)
                          }px`,
                        }
                      : undefined
                  }
                >
                  <>
                    {renderTableCell(
                      row,
                      rowCell,
                      shouldRowBeDisabled,
                      value,
                      rowCell.column.sortedIndex,
                      [...parentId, childIdx.toString()],
                    )}
                  </>
                </BodyTableCellContainer>
              ))}
            </BodyTableRow>
            {expandedState?.[[...parentId, childIdx.toString()].join(".")] &&
            value?.[childPropKey] ? (
              <RenderRecursiveTableRows
                data={value}
                depth={depth + 1}
                parentId={[...parentId, childIdx.toString()]}
                row={row}
              />
            ) : null}
          </Fragment>
        ))}
        {maxChildrenBeforeCollapse !== "all" && collapsedChildren.length !== childValues.length ? (
          <tr
            {...row.getRowProps()}
            id={`row-${parentId.join(".")}-child-row-expanse`}
            key={`row-${parentId.join(".")}-child-row-expanse`}
            className="[&:last-child>td]:[border-bottom:none] [&>td]:[border-bottom:1px_solid_var(--legacy-eva-color-light-3)]"
          >
            <td
              colSpan={row.cells.length}
              id={`row-${parentId.join(".")}-child-row-expanse-col-0`}
              style={
                tableOptions?.childRowShowMoreLessToggleRowApplyIndent
                  ? { paddingLeft: `${depth * (tableOptions?.expandableRowsIndent ?? 70)}px` }
                  : undefined
              }
            >
              {tableOptions?.childRowShowMoreLessToggleElement ? (
                <>
                  {tableOptions?.childRowShowMoreLessToggleElement({
                    isExpanded,
                    setIsExpanded,
                  })}
                </>
              ) : (
                <div className="flex w-full items-center justify-start p-0 py-3">
                  <LinkButton
                    variant="body2"
                    onClick={(e) => {
                      e.stopPropagation();
                      setIsExpanded((value) => !value);
                    }}
                  >
                    {isExpanded ? (
                      <FormattedMessage id="generic.label.show-less" defaultMessage="Show less" />
                    ) : (
                      <FormattedMessage
                        id="generic.label.show-more-total"
                        values={{ total: childValues.length }}
                        defaultMessage="Show more ({total} total)"
                      />
                    )}
                  </LinkButton>
                </div>
              )}
            </td>
          </tr>
        ) : null}
      </>
    );
  };

  const renderTableRow = (row: Row<any>) => {
    const shouldRowBeDisabled =
      tableOptions?.disableRow && !!tableOptions?.disableRow(row.original);

    const getBaseTableRow = (row: Row<any>) => {
      return (
        <BodyTableRow
          isPaginationNeeded={isPaginationNeeded}
          selected={row.isSelected}
          {...row.getRowProps()}
          ref={row.index === rows.length - 1 ? lastRowRef : null}
          disabled={shouldRowBeDisabled}
          disableHoverHighlightEffect={
            typeof tableOptions?.disableHoverHighlightEffect === "function"
              ? tableOptions?.disableHoverHighlightEffect(row.original)
              : tableOptions?.disableHoverHighlightEffect
          }
          className={
            typeof tableOptions?.rowContainerClassName === "function"
              ? tableOptions?.rowContainerClassName(row)
              : tableOptions?.rowContainerClassName
          }
        >
          {row.cells.map((rowCell, index) => (
            <BodyTableCellContainer
              onContextMenu={(e: any) => {
                if (!editMode && contextMenu && rowCell.column.id !== "selection") {
                  e.preventDefault();
                  setActiveContextRow(row?.original);
                  handleContextRightClick(e);
                }
              }}
              onClick={() => {
                if (
                  onRowClick &&
                  !editMode &&
                  contextClickState.mouseY === null &&
                  rowCell.column.id !== "selection"
                ) {
                  onRowClick(row?.original, index);
                }
              }}
              clickable={!!onRowClick && rowCell.column.id !== "selection"}
              minWidth={rowCell.column.minWidth}
              align={(rowCell.column as Partial<ITableColumn<any>>).align ?? "left"}
              columnVerticalAlignment={columnVerticalAlignment}
              {...rowCell.getCellProps()}
              key={index}
              className={
                typeof tableOptions?.tableCellContainerClassName === "function"
                  ? tableOptions?.tableCellContainerClassName(row, rowCell)
                  : tableOptions?.tableCellContainerClassName
              }
            >
              {renderTableCell(row, rowCell, shouldRowBeDisabled)}
            </BodyTableCellContainer>
          ))}
        </BodyTableRow>
      );
    };

    if (renderRowFooter(row) || (row.isExpanded && childPropKey && row.original?.[childPropKey])) {
      return (
        <Fragment key={row.index}>
          {getBaseTableRow(row)}
          {renderRowFooter(row)}
          {row.isExpanded && childPropKey && row.original?.[childPropKey] ? (
            <RenderRecursiveTableRows row={row} data={row.original} depth={1} parentId={[row.id]} />
          ) : null}
        </Fragment>
      );
    }

    return getBaseTableRow(row);
  };
  return () => {
    if ((page && page.length) || (rows && rows.length)) {
      if (isPaginationNeeded && page) {
        return page.map((row) => {
          prepareRow(row);
          return renderTableRow(row);
        });
      }
      return rows.map((row) => {
        prepareRow(row);
        return renderTableRow(row);
      });
    }
    return (
      <BodyTableRow isPaginationNeeded={isPaginationNeeded}>
        <BodyTableCellContainer
          colSpan={
            columns.length +
            ((tableOptions?.editRows && !tableOptions?.editAllRows) ||
            tableOptions?.deleteRow ||
            hasCustomRowActions
              ? 1
              : 0) +
            (tableOptions?.selectRows ? 1 : 0)
          }
          align="left"
          className="text-legacy-base pl-[20px] text-primary"
        >
          <TableBox className="py-4">
            <Text
              color="textPrimary"
              variant="body2"
              className={tableOptions?.noRowsAvailableTextClassname}
            >
              {(tableOptions && tableOptions.tableStrings?.noRowsAvailable) ??
                intl.formatMessage({
                  id: "generic.label.no-rows-available",
                  defaultMessage: "No rows available",
                })}
            </Text>
          </TableBox>
        </BodyTableCellContainer>
      </BodyTableRow>
    );
  };
};

export default useRenderTableRows;
