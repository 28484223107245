import { DetailedHTMLProps } from "react";
import { useIntl } from "react-intl";

import { SvgIcon } from "@new-black/lyra";

import { OrganizationUnitSelectorFilterAtom } from "./organization-unit-selector.state";

import Input from "~/components/suite-ui/input";
import useRecoilDebouncedValue from "~/hooks/suite-react-hooks/use-recoil-debounced-value";

const StyledMenuItem = (
  props: DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) => <div className="mb-5 w-full min-w-[318px]" {...props} />;

const StyleInputContainer = (
  props: DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) => <div className="mb-5 flex items-center px-5" {...props} />;

export interface IOrganizationUnitFilterProps {
  inputValue?: string;
}

const OrganizationUnitFilter = () => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useRecoilDebouncedValue(OrganizationUnitSelectorFilterAtom);

  return (
    <StyledMenuItem>
      <StyleInputContainer>
        <Input
          autoFocus
          value={inputValue ?? ""}
          onKeyDown={(e) => {
            e.stopPropagation();
          }}
          onChange={(event) => setInputValue(event.target.value)}
          label={intl.formatMessage({
            id: "generic.label.organization-unit",
            defaultMessage: "Organization unit",
          })}
          endIcon={<SvgIcon name="search" className="mr-2 text-secondary" />}
          fullWidth
        />
      </StyleInputContainer>
    </StyledMenuItem>
  );
};

export default OrganizationUnitFilter;
