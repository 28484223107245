import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getSettingLoaderQuery,
  serviceQuery: getSettingQuery,
  serviceQueryKeys: getSettingQueryKeys,
  useServiceQueryHook: useGetSettingQuery,
} = createServiceQuery(CoreManagement.GetSetting);

export const {
  serviceLoaderQuery: listSettingsLoaderQuery,
  serviceQueryKeys: listSettingsQueryKeys,
  useServiceQueryHook: useListSettingsQuery,
} = createServiceQuery(CoreManagement.ListSettings, true);

export const {
  serviceLoaderQuery: getAvailableSettingsLoaderQuery,
  serviceQuery: getAvailableSettingsQuery,
  serviceQueryKeys: getAvailableSettingsQueryKeys,
  useServiceQueryHook: useGetAvailableSettingsQuery,
} = createServiceQuery(CoreManagement.GetAvailableSettings);
