import { useEffect } from "react";

import { isEqual } from "lodash";
import { useRecoilState } from "recoil";

import { safelyParseWorkspaceSettings } from "../helpers";
import { isWorkspaceChangedState } from "../workspaces.state";
import { EKeyName, IPageConfiguration } from "../workspaces.types";

import { IWorkspaceSelectorItem } from "~/components/shared/menu/workspace/workspace-selector";
import { removeEmptyObjects, removeUndefinedValues } from "~/util/helper";

const useChangeWorkspace = <TWorkspace>(
  selectedWorkspace?: IWorkspaceSelectorItem,
  keyName?: EKeyName,
  currentPageConfiguration?: IPageConfiguration<TWorkspace>,
  workspaceState?: Partial<TWorkspace>,
  defaultWorkspaceSettings?: { [key: string]: any },
) => {
  const [isWorkspaceChanged, setIsWorkspaceChanged] = useRecoilState(
    isWorkspaceChangedState({
      keyname: keyName,
      propertyNames: currentPageConfiguration?.propertyNames
        .map((item) => item?.toString())
        .join(", "),
    }),
  );

  // Set changed state whenever the workspace changes
  useEffect(() => {
    if (workspaceState && selectedWorkspace) {
      const selectedWorkspaceSettings = safelyParseWorkspaceSettings(
        selectedWorkspace?.Settings,
        defaultWorkspaceSettings,
      );

      // If the parsing was successful, then compare the local workspace settings with the saved workspace settings
      if (selectedWorkspaceSettings.success) {
        setIsWorkspaceChanged(
          !isEqual(
            removeEmptyObjects(removeUndefinedValues(workspaceState)),
            removeEmptyObjects(removeUndefinedValues(selectedWorkspaceSettings.value)),
          ),
        );
      } else {
        // If the parsing was unsuccessful, then the workspace settings are reset to default, therefore it is not equal to the saved workspace settings
        setIsWorkspaceChanged(true);
      }
    }
  }, [selectedWorkspace, setIsWorkspaceChanged, workspaceState, defaultWorkspaceSettings]);

  return { isWorkspaceChanged };
};

export default useChangeWorkspace;
