import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { omit } from "lodash";
import { selector } from "recoil";

import { SupportedFunctionalitiesMap } from "~/types/functionalities";

export const supportedFunctionalitiesService = builders.buildServiceState({
  requireEmployee: true,
  requireLoggedIn: true,
  allowEmptyRequest: true,
  key: "supportedFunctionalitiesService",
  service: CoreManagement.GetSupportedFunctionalities,
});

export const supportedFunctionalitiesResponse = selector({
  key: "supportedFunctionalitiesService:response",
  get: ({ get }) => {
    const scopedFunctionalities = get(
      supportedFunctionalitiesService.response,
    )?.ScopedFunctionalities;
    return scopedFunctionalities
      ? [...scopedFunctionalities].sort((a, b) => a.Name.localeCompare(b.Name))
      : undefined;
  },
});

export const supportedFunctionalitiesMappedResponse = selector({
  key: "supportedFunctionalitiesService:response:mapped",
  get: ({ get }) => {
    const scopedFunctionalities = get(
      supportedFunctionalitiesService.response,
    )?.ScopedFunctionalities;

    if (!scopedFunctionalities) return undefined;

    const result: SupportedFunctionalitiesMap = {};

    scopedFunctionalities.forEach((functionality) => {
      result[functionality.Name] = omit(functionality, "Name");
    });

    return result;
  },
});
