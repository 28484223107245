import { FormattedMessage } from "react-intl";

import { ButtonGroup } from "@new-black/lyra";

import AddActionButton from "../action-buttons/add-action-button";
import EditActionButton from "../action-buttons/edit-action-button";

import { SidePaneProductsFilterProps } from ".";

import LinkButton from "~/components/suite-ui/link-button";
import Text from "~/components/suite-ui/text";

export const SidePaneProductsFilterHeader = ({
  isDisabled,
  onActionButtonClick,
  productsIDs,
  setProductsIDs,
}: Pick<SidePaneProductsFilterProps, "productsIDs" | "setProductsIDs" | "isDisabled"> & {
  onActionButtonClick: () => void;
}) => (
  <div className="flex items-center justify-between p-5">
    <Text variant="h3">
      <FormattedMessage id="generic.label.products" defaultMessage="Products" />
    </Text>

    <ButtonGroup className="items-center">
      {productsIDs ? (
        <LinkButton onClick={() => setProductsIDs(undefined)} className="!text-[13px]">
          <FormattedMessage
            id="generic.label.clear-all-products"
            defaultMessage="Clear all products"
          />
        </LinkButton>
      ) : null}

      {productsIDs ? (
        <EditActionButton onPress={onActionButtonClick} isDisabled={isDisabled} />
      ) : (
        <AddActionButton onPress={onActionButtonClick} isDisabled={isDisabled} />
      )}
    </ButtonGroup>
  </div>
);
