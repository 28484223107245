import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

// GetPaymentTypesForFilter
export const {
  serviceLoaderQuery: getPaymentTypesForFilterLoaderQuery,
  serviceQuery: getPaymentTypesForFilterQuery,
  serviceQueryKeys: getPaymentTypesForFilterQueryKeys,
  useServiceQueryHook: useGetPaymentTypesForFilterQuery,
} = createServiceQuery(CoreManagement.GetPaymentTypesForFilter, false, true);

// ListPaymentTransactions
export const {
  serviceLoaderQuery: listPaymentTransactionsLoaderQuery,
  serviceQuery: listPaymentTransactionsQuery,
  serviceQueryKeys: listPaymentTransactionsQueryKeys,
  useServiceQueryHook: useListPaymentTransactionsQuery,
} = createServiceQuery(CoreManagement.ListPaymentTransactions, false, true);

// ListPaymentSettlements
export const {
  serviceLoaderQuery: listPaymentSettlementsLoaderQuery,
  serviceQuery: listPaymentSettlementsQuery,
  serviceQueryKeys: listPaymentSettlementsQueryKeys,
  useServiceQueryWithRequest: useListPaymentSettlementsQueryWithRequest,
} = createServiceQuery(CoreManagement.ListPaymentSettlements, false, true);

// ListPaymentSettlementFiles
export const {
  serviceLoaderQuery: listPaymentSettlementFilesLoaderQuery,
  serviceQuery: listPaymentSettlementFilesQuery,
  serviceQueryKeys: listPaymentSettlementFilesQueryKeys,
  useServiceQueryWithRequest: useListPaymentSettlementFilesQueryWithRequest,
} = createServiceQuery(CoreManagement.ListPaymentSettlementFiles, false, true);

// GetAvailablePaymentSettlementFileHandlers
export const { serviceQuery: getAvailablePaymentSettlementFileHandlersQuery } = createServiceQuery(
  CoreManagement.GetAvailablePaymentSettlementFileHandlers,
  false,
);
