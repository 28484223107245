import { useCallback, useState } from "react";
import { useIntl } from "react-intl";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";

import { entityTranslationsState } from "../state";
import { EntityTranslationFieldIdentifiers, EntityTranslationFieldUpdating } from "../types";

import { toast } from "~/components/suite-ui/toast";

const useUpdateEntityTranslationWithoutInitialCountryId = ({
  entityField,
  entityID,
  entityType,
  translations,
}: EntityTranslationFieldIdentifiers & { translations?: EntityTranslationFieldUpdating }) => {
  const intl = useIntl();
  const [isLoading, setIsLoading] = useState(false);

  const deleteEntityTranslation = hooks.useCallService({ service: Core.DeleteEntityTranslation });
  const replaceEntityTranslation = hooks.useCallService({ service: Core.ReplaceEntityTranslation });
  const setListEntitiesResponseStale = hooks.useSetStale({
    staleState: entityTranslationsState.stale({
      entityType,
      entityID,
      entityField,
    }),
  });

  const updateEntityTranslationWithoutInitialCountryId = useCallback(
    async (countryID: string, languageID: string, value: string) => {
      setIsLoading(true);
      const toastId = toast.loading(
        translations?.loadingCreateUpdate ??
          intl.formatMessage({
            id: "generic.label.entity-translation.create-update.loading",
            defaultMessage: "Updating/creating entity translation...",
          }),
      );

      try {
        const deleteResult = await deleteEntityTranslation({
          EntityField: entityField,
          EntityID: entityID,
          EntityType: entityType,
          LanguageID: languageID,
        });

        if (deleteResult && !deleteResult.Error) {
          const editResult = await replaceEntityTranslation({
            EntityField: entityField,
            EntityID: entityID,
            EntityType: entityType,
            CountryID: countryID,
            LanguageID: languageID,
            Value: value,
          });

          if (editResult && !editResult.Error) {
            toast.success(
              translations?.successfullyCreatedUpdated ??
                intl.formatMessage({
                  id: "generic.label.entity-translation.create-updated.success",
                  defaultMessage: "Successfully updated/created entity translation",
                }),
              { id: toastId },
            );
            setListEntitiesResponseStale();
          } else {
            throw new Error();
          }
        } else {
          throw new Error();
        }
      } catch {
        toast.error(
          intl.formatMessage({
            id: "generic.message.something-went-wrong",
            defaultMessage: "Something went wrong.",
          }),
          { id: toastId },
        );
      } finally {
        setIsLoading(false);
      }
    },
    [
      deleteEntityTranslation,
      entityField,
      entityID,
      entityType,
      intl,
      replaceEntityTranslation,
      setListEntitiesResponseStale,
      translations?.loadingCreateUpdate,
      translations?.successfullyCreatedUpdated,
    ],
  );

  return {
    updateEntityTranslationWithoutInitialCountryId,
    isLoading,
  };
};

export default useUpdateEntityTranslationWithoutInitialCountryId;
