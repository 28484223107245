import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Table as LyraTable } from "@new-black/lyra";
import { useQuery } from "@tanstack/react-query";

import { useProductSummariesTableColumns } from "./use-product-summaries-table-columns";

import Table from "~/components/suite-ui/table";
import { getProductsQuery } from "~/models/products";
import { IProduct } from "~/types/product";

interface IProductSummariesTableProps {
  productIDs: number[];
  "aria-label"?: string;
  emptyTableMessage?: string;
  variant?: "material" | "lyra";
  setProductIDs: Dispatch<SetStateAction<number[]>>;
}

/**
 * Displays a table containing product summaries for an array of Product IDs which is provided.
 */
export function ProductSummariesTable({
  emptyTableMessage,
  productIDs,
  setProductIDs,
  variant = "material",
  ...props
}: IProductSummariesTableProps) {
  const intl = useIntl();
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(10);
  const [tableData, setTableData] = useState<IProduct[]>();

  const onDelete = useCallback(
    (id: number) => {
      setProductIDs((prev) => {
        const filtered = prev.filter((productId) => productId !== id);

        if (filtered.length === 0) {
          setTableData([]);
        }
        return filtered;
      });
    },
    [setProductIDs],
  );

  const columns = useProductSummariesTableColumns({
    onDelete,
  });

  const { data: productsDetailed } = useQuery({
    ...getProductsQuery(
      {
        ProductIDs: productIDs,
        IncludedFields: [
          "primary_image",
          "product_name",
          "custom_id",
          "backend_id",
          "product_id",
          "display_value",
        ],
      },
      productIDs,
    ),
    enabled: productIDs.length > 0,
    select: (response) => {
      const productObject = response?.Products;

      if (productObject === undefined) {
        return undefined;
      }

      return Object.values(productObject);
    },
  });

  useEffect(() => {
    if (productsDetailed !== undefined) {
      setTableData(productsDetailed);
    }
  }, [productsDetailed]);

  if (variant === "lyra") {
    return (
      <LyraTable<IProduct>
        columns={columns.lyraColumns}
        data={tableData ?? []}
        limit={limit}
        setLimit={setLimit}
        start={skip}
        setStart={setSkip}
        total={tableData?.length ?? 0}
        options={{ emptyMessage: emptyTableMessage }}
        aria-label={
          props["aria-label"] ??
          intl.formatMessage({ id: "generic.label.products", defaultMessage: "Products" })
        }
      />
    );
  }

  return (
    <Table
      columns={columns.materialColumns}
      data={tableData ?? []}
      limit={limit}
      setLimit={setLimit}
      skip={skip}
      setSkip={setSkip}
      total={tableData?.length ?? 0}
      options={{ hideHeader: true, tableStrings: { noRowsAvailable: emptyTableMessage } }}
    />
  );
}
