import { IntlShape } from "react-intl";

import { RepairState } from "~/types/repair-state";

export const getRepairStateTranslator = (intl: IntlShape) => {
  const translationMap: Record<RepairState, string> = {
    [RepairState.Cancelled]: intl.formatMessage({
      id: "generic.label.cancelled",
      defaultMessage: "Cancelled",
    }),
    [RepairState.Finished]: intl.formatMessage({
      id: "generic.label.finished",
      defaultMessage: "Finished",
    }),
    [RepairState.OnHold]: intl.formatMessage({
      id: "generic.label.on-hold",
      defaultMessage: "On hold",
    }),
    [RepairState.Started]: intl.formatMessage({
      id: "generic.label.started",
      defaultMessage: "Started",
    }),
    [RepairState.WaitingForGoods]: intl.formatMessage({
      id: "generic.label.waiting-for-goods",
      defaultMessage: "Waiting for goods",
    }),
    [RepairState.WaitingForPayment]: intl.formatMessage({
      id: "generic.label.waiting-for-payment",
      defaultMessage: "Waiting for payment",
    }),
    [RepairState.WaitingForPickup]: intl.formatMessage({
      id: "generic.label.waiting-for-pickup",
      defaultMessage: "Waiting for pickup",
    }),
    [RepairState.WaitingForRepair]: intl.formatMessage({
      id: "generic.label.waiting-for-repair",
      defaultMessage: "Waiting for repair",
    }),
    [RepairState.WaitingForShipment]: intl.formatMessage({
      id: "generic.label.waiting-for-shipment",
      defaultMessage: "Waiting for shipment",
    }),
  };

  return (status: RepairState) => {
    return translationMap[status];
  };
};
