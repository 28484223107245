import { forwardRef, useMemo } from "react";
import {
  generatePath,
  Link as RouterLink,
  LinkProps,
  Params,
  useSearchParams,
} from "react-router-dom";

import { usePrompt } from "./provider";

import { IRouteDefinition } from "~/types/route-definitions";

export interface DefinedRouteLinkProps extends Omit<LinkProps, "to"> {
  routeDefinition: IRouteDefinition;
  routeParams?: Params<string>;
  keepSearchParams?: boolean;
}

export const DefinedRouteLink = forwardRef<HTMLAnchorElement, DefinedRouteLinkProps>(
  (
    {
      children,
      keepSearchParams,
      onClick,
      replace = false,
      routeDefinition,
      routeParams,
      state,
      target,
      ...props
    },
    ref,
  ) => {
    const { setConfirmRoute, when } = usePrompt();
    const [searchParams] = useSearchParams();

    const path = useMemo(() => {
      let generatedPath = generatePath(routeDefinition.path, routeParams);

      if (keepSearchParams) {
        generatedPath = `${generatedPath}?${searchParams}`;
      }

      return generatedPath;
    }, [keepSearchParams, routeDefinition.path, routeParams, searchParams]);

    return (
      <RouterLink
        ref={ref}
        to={path}
        replace={replace}
        state={state}
        target={target}
        onClick={(e) => {
          if (when) {
            e?.preventDefault();
            setConfirmRoute(path);
          } else {
            onClick?.(e);
          }
        }}
        {...props}
      >
        {children}
      </RouterLink>
    );
  },
);

DefinedRouteLink.displayName = "Link";
