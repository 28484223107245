import { QueryClient, useQuery } from "@tanstack/react-query";

import {
  getApplicationConfigurationLoaderQuery,
  getApplicationConfigurationWithOUQuery,
} from "~/models/application";

/** Same as useApplicationConfiguration, but it can be used in loaders / actions / outside of React */
export const getApplicationConfiguration =
  (queryClient: QueryClient) => async (organizationUnitId?: number) => {
    const applicationConfiguration = await getApplicationConfigurationLoaderQuery(
      queryClient,
      {},
      [organizationUnitId?.toString() ?? "currentOU"],
      {
        ouId: organizationUnitId,
      },
    )();
    return applicationConfiguration;
  };

const useApplicationConfiguration = (organizationUnitId?: number) => {
  const { data, isLoading } = useQuery({
    ...getApplicationConfigurationWithOUQuery(
      {},
      [organizationUnitId?.toString() ?? "currentOU"],
      organizationUnitId,
    ),
  });
  return { data, isLoading };
};

export default useApplicationConfiguration;
