import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  FieldGroup,
} from "@new-black/lyra";
import { useQuery } from "@tanstack/react-query";

import {
  ISharedWorkspaceRole,
  SharedWorkspacePermissionsTable,
} from "./shared-workspace-permissions-table";
import { IWorkspaceView } from "./types";

import { generateRoleAutocomplete } from "~/components/shared/roles-input";
import Input from "~/components/suite-ui/input";
import { getSharedWorkspaceByIDQuery } from "~/models/workspaces";

interface IAddSharedWorkspaceModalState {
  workspaceName: string;
  roleIDs: number[];
  defaultRoleIDs: number[];
}

interface AddSharedWorkspaceModalProps extends IWorkspaceView<IAddSharedWorkspaceModalState> {
  labels?: {
    workspaceName?: string;
    roles?: string;
  };
  workspaceID?: number;
}

const { MultiAutocomplete: MultiRoleAutocomplete } = generateRoleAutocomplete();

const AddSharedWorkspaceModalContent = ({
  isLoading,
  labels,
  onCancel,
  onConfirm,
  title,
  workspaceID,
}: AddSharedWorkspaceModalProps) => {
  const intl = useIntl();

  const [workspaceName, setWorkspaceName] = useState<string>();
  const [roles, setRoles] = useState<ISharedWorkspaceRole[]>();

  const { isFetching: isWorkspaceLoading } = useQuery({
    ...getSharedWorkspaceByIDQuery(workspaceID ? { ID: workspaceID } : undefined),
    refetchOnWindowFocus: false,
    enabled: !!workspaceID,
    onSettled: (response) => {
      if (response) {
        setRoles(
          response.RolesWithDefault.map((role) => ({
            ID: role.ID,
            Name: role.Name,
            isDefault: role.IsDefault,
          })),
        );
      }
    },
  });

  return (
    <Card>
      <CardHeader
        title={
          title ??
          intl.formatMessage({
            id: "generic.label.create-shared-workspace",
            defaultMessage: "Create shared workspace",
          })
        }
      />

      <div className="max-h-[calc(90dvh-130px)] overflow-auto">
        <CardContent>
          <FieldGroup>
            <Input
              required
              label={
                labels?.workspaceName ??
                intl.formatMessage({
                  id: "generic.label.workspace-name",
                  defaultMessage: "Workspace name",
                })
              }
              value={workspaceName ?? ""}
              onChange={(event) => setWorkspaceName(event.target.value)}
              autoFocus
            />

            <MultiRoleAutocomplete.Controlled
              required
              values={roles}
              onChange={(newValues) => {
                setRoles(newValues);
              }}
              isLoading={isWorkspaceLoading}
              label={
                labels?.roles ??
                intl.formatMessage({
                  id: "generic.label.shared-with",
                  defaultMessage: "Shared with",
                })
              }
              disablePortal
            />
          </FieldGroup>
        </CardContent>

        <SharedWorkspacePermissionsTable roles={roles} setRoles={setRoles} />
      </div>

      <ButtonGroup className="flex justify-end p-4">
        <Button variant="secondary" onPress={onCancel} isDisabled={isLoading}>
          <FormattedMessage id="generic.label.cancel" defaultMessage="cancel" />
        </Button>

        <Button
          variant="primary"
          isDisabled={!workspaceName || !roles?.length || isLoading}
          isLoading={isLoading}
          onPress={() =>
            workspaceName &&
            roles?.length &&
            onConfirm({
              workspaceName,
              roleIDs: roles.map((role) => role.ID),
              defaultRoleIDs: roles.filter((role) => role.isDefault).map((role) => role.ID),
            })
          }
        >
          <FormattedMessage id="generic.label.save" defaultMessage="Save" />
        </Button>
      </ButtonGroup>
    </Card>
  );
};

const AddSharedWorkspaceModal = (props: AddSharedWorkspaceModalProps) => {
  return (
    <Dialog
      isOpen={props.open}
      onOpenChange={(open) => (open ? undefined : props.onCancel())}
      maxWidth="xl"
      aria-label="Add shared workspace"
    >
      <AddSharedWorkspaceModalContent {...props} />
    </Dialog>
  );
};

export default AddSharedWorkspaceModal;
