import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Button, SearchField, SvgIcon, TagInput } from "@new-black/lyra";

import { useProductSearchModalContext } from "../product-search-modal-context";

import useDebounce from "~/hooks/suite-react-hooks/use-debounce";

export default function ProductSearchModalFilters() {
  const intl = useIntl();
  const { filters, initialFilters, removePropertyFilter, setPropertyFilter } =
    useProductSearchModalContext();

  return (
    <div className="mx-4 max-h-[15.25rem] space-y-4 overflow-y-auto pb-4">
      <SearchInput />

      {filters.map((filter) => {
        const isInitialFilter = Object.keys(initialFilters ?? {}).includes(filter.ProductProperty);
        return (
          <TagInput
            key={filter.ProductProperty}
            isDisabled={isInitialFilter}
            label={intl.formatMessage(
              {
                id: "product-search-property.input.label",
                defaultMessage: "Property: {name}",
              },
              { name: filter.ProductProperty },
            )}
            hideHintLabel
            value={filter.Values ?? []}
            onChange={(values) => setPropertyFilter(filter.ProductProperty, values)}
            fullWidth
            hideInputFooter={isInitialFilter}
            outerEndSlot={
              <Button
                variant="icon"
                isDisabled={isInitialFilter}
                onPress={() => removePropertyFilter(filter.ProductProperty)}
                tooltip={intl.formatMessage({
                  id: "generic.label.delete",
                  defaultMessage: "Delete",
                })}
              >
                <SvgIcon name="delete" />
              </Button>
            }
          />
        );
      })}
    </div>
  );
}

function SearchInput() {
  const intl = useIntl();
  const { querySearchValue, setQuerySearchValue } = useProductSearchModalContext();

  const [internalValue, setInternalValue] = useState(querySearchValue);
  const debouncedValue = useDebounce(internalValue, 500);

  useEffect(() => {
    setQuerySearchValue(debouncedValue);
  }, [debouncedValue, setQuerySearchValue]);

  useEffect(() => {
    setInternalValue(querySearchValue);
  }, [querySearchValue]);

  return (
    <SearchField
      value={internalValue || ""}
      onChange={(value) => {
        setInternalValue(value);
      }}
      label={intl.formatMessage({
        id: "generic.label.search-query",
        defaultMessage: "Search query",
      })}
      hideInputFooter
      hideHintLabel
      onClear={() => {
        setInternalValue(undefined);
      }}
    />
  );
}
