import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Dialog } from "@material-ui/core";
import { omit } from "lodash";

import { useProductFilterContext } from "../product-filter-context";

import DialogTabPrefill from "./tab-prefill";
import DialogTabRange from "./tab-range";
import DialogTabValue from "./tab-value";

import { ProductFilterModal as LyraProductFilterModal } from "~/components/suite-composite/product-filter-modal";
import Tabs, { ITab } from "~/components/suite-ui/tabs";

type ProductFilterModalProps =
  | { variant?: "material" }
  | { variant: "lyra"; readOnlyPropertyOnEdit?: boolean };

const ProductFilterModal = (props: ProductFilterModalProps) => {
  const intl = useIntl();
  const {
    currentProductFilterModalValues,
    openProductFilterModal,
    productFilter,
    setCurrentProductFilterModalValues,
    setOpenProductFilterModal,
    updateProductFilter,
  } = useProductFilterContext();

  const tabs: ITab[] = useMemo<ITab[]>(
    () => [
      {
        label: intl.formatMessage({
          id: "product-filter.modal.values.title",
          defaultMessage: "Values",
        }),
        tabPanel: {
          value: 0,
          children: <DialogTabValue />,
        },
      },
      {
        label: intl.formatMessage({
          id: "product-filter.modal.range.title",
          defaultMessage: "Range",
        }),
        tabPanel: {
          value: 1,
          children: <DialogTabRange />,
        },
      },
      {
        label: intl.formatMessage({
          id: "product-filter.modal.prefill.title",
          defaultMessage: "Prefill",
        }),
        tabPanel: {
          value: 2,
          children: <DialogTabPrefill />,
        },
      },
    ],
    [intl],
  );

  const initialActiveTab = useMemo(
    () =>
      !!currentProductFilterModalValues?.From || !!currentProductFilterModalValues?.To ? 1 : 0,
    [currentProductFilterModalValues?.From, currentProductFilterModalValues?.To],
  );

  if (props.variant === "lyra") {
    return (
      <LyraProductFilterModal
        closeOnSave
        maxWidth="lg"
        isOpen={openProductFilterModal}
        readOnlyPropertyOnEdit={props.readOnlyPropertyOnEdit}
        initialValues={
          currentProductFilterModalValues
            ? {
                ...currentProductFilterModalValues,
                PropertyName: currentProductFilterModalValues.keyName,
              }
            : undefined
        }
        onChange={(newFilter) => {
          if (newFilter && newFilter.PropertyName) {
            updateProductFilter({
              ...productFilter,
              [newFilter.PropertyName]: omit(newFilter, "PropertyName"),
            });
          }
        }}
        onOpenChange={(newIsOpen) => {
          if (!newIsOpen) {
            setOpenProductFilterModal(false);
            setCurrentProductFilterModalValues(undefined);
          }
        }}
      />
    );
  }

  return (
    <Dialog
      open={openProductFilterModal}
      maxWidth="sm"
      fullWidth
      onClose={() => {
        setOpenProductFilterModal(false);
        setCurrentProductFilterModalValues(undefined);
      }}
    >
      <Tabs tabs={tabs} variant="fullWidth" initialActiveTab={initialActiveTab} />
    </Dialog>
  );
};

export default ProductFilterModal;
