import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { getAvailableShippingMethodHandlers } from "~/models/shipping-methods";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateAvailableShippingMethodHandlersSearchListField = () =>
  SearchListFieldGenerator<
    CoreManagement.GetAvailableShippingMethodHandlers,
    { HandlerName: string },
    string
  >({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: getAvailableShippingMethodHandlers,
        refetchOnFocus: false,
      }),
    getItemId: (item) => item.HandlerName,
    getLabel: (item) => item.HandlerName ?? "",
    getItemFromResponse: (resp) => resp?.Handlers?.map((handler) => ({ HandlerName: handler })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.handler",
      defaultMessage: "Handler",
    }),
    useItemByID: (id, items) => {
      const item = useMemo(() => items?.find((item) => item.HandlerName === id), [id, items]);
      return {
        data: id ? item : undefined,
      };
    },
  });
