import { LoaderFunctionArgs, matchPath, redirect } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { getCoreSettings, setCoreSetting } from "@springtree/eva-sdk-core-settings";
import { helpers } from "@springtree/eva-sdk-react-recoil";
import { QueryClient } from "@tanstack/react-query";

import packageJson from "../../../../package.json";

import { getEvaConfig } from "./get-eva-config";
import {
  adminGetAllModulesRootLoaderQuery,
  getApplicationConfigurationRootLoaderQuery,
  getAuthorizationStructureRootLoaderQuery,
  getAvailableCurrenciesRootLoaderQuery,
  getCurrentUserRootLoaderQuery,
} from "./queries";
import { setMomentLocale } from "./set-moment-locale";

import { translationLoaderQuery } from "~/models/application";
import routeDefinitions from "~/routes/route-definitions";
import { IConfig } from "~/types/auth";
import { InferLoaderOrActionReturnType } from "~/types/loaders.types";
import { LoaderServiceType } from "~/types/query.types";
import { getLocale } from "~/util/get-locale";

const { storage } = helpers;

const PATH_DASHBOARD = routeDefinitions.dashboard.wrapper.path;
const PATH_FORGOT_PASSWORD = routeDefinitions.auth.forgotPassword.path;
const PATH_FORGOT_PASSWORD_TOKEN = routeDefinitions.auth.forgotPassword.changePassword.path;
const PATH_INTENT_HANDLER = "/link";
const PATH_LOGIN = routeDefinitions.auth.login.path;
const PATH_LOGOUT = routeDefinitions.auth.logout.path;
const PATH_MODULE_INTENT_HANDLER = "/:module/link";
const PATH_ROOT = "/";
const PATH_SELECT_ENDPOINT = routeDefinitions.auth.selectEndpoint.path;
const PATH_NOT_FOUND = "/404";

export const LOGIN_REDIRECT_SEARCH_PARAM_NAME = "from" as const;
const disableCustomerManager = import.meta.env.VITE_EVA_DISABLE_CUSTOMER_MANAGER === "true";

// TODO: Clean up the loader
export const rootLoader =
  (queryClient: QueryClient) =>
  async ({ request }: LoaderFunctionArgs) => {
    setSettings();

    const url = new URL(request.url);
    const searchParams = new URLSearchParams(url.search);
    const userToken = storage.getItem("evaUserToken");

    const evaConfig = await getEvaConfig();

    const endpoint = evaConfig?.endpoint ?? storage.getItem("evaEndpointUrl");

    if (evaConfig?.endpoint) {
      storage.setItem("evaEndpointUrl", evaConfig.endpoint);
    } else if (!disableCustomerManager) {
      if (!endpoint && url.pathname !== PATH_SELECT_ENDPOINT) {
        console.log("redirecting to select endpoint");
        return redirect(PATH_SELECT_ENDPOINT);
      }
    } else {
      if (url.pathname !== PATH_NOT_FOUND) {
        return redirect(PATH_NOT_FOUND);
      }
    }

    // Handle situations when the users needs to be redirected to the login page, when endpoint is available.
    //
    if (
      endpoint &&
      !userToken &&
      ![
        PATH_SELECT_ENDPOINT,
        PATH_LOGIN,
        PATH_FORGOT_PASSWORD,
        PATH_FORGOT_PASSWORD_TOKEN,
        PATH_LOGOUT,
        PATH_INTENT_HANDLER,
        PATH_MODULE_INTENT_HANDLER,
        PATH_NOT_FOUND,
      ].some((path) => matchPath(path, url.pathname))
    ) {
      searchParams.set(LOGIN_REDIRECT_SEARCH_PARAM_NAME, url.pathname);
      return redirect(`${PATH_LOGIN}?${searchParams.toString()}`, 302);
    }

    let currentUser:
      | LoaderServiceType<EVA.Core.GetCurrentUser, EVA.Core.GetCurrentUserResponse | undefined>
      | undefined;
    let authorizationStructure:
      | LoaderServiceType<
          EVA.Core.GetAuthorizationStructure,
          EVA.Core.GetAuthorizationStructureResponse | undefined
        >
      | undefined;
    let applicationConfiguration:
      | LoaderServiceType<
          EVA.Core.GetApplicationConfiguration,
          EVA.Core.GetApplicationConfigurationResponse | undefined
        >
      | undefined;
    let currencies:
      | LoaderServiceType<
          EVA.Core.GetAvailableCurrencies,
          EVA.Core.GetAvailableCurrenciesResponse | undefined
        >
      | undefined;

    let availableModules: EVA.Admin.AdminGetAllModulesResponse | undefined;

    // If an endpoint and user token is available, we can fetch the current user.
    if (endpoint && userToken) {
      try {
        const { loaderQuery: preparedGetCurrentUserService, request: getCurrentUserDataRequest } =
          getCurrentUserRootLoaderQuery();

        const { loaderQuery: adminGetAllModulesService } = adminGetAllModulesRootLoaderQuery();

        const [getCurrentUserResponse, getAvailableModulesResponse] = await Promise.all([
          preparedGetCurrentUserService(),
          adminGetAllModulesService(),
        ]);

        availableModules = getAvailableModulesResponse.value;

        currentUser = { ...getCurrentUserResponse, request: getCurrentUserDataRequest };

        const currentUserData = currentUser.value;

        // Make sure we set locale of the user in the core settings.This way it will be added to the accept-language header of the eva services.
        if (currentUserData?.User) {
          const locale = `${currentUserData.User.LanguageID}-${currentUserData.User.CountryID}`;
          const currentCoreSettingLanguage = getCoreSettings().language;
          if (currentCoreSettingLanguage !== locale) {
            setCoreSetting("language", locale);
          }
        }

        // handle cases when the user is anonymous
        if (currentUserData?.User.FirstName && currentUserData?.User.FirstName === "anonymous") {
          // if the current route does not match these routes we set the from searchparams to the current route
          if (
            ![
              PATH_LOGIN,
              PATH_FORGOT_PASSWORD,
              PATH_FORGOT_PASSWORD_TOKEN,
              PATH_LOGOUT,
              PATH_SELECT_ENDPOINT,
              PATH_INTENT_HANDLER,
              PATH_MODULE_INTENT_HANDLER,
            ].some((path) => matchPath(path, url.pathname))
          ) {
            searchParams.set(LOGIN_REDIRECT_SEARCH_PARAM_NAME, url.pathname);
          }

          // if the current route is not login or forgot password we redirect to login including the from searchparam
          if (
            !([PATH_LOGIN, PATH_FORGOT_PASSWORD, PATH_FORGOT_PASSWORD_TOKEN] as string[]).includes(
              url.pathname,
            )
          ) {
            return redirect(`${PATH_LOGIN}?${searchParams.toString()}`);
          }
        }

        // Handle a redirect when the current path is /root or /dashboard.
        else if (url.pathname === PATH_ROOT || url.pathname === PATH_DASHBOARD) {
          return redirect(`${PATH_DASHBOARD}/search`, 302);
        }
        // handle the case that the user is active but lands on the login page or the forgot password page
        else if (
          currentUserData?.User &&
          (url.pathname === PATH_LOGIN ||
            url.pathname === PATH_FORGOT_PASSWORD ||
            url.pathname === PATH_FORGOT_PASSWORD_TOKEN)
        ) {
          const from = searchParams.get(LOGIN_REDIRECT_SEARCH_PARAM_NAME);
          const newSearchParams = new URLSearchParams(searchParams);
          newSearchParams.delete(LOGIN_REDIRECT_SEARCH_PARAM_NAME);
          return redirect(from ? `${from}?${newSearchParams.toString()}` : PATH_ROOT);
        }
      } catch (error) {
        // if the getCurrentUser call fails, make sure the userToken & requesterOrganizationUnitID are removed from storage

        console.error(error);
        storage.setItem("evaUserToken", "");
        storage.setItem("evaRequestedOrganizationUnitID", "");
        console.log("LOGIN GETS CATCHED", error);

        // set the from searchparam to the current route
        if (
          ![
            PATH_LOGIN,
            PATH_FORGOT_PASSWORD,
            PATH_FORGOT_PASSWORD_TOKEN,
            PATH_LOGOUT,
            PATH_SELECT_ENDPOINT,
            PATH_INTENT_HANDLER,
            PATH_MODULE_INTENT_HANDLER,
          ].some((path) => matchPath(path, url.pathname))
        ) {
          searchParams.set(LOGIN_REDIRECT_SEARCH_PARAM_NAME, url.pathname);
        }

        // if the current route is not login or forgot password we redirect to login including the from searchparam
        if (
          !([PATH_LOGIN, PATH_FORGOT_PASSWORD, PATH_FORGOT_PASSWORD_TOKEN] as string[]).includes(
            url.pathname,
          )
        ) {
          throw redirect(`${PATH_LOGIN}?${searchParams.toString()}`);
        }
      }

      // Handle redirect to dashboard search if path is root or /dashboard
      if (url.pathname === PATH_ROOT || url.pathname === PATH_DASHBOARD) {
        return redirect(`${PATH_DASHBOARD}/search`, 302);
      }

      // Handle redirect from select endpoint to root if endpoint is already selected
      if (url.pathname === PATH_SELECT_ENDPOINT && endpoint) {
        return redirect(PATH_ROOT, 302);
      }

      const {
        loaderQuery: preparedAuthorizationStructureService,
        request: authorizationStructureDataRequest,
      } = getAuthorizationStructureRootLoaderQuery();

      const {
        loaderQuery: preparedGetApplicationConfigurationService,
        request: getApplicationConfigurationRequest,
      } = getApplicationConfigurationRootLoaderQuery();

      const {
        loaderQuery: preparedGetAvailableCurrenciesService,
        request: getAvailableCurrenciesRequest,
      } = getAvailableCurrenciesRootLoaderQuery();

      const [
        getApplicationConfigurationOnLoaderResponse,
        getAuthorizationStructureOnLoaderResponse,
        getAvailableCurrenciesRespone,
      ] = await Promise.all([
        preparedGetApplicationConfigurationService(),
        preparedAuthorizationStructureService(),
        preparedGetAvailableCurrenciesService(),
      ]);

      applicationConfiguration = {
        ...getApplicationConfigurationOnLoaderResponse,
        request: getApplicationConfigurationRequest,
      };
      authorizationStructure = {
        ...getAuthorizationStructureOnLoaderResponse,
        request: authorizationStructureDataRequest,
      };
      currencies = {
        ...getAvailableCurrenciesRespone,
        request: getAvailableCurrenciesRequest,
      };
      // end of if (endpoint && userToken)
    }

    const { locale, translationMessages } = await getLocaleAndMessages(
      queryClient,
      currentUser?.value,
    );

    setMomentLocale(locale);

    // By now most information is available to enrich Sentry error tracking data.
    setupSentry(evaConfig, applicationConfiguration?.value, currentUser?.value, url.href);

    return {
      evaConfig,
      currentUser,
      authorizationStructure,
      applicationConfiguration,
      locale,
      translationMessages,
      currencies,
      availableModulesResponse: availableModules,
      evaEndpointURL: endpoint,
    };
  };

// Function that retrieves the user locale and translation messages.
const getLocaleAndMessages = async (
  queryClient: QueryClient,
  currentUser: EVA.Core.GetCurrentUserResponse | undefined,
) => {
  const locale = getLocale(currentUser?.User.LanguageID, currentUser?.User.CountryID);

  const translationMessages = await translationLoaderQuery(queryClient, locale);

  return {
    locale,
    translationMessages,
  };
};

export type RootLoaderData = InferLoaderOrActionReturnType<ReturnType<typeof rootLoader>>;

// Enrich Sentry error tracking with EVA and User context information.
const setupSentry = (
  evaConfig?: IConfig,
  appConfigResponse?: EVA.Core.GetApplicationConfigurationResponse,
  user?: EVA.Core.GetCurrentUserResponse,
  href?: string,
) => {
  const appConfig = appConfigResponse?.Configuration;
  const currentUser = user?.User;

  Sentry.setContext("EVA", {
    ...evaConfig,
    DeploymentStage: appConfig?.["EVA:DeploymentStage"],
    evaVersion: appConfig?.["EVA:Version"],
  });

  Sentry.setContext("User", {
    ID: currentUser?.ID,
    LanguageID: currentUser?.LanguageID,
    CountryID: currentUser?.CountryID,
    CurrentOrganizationID: currentUser?.CurrentOrganizationID,
    CurrentOrganizationName: currentUser?.CurrentOrganizationName,
    TimeZone: currentUser?.TimeZone,
  });

  Sentry.setUser({ id: `${currentUser?.ID}` });
  Sentry.setTag("appVersion", packageJson.version);
  Sentry.setTag("appMinorVersion", packageJson.version.split(".")[1]);
  Sentry.setTag("endpoint", evaConfig?.endpoint);
  Sentry.setTag("href", href);
};

/**
 * Function that will set EVA core settings if they are not set yet.
 */
const setSettings = () => {
  const settings = getCoreSettings();
  if (settings.appName === "UNKNOWN" || !settings.appName) {
    setCoreSetting("appName", packageJson.name);
    setCoreSetting("appVersion", packageJson.version);
  }
};
