import { RadioCheckbox } from "@new-black/lyra";

import { LyraOrderLineImageDescription } from "~/components/shared/order-line-image-description";

type OrderLineItemProps = {
  isSelected?: boolean;
  onChange?: (isSelected?: boolean) => void;
  orderLine: EVA.Core.OrderLineDto;
};

export const OrderLineItem = ({ isSelected, onChange, orderLine }: OrderLineItemProps) => {
  return (
    <RadioCheckbox
      isSelected={isSelected}
      onChange={onChange}
      className="flex items-center gap-4 p-4 hover:cursor-pointer"
    >
      <LyraOrderLineImageDescription
        type={orderLine.Type}
        productID={orderLine.ProductID}
        backendID={orderLine.Product?.BackendID}
        customID={orderLine.Product?.CustomID}
        description={orderLine.Description}
        blob={orderLine.Product?.Properties?.["primary_image"]?.["blob"]}
        disableVerticalPadding
        disableImageClick
      />
    </RadioCheckbox>
  );
};
