import { IntlShape } from "react-intl";

import { ProductTypes } from "~/types/eva-core";

function splitProductTypeFlaggedValue(value: number): ProductTypes[] {
  return [
    ProductTypes.Stock,
    ProductTypes.Insurance,
    ProductTypes.Marketing,
    ProductTypes.GiftCard,
    ProductTypes.Service,
    ProductTypes.GreetingCard,
    ProductTypes.CustomPricing,
    ProductTypes.External,
    ProductTypes.OrderCosts,
    ProductTypes.SystemGenerated,
    ProductTypes.ProductSet,
    ProductTypes.BundleProduct,
    ProductTypes.VirtualProduct,
    ProductTypes.MadeToOrder,
    ProductTypes.Configurable,
    ProductTypes.SupplierProduct,
    ProductTypes.Template,
    ProductTypes.SecondChance,
    ProductTypes.NotReturnable,
  ].filter((type) => value & type);
}

export function getProductTypeLabelTranslator(intl: IntlShape, ignoreNone?: boolean) {
  const lut: Record<ProductTypes, string> = {
    [ProductTypes.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
    [ProductTypes.Stock]: intl.formatMessage({
      id: "generic.label.stock",
      defaultMessage: "Stock",
    }),
    [ProductTypes.Insurance]: intl.formatMessage({
      id: "generic.label.insurance",
      defaultMessage: "Insurance",
    }),
    [ProductTypes.Marketing]: intl.formatMessage({
      id: "generic.label.marketing",
      defaultMessage: "Marketing",
    }),
    [ProductTypes.GiftCard]: intl.formatMessage({
      id: "generic.label.gift-card",
      defaultMessage: "Gift card",
    }),
    [ProductTypes.Service]: intl.formatMessage({
      id: "generic.label.service",
      defaultMessage: "Service",
    }),
    [ProductTypes.GreetingCard]: intl.formatMessage({
      id: "generic.label.greeting-card",
      defaultMessage: "Greeting card",
    }),
    [ProductTypes.CustomPricing]: intl.formatMessage({
      id: "generic.label.custom-pricing",
      defaultMessage: "Custom pricing",
    }),
    [ProductTypes.External]: intl.formatMessage({
      id: "generic.label.external",
      defaultMessage: "External",
    }),
    [ProductTypes.OrderCosts]: intl.formatMessage({
      id: "generic.label.order-costs",
      defaultMessage: "Order costs",
    }),
    [ProductTypes.SystemGenerated]: intl.formatMessage({
      id: "generic.label.system-generated",
      defaultMessage: "System generated",
    }),
    [ProductTypes.ProductSet]: intl.formatMessage({
      id: "generic.label.product-set",
      defaultMessage: "Product set",
    }),
    [ProductTypes.BundleProduct]: intl.formatMessage({
      id: "generic.label.bundle-product",
      defaultMessage: "Bundle product",
    }),
    [ProductTypes.VirtualProduct]: intl.formatMessage({
      id: "generic.label.virtual-product",
      defaultMessage: "Virtual product",
    }),
    [ProductTypes.MadeToOrder]: intl.formatMessage({
      id: "generic.label.made-to-order",
      defaultMessage: "Made to order",
    }),
    [ProductTypes.Configurable]: intl.formatMessage({
      id: "generic.label.configurable",
      defaultMessage: "Configurable",
    }),
    [ProductTypes.SupplierProduct]: intl.formatMessage({
      id: "generic.label.supplier-product",
      defaultMessage: "Supplier product",
    }),
    [ProductTypes.Template]: intl.formatMessage({
      id: "generic.label.template",
      defaultMessage: "Template",
    }),
    [ProductTypes.SecondChance]: intl.formatMessage({
      id: "generic.label.second-chance",
      defaultMessage: "Second chance",
    }),
    [ProductTypes.NotReturnable]: intl.formatMessage({
      id: "generic.label.not-returnable",
      defaultMessage: "Not returnable",
    }),
  };

  function translateLabel(value: number): string | undefined {
    if (ignoreNone && value === ProductTypes.None) {
      return undefined;
    }
    if (value in lut) {
      return lut[value as ProductTypes];
    }
    return undefined;
  }

  function translateFlaggedLabel(value: number): string[] | undefined {
    const flaggedValues = splitProductTypeFlaggedValue(value)
      .map(translateLabel)
      .filter((label): label is NonNullable<typeof label> => label !== undefined);
    if (!flaggedValues.length) {
      return undefined;
    }
    return flaggedValues;
  }

  return {
    translateLabel,
    translateFlaggedLabel,
  };
}
