import { IntlShape } from "react-intl";

import { SubscriptionConfirmation } from "~/types/eva-core";

export const getSubscriptionConfirmationTranslator = (intl: IntlShape) => {
  const translationMap: Record<EVA.Core.SubscriptionConfirmation, string> = {
    [SubscriptionConfirmation.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
    [SubscriptionConfirmation.Email]: intl.formatMessage({
      id: "generic.label.email",
      defaultMessage: "Email",
    }),
    [SubscriptionConfirmation.Webhook]: intl.formatMessage({
      id: "generic.label.webhook",
      defaultMessage: "Webhook",
    }),
  };

  return (subscriptionConfirmation: SubscriptionConfirmation) => {
    return translationMap[subscriptionConfirmation];
  };
};
