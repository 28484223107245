import { useState } from "react";
import { useIntl } from "react-intl";
import { Form } from "react-router-dom";

import { CalendarDate, parseAbsoluteToLocal } from "@internationalized/date";
import { DatePicker, FieldGroup, TextField } from "@new-black/lyra";

import { LoyaltyProgramGroupFormErrors, loyaltyProgramGroupFormKeys } from "./types";

import { generateCurrencyLyraSearchListField } from "~/components/suite-composite/currency-search-list-field";
import { LoyaltyProgramStatusSelect } from "~/components/suite-composite/loyalty-program-status-select";

export const LoyaltyProgramGroupForm = ({
  errors,
  formID,
  loyaltyProgramGroup,
}: {
  loyaltyProgramGroup?: EVA.Core.Management.GetLoyaltyProgramGroupResponse;
  formID: string;
  errors?: LoyaltyProgramGroupFormErrors;
}) => {
  const intl = useIntl();

  const [startDate, setStartDate] = useState(() => {
    if (loyaltyProgramGroup?.StartDate) {
      return parseAbsoluteToLocal(loyaltyProgramGroup.StartDate);
    }

    return null;
  });
  const [endDate, setEndDate] = useState(() => {
    if (loyaltyProgramGroup?.EndDate) {
      return parseAbsoluteToLocal(loyaltyProgramGroup.EndDate);
    }

    return null;
  });

  const [status, setStatus] = useState(loyaltyProgramGroup?.Status);

  return (
    <Form method="POST" id={formID}>
      <FieldGroup>
        <TextField
          isRequired
          name={loyaltyProgramGroupFormKeys.Name}
          label={intl.formatMessage({ id: "generic.label.name", defaultMessage: "Name" })}
          errorMessage={errors?.Name}
          defaultValue={loyaltyProgramGroup?.Name}
        />

        <TextField
          name={loyaltyProgramGroupFormKeys.TaxExemptionCode}
          label={intl.formatMessage({
            id: "generic.label.tax-exemption-code",
            defaultMessage: "Tax exemption code",
          })}
          errorMessage={errors?.TaxExemptionCode}
          defaultValue={loyaltyProgramGroup?.TaxExemptionCode}
        />

        <CurrencyIDSearchListField.Uncontrolled
          name={loyaltyProgramGroupFormKeys.BudgetCurrencyID}
          label={intl.formatMessage({
            id: "generic.label.budget-currency-id",
            defaultMessage: "Budget currency ID",
          })}
          defaultValue={loyaltyProgramGroup?.BudgetCurrencyID}
          errorMessage={errors?.BudgetCurrencyID}
        />

        <DatePicker
          value={startDate}
          onChange={setStartDate}
          label={intl.formatMessage({
            id: "generic.label.start-date",
            defaultMessage: "Start date",
          })}
          errorMessage={errors?.StartDate}
        />
        {startDate ? (
          <input
            hidden
            readOnly
            name={loyaltyProgramGroupFormKeys.StartDate}
            value={new CalendarDate(startDate.year, startDate.month, startDate.day).toString()}
          />
        ) : null}

        <DatePicker
          value={endDate}
          onChange={setEndDate}
          label={intl.formatMessage({
            id: "generic.label.end-date",
            defaultMessage: "End date",
          })}
          errorMessage={errors?.EndDate}
        />
        {endDate ? (
          <input
            hidden
            readOnly
            name={loyaltyProgramGroupFormKeys.EndDate}
            value={new CalendarDate(endDate.year, endDate.month, endDate.day).toString()}
          />
        ) : null}

        <LoyaltyProgramStatusSelect
          value={status}
          onChange={setStatus}
          errorMessage={errors?.Status}
        />
        {status !== undefined ? (
          <input hidden readOnly name={loyaltyProgramGroupFormKeys.Status} value={status} />
        ) : null}
      </FieldGroup>
    </Form>
  );
};

const { SingleIDSearchListField: CurrencyIDSearchListField } =
  generateCurrencyLyraSearchListField();
