import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { FieldGroup } from "@new-black/lyra";
import { omit } from "lodash";

import { CustomFieldOperatorSelect } from "../../../components/suite-composite/custom-field-operator-select";

import {
  loyaltyProgramCustomFieldConsumerWithOperatorKeys,
  TLoyaltyProgramCustomFieldConsumerWithOperatorForm,
  TLoyaltyProgramCustomFieldConsumerWithOperatorFormErrors,
} from "./loyalty-program-custom-field-consumer-with-operator.types";
import { LoyaltyProgramCustomFieldWithOUIDInput } from "./loyalty-program-custom-field-with-ouid-input";
import { LoyaltyProgramCustomFieldWithoutOUIDInput } from "./loyalty-program-custom-field-without-ouid-input";

import {
  CFCInferredFieldType,
  CFCTypedValueCustomField,
  CustomFieldConsumer,
} from "~/components/suite-composite/custom-field-consumer";
import { TCustomFieldAutocompleteItem } from "~/components/suite-composite/generate-custom-field-autocomplete";
import {
  CUSTOM_FIELD_OPERATORS_THAT_DONT_REQUIRE_VALUE,
  CustomFieldValueOperator,
} from "~/types/custom-field";
import {
  convertEVACustomFieldTypeToCustomFieldConsumerType,
  getCustomFieldValueFromEVACustomFieldValue,
  mapCustomFieldPrimitiveValueToEVACustomFieldValue,
} from "~/util/custom-fields";

export const LoyaltyProgramCustomFieldConsumerWithOperatorFields = ({
  errors,
  initialCustomField,
  initialValues,
  organizationUnitID,
}: {
  organizationUnitID?: number;
  initialValues?: TLoyaltyProgramCustomFieldConsumerWithOperatorForm;
  initialCustomField?: TCustomFieldAutocompleteItem;
  errors?: TLoyaltyProgramCustomFieldConsumerWithOperatorFormErrors;
}) => {
  const intl = useIntl();

  const [selectedCustomField, setSelectedCustomField] = useState(initialCustomField);
  const [selectedCustomFieldOperator, setSelectedCustomFieldOperator] = useState<
    CustomFieldValueOperator | undefined
  >(initialValues?.Operator);
  const [customFieldValue, setCustomFieldValue] = useState(
    getCustomFieldValueFromEVACustomFieldValue(omit(initialValues?.CustomFieldValue, "HasValue")),
  );

  const onSelectedCustomFieldValueChange = useCallback(
    <U extends CFCTypedValueCustomField>(newValue: CFCInferredFieldType<U>) =>
      setCustomFieldValue(newValue),
    [],
  );

  const mappedCustomFieldValue = useMemo<
    TLoyaltyProgramCustomFieldConsumerWithOperatorForm["CustomFieldValue"] | undefined
  >(() => {
    if (selectedCustomField?.DataTypeID !== undefined) {
      const mappedValue = mapCustomFieldPrimitiveValueToEVACustomFieldValue(
        convertEVACustomFieldTypeToCustomFieldConsumerType(
          selectedCustomField?.DataTypeID,
          selectedCustomField.IsArray,
        ),
        customFieldValue,
        selectedCustomField?.Options?.IsRequired,
      );

      return mappedValue;
    }

    return undefined;
  }, [
    selectedCustomField?.DataTypeID,
    selectedCustomField?.IsArray,
    selectedCustomField?.Options?.IsRequired,
    customFieldValue,
  ]);

  return (
    <FieldGroup>
      {organizationUnitID ? (
        <LoyaltyProgramCustomFieldWithOUIDInput
          organizationUnitID={organizationUnitID}
          value={selectedCustomField}
          onChange={(newValue) => {
            setSelectedCustomField(newValue);

            // Also reset the selected operator & value after selected custom field changes
            setSelectedCustomFieldOperator(undefined);
            setCustomFieldValue(undefined);
          }}
          errorMessage={errors?.CustomFieldId}
          name={loyaltyProgramCustomFieldConsumerWithOperatorKeys.CustomFieldId}
        />
      ) : (
        <LoyaltyProgramCustomFieldWithoutOUIDInput
          value={selectedCustomField}
          onChange={(newValue) => {
            setSelectedCustomField(newValue);

            // Also reset the selected operator & value after selected custom field changes
            setSelectedCustomFieldOperator(undefined);
            setCustomFieldValue(undefined);
          }}
          errorMessage={errors?.CustomFieldId}
          name={loyaltyProgramCustomFieldConsumerWithOperatorKeys.CustomFieldId}
        />
      )}

      <CustomFieldOperatorSelect
        isRequired
        name={loyaltyProgramCustomFieldConsumerWithOperatorKeys.Operator}
        customFieldDataTypeID={selectedCustomField?.DataTypeID}
        value={selectedCustomFieldOperator}
        setValue={setSelectedCustomFieldOperator}
        isDisabled={!selectedCustomField}
        errorMessage={errors?.Operator}
      />

      {selectedCustomFieldOperator !== undefined &&
      !!selectedCustomField &&
      !CUSTOM_FIELD_OPERATORS_THAT_DONT_REQUIRE_VALUE.includes(selectedCustomFieldOperator) ? (
        <CustomFieldConsumer
          customFieldId={selectedCustomField.CustomFieldID}
          value={customFieldValue}
          label={intl.formatMessage({ id: "generic.label.value", defaultMessage: "Value" })}
          enumDisplay="inline"
          onChange={onSelectedCustomFieldValueChange}
          variant="lyra"
        />
      ) : null}

      {mappedCustomFieldValue ? (
        <input
          type="hidden"
          name={loyaltyProgramCustomFieldConsumerWithOperatorKeys.CustomFieldValue}
          value={JSON.stringify(mappedCustomFieldValue)}
        />
      ) : null}
    </FieldGroup>
  );
};
