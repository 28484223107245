import { Core } from "@springtree/eva-services-core";

import { getCashCorrectionsReasonsQuery } from "~/models/cash-journals";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const {
  MultiSearchListField: CashCorrectionReasonMultiSearchListField,
  SingleSearchListField: CashCorrectionReasonSingleSearchListField,
} = SearchListFieldGenerator<
  Core.GetCashCorrectionReasons,
  {
    ID: number;
    Name: string;
  },
  number
>({
  getItemId: (item) => item.ID,
  getLabel: (item) => item.Name ?? item.ID?.toString() ?? "",
  getItemFromResponse: (response) =>
    response?.Reasons?.map((CashCorrectionReason) => ({
      ID: CashCorrectionReason.ID,
      Name: CashCorrectionReason.Name,
    })),
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.cash-correction-reason",
    defaultMessage: "Cash correction reason",
  }),
  useServiceQuery: () =>
    SearchListFieldGenerator.useSearchListFieldService({
      refetchOnFocus: false,
      query: getCashCorrectionsReasonsQuery,
      initialRequest: {},
    }),
});
