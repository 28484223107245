import { Cookbook } from "@springtree/eva-services-cookbook";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: previewAccountingRecipeLoaderQuery,
  useServiceQueryHook: usePreviewAccountingRecipeQuery,
} = createServiceQuery(Cookbook.PreviewAccountingRecipe);

export const {
  serviceLoaderQuery: listAccountingRecipesLoaderQuery,
  serviceQueryKeys: listAccountingRecipesQueryKeys,
  useServiceQueryHook: useListAccountingRecipesQuery,
} = createServiceQuery(Cookbook.ListAccountingRecipes);

export const {
  serviceLoaderQuery: getAccountingRecipeLoaderQuery,
  serviceQueryKeys: getAccountingRecipeQueryKeys,
  useServiceQueryHook: useGetAccountingRecipeQuery,
} = createServiceQuery(Cookbook.GetAccountingRecipe);

export const {
  serviceLoaderQuery: getAccountingRecipeHistoryLoaderQuery,
  serviceQueryKeys: getAccountingRecipeHistoryQueryKeys,
  useServiceQueryHook: useGetAccountingRecipeHistoryQuery,
} = createServiceQuery(Cookbook.GetAccountingRecipeHistory);
