import { useEffect, useState } from "react";

import { ButtonGroup } from "@new-black/lyra";
import classNames from "clsx";

import { PaneProps } from "./pane.types";
import { PaneFooter } from "./pane-footer";
import { PaneHeader } from "./pane-header";
import { PaneMain } from "./pane-main";
import { PaneSidebar } from "./pane-side-bar";
import { SidebarToggle } from "./side-bar-toggle";

import useLocalStorage from "~/hooks/suite-react-hooks/use-local-storage";
import { useScreenIsNarrowerThan } from "~/hooks/use-screen-width";
import { localStorageKeys } from "~/types/local-storage";

export const Pane = ({
  children,
  expandFooterOnSidebar,
  footer,
  isLoading,
  mainHeader,
  mainHeaderActions,
  sideBarBottomContent,
  sideBarContent,
  sideBarHeader,
  sidebarInitiallyOpen,
}: PaneProps) => {
  const smallerScreen = useScreenIsNarrowerThan(768);

  const [mainIsScrolled, setMainIsScrolled] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useLocalStorage(
    localStorageKeys.SidepaneOpenState,
    smallerScreen ? false : sidebarInitiallyOpen ?? true,
  );

  // automatically close menu if screen is narrower than 768 px or the sidebarInitiallyOpen
  // prop is set
  useEffect(() => {
    if (smallerScreen) {
      setSidebarOpen(false);
    } else if (sidebarInitiallyOpen) {
      setSidebarOpen(true);
    }
    // only run this effect on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative h-screen w-full overflow-hidden">
      <div className="relative flex w-full overflow-hidden bg-surface-tertiary">
        <div
          className={classNames(
            "relative flex h-screen flex-1 flex-col overflow-auto",
            !!footer && expandFooterOnSidebar && "pb-[70px]",
          )}
          onScroll={(event) => {
            setMainIsScrolled(event.currentTarget.scrollTop > 0);
          }}
        >
          <PaneHeader
            isScrolled={mainIsScrolled}
            isLoading={isLoading}
            end={
              mainHeaderActions || sideBarContent ? (
                <ButtonGroup className="items-center">
                  {mainHeaderActions}

                  {sideBarContent ? (
                    <SidebarToggle sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
                  ) : null}
                </ButtonGroup>
              ) : undefined
            }
          >
            {mainHeader}
          </PaneHeader>
          <PaneMain expandFooterOnSidebar={expandFooterOnSidebar} footer={footer}>
            {children}
          </PaneMain>
        </div>
        <PaneSidebar
          sideBarContent={sideBarContent}
          sideBarHeader={sideBarHeader}
          sideBarBottomContent={sideBarBottomContent}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={setSidebarOpen}
          expandFooterOnSidebar={expandFooterOnSidebar}
        />
      </div>
      {!!footer && expandFooterOnSidebar ? <PaneFooter>{footer}</PaneFooter> : null}
    </div>
  );
};
