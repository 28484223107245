import { useMediaQuery, useTheme } from "@material-ui/core";
import { Separator } from "@new-black/lyra";

import Grid from "~/components/suite-ui/grid";
import Text from "~/components/suite-ui/text";

interface IInformationValueComponentProps {
  /** Wrapper component, which can receive either a string or a number and will display it as bold text ReactNode, that can be used in JSX.
   *
   * ---
   * Example:
   *
   * `bold('Example text')` will produce the following ReactNode: `<BoldText>Example text</BoldText>`
   *
   * (**`BoldText`** is a styled Text component, with its `font-weight` CSS property set to `500`)
   * */
  bold: (text: string | number) => React.ReactNode;
}

interface IDisplayValue {
  displayComponent?: never;
  displayValue: string | number;
}

interface IDisplayComponent {
  displayValue?: never;
  displayComponent: (props: IInformationValueComponentProps) => React.ReactNode;
}

interface IInformationCardLineProps {
  label: string;
  hideDivider?: boolean;
  showHorizontalDividerOnSmallScreen?: boolean;
}

const InformationCardLine = ({
  displayComponent,
  displayValue,
  hideDivider,
  label,
  showHorizontalDividerOnSmallScreen: showVerticalDividerOnSmallScreen,
}: IInformationCardLineProps & (IDisplayValue | IDisplayComponent)) => {
  const theme = useTheme();
  const bigScreen = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <>
      <Grid item md={6} xs={12}>
        <div className="py-px">
          <Grid
            container
            spacing={2}
            alignItems="center"
            alignContent="center"
            justifyContent="space-between"
          >
            <Grid item>
              <Text color="textSecondary">{label}</Text>
            </Grid>
            <Grid item xs>
              {displayValue !== undefined ? (
                <Text className="font-medium" align="right">
                  {displayValue}
                </Text>
              ) : (
                displayComponent?.({
                  bold: (value) => <Text className="font-medium">{value}</Text>,
                })
              )}
            </Grid>
            {!hideDivider && bigScreen ? (
              <div className="pr-[10px]">
                <Separator className="my-2 w-3.5 -rotate-90" />
              </div>
            ) : null}
          </Grid>
        </div>
        {!bigScreen && showVerticalDividerOnSmallScreen ? <Separator /> : null}
      </Grid>
    </>
  );
};

export default InformationCardLine;
