import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selector } from "recoil";

import { ALL_ROWS_LIMIT } from "~/components/suite-composite/utils/types";

export const listRolesServiceState = builders.buildServiceState({
  service: CoreManagement.ListRoles,
  key: "CoreManagement.ListRoles",
  defaultRequest: {
    PageConfig: {
      Limit: ALL_ROWS_LIMIT,
    },
  },
  requireLoggedIn: true,
  requireEmployee: true,
});

export const listRolesResultSelector = selector({
  key: "CoreManagement.ListRoles:Result",
  get: ({ get }) => get(listRolesServiceState.response)?.Result?.Page,
});
