import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { listLoyaltyProgramsQuery, useGetLoyaltyProgramQuery } from "~/models/loyalty-programs";
import { AutocompleteGenerator } from "~/util/autocomplete-generator";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export interface ISubscriptionLoyaltyProgramAutocompleteItem {
  ID: number;
  Name: string;
}

export const generateSubscriptionLoyaltyProgramAutocomplete = (
  initialFilters?: Partial<EVA.Core.ListLoyaltyProgramsFilter>,
) => {
  const {
    MultiAutocomplete: SubscriptionLoyaltyProgramMultiAutocomplete,
    SingleAutocomplete: SubscriptionLoyaltyProgramAutocomplete,
  } = AutocompleteGenerator<
    CoreManagement.ListLoyaltyPrograms,
    ISubscriptionLoyaltyProgramAutocompleteItem
  >({
    idKey: "ID",
    labelKey: "Name",
    getItemFromResponse: (resp) =>
      resp?.Results?.map((loyaltyProgram) => ({
        ID: loyaltyProgram.ID,
        Name: loyaltyProgram.Name,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.loyalty-program",
      defaultMessage: "Loyalty program",
    }),
    useServiceQuery: () =>
      AutocompleteGenerator.useAutocompleteService({
        refetchOnFocus: false,
        query: listLoyaltyProgramsQuery,
        initialRequest: { InitialPageConfig: { Limit: 10, Filter: initialFilters } },
        getQueryRequest: (req) => req?.InitialPageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          InitialPageConfig: {
            ...req?.InitialPageConfig,
            Filter: {
              ...req?.InitialPageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
  });

  return {
    SubscriptionLoyaltyProgramMultiAutocomplete,
    SubscriptionLoyaltyProgramAutocomplete,
  };
};

export const generateSubscriptionLoyaltyProgramLyraSearchListField = (
  initialFilters?: Partial<EVA.Core.ListLoyaltyProgramsFilter>,
) =>
  LyraSearchListFieldGenerator<
    CoreManagement.ListLoyaltyPrograms,
    {
      ID: number;
      Name: string;
    },
    number
  >({
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    selectRenderElements: (item) => ({
      label: item.Name,
    }),
    getItemFromResponse: (resp) =>
      resp?.Results?.map((loyaltyProgram) => ({
        ID: loyaltyProgram.ID,
        Name: loyaltyProgram.Name,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.loyalty-program",
      defaultMessage: "Loyalty program",
    }),
    useItemByID: (id, items) => {
      const loyaltyProgramFromList = items?.find((item) => item.ID === id);

      const { data: loyaltyProgramFromService, isFetching: isLoading } = useGetLoyaltyProgramQuery(
        id && !loyaltyProgramFromList ? { ID: id } : undefined,
        {},
      );

      const data = useMemo(
        () =>
          loyaltyProgramFromList ??
          (loyaltyProgramFromService?.ID && loyaltyProgramFromService.Name
            ? { ID: loyaltyProgramFromService.ID, Name: loyaltyProgramFromService.Name }
            : undefined),
        [loyaltyProgramFromList, loyaltyProgramFromService],
      );

      return { data, isLoading };
    },
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listLoyaltyProgramsQuery,
        getQueryRequest: (req) => req?.InitialPageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          InitialPageConfig: {
            ...req?.InitialPageConfig,
            Filter: {
              ...req?.InitialPageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
        initialRequest: { InitialPageConfig: { Limit: 10, Filter: initialFilters } },
      }),
  });
