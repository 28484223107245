import { useOrganizationUnitSelectorContext } from "../organization-unit-selector-provider";

const useOrganizationUnitStructure = () => {
  const { flattenedOrganizationUnits, organizationUnits } = useOrganizationUnitSelectorContext();

  return {
    organizationUnits,
    flattenedOrganizationUnits,
  };
};

export default useOrganizationUnitStructure;
