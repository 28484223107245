import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listPurchaseOrderShipmentsLoaderQuery,
  serviceQueryKeys: listPurchaseOrderShipmentsQueryKeys,
  useServiceQueryHook: useListPurchaseOrderShipmentsQuery,
} = createServiceQuery(Core.ListPurchaseOrderShipments, true);

export const {
  serviceLoaderQuery: getPurchaseOrderShipmentLoaderQuery,
  serviceQueryKeys: getPurchaseOrderShipmentQueryKeys,
  useServiceQueryHook: useGetPurchaseOrderShipmentQuery,
} = createServiceQuery(Core.GetPurchaseOrderShipment);
