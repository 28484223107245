import { useCallback, useMemo } from "react";
import { useRevalidator } from "react-router-dom";

import { Button } from "@new-black/lyra";
import { setServiceSetting } from "@springtree/eva-sdk-core-service";
import { helpers, state } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { useSetRecoilState } from "recoil";

import { useShouldUseJWT } from "../helpers";
import { loginViaSSO } from "../utils";

import { loginViaSSOResultSchema } from "./login-sso-form.types";

import { useNavigate } from "~/components/routing";
import ErrorBoundary from "~/components/suite-ui/error-boundary";
import Text from "~/components/suite-ui/text";
import { getAuthorizationStructureKeys } from "~/models/auth";
import { mutate } from "~/util/mutate";
import { queryClient } from "~/util/query-client";

const { storage } = helpers;

interface ILoginWithSSOProps {
  primary?: boolean;
  providers?: EVA.Authentication.OpenID.GetAvailableOpenIDConfigurationsResponse["Providers"];
  processingProvider?: string;
  limitProvidersShown?: number;
  serviceError?: string;
  onLogin?: () => void;
  onProcessing?: (processingProvider?: string) => void;
}

const mutateLogin = mutate({ service: Core.Login, disabledNotifications: true });

export const LoginWithSSO = ({
  limitProvidersShown,
  onLogin,
  onProcessing,
  primary,
  processingProvider,
  providers,
  serviceError,
}: ILoginWithSSOProps) => {
  const setUserTokenState = useSetRecoilState(state.currentUser.currentUserTokenState);
  const revalidator = useRevalidator();

  const navigate = useNavigate();

  const shouldUseJWT = useShouldUseJWT();

  // Login via SSO by OpenIDConfig
  const handleLogin = useCallback(
    async (openIDConfig: EVA.Authentication.OpenID.AvailableOpenIDConfiguration) => {
      const ssoLoginResult = await loginViaSSO({
        BaseUrl: openIDConfig.BaseUrl,
        ClientID: openIDConfig.ClientID,
        ID: openIDConfig.ID,
      });

      const evaLoginResponse = await mutateLogin(
        {
          AsEmployee: true,
          UseJwtTokens: shouldUseJWT,
          SelectFirstOrganizationUnit: true,
          CustomAuthenticatorType: "OpenID",
          CustomAuthenticateData: {
            id_token: ssoLoginResult.idToken,
            provider: openIDConfig.ID,
            access_token: ssoLoginResult.accessToken,
          },
        },
        { authenticationToken: undefined },
      );

      if (evaLoginResponse?.response?.Authentication === 2) {
        setUserTokenState(evaLoginResponse?.response?.User?.AuthenticationToken ?? "");
        storage.setItem(
          "evaUserToken",
          evaLoginResponse?.response?.User?.AuthenticationToken ?? "",
        );
        setServiceSetting("requestedOrganizationUnitID", "");
        queryClient.removeQueries(getAuthorizationStructureKeys.base);
        revalidator.revalidate();
        onLogin?.();

        const ssoResultState = ssoLoginResult?.state ? JSON.parse(ssoLoginResult.state) : undefined;

        if (ssoResultState) {
          const parsedSSOResultState = loginViaSSOResultSchema.parse(ssoResultState);

          navigate(parsedSSOResultState.redirectPath);
        }
      }
    },
    [navigate, onLogin, revalidator, setUserTokenState, shouldUseJWT],
  );

  // Sort providers so that primary provider is shown first
  const sortedProviders = useMemo(
    () =>
      providers?.sort((providerA, providerB) => {
        if (providerA.Primary && !providerB.Primary) {
          // providerA is primary, providerB is not, so providerA should be shown first
          return -1;
        } else if (!providerA.Primary && providerB.Primary) {
          // providerB is primary, providerA is not, so providerB should be shown first
          return 1;
        }
        // both providers are primary or both are not, so no sorting is needed
        return 0;
      }),
    [providers],
  );

  return (
    <ErrorBoundary>
      <div className="flex flex-col">
        {sortedProviders
          ?.filter((_, i) => {
            if (limitProvidersShown && i >= limitProvidersShown) {
              return false;
            }
            return true;
          })
          ?.map((provider, i) => (
            <div className={i !== 0 ? "mt-2.5" : ""} key={provider.ID}>
              <Button
                fullWidth
                isDisabled={!!processingProvider}
                variant={primary && i === 0 ? "primary" : "secondary"}
                isLoading={processingProvider === provider.Name}
                onPress={() => {
                  onProcessing?.(provider.Name);
                  handleLogin(provider);
                }}
              >
                {provider.Name}
              </Button>
            </div>
          ))}
        {serviceError ? (
          <div className="py-5">
            <Text color="error">{serviceError}</Text>
          </div>
        ) : null}
      </div>
    </ErrorBoundary>
  );
};
