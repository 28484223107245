import { IWorkspaceView } from "./types";

import { DeleteModal } from "~/components/suite-composite/confirmation-modal/delete-modal";

const DeleteWorkspaceModal = ({
  isLoading,
  onCancel,
  onConfirm,
  open,
}: IWorkspaceView<undefined>) => (
  <DeleteModal
    isOpen={open}
    onCancel={onCancel}
    onConfirm={() => onConfirm(undefined)}
    isLoading={isLoading}
  />
);

export default DeleteWorkspaceModal;
