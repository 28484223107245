import { useIsFetching } from "@tanstack/react-query";
import { useRecoilValue } from "recoil";

import { shouldInitializeWorkspace } from "../workspaces.state";
import { EKeyName } from "../workspaces.types";

import { getWorkspacesQueryKeys } from "~/models/workspaces";

const useIsWorkspacesLoading = (keyName?: EKeyName) => {
  const isLoading = useIsFetching({ queryKey: getWorkspacesQueryKeys.base });

  const shouldInitWorkspace = useRecoilValue(shouldInitializeWorkspace(keyName));

  return !!isLoading || shouldInitWorkspace;
};

export default useIsWorkspacesLoading;
