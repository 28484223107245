import { useCallback } from "react";
import { useIntl } from "react-intl";

import DeclineTaskWarningDialog from "./decline-task-warning-dialog";
import useDeclineTasks from "./use-decline-tasks";
import useWarningDialog from "./use-warning-dialog";

import DeleteActionButton from "~/components/shared/action-buttons/delete-action-button";

interface ITasksDeclineAction<T> {
  selectedLines: T[];
  refetchTasks: () => void;
  emptySelectedLines: () => void;
}

const TasksTableDeclineAction = <T extends EVA.Core.AvailableUserTaskDto>({
  emptySelectedLines,
  refetchTasks,
  selectedLines,
}: ITasksDeclineAction<T>) => {
  const intl = useIntl();
  const declineTasksService = useDeclineTasks(emptySelectedLines, refetchTasks);

  const handleDeclineTask = useCallback(() => {
    declineTasksService(selectedLines.map((item) => item.ID));
  }, [declineTasksService, selectedLines]);

  const { onCloseWarningDialog, setShowWarningDialog, showWarningDialog } =
    useWarningDialog(handleDeclineTask);

  return (
    <>
      <DeleteActionButton
        tooltip={intl.formatMessage({
          id: "decline-tasks.label",
          defaultMessage: "Decline task(s)",
        })}
        isDisabled={!selectedLines.length}
        onPress={() => setShowWarningDialog(true)}
      />
      <DeclineTaskWarningDialog
        open={showWarningDialog}
        onClose={onCloseWarningDialog}
        numberOfTasks={selectedLines?.length}
      />
    </>
  );
};

export default TasksTableDeclineAction;
