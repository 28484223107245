import { useCallback, useEffect, useMemo } from "react";
import { useIntl } from "react-intl";

import { uniq } from "lodash";
import { v4 as uuid } from "uuid";

import { IProductSetFilter } from "../types";

import ProductPropertyFilter from "./product-property-filter";

import { InputClearIcon } from "~/components/shared/input-clear-icon";
import Input from "~/components/suite-ui/input";

export interface IProductPropertyFiltersList {
  productProperties: string[];
  filters: IProductSetFilter[];
  setFilters: (value: IProductSetFilter[]) => void;
  querySearchValue?: string;
  setQuerySearchValue: React.Dispatch<React.SetStateAction<string | undefined>>;
}

const ProductPropertyFiltersList = ({
  filters,
  productProperties,
  querySearchValue,
  setFilters,
  setQuerySearchValue,
}: IProductPropertyFiltersList) => {
  const intl = useIntl();

  // set 1 empty filter by default
  useEffect(() => {
    if (filters.length === 0) {
      setFilters([{}]);
    }
  }, [filters.length, setFilters]);

  const onFilterChange = useCallback(
    (newFilter: IProductSetFilter, index: number) => {
      const newFilters = [...filters];
      newFilters[index] = newFilter;
      setFilters(newFilters);
    },
    [filters, setFilters],
  );

  const addFilter = useCallback(() => {
    setFilters([...filters, {}]);
  }, [filters, setFilters]);

  const deleteFilter = useCallback(
    (index: number) => {
      setFilters(filters.filter((_, idx) => idx !== index));
    },
    [filters, setFilters],
  );

  const unusedProperties = useMemo(
    () =>
      productProperties.filter((property) =>
        filters.every((filter) => filter.ProductProperty !== property),
      ),
    [filters, productProperties],
  );

  const filtersWithUuids = useMemo(() => filters.map((x) => ({ ...x, uuid: uuid() })), [filters]);

  return (
    <div>
      <div className="flex flex-col gap-5 p-5 pt-0">
        <div className="w-1/2">
          <Input
            label={intl.formatMessage({
              id: "generic.label.query-search",
              defaultMessage: "Query search",
            })}
            className="pr-9"
            value={querySearchValue ?? ""}
            onChange={(event) => {
              setQuerySearchValue(event.target.value);
            }}
            small
            endIcon={
              <InputClearIcon
                onClear={() => setQuerySearchValue(undefined)}
                shown={querySearchValue !== undefined}
              />
            }
          />
        </div>

        {filtersWithUuids.map((filter, index) => (
          <ProductPropertyFilter
            key={filter.uuid}
            filter={filter}
            onChange={(newFilter) => {
              onFilterChange(newFilter, index);
            }}
            productProperties={
              filter.ProductProperty
                ? uniq([...unusedProperties, filter.ProductProperty])
                : unusedProperties
            }
            onAdd={index === 0 ? addFilter : undefined}
            onDelete={index !== 0 ? () => deleteFilter(index) : undefined}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductPropertyFiltersList;
