import { TextField, TextFieldProps } from "@new-black/lyra";
import { useField } from "formik";

import Input, { InputProps } from "~/components/suite-ui/input";

export interface FormikInputProps extends Omit<InputProps, "value" | "onChange"> {
  name: string;
}

export function FormikInput({ error, helperText, name, ...props }: FormikInputProps) {
  const [field, meta] = useField<string | undefined>(name);
  return (
    <Input
      {...props}
      {...field}
      value={field?.value ?? ""}
      error={error || (!!meta.error && meta.touched)}
      helperText={meta.error && meta.touched ? meta.error : helperText}
      inputProps={{
        ...props.inputProps,
        onBlur: (event) => {
          props.inputProps?.onBlur?.(event);
          props.onBlur?.(event);
        },
      }}
    />
  );
}

export interface LyraFormikInputProps
  extends Omit<TextFieldProps, "value" | "onChange" | "defaultValue" | "name"> {
  name: string;
}

export function LyraFormikInput({ errorMessage, isInvalid, name, ...props }: LyraFormikInputProps) {
  const [field, meta, { setValue }] = useField<string | undefined>(name);

  return (
    <TextField
      {...props}
      {...field}
      value={field?.value ?? ""}
      onChange={(newValue) => setValue(newValue)}
      errorMessage={meta.error && meta.touched ? meta.error : errorMessage}
      onBlur={(event) => {
        props.onBlur?.(event);
        field.onBlur(event);
      }}
    />
  );
}

FormikInput.Lyra = LyraFormikInput;
