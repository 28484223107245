import AddActionButton from "~/components/shared/action-buttons/add-action-button";
import DeleteActionButton from "~/components/shared/action-buttons/delete-action-button";
import EditActionButton from "~/components/shared/action-buttons/edit-action-button";
import { Authorized } from "~/components/suite-composite/authorized";
import { Functionalities, FunctionalityScope } from "~/types/functionalities";

export const CustomFieldOptionsEditAction = ({ handleEdit }: { handleEdit: () => void }) => {
  return (
    <Authorized
      disableRedirect
      scope={FunctionalityScope.Edit}
      functionality={Functionalities.CUSTOM_FIELDS}
    >
      <EditActionButton onPress={handleEdit} />
    </Authorized>
  );
};

export const CustomFieldOptionsDeleteAction = ({ handleDelete }: { handleDelete: () => void }) => {
  return (
    <Authorized
      disableRedirect
      scope={FunctionalityScope.Delete}
      functionality={Functionalities.CUSTOM_FIELDS}
    >
      <DeleteActionButton onPress={handleDelete} />
    </Authorized>
  );
};

export const CustomFieldOptionsCreateAction = ({ handleAdd }: { handleAdd: () => void }) => {
  return (
    <Authorized
      disableRedirect
      scope={FunctionalityScope.Create}
      functionality={Functionalities.CUSTOM_FIELDS}
    >
      <AddActionButton onPress={handleAdd} />
    </Authorized>
  );
};
