import { useMemo } from "react";
import { useRouteLoaderData } from "react-router-dom";

import { RootLoaderData } from "~/routes/root";

/**
 * Returns the value of the `customerName` property configured in the `eva-config.json` file for the current environment
 * or `undefined` if it does not exist.
 */
const useCustomerName = () => {
  const { evaConfig } = useRouteLoaderData("root") as RootLoaderData;

  const customerName = useMemo(() => evaConfig?.customerName, [evaConfig?.customerName]);

  return customerName;
};

export default useCustomerName;
