import { Core } from "@springtree/eva-services-core";
import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getOpeningHoursTypesLoaderQuery,
  serviceQuery: getOpeningHoursTypesQuery,
  serviceQueryKeys: getOpeningHoursTypesQueryKeys,
  useServiceQueryHook: useGetOpeningHoursTypesQuery,
} = createServiceQuery(CoreManagement.GetOpeningHoursTypes);

export const {
  serviceLoaderQuery: listOpeningHoursTemplatesLoaderQuery,
  serviceQuery: listOpeningHoursTemplatesQuery,
  serviceQueryKeys: listOpeningHoursTemplatesQueryKeys,
  useServiceQueryHook: useListOpeningHoursTemplatesQuery,
} = createServiceQuery(Core.ListOpeningHoursTemplates);

export const {
  serviceLoaderQuery: listOpeningHoursTemplatesOrganizationUnitSetsLoaderQuery,
  serviceQuery: listOpeningHoursTemplatesOrganizationUnitSetsQuery,
  serviceQueryKeys: listOpeningHoursTemplatesOrganizationUnitSetsQueryKeys,
  useServiceQueryHook: useListOpeningHoursTemplatesOrganizationUnitSetsQuery,
} = createServiceQuery(Core.ListOpeningHoursTemplatesOrganizationUnitSets, true);

export const {
  serviceLoaderQuery: getOpeningHoursTemplateLoaderQuery,
  serviceQuery: getOpeningHoursTemplateQuery,
  serviceQueryKeys: getOpeningHoursTemplateQueryKeys,
  useServiceQueryHook: useGetOpeningHoursTemplateQuery,
} = createServiceQuery(Core.GetOpeningHoursTemplateByID);
