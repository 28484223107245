import { useEffect, useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { useSetRecoilState } from "recoil";

import { productSearchTemplatesSelector, productSearchTemplatesState } from "../state";

import { useHandleSWR } from "~/hooks/suite-react-hooks/use-handle-swr";
import { ALL_ROWS_LIMIT } from "~/util/base-values";

const useProductSearchTemplates = () => {
  const productSearchTemplates = hooks.useGetState(productSearchTemplatesSelector);

  const productSearchTemplatesResponseLoading = hooks.useIsLoading({
    state: productSearchTemplatesSelector,
  });

  const productSearchTemplatesResponseLoadingWithoutResponse = useMemo(
    () => !productSearchTemplates && productSearchTemplatesResponseLoading,
    [productSearchTemplates, productSearchTemplatesResponseLoading],
  );

  const setRequest = useSetRecoilState(productSearchTemplatesState.request);

  // set initials request
  //
  useEffect(() => {
    if (!productSearchTemplates) {
      setRequest({
        PageConfig: {
          // TODO: check if we can use pagination instead of fetching all
          Limit: ALL_ROWS_LIMIT,
        },
      });
    }
  }, [productSearchTemplates, setRequest]);

  useHandleSWR(productSearchTemplatesState);

  return {
    productSearchTemplates,
    productSearchTemplatesResponseLoading,
    productSearchTemplatesResponseLoadingWithoutResponse,
  };
};

export default useProductSearchTemplates;
