import { Fragment, useCallback } from "react";

import { Button, SvgIcon, Text } from "@new-black/lyra";
import classNames from "clsx";
import { v4 as uuid } from "uuid";

import { Link, useNavigate } from "~/components/routing";

/**
 * Every page contains a wrapper with a title / breadcrumb.
 * @type IBreadcrumb
 */
export interface IBreadcrumb {
  url: string;
  name: string;
  onClick?: () => void;
  keepSearchParams?: boolean;
}

export interface IBreadcrumbs {
  /** `TBreadcrumb = {url: string, name: string}` */
  breadcrumbs: IBreadcrumb[];
  showBackButton?: boolean;
  backUrl?: string;
  className?: string;
}

const Breadcrumbs = ({ backUrl, breadcrumbs, className, showBackButton }: IBreadcrumbs) => {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    if (backUrl) {
      navigate(backUrl);
    } else {
      navigate(-1);
    }
  }, [backUrl, navigate]);

  // Helper for determining the last object in the array.
  // This way we can use that to give that object an active state and prevent linking to it.
  //
  const breadcrumbIndexLength = breadcrumbs.length - 1;

  const BackButton = showBackButton ? (
    <Button variant="icon" aria-label="go back" onPress={handleGoBack}>
      <SvgIcon name="chevron-left" />
    </Button>
  ) : null;

  const MappedBreadcrumbs = breadcrumbs.map((breadcrumb, index) => {
    if (index !== breadcrumbIndexLength) {
      return (
        <Fragment key={uuid()}>
          <Link
            className="text-inherit rounded-base text-secondary no-underline focus-visible:shadow-highlight focus-visible:ring-1 focus-visible:ring-default"
            to={breadcrumb.url}
            onClick={breadcrumb.onClick}
            keepSearchParams={breadcrumb.keepSearchParams}
          >
            <Text variant="heading-1" elementType="h2" color="secondary">
              {breadcrumb.name}
            </Text>
          </Link>
          <span className="text-tertiary">/</span>
        </Fragment>
      );
    }
    return (
      <Text key={uuid()} variant="heading-1" elementType="h1" color="primary" className="truncate">
        {breadcrumb.name}
      </Text>
    );
  });

  return (
    <div className={classNames("flex items-center gap-x-2", className)}>
      {BackButton}
      {MappedBreadcrumbs}
    </div>
  );
};

export default Breadcrumbs;
