import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listTaxCodesLoaderQuery,
  serviceQuery: listTaxCodesQuery,
  serviceQueryKeys: listTaxCodesQueryKeys,
  useServiceQueryHook: useListTaxCodesQuery,
} = createServiceQuery(Core.ListTaxCodes, true);
