import { useMemo } from "react";

import { helpers } from "@springtree/eva-sdk-react-recoil";

import { useGetAuthorizationStructureQuery } from "~/models/auth";
import { useRootRouteData } from "~/routes/root";
import { Functionalities, FunctionalityScope } from "~/types/functionalities";

export const useHasFunctionality = (
  functionality: Functionalities,
  scope: FunctionalityScope,
  /* Using the organizationUnitId will filter the functionalities a user has on a certain organization unit */
  organizationUnitId?: number,
) => {
  const { authorizationStructure: authorizationStructureQuery } = useRootRouteData();

  const {
    data: authorizationStructure,
    isFetching: loading,
    isLoading: loadingWithoutResponse,
  } = useGetAuthorizationStructureQuery(authorizationStructureQuery?.request, {
    ...authorizationStructureQuery?.queryProps,
  });

  // Due to problems using the provided eva enums, we have to use our own functionality scope
  // It is an exact duplicate of the one provided by EVA.
  // But it is not possible to typecast 1 enum, to another.
  // So first we need to type it as `unknown`.
  const convertedScope = scope as unknown;

  const functionalityAllowed = useMemo(() => {
    if (authorizationStructure?.Roles) {
      const hasFunctionality = helpers.authorizationStructure.hasFunctionality({
        authorizationStructure,
        functionality,
        scope: convertedScope as EVA.Core.FunctionalityScope,
        organizationUnitId,
      });
      return hasFunctionality;
    }
    return false;
  }, [authorizationStructure, convertedScope, functionality, organizationUnitId]);

  return { functionalityAllowed, loading, loadingWithoutResponse };
};
