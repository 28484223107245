import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listCaseStatusesLoaderQuery,
  serviceQuery: listCaseStatusesQuery,
  serviceQueryKeys: listCaseStatusesQueryKeys,
  useServiceQueryHook: useListCaseStatusesQuery,
  useServiceQueryWithRequest: useListCaseStatusesQueryWithRequest,
} = createServiceQuery(CoreManagement.ListCaseStatuses);

export const {
  serviceLoaderQuery: getCaseStatusLoaderQuery,
  serviceQueryKeys: getCaseStatusQueryKeys,
  useServiceQueryHook: useGetCaseStatusQuery,
} = createServiceQuery(CoreManagement.GetCaseStatus, true);

export const {
  serviceLoaderQuery: listCaseTopicsLoaderQuery,
  serviceQuery: listCaseTopicsQuery,
  serviceQueryKeys: listCaseTopicsQueryKeys,
  useServiceQueryHook: useListCaseTopicsQuery,
  useServiceQueryWithRequest: useListCaseTopicsQueryWithRequest,
} = createServiceQuery(CoreManagement.ListCaseTopics, true);

export const {
  serviceLoaderQuery: getCaseTopicLoaderQuery,
  serviceQueryKeys: getCaseTopicQueryKeys,
  useServiceQueryHook: useGetCaseTopicQuery,
} = createServiceQuery(CoreManagement.GetCaseTopic, true);

export const {
  serviceLoaderQuery: listCasesLoaderQuery,
  serviceQuery: listCasesQuery,
  serviceQueryKeys: listCasesQueryKeys,
  useServiceQueryHook: useListCasesQuery,
  useServiceQueryWithRequest: useListCasesQueryWithRequest,
} = createServiceQuery(CoreManagement.ListCases);

export const {
  serviceLoaderQuery: getCaseByIDLoaderQuery,
  serviceQueryKeys: getCaseByIDQueryKeys,
  useServiceQueryHook: useGetCaseByIDQuery,
} = createServiceQuery(CoreManagement.GetCaseByID, true);

export const {
  serviceLoaderQuery: listCaseInteractionsLoaderQuery,
  serviceQueryKeys: listCaseInteractionsQueryKeys,
  useServiceQueryHook: useListCaseInteractionsQuery,
} = createServiceQuery(CoreManagement.ListCaseInteractions, true);

export const {
  serviceLoaderQuery: getCaseInteractionLoaderQuery,
  serviceQueryKeys: getCaseInteractionQueryKeys,
  useServiceQueryHook: useGetCaseInteractionQuery,
} = createServiceQuery(CoreManagement.GetCaseInteraction, true);
