import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: searchStockMutationsLoaderQuery,
  serviceQueryKeys: searchStockMutationsQueryKeys,
  useServiceQueryHook: useSearchStockMutationsQuery,
  useServiceQueryWithRequest: useSearchStockMutationsQueryWithRequest,
} = createServiceQuery(CoreManagement.SearchStockMutations, true);

export const {
  serviceQuery: listStockMutationReasonsQuery,
  useServiceQueryHook: useListStockMutationReasonsQuery,
} = createServiceQuery(CoreManagement.ListStockMutationReasons, true);
