import { CoreManagement } from "@springtree/eva-services-core-management";
import { useQuery } from "@tanstack/react-query";

import {
  getPersonalizedPromotionByIDQuery,
  listPersonalizedPromotionsQuery,
} from "~/models/personalized-promotions";
import { AutocompleteGenerator } from "~/util/autocomplete-generator";
import { intlAccessor } from "~/util/intl-accessor";

export interface IPersonalizedPromotionAutocompleteItem {
  ID: number;
  Name: string;
}

export const generatePersonalizedPromotionAutocomplete = () => {
  const { SingleIDAutocomplete: PersonalizedPromotionSingleIDAutocomplete } = AutocompleteGenerator<
    CoreManagement.ListPersonalizedPromotions,
    IPersonalizedPromotionAutocompleteItem
  >({
    idKey: "ID",
    labelKey: "Name",
    getItemFromResponse: (response) => response?.Results,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.personalized-promotion",
      defaultMessage: "Personalized promotion",
    }),
    useItemByID: (ID) => {
      const { data, isFetching: isLoading } = useQuery({
        ...getPersonalizedPromotionByIDQuery(ID && typeof ID === "number" ? { ID } : undefined),
        refetchOnWindowFocus: false,
        enabled: !!ID,
      });

      return { data, isLoading };
    },
    useServiceQuery: () =>
      AutocompleteGenerator.useAutocompleteService({
        refetchOnFocus: false,
        query: listPersonalizedPromotionsQuery,
        initialRequest: { InitialPageConfig: { Limit: 10 } },
        getQueryRequest: (req) => req?.InitialPageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          InitialPageConfig: {
            ...req?.InitialPageConfig,
            Filter:
              newValue === ""
                ? undefined
                : {
                    Name: newValue,
                  },
          },
        }),
      }),
  });

  return {
    PersonalizedPromotionSingleIDAutocomplete,
  };
};
