import { Core } from "@springtree/eva-services-core";
import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listCashCorrectionReasonsLoaderQuery,
  serviceQueryKeys: listCashCorrectionReasonsQueryKeys,
  useServiceQueryHook: useListCashCorrectionReasonsQuery,
} = createServiceQuery(CoreManagement.ListCashCorrectionReasons);

export const {
  serviceLoaderQuery: getCashCorrectionReasonLoaderQuery,
  serviceQueryKeys: getCashCorrectionReasonQueryKeys,
  useServiceQueryHook: useGetCashCorrectionReasonQuery,
} = createServiceQuery(Core.GetCashCorrectionReason);
