import { useState } from "react";
import { useIntl } from "react-intl";

import { ConfirmationModal } from "~/components/suite-composite/confirmation-modal";

interface IPropertyClashConfirmationModalState {
  show: boolean;
  message?: string;
  onSubmit?: () => void;
  onCancel?: () => void;
  variant?: "material" | "lyra";
}

export function usePropertyClashConfirmationModalState() {
  const [propertyClashConfirmationModalState, setPropertyClashConfirmationModalState] =
    useState<IPropertyClashConfirmationModalState>({
      show: false,
    });

  return {
    propertyClashConfirmationModalState,
    setPropertyClashConfirmationModalState,
  };
}

export const PropertyClashConfirmationModal = ({
  message,
  onCancel,
  onSubmit,
  show,
  variant,
}: IPropertyClashConfirmationModalState) => {
  const intl = useIntl();

  return (
    <ConfirmationModal
      disablePortal
      isOpen={show}
      onCancel={() => onCancel?.()}
      onConfirm={() => onSubmit?.()}
      variant={variant}
      messages={{
        descriptionMessage:
          message ??
          intl.formatMessage(
            {
              id: "generic.question.overwrite-clashing-properties.singular",
              defaultMessage: "Are you sure you want to overwrite this property: {property}?",
            },
            { property: "" },
          ),
      }}
    />
  );
};
