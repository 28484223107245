import { useEffect, useMemo, useState } from "react";

import Editor from "@monaco-editor/react";
import type Monaco from "monaco-editor";

import { BASE_SCRIPT_EDITOR_OPTIONS } from "./helpers/base-script-editor-options";
import useDisableFirstLineEditing from "./hooks/use-disable-first-line-editing";
import useSetErrorsOnModel from "./hooks/use-set-errors-on-model";
import { IScriptCodeEditorBaseProps } from "./script-code-editor.types";
import { useScriptCodeEditorContext } from "./script-code-editor-provider";

import useSaveShortcut from "~/hooks/use-save-shortcut";
import { removeZeroWidthCharacters } from "~/util/helper";

export interface IScriptCodeEditor extends IScriptCodeEditorBaseProps {
  source?: string;
  initialModel?: Monaco.editor.ITextModel | null;
  onBlur?: (model?: Monaco.editor.ITextModel | null) => void;
}

/**
 * Component that renders a monaco editor configured with the language features of the Script language.
 * Make sure it is rendered within a `ScriptCodeEditorProvider` for the language features to work correctly.
 */
const ScriptCodeEditor = ({
  disableFirstLineEditing,
  errors,
  initialModel,
  onBlur,
  onChange,
  onSaveShortcut,
  readonly,
  source,
  ...props
}: IScriptCodeEditor) => {
  const { dialect } = useScriptCodeEditorContext();
  useSaveShortcut(onSaveShortcut);

  const [editor, setEditor] = useState<Monaco.editor.IStandaloneCodeEditor | null>(null);

  useDisableFirstLineEditing(editor, disableFirstLineEditing, source);
  useSetErrorsOnModel(editor?.getModel(), errors);

  function handleEditorDidMount(editor: Monaco.editor.IStandaloneCodeEditor) {
    const temp = editor;

    if (initialModel) {
      temp.setModel(initialModel);
    }

    temp.onDidBlurEditorWidget(() => {
      onBlur?.(editor?.getModel());
    });

    setEditor(temp);
  }

  const editorOptions = useMemo<Monaco.editor.IStandaloneEditorConstructionOptions | undefined>(
    () => ({
      ...BASE_SCRIPT_EDITOR_OPTIONS,
      readOnly: readonly,
    }),
    [readonly],
  );

  // Make sure we dispose the model on the current editor if the consumer doesn't provide an initial model, given
  // that `keepCurrentModel` prop is passed to the editor which disables the default behaviour of automatically
  // disposing the model on unmount
  useEffect(
    () => () => {
      if (!initialModel) {
        const temp = editor;

        temp?.getModel()?.dispose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <Editor
      {...props}
      value={source}
      onChange={(value) => onChange?.(removeZeroWidthCharacters(value))}
      language={dialect}
      onMount={handleEditorDidMount}
      options={editorOptions}
      keepCurrentModel
    />
  );
};

export default ScriptCodeEditor;
