import { useState } from "react";
import { useIntl } from "react-intl";

import { ButtonGroup } from "@new-black/lyra";
import { useRecoilState } from "recoil";

import { Authorized } from "../authorized";

import useGetEntityTranslationsState from "./hooks/use-get-translations-state";
import { entityTranslationsFilterTermAtom } from "./state";
import { EntityTranslationFieldIdentifiers, EntityTranslationFieldTranslations } from "./types";

import AddActionButton from "~/components/shared/action-buttons/add-action-button";
import SearchActionButton from "~/components/shared/action-buttons/search-action-button";
import Input from "~/components/suite-ui/input";
import { Functionalities, FunctionalityScope } from "~/types/functionalities";

interface IEntityTranslationFieldCustomActions {
  disabled?: boolean;
  addButtonRef: React.MutableRefObject<HTMLButtonElement | null>;
}

const EntityTranslationFieldCustomActions = ({
  addButtonRef,
  disabled,
  entityField,
  entityID,
  entityType,
  translations,
}: IEntityTranslationFieldCustomActions &
  EntityTranslationFieldIdentifiers & {
    translations?: EntityTranslationFieldTranslations;
  }) => {
  const intl = useIntl();

  const { results } = useGetEntityTranslationsState({ entityField, entityID, entityType });

  const [filterTerm, setFilterTerm] = useRecoilState(
    entityTranslationsFilterTermAtom({
      entityField,
      entityID,
      entityType,
    }),
  );

  const [showFilter, setShowFilter] = useState(!!filterTerm);

  return (
    <ButtonGroup className="items-center">
      {!results?.length ? null : showFilter ? (
        <Input
          disabled={disabled}
          className="w-56"
          autoFocus={!!showFilter && !filterTerm}
          small
          label={
            translations?.filters?.inputLabel ??
            intl.formatMessage({
              id: "generic.label.search-by-country-or-language",
              defaultMessage: "Search by country or language",
            })
          }
          value={filterTerm ?? ""}
          onChange={(event) => setFilterTerm(event.target.value)}
          onBlur={!filterTerm ? () => setShowFilter(false) : undefined}
        />
      ) : (
        <SearchActionButton
          isDisabled={disabled}
          onPress={() => setShowFilter(true)}
          tooltip={
            translations?.filters?.searchButtonLabel ??
            intl.formatMessage({
              id: "generic.label.search-translations",
              defaultMessage: "Search translations",
            })
          }
        />
      )}
      <Authorized
        disableRedirect
        functionality={Functionalities.TRANSLATIONS}
        scope={FunctionalityScope.Create}
      >
        <AddActionButton
          isDisabled={!!filterTerm || disabled}
          ref={addButtonRef}
          tooltip={intl.formatMessage({
            id: "generic.label.add-row",
            defaultMessage: "Add row",
          })}
        />
      </Authorized>
    </ButtonGroup>
  );
};

export default EntityTranslationFieldCustomActions;
