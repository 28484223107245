import { useQuery } from "@tanstack/react-query";

import { getAvailableCurrenciesQuery } from "~/models/application";

const TEN_MINUTES = 10 * 60 * 1000;

export const useCurrencyPrecision = (currencyID?: string) => {
  const { data: currencyData } = useQuery({
    ...getAvailableCurrenciesQuery({}),
    staleTime: TEN_MINUTES,
    select: (data) => data?.Currencies?.find((currency) => currency.ID === currencyID),
  });

  return currencyData?.Precision ?? 2;
};

export const useAllCurrencyPrecisions = () => {
  const { data: currencyPrecisionMap } = useQuery({
    ...getAvailableCurrenciesQuery({}),
    staleTime: TEN_MINUTES,
    select: (data) =>
      data?.Currencies?.reduce(
        (acc, curr) => ({ ...acc, [curr.ID]: curr.Precision }),
        {} as Record<string, number>,
      ),
  });

  return currencyPrecisionMap;
};
