import { isNumber } from "lodash";

import { Checkbox } from "~/components/ui/checkbox";
import { DaysApplicable } from "~/types/days-applicable";

export const daysApplicableOptions = Object.entries(DaysApplicable)
  .filter(([, value]) => isNumber(value) && value !== 0 && value !== 127)
  .map(([label, value]) => ({ label, value: parseInt(value as string, 10) }));

export interface IDaysApplicableCheckboxMultiselect {
  daysApplicable: number;
  onChange: (val: number) => void;
}

const DaysApplicableMultiSelect = ({
  daysApplicable,
  onChange,
}: IDaysApplicableCheckboxMultiselect) => (
  <div className="flex">
    {daysApplicableOptions.map((entry) => (
      <div key={entry.label} className="w-10">
        <div>{entry.label.slice(0, 2)}</div>
        <Checkbox
          name={entry.label}
          className="my-3"
          value={(daysApplicable & entry.value) === entry.value}
          onChange={() => {
            onChange(entry.value);
          }}
        />
      </div>
    ))}
  </div>
);

export default DaysApplicableMultiSelect;
