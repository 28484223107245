import { Dispatch, SetStateAction, useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Badge, Card, Dialog, SvgIcon } from "@new-black/lyra";
import classNames from "clsx";

import AddSharedWorkspaceModal from "./workspace/add-shared-workspace-modal";
import AddWorkspaceModal from "./workspace/add-workspace-modal";
import DeleteWorkspaceModal from "./workspace/delete-workspace-modal";
import EditWorkspaceSharePermissionsModal from "./workspace/edit-workspace-share-permissions-modal";
import SaveFromCurrentLayoutModal from "./workspace/save-workspace-from-current-layout-modal";
import { useWorkspaceModals } from "./workspace/workspace-modal-reducer";
import WorkspaceSelector, { IWorkspaceSelectorItem } from "./workspace/workspace-selector";
import { MenuWorkSpaceSelectorProps } from "./menu.types";
import { MenuButton } from "./menu-button";

import { useWorkspaces } from "~/features/workspaces";
import useAllowedFunctionalities from "~/hooks/suite-react-hooks/use-allowed-functionalities";
import { Functionalities } from "~/types/functionalities";

export function MenuWorkspaceSelector<TWorkSpace>({
  menuState,
  setIsFocused,
  setMouseEntered,
  workspaceState,
}: MenuWorkSpaceSelectorProps<TWorkSpace>) {
  const intl = useIntl();

  const [workspaceSelectorOpen, setWorkspaceSelectorOpen] = useState(false);

  return workspaceState.selectedWorkspace ? (
    <>
      <MenuButton
        icon={<SvgIcon name="workspaces" />}
        labelKey={intl.formatMessage({
          id: "generic.label.workspace",
          defaultMessage: "Workspace",
        })}
        labelValue={workspaceState.selectedWorkspace.Name ?? "-"}
        menuState={menuState}
        end={workspaceState.isWorkspaceChanged ? <UnsavedLabel /> : undefined}
        setMouseEntered={setMouseEntered}
        setIsFocused={setIsFocused}
        onClick={() => setWorkspaceSelectorOpen(true)}
      />
      <WorkspaceSelectorDialog
        open={workspaceSelectorOpen}
        setOpen={setWorkspaceSelectorOpen}
        workspace={workspaceState}
      />
    </>
  ) : null;
}

export const UnsavedLabel = ({ className }: { className?: string }) => (
  <Badge variant="orange" className={classNames("max-w-16", className)}>
    <FormattedMessage id="generic.label.unsaved" defaultMessage="Unsaved" />
  </Badge>
);

function WorkspaceSelectorDialog<TWorkSpace>({
  open,
  setOpen,
  workspace,
}: {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  workspace: ReturnType<typeof useWorkspaces<TWorkSpace>>;
}) {
  const [isLoading, setIsLoading] = useState(false);

  const [localSelectedWorkspace, setLocalSelectedWorkspace] = useState(workspace.selectedWorkspace);
  const [workspaceToDelete, setWorkspaceToDelete] = useState<IWorkspaceSelectorItem>();

  /** Synchronize current active workspace with local selected workspace. */
  useEffect(
    () => setLocalSelectedWorkspace(workspace.selectedWorkspace),
    [workspace.selectedWorkspace],
  );

  const { toggleWorkspaceModal, workspaceModalOpen } = useWorkspaceModals();

  const isWorkspaceModalOpen = useMemo(
    () => open && !Object.values(workspaceModalOpen).some((el) => !!el),
    [open, workspaceModalOpen],
  );

  const sharedWorkspacesFunctionality = useAllowedFunctionalities(
    Functionalities.SHARED_WORKSPACES,
  );

  /** Wrapper callback that also sets the loading state. */
  const onAction = useCallback(
    async (callback: () => Promise<{ success: boolean }>) => {
      setIsLoading(true);

      const result = await callback();

      if (result.success) {
        toggleWorkspaceModal("Close");
      }

      setIsLoading(false);
    },
    [toggleWorkspaceModal],
  );

  return (
    <>
      <Dialog
        aria-label="Workspace selector"
        isOpen={isWorkspaceModalOpen}
        onOpenChange={setOpen}
        maxWidth="xl"
      >
        <Card>
          <WorkspaceSelector
            localSelectedWorkspace={localSelectedWorkspace}
            setLocalSelectedWorkspace={setLocalSelectedWorkspace}
            items={workspace.workspaces}
            hasUnsavedChanges={workspace.isWorkspaceChanged}
            selectedItem={workspace.selectedWorkspace}
            onSelectedItemChange={async (workspaceItem) => {
              await workspace.handleSetSelectedWorkspace(workspaceItem);

              setOpen(false);
            }}
            onAdd={() => toggleWorkspaceModal("Add")}
            onAddFromDefault={() => toggleWorkspaceModal("AddFromDefault")}
            onAddShared={() => toggleWorkspaceModal("AddShared")}
            onAddSharedFromDefault={() => toggleWorkspaceModal("AddSharedFromDefault")}
            onEditSharePermissions={() => toggleWorkspaceModal("EditSharePermissions")}
            onDelete={(workspace) => {
              setWorkspaceToDelete(workspace);

              toggleWorkspaceModal("Delete");
            }}
            onReset={workspace.handleResetWorkspace}
            onResetToDefault={workspace.handleResetToDefaultWorkspace}
            onSaveAsNewWorkspace={() => toggleWorkspaceModal("SaveAsNewWorkspace")}
            onSave={
              localSelectedWorkspace?.ID !== workspace.selectedWorkspace?.ID
                ? () => toggleWorkspaceModal("SaveFromCurrentLayout")
                : (item) =>
                    workspace.handleSaveWorkspace({
                      ...item,
                      Settings: workspace.selectedWorkspace!.Settings,
                    })
            }
            sharedWorkspaceFunctionality={sharedWorkspacesFunctionality}
            isAdvancedWorkspaceEnabled={workspace.isAdvancedWorkspaceEnabled}
            setIsAdvancedWorkspaceEnabled={workspace.setIsAdvancedWorkpaceEnabled}
            hasAdvancedWorkspaceFunctionality={workspace.hasAdvancedWorkspaceFunctionality}
            isLoading={workspace.workspacesLoading}
            onClose={() => setOpen(false)}
          />
        </Card>
      </Dialog>

      <SaveFromCurrentLayoutModal
        open={workspaceModalOpen.SaveFromCurrentLayout}
        onCancel={() => toggleWorkspaceModal("Close")}
        onConfirm={() => {
          if (localSelectedWorkspace) {
            onAction(() => workspace.handleSaveWorkspace(localSelectedWorkspace));
          }
        }}
        isLoading={isLoading}
      />

      <AddWorkspaceModal
        open={workspaceModalOpen.SaveAsNewWorkspace}
        onCancel={() => toggleWorkspaceModal("Close")}
        onConfirm={({ workspaceName }) => {
          if (localSelectedWorkspace?.ID === workspace.selectedWorkspace?.ID) {
            onAction(() => workspace.handleAddWorkspace(workspaceName));
          } else if (localSelectedWorkspace) {
            onAction(() =>
              workspace.handleAddWorkspace(workspaceName, workspace.selectedWorkspace?.Settings),
            );
          }
        }}
        isLoading={isLoading}
      />

      <AddWorkspaceModal
        open={workspaceModalOpen.Add}
        onCancel={() => toggleWorkspaceModal("Close")}
        onConfirm={({ workspaceName }) => {
          onAction(() => workspace.handleAddWorkspace(workspaceName));
        }}
        isLoading={isLoading}
      />

      <AddWorkspaceModal
        open={workspaceModalOpen.AddFromDefault}
        onCancel={() => toggleWorkspaceModal("Close")}
        onConfirm={({ workspaceName }) => {
          onAction(() => workspace.handleAddWorkspace(workspaceName, undefined, true));
        }}
        isLoading={isLoading}
      />

      <AddSharedWorkspaceModal
        open={workspaceModalOpen.AddShared}
        onCancel={() => toggleWorkspaceModal("Close")}
        onConfirm={({ defaultRoleIDs, roleIDs, workspaceName }) => {
          if (workspace.selectedWorkspace?.Settings) {
            onAction(() =>
              workspace.handleAddSharedWorkspace(workspaceName, roleIDs, defaultRoleIDs),
            );
          }
        }}
        isLoading={isLoading}
      />

      <AddSharedWorkspaceModal
        open={workspaceModalOpen.AddSharedFromDefault}
        onCancel={() => toggleWorkspaceModal("Close")}
        onConfirm={({ defaultRoleIDs, roleIDs, workspaceName }) => {
          if (localSelectedWorkspace?.Settings) {
            onAction(() =>
              workspace.handleAddSharedWorkspace(workspaceName, roleIDs, defaultRoleIDs, true),
            );
          }
        }}
        isLoading={isLoading}
      />

      {workspaceToDelete ? (
        <DeleteWorkspaceModal
          open={workspaceModalOpen.Delete}
          onCancel={() => toggleWorkspaceModal("Close")}
          onConfirm={async () => {
            if (workspaceToDelete) {
              onAction(() => workspace.handleDeleteWorkspace(workspaceToDelete));
            }
          }}
          isLoading={isLoading}
          title={workspaceToDelete.Name}
        />
      ) : null}

      {localSelectedWorkspace?.ID && typeof localSelectedWorkspace.ID === "number" ? (
        <EditWorkspaceSharePermissionsModal
          open={workspaceModalOpen.EditSharePermissions}
          onCancel={() => toggleWorkspaceModal("Close")}
          onConfirm={({ defaultRoleIDs, roleIDs }) => {
            if (localSelectedWorkspace?.Settings) {
              onAction(() =>
                workspace.handleEditSharePermissions(
                  localSelectedWorkspace.ID as number,
                  roleIDs,
                  defaultRoleIDs,
                ),
              );
            }
          }}
          isLoading={isLoading}
          workspaceID={localSelectedWorkspace?.ID}
        />
      ) : null}
    </>
  );
}
