import { useEffect, useMemo, useState } from "react";
import { FormattedMessage } from "react-intl";

import { Button, SvgIcon, Text } from "@new-black/lyra";

import Image from "~/components/suite-ui/image";

interface IImageCarouselProps<T extends { src?: string; alt?: string }> {
  images?: T[];
  size?: number;
  srcFallback?: string;
  altFallback?: string;
  getImageText?: (image: T, idx: number) => string | undefined;
}

export function ImageCarousel<T extends { src?: string; alt?: string }>({
  altFallback = "no-image",
  getImageText,
  images,
  size,
  srcFallback = "no-image",
}: IImageCarouselProps<T>) {
  const [page, setPage] = useState(0);

  useEffect(() => {
    // When the images change, go to the first page
    if (images && images.length) {
      setPage(0);
    }
  }, [images]);

  const currentImage = useMemo(() => images?.find((_, idx) => idx === page), [images, page]);

  return (
    <div className="flex h-full w-full flex-col justify-between gap-4">
      <div className="flex h-full w-full flex-col items-center justify-center gap-2 overflow-hidden">
        {currentImage ? (
          <>
            <Image
              src={currentImage?.src ?? srcFallback}
              alt={currentImage?.alt ?? altFallback}
              className="block"
              enlarge={false}
              style={{ width: "auto", height: size }}
            />

            {getImageText ? (
              <Text variant="body-small" className="break-words">
                {getImageText(currentImage, page) ?? ""}
              </Text>
            ) : null}
          </>
        ) : null}
      </div>

      {images?.length ? (
        <div className="flex w-full items-center justify-between gap-4">
          <Button
            variant="icon"
            onPress={() => setPage((current) => --current)}
            isDisabled={page === 0}
          >
            <SvgIcon name="chevron-left" />
          </Button>

          <Text variant="body-small" className="text-secondary">
            <FormattedMessage
              id="generic.label.carousel-page-indicator"
              defaultMessage="{currentPage} of {total}"
              values={{ currentPage: page + 1, total: (images ?? []).length }}
            />
          </Text>

          <Button
            variant="icon"
            onPress={() => setPage((current) => ++current)}
            isDisabled={page === (images?.length ?? 0) - 1}
          >
            <SvgIcon name="chevron-right" />
          </Button>
        </div>
      ) : null}
    </div>
  );
}
