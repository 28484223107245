import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Form } from "react-router-dom";

import { CardContent, CardHeader, Dialog, DialogCard } from "@new-black/lyra";
import { isEmpty } from "lodash";

import {
  AddEditLoyaltyProgramRuleProductSetConditionFields,
  TLoyaltyProgramRuleProductSetCondition,
  TLoyaltyProgramRuleProductSetConditionErrors,
} from ".";

import { FormSubmitDialogFooter } from "~/components/shared/form-submit-dialog-footer";
import LoadingStateBox from "~/components/suite-ui/loading-state-box";
import { removeUndefinedValues } from "~/util/helper";

interface IAddEditLoyaltyProgramRuleProductSetConditionModal {
  /** Action path. */
  actionPath?: string;
  /** Form ID. */
  form: string;
  isOpen: boolean;
  onClose: () => void;
  errors?: TLoyaltyProgramRuleProductSetConditionErrors;
  initialValues?: TLoyaltyProgramRuleProductSetCondition;
  isLoading?: boolean;
}

export const AddEditLoyaltyProgramRuleProductSetConditionModal = ({
  actionPath,
  errors,
  form,
  initialValues,
  isLoading,
  isOpen,
  onClose,
}: IAddEditLoyaltyProgramRuleProductSetConditionModal) => {
  const intl = useIntl();

  const hasInitialValues = useMemo(
    () => !isEmpty(removeUndefinedValues(initialValues)),
    [initialValues],
  );

  return (
    <Dialog isOpen={isOpen} onOpenChange={(open) => (!open ? onClose() : undefined)} maxWidth="lg">
      <Form method="POST" id={form} action={actionPath}>
        <DialogCard>
          <CardHeader
            title={intl.formatMessage({
              id: "generic.label.product-set",
              defaultMessage: "Product set",
            })}
          />

          {isLoading ? (
            <LoadingStateBox limit={5} />
          ) : (
            <CardContent className="max-h-[60vh] overflow-y-auto">
              <AddEditLoyaltyProgramRuleProductSetConditionFields
                errors={errors}
                initialValues={initialValues}
              />
            </CardContent>
          )}

          <FormSubmitDialogFooter
            isSaveDisabled={isLoading}
            formID={form}
            onCancel={onClose}
            saveLabel={
              hasInitialValues
                ? undefined
                : intl.formatMessage({
                    id: "generic.label.create",
                    defaultMessage: "Create",
                  })
            }
          />
        </DialogCard>
      </Form>
    </Dialog>
  );
};
