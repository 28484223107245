import { useCallback, useEffect, useState } from "react";
import { useFetcher, useLocation } from "react-router-dom";

import { Dialog } from "@material-ui/core";
import { Core } from "@springtree/eva-services-core";

import { Login } from "~/features/login";
import useEventListener from "~/hooks/suite-react-hooks/use-event-listener";
import { unauthorizedRequestsStrategy } from "~/routes/__auth/unauthorized-requests-strategy";
import { pathsWithoutUserTokenRequirement } from "~/routes/root/root";
import routeDefinitions from "~/routes/route-definitions";
import { mutate } from "~/util/mutate";

const validateTokenService = mutate({
  service: Core.ValidateToken,
  disabledNotifications: true,
  disableRedirectOn401: true,
});

const useValidateToken = (onSuccess: () => void, onError: () => void) => {
  const validateToken = useCallback(() => {
    validateTokenService({})
      .then((response) => {
        if (response?.success) {
          onSuccess();
        } else {
          onError();
        }
      })
      .catch(() => onError());
  }, [onError, onSuccess]);

  return validateToken;
};

export const ActivityLoginDialog = ({
  applicationConfiguration,
}: {
  applicationConfiguration: { [key: string]: any } | undefined;
}) => {
  const { pathname } = useLocation();

  const [loginModalOpen, setLoginModalOpen] = useState(false);

  const onSuccess = () => {
    console.info("[SESSION]: Token validated successfully");
  };

  const onError = useCallback(() => {
    if (unauthorizedRequestsStrategy.canApplyStrategy("modal")) {
      setLoginModalOpen(true);
    }
  }, []);

  const validateToken = useValidateToken(onSuccess, onError);

  const onActive = useCallback(() => {
    const pathShouldBeIgnored = pathsWithoutUserTokenRequirement.includes(pathname);
    if (!pathShouldBeIgnored) {
      validateToken();
    }
  }, [pathname, validateToken]);

  useEventListener("focus", onActive, window);

  const onLogin = useCallback(() => {
    setLoginModalOpen(false);

    // On successful login, reset the unauthorized handling strategy
    unauthorizedRequestsStrategy.reset();
  }, []);

  // close modal if the location changes to a path that should be ignored
  useEffect(() => {
    if (pathsWithoutUserTokenRequirement.includes(pathname) && loginModalOpen) {
      setLoginModalOpen(false);

      // Reset the unauthorized handling strategy
      unauthorizedRequestsStrategy.reset();
    }
  }, [loginModalOpen, pathname]);

  return (
    <Dialog open={loginModalOpen} fullWidth maxWidth="xs">
      <div className="p-4">
        <LoginContent onLogin={onLogin} applicationConfiguration={applicationConfiguration} />
      </div>
    </Dialog>
  );
};

type LoginContentProps = {
  onLogin: () => void;
  applicationConfiguration: { [key: string]: any } | undefined;
};

const LoginContent = ({ applicationConfiguration, onLogin }: LoginContentProps) => {
  const fetcher = useFetcher();

  // make sure that we fetch the openID providers
  useEffect(() => {
    fetcher.load(routeDefinitions.auth.login.path);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Login
      onLogin={onLogin}
      applicationConfiguration={applicationConfiguration}
      openIDProviders={fetcher.data?.openIDConfigurations?.value?.Providers}
    />
  );
};
