import { useCallback } from "react";
import { useIntl } from "react-intl";

import {
  PropertyClashConfirmationModal,
  usePropertyClashConfirmationModalState,
} from "../property-clash-confirmation-modal";
import { EvaFilterModel } from "../types";

import { AddBulkProductsModal } from "~/components/suite-composite/add-bulk-products-modal";

export const ProductFilterAddBulkProductsModal = ({
  closeOnSave = true,
  currentProductFilter,
  isLoading,
  isOpen,
  onClose,
  onSave,
}: {
  isOpen: boolean;
  onClose: () => void;
  onSave: (filters?: EvaFilterModel | undefined, property?: string) => Promise<any>;
  isLoading?: boolean;
  /** Used to check for property clash */
  currentProductFilter?: EvaFilterModel;
  /** Defaults to `true`. */
  closeOnSave?: boolean;
}) => {
  const intl = useIntl();

  const handleAddBulkProducts = useCallback(
    (property: string, values: string[]) => {
      onSave({ ...currentProductFilter, [property]: { Values: values } }, property).then(() => {
        if (closeOnSave) {
          onClose?.();
        }
      });
    },
    [closeOnSave, currentProductFilter, onClose, onSave],
  );

  const { propertyClashConfirmationModalState, setPropertyClashConfirmationModalState } =
    usePropertyClashConfirmationModalState();

  const checkPropertyClash = useCallback(
    (property: string, values: string[]) => {
      const currentKeys = Object.keys(currentProductFilter ?? {});
      if (currentKeys.includes(property)) {
        setPropertyClashConfirmationModalState({
          show: true,
          message: intl.formatMessage(
            {
              id: "generic.question.overwrite-clashing-properties.singular",
              defaultMessage: "Are you sure you want to overwrite this property: {property}?",
            },
            { property },
          ),
          onSubmit: () => {
            handleAddBulkProducts(property, values);
            setPropertyClashConfirmationModalState({ show: false });
          },
          onCancel: () => {
            setPropertyClashConfirmationModalState({ show: false });
          },
        });
        return;
      }
      handleAddBulkProducts(property, values);
    },
    [currentProductFilter, handleAddBulkProducts, intl, setPropertyClashConfirmationModalState],
  );

  return (
    <>
      {isOpen ? (
        <AddBulkProductsModal
          onSave={checkPropertyClash}
          closeModal={onClose}
          isLoading={isLoading}
          closeOnSave={closeOnSave}
        />
      ) : null}

      <PropertyClashConfirmationModal variant="lyra" {...propertyClashConfirmationModalState} />
    </>
  );
};
