import { IntlShape } from "react-intl";

import { OrganizationUnitSupplierTargets } from "~/types/eva-core";

export const getOrganizationUnitSupplierTargetsTranslator = (intl: IntlShape) => {
  const translationMap: Record<EVA.Core.OrganizationUnitSupplierTargets, string> = {
    [OrganizationUnitSupplierTargets.Default]: intl.formatMessage({
      id: "generic.label.allocation.target.Default",
      defaultMessage: "Default",
    }),
    [OrganizationUnitSupplierTargets.Pickup]: intl.formatMessage({
      id: "generic.label.allocation.target.Pickup",
      defaultMessage: "Pickup",
    }),
    [OrganizationUnitSupplierTargets.Replenishment]: intl.formatMessage({
      id: "generic.label.allocation.target.Replenishment",
      defaultMessage: "Replenishment",
    }),
    [OrganizationUnitSupplierTargets.SupplierReturns]: intl.formatMessage({
      id: "generic.label.allocation.target.SupplierReturns",
      defaultMessage: "RMA / RTS",
    }),
  };

  return (status: EVA.Core.OrganizationUnitSupplierTargets) => {
    return translationMap[status];
  };
};
