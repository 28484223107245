import { memo, useCallback, useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

import transitions from "@material-ui/core/styles/transitions";
import { Badge, Card, CardActions, CardHeader, SvgIcon, Text } from "@new-black/lyra";
import { cloneDeep, filter, isArray, isEqual, isNumber, map } from "lodash";
import { v4 as uuid } from "uuid";

import DeleteActionButton from "../action-buttons/delete-action-button";

import {
  IEvaProductSet,
  IEvaProductSetOptions,
  IUpdateEvaProductSetOptions,
  ProductSetItem,
} from "./eva-product-set.types";
import EvaProductSetActions from "./eva-product-set-actions";

import EvaProductSetForm from "~/components/shared/eva-product-set/eva-product-set-form";
import { DeleteModal } from "~/components/suite-composite/confirmation-modal/delete-modal";
import {
  EEvaDiscountProductSetType,
  IEvaDiscountProductSet,
} from "~/features/discount-edit/edit-discount.types";
import useEditDiscountEditMode from "~/features/discount-edit/hooks/use-edit-discount-edit-mode";
import useScrollToLast from "~/util/hooks/use-scroll-to-last";

const EvaProductSet = ({
  amountOfProductsOption,
  defaultProductOption,
  initWithEmpyProductSet,
  isDiscountableProductSetOption,
  max,
  min,
  passive,
  productRequirementsOption,
  productSet,
  productSetUpdated,
  productSetValid,
  requiredAmountOption,
  requiredAmountSpentOption,
  requiredProductSetName,
  title,
}: IEvaProductSet & IEvaProductSetOptions) => {
  const [productSetScope, setProductSetScope] = useState<ProductSetItem[]>(
    productSet?.length
      ? productSet.map((setItem) => ({
          type: setItem.ProductSearchID
            ? EEvaDiscountProductSetType.ProductSearch
            : setItem.ProductIDs?.length
            ? EEvaDiscountProductSetType.ProductIDs
            : setItem.PersonalizedPromotionID
            ? EEvaDiscountProductSetType.PersonalizedPromotion
            : setItem.ProductRequirements
            ? EEvaDiscountProductSetType.ProductRequirement
            : EEvaDiscountProductSetType.ProductSet,
          key: uuid(),
          isValid: true,
          set: setItem,
        }))
      : initWithEmpyProductSet
      ? [
          {
            type: EEvaDiscountProductSetType.ProductSet,
            key: uuid(),
            isValid: false,
            set: {
              Name: "",
              IsDiscountableProductSet: false,
            } as IEvaDiscountProductSet,
          },
        ]
      : [],
  );
  const [isScopeValid, setIsScopeValid] = useState<boolean | undefined>();

  const [productSetToDelete, setProductSetToDelete] = useState<ProductSetItem>();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const [edited, setEdited] = useState(false);
  useEditDiscountEditMode(edited);

  const [shouldFocusInput, setShouldFocusInput] = useState<boolean>(false);

  const { enableScrollToLast, getScrollToLast } = useScrollToLast();

  // callback to add a new set to scope. Won't output until valid
  const addNewSet = useCallback(
    (disableScrollToLast = false, productSetType: EEvaDiscountProductSetType) => {
      if (!disableScrollToLast) {
        enableScrollToLast();
        setShouldFocusInput(true);
      }

      setProductSetScope((state) => [
        ...(state ?? []),
        {
          type: productSetType,
          key: uuid(),
          isValid: false,
          set: (productSetType === EEvaDiscountProductSetType.ProductRequirement
            ? { ProductRequirements: [{ uuid: uuid() }] }
            : {}) as IEvaDiscountProductSet,
        },
      ]);
    },
    [enableScrollToLast],
  );

  // callback to update a specific set within the scope. Also validate.
  const updateSet = useCallback(
    (setRef: string, options: IUpdateEvaProductSetOptions, validationFailed = false) => {
      setProductSetScope((state) =>
        map(state, (setItem) => {
          if (setItem.key === setRef) {
            const updatedSetItem = cloneDeep(setItem);
            updatedSetItem.isValid = true;

            if (validationFailed) {
              setItem.isValid = false;
              return setItem;
            }

            if (options.setName !== undefined) {
              updatedSetItem.set.Name = options.setName;
            }

            // validate set by type
            if (setItem.type === EEvaDiscountProductSetType.ProductSearch) {
              updatedSetItem.set.ProductSearchID = options.productSearchID;

              // product search ID is required
              if (!options.productSearchID) {
                updatedSetItem.isValid = false;
              }
            } else if (setItem.type === EEvaDiscountProductSetType.ProductIDs) {
              updatedSetItem.set.ProductIDs = options.productIDs;

              // product IDs are required
              if (!options.productIDs?.length) {
                updatedSetItem.isValid = false;
              }
            } else if (setItem.type === EEvaDiscountProductSetType.ProductSet) {
              // set must have at least 1 filter in order to be valid
              if (!updatedSetItem.set.Filters || updatedSetItem.set.Filters.length < 1) {
                updatedSetItem.isValid = false;
              }
            } else if (setItem.type === EEvaDiscountProductSetType.PersonalizedPromotion) {
              // set the personalized promotion ID
              updatedSetItem.set.PersonalizedPromotionID = options.personalizedPromotionID;

              // personalized Promotion ID is required
              if (!options.personalizedPromotionID) {
                updatedSetItem.isValid = false;
              }
            } else if (setItem.type === EEvaDiscountProductSetType.ProductRequirement) {
              // set the product requirement object
              updatedSetItem.set.ProductRequirements = options.productRequirements;

              // all fields are required
              if (
                options.productRequirements?.some(
                  (productRequirement) =>
                    !productRequirement?.ProductRequirementID ||
                    !productRequirement.ProductID ||
                    !productRequirement.Values?.length,
                )
              ) {
                updatedSetItem.isValid = false;
              }
            }

            // set DefaultProductToAdd only if the defaultProductOption is set
            if (options.defaultProduct?.product_id && defaultProductOption) {
              updatedSetItem.set.DefaultProductToAdd = options.defaultProduct?.product_id;
            } else {
              updatedSetItem.set.DefaultProductToAdd = null;
            }

            // set must have a name in order to be valid
            if (requiredProductSetName && !updatedSetItem.set.Name) {
              updatedSetItem.isValid = false;
            }

            if (options.filters !== undefined) {
              updatedSetItem.set.Filters = options.filters;
            }

            updatedSetItem.set.RequiredQuantityOfProducts =
              options.requiredQuantityOfProducts ?? undefined;
            updatedSetItem.set.RequiredProductAmount =
              options.requiredAmountSpentOnProducts ?? undefined;

            const isRequiredQuantityOfProductsValid =
              updatedSetItem.set.RequiredQuantityOfProducts &&
              updatedSetItem.set.RequiredQuantityOfProducts >= 1;

            const isRequiredQuantitySpentOnProductsValid =
              updatedSetItem.set.RequiredProductAmount &&
              updatedSetItem.set.RequiredProductAmount > 0;

            if (options.isDiscountableProductSet !== undefined && isDiscountableProductSetOption) {
              updatedSetItem.set.IsDiscountableProductSet =
                options.isDiscountableProductSet ?? false;
            } else {
              updatedSetItem.set.IsDiscountableProductSet = false;
            }

            // if amountOfProductsOption is set, defaultProductOption is not set or it is set but
            // the product set is not discountable, RequiredQuantityOfProducts must be 1 or higher to be valid
            if (
              amountOfProductsOption &&
              !requiredAmountSpentOption &&
              (!defaultProductOption ||
                (defaultProductOption && !updatedSetItem.set.IsDiscountableProductSet)) &&
              !isRequiredQuantityOfProductsValid
            ) {
              updatedSetItem.isValid = false;
            }

            // if requiredAmountSpentOption is set, at least one of RequiredQuantityOfProducts or RequiredProductAmount must be set and valid
            if (
              requiredAmountSpentOption &&
              !(isRequiredQuantityOfProductsValid || isRequiredQuantitySpentOnProductsValid)
            ) {
              updatedSetItem.isValid = false;
            }

            if (
              options.discountableQuantityOfProducts !== undefined &&
              isDiscountableProductSetOption
            ) {
              updatedSetItem.set.DiscountableQuantityOfProducts =
                options.discountableQuantityOfProducts;
            } else {
              updatedSetItem.set.DiscountableQuantityOfProducts = null;
            }

            if (
              updatedSetItem.set.IsDiscountableProductSet &&
              (!updatedSetItem.set.DiscountableQuantityOfProducts ||
                updatedSetItem.set.DiscountableQuantityOfProducts < 1)
            ) {
              updatedSetItem.isValid = false;
            }

            return updatedSetItem;
          }

          return setItem;
        }),
      );
    },
    [
      amountOfProductsOption,
      requiredAmountSpentOption,
      defaultProductOption,
      isDiscountableProductSetOption,
      requiredProductSetName,
    ],
  );

  // callback to delete a set
  const deleteSet = useCallback(
    (setRef: string) => {
      setProductSetScope((state) => filter(state, (stateItem) => stateItem.key !== setRef));

      // Reset product set to delete after leaving transition of the modal is fulfilled
      setTimeout(() => setProductSetToDelete(undefined), transitions.duration.leavingScreen);
    },
    [setProductSetScope],
  );

  const openDeleteModal = useCallback((set?: ProductSetItem) => {
    setProductSetToDelete(set);
    setIsDeleteModalOpen(true);
  }, []);

  const closeDeleteModal = useCallback(() => {
    setIsDeleteModalOpen(false);
  }, []);

  // Whenever the scope changes, output all the valid sets.
  useEffect(() => {
    // we only output valid sets
    const validSet = filter(productSetScope, (setItem) => setItem.isValid);
    // map set data, we don't need the metadata in the output
    const outputValue = map(validSet, (setItem) => setItem.set);
    // minimum of products sets
    const minimumOfSets = isNumber(min) ? min : 0;

    if (productSetScope) {
      let scopeValid = false;

      if (validSet.length === productSetScope.length && validSet.length >= minimumOfSets) {
        scopeValid = true;
      }

      setIsScopeValid(scopeValid);
    }

    // only output if there is a scope set, and only if it the output differs from the initial input
    if (productSetScope && !isEqual(productSet, outputValue)) {
      productSetUpdated(outputValue);
    }
  }, [productSet, productSetScope, productSetUpdated, productSetValid, min, setIsScopeValid]);

  useEffect(() => {
    if (productSetValid && isScopeValid !== undefined) {
      productSetValid(isScopeValid);
    }
  }, [isScopeValid, productSetValid]);

  return (
    <>
      <div className="flex flex-col gap-5 py-1 ">
        <div className="px-5 pb-1">
          <div className="flex items-center justify-between gap-5">
            <div className="flex items-center gap-2">
              <Text variant="heading-2">
                {title || (
                  <FormattedMessage id="generic.label.product-sets" defaultMessage="Product sets" />
                )}
              </Text>

              {!isScopeValid && productSetScope?.length === 0 && (
                <Badge variant="red">
                  <FormattedMessage
                    id="edit-discount.product-sets.header-warning-icon"
                    defaultMessage="At least {min} is required"
                    values={{ min }}
                  />
                </Badge>
              )}
            </div>

            {!passive ? (
              <div className="flex h-12 items-center gap-1">
                <EvaProductSetActions
                  onAddProductSearch={() =>
                    addNewSet(undefined, EEvaDiscountProductSetType.ProductSearch)
                  }
                  onAddProducts={() => addNewSet(undefined, EEvaDiscountProductSetType.ProductIDs)}
                  onAddCustomProductSet={() =>
                    addNewSet(undefined, EEvaDiscountProductSetType.ProductSet)
                  }
                  onAddPersonalizedPromotion={() =>
                    addNewSet(undefined, EEvaDiscountProductSetType.PersonalizedPromotion)
                  }
                  disabled={
                    Number(max) && isArray(productSetScope) ? max! <= productSetScope.length : false
                  }
                  onAddProductRequirement={
                    productRequirementsOption
                      ? () => addNewSet(undefined, EEvaDiscountProductSetType.ProductRequirement)
                      : undefined
                  }
                />
              </div>
            ) : null}
          </div>
        </div>

        <div className="px-5">
          {productSetScope &&
            productSetScope.map((setItem, index) => (
              <div
                key={setItem.key}
                ref={getScrollToLast(index, productSetScope.length)}
                style={{ marginBottom: "20px" }}
              >
                <Card variant="secondary">
                  <CardHeader
                    title={
                      <div className="flex items-center gap-2">
                        <Text variant="heading-2">
                          {setItem.type === EEvaDiscountProductSetType.ProductSearch ? (
                            <FormattedMessage
                              id="generic.label.product-search"
                              defaultMessage="Product search"
                            />
                          ) : setItem.type === EEvaDiscountProductSetType.ProductIDs ? (
                            <FormattedMessage
                              id="generic.label.products"
                              defaultMessage="Products"
                            />
                          ) : setItem.type === EEvaDiscountProductSetType.ProductSet ? (
                            <FormattedMessage
                              id="generic.label.product-set"
                              defaultMessage="Product set"
                            />
                          ) : setItem.type === EEvaDiscountProductSetType.ProductRequirement ? (
                            <FormattedMessage
                              id="generic.label.product-requirement"
                              defaultMessage="Product requirement"
                            />
                          ) : (
                            <FormattedMessage
                              id="generic.label.personalized-promotion"
                              defaultMessage="Personalized promotion"
                            />
                          )}
                        </Text>
                        {!setItem.isValid && <SvgIcon name="warning" className="text-error" />}
                      </div>
                    }
                    actions={
                      !passive ? (
                        <CardActions>
                          <DeleteActionButton onPress={() => openDeleteModal(setItem)} />
                        </CardActions>
                      ) : null
                    }
                  />

                  <EvaProductSetForm
                    setItem={setItem}
                    setIsEdited={setEdited}
                    shouldFocusInput={shouldFocusInput}
                    updateSet={updateSet}
                    amountOfProductsOption={amountOfProductsOption}
                    defaultProductOption={defaultProductOption}
                    isDiscountableProductSetOption={isDiscountableProductSetOption}
                    requiredAmountOption={requiredAmountOption}
                    requiredAmountSpentOption={requiredAmountSpentOption}
                    requiredProductSetName={requiredProductSetName}
                  />
                </Card>
              </div>
            ))}
        </div>
      </div>

      <DeleteModal
        isOpen={isDeleteModalOpen}
        onConfirm={() => {
          if (productSetToDelete) {
            deleteSet(productSetToDelete?.key);
          }

          closeDeleteModal();
        }}
        onCancel={closeDeleteModal}
      />
    </>
  );
};

export default memo(EvaProductSet);
