import { useState } from "react";

import { EntityTranslationFieldProvider } from "./entity-translation-field-context";
import EntityTranslationFieldModal from "./entity-translation-field-modal";
import { EntityTranslationIcon } from "./entity-translation-icon";
import { EntityTranslationFieldProps } from "./types";

import Grid from "~/components/suite-ui/grid";
import Input from "~/components/suite-ui/input";

const EntityTranslationField = ({
  centerVertically,
  customTranslationButton,
  disableEntityTranslations,
  entity,
  hideInput,
  smallIconButton,
  translations,
  ...inputProps
}: EntityTranslationFieldProps) => {
  const [openModal, setOpenModal] = useState(false);

  if (entity) {
    return (
      <EntityTranslationFieldProvider
        entityField={entity.entityField}
        entityID={entity.entityID}
        entityType={entity.entityType}
        translations={translations}
      >
        {!hideInput ? (
          <Grid container alignItems={centerVertically ? "center" : "flex-end"} spacing={1}>
            <Grid item className="flex-grow">
              <Input {...inputProps} />
            </Grid>
            <EntityTranslationIcon
              translations={translations}
              setOpenModal={setOpenModal}
              customTranslationButton={customTranslationButton}
              disableEntityTranslations={disableEntityTranslations}
              small={smallIconButton}
            />
          </Grid>
        ) : (
          <EntityTranslationIcon
            translations={translations}
            setOpenModal={setOpenModal}
            customTranslationButton={customTranslationButton}
            disableEntityTranslations={disableEntityTranslations}
            small={smallIconButton}
          />
        )}
        <EntityTranslationFieldModal open={openModal} setOpen={setOpenModal} />
      </EntityTranslationFieldProvider>
    );
  }
  return null;
};

export default EntityTranslationField;
