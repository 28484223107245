import { getFunctionalityRequirements } from "./get-functionality-requirements";

import { Functionalities, FunctionalityScope } from "~/types/functionalities";
import { queryClient } from "~/util/query-client";

export const authorizeMultipleFunctionalityRequirements = async (
  collectorLogic: "AND" | "OR",
  ...funcitonalityRequirements: {
    functionality: Functionalities;
    scope?: FunctionalityScope;
    ouId?: number;
  }[]
) => {
  const requirements = await Promise.all(
    funcitonalityRequirements.map(async (functionalityRequirement) => {
      const requirements = await getFunctionalityRequirements(
        queryClient,
        functionalityRequirement.functionality,
        functionalityRequirement.ouId,
      );
      return { data: requirements, ...functionalityRequirement };
    }),
  );

  function getValue() {
    const mappedRequirements = requirements.map((requirement) => {
      switch (requirement.scope) {
        case FunctionalityScope.View:
          return requirement.data.hasViewRights;
        case FunctionalityScope.Edit:
          return requirement.data.hasEditRights;
        case FunctionalityScope.Create:
          return requirement.data.hasCreateRights;
        case FunctionalityScope.Delete:
          return requirement.data.hasDeleteRights;
        case FunctionalityScope.Manage:
          return requirement.data.hasManageRights;
        case FunctionalityScope.Scripting:
          return requirement.data.hasScriptingRights;
        case FunctionalityScope.Settings:
          return requirement.data.hasSettingsRights;
        default:
          return requirement.data.hasUnscopedRights;
      }
    });
    if (collectorLogic === "AND") {
      return mappedRequirements.every((requirement) => requirement === true);
    }
    return mappedRequirements.some((requirement) => requirement === true);
  }

  if (getValue() === false) {
    throw new Response("Forbidden", { status: 403 });
  }
};
