import { useMemo } from "react";

import { listCompaniesQuery, useGetCompanyQuery, useListCompaniesQuery } from "~/models/companies";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const CompanySearchListField = SearchListFieldGenerator({
  getItemFromResponse: (resp) => resp?.Results?.map((item) => ({ ID: item.ID, Name: item.Name })),
  getItemId: (item) => item.ID,
  getLabel: (item) => item.Name,
  defaultLabel: intlAccessor.formatMessage({
    defaultMessage: "Company",
    id: "generic.label.company",
  }),
  useItemByID: (id) => {
    const { data, isFetching } = useGetCompanyQuery(id ? { ID: id } : undefined, {});
    const item = useMemo(() => {
      if (!id || !data) {
        return undefined;
      }
      return { ID: data.ID ?? id, Name: data.Name ?? "" };
    }, [data, id]);
    return { data: item, isLoading: isFetching };
  },
  useItemsByID: (ids) => {
    const { data, isFetching } = useListCompaniesQuery(
      ids && ids.length
        ? {
            InitialPageConfig: { Limit: ids.length, Filter: { IDs: ids } },
          }
        : undefined,
      {},
    );
    const items = useMemo(() => {
      if (!ids || !ids.length || !data) {
        return undefined;
      }
      return ids
        .map((id) => {
          const item = data.Results.find((company) => company.ID === id);
          return item ? { ID: item.ID ?? id, Name: item.Name ?? "" } : undefined;
        })
        .filter((item): item is { ID: number; Name: string } => item !== undefined);
    }, [data, ids]);
    return { data: items, isLoading: isFetching };
  },
  useServiceQuery: () =>
    SearchListFieldGenerator.useSearchListFieldService({
      query: listCompaniesQuery,
      initialRequest: {
        InitialPageConfig: { Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT, Filter: { Name: undefined } },
      },
      getQueryRequest: (req) => req?.InitialPageConfig?.Filter?.Name,
      setQueryRequest: (req, newValue) => ({
        ...req,
        InitialPageConfig: {
          ...req?.InitialPageConfig,
          Filter: { ...req?.InitialPageConfig?.Filter, Name: newValue },
        },
      }),
      configureLoadMoreButton: (resp) => ({
        shouldShowLoadMoreButton: resp?.NextPageToken !== undefined,
        onLoadMore: (req) => ({
          ...req,
          InitialPageConfig: {
            ...req?.InitialPageConfig,
            Limit:
              (req?.InitialPageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
              DEFAULT_SEARCH_LIST_FIELD_LIMIT,
          },
        }),
      }),
    }),
});
