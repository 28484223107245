import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Select } from "@new-black/lyra";

import {
  CUSTOM_FIELD_OPERATORS_DEFAULT_MESSAGE_MAP,
  CustomFieldValueOperator,
} from "~/types/custom-field";
import { useCustomFieldOperators } from "~/util/custom-fields";

export const CustomFieldOperatorSelect = ({
  customFieldDataTypeID,
  errorMessage,
  isDisabled,
  isRequired,
  name,
  setValue,
  value,
}: {
  isRequired?: boolean;
  customFieldDataTypeID?: number;
  value?: CustomFieldValueOperator;
  setValue: (newValue?: CustomFieldValueOperator) => void;
  isDisabled?: boolean;
  name: string;
  errorMessage?: string;
}) => {
  const intl = useIntl();

  const customFieldOperators = useCustomFieldOperators(customFieldDataTypeID);

  const items = useMemo(
    () =>
      customFieldOperators.map((operator) => ({
        value: operator,
        label: intl.formatMessage({
          id: `generic.operator.${
            CustomFieldValueOperator[operator] as keyof typeof CustomFieldValueOperator
          }`,
          defaultMessage: CUSTOM_FIELD_OPERATORS_DEFAULT_MESSAGE_MAP[operator],
        }),
      })),
    [customFieldOperators, intl],
  );

  const selectedValue = useMemo(() => items.find((item) => item.value === value), [items, value]);

  return (
    <>
      <Select
        getItemId={(item) => item.value}
        getLabel={(item) => item.label}
        selectRenderElements={(item) => ({ label: item.label })}
        isRequired={isRequired}
        label={intl.formatMessage({
          id: "generic.label.operator",
          defaultMessage: "Operator",
        })}
        items={items}
        value={selectedValue}
        isDisabled={isDisabled}
        onChange={(newValue) => setValue(newValue?.value)}
        errorMessage={errorMessage}
        description={
          value === undefined
            ? intl.formatMessage({
                id: "custom-fields.operator.select-custom-field.helper-text",
                defaultMessage: "Select a custom field above to show its corresponding operators.",
              })
            : undefined
        }
      />
      {value !== undefined && name ? <input hidden readOnly name={name} value={value} /> : null}
    </>
  );
};
