import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Table, TableColumnDef, TableProps } from "@new-black/lyra";

import DeleteActionButton from "~/components/shared/action-buttons/delete-action-button";

type Item = EVA.Core.Management.ListLoyaltyProgramRequiredCustomFieldsResponse.CustomField;

type TPickedRequiredTableProps =
  | "data"
  | "onNext"
  | "showNext"
  | "onPrevious"
  | "showPrevious"
  | "limit"
  | "setLimit";

type TRequiredLoyaltyProgramCustomFieldsTableProps = Required<
  Pick<TableProps<Item>, TPickedRequiredTableProps>
>;

export const LoyaltyProgramCustomFieldsTable = ({
  data,
  limit,
  onDelete,
  onNext,
  onPrevious,
  setLimit,
  showNext,
  showPrevious,
}: TRequiredLoyaltyProgramCustomFieldsTableProps & { onDelete?: (id: number) => void }) => {
  const intl = useIntl();

  const columns = useMemo(() => {
    const result: TableColumnDef<Item>[] = [
      {
        accessorKey: "ID",
        id: "ID",
        header: intl.formatMessage({ id: "generic.label.id", defaultMessage: "ID" }),
      },

      {
        accessorKey: "Name",
        id: "Name",
        header: intl.formatMessage({ id: "generic.label.name", defaultMessage: "Name" }),
      },

      {
        accessorKey: "BackendID",
        id: "BackendID",
        header: intl.formatMessage({
          id: "generic.label.backend-id",
          defaultMessage: "Backend ID",
        }),
      },
    ];

    if (onDelete) {
      result.push({
        id: "actions",
        cellType: "button",
        align: "end",
        header: "",
        cell: ({ row }) => <DeleteActionButton onPress={() => onDelete(row.original.ID)} />,
      });
    }

    return result;
  }, [intl, onDelete]);

  return (
    <Table
      data={data}
      onNext={onNext}
      showNext={showNext}
      onPrevious={onPrevious}
      showPrevious={showPrevious}
      limit={limit}
      setLimit={setLimit}
      aria-label={intl.formatMessage({
        id: "generic.label.custom-fields",
        defaultMessage: "Custom fields",
      })}
      columns={columns}
    />
  );
};
