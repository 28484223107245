import { FormattedMessage, useIntl } from "react-intl";

import { Alert, FieldGroup, NumberField, Text, TextField } from "@new-black/lyra";

import {
  LoyaltyProgramProductLimitationForm,
  LoyaltyProgramProductLimitationFormErrors,
  loyaltyProgramProductLimitationFormKeys,
} from "./types";

import { NUMBER_FORMAT_OPTIONS } from "~/util/number-format-options";

export const LoyaltyProgramProductLimitationFormFields = ({
  errors,
  initialValues,
}: {
  initialValues?: LoyaltyProgramProductLimitationForm;
  errors?: LoyaltyProgramProductLimitationFormErrors;
}) => {
  const intl = useIntl();

  return (
    <FieldGroup>
      <TextField
        isRequired
        name={loyaltyProgramProductLimitationFormKeys.Name}
        defaultValue={initialValues?.Name}
        errorMessage={errors?.Name}
        label={intl.formatMessage({ id: "generic.label.name", defaultMessage: "Name" })}
      />

      <TextField
        name={loyaltyProgramProductLimitationFormKeys.BackendID}
        defaultValue={initialValues?.BackendID}
        errorMessage={errors?.BackendID}
        label={intl.formatMessage({ id: "generic.label.backend-id", defaultMessage: "Backend ID" })}
      />

      <NumberField
        name={loyaltyProgramProductLimitationFormKeys.QuantityLimitPerUser}
        defaultValue={initialValues?.QuantityLimitPerUser}
        errorMessage={errors?.QuantityLimitPerUser}
        label={intl.formatMessage({
          id: "generic.label.quantity-limit-per-user",
          defaultMessage: "Quantity limit per user",
        })}
        minValue={1}
        formatOptions={NUMBER_FORMAT_OPTIONS.integer}
      />

      <NumberField
        name={loyaltyProgramProductLimitationFormKeys.OverallQuantityLimitPerUser}
        defaultValue={initialValues?.OverallQuantityLimitPerUser}
        errorMessage={errors?.OverallQuantityLimitPerUser}
        label={intl.formatMessage({
          id: "generic.label.overall-quantity-limit-per-user",
          defaultMessage: "Overall quantity limit per user",
        })}
        minValue={1}
        formatOptions={NUMBER_FORMAT_OPTIONS.integer}
      />

      <NumberField
        name={loyaltyProgramProductLimitationFormKeys.QuantityLimitPerOrder}
        defaultValue={initialValues?.QuantityLimitPerOrder}
        errorMessage={errors?.QuantityLimitPerOrder}
        label={intl.formatMessage({
          id: "generic.label.quantity-limit-per-order",
          defaultMessage: "Overall quantity limit per order",
        })}
        minValue={1}
        formatOptions={NUMBER_FORMAT_OPTIONS.integer}
      />

      <NumberField
        name={loyaltyProgramProductLimitationFormKeys.OverallQuantityLimitPerOrder}
        defaultValue={initialValues?.OverallQuantityLimitPerOrder}
        errorMessage={errors?.OverallQuantityLimitPerOrder}
        label={intl.formatMessage({
          id: "generic.label.overall-quantity-limit-per-order",
          defaultMessage: "Quantity limit per order",
        })}
        minValue={1}
        formatOptions={NUMBER_FORMAT_OPTIONS.integer}
      />

      <Alert variant="info">
        <Text variant="body-medium">
          <FormattedMessage
            id="loyalty-programs.product-limitations.add.apply-to-price-list-description"
            defaultMessage="This product limitation will apply to price list by default. You can change that via the product / product filter selection modals."
          />
        </Text>
      </Alert>
    </FieldGroup>
  );
};
