import { useCallback } from "react";
import { useIntl } from "react-intl";

import { Core } from "@springtree/eva-services-core";

import { toast } from "~/components/suite-ui/toast";
import useCallService from "~/hooks/suite-react-hooks/use-call-service";

const usePrintTasks = (deselectRows: () => void, refetchTasks: () => void) => {
  const intl = useIntl();
  const { callService: print } = useCallService({
    disableNotifications: true,
    service: Core.PrintShipFromStoreTasks,
    options: {
      onSuccess: (data) => {
        if (data) {
          // deselect the selected rows
          //
          deselectRows();

          refetchTasks();

          // open new window with the generated document
          //
          window.open(data.Url, "_blank");

          // Notify the user of the succesful generated document.
          //
          toast.success(
            intl.formatMessage({
              id: "ship-from-store.overview.pick-tasks.print-selected-tasks.succes",
              defaultMessage: "Succesfully generated picklist",
            }),
          );
        } else {
          console.error("no response");
          // Notify the user that something went wrong
          //
          toast.error(
            intl.formatMessage({
              id: "ship-from-store.overview.pick-tasks.print-selected-tasks.error",
              defaultMessage: "Something went wrong while generating picklist",
            }),
          );
        }
      },
      onError: (error) => {
        console.error(error);
        toast.error(
          intl.formatMessage({
            id: "ship-from-store.overview.pick-tasks.print-selected-tasks.error",
            defaultMessage: "Something went wrong while generating picklist",
          }),
        );
      },
    },
  });

  const printTasks = useCallback(
    (userTaskIds?: number[]) => {
      if (userTaskIds?.length) {
        print({
          UserTaskIDs: userTaskIds,
        });
      }
    },
    [print],
  );

  return printTasks;
};

export default usePrintTasks;
