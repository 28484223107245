import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import classNames from "clsx";

import {
  useGenerateLyraOUSetSearchListField,
  useGenerateOUSetSearchListField,
} from "../../organization-unit-set-search-list-field";
import CreateOUSetModal from "../common/create-ou-set-modal";
import ViewSubsetsButton, { ViewSubsetsLyraButton } from "../common/view-subsets-button";
import { IOuAndSetSelectorFormikProps, IOuAndSetSelectorOptions } from "../types";

import { IOUSetMultiSelect, IOUSetSelectorCommonProps, IOUSetSingleSelect } from "./types";

import AddActionButton from "~/components/shared/action-buttons/add-action-button";

interface IMultiOUSetSelectorBaseProps extends IOuAndSetSelectorFormikProps {
  options?: IOuAndSetSelectorOptions;
}

interface IMultiOUSetSingleSelectorProps extends IMultiOUSetSelectorBaseProps, IOUSetSingleSelect {}

interface IMultiOUSetMultiSelectorProps extends IMultiOUSetSelectorBaseProps, IOUSetMultiSelect {}

const MultiOUSetSelector = ({
  disableSearchListFieldPopoverPortal,
  error,
  filters,
  helperText,
  hideDefaultHelperText,
  label,
  multi,
  options,
  passive,
  required,
  selectedOUSetID,
  selectedOUSetIDs,
  setSelectedOUSetID,
  setSelectedOUSetIDs,
}: IOUSetSelectorCommonProps &
  (IMultiOUSetSingleSelectorProps | IMultiOUSetMultiSelectorProps)) => {
  const intl = useIntl();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const handleCreated = useCallback(
    (newId: number | undefined) => {
      if (newId !== undefined) {
        if (multi) {
          setSelectedOUSetIDs?.([...(selectedOUSetIDs ?? []), newId]);
        } else {
          setSelectedOUSetID?.(newId);
        }
        setIsCreateModalOpen(false);
      }
    },
    [multi, selectedOUSetIDs, setSelectedOUSetID, setSelectedOUSetIDs],
  );

  const searchListFieldGeneratorProps = useMemo(
    () => ({
      filters: { ...filters, HasSubsets: true, Types: [0, 1] },
      endAdornment: <ViewSubsetsButton selectedOuSetId={selectedOUSetID} />,
    }),
    [filters, selectedOUSetID],
  );

  const {
    GeneratedMultiOUSetIDSearchListFieldWithFilters: MultiOuSetSearchListField,
    GeneratedSingleOUSetIDSearchListFieldWithFilters: SingleOuSetSearchListField,
  } = useGenerateOUSetSearchListField(searchListFieldGeneratorProps);

  const helperTextWithDefault = useMemo(
    () =>
      helperText ??
      (hideDefaultHelperText
        ? undefined
        : intl.formatMessage({
            id: "ouset-selector.multi.autocomplete.helper-text",
            defaultMessage: "This change will affect all organization units included in this set",
          })),
    [helperText, hideDefaultHelperText, intl],
  );

  return (
    <>
      <div className="flex items-end">
        <div
          className={classNames(
            "flex-1",
            options?.canCreateOUSet && !passive ? "max-w-[calc(100%-3rem)]" : "max-w-full",
          )}
        >
          {multi ? (
            <MultiOuSetSearchListField.Controlled
              values={selectedOUSetIDs}
              onChange={(newValue) => {
                setSelectedOUSetIDs(newValue?.filter((id): id is number => id !== undefined));
              }}
              label={
                label ??
                intl.formatMessage({
                  id: passive
                    ? "ouset-selector.multi.autocomplete.label.passive"
                    : "ouset-selector.multi.autocomplete.label",
                  defaultMessage: passive
                    ? "Set of organization units"
                    : "Select set of organization units",
                })
              }
              helperText={
                error && helperText === helperTextWithDefault ? undefined : helperTextWithDefault
              }
              error={error}
              required={required}
              disabled={passive}
              disablePopoverPortal={disableSearchListFieldPopoverPortal}
            />
          ) : (
            <SingleOuSetSearchListField.Controlled
              value={selectedOUSetID}
              onChange={setSelectedOUSetID}
              label={
                label ??
                intl.formatMessage({
                  id: passive
                    ? "ouset-selector.multi.autocomplete.label.passive"
                    : "ouset-selector.multi.autocomplete.label",
                  defaultMessage: passive
                    ? "Set of organization units"
                    : "Select set of organization units",
                })
              }
              helperText={
                error && helperText === helperTextWithDefault ? undefined : helperTextWithDefault
              }
              error={error}
              required={required}
              disabled={passive}
              disablePopoverPortal={disableSearchListFieldPopoverPortal}
            />
          )}
        </div>
        {options?.canCreateOUSet && !passive ? (
          <AddActionButton
            className="ml-3 self-center"
            onPress={() => setIsCreateModalOpen(true)}
            tooltip={intl.formatMessage({
              id: "ouset-selector.multi.create.tooltip",
              defaultMessage: "Create organization unit set",
            })}
          />
        ) : null}
      </div>
      <CreateOUSetModal
        onCreated={handleCreated}
        isOpen={isCreateModalOpen}
        setIsOpen={setIsCreateModalOpen}
      />
    </>
  );
};

interface IMultiLyraOUSetSingleSelectorProps
  extends IOuAndSetSelectorFormikProps,
    Omit<IOUSetSingleSelect, "options"> {}

interface IMultiLyraOUSetMultiSelectorProps
  extends IOuAndSetSelectorFormikProps,
    Omit<IOUSetMultiSelect, "options"> {}

// Create lyra version and do the JSX here.
// So Preview and Create button aligned next to the field
export const MultiLyraOUSetSelector = ({
  disableClearLogic,
  disabledItemIdKeys,
  error,
  filters,
  helperText,
  hideDefaultHelperText,
  hideHintLabel,
  label,
  multi,
  passive,
  required,
  selectedOUSetID,
  selectedOUSetIDs,
  setSelectedOUSetID,
  setSelectedOUSetIDs,
}: IOUSetSelectorCommonProps & {
  hideHintLabel?: boolean;
  disabledItemIdKeys?: string[];
  disableClearLogic?: boolean;
} & (IMultiLyraOUSetSingleSelectorProps | IMultiLyraOUSetMultiSelectorProps)) => {
  const intl = useIntl();

  const Components = useGenerateLyraOUSetSearchListField({
    filters: { ...filters, HasSubsets: true, Types: [0, 1] },
  });

  const helperTextWithDefault = useMemo(
    () =>
      helperText ??
      (hideDefaultHelperText
        ? undefined
        : intl.formatMessage({
            id: "ouset-selector.multi.autocomplete.helper-text",
            defaultMessage: "This change will affect all organization units included in this set",
          })),
    [helperText, hideDefaultHelperText, intl],
  );

  return (
    <div className="max-w-full flex-1">
      {multi ? (
        <Components.MultiIDSearchListField.Controlled
          values={selectedOUSetIDs}
          onChange={(newValue) => {
            setSelectedOUSetIDs(newValue?.filter((id): id is number => id !== undefined));
          }}
          label={
            label ??
            intl.formatMessage({
              id: "ouset-selector.multi.autocomplete.label.passive",
              defaultMessage: "Set of organization units",
            })
          }
          description={error ? undefined : helperTextWithDefault}
          errorMessage={error ? helperTextWithDefault : undefined}
          isRequired={required}
          isDisabled={passive}
          hideHintLabel={hideHintLabel}
          endSlot={
            <div className="flex gap-2">
              <ViewSubsetsLyraButton selectedOuSetId={selectedOUSetID} />
            </div>
          }
          disabledItemIdKeys={disabledItemIdKeys}
          disableClearLogic={disableClearLogic}
        />
      ) : (
        <Components.SingleIDSearchListField.Controlled
          value={selectedOUSetID}
          onChange={setSelectedOUSetID}
          label={
            label ??
            intl.formatMessage({
              id: "ouset-selector.multi.autocomplete.label.passive",
              defaultMessage: "Set of organization units",
            })
          }
          description={error ? undefined : helperTextWithDefault}
          errorMessage={error ? helperTextWithDefault : undefined}
          isRequired={required}
          isDisabled={passive}
          hideHintLabel={hideHintLabel}
          endSlot={<ViewSubsetsLyraButton selectedOuSetId={selectedOUSetID} />}
          disabledItemIdKeys={disabledItemIdKeys}
          disableClearLogic={disableClearLogic}
        />
      )}
    </div>
  );
};

export default MultiOUSetSelector;
