import { ListItemText, ListItemTextProps } from "@material-ui/core";
import classNames from "clsx";

export const StyledListItemText = ({ className, ...props }: ListItemTextProps) => (
  <ListItemText
    {...props}
    className={classNames(
      "m-0 [&_span]:overflow-hidden [&_span]:text-ellipsis [&_span]:whitespace-nowrap",
      "[&_span]:text-legacy-sm [&_span]:text-center [&_span]:font-medium [&_span]:text-[color:var(--legacy-eva-color-dark-3)]",
      className,
    )}
  />
);
