import { AuthenticationOpenID } from "@springtree/eva-services-authentication-openid";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getAvailableOpenIDConfigurationsLoaderQuery,
  serviceQuery: getAvailableOpenIDConfigurationsQuery,
  serviceQueryKeys: getAvailableOpenIDConfigurationsQueryKeys,
  useServiceQueryHook: useGetAvailableOpenIDConfigurationsQuery,
} = createServiceQuery(AuthenticationOpenID.GetAvailableOpenIDConfigurations, true, false, false, {
  authenticationToken: undefined,
});
