import { useCallback, useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { useRecoilState } from "recoil";

import { OrganizationUnitSummariesService } from "./organization-unit-summaries.state";

import useRecoilDebouncedValue from "~/hooks/suite-react-hooks/use-recoil-debounced-value";
import { useServiceError } from "~/hooks/suite-react-hooks/use-service-error";
import useSetRequest from "~/hooks/suite-react-hooks/use-set-request";

export { OrganizationUnitSummariesService } from "./organization-unit-summaries.state";

interface UseOrganizationUnitSummariesProps {
  /**
   * The payload that will be used for `ListOrganizationUnitSummaries`.
   * @default
   * ```json
   * {
   *    "PageConfig": {
   *      "Start": 0,
   *      "Limit": 10,
   *      "Filter": { "Name": undefined },
   *    },
   * };
   * ```
   */
  defaultRequest?: EVA.Core.ListOrganizationUnitSummaries;
  familyKey: string;
  /**
   * Use this only if you want to ignore the implicit default request. This can be useful
   * if you want to stop making requests at some point (by setting it to `true` and passing
   * `undefined` to `defaultRequest`)
   */
  ignoreImplicitRequest?: boolean;
}

export const useOrganizationUnitSummaries = ({
  defaultRequest,
  familyKey,
  ignoreImplicitRequest,
}: UseOrganizationUnitSummariesProps) => {
  const [nameFilter, setNameFilter] = useRecoilDebouncedValue(
    OrganizationUnitSummariesService.nameFilter(familyKey),
  );

  const organizationUnits = hooks.useGetState(OrganizationUnitSummariesService.result(familyKey));

  const error = useServiceError({
    parameter: familyKey,
    serviceFamilyState: OrganizationUnitSummariesService.serviceState,
  });

  const totalFromResponse = hooks.useGetState(OrganizationUnitSummariesService.total(familyKey));
  const total = useMemo(() => totalFromResponse ?? 0, [totalFromResponse]);

  const [skip, setSkip] = useRecoilState(OrganizationUnitSummariesService.start(familyKey));
  const [limit, setLimit] = useRecoilState(OrganizationUnitSummariesService.limit(familyKey));

  const reset = useCallback(() => {
    setNameFilter(undefined);
    setSkip(
      defaultRequest?.PageConfig?.Start ??
        OrganizationUnitSummariesService.defaultRequest.PageConfig.Start,
    );
    setLimit(
      defaultRequest?.PageConfig?.Limit ??
        OrganizationUnitSummariesService.defaultRequest.PageConfig.Limit,
    );
  }, [
    setSkip,
    setLimit,
    setNameFilter,
    defaultRequest?.PageConfig?.Start,
    defaultRequest?.PageConfig?.Limit,
  ]);

  const currentRequest = hooks.useGetState(
    OrganizationUnitSummariesService.serviceState.request(familyKey),
  );

  const request = useMemo(() => {
    // Prevent resetting the request when changing the name filter
    if (
      currentRequest?.PageConfig?.Filter?.Name ||
      currentRequest?.PageConfig?.Filter?.Name === ""
    ) {
      return undefined;
    }

    if (!defaultRequest && !ignoreImplicitRequest) {
      return OrganizationUnitSummariesService.defaultRequest;
    }

    return defaultRequest;
  }, [currentRequest, defaultRequest, ignoreImplicitRequest]);

  const isOrganizationUnitsLoading = hooks.useIsLoading({
    state: OrganizationUnitSummariesService.serviceState.response(familyKey),
  });

  const isOrganizationUnitsLoadingWithoutResponse = useMemo(
    () => !organizationUnits && isOrganizationUnitsLoading,
    [organizationUnits, isOrganizationUnitsLoading],
  );

  useSetRequest(OrganizationUnitSummariesService.serviceState.request(familyKey), request);

  return {
    pagination: {
      skip,
      limit,
      total,
      setSkip,
      setLimit,
    },
    filter: {
      nameFilter,
      setNameFilter,
    },
    error,
    reset,
    organizationUnits,
    isOrganizationUnitsLoading,
    isOrganizationUnitsLoadingWithoutResponse,
  };
};

useOrganizationUnitSummaries.service = OrganizationUnitSummariesService;
