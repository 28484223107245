import toast from "react-hot-toast";
import { useIntl } from "react-intl";

import { useQuery } from "@tanstack/react-query";

import { getOrganizationUnitSetDetailsQuery } from "~/models/organization-unit-sets";
import { getOuSetSubsetsCounts } from "~/util/get-ou-set-subsets-counts";

export const useDisplayErrorToastOnExceededSubsetsCount = (
  subsetsLimit: number,
  ouSetID?: number,
) => {
  const intl = useIntl();

  const { data: organizationUnitsWithinSet } = useQuery({
    ...getOrganizationUnitSetDetailsQuery(
      ouSetID ? { ID: ouSetID } : undefined,
      ouSetID ? [ouSetID] : undefined,
    ),
    enabled: ouSetID !== undefined,
    select: (response) => response?.Set.Subsets,
    onSuccess: (data) => {
      const allSubsetsCount = getOuSetSubsetsCounts(data);

      if (allSubsetsCount > subsetsLimit) {
        toast.error(
          intl.formatMessage({
            id: "generic.label.limit-selection-to-100-organization-units",
            defaultMessage: "Please limit your selection to 100 organization units.",
          }),
        );
      }
    },
  });

  return {
    subsetsCount: getOuSetSubsetsCounts(organizationUnitsWithinSet),
    subsets: organizationUnitsWithinSet,
  };
};
