import { isEmpty, omit } from "lodash";

import { FilterModel, FilterModelErrors, FilterModelOptions } from "./product-filter-modal.types";

import { emptyStringToUndefined } from "~/util/helper";
import { intlAccessor } from "~/util/intl-accessor";

export type ProductFilterState = FilterModel & {
  errors?: FilterModelErrors;
};

export type ProductFilterAction =
  | { type: "SET_TYPE"; payload: "values" | "range" }
  | { type: "SET_PROPERTY"; payload: string | undefined }
  | { type: "SET_VALUES"; payload: string[] | undefined }
  | { type: "SET_FROM"; payload: string | undefined }
  | { type: "SET_TO"; payload: string | undefined }
  | { type: "SET_OPTION"; payload: { field: keyof FilterModelOptions; checked: boolean } }
  | { type: "INIT"; payload?: FilterModel }
  | { type: "SET_ERRORS"; payload: FilterModelErrors };

export const initialProductFilterState: ProductFilterState = {
  type: "values",
  Values: [],
  Negation: false,
  ExactMatch: false,
  IncludeMissing: false,
  PropertyName: undefined,
  errors: undefined,
};

export function productFilterReducer(
  state: ProductFilterState,
  action: ProductFilterAction,
): ProductFilterState {
  switch (action.type) {
    case "SET_TYPE": {
      const commonFields = {
        Negation: state.Negation,
        ExactMatch: state.ExactMatch,
        PropertyName: state.PropertyName,
        IncludeMissing: state.IncludeMissing,
      };
      if (action.payload === "values") {
        return { type: "values", Values: state.Values || [], ...commonFields };
      }
      return { type: "range", From: state.From, To: state.To, ...commonFields };
    }
    case "SET_PROPERTY": {
      const errorMessage = !action.payload
        ? intlAccessor.formatMessage({
            id: "validation.required",
            defaultMessage: "This field is required",
          })
        : undefined;
      return {
        ...state,
        PropertyName: action.payload,
        errors: { ...omit(state.errors ?? {}, "PropertyName"), PropertyName: errorMessage },
      };
    }
    case "SET_VALUES": {
      if (state.type === "values") {
        const newVal = action.payload
          ?.map((val) => val?.replace(/[\u200B-\u200D\uFEFF]/g, "")?.trim()) // Remove zero-width characters and trim leading/trailing whitespace
          ?.filter((val) => !isEmpty(val)); // filter out empty strings
        const errorMessage =
          !newVal || !newVal.length
            ? intlAccessor.formatMessage({
                id: "validation.required",
                defaultMessage: "This field is required",
              })
            : undefined;
        return {
          ...state,
          Values: newVal,
          errors: { ...omit(state.errors ?? {}, "Values"), Values: errorMessage },
        };
      }
      return state;
    }
    case "SET_FROM":
    case "SET_TO":
      if (state.type === "range") {
        const field = action.type === "SET_FROM" ? "From" : "To";
        const errorMessage = !action.payload
          ? intlAccessor.formatMessage({
              id: "validation.required",
              defaultMessage: "This field is required",
            })
          : undefined;
        return {
          ...state,
          [field]: emptyStringToUndefined(action.payload),
          errors: { ...omit(state.errors ?? {}, field), [field]: errorMessage },
        };
      }
      return state;
    case "SET_OPTION":
      return { ...state, [action.payload.field]: action.payload.checked };
    case "INIT": {
      const baseFields = {
        Negation: action?.payload?.Negation || false,
        ExactMatch: action?.payload?.ExactMatch || false,
        PropertyName: action?.payload?.PropertyName || undefined,
        IncludeMissing: action?.payload?.IncludeMissing || false,
      };
      if (action?.payload?.type === "range") {
        return {
          type: "range",
          From: action?.payload?.From || "",
          To: action?.payload?.To || "",
          ...baseFields,
        };
      }
      return { type: "values", Values: action?.payload?.Values || [], ...baseFields };
    }
    case "SET_ERRORS":
      return { ...state, errors: action.payload };
    default:
      return state;
  }
}
