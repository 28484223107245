import { useMemo } from "react";

import { Badge } from "@new-black/lyra";

import { LoyaltyProgramGroupDeactivationReasons } from "~/types/eva-core";
import enumToArray from "~/util/enum-to-array";

export const LoyaltyProgramGroupDeactivationReasonsBadges = ({ value }: { value?: number }) => {
  const valuesArray = useMemo(() => enumToArray(LoyaltyProgramGroupDeactivationReasons), []);

  const deactivationReasons = useMemo(() => {
    if (!valuesArray?.length || value === undefined) return [];

    if (value === 0) {
      return [LoyaltyProgramGroupDeactivationReasons[value]];
    }

    return valuesArray
      ?.filter((entry) => entry.value !== 0 && (value & entry.value) === entry.value)
      .map((entry) => entry.key);
  }, [value, valuesArray]);

  return (
    <>
      {deactivationReasons.length
        ? deactivationReasons.map((entry, index) => (
            <Badge key={index} variant="transparent">
              {entry}
            </Badge>
          ))
        : null}
    </>
  );
};
