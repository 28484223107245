import { useCallback, useMemo } from "react";

import { useRootRouteData } from "~/routes/root";

const useAddressSettings = () => {
  const rootLoader = useRootRouteData();

  const settings = useMemo(
    () => rootLoader.applicationConfiguration?.value?.Configuration ?? {},
    [rootLoader.applicationConfiguration?.value?.Configuration],
  );

  const getSettingCountryList = useCallback(
    (settingName: string) => {
      if (!(settingName in settings)) {
        return [];
      }
      const settingValue = settings[settingName];
      if (settingValue === undefined || !Array.isArray(settingValue) || !settingValue.length) {
        return [];
      }
      return settingValue
        .filter((arrItem): arrItem is string => typeof arrItem === "string")
        .map((countryCode) => countryCode.trim());
    },
    [settings],
  );

  const houseNumberVisibleCountries = useMemo<string[]>(
    () => getSettingCountryList("Addresses:HouseNumberVisibleCountries"),
    [getSettingCountryList],
  );
  const houseNumberRequiredCountries = useMemo<string[]>(
    () => getSettingCountryList("Addresses:HouseNumberRequiredCountries"),
    [getSettingCountryList],
  );
  const address2VisibleCountries = useMemo<string[]>(
    () => getSettingCountryList("Addresses:Address2VisibleCountries"),
    [getSettingCountryList],
  );
  const address1OptionalCountries = useMemo<string[]>(
    () => getSettingCountryList("Addresses:Address1OptionalCountries"),
    [getSettingCountryList],
  );
  const zipCodeOptionalCountries = useMemo<string[]>(
    () => getSettingCountryList("Addresses:ZipCodeOptionalCountries"),
    [getSettingCountryList],
  );
  const cityOptionalCountries = useMemo<string[]>(
    () => getSettingCountryList("Addresses:CityOptionalCountries"),
    [getSettingCountryList],
  );
  const stateVisibleCountries = useMemo<string[]>(
    () => getSettingCountryList("Addresses:StateVisibleCountries"),
    [getSettingCountryList],
  );
  const stateLength2Countries = useMemo<string[]>(
    () => getSettingCountryList("Addresses:StateLength2Countries"),
    [getSettingCountryList],
  );
  const stateLength3Countries = useMemo<string[]>(
    () => getSettingCountryList("Addresses:StateLength3Countries"),
    [getSettingCountryList],
  );

  const getAddressSettings = useCallback(
    (countryId: string) => ({
      cityOptional: cityOptionalCountries.includes(countryId),
      stateVisible: stateVisibleCountries.includes(countryId),
      stateLength2: stateLength2Countries.includes(countryId),
      stateLength3: stateLength3Countries.includes(countryId),
      address2Visible: address2VisibleCountries.includes(countryId),
      zipCodeOptional: zipCodeOptionalCountries.includes(countryId),
      address1Optional: address1OptionalCountries.includes(countryId),
      houseNumberVisible: houseNumberVisibleCountries.includes(countryId),
      houseNumberRequired: houseNumberRequiredCountries.includes(countryId),
    }),
    [
      cityOptionalCountries,
      stateLength2Countries,
      stateLength3Countries,
      stateVisibleCountries,
      address2VisibleCountries,
      zipCodeOptionalCountries,
      address1OptionalCountries,
      houseNumberVisibleCountries,
      houseNumberRequiredCountries,
    ],
  );

  return getAddressSettings;
};

export default useAddressSettings;
