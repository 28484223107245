import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";
import { useQuery } from "@tanstack/react-query";

import {
  getProductSearchTemplateByIDQuery,
  listProductSearchTemplatesQuery,
} from "~/models/product-sets";
import { AutocompleteGenerator } from "~/util/autocomplete-generator";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";
import { SearchListFieldGenerator } from "~/util/search-list-field-generator";

export type TSearchProductTemplateAutocompleteItem = Omit<
  EVA.Core.Management.ListProductSearchTemplatesResponse.ProductSearchDto,
  "Type" | "IncludeLeafProducts"
>;

function useItemByID(
  ID: number | undefined,
  itemsFromList: TSearchProductTemplateAutocompleteItem[] | undefined,
) {
  const itemInList = useMemo(
    () => itemsFromList?.find((item) => item.ID === ID),
    [ID, itemsFromList],
  );

  const { data, isFetching: isLoading } = useQuery({
    ...getProductSearchTemplateByIDQuery(ID && typeof ID === "number" ? { ID } : undefined),
    refetchOnWindowFocus: false,
    enabled: !!ID && typeof ID === "number" && !itemInList,
  });

  return {
    isLoading,
    data: itemInList ?? (data && ID && typeof ID === "number" ? { ID, ...data } : undefined),
  };
}

export const generateProductSearchTemplateAutocomplete = (
  initialFilters?: EVA.Core.ProductSearchTemplateFilters,
) => {
  const {
    MultiAutocomplete: ProductSearchTemplateMultiAutocomplete,
    SingleAutocomplete: ProductSearchTemplateAutocomplete,
    SingleIDAutocomplete: ProductSearchTemplateSingleIDAutocomplete,
  } = AutocompleteGenerator<
    CoreManagement.ListProductSearchTemplates,
    TSearchProductTemplateAutocompleteItem,
    "ID"
  >({
    idKey: "ID",
    useItemByID,
    labelKey: "Name",
    getItemFromResponse: (resp) => resp?.Result?.Page,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.product-search-template",
      defaultMessage: "Product search template",
    }),
    useServiceQuery: () =>
      AutocompleteGenerator.useAutocompleteService({
        refetchOnFocus: false,
        query: listProductSearchTemplatesQuery,
        initialRequest: { PageConfig: { Limit: 10, Filter: initialFilters } },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,

            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
  });

  return {
    ProductSearchTemplateAutocomplete,
    ProductSearchTemplateSingleIDAutocomplete,
    ProductSearchTemplateMultiAutocomplete,
  };
};

export const generateProductSearchTemplateSearchListField = (
  initialFilters?: EVA.Core.ProductSearchTemplateFilters,
) =>
  SearchListFieldGenerator<
    CoreManagement.ListProductSearchTemplates,
    TSearchProductTemplateAutocompleteItem,
    "ID"
  >({
    idKey: "ID",
    useItemByID,
    labelKey: "Name",
    getItemFromResponse: (resp) => resp?.Result?.Page,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.product-search-template",
      defaultMessage: "Product search template",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listProductSearchTemplatesQuery,
        initialRequest: { PageConfig: { Limit: 10, Filter: initialFilters } },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,

            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
  });

export const generateProductSearchTemplateLyraSearchListField = (
  initialFilters?: EVA.Core.ProductSearchTemplateFilters,
) =>
  LyraSearchListFieldGenerator<
    CoreManagement.ListProductSearchTemplates,
    TSearchProductTemplateAutocompleteItem,
    number
  >({
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    selectRenderElements: (item) => ({ label: item.Name }),
    useItemByID,
    getItemFromResponse: (resp) => resp?.Result?.Page,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.product-search-template",
      defaultMessage: "Product search template",
    }),
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listProductSearchTemplatesQuery,
        initialRequest: { PageConfig: { Limit: 10, Filter: initialFilters } },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,

            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
  });
