import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getSupportedFunctionalitiesLoaderQuery,
  serviceQuery: getSupportedFunctionalitiesQuery,
  useServiceQueryHook: useGetSupportedFunctionalitiesQuery,
} = createServiceQuery(CoreManagement.GetSupportedFunctionalities);

export const { serviceQuery: getFunctionalityCategoriesQuery } = createServiceQuery(
  CoreManagement.GetFunctionalityCategories,
);
