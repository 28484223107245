import { useCallback } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";
import { useMutation } from "@tanstack/react-query";

import { mutate } from "~/util/mutate";

const mutateCreateIdentificationPinForEmployee = () =>
  mutate({
    service: CoreManagement.CreateIdentificationPinForEmployee,
  });

export const useCreatePINCode = (
  pinCode?: number,
  organizationUnitID?: number,
  onSuccess?: () => void,
) => {
  const { isLoading, mutateAsync } = useMutation({
    mutationFn: mutateCreateIdentificationPinForEmployee(),
  });

  const callback = useCallback(() => {
    if (pinCode && organizationUnitID) {
      mutateAsync({ Pin: pinCode?.toString(), OrganizationUnitID: organizationUnitID }).then(
        (result) => (result?.success ? onSuccess?.() : undefined),
      );
    }
  }, [mutateAsync, onSuccess, organizationUnitID, pinCode]);

  return { isLoading, callback };
};
