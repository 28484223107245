import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listAppSettingsLoaderQuery,
  serviceQueryKeys: listAppSettingsQueryKeys,
  useServiceQueryHook: useListAppSettingsQuery,
} = createServiceQuery(CoreManagement.ListAppSettings);

export const {
  serviceLoaderQuery: getAppSettingsAutocompleteInfoLoaderQuery,
  serviceQuery: getAppSettingsAutocompleteInfoServiceQuery,
  serviceQueryKeys: getAppSettingsAutocompleteInfoQueryKeys,
  useServiceQueryHook: useGetAppSettingsAutocompleteInfoQuery,
} = createServiceQuery(CoreManagement.GetAppSettingsAutocompleteInfo);

export const {
  serviceLoaderQuery: listAppSettingHistoryLoaderQuery,
  serviceQueryKeys: listAppSettingHistoryQueryKeys,
  useServiceQueryHook: useListAppSettingHistoryQuery,
} = createServiceQuery(CoreManagement.ListAppSettingHistory);

export const {
  serviceLoaderQuery: getAppSettingsForOrganizationUnitLoaderQuery,
  serviceQuery: getAppSettingsForOrganizationUnitServiceQuery,
  serviceQueryKeys: getAppSettingsForOrganizationUnitQueryKeys,
  useServiceQueryHook: useGetAppSettingsForOrganizationUnitQuery,
} = createServiceQuery(CoreManagement.GetAppSettingsForOrganizationUnit);
