import { helpers } from "@springtree/eva-sdk-react-recoil";
import { QueryClient } from "@tanstack/react-query";

import { getAuthorizationStructureLoaderQuery } from "~/models/auth";
import { Functionalities, FunctionalityScope } from "~/types/functionalities";

const { authorizationStructure } = helpers;

export const hasFunctionality = async (
  queryClient: QueryClient,
  functionality: Functionalities,
  scope: FunctionalityScope,
  ouId?: number,
) => {
  const authorizationStructureResponse = await getAuthorizationStructureLoaderQuery(
    queryClient,
    {},
    ["root"],
  )();
  const authorizationStructureValue = authorizationStructureResponse.value;

  if (!authorizationStructureValue) {
    return false;
  }

  return authorizationStructure.hasFunctionality({
    authorizationStructure: authorizationStructureValue,
    functionality,
    scope: scope as unknown as EVA.Core.FunctionalityScope,
    organizationUnitId: ouId,
  });
};

export const hasFunctionalityWithoutScope = async (
  queryClient: QueryClient,
  functionality: Functionalities,
  ouId?: number,
) => {
  const authorizationStructureResponse = await getAuthorizationStructureLoaderQuery(
    queryClient,
    {},
    ["root"],
  )();
  const authorizationStructureValue = authorizationStructureResponse.value;

  if (!authorizationStructureValue) {
    return false;
  }

  const matchingFunctionalities = authorizationStructureValue.Roles.filter((role) => {
    if (ouId && role.OrganizationUnitIDs.indexOf(ouId) === -1) {
      return false;
    }
    return Object.keys(role.ScopedFunctionalities).includes(functionality);
  });

  return matchingFunctionalities.length > 0;
};
