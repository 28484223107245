import { contextGenerator } from "~/util/context-generator";

export const {
  Provider: LatestSelectedOrganizationUnitProvider,
  useContext: useLatestSelectedOrganizationUnitContext,
} = contextGenerator<
  | {
      latestSelectedOrganizationUnitID?: number;
      setLatestSelectedOrganizationUnitID: (newValue?: number) => void;
    }
  | undefined
>(undefined);
