import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { Text } from "@new-black/lyra";
import classNames from "clsx";

import SingleMultiSwitch from "../common/single-multi-switch";
import {
  IOuAndSetSelectorFormikProps,
  IOuAndSetSelectorOptions,
  TSingleMultiOptionValue,
} from "../types";

import MultiOUSetSelector, { MultiLyraOUOrOUSetSelector } from "./multi-ou-set-selector";
import SingleOUSetSelector, { SingleLyraOUSetSelector } from "./single-ou-set-selector";
import { IOUMultiSelect, IOUSetMultiSelect, IOUSetSingleSelect, IOUSingleSelect } from "./types";

export type IOUOrOUSetSelectProps = {
  options?: IOuAndSetSelectorOptions;
  initialSwitchOption?: TSingleMultiOptionValue;
  ouSetsFilters?: Partial<EVA.Core.ListOrganizationUnitSetsFilter>;
  ouFilters?: Partial<EVA.Core.ListOrganizationUnitsFilter>;
  optionsLabels?: { single?: string; multi?: string };
  inputLabels?: { single?: string; multi?: string };
  singleMultiSwitchHelperText?: string;
  hideMultiDefaultHelperText?: boolean;
  disableSearchListFieldPopoverPortal?: boolean;
  headerLabel?: string;
  onSingleMultiSegmentedButtonSwitch?: (newValue: TSingleMultiOptionValue) => void;
  componentClassNames?: {
    switch?: {
      container?: string;
    };
  };
} & (IOUSetMultiSelect | IOUSetSingleSelect) &
  (IOUSingleSelect | IOUMultiSelect) &
  IOuAndSetSelectorFormikProps;

/**
 * Component that allows the user to select between an Organization Unit or an Organization Unit Set
 */
export const OUOrOUSetSelect = (props: IOUOrOUSetSelectProps) => {
  const {
    componentClassNames,
    helperText,
    hideMultiDefaultHelperText,
    initialSwitchOption,
    inputHelperTexts,
    inputLabels,
    onSingleMultiSegmentedButtonSwitch,
    optionsLabels,
    passive,
    setSelectedOUID,
    setSelectedOUIDs,
    setSelectedOUSetID,
    setSelectedOUSetIDs,
    singleMultiSwitchHelperText,
  } = props;

  const [singleMulti, setSingleMulti] = useState<TSingleMultiOptionValue>(
    initialSwitchOption ?? "single",
  );

  useEffect(() => {
    if (initialSwitchOption) {
      setSingleMulti(initialSwitchOption);
    }
  }, [initialSwitchOption, setSingleMulti]);

  return (
    <>
      {passive ? null : (
        <div className={componentClassNames?.switch?.container}>
          <SingleMultiSwitch
            activeOption={singleMulti}
            setActiveOption={(newValue) => {
              setSingleMulti(newValue);
              onSingleMultiSegmentedButtonSwitch?.(newValue);
            }}
            resetSelection={() => {
              setSelectedOUID?.(undefined);
              setSelectedOUIDs?.(undefined);
              setSelectedOUSetID?.(undefined);
              setSelectedOUSetIDs?.(undefined);
            }}
            optionsLabels={optionsLabels}
          />
        </div>
      )}
      {singleMultiSwitchHelperText ? (
        <div className="mb-5">
          <Text variant="body-small">{singleMultiSwitchHelperText}</Text>
        </div>
      ) : null}
      {singleMulti === "single" ? (
        <SingleOUSetSelector
          {...props}
          label={inputLabels?.single}
          helperText={inputHelperTexts?.single ?? helperText}
        />
      ) : (
        <MultiOUSetSelector
          {...props}
          hideDefaultHelperText={hideMultiDefaultHelperText}
          helperText={inputHelperTexts?.multi ?? helperText}
          label={inputLabels?.multi}
        />
      )}
    </>
  );
};

export const OUOrOUSetLyraSelect = (
  props: IOUOrOUSetSelectProps & { disableClearLogic?: boolean },
) => {
  const intl = useIntl();
  const {
    componentClassNames,
    headerLabel,
    helperText,
    hideMultiDefaultHelperText,
    initialSwitchOption,
    inputHelperTexts,
    inputLabels,
    onSingleMultiSegmentedButtonSwitch,
    optionsLabels,
    passive,
    setSelectedOUID,
    setSelectedOUIDs,
    setSelectedOUSetID,
    setSelectedOUSetIDs,
    singleMultiSwitchHelperText,
  } = props;

  const [singleMulti, setSingleMulti] = useState<TSingleMultiOptionValue>(
    initialSwitchOption ?? "single",
  );

  useEffect(() => {
    if (initialSwitchOption) {
      setSingleMulti(initialSwitchOption);
    }
  }, [initialSwitchOption, setSingleMulti]);

  return (
    <div className={classNames("flex flex-col gap-4", passive ? "" : "min-h-[123px]")}>
      {passive ? null : (
        <div className={componentClassNames?.switch?.container}>
          <SingleMultiSwitch
            activeOption={singleMulti}
            setActiveOption={(newValue) => {
              setSingleMulti(newValue);
              onSingleMultiSegmentedButtonSwitch?.(newValue);
            }}
            resetSelection={() => {
              setSelectedOUID?.(undefined);
              setSelectedOUIDs?.(undefined);
              setSelectedOUSetID?.(undefined);
              setSelectedOUSetIDs?.(undefined);
            }}
            optionsLabels={{
              multi:
                optionsLabels?.multi ??
                intl.formatMessage({ id: "generic.label.set", defaultMessage: "Set" }),
              single:
                optionsLabels?.single ??
                intl.formatMessage({ id: "generic.label.single", defaultMessage: "Single" }),
            }}
            hideHintLabel
            hideInputHeader={false}
            label={
              headerLabel ??
              intl.formatMessage({
                id: "generic.label.organization-unit",
                defaultMessage: "Organization unit",
              })
            }
          />
        </div>
      )}
      {singleMultiSwitchHelperText ? (
        <div className="mb-4">
          <Text variant="body-small">{singleMultiSwitchHelperText}</Text>
        </div>
      ) : null}
      {singleMulti === "single" ? (
        <SingleLyraOUSetSelector
          {...props}
          label={inputLabels?.single}
          helperText={inputHelperTexts?.single ?? helperText}
        />
      ) : (
        <MultiLyraOUOrOUSetSelector
          {...props}
          hideDefaultHelperText={hideMultiDefaultHelperText}
          helperText={inputHelperTexts?.multi ?? helperText}
          label={inputLabels?.multi}
        />
      )}
    </div>
  );
};
