import { useCallback, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { MultiSearchListField } from "@new-black/lyra";

import { UserBudgetDeduction } from "~/types/eva-core";
import { getUserBudgetDeductionTranslator } from "~/types/translations-enums/user-budget-deduction";

export const UserBudgetDeductionSelect = ({
  errorMessage,
  initialValue,
  name,
}: {
  name: string;
  initialValue?: number;
  errorMessage?: string;
}) => {
  const intl = useIntl();

  const translateUserBudgetDeduction = useCallback(
    (value: number) => getUserBudgetDeductionTranslator(intl)(value),
    [intl],
  );

  const valuesArray = useMemo(
    () => [
      {
        Value: UserBudgetDeduction.None,
        Name: translateUserBudgetDeduction(UserBudgetDeduction.None) as string,
      },
      {
        Value: UserBudgetDeduction.Program,
        Name: translateUserBudgetDeduction(UserBudgetDeduction.Program) as string,
      },
      {
        Value: UserBudgetDeduction.PaymentMethod,
        Name: translateUserBudgetDeduction(UserBudgetDeduction.PaymentMethod) as string,
      },
      {
        Value: UserBudgetDeduction.Both,
        Name: translateUserBudgetDeduction(UserBudgetDeduction.Both) as string,
      },
    ],
    [translateUserBudgetDeduction],
  );

  const [value, setValue] = useState(() => {
    if (!initialValue) return undefined;

    if (initialValue === UserBudgetDeduction.None) return [0];

    return valuesArray
      ?.filter((entry) => entry.Value !== 0 && (entry.Value & initialValue) === entry.Value)
      .map((entry) => entry.Value);
  });

  const allOption = useMemo(
    () => valuesArray?.reduce((accumulator, current) => accumulator | current.Value, 0),
    [valuesArray],
  );

  const items = useMemo(
    () => valuesArray?.filter((item) => item.Value !== allOption),
    [allOption, valuesArray],
  );

  const selectedValue = useMemo<typeof valuesArray[number][]>(
    () => items?.filter((item) => value?.includes(item.Value)) ?? [],
    [items, value],
  );

  const reducedValue = useMemo(
    () => value?.reduce((accumulator, current) => accumulator | current, 0),
    [value],
  );

  return (
    <>
      <MultiSearchListField
        getItemId={(item) => item.Value}
        getLabel={(item) => item.Name}
        selectRenderElements={(item) => ({
          label: translateUserBudgetDeduction(item.Value) ?? item.Name,
        })}
        value={selectedValue}
        items={items ?? []}
        onChange={(newValue) => setValue(newValue?.map((item) => item.Value) ?? [])}
        label={intl.formatMessage({
          id: "generic.label.user-budget-deduction",
          defaultMessage: "User budget deduction",
        })}
        errorMessage={errorMessage}
      />

      {reducedValue !== undefined ? (
        <input hidden readOnly name={name} value={reducedValue} />
      ) : null}
    </>
  );
};
