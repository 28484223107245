import { ComponentPropsWithoutRef } from "react";

import { cva } from "class-variance-authority";
import classNames from "clsx";

const wrapperClassname = cva(
  classNames(
    "[&_.dropzone]:flex [&_.dropzone]:h-full [&_.dropzone]:items-center [&_.dropzone]:justify-center",
    "[&_.dropzone]:rounded-[2px] [&_.dropzone]:border-2 [&_.dropzone]:border-[color:var(--legacy-eva-color-light-3)] [&_.dropzone]:p-[1rem]",
  ),
  {
    variants: {
      isDragActive: {
        true: "[&_.dropzone]:bg-[color:rgba(33,150,243,0.06)] [&_.dropzone]:border-solid [&_.dropzone]:border-[color:var(--legacy-eva-color-primary)]",
      },
      isDragReject: {
        true: "[&_.dropzone]:bg-[color:var(--legacy-eva-color-red-orange-transparent)] [&_.dropzone]:border-solid [&_.dropzone]:border-[color:var(--legacy-eva-color-error)]",
      },
    },
    compoundVariants: [
      {
        isDragActive: false,
        isDragReject: false,
        class: "[&_.dropzone]:border-dashed",
      },
    ],
  },
);

export const DropzoneWrapper = ({
  children,
  className,
  isDragActive,
  isDragReject,
}: ComponentPropsWithoutRef<"div"> & { isDragActive: boolean; isDragReject: boolean }) => (
  <div className={classNames(wrapperClassname({ isDragActive, isDragReject }), className)}>
    {children}
  </div>
);
