import { useMemo } from "react";
import { useIntl } from "react-intl";

import { adminSuiteConfig } from "../../../../admin-suite.config";
import { IChapter } from "../chapter-finder.types";

import useAllowedFunctionalities from "~/hooks/suite-react-hooks/use-allowed-functionalities";
import { useHasFunctionalityWithoutScope } from "~/hooks/suite-react-hooks/use-has-functionality";
import { Functionalities } from "~/types/functionalities";

export const useComplianceChapters = (): IChapter[] => {
  const intl = useIntl();

  const { functionalityAllowed: eventLedgerChapterAllowed } = useHasFunctionalityWithoutScope(
    Functionalities.EVENT_LEDGER_AUDITING,
  );

  const { viewFunctionalityAllowed: invoicesFunctionalityViewAllowed } = useAllowedFunctionalities(
    Functionalities.INVOICES,
  );

  const { viewFunctionalityAllowed: viewAuditFilesAllowed } = useAllowedFunctionalities(
    Functionalities.AUDITS,
  );

  const { viewFunctionalityAllowed: viewInvoiceExportsAllowed } = useAllowedFunctionalities(
    Functionalities.INVOICE_EXPORTS,
  );

  const complianceChapters = useMemo<IChapter[]>(() => {
    const chapters: IChapter[] = [];

    if (eventLedgerChapterAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.event-ledger",
          defaultMessage: "Event Ledger",
        }),
        path: "/event-ledger",
      });
    }

    if (invoicesFunctionalityViewAllowed) {
      chapters.push(
        {
          title: intl.formatMessage({
            id: "generic.title.manual-invoices",
            defaultMessage: "Manual Invoices",
          }),
          path: "/manual-invoices",
        },
        {
          title: intl.formatMessage({
            id: "generic.title.control-documents",
            defaultMessage: "Control Documents",
          }),
          path: "/control-documents",
        },
      );
    }

    if (viewAuditFilesAllowed && adminSuiteConfig.auditFiles) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.audit-files",
          defaultMessage: "Audit Files",
        }),
        path: "/audit-files",
      });
    }

    if (viewInvoiceExportsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.e-invoices",
          defaultMessage: "E-Invoices",
        }),
        path: "/e-invoices",
      });
    }

    return chapters;
  }, [
    eventLedgerChapterAllowed,
    intl,
    invoicesFunctionalityViewAllowed,
    viewAuditFilesAllowed,
    viewInvoiceExportsAllowed,
  ]);

  return complianceChapters;
};
