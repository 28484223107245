import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: auditingValidateConfigurationLoaderQuery,
  serviceQuery: auditingValidateConfigurationQuery,
  serviceQueryKeys: auditingValidateConfigurationQueryKeys,
  useServiceQueryHook: useAuditingValidateConfigurationQuery,
} = createServiceQuery(CoreManagement.AuditingValidateConfiguration);
