import { forwardRef, useCallback, useMemo } from "react";
import {
  generatePath,
  Link as RouterLink,
  LinkProps,
  Params,
  useSearchParams,
} from "react-router-dom";

import { buttonStyles, CustomTooltipTriggerWrapper, Tooltip } from "@new-black/lyra";
import { omit } from "lodash";
import { VariantProps } from "tailwind-variants";

import { usePrompt } from "./provider";

import { IRouteDefinition } from "~/types/route-definitions";

export type DefinedRouteButtonLinkProps = Omit<LinkProps, "to"> &
  VariantProps<typeof buttonStyles> & {
    routeDefinition: IRouteDefinition;
    routeParams?: Params<string>;
    keepSearchParams?: boolean;
    tooltip?: string;
  };

export const DefinedRouteButtonLink = forwardRef<HTMLAnchorElement, DefinedRouteButtonLinkProps>(
  (props, ref) => {
    const {
      children,
      className,
      fullWidth,
      keepSearchParams,
      onClick,
      replace = false,
      routeDefinition,
      routeParams,
      state,
      target,
      tooltip,
      variant,
    } = props;
    const { setConfirmRoute, when } = usePrompt();
    const [searchParams] = useSearchParams();

    const path = useMemo(() => {
      let generatedPath = generatePath(routeDefinition.path, routeParams);

      if (keepSearchParams) {
        generatedPath = `${generatedPath}?${searchParams}`;
      }

      return generatedPath;
    }, [keepSearchParams, routeDefinition.path, routeParams, searchParams]);

    const renderLink = useCallback(() => {
      return (
        <RouterLink
          {...omit(props, "keepSearchParams", "routeParams", "routeDefinition")}
          ref={ref}
          to={path}
          replace={replace}
          state={state}
          target={target}
          onClick={(e) => {
            if (when) {
              e?.preventDefault();
              setConfirmRoute(path);
            } else {
              onClick?.(e);
            }
          }}
          className={buttonStyles({ variant, fullWidth, className })}
        >
          {children}
        </RouterLink>
      );
    }, [
      children,
      className,
      fullWidth,
      onClick,
      path,
      props,
      ref,
      replace,
      setConfirmRoute,
      state,
      target,
      variant,
      when,
    ]);

    if (tooltip) {
      return (
        <Tooltip tooltip={tooltip}>
          <CustomTooltipTriggerWrapper>{renderLink()}</CustomTooltipTriggerWrapper>
        </Tooltip>
      );
    }

    return renderLink();
  },
);

DefinedRouteButtonLink.displayName = "DefinedRouteButtonLink";
