import { CoreManagement } from "@springtree/eva-services-core-management";

import {
  listOrganizationUnitShippingMethodsQuery,
  useGetOrganizationUnitShippingMethodByIDQuery,
} from "~/models/shipping-methods";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateOrganizationUnitShippingMethodSearchListField = (
  initialFilter?: EVA.Core.ListOrganizationUnitShippingMethodsFilter,
) =>
  SearchListFieldGenerator<
    CoreManagement.ListOrganizationUnitShippingMethods,
    {
      ShippingMethodID: number;
      ShippingMethodName?: string;
    },
    number
  >({
    getItemId: (item) => item.ShippingMethodID,
    getLabel: (item) => item.ShippingMethodName ?? "",
    useItemByID: (id) => {
      const { data, isFetching: isLoading } = useGetOrganizationUnitShippingMethodByIDQuery(
        id ? { ID: id } : undefined,
        { refetchOnWindowFocus: false },
      );

      return {
        data: id ? data : undefined,
        isLoading,
      };
    },
    getItemFromResponse: (response) =>
      response?.Result?.Page?.map((el) => ({
        ShippingMethodID: el.ShippingMethodID,
        ShippingMethodName: el.ShippingMethodName,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.shipping-method",
      defaultMessage: "Shipping method",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listOrganizationUnitShippingMethodsQuery,
        initialRequest: {
          PageConfig: { Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT, Start: 0, Filter: initialFilter },
        },
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton: (response?.Result?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (req) => ({
            ...req,
            PageConfig: {
              ...req?.PageConfig,
              Limit: req?.PageConfig?.Limit ?? 0 + DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
  });
