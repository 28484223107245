import { useCallback, useMemo, useState } from "react";

import { useGetScriptQuery, useListScriptHistoryQuery } from "~/models/scripts";

const useScriptEditorHistory = (scriptId?: number) => {
  const [limit, setLimit] = useState(10);
  const scriptHistoryQuery = useListScriptHistoryQuery(
    scriptId ? { ID: scriptId, InitialPageConfig: { Limit: limit } } : undefined,
    { loaderKey: [scriptId] },
  );

  const getScriptQuery = useGetScriptQuery(scriptId ? { ID: scriptId } : undefined, {});

  const onLoadMore = useCallback(() => {
    setLimit((prev) => prev + 10);
  }, []);

  const scriptHistory = useMemo(
    () => [
      {
        RevisionComment: getScriptQuery.data?.RevisionComment,
        Source: getScriptQuery.data?.Source,
        Revision: "-1",
      },
      ...(scriptHistoryQuery?.data?.Results ?? []),
    ],
    [
      getScriptQuery.data?.RevisionComment,
      getScriptQuery.data?.Source,
      scriptHistoryQuery?.data?.Results,
    ],
  );

  const isLoading = useMemo(
    () => getScriptQuery.isFetching || scriptHistoryQuery.isFetching,
    [getScriptQuery.isFetching, scriptHistoryQuery.isFetching],
  );

  return {
    scriptHistory,
    isLoading,
    onLoadMore: scriptHistoryQuery.data?.NextPageToken ? onLoadMore : undefined,
  };
};

export default useScriptEditorHistory;
