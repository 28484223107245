import { useCallback, useEffect, useState } from "react";

import { useRecoilState, useRecoilValue } from "recoil";

import { UserIdAssignedToTask } from "./state";

import { useNavigate } from "~/components/routing";
import useHandle404 from "~/hooks/suite-react-hooks/use-handle-404";
import { useHandleSWRFamily } from "~/hooks/suite-react-hooks/use-handle-swr";
import useCurrentUser from "~/hooks/use-current-user";
import { getUserTaskDetailsServiceState } from "~/store/get-user-task-details";

// TODO: refactor this to split up the logic
// TODO: when migrating to react-router loaders & react query, this check should happen in loaders; see SFS chapter as an example
const useCheckTaskAssignee = (routeBack: string, taskId?: number) => {
  const navigate = useNavigate();
  const currentUser = useCurrentUser();
  const userIdAssignedToTask = useRecoilValue(UserIdAssignedToTask(routeBack));

  const [confirmed, setConfirmed] = useState(false);
  const [passedValidation, setPassedValidation] = useState(false);
  const [showWarningDialog, setShowWarningDialog] = useState(false);
  const [isDifferentAssignee, setIsDifferentAssignee] = useState(false);

  const [userTaskDetailsRequest, setUserTaskDetailsRequest] = useRecoilState(
    getUserTaskDetailsServiceState.request(taskId),
  );
  const userTaskDetails = useRecoilValue(getUserTaskDetailsServiceState.response(taskId))?.Details;
  useHandleSWRFamily(getUserTaskDetailsServiceState, taskId);

  useEffect(() => {
    if (
      userIdAssignedToTask === null &&
      taskId !== undefined &&
      userTaskDetailsRequest?.UserTaskID !== taskId
    ) {
      setUserTaskDetailsRequest({ UserTaskID: taskId });
    }
  }, [setUserTaskDetailsRequest, taskId, userIdAssignedToTask, userTaskDetailsRequest?.UserTaskID]);

  const userTaskDetailsHasDifferentAssignee = useCallback(
    () =>
      userTaskDetails &&
      userTaskDetails?.UserID !== undefined &&
      userTaskDetails?.UserID !== currentUser?.ID,
    [currentUser, userTaskDetails],
  );

  useEffect(() => {
    if (
      (userIdAssignedToTask && userIdAssignedToTask !== currentUser?.ID) ||
      userTaskDetailsHasDifferentAssignee()
    ) {
      setIsDifferentAssignee(true);
    }
  }, [currentUser, userTaskDetailsHasDifferentAssignee, userIdAssignedToTask, userTaskDetails]);

  useEffect(() => {
    if (userTaskDetailsHasDifferentAssignee()) {
      setShowWarningDialog(true);
    }
  }, [currentUser, userTaskDetailsHasDifferentAssignee, userTaskDetails]);

  useEffect(() => {
    if (
      userIdAssignedToTask ||
      (userTaskDetails &&
        (userTaskDetails?.UserID === currentUser?.ID || !userTaskDetails?.UserID)) ||
      confirmed
    ) {
      setPassedValidation(true);
    }
  }, [confirmed, currentUser, userIdAssignedToTask, userTaskDetails]);

  const handleWarningDialogClose = useCallback(
    (accept: boolean) => {
      setShowWarningDialog(false);

      if (accept) {
        setConfirmed(true);
      } else {
        navigate(routeBack);
      }
    },
    [navigate, routeBack],
  );

  useHandle404({
    parameter: taskId,
    serviceFamilyState: getUserTaskDetailsServiceState,
  });

  return {
    passedValidation,
    isDifferentAssignee: isDifferentAssignee,
    showWarningDialog,
    handleWarningDialogClose,
  };
};

export default useCheckTaskAssignee;
