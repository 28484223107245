import { useCallback } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, Separator, SvgIcon, Text } from "@new-black/lyra";

import { useScriptSelectorContext } from "./script-selector-provider";

import { DEFAULT_SCRIPT_EDITOR_WITH_REVISION_COMMENT_HEADER_IN_MODAL_HEIGHT } from "~/components/shared/script-editor/helpers/base-script-editor-options";
import {
  ScriptEditorProps,
  ScriptEditorWithoutProvider,
} from "~/components/shared/script-editor/script-editor";
import { ScriptEditorLanguage } from "~/types/monaco-editor-language";

export const FullScreenCustomScriptEditor = ({
  onClose,
}: Pick<ScriptEditorProps, "value" | "onChange" | "initialValue"> & {
  onClose: () => void;
}) => {
  const intl = useIntl();

  const {
    customScriptRevisionComment,
    customScriptSource,
    editorType,
    initialCustomScript,
    isCreatingNewCustomScript,
    isScriptHistoryLoading,
    ModelProps,
    onCustomScriptRevisionCommentChange,
    onCustomScriptSourceChange,
    onLoadMoreHistory,
    scriptHistory,
    setEditorType,
  } = useScriptSelectorContext();

  const handleOnClose = useCallback(() => {
    // Reset editor type to `normal` when exiting the full screen mode
    setEditorType("normal");
    onClose();
  }, [onClose, setEditorType]);

  return (
    <div className="flex h-full flex-col">
      {initialCustomScript ? (
        <div className="px-4 pt-4">
          <Text variant="heading-2">
            <FormattedMessage id="generic.label.editor" defaultMessage="Editor" />
          </Text>
        </div>
      ) : undefined}
      <div className="flex-1">
        <ScriptEditorWithoutProvider
          value={customScriptSource}
          dialect={ScriptEditorLanguage.Extension}
          onChange={onCustomScriptSourceChange}
          height={DEFAULT_SCRIPT_EDITOR_WITH_REVISION_COMMENT_HEADER_IN_MODAL_HEIGHT}
          disableFirstLineEditing
          title={
            initialCustomScript
              ? undefined
              : intl.formatMessage({ id: "generic.label.editor", defaultMessage: "Editor" })
          }
          initialValue={initialCustomScript?.Source}
          hideCurrentRevisionComment={isCreatingNewCustomScript}
          revisionCommentValue={initialCustomScript ? customScriptRevisionComment : undefined}
          onRevisionCommentValueChange={
            initialCustomScript ? onCustomScriptRevisionCommentChange : undefined
          }
          hideFullScreenAction
          currentRevisionComment={initialCustomScript?.RevisionComment}
          customActionsPosition="end"
          customActions={
            <Button
              variant="icon"
              tooltip={intl.formatMessage({ id: "generic.label.close", defaultMessage: "Close" })}
              onPress={handleOnClose}
            >
              <SvgIcon name="exit-fullscreen" />
            </Button>
          }
          isHistoryLoading={isScriptHistoryLoading}
          scriptHistory={scriptHistory}
          onLoadMoreHistory={onLoadMoreHistory}
          ModelProps={ModelProps}
          editorType={editorType}
          setEditorType={setEditorType}
        />
      </div>
      <Separator />
      <div className="flex justify-end p-4">
        <Button variant="secondary" onPress={handleOnClose}>
          <FormattedMessage id="generic.label.close" defaultMessage="Close" />
        </Button>
      </div>
    </div>
  );
};
