import { useState } from "react";

import { IEvaServiceDefinition } from "@springtree/eva-services-core";
import { QueryKey, useQuery, UseQueryOptions } from "@tanstack/react-query";
import { omit } from "lodash";

import { ServiceQueryWithRequest } from "~/types/query.types";
import { ServiceQueryFunction } from "~/util/query";

/**
 * Same as `useServiceQueryWithRequest` (`src/util/query/index.ts`) but it doesn't require a loader query (can be used with a simple query)
 *
 * @param queryFn - returned by `createServiceQuery` (`serviceQuery` field)
 * @param initialRequest - initial request to be used
 * @param options - options to be passed to `useQuery`
 * @param options.key - query key that should be added to the default query key (the default key is generated from the service as defined in `src/util/query/index.ts`)
 */
export const useQueryWithRequest = <SVC extends IEvaServiceDefinition>(
  queryFn: ServiceQueryFunction<SVC>,
  initialRequest?: SVC["request"],
  options?: Partial<
    Omit<
      UseQueryOptions<SVC["response"], unknown, SVC["response"], QueryKey>,
      "initialData" | "queryKey"
    > & {
      initialData?: () => undefined;
    } & { key?: QueryKey }
  >,
): ServiceQueryWithRequest<SVC["request"], SVC["response"]> => {
  const [request, setRequest] = useState<SVC["request"] | undefined>(initialRequest);
  const queryResult = useQuery({
    ...queryFn(request, options?.key),
    ...omit(options, ["key"]),
  });
  return { request, setRequest, queryResult };
};
