import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getCurrencyLoaderQuery,
  serviceQuery: getCurrencyQuery,
  serviceQueryKeys: getCurrencyKeys,
  useServiceQueryHook: useGetCurrencyQuery,
  useServiceQueryWithRequest: useGetCurrencyQueryWithRequest,
} = createServiceQuery(Core.GetCurrency);

export const {
  serviceLoaderQuery: listCurrenciesLoaderQuery,
  serviceQueryKeys: listCurrenciesKeys,
  useServiceQueryHook: useListCurrenciesQuery,
} = createServiceQuery(Core.ListCurrencies);
