import { parseDate } from "@internationalized/date";
import { z } from "zod";
import { zfd } from "zod-form-data";

import { intlAccessor } from "~/util/intl-accessor";

export const loyaltyProgramGroupFormSchema = z
  .object({
    Name: z.string(),
    TaxExemptionCode: z.string().optional(),
    BudgetCurrencyID: z.string().optional(),
    StartDate: z.string().optional(),
    EndDate: z.string().optional(),
    Status: zfd.numeric(z.number().optional()),
  })
  .refine(
    ({ EndDate, StartDate }) => {
      if (EndDate?.length && StartDate?.length) {
        return parseDate(StartDate).compare(parseDate(EndDate)) < 0;
      }

      return true;
    },
    {
      message: intlAccessor.formatMessage({
        id: "validation.start-date-before-end-date",
        defaultMessage: "Start date must be before end date.",
      }),
      path: ["StartDate"],
    },
  );

export const loyaltyProgramGroupFormKeys = loyaltyProgramGroupFormSchema.innerType().keyof().Values;

export type LoyaltyProgramGroupFormErrors = Partial<
  Record<keyof typeof loyaltyProgramGroupFormKeys, string>
>;

export type TLoyaltyGroupForm = z.infer<typeof loyaltyProgramGroupFormSchema>;
