import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getShipmentLoaderQuery,
  useServiceQueryHook: useGetShipmentQuery,
} = createServiceQuery(Core.GetShipment, true);

export const {
  serviceLoaderQuery: listShipmentLinesLoaderQuery,
  serviceQueryKeys: listShipmentLinesQueryKeys,
  useServiceQueryHook: useListShipmentLinesQuery,
} = createServiceQuery(Core.ListShipmentLines, true);
