import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { getSupportedFunctionalitiesQuery } from "~/models/functionalities";
import { ONE_HOUR } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";
import { ISearchListFieldGeneratorProps } from "~/util/lyra-search-list-field-generator/search-list-field-generator.types";

export type SupportedFunctionalitiesSearchListFieldItem =
  EVA.Core.Management.GetSupportedFunctionalitiesResponse.FunctionalityDefinition;

type BaseSearchListFieldGeneratorProps = ISearchListFieldGeneratorProps<
  CoreManagement.GetSupportedFunctionalities,
  SupportedFunctionalitiesSearchListFieldItem,
  string
>;

export const generateSupportedFunctionalitiesLyraSearchListField = ({
  frontendFilter,
}: {
  frontendFilter?: BaseSearchListFieldGeneratorProps["frontendFilter"];
}) =>
  SearchListFieldGenerator<
    CoreManagement.GetSupportedFunctionalities,
    SupportedFunctionalitiesSearchListFieldItem,
    string
  >({
    getItemFromResponse: (response) => response?.ScopedFunctionalities ?? [],
    getItemId: (item) => item.Name,
    getLabel: (item) => item.Name,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.functionality",
      defaultMessage: "Functionality",
    }),
    useItemByID: (name, itemsFromList) => {
      const item = useMemo(
        () => itemsFromList?.find((item) => item.Name === name),
        [name, itemsFromList],
      );
      return { data: item, isLoading: false };
    },
    useItemsByID: (names, itemsFromList) => {
      const items = useMemo(
        () =>
          names
            ?.map((name) => itemsFromList?.find((item) => item.Name === name))
            ?.filter((item): item is SupportedFunctionalitiesSearchListFieldItem => !!item),
        [names, itemsFromList],
      );
      return { data: items, isLoading: false };
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: getSupportedFunctionalitiesQuery,
        initialRequest: {},
        refetchOnFocus: false,
        staleTime: ONE_HOUR,
      }),
    frontendFilter,
  });

export const GeneratedSupportedFunctionalitiesLyraSearchListField =
  generateSupportedFunctionalitiesLyraSearchListField({});
