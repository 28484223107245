import { CoreManagement } from "@springtree/eva-services-core-management";

import { useListPaymentTypesByIds } from "./use-list-payment-types-by-ids";

import { listPaymentTypesQuery, listPaymentTypesQueryKeys } from "~/models/payment-types";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT, ONE_SECOND } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateListPaymentTypesSearchListField = () =>
  SearchListFieldGenerator<
    CoreManagement.ListPaymentTypes,
    EVA.Core.Management.ListPaymentTypesResponse.PaymentTypeDto,
    number
  >({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listPaymentTypesQuery,
        refetchOnFocus: false,
        initialRequest: {
          PageConfig: { Start: 0, Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT },
        },
        queryKey: listPaymentTypesQueryKeys.withKey(["search-list-field"]),
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue,
            },
          },
        }),
        staleTime: ONE_SECOND,
        cacheTime: ONE_SECOND,
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
    getItemId: (item) => item?.ID,
    getLabel: (item) => item.Name ?? "",
    getItemFromResponse: (response) => response?.Result.Page,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.payment-type",
      defaultMessage: "Payment type",
    }),
    useItemsByID: useListPaymentTypesByIds,
  });
