import { CountryCode } from "libphonenumber-js";
import metadata from "libphonenumber-js/metadata.mobile.json";

import { intlAccessor } from "~/util/intl-accessor";

export interface ICountryPhoneCode {
  country_code: CountryCode;
  country_name: string;
  phone_code: string;
}

export const countriesPhoneNumbers: ICountryPhoneCode[] = Object.entries(metadata.countries).map(
  ([country_code, [phone_code]]) => ({
    country_code: country_code as CountryCode,
    country_name:
      intlAccessor.formatDisplayName(country_code.toUpperCase(), { type: "region" }) ??
      country_code.toUpperCase(),
    phone_code: `+${phone_code}`,
  }),
);
