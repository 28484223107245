import { useEffect } from "react";
import type { TableDispatch } from "react-table";

import usePrevious from "~/hooks/suite-react-hooks/use-previous";

const usePagination = (
  dispatch: TableDispatch<any>,
  pageSize?: number,
  pageIndex?: number,
  limit?: number,
  skip?: number,
  setLimit?: (value: number) => void,
  setSkip?: (value: number) => void,
) => {
  const previousSkip = usePrevious(skip);

  /**
   * When setLimit is available update it with the pageSize
   *
   */
  useEffect(() => {
    if (setLimit !== undefined && pageSize !== undefined) {
      setLimit(pageSize);
    }
  }, [setLimit, pageSize]);

  /**
   * When setSkip is available update it when pageIndex or limit changes
   *
   */
  useEffect(() => {
    if (setSkip !== undefined && limit !== undefined && pageIndex !== undefined) {
      setSkip(pageIndex * limit);
    }
  }, [setSkip, limit, pageIndex]);

  /**
   * Reset the page index if skip is 0 but pageIndex is not updated.
   * This is in case you change the data (items), from outside of this component.
   */
  useEffect(() => {
    if (
      skip === 0 &&
      previousSkip !== skip &&
      previousSkip !== undefined &&
      setSkip !== undefined &&
      pageIndex !== undefined &&
      pageIndex > 0
    ) {
      dispatch({
        type: "resetPage",
      });
    }
  }, [dispatch, skip, setSkip, pageIndex, previousSkip]);
};

export default usePagination;
