import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { Button, SvgIcon } from "@new-black/lyra";

import {
  CFCInferredCFType,
  CFCInferredFieldType,
  CFCTypedValueCustomField,
  ChangeHandlerWithType,
  ICFCCustomFieldValueTypeWithArrays,
  ICFCTranslations,
} from "../custom-field-consumer.types";
import { useCustomFieldLabelEntityTranslation } from "../use-custom-field-label-entity-translation";

import { CustomBoolFieldConsumer } from "./custom-bool-field-consumer";
import { CustomDateFieldConsumer } from "./custom-date-field-consumer";
import { CustomMultiEnumFieldConsumer } from "./custom-multi-enum-field-consumer";
import { CustomNumberFieldConsumer } from "./custom-number-field-consumer";
import { CustomSingleEnumFieldConsumer } from "./custom-single-enum-field-consumer";
import { CustomStringFieldConsumer } from "./custom-string-field-consumer";

import { ICustomField } from "~/types/custom-field";
import { CustomFieldDataType } from "~/types/custom-field-data-types";

type X = CFCTypedValueCustomField;

interface ICustomFieldDataTypeConsumerProps<U extends X> {
  label?: string;
  placeholder?: string;
  disableLabel?: boolean;
  customField: ICustomField;
  value: CFCInferredFieldType<U>;
  onChange: (value: CFCInferredFieldType<U>, type: CFCInferredCFType<U>, error?: string) => void;
  translations?: ICFCTranslations;
  enumDisplay?: "expanded" | "inline";
  booleanVariant?: "switch" | "segmented" | "radio";
  touched?: boolean;
  error?: string;
  allowMultipleValues: boolean;
  alwaysShowValidationMessage?: boolean;
  variant?: "material" | "lyra";
}

const ArrayRowIcon = <
  Value extends boolean | number | string,
  Type extends ICFCCustomFieldValueTypeWithArrays,
>({
  arr,
  idx,
  onChange,
  type,
}: {
  type: Type;
  idx: number;
  arr: (Value | undefined)[];
  onChange: ChangeHandlerWithType<(Value | undefined)[] | undefined, Type>;
}) => {
  const intl = useIntl();
  return (
    <Button
      variant="icon"
      tooltip={
        idx === 0
          ? intl.formatMessage({ id: "generic.label.add", defaultMessage: "Add" })
          : intl.formatMessage({ id: "generic.label.delete", defaultMessage: "Delete" })
      }
      className="mt-5"
      onPress={() =>
        onChange(idx === 0 ? [...arr, undefined] : arr.filter((_, index) => index !== idx), type)
      }
    >
      {idx === 0 ? <SvgIcon name="plus" /> : <SvgIcon name="clear" />}
    </Button>
  );
};

/** This is an internal component, and it is not intended for use outside of the CustomFieldConsumer.
 * Please use the **CustomFieldConsumer** component instead! */
const CustomFieldDataTypeConsumer = <T extends X>({
  allowMultipleValues,
  alwaysShowValidationMessage,
  booleanVariant,
  customField,
  disableLabel,
  enumDisplay,
  error,
  label,
  onChange,
  placeholder,
  touched,
  translations,
  value,
  variant,
}: ICustomFieldDataTypeConsumerProps<T>) => {
  const entityTranslationLabel = useCustomFieldLabelEntityTranslation(customField);

  const customFieldLabel = useMemo(() => {
    if (disableLabel) {
      return undefined;
    }

    return label ?? entityTranslationLabel;
  }, [disableLabel, entityTranslationLabel, label]);

  const getCFArray = useCallback(
    <T extends boolean | number | string>(v: any) =>
      (v as (T | undefined)[] | undefined) ?? ([undefined] as (T | undefined)[]),
    [],
  );

  const isCFArray = useMemo(
    () => (customField.IsArray ?? false) && (value === undefined || Array.isArray(value)),
    [customField.IsArray, value],
  );

  switch (customField.DataType) {
    case CustomFieldDataType.Bool:
      return isCFArray ? (
        <div className="flex flex-col gap-2">
          {getCFArray<boolean>(value).map((arrValue, idx, arr) => (
            <div key={idx} className="flex flex-row items-center gap-2">
              <CustomBoolFieldConsumer
                error={error}
                label={customFieldLabel}
                name={`${customField.Name}[${idx}]`}
                value={arrValue as boolean | undefined}
                required={customField.Options?.IsRequired}
                requiredMessage={translations?.requiredMessage}
                onChange={(newValue) =>
                  (
                    onChange as ChangeHandlerWithType<
                      (boolean | undefined)[] | undefined,
                      "MultiBoolValue"
                    >
                  )(
                    arr.map((cfv, index) => (index === idx ? newValue : cfv)),
                    "MultiBoolValue",
                  )
                }
                variant={booleanVariant}
                componentVariant={variant}
              />

              {allowMultipleValues ? (
                <ArrayRowIcon
                  idx={idx}
                  arr={arr}
                  type="MultiBoolValue"
                  onChange={
                    onChange as ChangeHandlerWithType<
                      (boolean | undefined)[] | undefined,
                      "MultiBoolValue"
                    >
                  }
                />
              ) : null}
            </div>
          ))}
        </div>
      ) : (
        <CustomBoolFieldConsumer
          error={error}
          name={customField.Name}
          value={value as boolean | undefined}
          label={customFieldLabel}
          required={customField.Options?.IsRequired}
          requiredMessage={translations?.requiredMessage}
          onChange={(newValue) =>
            (onChange as ChangeHandlerWithType<boolean | undefined, "BoolValue">)(
              newValue,
              "BoolValue",
            )
          }
          variant={booleanVariant}
          componentVariant={variant}
        />
      );
    case CustomFieldDataType.Integer:
    case CustomFieldDataType.Decimal:
      return isCFArray ? (
        <div className="flex flex-col gap-2">
          {getCFArray<number>(value).map((arrValue, idx, arr) => (
            <div key={idx} className="flex flex-row items-center gap-2">
              <CustomNumberFieldConsumer
                error={error}
                touched={touched}
                label={customFieldLabel}
                placeholder={placeholder}
                name={`${customField.Name}[${idx}]`}
                value={arrValue as number | undefined}
                min={customField.Options?.MinimumValue}
                max={customField.Options?.MaximumValue}
                required={customField.Options?.IsRequired}
                minMessage={translations?.minValueMessage}
                maxMessage={translations?.maxValueMessage}
                requiredMessage={translations?.requiredMessage}
                outOfRangeMessage={translations?.outOfRangeValueMessage}
                float={customField.DataType === CustomFieldDataType.Decimal}
                onChange={(newValue, error) =>
                  (
                    onChange as ChangeHandlerWithType<
                      (number | undefined)[] | undefined,
                      "MultiDecimalValue" | "MultiIntegerValue"
                    >
                  )(
                    arr.map((cfv, index) => (index === idx ? newValue : cfv)),
                    customField.DataType === 3 ? "MultiDecimalValue" : "MultiIntegerValue",
                    error,
                  )
                }
                alwaysShowValidationMessage={alwaysShowValidationMessage}
                componentVariant={variant}
              />

              {allowMultipleValues ? (
                <ArrayRowIcon
                  idx={idx}
                  arr={arr}
                  type={customField.DataType === 3 ? "MultiDecimalValue" : "MultiIntegerValue"}
                  onChange={
                    onChange as ChangeHandlerWithType<
                      (number | undefined)[] | undefined,
                      "MultiDecimalValue" | "MultiIntegerValue"
                    >
                  }
                />
              ) : null}
            </div>
          ))}
        </div>
      ) : (
        <CustomNumberFieldConsumer
          touched={touched}
          error={error}
          name={customField.Name}
          label={customFieldLabel}
          placeholder={placeholder}
          value={value as number | undefined}
          min={customField.Options?.MinimumValue}
          max={customField.Options?.MaximumValue}
          required={customField.Options?.IsRequired}
          minMessage={translations?.minValueMessage}
          maxMessage={translations?.maxValueMessage}
          requiredMessage={translations?.requiredMessage}
          outOfRangeMessage={translations?.outOfRangeValueMessage}
          float={customField.DataType === CustomFieldDataType.Decimal}
          onChange={(newValue, error) =>
            (
              onChange as ChangeHandlerWithType<number | undefined, "DecimalValue" | "IntegerValue">
            )(newValue, customField.DataType === 3 ? "DecimalValue" : "IntegerValue", error)
          }
          alwaysShowValidationMessage={alwaysShowValidationMessage}
          componentVariant={variant}
        />
      );
    case CustomFieldDataType.Enum:
      return isCFArray ? (
        <CustomMultiEnumFieldConsumer
          error={error}
          name={customField.Name}
          label={customFieldLabel}
          value={value as string[] | undefined}
          options={customField.EnumValues ?? {}}
          required={customField.Options?.IsRequired}
          requiredMessage={translations?.requiredMessage}
          onChange={(newValue) =>
            (onChange as ChangeHandlerWithType<string[] | undefined, "MultiEnumValue">)(
              newValue,
              "MultiEnumValue",
            )
          }
          allowMultipleValues={allowMultipleValues}
          enumDisplay={enumDisplay}
          componentVariant={variant}
        />
      ) : (
        <CustomSingleEnumFieldConsumer
          error={error}
          name={customField.Name}
          label={customFieldLabel}
          value={value as string | undefined}
          options={customField.EnumValues ?? {}}
          required={customField.Options?.IsRequired}
          requiredMessage={translations?.requiredMessage}
          onChange={(newValue) =>
            (onChange as ChangeHandlerWithType<string | undefined, "SingleEnumValue">)(
              newValue,
              "SingleEnumValue",
            )
          }
          enumDisplay={enumDisplay}
          componentVariant={variant}
        />
      );
    case CustomFieldDataType.DateTime:
    case CustomFieldDataType.Date:
      return isCFArray ? (
        <div className="flex flex-col gap-2">
          {getCFArray<string>(value).map((arrValue, idx, arr) => (
            <div key={idx} className="flex flex-row items-center gap-2">
              <CustomDateFieldConsumer
                touched={touched}
                error={error}
                name={`${customField.Name}[${idx}]`}
                value={arrValue as string | undefined}
                onChange={(newValue, error) =>
                  (
                    onChange as ChangeHandlerWithType<
                      (string | undefined)[] | undefined,
                      "MultiDateTimeValue" | "MultiDateValue"
                    >
                  )(
                    arr.map((cfv, index) => (index === idx ? newValue : cfv)),
                    customField.DataType === 7 ? "MultiDateTimeValue" : "MultiDateValue",
                    error,
                  )
                }
                label={customFieldLabel}
                placeholder={placeholder}
                timeLabel={translations?.timeLabel}
                required={customField.Options?.IsRequired}
                minimum={customField.Options?.MinimumDate}
                maximum={customField.Options?.MaximumDate}
                minimumMessage={translations?.minDateMessage}
                maximumMessage={translations?.maxDateMessage}
                requiredMessage={translations?.requiredMessage}
                outOfRangeMessage={translations?.outOfRangeDateMessage}
                time={customField.DataType === CustomFieldDataType.DateTime}
                alwaysShowValidationMessage={alwaysShowValidationMessage}
                componentVariant={variant}
              />

              {allowMultipleValues ? (
                <ArrayRowIcon
                  idx={idx}
                  arr={arr}
                  type={customField.DataType === 7 ? "MultiDateTimeValue" : "MultiDateValue"}
                  onChange={
                    onChange as ChangeHandlerWithType<
                      (string | undefined)[] | undefined,
                      "MultiDateTimeValue" | "MultiDateValue"
                    >
                  }
                />
              ) : null}
            </div>
          ))}
        </div>
      ) : (
        <CustomDateFieldConsumer
          touched={touched}
          error={error}
          name={customField.Name}
          value={value as string | undefined}
          onChange={(newValue, error) =>
            (onChange as ChangeHandlerWithType<string | undefined, "DateTimeValue" | "DateValue">)(
              newValue,
              customField.DataType === 6 ? "DateTimeValue" : "DateValue",
              error,
            )
          }
          label={customFieldLabel}
          placeholder={placeholder}
          timeLabel={translations?.timeLabel}
          required={customField.Options?.IsRequired}
          minimum={customField.Options?.MinimumDate}
          maximum={customField.Options?.MaximumDate}
          minimumMessage={translations?.minDateMessage}
          maximumMessage={translations?.maxDateMessage}
          requiredMessage={translations?.requiredMessage}
          outOfRangeMessage={translations?.outOfRangeDateMessage}
          time={customField.DataType === CustomFieldDataType.DateTime}
          alwaysShowValidationMessage={alwaysShowValidationMessage}
          componentVariant={variant}
        />
      );
    case CustomFieldDataType.String:
    case CustomFieldDataType.Text:
    default:
      return isCFArray ? (
        <div className="flex flex-col gap-2">
          {getCFArray<string>(value).map((arrValue, idx, arr) => (
            <div key={idx} className="flex flex-row items-center gap-2">
              <CustomStringFieldConsumer
                touched={touched}
                error={error}
                value={arrValue as string | undefined}
                name={`${customField.Name}[${idx}]`}
                label={customFieldLabel}
                placeholder={placeholder}
                minLength={customField.Options?.MinimumLength}
                maxLength={customField.Options?.MaximumLength}
                requiredMessage={translations?.requiredMessage}
                maxLengthMessage={translations?.maxLengthMessage}
                minLengthMessage={translations?.minLengthMessage}
                outOfRangeMessage={translations?.outOfRangeLengthMessage}
                required={customField.Options?.IsRequired ?? false}
                multiline={customField.DataType === CustomFieldDataType.Text}
                onChange={(newValue, error) =>
                  (
                    onChange as ChangeHandlerWithType<
                      (string | undefined)[] | undefined,
                      "MultiMultiLineStringValue" | "MultiSingleLineStringValue"
                    >
                  )(
                    arr.map((cfv, index) => (index === idx ? newValue : cfv)),
                    customField.DataType === CustomFieldDataType.Text
                      ? "MultiMultiLineStringValue"
                      : "MultiSingleLineStringValue",
                    error,
                  )
                }
                alwaysShowValidationMessage={alwaysShowValidationMessage}
                componentVariant={variant}
              />

              {allowMultipleValues ? (
                <ArrayRowIcon
                  idx={idx}
                  arr={arr}
                  type={
                    customField.DataType === CustomFieldDataType.Text
                      ? "MultiMultiLineStringValue"
                      : "MultiSingleLineStringValue"
                  }
                  onChange={
                    onChange as ChangeHandlerWithType<
                      (string | undefined)[] | undefined,
                      "MultiMultiLineStringValue" | "MultiSingleLineStringValue"
                    >
                  }
                />
              ) : null}
            </div>
          ))}
        </div>
      ) : (
        <CustomStringFieldConsumer
          touched={touched}
          error={error}
          value={value as string | undefined}
          name={customField.Name}
          label={customFieldLabel}
          placeholder={placeholder}
          minLength={customField.Options?.MinimumLength}
          maxLength={customField.Options?.MaximumLength}
          requiredMessage={translations?.requiredMessage}
          maxLengthMessage={translations?.maxLengthMessage}
          minLengthMessage={translations?.minLengthMessage}
          outOfRangeMessage={translations?.outOfRangeLengthMessage}
          required={customField.Options?.IsRequired ?? false}
          multiline={customField.DataType === CustomFieldDataType.Text}
          onChange={(newValue, error) =>
            (
              onChange as ChangeHandlerWithType<
                string | undefined,
                "MultiLineStringValue" | "SingleLineStringValue"
              >
            )(
              newValue,
              customField.DataType === CustomFieldDataType.Text
                ? "MultiLineStringValue"
                : "SingleLineStringValue",
              error,
            )
          }
          alwaysShowValidationMessage={alwaysShowValidationMessage}
          componentVariant={variant}
        />
      );
  }
};

export default CustomFieldDataTypeConsumer;
