import { builders } from "@springtree/eva-sdk-react-recoil";
import { CoreManagement } from "@springtree/eva-services-core-management";
import { selector } from "recoil";

import { ALL_ROWS_LIMIT } from "~/components/suite-composite/utils/types";

export const discountLayersServiceState = builders.buildServiceState({
  service: CoreManagement.ListDiscountLayers,
  key: "CoreManagement:ListDiscountLayers",
  allowEmptyRequest: false,
  requireEmployee: true,
  defaultRequest: {
    PageConfig: {
      Start: 0,
      Limit: ALL_ROWS_LIMIT,
    },
  },
});

export const discountLayersResultSelector = selector({
  key: "CoreManagement:ListDiscountLayers:Result",
  get: ({ get }) => get(discountLayersServiceState.response)?.Result?.Page,
});
