import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getLayerGroupedDiscountsByQueryLoaderQuery,
  useServiceQueryHook: useGetLayerGroupedDiscountsByQueryQuery,
} = createServiceQuery(CoreManagement.GetLayerGroupedDiscountsByQuery, true);

export const {
  serviceLoaderQuery: getDiscountByIDLoaderQuery,
  serviceQuery: getDiscountByIDQuery,
  serviceQueryKeys: getDiscountByIDQueryKeys,
  useServiceQueryHook: useGetDiscountByIDQuery,
} = createServiceQuery(CoreManagement.GetDiscountByID, true);
