import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Button, Card } from "@new-black/lyra";
import classNames from "clsx";

import { toast } from "~/components/suite-ui/toast";

interface IRawCodeBoxProps {
  className?: string;
  isCopiable?: boolean;
  copyText?: string;
  children: string;
}

export const RawCodeBox = ({ children, className, copyText, isCopiable }: IRawCodeBoxProps) => {
  const intl = useIntl();

  const textToCopy = useMemo(() => copyText ?? children, [children, copyText]);

  const handleCopy = useCallback(() => {
    if (navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(textToCopy)
        .then(() =>
          toast.success(
            intl.formatMessage({
              id: "generic.label.copied",
              defaultMessage: "Copied to clipboard",
            }),
          ),
        )
        .catch((err) => console.error(err));
    }
  }, [intl, textToCopy]);

  return (
    <Card variant="secondary" className={classNames("group relative", className)}>
      {isCopiable ? (
        <div className="invisible absolute right-0 group-hover:visible">
          <Button variant="secondary" onPress={handleCopy}>
            <FormattedMessage id="generic.label.copy" defaultMessage="Copy" />
          </Button>
        </div>
      ) : null}
      <code>
        <pre className="my-0">
          <div className="overflow-auto px-5 py-2">{children}</div>
        </pre>
      </code>
    </Card>
  );
};
