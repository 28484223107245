import { userByIdSelector } from "./state";

import { useLoadableState } from "~/hooks/use-loadable-state";

const useUserById = (id?: number) => {
  const {
    isLoading,
    isLoadingWithoutResponse,
    value: user,
  } = useLoadableState(userByIdSelector(id));

  return {
    user,
    isLoading,
    isLoadingWithoutResponse,
  };
};

export default useUserById;
