import { useMemo } from "react";

import { generateProductRequirementAutocomplete } from "~/components/suite-composite/generate-product-requirement-autocomplete";
import { getProductRequirementLabel } from "~/features/discount-edit/views/conditions/tabs/product-requirement/product-requirement.util";

export const EvaProductSetProductRequirementField = ({
  errorMessage,
  isLoading,
  onChange,
  onFieldBlur,
  passive,
  productID,
  value,
}: {
  productID: number;
  value?: EVA.Core.Management.ProductRequirementDto;
  onChange: (productRequirementID?: number) => void;
  onFieldBlur: () => void;
  passive?: boolean;
  isLoading?: boolean;
  errorMessage?: string;
}) => {
  const ProductRequirementAutocomplete = useMemo(
    () =>
      generateProductRequirementAutocomplete({ ProductID: productID }, (productRequirement) => ({
        ID: productRequirement.ID,
        Name: getProductRequirementLabel(productRequirement),
      })).ProductRequirementAutocomplete,
    [productID],
  );

  return (
    <ProductRequirementAutocomplete.Controlled
      required
      value={value}
      onChange={(newValue) => onChange(newValue?.ID)}
      passive={passive}
      isLoading={isLoading}
      error={!!errorMessage}
      helperText={errorMessage}
      onBlur={onFieldBlur}
    />
  );
};
