import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { listCustomFieldEligibilityScriptsQuery, useGetScriptQuery } from "~/models/scripts";
import { AutocompleteGenerator } from "~/util/autocomplete-generator";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const useScriptByID = (id?: number) => {
  const request = useMemo(() => (id ? { ID: id } : undefined), [id]);
  const { data, isFetching } = useGetScriptQuery(request, {});
  const script = useMemo(
    () => (id && data?.ID && data?.Name ? { ID: data.ID, Name: data.Name } : undefined),
    [data?.ID, data?.Name, id],
  );
  return {
    data: script,
    isLoading: id ? isFetching : false,
  };
};

export const generateCustomFieldEligibilityScriptAutocomplete = (
  initialRequest: EVA.Core.Management.ListCustomFieldEligibilityScripts,
) =>
  AutocompleteGenerator<
    CoreManagement.ListCustomFieldEligibilityScripts,
    { Name: string; ID: number },
    "ID"
  >({
    getItemFromResponse: (response) =>
      response?.Scripts?.filter((item) => item.Name && item.ID)?.map((item) => ({
        Name: item.Name!,
        ID: item.ID!,
      })),
    idKey: "ID",
    labelKey: "Name",
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.eligibility-script",
      defaultMessage: "Eligibility Script",
    }),
    useItemByID: useScriptByID,
    useServiceQuery: () =>
      AutocompleteGenerator.useAutocompleteService({
        initialRequest,
        query: listCustomFieldEligibilityScriptsQuery,
      }),
  });

export const generateCustomFieldEligibilityScriptLyraSearchListField = (
  initialRequest: EVA.Core.Management.ListCustomFieldEligibilityScripts,
) =>
  LyraSearchListFieldGenerator<
    CoreManagement.ListCustomFieldEligibilityScripts,
    { Name: string; ID: number },
    number
  >({
    getItemFromResponse: (response) =>
      response?.Scripts?.filter((item) => item.Name && item.ID)?.map((item) => ({
        Name: item.Name!,
        ID: item.ID!,
      })),
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.eligibility-script",
      defaultMessage: "Eligibility Script",
    }),
    useItemByID: useScriptByID,
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listCustomFieldEligibilityScriptsQuery,
        initialRequest,
      }),
  });
