import { useIntl } from "react-intl";

import { Card, CardActions, CardContent, CardHeader } from "@new-black/lyra";

import { LoyaltyProgramRuleConsumerConditionKeyValueRows } from "./loyalty-program-rule-consumer-condition-key-value-rows";

import DeleteActionButton from "~/components/shared/action-buttons/delete-action-button";
import EditActionButton from "~/components/shared/action-buttons/edit-action-button";
import { TLoyaltyProgramCustomFieldConsumerWithOperatorForm } from "~/features/loyalty-programs/loyalty-program-custom-field-consumer-with-operator-fields";
import { useGetCustomFieldByIDQuery } from "~/models/custom-fields";

export const LoyaltyProgramRuleConsumerConditionCard = ({
  conditionID,
  data,
  inner,
  onDelete,
  onEdit,
}: {
  conditionID: number;
  data: Partial<TLoyaltyProgramCustomFieldConsumerWithOperatorForm>;
  onEdit?: (conditionID: number) => void;
  onDelete?: (conditionID: number) => void;
  inner?: boolean;
}) => {
  const intl = useIntl();

  const { data: customField } = useGetCustomFieldByIDQuery(
    data?.CustomFieldId ? { ID: data.CustomFieldId } : undefined,
    {},
  );

  return (
    <Card variant={inner ? "secondary" : "primary"}>
      <CardHeader
        title={intl.formatMessage({
          id: "generic.label.consumer",
          defaultMessage: "Consumer",
        })}
        actions={
          onEdit || onDelete ? (
            <CardActions>
              {onEdit ? <EditActionButton onPress={() => onEdit(conditionID)} /> : null}
              {onDelete ? <DeleteActionButton onPress={() => onDelete(conditionID)} /> : null}
            </CardActions>
          ) : null
        }
      />

      <CardContent className="flex flex-col gap-2">
        <LoyaltyProgramRuleConsumerConditionKeyValueRows
          customField={customField}
          operator={data?.Operator}
          value={data?.CustomFieldValue}
          hasValue={data?.CustomFieldValue?.HasValue}
        />
      </CardContent>
    </Card>
  );
};
