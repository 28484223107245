import { ComponentType, ReactNode, useMemo, useState } from "react";

import useBoundingRectangle, {
  IBoundingRectangle,
} from "~/hooks/suite-react-hooks/use-bounding-rectangle";

export interface ContentDimensions {
  contentWidth?: number;
  contentHeight?: number;
}

export interface IMeasureContent<TParentProps> {
  /* component whose children's dimension we need to measure */
  ParentComponent: ComponentType<TParentProps & ContentDimensions & { children: ReactNode }>;
  /* additional props we need to pass to the parent component */
  parentComponentProps: TParentProps;
  children: ReactNode;
}
/**
 * Component used to inject the width and height of a component's children as props.
 */
export const MeasureContent = <TParentProps,>({
  children,
  ParentComponent,
  parentComponentProps,
}: IMeasureContent<TParentProps>) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const boundingRectangle = useBoundingRectangle(ref);

  const height = useMemo<number | undefined>(
    () => boundingRectangle?.height,
    [boundingRectangle?.height],
  );
  const width = useMemo<number | undefined>(
    () => boundingRectangle?.width,
    [boundingRectangle?.width],
  );

  return (
    <ParentComponent {...parentComponentProps} contentWidth={width} contentHeight={height}>
      <div
        ref={(instance) => {
          if (instance) {
            setRef(instance);
          }
        }}
      >
        {children}
      </div>
    </ParentComponent>
  );
};

export interface IMeasureContentRenderFunctionProps {
  children: (props: IBoundingRectangle) => JSX.Element;
}

/**
 * Component used to inject the width and height of a component's children as props.
 */
export const MeasureContentRenderFunction = ({ children }: IMeasureContentRenderFunctionProps) => {
  const [ref, setRef] = useState<HTMLDivElement | null>(null);
  const boundingRectangle = useBoundingRectangle(ref);

  return (
    <div
      ref={(instance) => {
        if (instance) {
          setRef(instance);
        }
      }}
    >
      {children(boundingRectangle)}
    </div>
  );
};
