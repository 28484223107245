import { PaymentCore } from "@springtree/eva-services-payment-core";

import { createServiceQuery } from "~/util/query";

// GetAvailablePinHandlers
export const {
  serviceLoaderQuery: getAvailablePinHandlersLoaderQuery,
  serviceQuery: getAvailablePinHandlersQuery,
  serviceQueryKeys: getAvailablePinHandlersQueryKeys,
  useServiceQueryHook: useGetAvailablePinHandlersQuery,
} = createServiceQuery(PaymentCore.GetAvailablePinHandlers, false);
