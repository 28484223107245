import { Checkbox as LyraCheckbox, CheckboxProps as LyraCheckboxProps } from "@new-black/lyra";
import { Field, FieldProps } from "formik";

import { Checkbox, CheckboxProps } from "~/components/ui/checkbox";

interface IFormikCheckboxProps
  extends Omit<CheckboxProps, "value" | "onChange" | "defaultChecked"> {
  name: string;
  CheckboxProps?: {
    name: string;
  };
  onChange?: (newValue: boolean) => void;
}

export const FormikCheckboxV2 = (props: IFormikCheckboxProps) => (
  <Field name={props.name}>
    {({ field, form }: FieldProps) => (
      <Checkbox
        {...props}
        {...field}
        name={props.CheckboxProps?.name ?? props.name}
        value={field.value ?? false}
        onChange={(newValue) => {
          form.setFieldValue(field.name, newValue, form.validateOnChange);
          props.onChange?.(newValue);
        }}
        onBlur={(event) => {
          props?.onBlur?.(event);
          field.onBlur(event);
        }}
      />
    )}
  </Field>
);

interface ILyraFormikCheckboxProps
  extends Omit<LyraCheckboxProps, "value" | "onChange" | "defaultChecked"> {
  name: string;
}

const LyraFormikCheckboxV2 = (props: ILyraFormikCheckboxProps) => (
  <Field name={props.name}>
    {({ field, form }: FieldProps) => (
      <LyraCheckbox
        {...props}
        {...field}
        value={field?.value ?? false}
        isSelected={field?.value ?? false}
        onChange={(newValue) => {
          form.setFieldValue(field?.name, newValue, form.validateOnChange);
        }}
        onBlur={(event) => {
          props?.onBlur?.(event);
          field?.onBlur(event);
        }}
      />
    )}
  </Field>
);

FormikCheckboxV2.Lyra = LyraFormikCheckboxV2;
