import { useCallback, useEffect, useState } from "react";

import classNames from "clsx";

import DragDropContainer from "../drag-drop-container";
import DraggableItem from "../draggable-item";

import DraggableCard from "./draggable-card";
import OverlayedDraggableCard from "./overlayed-draggable-card";

import usePrevious from "~/hooks/suite-react-hooks/use-previous";

export interface IOrderableCard {
  label: string;
  value: string;
}

export interface IDraggableCardsProps {
  cards: IOrderableCard[];
  setCards: (newCards: IOrderableCard[]) => void;
  allCards: IOrderableCard[];
  showOrderableCards: boolean;
  removeCardTooltip?: string;
  addCardTooltip?: string;
  disableBottomPadding?: boolean;
}

const DraggableCards = ({
  addCardTooltip,
  allCards,
  cards,
  disableBottomPadding,
  removeCardTooltip,
  setCards,
  showOrderableCards,
}: IDraggableCardsProps) => {
  const [items, setItems] = useState(
    allCards.map((card) => ({
      ...card,
      isDisabled: cards?.findIndex((activeCard) => activeCard.value === card?.value) === -1,
    })),
  );

  // Update local state when changes are made outside this component
  useEffect(() => {
    setItems(
      allCards.map((card) => ({
        ...card,
        isDisabled: cards?.findIndex((activeCard) => activeCard.value === card?.value) === -1,
      })),
    );
  }, [allCards, cards]);

  const toggleVisible = useCallback((item: any) => {
    setItems((previous) =>
      previous.map((previousItem) => {
        if (previousItem.value === item.value) {
          return { ...previousItem, isDisabled: !previousItem.isDisabled };
        }

        return previousItem;
      }),
    );
  }, []);

  const previousShowOrderableCards = usePrevious<boolean>(showOrderableCards);

  useEffect(() => {
    if (previousShowOrderableCards && !showOrderableCards) {
      setCards(items.filter((item) => !item.isDisabled));
    }
  }, [showOrderableCards, previousShowOrderableCards, setCards, items]);

  return (
    <>
      {showOrderableCards ? (
        <DragDropContainer
          items={items ?? []}
          setItems={setItems}
          direction="vertical"
          OverlayComponent={OverlayedDraggableCard}
        >
          <div className={classNames("flex flex-col", !disableBottomPadding && "pb-5")}>
            {items.map((item) => (
              <DraggableItem
                key={item.value}
                draggableId={item.value}
                ItemComponent={DraggableCard}
                itemComponentProps={{
                  label: item.label,
                  toggleVisible: () => toggleVisible(item),
                  isDisabled: item.isDisabled,
                  removeCardTooltip,
                  addCardTooltip,
                }}
              />
            ))}
          </div>
        </DragDropContainer>
      ) : null}
    </>
  );
};

export default DraggableCards;
