import { memo, useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { NumberField, Table, TableColumnDef } from "@new-black/lyra";

import { DenominationTableProps } from "./denomination.types";

import Amount from "~/components/suite-ui/amount";
import { INT32MAX } from "~/util/base-values";
import { NUMBER_FORMAT_OPTIONS } from "~/util/number-format-options";

const DenominationTable = memo(
  ({
    autoFocusFirstInput,
    currencyID,
    denominations,
    updateDenominations,
  }: DenominationTableProps) => {
    const intl = useIntl();

    const data = useMemo(
      () =>
        Object.entries(denominations)
          .map(([key, value]) => ({ key, value }))
          .sort((a, b) => Number(a.key) - Number(b.key)),
      [denominations],
    );

    const updateRow = useCallback(
      (key: string, value: number) => {
        updateDenominations?.({ ...denominations, [key]: value });
      },
      [denominations, updateDenominations],
    );

    const calculateTotal = (key: string, value: number) => {
      const floatNumber = parseFloat(key);
      const total = floatNumber * value;
      return total;
    };

    const columns = useMemo<
      TableColumnDef<{
        key: string;
        value: number;
      }>[]
    >(
      () => [
        {
          header: intl.formatMessage({ id: "generic.label.amount", defaultMessage: "Amount" }),
          id: "Amount",
          width: 150,
          cell: ({ row }) =>
            currencyID ? (
              <Amount value={parseFloat(row.original.key)} currency={currencyID} />
            ) : (
              parseFloat(row.original.key)
            ),
        },
        {
          header: intl.formatMessage({ id: "generic.label.quantity", defaultMessage: "Quantity" }),
          id: "Quantity",
          width: 200,
          cellType: "button",
          cell: ({ row }) => {
            return updateDenominations ? (
              <NumberField
                aria-label={intl.formatMessage({
                  id: "generic.label.denomination-table-quantity",
                  defaultMessage: "Denomination table quantity",
                })}
                className="w-[200px]"
                fullWidth
                hideStepper
                formatOptions={NUMBER_FORMAT_OPTIONS.integer}
                minValue={0}
                maxValue={INT32MAX}
                hideHintLabel
                autoFocus={row.index === 0 && autoFocusFirstInput}
                value={row.original.value ?? ""}
                onChange={(value) => {
                  if (value) {
                    updateRow(row.original.key, value);
                  } else {
                    updateRow(row.original.key, 0);
                  }
                }}
              />
            ) : (
              row.original.value ?? 0
            );
          },
        },
        {
          header: intl.formatMessage({ id: "generic.label.total", defaultMessage: "Total" }),
          id: "Total",
          align: "end",
          cell: ({ row }) => {
            return currencyID ? (
              <Amount
                value={calculateTotal(row.original.key, row.original.value)}
                currency={currencyID}
              />
            ) : (
              calculateTotal(row.original.key, row.original.value)
            );
          },
        },
      ],
      [autoFocusFirstInput, currencyID, intl, updateDenominations, updateRow],
    );

    return (
      <Table
        aria-label={intl.formatMessage({
          id: "generic.label.financial-periods.denomination-table",
          defaultMessage: "Denomination table",
        })}
        data={data}
        columns={columns}
        options={{ hidePagination: true }}
        total={data.length}
      />
    );
  },
);
DenominationTable.displayName = "DenominationTable";

export default DenominationTable;
