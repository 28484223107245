import { useCallback, useMemo, useState } from "react";
import { IntlShape, useIntl } from "react-intl";

import { TextField, TextFieldArea } from "@new-black/lyra";
import { isNil } from "lodash";

import { ICustomFieldConsumerProps } from "../custom-field-consumer.types";

import { LineIcon, MultiLineIcon } from "~/assets/icons/components/custom-field-icons";
import Input from "~/components/suite-ui/input";

interface ICustomStringFieldConsumerProps extends ICustomFieldConsumerProps<string | undefined> {
  minLength?: number;
  maxLength?: number;
  multiline?: boolean;
  minLengthMessage?: string;
  maxLengthMessage?: string;
  outOfRangeMessage?: string;
}

interface ICustomStringFieldValidatorOptions {
  required?: boolean;
  minLength?: number;
  maxLength?: number;
  requiredMessage?: string;
  minLengthMessage?: string;
  maxLengthMessage?: string;
  outOfRangeMessage?: string;
  validateNullishValues?: boolean;
}

const validateSingleStringValue =
  (intl: IntlShape) =>
  (
    valueToValidate: string | undefined,
    {
      maxLength,
      maxLengthMessage,
      minLength,
      minLengthMessage,
      outOfRangeMessage,
      required,
      requiredMessage,
      validateNullishValues,
    }: ICustomStringFieldValidatorOptions,
  ) => {
    if (required && isNil(valueToValidate)) {
      return (
        requiredMessage ??
        intl.formatMessage({
          id: "validation.required",
          defaultMessage: "This field is required",
        })
      );
    }

    if (!isNil(valueToValidate) || validateNullishValues) {
      if (
        minLength !== undefined &&
        maxLength !== undefined &&
        (validateNullishValues ||
          (valueToValidate &&
            (valueToValidate?.length < minLength || valueToValidate?.length > maxLength)))
      ) {
        return (
          outOfRangeMessage ??
          intl.formatMessage(
            {
              id: "validation.charactersRange",
              defaultMessage: "Value must be between {min} and {max} characters long",
            },
            { min: minLength, max: maxLength },
          )
        );
      }

      if (
        minLength !== undefined &&
        (validateNullishValues || (valueToValidate && valueToValidate?.length < minLength))
      ) {
        return (
          minLengthMessage ??
          intl.formatMessage(
            {
              id: "validation.min-length",
              defaultMessage: "The value must be at least {min} characters long",
            },
            { min: minLength },
          )
        );
      }

      if (
        maxLength !== undefined &&
        (validateNullishValues || (valueToValidate && valueToValidate?.length > maxLength))
      ) {
        return (
          maxLengthMessage ??
          intl.formatMessage(
            {
              id: "validation.max-length",
              defaultMessage: "The value must be at most {max} characters long",
            },
            { max: maxLength },
          )
        );
      }
    }

    return undefined;
  };

const validateMultiStringValue =
  (intl: IntlShape) =>
  (
    valueToValidate: (string | undefined)[] | undefined,
    options: ICustomStringFieldValidatorOptions,
  ) => {
    const validateSingleValue = validateSingleStringValue(intl);
    return valueToValidate?.map?.((value) => validateSingleValue(value, options));
  };

const validateStringValue =
  (intl: IntlShape) =>
  (
    valueToValidate: string | (string | undefined)[] | undefined,
    options: ICustomStringFieldValidatorOptions,
  ) => {
    if (typeof valueToValidate === "string" || typeof valueToValidate === "undefined") {
      return validateSingleStringValue(intl)(valueToValidate, options);
    }
    if (Array.isArray(valueToValidate)) {
      return validateMultiStringValue(intl)(valueToValidate, options);
    }
    return undefined;
  };

export const CustomStringFieldConsumer = ({
  alwaysShowValidationMessage,
  componentVariant,
  error: parentError,
  label,
  maxLength,
  maxLengthMessage,
  minLength,
  minLengthMessage,
  multiline,
  name,
  onChange,
  outOfRangeMessage,
  placeholder,
  required,
  requiredMessage,
  touched: parentTouched,
  value,
}: ICustomStringFieldConsumerProps) => {
  const intl = useIntl();

  const [touched, setTouched] = useState(parentTouched ?? false);

  const validateValue = useCallback(
    (valueToValidate: string | undefined, validateNullishValues?: boolean) =>
      validateStringValue(intl)(valueToValidate, {
        required,
        minLength,
        maxLength,
        requiredMessage,
        minLengthMessage,
        maxLengthMessage,
        outOfRangeMessage,
        validateNullishValues,
      }),
    [
      intl,
      maxLength,
      maxLengthMessage,
      minLength,
      minLengthMessage,
      outOfRangeMessage,
      required,
      requiredMessage,
    ],
  );

  const error = useMemo(() => validateValue(value), [validateValue, value]);
  const helperText = useMemo(
    () => validateValue(value, alwaysShowValidationMessage),
    [alwaysShowValidationMessage, validateValue, value],
  );

  if (componentVariant === "lyra") {
    if (multiline) {
      return (
        <TextFieldArea
          hideHintLabel
          name={name}
          label={label}
          placeholder={placeholder}
          value={value ?? ""}
          onBlur={() => setTouched(true)}
          errorMessage={
            parentError ??
            (touched || parentTouched || alwaysShowValidationMessage
              ? (helperText as string)
              : undefined)
          }
          onChange={(newValue) => {
            onChange(newValue || undefined, validateValue(newValue || undefined) as string);
          }}
        />
      );
    }

    return (
      <TextField
        hideHintLabel
        name={name}
        label={label}
        placeholder={placeholder}
        value={value ?? ""}
        onBlur={() => setTouched(true)}
        errorMessage={
          parentError ??
          (touched || parentTouched || alwaysShowValidationMessage
            ? (helperText as string)
            : undefined)
        }
        onChange={(newValue) => {
          onChange(newValue || undefined, validateValue(newValue || undefined) as string);
        }}
      />
    );
  }

  return (
    <Input
      name={name}
      label={label}
      placeholder={placeholder}
      required={required}
      value={value ?? ""}
      multiline={multiline}
      error={parentError ? !!parentError : (touched || parentTouched) && !!error}
      onBlur={() => setTouched(true)}
      helperText={
        parentError ??
        (touched || parentTouched || alwaysShowValidationMessage ? helperText : undefined)
      }
      onChange={(e) => {
        const newValue = e.target.value === "" ? undefined : e.target.value;
        onChange(newValue, validateValue(newValue) as string);
      }}
      endIcon={
        multiline ? (
          <MultiLineIcon fontSize="small" className="-mr-4" />
        ) : (
          <LineIcon fontSize="small" />
        )
      }
    />
  );
};

CustomStringFieldConsumer.validate = validateStringValue;
