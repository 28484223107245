import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";
import { useQuery } from "@tanstack/react-query";

import { IRole } from "~/features/users/permissions-card/permissions-card.types";
import {
  getRoleServiceQuery,
  getRolesForOrganizationUnitSetQuery,
  listRolesQuery,
} from "~/models/roles";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";
import { SearchListFieldGenerator } from "~/util/search-list-field-generator";

export const generateRoleSearchListFieldWithFilters = (
  filters?: Partial<EVA.Core.ListRolesFilter>,
) => {
  const {
    MultiIDSearchListField: GeneratedMultiRoleIDSearchListFieldWithFilters,
    SingleIDSearchListField: GeneratedSingleRoleIDSearchListFieldWithFilters,
  } = SearchListFieldGenerator<
    CoreManagement.ListRoles,
    {
      ID: number;
      Name: string;
    },
    "ID"
  >({
    idKey: "ID",
    labelKey: "Name",
    getItemFromResponse: (resp) => resp?.Result?.Page?.map((ou) => ({ ID: ou.ID, Name: ou.Name })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.role",
      defaultMessage: "Role",
    }),
    useItemByID: (id, currentItems) => {
      const currentRole = useMemo(
        () => currentItems?.find((role) => role.ID === id),
        [currentItems, id],
      );

      const { data, isFetching: isLoading } = useQuery({
        ...getRoleServiceQuery(id ? { ID: id } : undefined),
        enabled: !currentRole && !!id,
      });

      return {
        data:
          currentRole ??
          (id && data?.Result?.Name ? { ID: id, Name: data?.Result.Name } : undefined),
        isLoading,
      };
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        refetchOnFocus: false,
        query: listRolesQuery,
        initialRequest: {
          PageConfig: {
            Start: 0,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: { ...filters },
          },
        },
        getQueryRequest: (req) => (req?.PageConfig?.Filter as any)?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
      }),
  });

  return {
    GeneratedSingleRoleIDSearchListFieldWithFilters,
    GeneratedMultiRoleIDSearchListFieldWithFilters,
  };
};

export const generateRoleForOUSetSearchListField = (
  organizationUnitSetID?: number,
  enabled?: boolean,
) => {
  return LyraSearchListFieldGenerator<CoreManagement.GetRolesForOrganizationUnitSet, IRole, number>(
    {
      getLabel: (item) => item.RoleName!,
      getItemId: (item) => item.RoleID!,
      getItemFromResponse: (response) =>
        response?.Roles?.map((role) => ({
          RoleName: role.Name,
          RoleID: role.ID,
          UserType: role.UserType,
        })),
      defaultLabel: intlAccessor.formatMessage({
        id: "generic.label.role",
        defaultMessage: "Role",
      }),
      useItemsByID: (ids, currentItems) =>
        useMemo(
          () => ({
            data: currentItems?.filter((role) => ids?.includes(role.RoleID!)),
          }),
          [currentItems, ids],
        ),
      useServiceQuery: () =>
        LyraSearchListFieldGenerator.useSearchListFieldService({
          enabled,
          refetchOnFocus: false,
          query: getRolesForOrganizationUnitSetQuery,
          initialRequest: organizationUnitSetID
            ? { OrganizationUnitSetID: organizationUnitSetID }
            : undefined,
        }),
    },
  );
};

export const generateRoleLyraSearchListField = (filters?: Partial<EVA.Core.ListRolesFilter>) => {
  return LyraSearchListFieldGenerator<
    CoreManagement.ListRoles,
    { Name: string; ID: number },
    number
  >({
    getLabel: (item) => item.Name,
    getItemId: (item) => item.ID,
    getItemFromResponse: (response) =>
      response?.Result?.Page?.map((role) => ({ Name: role.Name, ID: role.ID })),
    useItemByID: (id, currentItems) => {
      const currentRole = useMemo(
        () => currentItems?.find((role) => role.ID === id),
        [currentItems, id],
      );

      const { data, isFetching: isLoading } = useQuery({
        ...getRoleServiceQuery(id ? { ID: id } : undefined),
        enabled: !currentRole && !!id,
      });

      return {
        data:
          currentRole ??
          (id && data?.Result?.Name ? { ID: id, Name: data?.Result.Name } : undefined),
        isLoading,
      };
    },
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.role",
      defaultMessage: "Role",
    }),
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        query: listRolesQuery,
        refetchOnFocus: false,
        initialRequest: {
          PageConfig: {
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Start: 0,
            Filter: { ...filters },
          },
        },
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
      }),
  });
};
