import { useIntl } from "react-intl";

import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  KeyValueRow,
  KeyValueRowList,
} from "@new-black/lyra";

import DeleteActionButton from "~/components/shared/action-buttons/delete-action-button";
import EditActionButton from "~/components/shared/action-buttons/edit-action-button";
import LoadingStateBox from "~/components/shared/loading-state-box";
import { useGetProductSearchTemplateByIDQuery } from "~/models/product-sets";

export const LoyaltyProgramProductLimitationDataProductSearchTemplateCard = ({
  onDelete,
  onEdit,
  productSearchTemplateID,
}: {
  productSearchTemplateID: number;
  onEdit?: () => void;
  onDelete?: () => void;
}) => {
  const intl = useIntl();

  const {
    data: productSearchTemplate,
    isLoading: isProductSearchTemplateLoadingWithoutPreviousResponse,
  } = useGetProductSearchTemplateByIDQuery(
    { ID: productSearchTemplateID },
    { loaderKey: [productSearchTemplateID] },
  );

  return (
    <Card>
      <CardHeader
        title={intl.formatMessage({
          id: "generic.label.product-search-template",
          defaultMessage: "Product search template",
        })}
        actions={
          onEdit || onDelete ? (
            <CardActions>
              {onDelete ? <DeleteActionButton onPress={onDelete} /> : null}

              {onEdit ? <EditActionButton onPress={onEdit} /> : null}
            </CardActions>
          ) : undefined
        }
      />

      {isProductSearchTemplateLoadingWithoutPreviousResponse ? (
        <LoadingStateBox limit={1} />
      ) : (
        <CardContent>
          <KeyValueRowList>
            <KeyValueRow
              label={intl.formatMessage({ id: "generic.label.name", defaultMessage: "Name" })}
            >
              {productSearchTemplate?.Name}
            </KeyValueRow>
          </KeyValueRowList>
        </CardContent>
      )}
    </Card>
  );
};
