import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { getFunctionalityCategoriesQuery } from "~/models/functionalities";
import { ONE_HOUR } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";
import { ISearchListFieldGeneratorProps } from "~/util/lyra-search-list-field-generator/search-list-field-generator.types";

export type FunctionalityCategorySearchListFieldItem = {
  ID: string;
  Name: string;
};

type BaseSearchListFieldGeneratorProps = ISearchListFieldGeneratorProps<
  CoreManagement.GetFunctionalityCategories,
  FunctionalityCategorySearchListFieldItem,
  string
>;

export const generateFunctionalityCategoryLyraSearchListField = ({
  frontendFilter,
}: {
  frontendFilter?: BaseSearchListFieldGeneratorProps["frontendFilter"];
}) =>
  SearchListFieldGenerator<
    CoreManagement.GetFunctionalityCategories,
    FunctionalityCategorySearchListFieldItem,
    string
  >({
    getItemFromResponse: (response) =>
      response?.Categories?.map((category) => ({
        ID: category,
        Name: category,
      })) ?? [],
    getItemId: (item) => item.Name,
    getLabel: (item) => item.Name,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.functionality-category",
      defaultMessage: "Functionality category",
    }),
    useItemByID: (name, itemsFromList) => {
      const item = useMemo(
        () => itemsFromList?.find((item) => item.Name === name),
        [name, itemsFromList],
      );
      return { data: item, isLoading: false };
    },
    useItemsByID: (names, itemsFromList) => {
      const items = useMemo(
        () =>
          names
            ?.map((name) => itemsFromList?.find((item) => item.Name === name))
            ?.filter((item): item is FunctionalityCategorySearchListFieldItem => !!item),
        [names, itemsFromList],
      );
      return { data: items, isLoading: false };
    },
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: getFunctionalityCategoriesQuery,
        initialRequest: {},
        refetchOnFocus: false,
        staleTime: ONE_HOUR,
      }),
    frontendFilter,
  });

export const GeneratedFunctionalityCategoryLyraSearchListField =
  generateFunctionalityCategoryLyraSearchListField({});
