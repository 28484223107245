import { CSSProperties, useMemo, useState } from "react";

import {
  SEARCH_LIST_FIELD_COMBOBOX_INPUT_FIELD,
  SEARCH_LIST_FIELD_COMBOBOX_OPTION_HEIGHT,
  SEARCH_LIST_FIELD_COMBOBOX_OPTIONS_HEIGHT,
  SEARCH_LIST_FIELD_SPACE_BETWEEN_BUTTON_AND_POPOVER,
} from "./types";

import { useBoundingRectangleV2 } from "~/hooks/suite-react-hooks/use-bounding-rectangle-v2";
import { useBoundingRectangleWithScroll } from "~/hooks/suite-react-hooks/use-bounding-rectangle-with-scroll";
import useEventListener from "~/hooks/suite-react-hooks/use-event-listener";

export const useSearchListFieldPopoverPositioning = (
  buttonRef: React.MutableRefObject<HTMLButtonElement | null>,
) => {
  const { bottom, left, right, top } = useBoundingRectangleV2(buttonRef.current);
  const { bottom: bottomWithScroll, top: topWithScroll } = useBoundingRectangleWithScroll(
    buttonRef.current,
  );

  // Trigger component update on resize in order to make the popover update its maximum height
  const [, setResizeCount] = useState(0);
  useEventListener("resize", () => setResizeCount((current) => current + 1));

  const possiblePopoverPlacement =
    bottomWithScroll +
      SEARCH_LIST_FIELD_SPACE_BETWEEN_BUTTON_AND_POPOVER +
      SEARCH_LIST_FIELD_COMBOBOX_INPUT_FIELD +
      SEARCH_LIST_FIELD_COMBOBOX_OPTION_HEIGHT * 3 +
      20 >
    window.scrollY + window.innerHeight
      ? "top"
      : "bottom";

  const maxHeightTopPositioning = Math.min(
    topWithScroll - window.scrollY - SEARCH_LIST_FIELD_COMBOBOX_INPUT_FIELD - 20,
    SEARCH_LIST_FIELD_COMBOBOX_OPTIONS_HEIGHT,
  );

  const maxHeightBottomPositioning = Math.min(
    window.scrollY +
      window.innerHeight -
      bottomWithScroll -
      SEARCH_LIST_FIELD_COMBOBOX_INPUT_FIELD -
      20,
    SEARCH_LIST_FIELD_COMBOBOX_OPTIONS_HEIGHT,
  );

  const styles = useMemo<Record<"wrapper" | "options", CSSProperties>>(
    () => ({
      wrapper: {
        position: "absolute",
        left,
        top:
          possiblePopoverPlacement === "bottom"
            ? bottom + SEARCH_LIST_FIELD_SPACE_BETWEEN_BUTTON_AND_POPOVER
            : undefined,
        bottom:
          possiblePopoverPlacement === "top"
            ? window.innerHeight - top + SEARCH_LIST_FIELD_SPACE_BETWEEN_BUTTON_AND_POPOVER
            : undefined,
        width: right - left,
      },
      options: {
        maxHeight:
          possiblePopoverPlacement === "bottom"
            ? `${maxHeightBottomPositioning}px`
            : `${maxHeightTopPositioning}px`,
        minHeight: `${SEARCH_LIST_FIELD_COMBOBOX_OPTION_HEIGHT}px`,
      },
    }),
    [
      left,
      possiblePopoverPlacement,
      bottom,
      top,
      right,
      maxHeightBottomPositioning,
      maxHeightTopPositioning,
    ],
  );

  return styles;
};
