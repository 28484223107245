import { IntlShape } from "react-intl";

import { GiftCardConfigurationUsageTypes } from "~/types/eva-core";

export const getGiftCardConfigurationUsageTypesTranslator = (intl: IntlShape) => {
  const translationMap: Record<EVA.Core.GiftCardConfigurationUsageTypes, string> = {
    [GiftCardConfigurationUsageTypes.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
    [GiftCardConfigurationUsageTypes.Payment]: intl.formatMessage({
      id: "generic.label.payment",
      defaultMessage: "Payment",
    }),
    [GiftCardConfigurationUsageTypes.Sale]: intl.formatMessage({
      id: "generic.label.sale",
      defaultMessage: "Sale",
    }),
  };

  return (status: EVA.Core.GiftCardConfigurationUsageTypes) => {
    return translationMap[status];
  };
};
