import { z } from "zod";

import { intlAccessor } from "~/util/intl-accessor";

export const setGlobalValidationErrorMap = () => {
  // create new error map with the latest intl instance translations
  const errorMap: z.ZodErrorMap = (issue, ctx) => {
    switch (issue.code) {
      case z.ZodIssueCode.invalid_type: {
        if (issue.expected !== "undefined" && issue.received === "undefined") {
          return {
            message: intlAccessor.formatMessage({
              id: "validation.required",
              defaultMessage: "This field is required",
            }),
          };
        }
        break;
      }
      case z.ZodIssueCode.invalid_date: {
        return {
          message: intlAccessor.formatMessage({
            id: "validation.invalid-date",
            defaultMessage: "Invalid date",
          }),
        };
      }
      case z.ZodIssueCode.too_big: {
        const maxValue = Number(issue.maximum) - (issue.inclusive ? 0 : 1);
        if (issue.type === "number") {
          return {
            message: intlAccessor.formatMessage(
              {
                id: "validation.max",
                defaultMessage: "The value must be at most {max}",
              },
              { max: maxValue },
            ),
          };
        }
        if (issue.type === "date") {
          return {
            message: intlAccessor.formatMessage(
              {
                id: "validation.max-date",
                defaultMessage: "The date must be before {max}",
              },
              { max: `${intlAccessor.formatDate(maxValue)} ${intlAccessor.formatTime(maxValue)}` },
            ),
          };
        }
        if (issue.type === "string") {
          return {
            message: intlAccessor.formatMessage(
              {
                id: "validation.max-length",
                defaultMessage: "The value can not exceed {max} characters",
              },
              { max: maxValue },
            ),
          };
        }
        break;
      }
      case z.ZodIssueCode.too_small: {
        const minValue = Number(issue.minimum) + (issue.inclusive ? 0 : 1);
        if (issue.type === "number") {
          return {
            message: intlAccessor.formatMessage(
              {
                id: "validation.min-value",
                defaultMessage: "The value must be at least {min}",
              },
              { min: Number(issue.minimum) },
            ),
          };
        }
        if (issue.type === "date") {
          return {
            message: intlAccessor.formatMessage(
              {
                id: "validation.min-date",
                defaultMessage: "The date must be after {min}",
              },
              { min: `${intlAccessor.formatDate(minValue)} ${intlAccessor.formatTime(minValue)}` },
            ),
          };
        }
        if (issue.type === "string") {
          return {
            message: intlAccessor.formatMessage(
              {
                id: "validation.min-length",
                defaultMessage: "The value must be at least {min} characters long",
              },
              { min: minValue },
            ),
          };
        }
        break;
      }
      case z.ZodIssueCode.invalid_string: {
        if (issue.validation === "email") {
          return {
            message: intlAccessor.formatMessage({
              id: "validation.email",
              defaultMessage: "The entered value is not a valid email address",
            }),
          };
        }
        break;
      }
      case z.ZodIssueCode.invalid_enum_value: {
        return {
          message: intlAccessor.formatMessage(
            {
              id: "validation.invalid-enum-option",
              defaultMessage: "Invalid option. Valid options are: {validOptions}.",
            },
            { validOption: issue.options.map((option) => `${option}`).join(", ") },
          ),
        };
      }
    }
    return { message: ctx.defaultError };
  };

  z.setErrorMap(errorMap);
};
