import { useEffect, useMemo, useState } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { uniq } from "lodash";
import { SerializableParam, useRecoilState, useResetRecoilState } from "recoil";

import {
  DEFAULT_INCLUDED_FIELDS,
  productAutocompleteRequestSelector,
  productAutocompleteResultSelector,
  productAutocompleteServiceState,
} from "./state";

import useDebounce from "~/hooks/suite-react-hooks/use-debounce";
import usePrevious from "~/hooks/suite-react-hooks/use-previous";

export interface IUseProductAutocomplete {
  includedFields?: string[];
  filters?: Partial<EVA.Core.SearchProducts["Filters"]>;
  familyKey: SerializableParam;
  selectedProductId?: number;
  maximumOptions?: number;
}

const useProductAutocomplete = ({
  familyKey,
  filters,
  includedFields,
  maximumOptions,
  selectedProductId,
}: IUseProductAutocomplete) => {
  const [inputValue, setInputValue] = useState<string>();
  const debouncedInputValue = useDebounce(inputValue, 500);
  const [request, setRequest] = useRecoilState(productAutocompleteRequestSelector(familyKey));
  const resetRequest = useResetRecoilState(productAutocompleteServiceState.request(familyKey));
  const products = hooks.useGetState(productAutocompleteResultSelector(familyKey));
  const isLoading = hooks.useIsLoading({
    state: productAutocompleteResultSelector(familyKey),
  });

  const includedFieldsWithDefaults = useMemo(
    () => uniq([...DEFAULT_INCLUDED_FIELDS].concat(includedFields ?? [])),
    [includedFields],
  );

  const previousSelectedId = usePrevious(selectedProductId);
  const previousDebouncedInputValue = usePrevious(debouncedInputValue);

  useEffect(() => {
    if (isLoading) {
      return;
    }

    const optionsIncludedSelectedProduct = products?.some(
      (x) => x.product_id === selectedProductId,
    );
    const selectedProductChanged = selectedProductId !== previousSelectedId;
    const shouldFilterOnSelectedProductId =
      selectedProductId &&
      (!request || (selectedProductChanged && !optionsIncludedSelectedProduct));

    if (shouldFilterOnSelectedProductId) {
      setRequest({
        IncludedFields: includedFieldsWithDefaults,
        Filters: {
          product_id: {
            Values: [selectedProductId],
          },
        },
        Query: undefined,
        PageSize: maximumOptions ?? 10,
      });
    } else if (
      !request ||
      !selectedProductId ||
      (debouncedInputValue && debouncedInputValue !== previousDebouncedInputValue)
    ) {
      setRequest({
        IncludedFields: includedFieldsWithDefaults,
        Filters: filters as EVA.Core.SearchProducts["Filters"],
        Query: debouncedInputValue || undefined,
        PageSize: maximumOptions ?? 10,
      });
    }
  }, [
    debouncedInputValue,
    filters,
    includedFieldsWithDefaults,
    isLoading,
    maximumOptions,
    previousSelectedId,
    products,
    request,
    selectedProductId,
    previousDebouncedInputValue,
    setRequest,
  ]);

  // reset the request on unmount
  useEffect(() => () => resetRequest(), [resetRequest]);

  return {
    products,
    isLoading,
    inputValue,
    setInputValue,
  };
};

export default useProductAutocomplete;
