import { SvgIcon } from "@new-black/lyra";
import classNames from "clsx";

export interface ICountIndicator {
  count?: number;
}
const CountIndicator = ({ count }: ICountIndicator) => {
  if (count || count === 0) {
    return (
      <div
        className={classNames(
          "absolute -right-1.5 -top-1.5 flex h-[13px] items-center justify-center rounded-full  text-center font-sans text-sm text-inverted",
          count < 9 ? " w-[13px]" : count < 99 ? "w-5" : "w-7",
          !count || count === 0 ? "bg-indication-green" : "bg-indication-red",
        )}
      >
        {!count || count === 0 ? <SvgIcon name="checkmark" /> : count > 99 ? "99+" : count}
      </div>
    );
  }
  return null;
};

export default CountIndicator;
