import { useCallback, useEffect, useMemo, useState } from "react";

import { IOrganizationUnitMenuItem } from "../organization-unit-selector.types";

import useOrganizationUnitStructure from "./use-organization-unit-structure";
import { useSelectedOrganizationUnit } from "./use-selected-organization-unit";

import usePrevious from "~/hooks/suite-react-hooks/use-previous";

const useOrganizationMenuItems = () => {
  const [menuItems, setMenuItems] = useState<IOrganizationUnitMenuItem[] | undefined>();
  const { selectedOrganizationUnit } = useSelectedOrganizationUnit();
  const { flattenedOrganizationUnits } = useOrganizationUnitStructure();

  const setMenuItemsFromSelectedOU = useCallback(() => {
    const OUs =
      flattenedOrganizationUnits?.filter(
        (ou) => ou.ParentID === selectedOrganizationUnit?.ParentID,
      ) ??
      flattenedOrganizationUnits?.filter((ou) => !ou.ParentID) ??
      flattenedOrganizationUnits?.filter((ou) => ou.ParentID === selectedOrganizationUnit?.ID) ??
      [];
    setMenuItems(
      OUs.map((x) => ({
        ID: x.ID,
        Name: x.Name,
        ParentID: x.ParentID,
        BackendID: x.BackendID,
        HasChildren: flattenedOrganizationUnits?.some((ou) => ou.ParentID === x.ID) ?? false,
      })),
    );
  }, [
    flattenedOrganizationUnits,
    selectedOrganizationUnit?.ID,
    selectedOrganizationUnit?.ParentID,
  ]);

  const currentMenuItemsLength = useMemo(() => menuItems?.length ?? 0, [menuItems]);
  const previousMenuItemsLength = usePrevious(currentMenuItemsLength);

  // Initialize menu items based on selected organization unit
  useEffect(() => {
    setMenuItemsFromSelectedOU();
  }, [setMenuItemsFromSelectedOU]);

  return {
    menuItems,
    setMenuItems,
    setMenuItemsFromSelectedOU,
    previousMenuItemsLength,
  };
};

export default useOrganizationMenuItems;
