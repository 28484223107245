import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

// Barcode
export const {
  serviceLoaderQuery: barcodeLoaderQuery,
  serviceQuery: barcodeQuery,
  serviceQueryKeys: barcodeQueryKeys,
  useServiceQueryHook: useBarcodeQuery,
} = createServiceQuery(Core.Barcode);
