import { useEffect, useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { useSetRecoilState } from "recoil";

import { productPropertyTypesList, searchProductPropertyTypesState } from "../state";

import { ALL_ROWS_LIMIT } from "~/util/base-values";

const useProductPropertyTypes = () => {
  const productPropertyTypes = hooks.useGetState(productPropertyTypesList);

  const productPropertyTypesResponseLoading = hooks.useIsLoading({
    state: productPropertyTypesList,
  });

  const productPropertyTypesResponseLoadingWithoutResponse = useMemo(
    () => !productPropertyTypes && productPropertyTypesResponseLoading,
    [productPropertyTypes, productPropertyTypesResponseLoading],
  );

  const setRequest = useSetRecoilState(searchProductPropertyTypesState.request);

  // set initials request
  //
  useEffect(() => {
    if (!productPropertyTypes) {
      setRequest({
        PageConfig: {
          Limit: ALL_ROWS_LIMIT,
        },
      });
    }
  }, [productPropertyTypes, setRequest]);

  return {
    productPropertyTypes,
    productPropertyTypesResponseLoading,
    productPropertyTypesResponseLoadingWithoutResponse,
  };
};

export default useProductPropertyTypes;
