import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import { SearchField } from "@new-black/lyra";

import SearchActionButton from "~/components/shared/action-buttons/search-action-button";
import useDebounce from "~/hooks/suite-react-hooks/use-debounce";

interface CustomQueryProps {
  query?: string;
  updateQuery: (value: string | undefined) => void;
}

export const LyraCustomQuery = ({ query, updateQuery }: CustomQueryProps) => {
  const intl = useIntl();
  const [showQueryField, setShowQueryField] = useState(false);
  const [localQuery, setLocalQuery] = useState(query ?? "");
  const debouncedQuery = useDebounce(localQuery, 500);

  useEffect(() => {
    const newQuery = debouncedQuery === "" ? undefined : debouncedQuery;
    updateQuery(newQuery);
  }, [debouncedQuery, updateQuery]);

  return showQueryField ? (
    <div className="min-w-[220px]">
      <SearchField
        hideHintLabel
        hideInputHeader
        label={intl.formatMessage({
          id: "composite.products-overview.list.search.input.placeholder",
          defaultMessage: "Search product",
        })}
        value={localQuery}
        onChange={setLocalQuery}
        onBlur={() => {
          if (!localQuery) {
            setShowQueryField(false);
          }
        }}
        autoFocus
      />
    </div>
  ) : (
    <SearchActionButton
      onPress={() => setShowQueryField(true)}
      tooltip={intl.formatMessage({
        id: "composite.products-overview.list.search.button",
        defaultMessage: "Search product",
      })}
    />
  );
};
