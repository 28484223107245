import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { useProductFilterContext } from "../product-filter-context";
import ProductOverviewModal from "../product-overview-modal";

import SearchActionButton from "~/components/shared/action-buttons/search-action-button";

/**
 * Component that can be used in order to trigger the products overview modal.
 */
export default function ProductOverviewAction({
  previewActionIcon,
  variant = "material",
}: {
  previewActionIcon?: "search" | "preview";
  variant?: "lyra" | "material";
}) {
  const intl = useIntl();

  const { productFilter, setOpenProductOverviewModal } = useProductFilterContext();

  const disableProductOverviewButton = useMemo(() => {
    const filters = productFilter ? Object.keys(productFilter) : [];
    return !filters?.length;
  }, [productFilter]);

  const openProductOverviewModal = useCallback(() => {
    if (!disableProductOverviewButton) {
      setOpenProductOverviewModal(true);
    }
  }, [disableProductOverviewButton, setOpenProductOverviewModal]);

  return (
    <>
      <SearchActionButton
        icon={previewActionIcon}
        isDisabled={disableProductOverviewButton}
        onPress={openProductOverviewModal}
        tooltip={intl.formatMessage({
          id: "product-filter.actions.show-selected-products",
          defaultMessage: "Show selected products",
        })}
      />
      <ProductOverviewModal variant={variant} />
    </>
  );
}
