import { Field, FieldProps } from "formik";

import { LyraUserTypeSelect } from "~/components/suite-composite/usertype-select";

interface IFormikUserTypeSelectProps {
  name: string;
  label?: string;
  multiple?: boolean;
}

export const FormikUserTypeSelect = ({ label, multiple, name }: IFormikUserTypeSelectProps) => (
  <Field name={name}>
    {({ field, form, meta }: FieldProps<number | undefined>) => (
      <LyraUserTypeSelect
        label={label}
        name={field.name}
        multiple={multiple}
        value={field.value}
        errorMessage={meta.touched ? meta.error : undefined}
        setValue={(newValue) => form.setFieldValue(field.name, newValue)}
      />
    )}
  </Field>
);

FormikUserTypeSelect.defaultProps = {
  label: undefined,
  multiple: undefined,
};
