import { forwardRef, useMemo } from "react";
import {
  generatePath,
  NavLink as RouterNavLink,
  NavLinkProps,
  Params,
  useLocation,
} from "react-router-dom";

import { usePrompt } from "./provider";

import { IRouteDefinition } from "~/types/route-definitions";

export interface DefinedRouteNavLinkProps extends Omit<NavLinkProps, "to"> {
  routeDefinition: IRouteDefinition;
  routeParams?: Params<string>;
  keepSearchParams?: boolean;
}

export const DefinedRouteNavLink = forwardRef<HTMLAnchorElement, DefinedRouteNavLinkProps>(
  ({ keepSearchParams, onClick, routeDefinition, routeParams, ...props }, ref) => {
    const { setConfirmRoute, when } = usePrompt();

    const location = useLocation();

    const path = useMemo(() => {
      const pathWithRouteParams = generatePath(routeDefinition.path, routeParams);
      return keepSearchParams ? `${pathWithRouteParams}${location.search}` : pathWithRouteParams;
    }, [keepSearchParams, location.search, routeDefinition.path, routeParams]);

    return (
      <RouterNavLink
        {...props}
        ref={ref}
        to={path}
        onClick={(e) => {
          if (when) {
            e?.preventDefault();
            setConfirmRoute(path);
          } else {
            onClick?.(e);
          }
        }}
      />
    );
  },
);

DefinedRouteNavLink.displayName = "NavLink";
