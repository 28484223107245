import { MutableRefObject, useCallback, useState } from "react";

import useWindowResize from "~/hooks/use-window-resize";

export interface IUseUpdateCalendarCellWidth {
  numberOfColumns: number;
  calendarRef: MutableRefObject<HTMLDivElement | null>;
}

const useCalendarCellWidth = ({ calendarRef, numberOfColumns }: IUseUpdateCalendarCellWidth) => {
  const [cellWidth, setCellWidth] = useState<number>();

  const updateWidth = useCallback(() => {
    const calendarWidth = calendarRef.current?.scrollWidth ?? 0;
    setCellWidth(calendarWidth / numberOfColumns);
  }, [calendarRef, numberOfColumns]);

  useWindowResize(updateWidth);

  return cellWidth;
};

export default useCalendarCellWidth;
