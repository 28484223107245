import { QueryClient } from "@tanstack/react-query";

import { intlAccessor } from "./intl-accessor";

import {
  CFCInferredFieldType,
  CFCTypedValueCustomField,
  ICFCTranslations,
} from "~/components/suite-composite/custom-field-consumer";
import { CustomDateFieldConsumer } from "~/components/suite-composite/custom-field-consumer/custom-field-data-types/custom-date-field-consumer";
import { CustomNumberFieldConsumer } from "~/components/suite-composite/custom-field-consumer/custom-field-data-types/custom-number-field-consumer";
import { CustomStringFieldConsumer } from "~/components/suite-composite/custom-field-consumer/custom-field-data-types/custom-string-field-consumer";
import { getCustomFieldLabel } from "~/components/suite-composite/custom-field-consumer/use-custom-field-label";
import { getAppConfigCustomFields } from "~/hooks/use-app-config-custom-fields";
import { ICustomFieldOptions } from "~/types/custom-field";

type ValidationResult =
  | { type: "success" }
  | { type: "error"; message: string | (string | undefined)[] | undefined };

const validationVerdictParser = (
  verdict: string | (string | undefined)[] | undefined,
  errorMessageFormatterFn: (message: string | undefined) => string | undefined = (message) =>
    message,
): ValidationResult => {
  if (verdict !== undefined) {
    if (typeof verdict === "string") {
      return {
        type: "error",
        message: errorMessageFormatterFn(verdict),
      };
    } else {
      const verdicts = verdict.map((entry) => errorMessageFormatterFn(entry));

      // Return success if we don't have any error message
      if (verdicts.every((entry) => entry === undefined)) {
        return { type: "success" } as const;
      }

      return {
        type: "error",
        message: verdicts,
      };
    }
  }

  return { type: "success" } as const;
};

export const validateCustomField =
  (queryClient: QueryClient) =>
  async (
    customFieldId?: number,
    organizationUnitId?: number,
    includeCustomFieldNameInError?: boolean,
    localCustomFieldOptions?: ICustomFieldOptions,
    omitNotFoundError?: boolean,
  ) => {
    const { getCustomFieldById } = await getAppConfigCustomFields(queryClient)(organizationUnitId);
    const customField = getCustomFieldById(customFieldId);

    if (!customField && !omitNotFoundError) {
      return () => ({ type: "error", message: "Custom field not found" } as const);
    }

    const customFieldLabel = await getCustomFieldLabel(queryClient)({
      customField,
      disableLabel: !(includeCustomFieldNameInError ?? false),
    });

    const parseValidationVerdict = (verdict: string | (string | undefined)[] | undefined) =>
      validationVerdictParser(
        verdict,
        includeCustomFieldNameInError
          ? (message: string | undefined) =>
              message ? `${customFieldLabel ?? customField?.Name}: ${message}` : undefined
          : undefined,
      );

    return (
      value: CFCInferredFieldType<CFCTypedValueCustomField>,
      translations?: ICFCTranslations,
    ): ValidationResult => {
      const customFieldOptions = localCustomFieldOptions ?? customField?.Options;

      switch (customField?.DataType) {
        case 1:
          return { type: "success" } as const;
        case 2:
        case 3:
          return parseValidationVerdict(
            CustomNumberFieldConsumer.validate(intlAccessor)(
              value as number | (number | undefined)[] | undefined,
              {
                min: customFieldOptions?.MinimumValue,
                max: customFieldOptions?.MaximumValue,
                required: customFieldOptions?.IsRequired,
                minMessage: translations?.minValueMessage,
                maxMessage: translations?.maxValueMessage,
                outOfRangeMessage: translations?.outOfRangeValueMessage,
                requiredMessage: translations?.requiredMessage,
              },
            ),
          );
        case 4:
          return { type: "success" } as const;
        case 6:
        case 7:
          return parseValidationVerdict(
            CustomDateFieldConsumer.validate(intlAccessor)(
              value as string | (string | undefined)[] | undefined,
              {
                minimum: customFieldOptions?.MinimumDate,
                maximum: customFieldOptions?.MaximumDate,
                required: customFieldOptions?.IsRequired,
                minimumMessage: translations?.minDateMessage,
                maximumMessage: translations?.maxDateMessage,
                requiredMessage: translations?.requiredMessage,
                outOfRangeMessage: translations?.outOfRangeDateMessage,
              },
            ),
          );
        case 0:
        case 5:
        default:
          return parseValidationVerdict(
            CustomStringFieldConsumer.validate(intlAccessor)(
              value as string | (string | undefined)[] | undefined,
              {
                minLength: customFieldOptions?.MinimumLength,
                maxLength: customFieldOptions?.MaximumLength,
                required: customFieldOptions?.IsRequired,
                minLengthMessage: translations?.minLengthMessage,
                maxLengthMessage: translations?.maxLengthMessage,
                requiredMessage: translations?.requiredMessage,
                outOfRangeMessage: translations?.outOfRangeLengthMessage,
              },
            ),
          );
      }
    };
  };

/**
 * Decodes a custom field value from the API to a value that can be used in a form.
 * @param value The custom field value from the API
 * @returns The decoded value
 * @example
 * decodeCustomFieldValue({ StringValue: "foo" }); // "foo"
 * decodeCustomFieldValue({ NumberValue: 42 }); // 42
 * decodeCustomFieldValue({ BoolValue: true }); // true
 * decodeCustomFieldValue({ DateTimeValue: "2021-01-01T00:00:00Z" }); // "2021-01-01T00:00:00Z"
 * decodeCustomFieldValue({ ArrayValues: [{ StringValue: "foo" }, { StringValue: "bar" }] }); // ["foo", "bar"]
 * decodeCustomFieldValue(); // undefined
 * decodeCustomFieldValue({}); // undefined
 */
export function decodeCustomFieldValue(
  value?: EVA.Core.CustomFieldValue,
): string | number | boolean | string[] | undefined {
  if (!value) {
    return undefined;
  }
  if (value.BoolValue !== undefined) {
    return value.BoolValue;
  }
  if (value.StringValue !== undefined) {
    return value.StringValue;
  }
  if (value.NumberValue !== undefined) {
    return value.NumberValue;
  }
  if (value.DateTimeValue !== undefined) {
    return value.DateTimeValue;
  }
  if (value.ArrayValues !== undefined) {
    return value.ArrayValues.map((arrayValue) => arrayValue.StringValue ?? "");
  }
  return undefined;
}

export const validateCustomFieldValuesObject =
  (queryClient: QueryClient) =>
  async (
    customFieldValuesObject: Record<number, EVA.Core.CustomFieldValue>,
    organizationUnitId?: number,
    includeCustomFieldNameInError?: boolean,
    localCustomFieldOptions?: ICustomFieldOptions,
    omitNotFoundError = false,
  ) => {
    const customFieldValidatorPromises = Object.keys(customFieldValuesObject)
      .map((key) => parseInt(key, 10))
      .map(async (customFieldId) => {
        const validator = await validateCustomField(queryClient)(
          customFieldId,
          organizationUnitId,
          includeCustomFieldNameInError,
          localCustomFieldOptions,
          omitNotFoundError,
        );
        return { validator, customFieldId };
      });
    const customFieldValidators = await Promise.all(customFieldValidatorPromises);
    const validationVerdicts = customFieldValidators.map((validationObject) => {
      const value = decodeCustomFieldValue(customFieldValuesObject[validationObject.customFieldId]);
      const validationResult = validationObject.validator(value);
      return {
        field: validationObject.customFieldId,
        error: validationResult.type === "error" ? validationResult.message : undefined,
      };
    });
    return validationVerdicts;
  };
