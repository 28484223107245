import { IntlShape } from "react-intl";

import { LoyaltyPaymentTaxHandlingType } from "~/types/eva-core";

export const getLoyaltyPaymentTaxHandlingTranslator = (
  intl: IntlShape,
): ((status: number) => string | undefined) => {
  const translationMap: Record<EVA.Core.LoyaltyPaymentTaxHandlingType | number, string> = {
    [LoyaltyPaymentTaxHandlingType.InTax]: intl.formatMessage({
      id: "generic.label.in-tax",
      defaultMessage: "In tax",
    }),
    [LoyaltyPaymentTaxHandlingType.UpToTax]: intl.formatMessage({
      id: "generic.label.up-to-tax",
      defaultMessage: "Up to tax",
    }),
  };

  return (status) => {
    return translationMap[status];
  };
};
