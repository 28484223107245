import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listProductRequirementsLoaderQuery,
  serviceQuery: listProductRequirementsQuery,
  serviceQueryKeys: listProductRequirementsQueryKeys,
  useServiceQueryHook: useListProductRequirementsQuery,
  useServiceQueryWithRequest: useListProductRequirementsQueryWithRequest,
} = createServiceQuery(CoreManagement.ListProductRequirements, true);

export const {
  serviceLoaderQuery: getProductRequirementByIDLoaderQuery,
  serviceQuery: getProductRequirementByIDQuery,
  serviceQueryKeys: getProductRequirementByIDQueryKeys,
  useServiceQueryHook: useGetProductRequirementByIDQuery,
} = createServiceQuery(CoreManagement.GetProductRequirementByID, true);
