import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Menu, MenuTrigger } from "@new-black/lyra";

import AddActionButton from "~/components/shared/action-buttons/add-action-button";

export interface ILoyaltyProgramProductLimitationDataAddAction {
  onAddProductSearchTemplate?: () => void;
  onAddProducts?: () => void;
  onAddProductFilters?: () => void;
  onAddProductsBulk?: () => void;
}

export const LoyaltyProgramProductLimitationDataAddAction = ({
  onAddProductFilters,
  onAddProducts,
  onAddProductsBulk,
  onAddProductSearchTemplate,
}: ILoyaltyProgramProductLimitationDataAddAction) => {
  const intl = useIntl();

  const menuItems = useMemo(() => {
    const result = [];

    if (onAddProductSearchTemplate) {
      result.push({
        id: "product-search-template",
        label: intl.formatMessage({
          id: "generic.label.add-product-search-template",
          defaultMessage: "Add product search template",
        }),
        action: onAddProductSearchTemplate,
      });
    }

    if (onAddProducts) {
      result.push({
        id: "products",
        label: intl.formatMessage({
          id: "generic.label.add-products",
          defaultMessage: "Add products",
        }),
        action: onAddProducts,
      });
    }

    if (onAddProductFilters) {
      result.push({
        id: "product-filters",
        label: intl.formatMessage({
          id: "generic.label.add-product-filters",
          defaultMessage: "Add product filters",
        }),
        action: onAddProductFilters,
      });
    }

    if (onAddProductsBulk) {
      result.push({
        id: "products-bulk",
        label: intl.formatMessage({
          id: "generic.label.add-products-in-bulk",
          defaultMessage: "Add products in bulk",
        }),
        action: onAddProductsBulk,
      });
    }

    return result;
  }, [intl, onAddProductFilters, onAddProductSearchTemplate, onAddProducts, onAddProductsBulk]);

  if (!menuItems.length) return null;

  return (
    <MenuTrigger>
      <AddActionButton />

      <Menu
        aria-label={intl.formatMessage({ id: "generic.label.add", defaultMessage: "Add" })}
        items={menuItems}
        getItemId={(item) => item.id}
        selectRenderElements={(item) => ({ label: item.label })}
        onAction={(key) => menuItems.find((item) => item.id === key)?.action()}
      />
    </MenuTrigger>
  );
};
