import { ReactNode } from "react";

import { ButtonGroup, Text } from "@new-black/lyra";
import { omit } from "lodash";

import { IEVAFilterArrayItem } from "../hooks/use-convert-filter-to-array";
import { ProductFilterProvider, useProductFilterContext } from "../product-filter-context";
import { DefaultProductFilterRowActions } from "../product-filter-row-actions";
import { IProductFilterTableProps } from "../table/product-filter-table";

import { LyraProductFilterAddProductsAction } from "./product-filter-add-products-action";
import ProductFilterList from "./product-filter-list";
import { LyraProductOverviewAction } from "./product-overview-action";

import WarningIcon from "~/components/shared/warning-icon";
import { ProductFilterModal } from "~/components/suite-composite/product-filter-modal";
import ErrorBoundary from "~/components/suite-ui/error-boundary";

/**
 * Component used to manage a product search filter. Renders a list of filters as well as actions that allow managing the list and previewing the search result.
 */
export const LyraProductFilterTable = ({
  customRowActions,
  customTableActions,
  disableAdd,
  disableDelete,
  disableEdit,
  error,
  placeholderImageUrl,
  productFilter,
  tableOptions,
  title,
  titleClassName,
  updateProductFilter,
  updateProductFilterAsync,
}: IProductFilterTableProps & {
  /** This will overwrite the default row actions */
  customRowActions?: (rowData: IEVAFilterArrayItem) => ReactNode;
  /** This will overwrite the default table actions */
  customTableActions?: ReactNode;
}) => (
  <ErrorBoundary>
    <ProductFilterProvider
      productFilter={productFilter}
      updateProductFilter={updateProductFilter}
      updateProductFilterAsync={updateProductFilterAsync}
      placeholderImageUrl={placeholderImageUrl}
      disableEdit={disableEdit}
      disableDelete={disableDelete}
      disableAdd={disableAdd}
      maximumDisplayedValuesInList={5}
      error={error}
    >
      <div className="p-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <Text variant="heading-2" className={titleClassName}>
              {title}
            </Text>

            {error ? <WarningIcon color="error" tooltipMessage={error} /> : null}
          </div>

          <ErrorBoundary>
            {customTableActions ?? (
              <ButtonGroup>
                <LyraProductOverviewAction />

                {disableAdd ? null : <LyraProductFilterAddProductsAction />}
              </ButtonGroup>
            )}
          </ErrorBoundary>
        </div>
      </div>
      <ErrorBoundary>
        <ProductFilterList
          tableOptions={tableOptions}
          customRowActions={
            customRowActions !== undefined
              ? (rowData) => customRowActions?.(rowData)
              : disableDelete && disableEdit
              ? undefined
              : (rowData) => DefaultProductFilterRowActions({ rowData, deleteModalVariant: "lyra" })
          }
        />
      </ErrorBoundary>

      <ProductFilterTableModal />
    </ProductFilterProvider>
  </ErrorBoundary>
);

const ProductFilterTableModal = () => {
  const {
    currentProductFilterModalValues,
    isUpdateProductFilterLoading,
    openProductFilterModal,
    productFilter,
    setOpenProductFilterModal,
    updateProductFilter,
  } = useProductFilterContext();

  return (
    <ProductFilterModal
      isOpen={openProductFilterModal}
      onOpenChange={setOpenProductFilterModal}
      initialValues={{
        ...currentProductFilterModalValues,
        PropertyName: currentProductFilterModalValues?.keyName,
      }}
      onChange={async (newFilter) => {
        if (newFilter.PropertyName) {
          await updateProductFilter({
            ...productFilter,
            [newFilter.PropertyName]: omit(newFilter, "PropertyName"),
          });

          setOpenProductFilterModal(false);
        }
      }}
      closeOnSave={false}
      isLoading={isUpdateProductFilterLoading}
    />
  );
};
