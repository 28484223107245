import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Dialog,
  Separator,
} from "@new-black/lyra";

import { IWorkspaceView } from "./types";

import Input from "~/components/suite-ui/input";

interface IAddWorkspaceModalState {
  workspaceName: string;
}

interface AddWorkspaceModalProps extends IWorkspaceView<IAddWorkspaceModalState> {
  labels?: {
    workspaceName?: string;
  };
}

const AddWorkspaceModalContent = ({
  isLoading,
  labels,
  onCancel,
  onConfirm,
  title,
}: AddWorkspaceModalProps) => {
  const intl = useIntl();

  const [workspaceName, setWorkspaceName] = useState<string>();

  return (
    <Card>
      <CardHeader
        title={
          title ??
          intl.formatMessage({
            id: "generic.label.save-as-new-workspace",
            defaultMessage: "Save as new workspace",
          })
        }
      />

      <CardContent>
        <Input
          required
          label={
            labels?.workspaceName ??
            intl.formatMessage({
              id: "generic.label.workspace-name",
              defaultMessage: "Workspace name",
            })
          }
          value={workspaceName ?? ""}
          onChange={(event) => setWorkspaceName(event.target.value)}
          autoFocus
        />
      </CardContent>

      <Separator />

      <ButtonGroup className="flex justify-end p-4">
        <Button variant="secondary" onPress={onCancel} isDisabled={isLoading}>
          <FormattedMessage id="generic.label.cancel" defaultMessage="cancel" />
        </Button>

        <Button
          variant="primary"
          isDisabled={!workspaceName || isLoading}
          isLoading={isLoading}
          onPress={() => workspaceName && onConfirm({ workspaceName })}
        >
          <FormattedMessage id="generic.label.save" defaultMessage="Save" />
        </Button>
      </ButtonGroup>
    </Card>
  );
};

const AddWorkspaceModal = (props: AddWorkspaceModalProps) => (
  <Dialog
    isOpen={props.open}
    onOpenChange={(open) => (open ? undefined : props.onCancel())}
    maxWidth="lg"
    aria-label="Add workspace"
  >
    <AddWorkspaceModalContent {...props} />
  </Dialog>
);

export default AddWorkspaceModal;
