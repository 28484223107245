import { useCallback, useEffect, useState } from "react";

import { noop } from "lodash";
import moment from "moment";

import DatePickerController from "../../suite-ui/date-picker/date-picker";

import DatePickerField from "~/components/suite-ui/date-picker/date-picker-field";

export interface IDatePicker {
  onChange: (value?: string) => void;
  value?: string;
  label?: string;
  invertDatePopupPosition?: boolean;
  placeholder?: string;
  small?: boolean;
  error?: boolean;
  helperText?: string;
  onBlur?: React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
}

const DatePicker = ({
  error,
  helperText,
  invertDatePopupPosition,
  label,
  onBlur,
  onChange,
  placeholder,
  small,
  value,
}: IDatePicker) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openPopover, setOpenPopover] = useState(false);
  const [localValue, setLocalValue] = useState(value);

  const handleChange = useCallback(
    (newValue?: string) => {
      setLocalValue(newValue);
      onChange(newValue);
    },
    [onChange],
  );

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  return (
    <div>
      <DatePickerField
        small={small}
        value={localValue ? moment(localValue).format("YYYY-MM-DD") : ""}
        placeholder={placeholder}
        label={label}
        clear={() => {
          handleChange(undefined);
        }}
        onClick={(event) => {
          if (event.currentTarget) {
            setAnchorEl(event.currentTarget);
          }
          setOpenPopover(true);
        }}
        onChange={(event) => handleChange(event.target.value)}
        fullWidth
        error={error}
        helperText={helperText}
        onBlur={onBlur}
      />
      <DatePickerController
        date={localValue ? moment(localValue) : null}
        onDateChange={(updatedValue) => {
          if (updatedValue) {
            handleChange(moment(updatedValue).format("YYYY-MM-DD"));
            setOpenPopover(false);
          }
        }}
        numberOfMonths={1}
        open={openPopover}
        setOpen={setOpenPopover}
        anchorEl={anchorEl}
        anchorOrigin={
          invertDatePopupPosition ? { vertical: "top", horizontal: "right" } : undefined
        }
        transformOrigin={
          invertDatePopupPosition ? { vertical: "bottom", horizontal: "right" } : undefined
        }
        focused={openPopover}
        onFocusChange={noop}
        id="date-picker"
      />
    </div>
  );
};

export default DatePicker;
