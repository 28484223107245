import { useRef } from "react";

import { useIsInViewport } from "~/util/hooks/use-is-in-viewport";

export const useSearchListFieldCombobox = () => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const optionsRef = useRef<HTMLUListElement | null>(null);
  const loadMoreButtonRef = useRef<HTMLButtonElement | null>(null);

  const topOfListRef = useRef<HTMLDivElement | null>(null);
  const isTopOfListReached = useIsInViewport(topOfListRef, true);

  const endOfListRef = useRef<HTMLDivElement | null>(null);
  const isEndOfListReached = useIsInViewport(endOfListRef);

  return {
    optionsRef,
    inputRef,
    loadMoreButtonRef,
    topOfListRef,
    isTopOfListReached,
    endOfListRef,
    isEndOfListReached,
  };
};
