import { FormattedMessage } from "react-intl";

import CompletedTasksIcon from "~/assets/icons/components/completed-tasks-icon";
import Text from "~/components/suite-ui/text";

const AvailableUserTasksEmptyView = () => {
  return (
    <div className="flex flex-1 flex-col items-center justify-center ">
      <CompletedTasksIcon className="text-7xl mb-5" />
      <Text variant="h2" className="mb-2.5 text-xl">
        <FormattedMessage id="generic.label.done-exclamation" defaultMessage="Done!" />
      </Text>
      <Text variant="body2" className="text-center">
        <FormattedMessage
          id="generic.label.no-user-tasks-available.message"
          defaultMessage="All done! There are no user tasks of this type for you."
        />
      </Text>
      <Text variant="body2" className="text-center">
        <FormattedMessage
          id="generic.label.not-what-you-expected.message"
          defaultMessage="Not what you expected? Please check if you have selected the correct store in the left pane."
        />
      </Text>
    </div>
  );
};

export default AvailableUserTasksEmptyView;
