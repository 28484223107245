import { useMemo } from "react";
import { useIntl } from "react-intl";

import { useCustomFields } from "~/hooks/use-custom-fields";
import { CustomFieldType } from "~/types/custom-field";
import { AutocompleteGenerator } from "~/util/autocomplete-generator";

export type TCustomFieldAutocompleteItem = EVA.Core.CustomFieldResponse;

export const useGenerateCustomFieldAutocomplete = (
  organizationUnitID: number | undefined,
  customFieldType?: CustomFieldType,
  filteredOutCustomFieldIDs?: number[],
) => {
  const intl = useIntl();
  const { customFields } = useCustomFields(customFieldType, organizationUnitID);

  const filteredCustomFields = useMemo(() => {
    if (!filteredOutCustomFieldIDs?.length) {
      return customFields;
    }

    return customFields?.filter((cf) => !filteredOutCustomFieldIDs.includes(cf.CustomFieldID));
  }, [customFields, filteredOutCustomFieldIDs]);

  const {
    MultiAutocomplete: CustomFieldMultiAutocomplete,
    MultiIDAutocomplete: CustomFIeldMultiIDAutocomplete,
    SingleAutocomplete: CustomFieldAutocomplete,
    SingleIDAutocomplete: CustomFieldSingleIDAutocomplete,
  } = AutocompleteGenerator.LocalAutocompleteGenerator({
    items: filteredCustomFields,
    idKey: "CustomFieldID",
    labelKey: "Name",
    defaultLabel: intl.formatMessage({
      id: "generic.label.custom-field",
      defaultMessage: "Custom field",
    }),
    useItemByID: (id, itemsFromList) => {
      const data = itemsFromList?.find((cf) => cf.CustomFieldID === id);

      return {
        data,
      };
    },
    useItemsByID(ids, itemsFromList) {
      const data = itemsFromList?.filter((cf) => ids?.includes(cf.CustomFieldID));

      return { data };
    },
  });

  return {
    CustomFieldMultiAutocomplete,
    CustomFieldAutocomplete,
    CustomFIeldMultiIDAutocomplete,
    CustomFieldSingleIDAutocomplete,
  };
};
