import { useMemo } from "react";
import { useIntl } from "react-intl";

import { CircularProgress } from "@material-ui/core";
import { SvgIcon } from "@new-black/lyra";

import SerialNumberWarrantyDataInformation from "./serial-number-warranty-data-information";

import Input, { InputProps } from "~/components/suite-ui/input";
import useDebounce from "~/hooks/suite-react-hooks/use-debounce";
import { useGetSettingQuery } from "~/models/settings";
import { ONE_MINUTE, ONE_SECOND } from "~/util/base-values";
import useProductSerialNumberAndWarranty from "~/util/hooks/use-product-serial-number-and-warranty";

export const SerialNumberInput = ({
  displayWarrantyInformation,
  onBlur,
  onChange,
  orderLineID,
  small,
  validateSerialNumber,
  value,
}: Required<Pick<InputProps, "value" | "onChange">> &
  Pick<InputProps, "onBlur"> & {
    requestDebounceTime?: number;
    displayWarrantyInformation?: boolean;
    orderLineID?: number;
    small?: boolean;
    validateSerialNumber?: boolean;
  }) => {
  const intl = useIntl();

  const debouncedValue = useDebounce(value as string | undefined, ONE_SECOND);

  const { data: productWarrantyEndpoint, isFetching: isProductWarrantyEndpointLoading } =
    useGetSettingQuery({ Key: "Product:WarrantyEndpoint" }, { staleTime: ONE_MINUTE });

  const {
    getProductWarrantyData,
    isLoading: isProductInformationLoading,
    verifySerialNumberData,
  } = useProductSerialNumberAndWarranty(
    debouncedValue,
    orderLineID,
    displayWarrantyInformation && !!productWarrantyEndpoint?.Value,
    validateSerialNumber,
  );

  const isLoading = useMemo(
    () => isProductWarrantyEndpointLoading || isProductInformationLoading,
    [isProductInformationLoading, isProductWarrantyEndpointLoading],
  );

  const EndIcon = useMemo(
    () =>
      isLoading ? (
        <CircularProgress color="inherit" size={20} />
      ) : verifySerialNumberData && !verifySerialNumberData?.Error ? (
        <SvgIcon name="alert-success" className="h-5 w-5 text-success" />
      ) : null,
    [isLoading, verifySerialNumberData],
  );

  const shouldDisplayWarrantyInformation = useMemo(
    () => !!displayWarrantyInformation && !!debouncedValue,
    [debouncedValue, displayWarrantyInformation],
  );

  return (
    <>
      <Input
        small={small}
        type="text"
        label={intl.formatMessage({
          id: "generic.label.serial-number",
          defaultMessage: "Serial number",
        })}
        value={value ?? ""}
        onChange={onChange}
        onBlur={onBlur}
        error={!!verifySerialNumberData?.Error?.Message}
        helperText={verifySerialNumberData?.Error?.Message}
        endIcon={EndIcon}
      />

      {displayWarrantyInformation ? (
        <div className="min-h-[2.25rem]">
          <SerialNumberWarrantyDataInformation
            isLoading={isLoading}
            hasWarrantyEndpoint={!productWarrantyEndpoint?.Value}
            shouldDisplayWarrantyInformation={shouldDisplayWarrantyInformation}
            productWarranty={getProductWarrantyData}
          />
        </div>
      ) : null}
    </>
  );
};
