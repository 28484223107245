import { useIntl } from "react-intl";

import { useFormikContext } from "formik";

import { IRequiredAmountRadioOption } from "./eva-product-set.types";

import RadioButtonList from "~/components/suite-ui/radio-button-list";
import RadioButtonLabel from "~/components/suite-ui/radio-button-list/radio-button-label";
import useIsDiscountEditDisabled from "~/features/discount-edit/hooks/use-is-discount-edit-disabled";

export interface IEvaProductSetRequiredAmountRadioButtonList {
  selectedOption: IRequiredAmountRadioOption;
  setSelectedOption: (newValue: IRequiredAmountRadioOption) => void;
}

const EvaProductSetRequiredAmountRadioButtonList = ({
  selectedOption,
  setSelectedOption,
}: IEvaProductSetRequiredAmountRadioButtonList) => {
  const intl = useIntl();

  const { setFieldValue } = useFormikContext();
  const isEditDiscountDisabled = useIsDiscountEditDisabled();

  return (
    <RadioButtonList
      name="type"
      label={intl.formatMessage({
        id: "generic.label.type",
        defaultMessage: "Type",
      })}
      selectedValue={selectedOption}
      handleSelectedValueChange={(_, value) => {
        if (selectedOption !== value) {
          setSelectedOption(value as IRequiredAmountRadioOption);

          // Clear previous amount input
          setFieldValue(
            selectedOption === IRequiredAmountRadioOption.QuantityOfProducts
              ? "requiredQuantityOfProducts"
              : "requiredAmountSpentOnProducts",
            "",
          );
        }
      }}
      disabled={isEditDiscountDisabled}
    >
      <RadioButtonLabel
        label={intl.formatMessage({
          id: "edit-discount.product-set.required-quantity",
          defaultMessage: "Required quantity of products",
        })}
        value={IRequiredAmountRadioOption.QuantityOfProducts}
        activeValue={selectedOption}
      />
      <RadioButtonLabel
        label={intl.formatMessage({
          id: "edit-discount.product-set.required-amount-spent",
          defaultMessage: "Required amount spent on products",
        })}
        value={IRequiredAmountRadioOption.AmountSpentOnProduct}
        activeValue={selectedOption}
      />
    </RadioButtonList>
  );
};

export default EvaProductSetRequiredAmountRadioButtonList;
