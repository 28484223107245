import { useCallback, useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Column, Row } from "react-table";

import { Badge } from "@new-black/lyra";

import AvailableUserTasksTablePrintStatusCell from "./available-user-tasks-table-print-status-cell";
import { AvailableUserTasksTableColumns, IAvailableUserTaskDto } from "./types";

import DeadlineTableCell from "~/components/shared/deadline-table-cell";
import { AvatarWithLabel } from "~/components/suite-ui/avatar";

const useAvailableUserTasksTableColumns = (columns: AvailableUserTasksTableColumns[]) => {
  const intl = useIntl();

  const getColumnDef = useCallback(
    (column: AvailableUserTasksTableColumns): Column<IAvailableUserTaskDto> | undefined => {
      switch (column) {
        case AvailableUserTasksTableColumns.OrderID:
          return {
            Header: intl.formatMessage({
              id: "generic.label.order-id",
              defaultMessage: "Order ID",
            }),
            accessor: (row) => row.Data?.OrderID,
            minWidth: 60,
          };
        case AvailableUserTasksTableColumns.RelatedOrderID:
          return {
            Header: intl.formatMessage({
              id: "generic.label.related-order-id",
              defaultMessage: "Related order ID",
            }),
            accessor: (row) => row.Data?.OrderID,
            minWidth: 60,
          };
        case AvailableUserTasksTableColumns.TaskID:
          return {
            Header: intl.formatMessage({
              id: "generic.label.task-id",
              defaultMessage: "Task ID",
            }),
            accessor: "ID",
            minWidth: 60,
          };

        case AvailableUserTasksTableColumns.Deadline:
          return {
            Header: intl.formatMessage({
              id: "generic.label.deadline",
              defaultMessage: "Deadline",
            }),
            accessor: "Deadline",
            minWidth: 80,
            Cell: ({ value }) => (value ? <DeadlineTableCell value={value} /> : <div />),
          };

        case AvailableUserTasksTableColumns.Urgency:
          return {
            Header: intl.formatMessage({
              id: "generic.label.urgency",
              defaultMessage: "Urgency",
            }),
            accessor: "HasUrgency",
            minWidth: 40,
            Cell: ({ value }) => (
              <div className="w-20">
                {value ? (
                  <Badge variant="red">
                    <FormattedMessage id="generic.label.yes" defaultMessage="Yes" />
                  </Badge>
                ) : (
                  <Badge variant="gray">
                    <FormattedMessage id="generic.label.no" defaultMessage="No" />
                  </Badge>
                )}
              </div>
            ),
          };

        case AvailableUserTasksTableColumns.Status:
          return {
            Header: intl.formatMessage({
              id: "generic.label.status",
              defaultMessage: "Status",
            }),
            accessor: (row) => row.User?.FullName,
            Cell: ({ value }: { value?: string }) => (
              <div className="w-20">
                {value ? (
                  <Badge variant="yellow">
                    <FormattedMessage id="generic.label.in-progress" defaultMessage="In progress" />
                  </Badge>
                ) : (
                  <Badge variant="blue">
                    <FormattedMessage id="generic.label.to-do" defaultMessage="To do" />
                  </Badge>
                )}
              </div>
            ),
          };

        case AvailableUserTasksTableColumns.Description:
          return {
            Header: intl.formatMessage({
              id: "generic.label.description",
              defaultMessage: "Description",
            }),
            accessor: "Description",
            minWidth: 500,
            Cell: ({ value }) => <span>{value || "-"} </span>,
          };

        case AvailableUserTasksTableColumns.SpecialProducts:
          return {
            Header: intl.formatMessage({
              id: "generic.label.special-products",
              defaultMessage: "Special products",
            }),
            accessor: (row) => row.Data?.ProductMarkerValue,
            Cell: ({ value }: { value: string }) => <span>{value || "-"} </span>,
          };

        case AvailableUserTasksTableColumns.AssigneeAvatar:
          return {
            Header: intl.formatMessage({
              id: "generic.label.assignee",
              defaultMessage: "Assignee",
            }),
            accessor: "User",
            minWidth: 100,
            Cell: ({ value }) => (
              <div className="py-[0.3125rem]">
                <AvatarWithLabel label={value?.FullName} />
              </div>
            ),
          };

        case AvailableUserTasksTableColumns.Assignee:
          return {
            Header: intl.formatMessage({
              id: "generic.label.assignee",
              defaultMessage: "Assignee",
            }),
            accessor: "User",
            minWidth: 100,
            Cell: ({ value }) => <span>{value?.FullName || "-"}</span>,
          };

        case AvailableUserTasksTableColumns.Label:
          return {
            Header: intl.formatMessage({
              id: "generic.label.label",
              defaultMessage: "Label",
            }),
            id: "labels",
            accessor: (row) => row.Labels,
            Cell: ({ value }: { value?: string[] }) => <span>{value?.join(", ") || "-"} </span>,
          };

        case AvailableUserTasksTableColumns.NumberOfProducts:
          return {
            Header: intl.formatMessage({
              id: "generic.label.number-of-products",
              defaultMessage: "Number of products",
            }),
            id: "quantity",
            accessor: (row) => row.Data?.Quantity,
            Cell: ({ value }: { value?: number }) => <span>{value ?? "-"} </span>,
          };

        // TODO: revisit this when BE changes are ready as the required data is not available yet
        // case AvailableUserTasksTableColumns.ShipmentID:
        //   return {
        //     Header: intl.formatMessage({
        //       id: "generic.label.shipment-id",
        //       defaultMessage: "Shipment ID",
        //     }),
        //     id: "shipmentId",
        //     accessor: (row) => row.Data?.ShipmentID,
        //     Cell: ({ value }: { value?: number }) => <span>{value ?? "-"} </span>,
        //   };

        // case AvailableUserTasksTableColumns.ShippingMethod:
        //   return {
        //     Header: intl.formatMessage({
        //       id: "generic.label.shipping-method",
        //       defaultMessage: "Shipping method",
        //     }),
        //     id: "shippingMethod",
        //     accessor: (row) => row.Data?.ShippingMethodName,
        //     Cell: ({ value }: { value?: string }) => <span>{value ?? "-"} </span>,
        //   };

        case AvailableUserTasksTableColumns.PrintStatus:
          return {
            Header: intl.formatMessage({
              id: "generic.label.status",
              defaultMessage: "Status",
            }),
            id: "print status",

            Cell: ({ row }: { row: Row<IAvailableUserTaskDto> }) => (
              <AvailableUserTasksTablePrintStatusCell task={row.original} />
            ),
          };

        case AvailableUserTasksTableColumns.StartTime:
          return {
            Header: intl.formatMessage({
              id: "generic.label.start-time",
              defaultMessage: "Start time",
            }),
            accessor: (row) => (
              <span>
                {row.StartTime
                  ? `${intl.formatDate(row.StartTime)} ${intl.formatTime(row.StartTime)}`
                  : "-"}
              </span>
            ),
          };

        case AvailableUserTasksTableColumns.HasOpenAmount:
          return {
            Header: intl.formatMessage({
              id: "generic.label.open-amount",
              defaultMessage: "Open amount",
            }),
            accessor: (row) => (
              <span>
                {row.hasOpenAmount !== undefined ? (
                  <Badge variant={row.hasOpenAmount ? "red" : "green"}>
                    {row.hasOpenAmount ? (
                      <FormattedMessage id="generic.label.yes" defaultMessage="Yes" />
                    ) : (
                      <FormattedMessage id="generic.label.no" defaultMessage="No" />
                    )}
                  </Badge>
                ) : null}
              </span>
            ),
          };

        default:
          return undefined;
      }
    },
    [intl],
  );

  const tableColumns = useMemo(() => {
    const columnDefs = columns
      .map((column) => getColumnDef(column))
      .filter((def): def is Column<IAvailableUserTaskDto> => !!def);

    return columnDefs;
  }, [columns, getColumnDef]);

  return tableColumns;
};

export default useAvailableUserTasksTableColumns;
