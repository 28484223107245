import { IntlShape } from "react-intl";

import { ProductPropertyTypeSearchTypes } from "~/types/eva-core";

export const getProductPropertyTypeSearchTypeTanslation = (intl: IntlShape) => {
  const translationMap: Record<EVA.PIM.ProductPropertyTypeSearchTypes, string> = {
    [ProductPropertyTypeSearchTypes.None]: intl.formatMessage({
      id: "generic.label.none",
      defaultMessage: "None",
    }),
    [ProductPropertyTypeSearchTypes.Keyword]: intl.formatMessage({
      id: "generic.label.keyword",
      defaultMessage: "Keyword",
    }),
    [ProductPropertyTypeSearchTypes.Text]: intl.formatMessage({
      id: "generic.label.text",
      defaultMessage: "Text",
    }),
    [ProductPropertyTypeSearchTypes.IsHidden]: intl.formatMessage({
      id: "generic.label.hidden",
      defaultMessage: "Hidden",
    }),
  };

  return (type: ProductPropertyTypeSearchTypes) => translationMap[type];
};
