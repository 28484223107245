import { useMemo } from "react";
import { useIntl } from "react-intl";
import { Column } from "react-table";

import { TableColumnDef } from "@new-black/lyra";

import DeleteActionButton from "~/components/shared/action-buttons/delete-action-button";
import { LyraProductSummary, ProductSummary } from "~/components/suite-composite/product-summary";
import { IProduct } from "~/types/product";

export const useProductSummariesTableColumns = ({
  onDelete,
}: {
  onDelete: (productID: number) => void;
}) => {
  const intl = useIntl();

  const materialColumns = useMemo<Column<IProduct>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: "generic.label.product-name",
          defaultMessage: "Product name",
        }),

        Cell: ({ row }) => (
          <ProductSummary
            productID={row.original.product_id}
            backendID={row.original?.backend_id}
            customID={row.original?.custom_id}
            productName={row.original?.display_value ?? row.original?.product_name ?? "-"}
            productImageBlobID={row.original?.primary_image?.blob}
            imageSize={38}
          />
        ),
      },
      {
        Header: intl.formatMessage({
          id: "generic.label.custom-id",
          defaultMessage: "Custom ID",
        }),
        accessor: (rowData) => rowData?.custom_id,
      },
      {
        Header: intl.formatMessage({ id: "generic.label.action", defaultMessage: "Action" }),
        align: "right",
        accessor: "product_id",
        Cell: ({ row }) => <DeleteActionButton onPress={() => onDelete(row.original.product_id)} />,
      },
    ],
    [intl, onDelete],
  );

  const lyraColumns = useMemo<TableColumnDef<IProduct>[]>(
    () => [
      {
        id: "product_name",
        header: intl.formatMessage({
          id: "generic.label.product-name",
          defaultMessage: "Product name",
        }),
        cellType: "button",
        cell: ({ row }) => (
          <LyraProductSummary
            productID={row.original.product_id}
            backendID={row.original?.backend_id}
            customID={row.original?.custom_id}
            productName={row.original?.display_value ?? row.original?.product_name ?? "-"}
            productImageBlobID={row.original?.primary_image?.blob}
            imageSize={38}
          />
        ),
      },
      {
        id: "custom_id",
        accessorKey: "custom_id",
        header: intl.formatMessage({
          id: "generic.label.custom-id",
          defaultMessage: "Custom ID",
        }),
      },
      {
        id: "action",
        header: "",
        align: "end",
        cellType: "button",
        cell: ({ row }) => <DeleteActionButton onPress={() => onDelete(row.original.product_id)} />,
      },
    ],
    [intl, onDelete],
  );

  return { materialColumns, lyraColumns };
};
