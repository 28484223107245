import { FormattedMessage, useIntl } from "react-intl";

import { KeyValueRow } from "@new-black/lyra";

import Grid from "~/components/suite-ui/grid";
import Text from "~/components/suite-ui/text";

export interface IProductNameProps {
  productName?: string;
  variant?: "default" | "lyra";
}

const ProductName = ({ productName, variant }: IProductNameProps) => {
  const intl = useIntl();
  if (variant === "lyra") {
    return (
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.product-name",
          defaultMessage: "Product name",
        })}
      >
        {productName}
      </KeyValueRow>
    );
  }

  return (
    <Grid container direction="column">
      <Text variant="body2">
        <FormattedMessage id="generic.label.product-name" defaultMessage="Product name" />
      </Text>
      <Text variant="body1">{productName ?? "-"}</Text>
    </Grid>
  );
};

export default ProductName;
