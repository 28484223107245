import { atomFamily } from "recoil";

import { EKeyName } from "./workspaces.types";

import {
  DEFAULT_WORKSPACE_ID,
  IWorkspaceSelectorItem,
} from "~/components/shared/menu/workspace/workspace-selector";

export const selectedWorkspaceIdState = atomFamily<
  IWorkspaceSelectorItem["ID"],
  string | undefined
>({
  key: "Workspaces:SelectedWorkspaceId",
  default: DEFAULT_WORKSPACE_ID,
});

export const shouldInitializeWorkspace = atomFamily<boolean, string | undefined>({
  key: "Workspaces:InitActiveWorkspace",
  default: true,
});

export const isWorkspaceVisibleState = atomFamily<boolean, string | undefined>({
  key: "Workspaces:IsVisible",
  default: true,
});

export const isWorkspaceChangedState = atomFamily<
  boolean,
  { keyname?: string; propertyNames?: string } | undefined
>({
  key: "Workspaces:IsChanged",
  default: false,
});

export const isAdvancedWorkspacesEnabled = atomFamily<boolean, EKeyName | undefined>({
  key: "Workspaces:IsAdvancedWorkspacesEnabled",
  default: false,
});
