import { useCallback, useEffect, useState } from "react";

import { useField, useFormikContext } from "formik";

import { SingleAutocomplete, SpyCallback, SpyFunction } from "./autocomplete-generator.types";

import useRecoilDebouncedValue from "~/hooks/suite-react-hooks/use-recoil-debounced-value";

export interface ISingleAutocompleteWrapperReturnValues<Item> {
  Controlled: (inputProps: SingleAutocomplete<Item>["Props"]) => JSX.Element;
  Uncontrolled: (inputProps: SingleAutocomplete<Item>["Uncontrolled"]) => JSX.Element;
  Formik: (inputProps: SingleAutocomplete<Item>["Formik"]) => JSX.Element;
  Recoil: (inputProps: SingleAutocomplete<Item>["Recoil"]) => JSX.Element;
  RecoilIDOnlyWithQuery: <IDKey extends keyof Item>(
    inputProps: SingleAutocomplete<Item, IDKey>["RecoilIDOnlyWithQuery"],
  ) => JSX.Element;
}

/** Wrapper component that based on a basic SingleAutocomplete
 * generates Controlled, Uncontrolled, Formik and Recoil variants */
export const SingleAutocompleteWrapper = <Item,>(
  Autocomplete: (props: SingleAutocomplete<Item>["Props"]) => JSX.Element,
): ISingleAutocompleteWrapperReturnValues<Item> => {
  const Controlled = (inputProps: SingleAutocomplete<Item>["Props"]) => (
    <Autocomplete {...inputProps} />
  );

  const Uncontrolled = ({
    defaultValue,
    ...inputProps
  }: SingleAutocomplete<Item>["Uncontrolled"]) => {
    const [value, setValue] = useState(defaultValue);
    return <Autocomplete {...inputProps} value={value} onChange={setValue} />;
  };

  const Formik = ({ name, ...inputProps }: SingleAutocomplete<Item>["Formik"]) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField<Item | undefined>(name);
    return (
      <Autocomplete
        {...inputProps}
        value={field.value}
        error={meta.touched && !!meta.error}
        helperText={meta.touched ? meta.error : undefined}
        onChange={(newValue) => {
          setFieldValue(name, newValue);
          inputProps?.onChange?.(newValue);
        }}
      />
    );
  };

  const Recoil = ({
    customOnChange,
    recoilState,
    ...inputProps
  }: SingleAutocomplete<Item>["Recoil"]) => {
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);
    return (
      <Controlled
        value={recoilValue}
        onChange={(newValue) =>
          customOnChange ? customOnChange(setRecoilValue, newValue) : setRecoilValue(newValue)
        }
        {...inputProps}
      />
    );
  };

  /** Same as Recoil, but it is based only on the ID and it also allows to pass a hook that retrievs an item by ID */
  const RecoilIDOnlyWithQuery = <IDKey extends keyof Item>({
    customOnChange,
    idKey,
    recoilState,
    useItem,
    ...inputProps
  }: SingleAutocomplete<Item, IDKey>["RecoilIDOnlyWithQuery"]) => {
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);

    const { isLoading, item } = useItem(recoilValue);

    const handleChange = useCallback(
      (newValue: Item | undefined) => {
        setRecoilValue(newValue?.[idKey]);
      },
      [idKey, setRecoilValue],
    );

    return (
      <Controlled
        value={item}
        isLoading={recoilValue ? isLoading : false}
        onChange={(newValue) =>
          customOnChange ? customOnChange(setRecoilValue, newValue) : handleChange(newValue)
        }
        {...inputProps}
      />
    );
  };

  return { Controlled, Uncontrolled, Formik, Recoil, RecoilIDOnlyWithQuery };
};

/** Wrapper component that is based on a basic, ID-based SingleAutocomplete
 * that allows to pass a hook that retrievs an item by ID */
export const SingleAutocompleteIDWrapper = <Item, IDKey extends keyof Item>(
  Autocomplete: (props: SingleAutocomplete<Item>["Props"] & SpyCallback<Item>) => JSX.Element,
  idKey: IDKey,
  useItemByID: (
    id: Item[IDKey] | undefined,
    listItems: Item[] | undefined,
  ) => { data: Item | undefined; isLoading?: boolean },
): Omit<ISingleAutocompleteWrapperReturnValues<Item[IDKey]>, "RecoilIDOnlyWithQuery"> => {
  const Controlled = (inputProps: SingleAutocomplete<Item[IDKey]>["Props"]) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const { data: currentItem, isLoading } = useItemByID(inputProps.value, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      ({ listItems }) => setListItems(listItems),
      [],
    );
    return (
      <Autocomplete
        {...inputProps}
        value={currentItem}
        spyCallback={invokeSpy}
        isLoading={isLoading || inputProps.isLoading}
        onChange={(newValue) => inputProps.onChange?.(newValue?.[idKey])}
      />
    );
  };

  const Uncontrolled = ({
    defaultValue,
    ...inputProps
  }: SingleAutocomplete<Item[IDKey]>["Uncontrolled"]) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const [currentItemId, setCurrentItemId] = useState(defaultValue);

    useEffect(() => setCurrentItemId(defaultValue), [defaultValue]);

    const { data: currentItem, isLoading } = useItemByID(currentItemId, listItems);

    const invokeSpy = useCallback<SpyFunction<Item>>(
      ({ listItems }) => setListItems(listItems),
      [],
    );

    return (
      <Autocomplete
        {...inputProps}
        value={currentItem}
        onChange={(newValue) => setCurrentItemId(newValue?.[idKey])}
        spyCallback={invokeSpy}
        isLoading={isLoading || inputProps.isLoading}
      />
    );
  };

  const Formik = ({ name, ...inputProps }: SingleAutocomplete<Item[IDKey]>["Formik"]) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField<Item[IDKey] | undefined>(name);
    const { data: currentItem, isLoading } = useItemByID(field.value, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      ({ listItems }) => setListItems(listItems),
      [],
    );
    return (
      <Autocomplete
        {...inputProps}
        value={currentItem}
        spyCallback={invokeSpy}
        error={meta.touched && !!meta.error}
        isLoading={isLoading || inputProps.isLoading}
        helperText={meta.touched ? meta.error : undefined}
        onChange={(newValue) => {
          setFieldValue(name, newValue?.[idKey]);
          inputProps?.onChange?.(newValue?.[idKey]);
        }}
      />
    );
  };

  const Recoil = ({
    customOnChange,
    recoilState,
    ...inputProps
  }: SingleAutocomplete<Item[IDKey]>["Recoil"]) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);
    const { data: currentItem, isLoading } = useItemByID(recoilValue, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      ({ listItems }) => setListItems(listItems),
      [],
    );
    return (
      <Autocomplete
        {...inputProps}
        value={currentItem}
        spyCallback={invokeSpy}
        isLoading={isLoading || inputProps.isLoading}
        onChange={(newValue) =>
          customOnChange
            ? customOnChange(setRecoilValue, newValue?.[idKey])
            : setRecoilValue(newValue?.[idKey])
        }
      />
    );
  };

  return { Controlled, Uncontrolled, Formik, Recoil };
};
