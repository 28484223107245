import { mapCustomFieldPrimitiveValueToEVACustomFieldValue } from "~/util/custom-fields";

export interface ICustomFieldConsumerProps<T> {
  value: T;
  name: string;
  label?: string;
  placeholder?: string;
  required?: boolean;
  requiredMessage?: string;
  onChange: (value: T, error?: string) => void;
  touched?: boolean;
  error?: string;
  alwaysShowValidationMessage?: boolean;
  componentVariant?: "material" | "lyra";
}

/** Customizable translations for the consumer (validation messages, labels, etc.)
 *
 * If this is not provided, the consumer will use the defined EntityTranslations
 * for the selected culture of the current user, and fallback to the default (english) translations.
 */
export interface ICFCTranslations {
  /** Label to display above the Time field in case of a DateTime CustomField */
  timeLabel?: string;
  /** Error message to display when a required field has a value of `undefined` */
  requiredMessage?: string;
  /** Error message to display when a field's value's length is less than the required minimum length (e.g. String, Text) */
  minLengthMessage?: string;
  /** Error message to display when a field's value's length is more than the required maximum length (e.g. String, Text) */
  maxLengthMessage?: string;
  /** */
  outOfRangeLengthMessage?: string;
  /** Error message to display when a field's numeric value is less than the required minimum value (e.g. Integer, Decimal) */
  minValueMessage?: string;
  /** Error message to display when a field's numeric value is more than the required maximum value (e.g. Integer, Decimal) */
  maxValueMessage?: string;
  /** */
  outOfRangeValueMessage?: string;
  /** Error message to display when a field's Date / DateTime value is earlier than the required minimum Date (e.g. Date, DateTime) */
  minDateMessage?: string;
  /** Error message to display when a field's Date / DateTime value is later than the required maximum Date (e.g. Date, DateTime) */
  maxDateMessage?: string;
  /** */
  outOfRangeDateMessage?: string;
}

export type ICFCCustomFieldValueType =
  | "SingleLineStringValue"
  | "MultiLineStringValue"
  | "IntegerValue"
  | "DecimalValue"
  | "BoolValue"
  | "DateValue"
  | "DateTimeValue"
  | "SingleEnumValue"
  | "MultiEnumValue";

export type ICFCCustomFieldValueTypeWithArrays =
  | ICFCCustomFieldValueType
  | `Multi${Exclude<ICFCCustomFieldValueType, "SingleEnumValue" | "MultiEnumValue">}`;

export type ChangeHandlerWithType<T, U extends ICFCCustomFieldValueTypeWithArrays> = (
  value: T,
  type: U,
  error?: string,
) => void;

export type CFCConsumerType<T, X extends ICFCCustomFieldValueTypeWithArrays> = [T, X];

export type CFCInferredFieldType<T> = T extends CFCConsumerType<infer X, any> ? X : never;
export type CFCInferredCFType<T> = T extends CFCConsumerType<any, infer X> ? X : never;
export type CFCInferredChangeHandler<T> = T extends CFCConsumerType<infer X, infer Y>
  ? (value: X, type: Y) => void
  : never;

export type CFCSingleLineStringValueCustomField = CFCConsumerType<
  string | undefined,
  "SingleLineStringValue"
>;
export type CFCMultiLineStringValueCustomField = CFCConsumerType<
  string | undefined,
  "MultiLineStringValue"
>;
export type CFCIntegerValueCustomField = CFCConsumerType<number | undefined, "IntegerValue">;
export type CFCDecimalValueCustomField = CFCConsumerType<number | undefined, "DecimalValue">;
export type CFCBoolValueCustomField = CFCConsumerType<boolean | undefined, "BoolValue">;
export type CFCDateValueCustomField = CFCConsumerType<string | undefined, "DateValue">;
export type CFCDateTimeValueCustomField = CFCConsumerType<string | undefined, "DateTimeValue">;
export type CFCSingleEnumValueCustomField = CFCConsumerType<string | undefined, "SingleEnumValue">;
export type CFCMultiEnumValueCustomField = CFCConsumerType<string[] | undefined, "MultiEnumValue">;
export type CFCMultiIntegerValueCustomField = CFCConsumerType<
  (number | undefined)[] | undefined,
  "MultiIntegerValue"
>;
export type CFCMultiDecimalValueCustomField = CFCConsumerType<
  (number | undefined)[] | undefined,
  "MultiDecimalValue"
>;
export type CFCMultiBoolValueCustomField = CFCConsumerType<
  (boolean | undefined)[] | undefined,
  "MultiBoolValue"
>;
export type CFCMultiDateValueCustomField = CFCConsumerType<
  (string | undefined)[] | undefined,
  "MultiDateValue"
>;
export type CFCMultiDateTimeValueCustomField = CFCConsumerType<
  (string | undefined)[] | undefined,
  "MultiDateTimeValue"
>;

export type CFCTypedValueCustomField =
  | CFCSingleLineStringValueCustomField
  | CFCMultiLineStringValueCustomField
  | CFCIntegerValueCustomField
  | CFCDecimalValueCustomField
  | CFCBoolValueCustomField
  | CFCDateValueCustomField
  | CFCDateTimeValueCustomField
  | CFCSingleEnumValueCustomField
  | CFCMultiEnumValueCustomField
  | CFCMultiIntegerValueCustomField
  | CFCMultiDecimalValueCustomField
  | CFCMultiBoolValueCustomField
  | CFCMultiDateValueCustomField
  | CFCMultiDateTimeValueCustomField;

export function customFieldChangeToCustomFieldValue<U extends CFCTypedValueCustomField>(
  value: CFCInferredFieldType<U>,
  type: CFCInferredCFType<U>,
  isRequired?: boolean,
): EVA.Core.CustomFieldValue | undefined {
  return mapCustomFieldPrimitiveValueToEVACustomFieldValue(type, value, isRequired, true);
}
