import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Radio, RadioGroup, Select as LyraSelect, Text as LyraText } from "@new-black/lyra";

import { ICustomFieldConsumerProps } from "../custom-field-consumer.types";

import { RadioButtonIcon } from "~/assets/icons/components/custom-field-icons";
import RadioButtonList from "~/components/suite-ui/radio-button-list";
import RadioButtonLabel from "~/components/suite-ui/radio-button-list/radio-button-label";
import Select from "~/components/suite-ui/select";
import Text from "~/components/suite-ui/text";

interface ICustomSingleEnumFieldConsumerProps
  extends ICustomFieldConsumerProps<string | undefined> {
  options: { [key: string]: string };
  enumDisplay?: "expanded" | "inline";
}

export const CustomSingleEnumFieldConsumer = ({
  componentVariant,
  enumDisplay,
  error,
  label,
  name,
  onChange,
  options,
  required,
  value,
}: ICustomSingleEnumFieldConsumerProps) => {
  const intl = useIntl();

  const optionsArrayForRadioBoxesList = useMemo(
    () =>
      Object.entries(options).map((entry) => ({
        Label: entry[1],
        BackendID: entry[0],
      })),
    [options],
  );

  const optionsArrayForSelect = useMemo(
    () => Object.entries(options).map((entry) => ({ label: entry[1], value: entry[0] })),
    [options],
  );

  if (componentVariant === "lyra") {
    if (enumDisplay === "inline") {
      return (
        <LyraSelect
          name={name}
          hideHintLabel
          label={label}
          items={optionsArrayForSelect}
          getItemId={(item) => item.value}
          getLabel={(item) => item.label}
          selectRenderElements={(item) => ({ label: item.label })}
          errorMessage={error}
          value={optionsArrayForSelect.find((option) => value === option.value)}
          onChange={(newValue) => onChange(newValue?.value)}
        />
      );
    }

    return (
      <>
        <RadioGroup
          value={
            optionsArrayForRadioBoxesList.find((option) => option.BackendID === value)?.BackendID ??
            ""
          }
          onChange={onChange}
          name={name}
          label={label ?? ""}
          hideHintLabel
        >
          {optionsArrayForRadioBoxesList.map((option) => (
            <Radio key={option.BackendID} value={option.BackendID}>
              {option.Label}
            </Radio>
          ))}
        </RadioGroup>

        {error ? (
          <LyraText variant="body-small" color="error">
            {error}
          </LyraText>
        ) : null}
      </>
    );
  }

  return enumDisplay === "inline" ? (
    <div>
      <Select
        name="Select"
        label={
          label ?? intl.formatMessage({ id: "generic.label.select", defaultMessage: "Select" })
        }
        items={optionsArrayForSelect}
        value={value ?? ""}
        onChange={(event) => onChange(event.target.value as string)}
        fullWidth
        error={!!error}
        helpertext={error}
        required={required}
        endIcon={<RadioButtonIcon fontSize="small" />}
      />
    </div>
  ) : (
    <div className="flex flex-col gap-1">
      <RadioButtonList
        name={name}
        label={label}
        error={!!error}
        selectedValue={value ?? ""}
        handleSelectedValueChange={(_, newValue) => onChange(newValue)}
        required={required}
      >
        {optionsArrayForRadioBoxesList.map((option) => (
          <RadioButtonLabel
            label={option.Label}
            key={option.BackendID}
            value={option.BackendID}
            activeValue={value ?? ""}
          />
        ))}
      </RadioButtonList>

      {error ? (
        <Text variant="caption" color="error">
          {error}
        </Text>
      ) : null}
    </div>
  );
};
