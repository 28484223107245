import { useDateDuration } from "~/util/hooks/use-date-duration";

export interface IDeadlineTableCell {
  value: string;
}

const DeadlineTableCell = ({ value }: IDeadlineTableCell) => {
  const getDateDuration = useDateDuration();

  return <div>{getDateDuration(value)}</div>;
};

export default DeadlineTableCell;
