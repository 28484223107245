import { builders } from "@springtree/eva-sdk-react-recoil";
import { Core } from "@springtree/eva-services-core";
import { selectorFamily } from "recoil";

import { getFlattenedChildren } from "~/util/configurable-products.utils";

export const configurableProductDetailsServiceState = builders.buildServiceFamilyState({
  service: Core.GetConfigurableProductDetail,
  key: "Core:GetConfigurableProductDetail",
  allowEmptyRequest: false,
  requireEmployee: false,
});

export const configurableProductDetailsResultSelector = selectorFamily({
  key: "Core:GetConfigurableProductDetail:Result",
  get:
    (productId: number | undefined) =>
    ({ get }) =>
      get(configurableProductDetailsServiceState.response(productId))?.Configurable,
});

export const configurableProductFlattenedChildrenSelector = selectorFamily({
  key: "Core:GetConfigurableProductDetail:Result:Children:Flattened",
  get:
    (productId: number | undefined) =>
    ({ get }) =>
      getFlattenedChildren(
        get(configurableProductDetailsServiceState.response(productId))?.Configurable,
      ),
});

export const configurableProductChildrenCountSelector = selectorFamily({
  key: "Core:GetConfigurableProductDetail:Result:Children:Count",
  get:
    (productId: number | undefined) =>
    ({ get }) =>
      get(configurableProductFlattenedChildrenSelector(productId))?.length ?? 0,
});
