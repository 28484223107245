import { useMemo } from "react";
import { useIntl } from "react-intl";

import { ISONameColumnValue } from "../../table-column-components";
import TableInlineAutocomplete from "../../table-inline-autocomplete";
import TableInlineTextInput from "../../table-inline-text-input";
import { useEntityTranslationFieldSettings } from "../entity-translation-field-context";

import useGetISOCodeLists from "~/components/suite-composite/entity-translation-field/hooks/use-get-iso-code-lists";
import { ITableColumn } from "~/components/suite-ui/table/table-types";

const useEntityTranslationFieldTableColumns = (shouldValidate?: boolean) => {
  const intl = useIntl();
  const { translations } = useEntityTranslationFieldSettings();
  const { countryISOCodeList, languageISOCodeList } = useGetISOCodeLists();

  const columns = useMemo<ITableColumn<any>[]>(() => {
    return [
      {
        Header:
          translations?.tableHeaders?.country ??
          intl.formatMessage({ id: "generic.label.country", defaultMessage: "Country" }),
        accessor: "CountryID",
        width: 280,
        Cell: ({ value }) => (
          <>{value ? <ISONameColumnValue value={value} type="region" /> : null}</>
        ),
        editComponent: ({ cell, onFieldUpdate }) => {
          if (cell.row.original.CountryID) {
            return <ISONameColumnValue value={cell.value} type="region" />;
          }
          return (
            <TableInlineAutocomplete
              required
              cell={cell}
              onFieldUpdate={onFieldUpdate}
              items={countryISOCodeList ?? []}
              shouldValidate={shouldValidate}
              emptyFieldError={translations?.editField?.emptyEditFieldErrorMessage}
              label={
                translations?.editField?.labelCountryField ??
                intl.formatMessage({ id: "generic.label.country", defaultMessage: "Country" })
              }
            />
          );
        },
      },
      {
        Header:
          translations?.tableHeaders?.language ??
          intl.formatMessage({ id: "generic.label.language", defaultMessage: "Language" }),
        accessor: "LanguageID",
        width: 280,
        Cell: ({ value }) => (
          <>{value ? <ISONameColumnValue value={value} type="language" /> : null}</>
        ),
        editComponent: ({ cell, onFieldUpdate }) => {
          if (cell.row.original.LanguageID) {
            return <ISONameColumnValue value={cell.value} type="language" />;
          }
          return (
            <TableInlineAutocomplete
              required
              cell={cell}
              onFieldUpdate={onFieldUpdate}
              items={languageISOCodeList ?? []}
              shouldValidate={shouldValidate}
              emptyFieldError={translations?.editField?.emptyEditFieldErrorMessage}
              label={
                translations?.editField?.labelLanguageField ??
                intl.formatMessage({ id: "generic.label.language", defaultMessage: "Language" })
              }
            />
          );
        },
      },
      {
        Header:
          translations?.tableHeaders?.translation ??
          intl.formatMessage({ id: "generic.label.translation", defaultMessage: "Translation" }),
        accessor: "Value",
        width: 300,
        editComponent: ({ cell, onFieldUpdate }) => (
          <TableInlineTextInput
            required
            cell={cell}
            onFieldUpdate={onFieldUpdate}
            shouldValidate={shouldValidate}
            emptyFieldError={translations?.editField?.emptyEditFieldErrorMessage}
            label={
              translations?.editField?.labelValueField ??
              intl.formatMessage({ id: "generic.label.translation", defaultMessage: "Translation" })
            }
          />
        ),
      },
    ];
  }, [
    intl,
    shouldValidate,
    countryISOCodeList,
    languageISOCodeList,
    translations?.tableHeaders?.country,
    translations?.tableHeaders?.language,
    translations?.tableHeaders?.translation,
    translations?.editField?.labelValueField,
    translations?.editField?.labelCountryField,
    translations?.editField?.labelLanguageField,
    translations?.editField?.emptyEditFieldErrorMessage,
  ]);

  return columns;
};

export default useEntityTranslationFieldTableColumns;
