import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getSerialNumberConfigurationsLoaderQuery,
  serviceQueryKeys: getSerialNumberConfigurationsQueryKeys,
  useServiceQueryHook: useGetSerialNumberConfigurationsQuery,
  useServiceQueryWithRequest: useGetSerialNumberConfigurationsQueryWithRequest,
} = createServiceQuery(Core.GetSerialNumberConfigurations, true);

export const {
  serviceLoaderQuery: listSerialNumberProductsLoaderQuery,
  serviceQueryKeys: listSerialNumberProductsQueryKeys,
  useServiceQueryHook: useListSerialNumberProductsQuery,
  useServiceQueryWithRequest: useListSerialNumberProductsQueryWithRequest,
} = createServiceQuery(Core.ListSerialNumberProducts, true);
