import { useMemo } from "react";
import { useIntl } from "react-intl";

import { IChapter } from "../chapter-finder.types";

import { useHasFunctionality } from "~/hooks/suite-react-hooks/use-has-functionality";
import { Functionalities, FunctionalityScope } from "~/types/functionalities";

export const usePeopleChapters = (): IChapter[] => {
  const intl = useIntl();

  const { functionalityAllowed: userRequirementsChapterAllowed } = useHasFunctionality(
    Functionalities.USER_REQUIREMENTS,
    FunctionalityScope.View,
  );
  const { functionalityAllowed: rolesChapterAllowed } = useHasFunctionality(
    Functionalities.ROLES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: employeesChapterAllowed } = useHasFunctionality(
    Functionalities.EMPLOYEES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: subscriptionsChapterAllowed } = useHasFunctionality(
    Functionalities.SUBSCRIPTIONS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: roleSetsChapterAllowed } = useHasFunctionality(
    Functionalities.ROLE_SETS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: inquiriesChapterAllowed } = useHasFunctionality(
    Functionalities.INQUIRIES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: consumersChapterAllowed } = useHasFunctionality(
    Functionalities.CUSTOMERS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewCaseStatusesAllowed } = useHasFunctionality(
    Functionalities.CASE_STATUSES,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewCaseTopicsAllowed } = useHasFunctionality(
    Functionalities.CASE_TOPICS,
    FunctionalityScope.View,
  );

  const { functionalityAllowed: viewCasesAllowed } = useHasFunctionality(
    Functionalities.CASES,
    FunctionalityScope.View,
  );

  const peopleChapters = useMemo<IChapter[]>(() => {
    const chapters: IChapter[] = [];

    if (employeesChapterAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "module.people.chapters.employees",
          defaultMessage: "Employees",
        }),
        path: "/employees",
      });
    }

    if (rolesChapterAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.roles-and-rights",
          defaultMessage: "Roles and Rights",
        }),
        path: "/roles-rights",
      });
    }

    if (roleSetsChapterAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.role-sets",
          defaultMessage: "Role Sets",
        }),
        path: "/role-sets",
      });
    }

    if (consumersChapterAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.consumers",
          defaultMessage: "Consumers",
        }),
        path: "/consumers",
      });
    }

    if (userRequirementsChapterAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.title.user-requirement-sets",
          defaultMessage: "User Requirement Sets",
        }),
        path: "/user-requirement-sets",
      });
    }

    if (subscriptionsChapterAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.subscriptions",
          defaultMessage: "Subscriptions",
        }),
        path: "/subscriptions",
      });
    }

    if (inquiriesChapterAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.inquiries",
          defaultMessage: "Inquiries",
        }),
        path: "/inquiries",
      });
    }

    if (viewCasesAllowed || viewCaseStatusesAllowed || viewCaseTopicsAllowed) {
      chapters.push({
        title: intl.formatMessage({
          id: "generic.label.cases",
          defaultMessage: "Cases",
        }),
        path: "/cases",
      });
    }

    return chapters;
  }, [
    consumersChapterAllowed,
    employeesChapterAllowed,
    inquiriesChapterAllowed,
    intl,
    roleSetsChapterAllowed,
    rolesChapterAllowed,
    subscriptionsChapterAllowed,
    userRequirementsChapterAllowed,
    viewCaseStatusesAllowed,
    viewCaseTopicsAllowed,
    viewCasesAllowed,
  ]);

  return peopleChapters;
};
