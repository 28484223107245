import { useEffect, useState } from "react";

import { isNil } from "lodash";

import { MenuItemProps } from "~/components/suite-ui/select";

/**
 * Use this hook to map any array to items you can use in the Select component.
 *
 * @param items
 * @param nameProp
 * @param valueProp
 * @param disabled
 */
const useMapArrayToSelectItems = <T extends { [key: string]: any }>(
  items: T[] | null | undefined,
  nameProp: keyof T,
  valueProp: keyof T,
  disabled?: boolean,
) => {
  const [selectOptions, setSelectOptions] = useState<MenuItemProps[]>([]);
  useEffect(() => {
    if (!isNil(items)) {
      const newMapping = items.map((item) => ({
        label: item[nameProp],
        value: item[valueProp],
        disabled,
      }));
      setSelectOptions(newMapping);
    }
  }, [items, nameProp, valueProp, disabled]);
  return selectOptions;
};
export default useMapArrayToSelectItems;
