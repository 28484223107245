import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getCustomFieldByIDLoaderQuery,
  serviceQuery: getCustomFieldByIDQuery,
  serviceQueryKeys: getCustomFieldByIDKeys,
  useServiceQueryHook: useGetCustomFieldByIDQuery,
  useServiceQueryWithRequest: useGetCustomFieldByIDQueryWithRequest,
} = createServiceQuery(CoreManagement.GetCustomFieldByID, true);

export const {
  serviceLoaderQuery: getCustomFieldTypesLoaderQuery,
  serviceQuery: getCustomFieldTypesQuery,
  serviceQueryKeys: getCustomFieldTypesQueryKeys,
  useServiceQueryHook: useGetCustomFieldTypesQuery,
  useServiceQueryWithRecoilRequest: useGetCustomFieldTypesQueryWithRecoilRequest,
  useServiceQueryWithRequest: useGetCustomFieldTypesQueryWithRequest,
} = createServiceQuery(CoreManagement.GetCustomFieldTypes);

export const {
  serviceLoaderQuery: getCustomFieldsLoaderQuery,
  serviceQuery: getCustomFieldsQuery,
  serviceQueryKeys: getCustomFieldsQueryKeys,
  useServiceQueryHook: useGetCustomFieldsQuery,
} = createServiceQuery(CoreManagement.GetCustomFields, true);

export const {
  serviceLoaderQuery: listCustomFieldsLoaderQuery,
  serviceQuery: listCustomFieldsQuery,
  serviceQueryKeys: listCustomFieldsQueryKeys,
  useServiceQueryHook: useListCustomFieldsQuery,
} = createServiceQuery(CoreManagement.ListCustomFields, true);

export const {
  serviceLoaderQuery: getCustomFieldMetadataLoaderQuery,
  serviceQueryKeys: getCustomFieldMetadataQueryKeys,
  useServiceQueryHook: useGetCustomFieldMetadataQuery,
} = createServiceQuery(CoreManagement.GetCustomFieldMetadata, true);
