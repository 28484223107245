import { CoreManagement } from "@springtree/eva-services-core-management";

import { listSubscriptionsQuery } from "~/models/subscriptions";
import { AutocompleteGenerator } from "~/util/autocomplete-generator";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const {
  MultiAutocomplete: SubscriptionMultiAutocomplete,
  SingleAutocomplete: SubscriptionSingleAutocomplete,
} = AutocompleteGenerator<
  CoreManagement.ListSubscriptions,
  {
    ID?: number;
    Name?: string;
  }
>({
  idKey: "ID",
  labelKey: "Name",
  getItemFromResponse: (resp) =>
    resp?.Subscriptions?.map((subscription) => ({
      ID: subscription.ID,
      Name: subscription.IsExternal
        ? `(${intlAccessor.formatMessage({
            id: "generic.label.external",
            defaultMessage: "External",
          })}) ${subscription.Name} `
        : subscription.Name,
    })),
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.subscription",
    defaultMessage: "Subscription",
  }),
  useServiceQuery: () =>
    AutocompleteGenerator.useAutocompleteService({
      refetchOnFocus: false,
      query: listSubscriptionsQuery,
    }),
});

export const generateSubscriptionLyraSearchListField = () =>
  SearchListFieldGenerator<
    CoreManagement.ListSubscriptions,
    {
      ID: number;
      Name: string;
      IsExternal?: boolean;
    },
    number
  >({
    getItemId: (item) => item.ID,
    getLabel: (item) => getSubscriptionLabel(item.Name, item.IsExternal),
    selectRenderElements: (item) => ({
      label: getSubscriptionLabel(item.Name, item.IsExternal),
    }),
    getItemFromResponse: (resp) =>
      resp?.Subscriptions?.map((subscription) => ({
        ID: subscription.ID as number,
        Name: getSubscriptionLabel(subscription.Name as string, subscription.IsExternal),
        IsExternal: subscription.IsExternal,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.subscription",
      defaultMessage: "Subscription",
    }),
    useItemByID: (id, items) => ({ data: items?.find((item) => item.ID === id) }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listSubscriptionsQuery,
        initialRequest: {},
      }),
  });

const getSubscriptionLabel = (name: string, isExternal?: boolean) =>
  isExternal
    ? `(${intlAccessor.formatMessage({
        id: "generic.label.external",
        defaultMessage: "External",
      })}) ${name} `
    : name;
