import { CoreManagement } from "@springtree/eva-services-core-management";

import { useAccountByObjectAccount } from "./use-account-by-object-account-id";

import { listAccountsQuery } from "~/models/general-ledger-accounts";
import { DEFAULT_SEARCH_LIST_FIELD_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateListAccountsSearchListField = () =>
  SearchListFieldGenerator<
    CoreManagement.ListAccounts,
    {
      ObjectAccount: string;
      Name?: string;
    },
    string
  >({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: listAccountsQuery,
        refetchOnFocus: false,
        initialRequest: {
          PageConfig: { Start: 0, Limit: DEFAULT_SEARCH_LIST_FIELD_LIMIT },
        },
        configureLoadMoreButton: (response) => ({
          shouldShowLoadMoreButton:
            (response?.Result?.PageConfig?.Limit ?? 0) < (response?.Result?.Total ?? 0),
          onLoadMore: (request) => ({
            ...request,
            PageConfig: {
              ...request?.PageConfig,
              Limit:
                (request?.PageConfig?.Limit ?? DEFAULT_SEARCH_LIST_FIELD_LIMIT) +
                DEFAULT_SEARCH_LIST_FIELD_LIMIT,
            },
          }),
        }),
        getQueryRequest: (req) => req?.PageConfig?.Filter?.ObjectAccount,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              ObjectAccount: newValue,
            },
          },
        }),
      }),
    getItemId: (item) => item.ObjectAccount,
    getLabel: (item) => item.Name ?? "",
    getItemFromResponse: (resp) =>
      resp?.Result?.Page?.map((item) => ({
        ObjectAccount: item.ObjectAccount,
        Name: item.Name,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.account",
      defaultMessage: "Account",
    }),
    useItemByID: (objectAccount) => {
      const { data, isLoading } = useAccountByObjectAccount(objectAccount);

      return {
        data,
        isLoading,
      };
    },
  });
