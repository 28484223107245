import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listWishlistsLoaderQuery,
  serviceQuery: listWishlistsQuery,
  useServiceQueryHook: useListWishlistsQuery,
} = createServiceQuery(CoreManagement.ListWishlists, true);

export const { serviceQuery: getWishlistQuery, useServiceQueryHook: useGetWishlistQuery } =
  createServiceQuery(CoreManagement.GetWishlist, true);
