import { useCallback, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import {
  Button,
  CardContent,
  CardFooter,
  CardHeader,
  Checkbox,
  Dialog,
  DialogCard,
  FieldGroup,
  SvgIcon,
  Text,
  TextField,
} from "@new-black/lyra";

import { toast } from "~/components/suite-ui/toast";
import { SafeClipboard } from "~/util/clipboard";

interface SecureKeysConfirmationDialogProps {
  isOpen: boolean;
  onDone: () => void;
  keys: { label: string; value: string }[];
  messages?: {
    title?: string;
    description?: string;
    confirmation?: string;
  };
}

export const SecureKeysConfirmationDialog = ({
  isOpen,
  keys,
  messages,
  onDone,
}: SecureKeysConfirmationDialogProps) => {
  return (
    <Dialog isOpen={isOpen} maxWidth="xl" isDismissable={false} isKeyboardDismissDisabled={true}>
      <SecureKeysConfirmationDialogContent onDone={onDone} keys={keys} messages={messages} />
    </Dialog>
  );
};

export const SecureKeysConfirmationDialogContent = ({
  keys,
  messages,
  onDone,
}: Omit<SecureKeysConfirmationDialogProps, "isOpen">) => {
  const intl = useIntl();

  const [isConfirmed, setIsConfirmed] = useState(false);

  const copyToClipboard = useCallback(
    (key?: string) => {
      if (key) {
        SafeClipboard.writeText(key)
          .then(() =>
            toast.success(
              intl.formatMessage({
                id: "generic.label.copied",
                defaultMessage: "Copied to clipboard",
              }),
            ),
          )
          .catch((err) => console.error(err));
      }
    },
    [intl],
  );

  return (
    <DialogCard>
      <CardHeader
        title={
          messages?.title ??
          intl.formatMessage({
            id: "generic.label.secure-the-api-key",
            defaultMessage: "Secure the API key",
          })
        }
      />
      <CardContent className="flex flex-col gap-4">
        <Text variant="body-medium">
          {messages?.description ?? (
            <FormattedMessage
              id="secure-watchtower-api-key.description"
              defaultMessage="This API key will only be shown once here. Make sure to copy and save it securely, as you will not be able to retrieve it again once you navigate away from this page."
            />
          )}
        </Text>
        <FieldGroup>
          {keys?.map((key, index) => (
            <TextField
              key={index}
              hideHintLabel
              isDisabled
              label={key.label}
              value={key.value}
              outerEndSlot={
                <Button
                  variant="icon"
                  onPress={() => copyToClipboard(key.value)}
                  tooltip={intl.formatMessage({
                    id: "generic.label.copy-to-clipboard",
                    defaultMessage: "Copy to clipboard",
                  })}
                >
                  <SvgIcon name="clipboard" />
                </Button>
              }
            />
          ))}

          <Checkbox onChange={setIsConfirmed}>
            {messages?.confirmation ?? (
              <FormattedMessage
                id="secure-watchtower-api-key.confirmation"
                defaultMessage="I have copied and securely saved the API key."
              />
            )}
          </Checkbox>
        </FieldGroup>
      </CardContent>

      <CardFooter>
        <Button onPress={onDone} isDisabled={!isConfirmed}>
          <FormattedMessage id="generic.label.done" defaultMessage="Done" />
        </Button>
      </CardFooter>
    </DialogCard>
  );
};
