import { forwardRef } from "react";
import { useIntl } from "react-intl";

import { SvgIcon } from "@new-black/lyra";

import { IActionButtonLink } from "./types";

import { ButtonLink } from "~/components/routing";

const DuplicateActionButtonLink = forwardRef<HTMLAnchorElement, IActionButtonLink>(
  (
    {
      className,
      force,
      keepSearchParams,
      onLinkClick,
      state,
      target,
      to,
      tooltip,
    }: IActionButtonLink,
    ref,
  ) => {
    const intl = useIntl();

    return (
      <ButtonLink
        to={to}
        state={state}
        keepSearchParams={keepSearchParams}
        onClick={onLinkClick}
        target={target}
        force={force}
        ref={ref}
        className={className}
        tooltip={
          tooltip ??
          intl.formatMessage({ id: "generic.label.duplicate", defaultMessage: "Duplicate" })
        }
        variant="icon"
      >
        <SvgIcon name="copy" />
      </ButtonLink>
    );
  },
);

DuplicateActionButtonLink.displayName = "DuplicateActionButtonLink";

export default DuplicateActionButtonLink;
