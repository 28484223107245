import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";
import { matchPath, useLocation } from "react-router-dom";

import { useRecoilState, useRecoilValue } from "recoil";

import { isAdvancedWorkspacesEnabled, isWorkspaceVisibleState } from "../workspaces.state";
import { IUseWorkspacesProps } from "../workspaces.types";

import { ADVANCED_WORKSPACES_SUPPORTED_KEY_NAMES } from "./use-advanced-workspace";
import useChangeWorkspace from "./use-change-workspace";
import useGetWorkspaces from "./use-get-workspaces";
import useInitializeWorkspace from "./use-initialize-workspace";
import useSelectedWorkspace from "./use-selected-workspace";
import { useWorkspaceHandlers } from "./use-workspace-handlers";
import useWorkspaceSelectorItems from "./use-workspace-selector-items";

import {
  DEFAULT_WORKSPACE_ID,
  IWorkspaceSelectorItem,
} from "~/components/shared/menu/workspace/workspace-selector";

const useWorkspaces = <TWorkspace>(props: IUseWorkspacesProps<TWorkspace>) => {
  const intl = useIntl();
  const location = useLocation();

  const currentPageConfiguration = useMemo(
    () =>
      props.pageConfigurations.find((config) => {
        const match = matchPath(
          {
            path: config.path,
          },
          location.pathname,
        );

        return config.path === match?.pattern.path;
      }),
    [location.pathname, props.pageConfigurations],
  );

  const keyName = useMemo(
    () => currentPageConfiguration?.keyName,
    [currentPageConfiguration?.keyName],
  );

  const isWorkspaceVisible = useRecoilValue(isWorkspaceVisibleState(keyName));

  const [isAdvancedWorkspaceEnabled, setIsAdvancedWorkpaceEnabled] = useRecoilState(
    isAdvancedWorkspacesEnabled(keyName),
  );

  const hasAdvancedWorkspaceFunctionality = useMemo(
    () => (keyName ? ADVANCED_WORKSPACES_SUPPORTED_KEY_NAMES.includes(keyName) : false),
    [keyName],
  );

  const { refreshWorkspaces, workspacesLoading, workspacesResponse } = useGetWorkspaces(keyName);

  const defaultWorkspaceSettings = useRecoilValue(props.defaultSettings(keyName));

  const getDefaultWorkspace = useCallback<(keyName: string) => IWorkspaceSelectorItem>(
    (keyName: string) => ({
      ID: DEFAULT_WORKSPACE_ID,
      IsActive: true,
      IsShared: false,
      Key: keyName,
      Name: intl.formatMessage({
        id: "generic.label.default-workspace",
        defaultMessage: "Default workspace",
      }),
      RolesWithDefault: [],
      Settings: JSON.stringify(defaultWorkspaceSettings),
      isDefault: true,
    }),
    [defaultWorkspaceSettings, intl],
  );

  // The list of workspaces with a fallback to the local default workspace
  const workspaces = useMemo<IWorkspaceSelectorItem[]>(() => {
    if (!keyName || workspacesLoading) return [];

    return workspacesResponse?.Result?.length
      ? workspacesResponse?.Result
      : [getDefaultWorkspace(keyName)];
  }, [getDefaultWorkspace, keyName, workspacesLoading, workspacesResponse?.Result]);

  // Callback that gets the next active workspace based on an array of workspaces
  const getNextWorkspace = useCallback(
    (keyName: string, workspaces: IWorkspaceSelectorItem[]) => {
      const nextActiveWorkspace =
        // Check for the active workspace
        workspaces?.find(
          (workspace) => workspace.IsActive && workspace.ID !== DEFAULT_WORKSPACE_ID,
        ) ??
        // Check for the first shared workspace with a default
        workspaces?.find(
          (workspace) =>
            workspace.RolesWithDefault?.find((role) => role.IsDefault) &&
            workspace.ID !== DEFAULT_WORKSPACE_ID,
        ) ??
        // Check the first workspace whatsoever
        workspaces?.[0] ??
        // Check for local default workspace
        workspaces?.find((workspace) => workspace.ID === DEFAULT_WORKSPACE_ID) ??
        // Finally, fallback to the default workspace
        getDefaultWorkspace(keyName);

      return nextActiveWorkspace;
    },
    [getDefaultWorkspace],
  );

  const {
    applyWorkspaceSettings,
    handleResetToDefaultWorkspace,
    handleResetWorkspace,
    selectedWorkspace,
    selectedWorkspaceId,
    setSelectedWorkspaceId,
    workspaceState,
  } = useSelectedWorkspace(
    props.state,
    defaultWorkspaceSettings,
    keyName,
    workspaces,
    currentPageConfiguration,
  );

  useInitializeWorkspace(
    applyWorkspaceSettings,
    setSelectedWorkspaceId,
    getNextWorkspace,
    workspaces,
    workspacesLoading,
    keyName,
  );

  const workspacesVisible = useMemo(
    () => isWorkspaceVisible && currentPageConfiguration !== undefined,
    [currentPageConfiguration, isWorkspaceVisible],
  );

  const { selectedWorkspaceSelectorItem, workspaceSelectorItems } = useWorkspaceSelectorItems(
    workspaces,
    selectedWorkspaceId,
    workspacesVisible,
  );

  const { isWorkspaceChanged } = useChangeWorkspace(
    selectedWorkspace,
    keyName,
    currentPageConfiguration,
    workspaceState,
    defaultWorkspaceSettings,
  );

  const {
    handleAddSharedWorkspace,
    handleAddWorkspace,
    handleDeleteWorkspace,
    handleEditSharePermissions,
    handleSaveWorkspace,
    handleSetSelectedWorkspace,
  } = useWorkspaceHandlers(
    applyWorkspaceSettings,
    setSelectedWorkspaceId,
    refreshWorkspaces,
    getNextWorkspace,
    currentPageConfiguration,
    workspaceState,
    defaultWorkspaceSettings,
    selectedWorkspaceId,
    keyName,
    workspaces,
  );

  return {
    workspaces: workspaceSelectorItems,
    selectedWorkspace: selectedWorkspaceSelectorItem,
    workspacesVisible,
    handleSetSelectedWorkspace,
    handleDeleteWorkspace,
    handleResetWorkspace,
    handleResetToDefaultWorkspace:
      selectedWorkspaceId === DEFAULT_WORKSPACE_ID ? undefined : handleResetToDefaultWorkspace,
    handleSaveWorkspace,
    handleAddSharedWorkspace,
    handleEditSharePermissions,
    handleAddWorkspace,
    isWorkspaceChanged,
    isAdvancedWorkspaceEnabled,
    setIsAdvancedWorkpaceEnabled,
    hasAdvancedWorkspaceFunctionality,
    workspacesLoading,
  };
};

export default useWorkspaces;
