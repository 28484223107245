import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listUserRequirementSetsLoaderQuery,
  serviceQueryKeys: listUserRequirementSetsQueryKeys,
  useServiceQueryHook: useListUserRequirementSetsQuery,
} = createServiceQuery(CoreManagement.ListUserRequirementSets);

export const {
  serviceLoaderQuery: listUserRequirementsLoaderQuery,
  serviceQueryKeys: listUserRequirementsQueryKeys,
  useServiceQueryHook: useListUserRequirementsQuery,
} = createServiceQuery(CoreManagement.ListUserRequirements);

export const { useServiceQueryHook: useGetAvailableUserRequirementsProperties } =
  createServiceQuery(CoreManagement.GetAvailableUserRequirementsProperties);

export const {
  serviceLoaderQuery: getUserRequirementLoaderQuery,
  serviceQueryKeys: getUserRequirementQueryKeys,
  useServiceQueryHook: useGetUserRequirementQuery,
} = createServiceQuery(CoreManagement.GetUserRequirement, true);
