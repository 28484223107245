import { useIntl } from "react-intl";

import { Field, FieldProps, FormikProps } from "formik";
import { useRecoilValue } from "recoil";

import AmountInput from "~/components/suite-ui/amount-input";
import { financialInfoCurrencySelector } from "~/features/discount-edit/views/financial-info/financial-info.state";
import useParsedRouteParam from "~/hooks/suite-react-hooks/use-parsed-route-param";
import { useCurrencyPrecision } from "~/util/hooks/use-currency-precision";

export interface IEvaProductSetRequiredAmountSpentOnProductsInput {
  onFieldBlur: (form: FormikProps<any>) => void;
  passive?: boolean;
}

const EvaProductSetRequiredAmountSpentOnProductsInput = ({
  onFieldBlur,
  passive,
}: IEvaProductSetRequiredAmountSpentOnProductsInput) => {
  const intl = useIntl();
  const discountId = useParsedRouteParam();
  const currency = useRecoilValue(financialInfoCurrencySelector(discountId));
  const precision = useCurrencyPrecision(currency);

  return (
    <Field name="requiredAmountSpentOnProducts">
      {(props: FieldProps) => (
        <AmountInput
          label={intl.formatMessage({
            id: "edit-discount.product-set.required-amount-spent",
            defaultMessage: "Required amount spent on products",
          })}
          required
          CurrencyID={currency}
          precision={precision}
          {...props.field}
          passive={passive}
          value={props.field.value ?? ""}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.form.setFieldValue(
              props.field.name,
              e.target.value ? parseFloat(e.target.value) : undefined,
            );
          }}
          allowNegative={false}
          error={props.meta.error && props.meta.touched ? true : undefined}
          helperText={props.meta.error && props.meta.touched ? props.meta.error : undefined}
          onBlur={() => onFieldBlur(props.form)}
        />
      )}
    </Field>
  );
};

export default EvaProductSetRequiredAmountSpentOnProductsInput;
