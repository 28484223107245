import { useMemo } from "react";

import { getApplicationConfigurationLoaderQuery } from "~/models/application";
import { useRootRouteData } from "~/routes/root";
import { queryClient } from "~/util/query-client";

export const getAppSetting = async (key: string, fallbackValue?: any) => {
  const applicationConfiguration = await getApplicationConfigurationLoaderQuery(queryClient, {})();

  const config = applicationConfiguration.value?.Configuration;

  if (config) {
    if (Object.keys(config).includes(key)) {
      return config[key];
    }
  }

  return fallbackValue ?? undefined;
};

export const useAppSetting = <T = any>(
  key: string,
  fallbackValue?: T,
  fallbackAppConfig?: { [key: string]: any },
) => {
  const { applicationConfiguration } = useRootRouteData();

  const value = useMemo(() => {
    const config = applicationConfiguration?.value?.Configuration ?? fallbackAppConfig;
    if (config) {
      if (Object.keys(config).includes(key)) {
        return config[key] as T;
      }
    }
    return fallbackValue ?? undefined;
  }, [applicationConfiguration?.value?.Configuration, fallbackAppConfig, fallbackValue, key]);

  return value;
};

/**
 * Returns if a boolean app setting is true. Handles boolean and string value
 * @param appSettingValue the value of the app setting to check
 * @returns true if the appSettingValue is the string `"true"` or boolean value `true` and false otherwise
 */
export const isBooleanAppSettingTrue = (appSettingValue: any): appSettingValue is "true" | true =>
  appSettingValue === "true" || appSettingValue === true;

export const useBooleanAppSetting = (key: string, fallbackValue?: boolean) => {
  const appSetting = useAppSetting(key, fallbackValue);
  const booleanAppSettingValue = useMemo(() => isBooleanAppSettingTrue(appSetting), [appSetting]);
  return booleanAppSettingValue;
};
