import { useEffect, useMemo } from "react";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { isEqual } from "lodash";
import { useRecoilState } from "recoil";

import { blobsForOrderServiceState } from ".";

import { useHandleSWRFamily } from "~/hooks/suite-react-hooks/use-handle-swr";

const useBlobsForOrder = (orderID?: number) => {
  const [blobsForOrderRequest, setBlobsForOrderRequest] = useRecoilState(
    blobsForOrderServiceState.request(orderID),
  );
  const blobsForOrderResponse = hooks.useGetState(blobsForOrderServiceState.response(orderID));

  const blobsForOrderLoading = hooks.useIsLoading({
    state: blobsForOrderServiceState.response(orderID),
  });

  const blobsForOrderLoadingWithoutResponse = useMemo(
    () => blobsForOrderLoading && !blobsForOrderResponse,
    [blobsForOrderLoading, blobsForOrderResponse],
  );

  useHandleSWRFamily(blobsForOrderServiceState, orderID);

  const request = useMemo<EVA.Core.ListBlobsForOrder | undefined>(() => {
    if (orderID) {
      return {
        OrderID: orderID,
      };
    }
    return undefined;
  }, [orderID]);

  useEffect(() => {
    if (request && !isEqual(blobsForOrderRequest, request)) {
      setBlobsForOrderRequest(request);
    }
  }, [blobsForOrderRequest, orderID, request, setBlobsForOrderRequest]);

  return {
    blobsForOrder: blobsForOrderResponse?.Result,
    blobsForOrderLoading,
    blobsForOrderLoadingWithoutResponse,
  };
};

export default useBlobsForOrder;
