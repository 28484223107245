import { useCallback } from "react";

import { useMonaco } from "@monaco-editor/react";
import { useMutation } from "@tanstack/react-query";
import type Monaco from "monaco-editor";

import { parseScriptMutation } from "../helpers/parse-script";
import { CompletionCategory } from "../script-code-editor.types";

import { ScriptEditorLanguage } from "~/types/monaco-editor-language";

/**
 * Returns a callback that can be used to register the code completion provider for the Script dialect language on the monaco editor
 */
const useRegisterScriptLanguageAutocomplete = (dialect: ScriptEditorLanguage) => {
  const monaco = useMonaco();

  const { mutateAsync: parseScript } = useMutation(parseScriptMutation);

  // Map EVA node type to Monaco language suggestion kind
  //
  const getSuggestionKind = useCallback(
    (type?: CompletionCategory) => {
      if (monaco?.languages) {
        switch (type) {
          case CompletionCategory.Keyword:
            return monaco?.languages?.CompletionItemKind.Keyword;
          case CompletionCategory.Member:
            return monaco?.languages?.CompletionItemKind.Variable;
          case CompletionCategory.Snippet:
            return monaco?.languages?.CompletionItemKind.Snippet;
          default:
            return monaco?.languages?.CompletionItemKind.Keyword;
        }
      } else {
        return 4 as Monaco.languages.CompletionItemKind;
      }
    },
    [monaco],
  );

  /**
   * Configures the code completion provider used by the monaco editor for the Script dialect language
   *
   * @returns an `IDisposable` object that can be used to un-register the provider
   */
  const registerScriptLanguageAutocompleteProvider = useCallback(() => {
    return monaco?.languages?.registerCompletionItemProvider(dialect, {
      triggerCharacters: [".", " ", "(", "["],
      provideCompletionItems: async (model, position) => {
        // Get the current line number and column from position
        //
        const { column, lineNumber } = position;

        // What is the word we're typing now?
        //
        const word = model.getWordUntilPosition(position);

        // What is the script up until the current cursor position
        //
        const valueUntilPosition = model.getValueInRange({
          startLineNumber: 1,
          startColumn: 1,
          endLineNumber: lineNumber,
          endColumn: column,
        });

        const lineIsComment = model.getLineContent(lineNumber).startsWith("# ");

        // If the line is a comment, return an empty list of suggestions
        //
        if (lineIsComment) {
          return {
            suggestions: [],
          };
        }

        // Call parseScript to retrieve the autocomplete suggestions
        //
        const res = await parseScript({
          Dialect: dialect,
          CompletionRequest: {
            Source: valueUntilPosition,
          },
        });

        const completionSuggestions = res?.response?.CompletionSuggestions;

        // What is the current range based on the current position
        //
        const range = {
          startLineNumber: position.lineNumber,
          endLineNumber: position.lineNumber,
          startColumn: word.startColumn,
          endColumn: word.endColumn,
        };

        // Create an empty list of suggestion
        //
        let suggestions: Monaco.languages.CompletionItem[] = [];

        // If there are any suggestion, map it to Monaco suggestions
        //
        if (completionSuggestions && completionSuggestions.length) {
          suggestions =
            completionSuggestions
              .filter((suggestion) => !!suggestion.Code && !!suggestion.Label)
              .map((suggestion, index) => {
                const autoCompleteItem: Monaco.languages.CompletionItem = {
                  range,
                  label: suggestion.Label!,
                  insertText: `${suggestion.Code!}`,
                  insertTextRules:
                    (suggestion.Category as number) === CompletionCategory.Snippet
                      ? monaco.languages.CompletionItemInsertTextRule.InsertAsSnippet
                      : undefined,
                  kind: getSuggestionKind(suggestion.Category as number),
                  // Making sure we always show the suggestions in the order BE returns them
                  sortText: new Array(index + 1).fill("A").join(""),
                  command: commandBasedOnSuggestionCode(suggestion.Code),
                  detail: suggestion.Label!,
                };
                return autoCompleteItem;
              }) ?? [];
        }

        return {
          suggestions,
          incomplete: res?.response?.HasErrors,
        };
      },
    });
  }, [dialect, getSuggestionKind, monaco, parseScript]);

  return registerScriptLanguageAutocompleteProvider;
};

const commandBasedOnSuggestionCode = (code?: string): Monaco.languages.Command | undefined => {
  const commandsConfiguration: Array<{
    condition: (code?: string) => boolean | undefined;
    command: Monaco.languages.Command;
  }> = [
    // Re-triggering suggestions when you autocomplete something that ends with a period (eg: `Order.`)
    {
      command: {
        id: "editor.action.triggerSuggest",
        title: "Re-trigger completions...",
      },
      condition: (code) => code?.endsWith("."),
    },

    // Indentation when you autocomplete a "then" or "do" keyword followed by a new line
    {
      command: {
        id: "editor.action.indentLines",
        title: "Indent line",
      },
      condition: (code) => !!code && ["then\n", "do\n"].includes(code),
    },
  ];

  return commandsConfiguration.find(({ condition }) => condition(code))?.command;
};

export default useRegisterScriptLanguageAutocomplete;
