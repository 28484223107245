import { ComponentProps, useCallback, useEffect, useState } from "react";

import { useField, useFormikContext } from "formik";
import { isEqual } from "lodash";

import {
  MultiSearchListField,
  SpyCallback,
  SpyFunction,
  TSearchListFieldGeneratorOptionsProps,
  TUncontrolledValueSelectorProp,
} from "./search-list-field-generator.types";

import useRecoilDebouncedValue from "~/hooks/suite-react-hooks/use-recoil-debounced-value";

export interface IMultiSearchListFieldWrapperReturnValues<Item> {
  Controlled: (
    inputProps: MultiSearchListField<Item>["Props"] & TSearchListFieldGeneratorOptionsProps<Item>,
  ) => JSX.Element;
  Uncontrolled: (
    inputProps: MultiSearchListField<Item>["Uncontrolled"] &
      TUncontrolledValueSelectorProp<Item> &
      TSearchListFieldGeneratorOptionsProps<Item>,
  ) => JSX.Element;
  Formik: (
    inputProps: MultiSearchListField<Item>["Formik"] & TSearchListFieldGeneratorOptionsProps<Item>,
  ) => JSX.Element;
  Recoil: (
    inputProps: MultiSearchListField<Item>["Recoil"] & TSearchListFieldGeneratorOptionsProps<Item>,
  ) => JSX.Element;
}

/** Wrapper component that based on a basic MultiSearchListField
 * generates Controlled, Uncontrolled, Formik and Recoil variants */
export const MultiSearchListFieldWrapper = <Item,>(
  SearchListField: (props: MultiSearchListField<Item>["Props"]) => JSX.Element,
): IMultiSearchListFieldWrapperReturnValues<Item> => {
  const Controlled = (
    inputProps: ComponentProps<IMultiSearchListFieldWrapperReturnValues<Item>["Controlled"]>,
  ) => <SearchListField {...inputProps} />;

  const Uncontrolled = ({
    defaultValue,
    ...inputProps
  }: ComponentProps<IMultiSearchListFieldWrapperReturnValues<Item>["Uncontrolled"]>) => {
    const [values, setValues] = useState(defaultValue);
    return (
      <SearchListField
        {...inputProps}
        values={values}
        onChange={(newValues) => {
          setValues(newValues);
          inputProps.onChange?.(newValues);
        }}
      />
    );
  };

  const Formik = ({
    name,
    ...inputProps
  }: ComponentProps<IMultiSearchListFieldWrapperReturnValues<Item>["Formik"]>) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField<Item[] | undefined>(name);
    return (
      <SearchListField
        {...inputProps}
        values={field.value}
        error={meta.touched && !!meta.error}
        helperText={meta.touched ? meta.error : undefined}
        onChange={(newValue) => setFieldValue(name, newValue)}
      />
    );
  };

  const Recoil = ({
    customOnChange,
    recoilState,
    ...inputProps
  }: ComponentProps<IMultiSearchListFieldWrapperReturnValues<Item>["Recoil"]>) => {
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);
    return (
      <Controlled
        values={recoilValue}
        onChange={(newValue) =>
          customOnChange ? customOnChange(setRecoilValue, newValue) : setRecoilValue(newValue)
        }
        {...inputProps}
      />
    );
  };

  return { Controlled, Uncontrolled, Formik, Recoil };
};

export interface IMultiSearchListFieldWrapperWithIDReturnValues<Item, IDKey extends keyof Item> {
  Controlled: (
    inputProps: MultiSearchListField<Item[IDKey]>["Props"] &
      TSearchListFieldGeneratorOptionsProps<Item>,
  ) => JSX.Element;
  Uncontrolled: (
    inputProps: MultiSearchListField<Item[IDKey]>["Uncontrolled"] &
      TSearchListFieldGeneratorOptionsProps<Item> &
      TUncontrolledValueSelectorProp<Item>,
  ) => JSX.Element;
  Formik: (
    inputProps: MultiSearchListField<Item[IDKey]>["Formik"] &
      TSearchListFieldGeneratorOptionsProps<Item>,
  ) => JSX.Element;
  Recoil: (
    inputProps: MultiSearchListField<Item[IDKey]>["Recoil"] &
      TSearchListFieldGeneratorOptionsProps<Item>,
  ) => JSX.Element;
}

/** Wrapper component that is based on a basic, ID-based MultiSearchListField
 * that allows to pass a hook that retrievs an item by ID */
export const MultiSearchListFieldIDWrapper = <Item, IDKey extends keyof Item>(
  SearchListField: (props: MultiSearchListField<Item>["Props"] & SpyCallback<Item>) => JSX.Element,
  idKey: IDKey,
  useItemsByID: (
    ids: Item[IDKey][] | undefined,
    listItems: Item[] | undefined,
  ) => {
    data: Item[] | undefined;
    isLoading?: boolean;
  },
): IMultiSearchListFieldWrapperWithIDReturnValues<Item, IDKey> => {
  const Controlled = (
    inputProps: ComponentProps<
      IMultiSearchListFieldWrapperWithIDReturnValues<Item, IDKey>["Controlled"]
    >,
  ) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const { data: items, isLoading } = useItemsByID(inputProps.values, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      (args) => {
        const listItemsIDs = listItems?.map((item) => item?.[idKey]);
        const itemsIDs = args.listItems?.map((item) => item?.[idKey]);

        if (!isEqual(listItemsIDs, itemsIDs)) {
          setListItems(args.listItems);
        }
      },
      [listItems],
    );
    return (
      <SearchListField
        {...inputProps}
        values={items}
        isLoading={{ initialValue: isLoading, ...inputProps.isLoading }}
        spyCallback={invokeSpy}
        onChange={(newValue) => inputProps.onChange(newValue?.map((item) => item[idKey]))}
      />
    );
  };

  const Uncontrolled = ({
    defaultValue,
    ...inputProps
  }: ComponentProps<
    IMultiSearchListFieldWrapperWithIDReturnValues<Item, IDKey>["Uncontrolled"]
  >) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const [currentItemsIds, setCurrentItemsIds] = useState(defaultValue);

    useEffect(() => setCurrentItemsIds(defaultValue), [defaultValue]);

    const { data: currentItems, isLoading } = useItemsByID(currentItemsIds, listItems);

    const invokeSpy = useCallback<SpyFunction<Item>>(
      (args) => {
        const listItemsIDs = listItems?.map((item) => item?.[idKey]);
        const itemsIDs = args.listItems?.map((item) => item?.[idKey]);

        if (!isEqual(listItemsIDs, itemsIDs)) {
          setListItems(args.listItems);
        }
      },
      [listItems],
    );
    return (
      <SearchListField
        {...inputProps}
        values={currentItems}
        onChange={(newValues) => {
          setCurrentItemsIds(newValues?.map((newValue) => newValue[idKey]));
          inputProps.onChange?.(newValues?.map((newValue) => newValue[idKey]));
        }}
        isLoading={{ initialValue: isLoading, ...inputProps.isLoading }}
        spyCallback={invokeSpy}
      />
    );
  };

  const Formik = ({
    name,
    ...inputProps
  }: ComponentProps<IMultiSearchListFieldWrapperWithIDReturnValues<Item, IDKey>["Formik"]>) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField<Item[IDKey][] | undefined>(name);
    const { data: items, isLoading } = useItemsByID(field.value, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      (args) => {
        const listItemsIDs = listItems?.map((item) => item?.[idKey]);
        const itemsIDs = args.listItems?.map((item) => item?.[idKey]);

        if (!isEqual(listItemsIDs, itemsIDs)) {
          setListItems(args.listItems);
        }
      },
      [listItems],
    );
    return (
      <SearchListField
        {...inputProps}
        values={items}
        isLoading={{ initialValue: isLoading, ...inputProps.isLoading }}
        spyCallback={invokeSpy}
        error={meta.touched && !!meta.error}
        helperText={meta.touched ? meta.error : undefined}
        onChange={(newValue) =>
          setFieldValue(
            name,
            newValue?.map((item) => item[idKey]),
          )
        }
      />
    );
  };

  const Recoil = ({
    customOnChange,
    recoilState,
    ...inputProps
  }: ComponentProps<IMultiSearchListFieldWrapperWithIDReturnValues<Item, IDKey>["Recoil"]>) => {
    const [listItems, setListItems] = useState<Item[] | undefined>();
    const [recoilValue, setRecoilValue] = useRecoilDebouncedValue(recoilState);
    const { data: items, isLoading } = useItemsByID(recoilValue, listItems);
    const invokeSpy = useCallback<SpyFunction<Item>>(
      (args) => {
        const listItemsIDs = listItems?.map((item) => item?.[idKey]);
        const itemsIDs = args.listItems?.map((item) => item?.[idKey]);

        if (!isEqual(listItemsIDs, itemsIDs)) {
          setListItems(args.listItems);
        }
      },
      [listItems],
    );
    return (
      <SearchListField
        {...inputProps}
        values={items}
        isLoading={{ initialValue: isLoading, ...inputProps.isLoading }}
        spyCallback={invokeSpy}
        onChange={(newValue) =>
          customOnChange
            ? customOnChange(
                setRecoilValue,
                newValue?.map((item) => item[idKey]),
              )
            : setRecoilValue(newValue?.map((item) => item[idKey]))
        }
      />
    );
  };

  return { Controlled, Uncontrolled, Formik, Recoil };
};
