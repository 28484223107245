import { Core } from "@springtree/eva-services-core";
import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listSupplierRelationsLoaderQuery,
  serviceQuery: listSupplierRelationsQuery,
  serviceQueryKeys: listSupplierRelationsQueryKeys,
  useServiceQueryHook: useListSupplierRelationsQuery,
} = createServiceQuery(Core.ListOrganizationUnitSuppliers);

export const {
  serviceLoaderQuery: listStockAllocationRulesLoaderQuery,
  serviceQuery: listStockAllocationRulesQuery,
  serviceQueryKeys: listStockAllocationRulesQueryKeys,
  useServiceQueryHook: useListStockAllocationRulesQuery,
} = createServiceQuery(CoreManagement.ListStockAllocationRules);
