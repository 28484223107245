import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { Badge } from "@new-black/lyra";

import { UserBudgetDeduction } from "~/types/eva-core";
import { getUserBudgetDeductionTranslator } from "~/types/translations-enums/user-budget-deduction";
import enumToArray from "~/util/enum-to-array";

export const LoyaltyProgramUserBudgetDeductionBadge = ({ value }: { value: number }) => {
  const intl = useIntl();

  const valuesArray = useMemo(() => enumToArray(UserBudgetDeduction), []);

  const getUserBudgetDeductionTranslation = useCallback(
    (value: number) => getUserBudgetDeductionTranslator(intl)(value) ?? UserBudgetDeduction[value],
    [intl],
  );

  const allOption = useMemo(
    () => valuesArray?.reduce((accumulator, current) => accumulator | current.value, 0),
    [valuesArray],
  );

  const items = useMemo(
    () => valuesArray?.filter((item) => item.value !== allOption),
    [allOption, valuesArray],
  );

  const userBudgetDeduction = useMemo(() => {
    if (!items?.length) return [];

    if (value === 0) {
      return [getUserBudgetDeductionTranslation(value)];
    }

    return items
      ?.filter((entry) => entry.value !== 0 && (entry.value & value) === entry.value)
      .map((entry) => entry.key);
  }, [getUserBudgetDeductionTranslation, items, value]);

  return (
    <>
      {userBudgetDeduction.length
        ? userBudgetDeduction.map((entry, index) => (
            <Badge key={index} variant="transparent">
              {entry}
            </Badge>
          ))
        : null}
    </>
  );
};
