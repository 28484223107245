import { Core } from "@springtree/eva-services-core";

import useUserById from "../hooks/use-user-by-id";

import { searchUsersQuery } from "~/models/users";
import { AutocompleteGenerator } from "~/util/autocomplete-generator";
import { intlAccessor } from "~/util/intl-accessor";

interface IUserType {
  ID: number;
  FullName?: string;
  EmailAddress?: string;
}

// FOR FUTURE REFERENCE:
// When migrating this to a Lyra SearchListField,
// note that there is an implementation already that you can use: generateSearchUsersSearchListField
// Path: src/components/suite-composite/generate-search-users-search-list-field/index.tsx
export const generateUserAutocomplete = ({
  defaultRequest = { IncludeCustomers: true, IncludeEmployees: true },
  SecondaryLabelKey,
}: {
  defaultRequest?: Partial<EVA.Core.SearchUsers>;
  SecondaryLabelKey?: "EmailAddress";
}) =>
  AutocompleteGenerator<Core.SearchUsers, Partial<IUserType>, "ID">({
    idKey: "ID",
    labelKey: "FullName",
    secondaryLabelKey: SecondaryLabelKey,
    getItemFromResponse: (response) =>
      response?.Result?.Page?.map((user) => ({
        ID: user.ID,
        FullName: user.FullName,
        EmailAddress: user.EmailAddress,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.user",
      defaultMessage: "User",
    }),

    useServiceQuery: () =>
      AutocompleteGenerator.useAutocompleteService({
        refetchOnFocus: false,
        query: searchUsersQuery,
        initialRequest: {
          ...defaultRequest,
          PageConfig: { Limit: 10, ...defaultRequest?.PageConfig },
        },
        getQueryRequest: (req) => req?.SearchQuery,
        setQueryRequest: (prev, newValue) => ({
          ...prev,
          SearchQuery: newValue,
        }),
      }),
    useItemByID: (id) => {
      const { isLoading, user } = useUserById(id);
      return {
        data: user,
        isLoading,
      };
    },
  });
