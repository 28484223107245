import { CoreManagement } from "@springtree/eva-services-core-management";

import { getOpeningHoursTypesQuery } from "~/models/opening-hours";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateGetOpeningHoursTypesSearchListField = () =>
  SearchListFieldGenerator<
    CoreManagement.GetOpeningHoursTypes,
    {
      ID: number;
      Name?: string;
    },
    number
  >({
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        query: getOpeningHoursTypesQuery,
        refetchOnFocus: false,
      }),
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name ?? "",
    getItemFromResponse: (resp) =>
      resp?.OpeningHoursTypes?.map((station) => ({ ID: station.ID, Name: station.Name })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.type",
      defaultMessage: "Type",
    }),
    useItemByID: (id, itemsFromList) => {
      const item = itemsFromList?.find((item) => item.ID === id);
      return {
        data: item,
      };
    },
  });
