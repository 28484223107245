import { useContext } from "react";

import { LocaleContext } from "~/components/suite-composite/translation-provider";

const useLocale = () => {
  const localeContext = useContext(LocaleContext);

  if (!localeContext) {
    throw new Error("useTranslationProvider must be used within a TranslationProvider");
  }

  const { locale } = localeContext;

  return { locale };
};

export default useLocale;
