import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Card, CardActions, CardContent, CardHeader } from "@new-black/lyra";

import {
  LoyaltyProgramRuleProductSetConditionKeyValueRows,
  TLoyaltyProgramRuleProductSetCondition,
} from ".";

import DeleteActionButton from "~/components/shared/action-buttons/delete-action-button";
import EditActionButton from "~/components/shared/action-buttons/edit-action-button";
import { LyraProductFilterTable } from "~/components/suite-composite/product-filter/lyra-table/product-filter-table";
import { EvaFilterModel } from "~/components/suite-composite/product-filter/types";
import { mapProductSetsToEVAFilterModel } from "~/util/product-sets";

export const LoyaltyProgramRuleProductSetConditionCard = ({
  conditionID,
  data,
  inner,
  onDelete,
  onEdit,
  onUpdateProductSetFilter,
}: {
  conditionID: number;
  data?: Partial<TLoyaltyProgramRuleProductSetCondition>;
  onEdit?: (conditionID: number) => void;
  onUpdateProductSetFilter?: (conditionID: number, newValue?: EvaFilterModel) => Promise<void>;
  inner?: boolean;
  onDelete?: (conditionID: number) => void;
}) => {
  const intl = useIntl();

  const productSet = useMemo(() => mapProductSetsToEVAFilterModel(data?.ProductsSets)?.[0], [data]);

  return (
    <Card variant={inner ? "secondary" : "primary"}>
      <CardHeader
        title={intl.formatMessage({
          id: "generic.label.product-set",
          defaultMessage: "Product set",
        })}
        actions={
          onDelete ? (
            <CardActions>
              <DeleteActionButton onPress={() => onDelete(conditionID)} />
            </CardActions>
          ) : undefined
        }
      />
      <CardContent className="flex flex-col gap-5">
        <Card>
          <CardHeader
            title={productSet.Name}
            actions={
              onEdit ? (
                <CardActions>
                  <EditActionButton onPress={() => onEdit(conditionID)} />
                </CardActions>
              ) : null
            }
          />

          <CardContent>
            <LoyaltyProgramRuleProductSetConditionKeyValueRows
              identicalProductsOnly={data?.IdenticalProductsOnly}
              productSet={productSet}
            />
          </CardContent>
        </Card>

        {productSet?.Filters ? (
          <Card className="overflow-hidden">
            <LyraProductFilterTable
              title={intl.formatMessage({
                id: "generic.label.product-filters",
                defaultMessage: "Product filters",
              })}
              productFilter={productSet.Filters}
              updateProductFilterAsync={
                onUpdateProductSetFilter
                  ? (newValue) => onUpdateProductSetFilter(conditionID, newValue)
                  : undefined
              }
              disableAdd={!onUpdateProductSetFilter}
              disableDelete={
                !onUpdateProductSetFilter || Object.keys(productSet.Filters).length < 2
              }
              disableEdit={!onUpdateProductSetFilter}
            />
          </Card>
        ) : null}
      </CardContent>
    </Card>
  );
};
