import { useCallback } from "react";
import { useIntl } from "react-intl";

import { CoreManagement } from "@springtree/eva-services-core-management";
import { FormikHelpers } from "formik";
import moment from "moment";

import useCustomFieldOptions from "../hooks/use-custom-field-options";

import { ICFSettingFormValue } from "./custom-field-settings-modal-types";

import useCallService from "~/hooks/suite-react-hooks/use-call-service";
import { CustomFieldDataType } from "~/types/custom-field-data-types";
import {
  convertEVACustomFieldTypeToCustomFieldConsumerType,
  mapCustomFieldPrimitiveValueToEVACustomFieldValue,
} from "~/util/custom-fields";

const useSubmitCustomFieldSettingsForm = (
  closeCFSettingsModal: () => void,
  customFieldId?: number,
  customFieldDataTypeID?: number,
  customFieldIsArray?: boolean,
) => {
  const intl = useIntl();
  const { refetch } = useCustomFieldOptions(customFieldId);

  const { callService: setCustomFieldOption, serviceLoading: isLoading } = useCallService({
    service: CoreManagement.SetCustomFieldOptions,
    messages: {
      successMessage: intl.formatMessage({
        id: "custom-field.options.add.set.success",
        defaultMessage: "Custom field option successfully set",
      }),
      fallbackErrorMessage: intl.formatMessage({
        id: "custom-field.options.add.set.error",
        defaultMessage: "Failed to set custom field option",
      }),
      loadingMessage: intl.formatMessage({
        id: "custom-field.options.add.set.loading",
        defaultMessage: "Setting custom field option",
      }),
    },
  });

  const handleSubmit = useCallback(
    (values: ICFSettingFormValue, { setSubmitting }: FormikHelpers<ICFSettingFormValue>) => {
      if (customFieldId && values.OUSetID && customFieldDataTypeID !== undefined) {
        let customFieldDefaultValue: EVA.Core.CustomFieldValue | undefined =
          values.CustomFieldDefaultValue !== undefined && values.CustomFieldDefaultValue !== ""
            ? mapCustomFieldPrimitiveValueToEVACustomFieldValue(
                convertEVACustomFieldTypeToCustomFieldConsumerType(
                  customFieldDataTypeID,
                  customFieldIsArray,
                ),
                values.CustomFieldDefaultValue,
              )
            : undefined;

        let minimumDate: string | undefined = values.MinimumDate;
        let maximumDate: string | undefined = values.MaximumDate;

        // Format `Date` custom field values to YYYY-MM-DD since the timestamp is not supported by BE
        if (customFieldDataTypeID === CustomFieldDataType.Date) {
          minimumDate = minimumDate ? moment(minimumDate).format("YYYY-MM-DD") : undefined;
          maximumDate = maximumDate ? moment(maximumDate).format("YYYY-MM-DD") : undefined;

          if (customFieldDefaultValue) {
            if (customFieldIsArray) {
              customFieldDefaultValue = {
                ArrayValues: customFieldDefaultValue?.ArrayValues?.map((value) => ({
                  DateTimeValue: moment(value.DateTimeValue).format("YYYY-MM-DD"),
                })),
              };
            } else {
              customFieldDefaultValue = {
                DateTimeValue: moment(customFieldDefaultValue?.DateTimeValue).format("YYYY-MM-DD"),
              };
            }
          }
        }

        // If the custom field is an array and the default value is empty, set it to an empty object. This will allow
        // the BE to correctly clear the default value.
        if (customFieldIsArray && !customFieldDefaultValue?.ArrayValues?.length) {
          customFieldDefaultValue = {};
        }

        setCustomFieldOption({
          CustomFieldID: customFieldId,
          IsRequired: values.IsRequired,
          OrganizationUnitSetID: values.OUSetID,
          VisibleByUserTypes: values.VisibleByUserType ?? (null as any),
          EditableByUserTypes: values.EditableByUserType ?? (null as any),
          MinimumValue: values.MinimumValue ?? (null as any),
          MaximumValue: values.MaximumValue ?? (null as any),
          MinimumLength: values.MinimumLength ?? (null as any),
          MaximumLength: values.MaximumLength ?? (null as any),
          MinimumDate: minimumDate ?? (null as any),
          MaximumDate: maximumDate ?? (null as any),
          CustomFieldDefaultValue: customFieldDefaultValue ?? (null as any),
          SecurityScriptID: values.SecurityScriptID ?? (null as any),
          EligibilityScriptID: values.EligibilityScriptID ?? (null as any),
        }).then(() => {
          setSubmitting(false);
          refetch();
          closeCFSettingsModal();
        });
        return;
      }
      setSubmitting(false);
    },
    [
      closeCFSettingsModal,
      customFieldDataTypeID,
      customFieldId,
      customFieldIsArray,
      refetch,
      setCustomFieldOption,
    ],
  );

  return {
    isLoading,
    handleSubmit,
  };
};

export default useSubmitCustomFieldSettingsForm;
