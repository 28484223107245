import { Core } from "@springtree/eva-services-core";

import { useUserTaskTypeById } from "./use-user-task-type-by-id";

import { getUserTaskTypesQuery } from "~/models/user-tasks";
import { AutocompleteGenerator } from "~/util/autocomplete-generator";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateUserTaskTypesAutocomplete = (initialRequest?: EVA.Core.GetUserTaskTypes) => {
  const {
    SingleAutocomplete: UserTaskTypesAutocomplete,
    SingleIDAutocomplete: UserTaskTypesIDAutocomplete,
  } = AutocompleteGenerator<Core.GetUserTaskTypes, EVA.Core.UserTaskTypeDto, "ID">({
    idKey: "ID",
    labelKey: "Name",
    getItemFromResponse: (resp) => resp?.Types,
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.user-task-type",
      defaultMessage: "User task type",
    }),
    useServiceQuery: () =>
      AutocompleteGenerator.useAutocompleteService({
        refetchOnFocus: false,
        query: getUserTaskTypesQuery,
        initialRequest: initialRequest,
      }),
    useItemByID: useUserTaskTypeById,
  });

  return {
    UserTaskTypesAutocomplete,
    UserTaskTypesIDAutocomplete,
  };
};

export type UserTaskTypeSearchListFieldItem = Pick<EVA.Core.UserTaskTypeDto, "ID" | "Name">;

export const generateUserTaskTypesLyraSearchListField = (
  initialRequest?: EVA.Core.GetUserTaskTypes,
) => {
  const {
    SingleIDSearchListField: UserTaskTypesIDSearchListField,
    SingleSearchListField: UserTaskTypesSearchListField,
  } = SearchListFieldGenerator<Core.GetUserTaskTypes, UserTaskTypeSearchListFieldItem, number>({
    getItemId: (item) => item.ID,
    getLabel: (item) => item.Name,
    getItemFromResponse: (resp) =>
      resp?.Types?.map((type) => ({ ID: type.ID, Name: type.Name })) ?? [],
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.user-task-type",
      defaultMessage: "User task type",
    }),
    useServiceQuery: () =>
      SearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: getUserTaskTypesQuery,
        initialRequest: initialRequest,
      }),
    useItemByID: useUserTaskTypeById,
  });

  return {
    UserTaskTypesSearchListField,
    UserTaskTypesIDSearchListField,
  };
};
