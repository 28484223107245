import { useMemo } from "react";

import { ProductRelationDirection } from "~/types/eva-core";
import enumToArray from "~/util/enum-to-array";

const useProductRelationDirections = () => {
  const productRelationDirections = useMemo(() => enumToArray(ProductRelationDirection), []);
  return productRelationDirections;
};

export default useProductRelationDirections;
