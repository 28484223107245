import { bootstrapEndpoint, IEvaServiceCallOptions } from "@springtree/eva-sdk-core-service";
import { helpers } from "@springtree/eva-sdk-react-recoil";
import { IEvaServiceDefinition } from "@springtree/eva-services-core";
import ky from "ky";
import invariant from "tiny-invariant";

import { toast } from "~/components/suite-ui/toast";
import { unauthorizedRequestsStrategy } from "~/routes/__auth/unauthorized-requests-strategy";
import { LOGIN_REDIRECT_SEARCH_PARAM_NAME } from "~/routes/root";
import { getEvaConfig } from "~/routes/root/data/get-eva-config";
import { pathsWithoutUserTokenRequirement } from "~/routes/root/root";
import routeDefinitions from "~/routes/route-definitions";

const storage = helpers.storage;

const redirectToLoginPage = () => {
  if (!pathsWithoutUserTokenRequirement.some((path) => window.location.pathname.startsWith(path))) {
    const searchParams = new URLSearchParams(window.location.search);

    // Set the `from` param with current pathname
    searchParams.set(LOGIN_REDIRECT_SEARCH_PARAM_NAME, window.location.pathname);

    const loginURL = `${routeDefinitions.auth.login.path}?${searchParams.toString()}`;

    // Redirect to login page (with replace)
    window.location.replace(loginURL);
  }
};

const handleUnauthorizedError = (error?: ky.HTTPError) => {
  if (
    error?.response.status === 401 &&
    !pathsWithoutUserTokenRequirement.some((path) => location.pathname.startsWith(path)) &&
    unauthorizedRequestsStrategy.canApplyStrategy("redirect")
  ) {
    redirectToLoginPage();
  }
};

export const handleError = async (
  e: Error,
  serviceName: string,
  disableErrorNotification: boolean | ((error?: Error) => boolean) | undefined = false,
  disableRedirectOn401 = false,
) => {
  const displayErrorMessage = (toastErrorMessage?: string, consoleError?: string) => {
    console.error(`[Error: ${serviceName}]:`, consoleError ?? e);

    if (
      (typeof disableErrorNotification === "boolean" && !disableErrorNotification) ||
      (typeof disableErrorNotification === "function" && !disableErrorNotification(e))
    ) {
      const errorMessage =
        toastErrorMessage ?? "An error occurred while trying to perform this action.";

      toast.error(errorMessage, { id: errorMessage });
    }
  };

  if (e.name === "HTTPError") {
    const httpError = e as ky.HTTPError;
    try {
      if (!disableRedirectOn401) {
        handleUnauthorizedError(httpError);
      }

      const error = await httpError.response.clone().json();

      displayErrorMessage(error?.Error?.Message, error);

      return error;
    } catch (parsingError) {
      displayErrorMessage();
    }
  } else {
    displayErrorMessage();
  }
};

function evaCallService<SVC extends IEvaServiceDefinition>(service: new () => SVC) {
  return async (
    payload?: SVC["request"],
    options?: IEvaServiceCallOptions,
    throwOnError?: boolean,
    disableErrorNotification: boolean | ((error?: Error) => boolean) | undefined = undefined,
    disableRedirectOn401 = false,
  ): Promise<SVC["response"] | undefined> => {
    try {
      let endpoint = storage.getItem("evaEndpointUrl");
      const authToken = storage.getItem("evaUserToken");
      const requestedOU = storage.getItem("evaRequestedOrganizationUnitID");

      if (!endpoint) {
        const evaConfig = await getEvaConfig();
        if (evaConfig) {
          endpoint = evaConfig.endpoint;
        }
      }

      invariant(endpoint, "endpoint must be set");

      const bootstrappedEndpoint = await bootstrapEndpoint({ uri: endpoint, storage });

      const response = await bootstrappedEndpoint.callService(service, payload, {
        authenticationToken: authToken ?? undefined,
        requestedOrganizationUnitID: requestedOU ? parseInt(requestedOU, 10) : undefined,
        ...options,
      });

      return response;
    } catch (error) {
      if (error instanceof Error) {
        const serviceError = await handleError(
          error,
          service.name,
          disableErrorNotification,
          disableRedirectOn401,
        );

        if (throwOnError) {
          if (serviceError) {
            throw serviceError;
          } else {
            throw new Error(error.message);
          }
        } else {
          return serviceError;
        }
      } else {
        console.error(error);
        if (throwOnError) {
          throw new Error("An error occurred while trying to perform this action.");
        }
      }
    }
  };
}

export function createEVAService<SVC extends IEvaServiceDefinition>(service: new () => SVC) {
  return {
    call: evaCallService(service),
  };
}
