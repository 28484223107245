import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { FieldGroup, NumberField, Select, TextField } from "@new-black/lyra";

import {
  loyaltyProgramFormKeys,
  LoyaltyProgramUserIdentifiers,
  TLoyaltyProgramFormErrors,
} from "./types";

import { NUMBER_FORMAT_OPTIONS } from "~/util/number-format-options";

export const LoyaltyProgramUserIdentifierFields = ({
  defaultValue,
  errors,
}: {
  defaultValue?: EVA.Core.Management.GetLoyaltyProgramResponse;
  errors?: TLoyaltyProgramFormErrors;
}) => {
  const intl = useIntl();

  const policies = useMemo(
    () => [
      {
        id: LoyaltyProgramUserIdentifiers.RequestFromFrontEnd,
        label: intl.formatMessage({
          id: "generic.label.request-from-front-end",
          defaultMessage: "Request from front end",
        }),
      },
      {
        id: LoyaltyProgramUserIdentifiers.Custom,
        label: intl.formatMessage({
          id: "generic.label.custom",
          defaultMessage: "Custom",
        }),
      },
    ],
    [intl],
  );

  const [userIdentifier, setUserIdentifier] = useState<LoyaltyProgramUserIdentifiers | undefined>(
    () => {
      if (!defaultValue) return undefined;

      return defaultValue?.RequireManualUserIdentifier
        ? LoyaltyProgramUserIdentifiers.RequestFromFrontEnd
        : LoyaltyProgramUserIdentifiers.Custom;
    },
  );

  return (
    <>
      <Select
        name={loyaltyProgramFormKeys.UserIdentifier}
        label={intl.formatMessage({
          id: "generic.label.user-identifier",
          defaultMessage: "User identifier",
        })}
        items={policies}
        value={policies.find((entry) => entry.id === userIdentifier)}
        onChange={(newValue) => setUserIdentifier(newValue?.id)}
        isRequired
        getItemId={(item) => item.id}
        getLabel={(item) => item.label}
        selectRenderElements={(item) => ({ label: item.label })}
        errorMessage={errors?.UserIdentifier}
      />

      {userIdentifier === LoyaltyProgramUserIdentifiers.Custom ? (
        <FieldGroup className="flex-col sm:flex-row">
          <TextField
            name={loyaltyProgramFormKeys.UserIdentifierPrefix}
            defaultValue={defaultValue?.UserIdentifierPrefix}
            label={intl.formatMessage({ id: "generic.label.prefix", defaultMessage: "Prefix" })}
            errorMessage={errors?.UserIdentifierPrefix}
          />
          <NumberField
            name={loyaltyProgramFormKeys.UserIdentifierStartNumber}
            defaultValue={defaultValue?.UserIdentifierStartNumber}
            label={intl.formatMessage({ id: "generic.label.start", defaultMessage: "Start" })}
            isRequired
            hideStepper
            formatOptions={NUMBER_FORMAT_OPTIONS.integer}
            errorMessage={errors?.UserIdentifierStartNumber}
          />
          <TextField
            name={loyaltyProgramFormKeys.UserIdentifierSuffix}
            defaultValue={defaultValue?.UserIdentifierSuffix}
            label={intl.formatMessage({ id: "generic.label.suffix", defaultMessage: "Suffix" })}
            errorMessage={errors?.UserIdentifierSuffix}
          />
        </FieldGroup>
      ) : null}
    </>
  );
};
