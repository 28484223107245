import { useMemo } from "react";
import { useIntl } from "react-intl";

import { Menu, MenuTrigger } from "@new-black/lyra";

import AddActionButton from "~/components/shared/action-buttons/add-action-button";

type SetCaseProductOrOrderLineMenuButtonProps = {
  onSearchOrder?: () => void;
  onSearchProduct?: () => void;
  onSearchOwnedProducts?: () => void;
  isDisabled?: boolean;
};

export const SetCaseProductOrOrderLineMenuButton = ({
  isDisabled,
  onSearchOrder,
  onSearchOwnedProducts,
  onSearchProduct,
}: SetCaseProductOrOrderLineMenuButtonProps) => {
  const intl = useIntl();

  const menuItems = useMemo(() => {
    const items = [];

    if (onSearchProduct) {
      items.push({
        id: "search-product",
        label: intl.formatMessage({
          id: "generic.label.search-product",
          defaultMessage: "Search product",
        }),
        onContextItemClick: onSearchProduct,
      });
    }

    if (onSearchOrder) {
      items.push({
        id: "search-in-consumer-orders",
        label: intl.formatMessage({
          id: "generic.label.search-in-consumer-orders",
          defaultMessage: "Search in consumer orders",
        }),
        onContextItemClick: onSearchOrder,
      });
    }

    if (onSearchOwnedProducts) {
      items.push({
        id: "search-in-bought-products",
        label: intl.formatMessage({
          id: "generic.label.search-owned-products",
          defaultMessage: "Search owned products",
        }),
        onContextItemClick: onSearchOwnedProducts,
      });
    }

    return items;
  }, [intl, onSearchOrder, onSearchProduct, onSearchOwnedProducts]);

  return (
    <MenuTrigger>
      <AddActionButton isDisabled={isDisabled} />

      <Menu
        aria-label={intl.formatMessage({
          id: "generic.label.add-product",
          defaultMessage: "Add product",
        })}
        items={menuItems}
        getItemId={(item) => item.id}
        selectRenderElements={(item) => ({ label: item.label })}
        onAction={(key) => menuItems.find((item) => item.id === key)?.onContextItemClick()}
      />
    </MenuTrigger>
  );
};
