import { useMemo } from "react";
import { useIntl } from "react-intl";

import { KeyValueRow, KeyValueRowList } from "@new-black/lyra";

import { TLoyaltyProgramCustomFieldConsumerWithOperatorForm } from "~/features/loyalty-programs";
import {
  CUSTOM_FIELD_OPERATORS_DEFAULT_MESSAGE_MAP,
  CustomFieldValueOperator,
} from "~/types/custom-field";
import {
  formatCustomFieldValueDisplay,
  getCustomFieldValueFromEVACustomFieldValue,
} from "~/util/custom-fields";

export const LoyaltyProgramRuleConsumerConditionKeyValueRows = ({
  customField,
  hasValue,
  operator,
  value,
}: {
  operator?: CustomFieldValueOperator;
  customField?: EVA.Core.Management.GetCustomFieldByIDResponse;
  hasValue?: boolean;
  value?: Omit<TLoyaltyProgramCustomFieldConsumerWithOperatorForm["CustomFieldValue"], "HasValue">;
}) => {
  const intl = useIntl();

  const operatorValue = useMemo(
    () =>
      operator !== undefined
        ? intl.formatMessage({
            id: `generic.operator.${
              CustomFieldValueOperator[operator] as keyof typeof CustomFieldValueOperator
            }`,
            defaultMessage: CUSTOM_FIELD_OPERATORS_DEFAULT_MESSAGE_MAP[operator],
          })
        : undefined,
    [intl, operator],
  );

  return (
    <KeyValueRowList>
      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.custom-field",
          defaultMessage: "Custom field",
        })}
      >
        {customField?.DisplayName ?? customField?.Name}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.operator",
          defaultMessage: "Operator",
        })}
      >
        {operatorValue}
      </KeyValueRow>

      {hasValue ? (
        <KeyValueRow
          label={intl.formatMessage({
            id: "generic.label.value",
            defaultMessage: "Value",
          })}
          classes={{ value: "whitespace-pre-line" }}
        >
          {customField
            ? formatCustomFieldValueDisplay(intl)(
                { ...customField, DataType: customField.DataType as number },
                getCustomFieldValueFromEVACustomFieldValue(value),
              )
            : undefined}
        </KeyValueRow>
      ) : null}
    </KeyValueRowList>
  );
};
