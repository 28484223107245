import { useCallback } from "react";
import { FormattedDate, useIntl } from "react-intl";
import { generatePath } from "react-router-dom";

import { KeyValueRow, KeyValueRowList, Text } from "@new-black/lyra";

import { shouldEnableLoyaltyProgramLoyaltyPoints } from "./helpers";
import { LoyaltyProgramOptionsBadges } from "./loyalty-program-options-badges";
import { LoyaltyProgramStatusBadge } from "./loyalty-program-status-badge";
import { LoyaltyProgramUsageTypeBadges } from "./loyalty-program-usage-type-badges";

import OpenInNewTabActionButtonLink from "~/components/shared/action-button-links/open-in-new-tab-action-button-link";
import Chip from "~/components/suite-ui/chip";
import routeDefinitions from "~/routes/route-definitions";

export const LoyaltyProgramDetailsKeyValueList = ({
  loyaltyProgram,
  loyaltyProgramGroupName,
}: {
  loyaltyProgram?: EVA.Core.Management.GetLoyaltyProgramResponse;
  loyaltyProgramGroupName?: string;
}) => {
  const intl = useIntl();

  const getYesNoLabel = useCallback(
    (value?: boolean) =>
      value
        ? intl.formatMessage({ id: "generic.label.true", defaultMessage: "True" })
        : intl.formatMessage({ id: "generic.label.false", defaultMessage: "False" }),
    [intl],
  );

  return (
    <KeyValueRowList>
      <KeyValueRow label={intl.formatMessage({ id: "generic.label.id", defaultMessage: "ID" })}>
        {loyaltyProgram?.ID}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({ id: "generic.label.backend-id", defaultMessage: "Backend ID" })}
      >
        {loyaltyProgram?.BackendID}
      </KeyValueRow>

      <KeyValueRow label={intl.formatMessage({ id: "generic.label.name", defaultMessage: "Name" })}>
        {loyaltyProgram?.Name}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.description",
          defaultMessage: "Description",
        })}
      >
        {loyaltyProgram?.Description}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.options",
          defaultMessage: "Options",
        })}
        classes={{ valueContainer: "flex flex-wrap gap-2" }}
        type="badge"
      >
        {loyaltyProgram?.Options !== undefined ? (
          <LoyaltyProgramOptionsBadges value={loyaltyProgram.Options} />
        ) : null}
      </KeyValueRow>

      {shouldEnableLoyaltyProgramLoyaltyPoints(loyaltyProgram?.Options) ? (
        <KeyValueRow
          label={intl.formatMessage({
            id: "generic.label.point-usage-types",
            defaultMessage: "Point usage types",
          })}
          classes={{ valueContainer: "flex flex-wrap gap-2" }}
          type="badge"
        >
          {loyaltyProgram?.LoyaltyPointUsageOptions !== undefined ? (
            <LoyaltyProgramUsageTypeBadges value={loyaltyProgram.LoyaltyPointUsageOptions} />
          ) : null}
        </KeyValueRow>
      ) : null}

      <KeyValueRow
        label={intl.formatMessage({ id: "generic.label.handler", defaultMessage: "Handler" })}
      >
        {loyaltyProgram?.Handler}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.user-identifier",
          defaultMessage: "User identifier",
        })}
      >
        {loyaltyProgram?.RequireManualUserIdentifier
          ? intl.formatMessage({
              id: "generic.label.request-from-front-end",
              defaultMessage: "Request from front end",
            })
          : intl.formatMessage({
              id: "generic.label.custom",
              defaultMessage: "Custom",
            })}
      </KeyValueRow>

      {loyaltyProgram?.RequireManualUserIdentifier ? null : (
        <KeyValueRow
          label={intl.formatMessage({
            id: "generic.label.prefix-start-suffix",
            defaultMessage: "Prefix, start, suffix",
          })}
          type="badge"
        >
          <div className="flex gap-x-1">
            <Chip size="small" classNames={{ parent: "rounded-tr-sm rounded-br-sm" }}>
              {loyaltyProgram?.UserIdentifierPrefix}
            </Chip>
            <Chip size="small" classNames={{ parent: "rounded-sm" }}>
              {loyaltyProgram?.UserIdentifierStartNumber}
            </Chip>
            <Chip size="small" classNames={{ parent: "rounded-tl-sm rounded-bl-sm" }}>
              {loyaltyProgram?.UserIdentifierSuffix}
            </Chip>
          </div>
        </KeyValueRow>
      )}

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.start-date",
          defaultMessage: "Start date",
        })}
      >
        {loyaltyProgram?.StartDate ? (
          <Text>
            <FormattedDate value={loyaltyProgram?.StartDate} dateStyle="short" />
          </Text>
        ) : null}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.end-date",
          defaultMessage: "End date",
        })}
      >
        {loyaltyProgram?.EndDate ? (
          <Text>
            <FormattedDate value={loyaltyProgram?.EndDate} dateStyle="short" />
          </Text>
        ) : null}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.status",
          defaultMessage: "Status",
        })}
        type="badge"
      >
        <LoyaltyProgramStatusBadge value={loyaltyProgram?.Status as number | undefined} />
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.requires-subscription-validation",
          defaultMessage: "Requires subscription validation",
        })}
      >
        {getYesNoLabel(loyaltyProgram?.RequiresSubscriptionValidation)}
      </KeyValueRow>

      <KeyValueRow
        label={intl.formatMessage({
          id: "generic.label.loyalty-program-group",
          defaultMessage: "Loyalty program group",
        })}
        type="button"
      >
        {loyaltyProgram?.LoyaltyProgramGroupID && loyaltyProgramGroupName ? (
          <div className="flex flex-wrap items-center gap-2">
            <Text>{loyaltyProgramGroupName}</Text>

            <OpenInNewTabActionButtonLink
              to={generatePath(
                routeDefinitions.promotions.loyaltyPrograms.loyaltyProgramGroups.edit.path,
                { id: loyaltyProgram.LoyaltyProgramGroupID.toString() },
              )}
              target="_blank"
              small
            />
          </div>
        ) : null}
      </KeyValueRow>
    </KeyValueRowList>
  );
};
