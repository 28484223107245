import { IntlShape } from "react-intl";

import { APPLE_COLORS } from "~/types/apple-colors";

export const getAppleColorLabel =
  (intl: IntlShape) => (color: typeof APPLE_COLORS[number] | string) => {
    switch (color.toLowerCase()) {
      case "#af52de":
        return intl.formatMessage({ id: "generic.label.purple", defaultMessage: "Purple" });
      case "#5856d6":
        return intl.formatMessage({ id: "generic.label.indigo", defaultMessage: "Indigo" });
      case "#32ade6":
        return intl.formatMessage({ id: "generic.label.cyan", defaultMessage: "Cyan" });
      case "#30b0c7":
        return intl.formatMessage({ id: "generic.label.teal", defaultMessage: "Teal" });
      case "#00c7be":
        return intl.formatMessage({ id: "generic.label.mint", defaultMessage: "Mint" });
      case "#ffcc00":
        return intl.formatMessage({ id: "generic.label.yellow", defaultMessage: "Yellow" });
      case "#a2845e":
        return intl.formatMessage({ id: "generic.label.brown", defaultMessage: "Brown" });
      case "#ff2d55":
        return intl.formatMessage({ id: "generic.label.pink", defaultMessage: "Pink" });
      default:
        return color.toLowerCase();
    }
  };
