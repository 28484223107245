import { useEffect, useMemo, useState } from "react";

import { DiffEditor } from "@monaco-editor/react";
import type Monaco from "monaco-editor";

import { BASE_SCRIPT_EDITOR_OPTIONS } from "./helpers/base-script-editor-options";
import useSetErrorsOnModel from "./hooks/use-set-errors-on-model";
import { IScriptCodeEditorBaseProps } from "./script-code-editor.types";
import { useScriptCodeEditorContext } from "./script-code-editor-provider";

import useSaveShortcut from "~/hooks/use-save-shortcut";
import { removeZeroWidthCharacters } from "~/util/helper";

export interface IScriptDiffEditor extends IScriptCodeEditorBaseProps {
  /* Original source code. This will not be editable at all times. */
  originalSource?: string;
  /* Modified source code, which is compared against the `originalSource`. The editor allows further changes to this unless the `readonly` prop is set */
  modifiedSource?: string;
  initialModel?: Monaco.editor.IDiffEditorModel | null;
  onBlur?: (model?: Monaco.editor.IDiffEditorModel | null) => void;
}

/**
 * Component that renders a monaco diff editor configured with the language features of the Script language.
 * Make sure it is rendered within a `ScriptCodeEditorProvider` for the language features to work correctly.
 */
const ScriptDiffEditor = ({
  errors,
  initialModel,
  modifiedSource,
  onBlur,
  onChange,
  onSaveShortcut,
  originalSource,
  readonly,
  ...props
}: IScriptDiffEditor) => {
  const [editor, setEditor] = useState<Monaco.editor.IStandaloneDiffEditor>();

  const { dialect } = useScriptCodeEditorContext();

  useSaveShortcut(onSaveShortcut);
  useSetErrorsOnModel(editor?.getModel()?.modified, errors);

  const editorOptions = useMemo<Monaco.editor.IDiffEditorConstructionOptions | undefined>(
    () => ({
      ...BASE_SCRIPT_EDITOR_OPTIONS,
      readOnly: readonly,
    }),
    [readonly],
  );

  function handleEditorDidMount(editor: Monaco.editor.IStandaloneDiffEditor) {
    const temp = editor;

    if (initialModel) {
      temp.setModel(initialModel);
    }

    if (onChange) {
      temp.getModifiedEditor()?.onDidChangeModelContent(() => {
        onChange(removeZeroWidthCharacters(editor?.getModifiedEditor().getValue()));
      });
    }

    if (onBlur) {
      temp.getModifiedEditor().onDidBlurEditorWidget(() => {
        onBlur(editor.getModel());
      });
    }

    setEditor(temp);
  }

  // update the modified editor options to make it readonly when needed
  useEffect(() => {
    const temp = editor;

    temp?.getModifiedEditor()?.updateOptions({ readOnly: readonly ?? false });

    setEditor(temp);
    // Only execute this effect when `readonly` changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [readonly]);

  // Make sure we dispose the model on the current editor if the consumer doesn't provide an initial model, given
  // that `keepCurrentModel` prop is passed to the editor which disables the default behaviour of automatically
  // disposing the model on unmount
  useEffect(
    () => () => {
      if (!initialModel) {
        const temp = editor;

        temp?.getOriginalEditor()?.dispose();
        temp?.getModifiedEditor()?.dispose();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  return (
    <DiffEditor
      {...props}
      original={originalSource}
      modified={modifiedSource}
      language={dialect}
      onMount={handleEditorDidMount}
      options={editorOptions}
      keepCurrentModifiedModel
      keepCurrentOriginalModel
    />
  );
};

export default ScriptDiffEditor;
