import { useState } from "react";

import { ControlledEnumSelect, ControlledLyraEnumSelect } from "./controlled-enum-select";
import {
  IUncontrolledEnumSelectProps,
  IUncontrolledLyraEnumSelectProps,
} from "./enum-select.types";

/**
 * The props for the uncontrolled version of the enum select.
 * @param defaultValue The default value of the select.
 * @param rest The rest of the props to pass to the select (`Omit<IControlledEnumSelectProps, "value" | "onChange">`)
 * 
 * ### Example:
```tsx
import { EnumSelect } from "~/components/shared/enum-select";

// replicating EVA.Core.Management.ListSettings.TypedFilters
enum TypedFilters {
  ShowAll = 0,
  ShowTyped = 1,
  ShowUntyped = 2,
}

const labelLookup = {
  [TypedFilters.ShowAll]: "Show all",
  [TypedFilters.ShowTyped]: "Show typed",
  [TypedFilters.ShowUntyped]: "Show untyped",
};

export const TypedFilter = () => (
  <EnumSelect.Uncontrolled
    enum={TypedFilters}
    label="Setting type"
    name="TypedFiltersSelect"
    defaultValue={TypedFilters.ShowAll}
    getOptionLabel={(value) => labelLookup[value]}
  />
);
```
*/
export const UncontrolledEnumSelect = <
  EnumType extends object,
  EnumValue extends string | number = string | number,
>({
  defaultValue,
  enableOnClear,
  name,
  ...rest
}: IUncontrolledEnumSelectProps<EnumType, EnumValue>) => {
  const [value, setValue] = useState(defaultValue);
  return (
    <>
      <ControlledEnumSelect
        {...rest}
        value={value}
        onChange={setValue}
        onClear={enableOnClear ? () => setValue(undefined) : undefined}
        name={`${name}-uncontrolled`}
      />
      <input type="hidden" name={name} value={value ?? ""} />
    </>
  );
};

export const UncontrolledLyraEnumSelect = <
  EnumType extends object,
  EnumValue extends string | number = string | number,
>({
  defaultValue,
  enableOnClear,
  name,
  ...rest
}: IUncontrolledLyraEnumSelectProps<EnumType, EnumValue>) => {
  const [value, setValue] = useState(defaultValue);
  return (
    <>
      <ControlledLyraEnumSelect
        {...rest}
        value={value}
        onChange={setValue}
        name={`${name}-uncontrolled`}
      />
      <input type="hidden" name={name} value={value ?? ""} />
    </>
  );
};
