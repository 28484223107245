import { Fragment, useMemo } from "react";

import { Switch as HeadlessSwitch } from "@headlessui/react";
import classNames from "clsx";

import Text from "~/components/suite-ui/text";

export interface SwitchProps {
  name?: string;
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  defaultChecked?: boolean;
  label?: string;
  labelPosition?: "top" | "right" | "left";
  className?: string;
  labelClassName?: string;
  helperText?: string;
  error?: boolean;
  color?: "primary" | "success" | "error" | "warning";
  required?: boolean;
}

export const Switch = ({
  checked,
  color = "primary",
  defaultChecked,
  disabled,
  error,
  helperText,
  label,
  labelClassName,
  labelPosition = "right",
  name,
  onChange,
  required,
}: SwitchProps) => {
  const colorClasses = useMemo(() => {
    switch (color) {
      case "primary":
        return {
          backGround: "bg-action-default",
          border: "border-focus",
          ring: "ring-[color:var(--color-primary-3)]",
        };
      case "success":
        return {
          backGround: "bg-indication-green",
          border: "border-[color:var(--color-success-3)]",
          ring: "ring-[color:var(--color-success-3)]",
        };
      case "error":
        return {
          backGround: "bg-indication-red",
          border: "border-error",
          ring: "ring-[var(--color-error-3)]",
        };
      case "warning":
        return {
          backGround: "bg-indication-orange",
          border: "border-[color:var(--color-warning-3)]",
          ring: "ring-[color:var(--color-warning-3)]",
        };
      default:
        return {
          backGround: "bg-action-default",
          border: "border-focus",
          ring: "ring-[color:var(--color-primary-3)]",
        };
    }
  }, [color]);

  return (
    <HeadlessSwitch.Group>
      <div>
        {labelPosition === "top" ? (
          <SwitchLabel
            required={required}
            label={label}
            className={classNames("mb-5", labelClassName)}
            disabled={disabled}
          />
        ) : null}
        <div className="flex">
          {labelPosition === "left" ? (
            <SwitchLabel
              required={required}
              label={label}
              className={classNames("mr-5", labelClassName)}
              disabled={disabled}
            />
          ) : null}
          <HeadlessSwitch
            name={name}
            defaultChecked={defaultChecked}
            checked={checked}
            onChange={onChange}
            as={Fragment}
          >
            {({ checked }) => (
              <button
                disabled={disabled}
                className={classNames(
                  "relative inline-flex h-6 w-10 items-center rounded-full border border-solid p-0 transition-colors duration-300",
                  "focus-visible:ring-2 focus-visible:ring-offset-2",
                  `${colorClasses.ring}`,
                  disabled && "opacity-60",
                  checked
                    ? `${colorClasses.border} ${colorClasses.backGround}`
                    : "shadow-neutral-3/30 border-default bg-surface-tertiary shadow-inner",
                )}
              >
                <span
                  className={`${
                    checked ? "translate-x-5" : "translate-x-1"
                  } shadow-neutral-2/50 inline-block h-4 w-4 transform rounded-full bg-surface-primary shadow-sm transition`}
                />
              </button>
            )}
          </HeadlessSwitch>
          {labelPosition === "right" ? (
            <SwitchLabel
              required={required}
              label={label}
              className={classNames("ml-5", labelClassName)}
              disabled={disabled}
            />
          ) : null}
        </div>
        <HeadlessSwitch.Description
          as={Text}
          variant="body2"
          className={classNames("mt-1 ", error ? "text-error" : "text-secondary")}
        >
          {helperText}
        </HeadlessSwitch.Description>
      </div>
    </HeadlessSwitch.Group>
  );
};

const SwitchLabel = ({
  className,
  disabled,
  label,
  required,
}: {
  required?: SwitchProps["required"];
  className?: string;
  label: SwitchProps["label"];
  disabled?: SwitchProps["disabled"];
}) => {
  if (label) {
    return (
      <HeadlessSwitch.Label
        className={classNames(
          "text-legacy-base flex select-none items-center",
          disabled && "opacity-60",
          className,
        )}
        passive={disabled}
        as={Text}
        variant="body1"
      >
        {`${label}${required ? "*" : ""}`}
      </HeadlessSwitch.Label>
    );
  }
  return null;
};
