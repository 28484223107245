import { Reducer } from "react";

import { difference, isEmpty, omit } from "lodash";

import { DEFAULT_LIMIT } from "~/util/base-values";

type TSearchProductsRequestAction =
  | { type: "reset" }
  | { type: "setQuery"; query: string | undefined }
  | { type: "setPropertyFilter"; property: string; values: string[] }
  | { type: "removePropertyFilter"; property: string }
  | { type: "incrementLimit" };

export const searchProductsRequestReducer: Reducer<
  EVA.Core.SearchProducts,
  TSearchProductsRequestAction
> = (state, action) => {
  switch (action.type) {
    case "removePropertyFilter":
      return {
        ...state,
        Filters: omit({ ...state.Filters }, action.property),
        PageSize: state.Filters?.[action.property]?.Values?.length
          ? DEFAULT_LIMIT
          : state.PageSize ?? DEFAULT_LIMIT,
      };

    case "setPropertyFilter": {
      const previousValues = state.Filters?.[action.property]?.Values ?? [];
      const valuesChanged =
        !isEmpty(difference(previousValues, action.values)) ||
        !isEmpty(difference(action.values, previousValues));

      return {
        ...state,
        Filters: {
          ...state.Filters,
          [action.property]: {
            Values: action.values,
          },
        },
        PageSize: valuesChanged ? DEFAULT_LIMIT : state.PageSize ?? DEFAULT_LIMIT,
      };
    }

    case "incrementLimit":
      return {
        ...state,
        PageSize: (state.PageSize ?? DEFAULT_LIMIT) + DEFAULT_LIMIT,
      };

    case "setQuery":
      return {
        ...state,
        Query: action.query,
        PageSize: action.query !== state.Query ? DEFAULT_LIMIT : state.PageSize ?? DEFAULT_LIMIT,
      };

    case "reset":
      return {};
    default:
      return state;
  }
};
