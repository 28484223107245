import { EPurchaseOrderStatus } from "~/types/purchase-order-status";
import { intlAccessor } from "~/util/intl-accessor";

export const getPurchaseOrderStatusTanslation = (status: EPurchaseOrderStatus) => {
  const labelMapping: Record<EPurchaseOrderStatus, string> = {
    [EPurchaseOrderStatus.Open]: intlAccessor.formatMessage({
      id: "generic.label.open",
      defaultMessage: "Open",
    }),
    [EPurchaseOrderStatus.Completed]: intlAccessor.formatMessage({
      id: "generic.label.completed",
      defaultMessage: "Completed",
    }),
    [EPurchaseOrderStatus.Paid]: intlAccessor.formatMessage({
      id: "generic.label.paid",
      defaultMessage: "Paid",
    }),
    [EPurchaseOrderStatus.Shipped]: intlAccessor.formatMessage({
      id: "generic.label.shipped",
      defaultMessage: "Shipped",
    }),
    [EPurchaseOrderStatus.Placed]: intlAccessor.formatMessage({
      id: "generic.label.placed",
      defaultMessage: "Placed",
    }),
    [EPurchaseOrderStatus.Confirmed]: intlAccessor.formatMessage({
      id: "generic.label.confirmed",
      defaultMessage: "Confirmed",
    }),
  };

  return labelMapping[status as EPurchaseOrderStatus];
};
