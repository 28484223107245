import {
  cloneElement,
  DetailedHTMLProps,
  ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState,
} from "react";
import { Placement } from "react-aria";
import { useIntl } from "react-intl";

import { Popover, PopoverProps } from "@material-ui/core";
import {
  Button,
  ButtonProps,
  Popover as LyraPopover,
  PopoverTrigger,
  SvgIcon,
  Text as LyraText,
} from "@new-black/lyra";
import { cva } from "class-variance-authority";
import classNames from "clsx";

import Grid from "../grid";
import IconButton from "../icon-button";
import LinkButton, { LinkButtonProps } from "../link-button";
import Text, { TextProps } from "../text";

import HelpIcon from "./help-icon";
import InformationIcon from "./information-icon";

const popperContentStyled = cva("", {
  variants: {
    isExpanded: {
      false: "max-h-[185px] overflow-hidden",
    },
  },
});

const StyledPopperContent = ({
  isExpanded,
  width,
  ...props
}: DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  isExpanded?: boolean;
  width?: string;
}) => (
  <div
    className={popperContentStyled({ isExpanded: isExpanded })}
    {...props}
    style={{ width: width }}
  />
);

const StyledPopover = (props: PopoverProps) => <Popover {...props} className="rounded" />;

const StyledLabel = ({
  normalLineHeight = false,
  ...props
}: TextProps & { normalLineHeight?: boolean }) => (
  <Text
    className={classNames(
      "text-sm tracking-[0.44px] text-[color:var(--legacy-eva-color-transparent-900)]",
      !normalLineHeight && "leading-7",
    )}
    {...props}
  />
);

const StyledText = (props: TextProps) => {
  return (
    <Text
      {...props}
      className="inline text-[13px] leading-5 tracking-[0.25px] text-[color:var(--legacy-eva-color-transparent-500)]"
    />
  );
};

const StyledLinkButton = (props: LinkButtonProps) => (
  <LinkButton className="!inline !text-[13px] !leading-5 !tracking-[0.25px]" {...props} />
);

interface IContextualHelpIconProps {
  label?: ReactNode;
  text: string;
  moreLabel?: string;
  textCharacterLimit?: number;
  customIcon?: JSX.Element;
  iconPlacement?: "left" | "right";
  popoverWidth?: number;
  className?: string;
  size?: "small" | "medium";
  noWrap?: boolean;
  expectMultilineTitle?: boolean;
}

const ContextualHelpIcon = ({
  className,
  customIcon,
  expectMultilineTitle = false,
  iconPlacement = "right",
  label,
  moreLabel,
  noWrap,
  popoverWidth = 260,
  size = "medium",
  text,
  textCharacterLimit = 200,
}: IContextualHelpIconProps) => {
  const intl = useIntl();
  const [showPopper, setShowPopper] = useState(false);

  const displayedText = useMemo(
    () => (text.length > textCharacterLimit ? `${text.slice(0, textCharacterLimit)}...` : text),
    [text, textCharacterLimit],
  );

  const [isTextExpanded, setIsTextExpanded] = useState(displayedText === text);
  const anchorElement = useRef<HTMLDivElement | null>(null);

  const handleClose = useCallback(() => {
    setShowPopper(false);
    // reset expanded state
    setIsTextExpanded(displayedText === text);
  }, [displayedText, text]);

  return (
    <div ref={anchorElement} className={className}>
      <IconButton
        size={size}
        onClick={(e) => {
          e.stopPropagation();
          setShowPopper(true);
        }}
      >
        {customIcon ?? <HelpIcon />}
      </IconButton>
      {showPopper ? (
        <StyledPopover
          open={showPopper}
          onClose={handleClose}
          anchorEl={anchorElement.current}
          transformOrigin={{
            vertical: "top",
            horizontal: iconPlacement,
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: iconPlacement,
          }}
        >
          <StyledPopperContent isExpanded={isTextExpanded} width={`${popoverWidth}px`}>
            <div className={classNames(iconPlacement === "left" ? "pl-0" : "pl-5")}>
              <Grid
                container
                justifyContent={iconPlacement === "right" ? "space-between" : "flex-start"}
                alignItems="center"
                wrap={noWrap ? "nowrap" : undefined}
              >
                {iconPlacement === "left" ? (
                  <Grid item>
                    <IconButton onClick={handleClose}>
                      {customIcon ? (
                        cloneElement(customIcon, { color: "primary" })
                      ) : (
                        <HelpIcon color="primary" />
                      )}
                    </IconButton>
                  </Grid>
                ) : null}
                <Grid item>
                  {label ? (
                    <StyledLabel variant="h3" normalLineHeight={expectMultilineTitle}>
                      {label}
                    </StyledLabel>
                  ) : null}
                </Grid>
                {iconPlacement === "right" ? (
                  <Grid item>
                    <IconButton onClick={handleClose}>
                      {customIcon ? (
                        cloneElement(customIcon, { color: "primary" })
                      ) : (
                        <HelpIcon color="primary" />
                      )}
                    </IconButton>
                  </Grid>
                ) : null}
              </Grid>
            </div>
            <div className="break-words p-5 pt-0">
              <StyledText>{isTextExpanded ? text : displayedText.concat(" ")}</StyledText>
              {!isTextExpanded ? (
                <StyledLinkButton onClick={() => setIsTextExpanded(true)} variant="body2">
                  {moreLabel ??
                    intl.formatMessage({ id: "generic.label.more", defaultMessage: "More" })}
                </StyledLinkButton>
              ) : null}
            </div>
          </StyledPopperContent>
        </StyledPopover>
      ) : null}
    </div>
  );
};

ContextualHelpIcon.HelpIcon = HelpIcon;
ContextualHelpIcon.InformationIcon = InformationIcon;

export default ContextualHelpIcon;

export const LyraContextualHelpIcon = ({
  className,
  description,
  icon = <SvgIcon name="questionmark" />,
  label,
  placement = "bottom end",
  popoverClassName,
  variant = "icon",
}: {
  variant?: ButtonProps["variant"];
  icon?: ReactNode;
  label?: string | ReactNode;
  description?: string | ReactNode;
  className?: string;
  placement?: Placement;
  popoverClassName?: string;
}) => (
  <PopoverTrigger>
    <Button variant={variant} tooltip={label} className={className}>
      {icon}
    </Button>
    <LyraPopover className={classNames("w-80", popoverClassName)} placement={placement}>
      {() => (
        <div className="flex flex-col">
          {label ? (
            <LyraText slot="description" variant="heading-3">
              {label}
            </LyraText>
          ) : null}
          <LyraText slot="description">{description}</LyraText>
        </div>
      )}
    </LyraPopover>
  </PopoverTrigger>
);
