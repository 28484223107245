import { useMemo } from "react";

import { CoreManagement } from "@springtree/eva-services-core-management";

import { listAssortmentsQuery, useGetAssortmentByIDQuery } from "~/models/assortments";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";
import { SearchListFieldGenerator } from "~/util/search-list-field-generator";

function useAssortmentByID(id: number | undefined) {
  const { data: resp, isFetching } = useGetAssortmentByIDQuery(
    id !== undefined ? { ID: id } : undefined,
    {},
  );

  const data = useMemo(() => {
    if (id !== undefined && resp?.ID === id) {
      return resp;
    }
    return undefined;
  }, [resp, id]);

  const isLoading = useMemo(() => id !== undefined && isFetching, [id, isFetching]);

  return { data, isLoading };
}

export const AssortmentSearchListField = SearchListFieldGenerator<
  CoreManagement.ListAssortments,
  EVA.Core.Management.ListAssortmentsResponse.AssortmentDto,
  "ID"
>({
  idKey: "ID",
  labelKey: "Name",
  useItemByID: useAssortmentByID,
  getItemFromResponse: (resp) => resp?.Result.Page,
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.assortment-id",
    defaultMessage: "Assortment ID",
  }),
  useServiceQuery: () =>
    SearchListFieldGenerator.useSearchListFieldService({
      query: listAssortmentsQuery,
      initialRequest: { PageConfig: { Start: 0, Limit: 10, Filter: { Name: undefined } } },
      getQueryRequest: (req) => req?.PageConfig?.Filter?.["Name"],
      setQueryRequest: (req, value) => ({
        ...req,
        PageConfig: {
          ...req?.PageConfig,
          Filter: {
            ...req?.PageConfig?.Filter,
            Name: value,
          },
        },
      }),
    }),
});

export const AssortmentLyraSearchListField = LyraSearchListFieldGenerator<
  CoreManagement.ListAssortments,
  EVA.Core.Management.ListAssortmentsResponse.AssortmentDto,
  number
>({
  getItemId: (item) => item.ID,
  getLabel: (item) => item.Name,
  useItemByID: useAssortmentByID,
  getItemFromResponse: (resp) => resp?.Result.Page,
  defaultLabel: intlAccessor.formatMessage({
    id: "generic.label.assortment-id",
    defaultMessage: "Assortment ID",
  }),
  useServiceQuery: () =>
    LyraSearchListFieldGenerator.useSearchListFieldService({
      query: listAssortmentsQuery,
      initialRequest: { PageConfig: { Start: 0, Limit: 10, Filter: { Name: undefined } } },
      getQueryRequest: (req) => req?.PageConfig?.Filter?.["Name"],
      setQueryRequest: (req, value) => ({
        ...req,
        PageConfig: {
          ...req?.PageConfig,
          Filter: {
            ...req?.PageConfig?.Filter,
            Name: value,
          },
        },
      }),
    }),
});
