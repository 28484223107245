import { CoreManagement } from "@springtree/eva-services-core-management";

import { intlAccessor } from "~/util/intl-accessor";
import { mutate } from "~/util/mutate";

export const mutateUpdateLoyaltyProgramPaymentType = () =>
  mutate({
    service: CoreManagement.UpdateLoyaltyProgramPaymentType,
    messages: {
      loadingMessage: intlAccessor.formatMessage({
        id: "generic.message.update-payment-type.loading",
        defaultMessage: "Updating payment type...",
      }),
      successMessage: intlAccessor.formatMessage({
        id: "generic.message.update-payment-type.success",
        defaultMessage: "Payment type successfully updated.",
      }),
    },
  });

export const mutateUpdateLoyaltyProgramProductLimitation = () =>
  mutate({
    service: CoreManagement.UpdateLoyaltyProgramProductLimitation,
    messages: {
      loadingMessage: intlAccessor.formatMessage({
        id: "generic.message.update-product-limitation.loading",
        defaultMessage: "Updating product limitation...",
      }),
      successMessage: intlAccessor.formatMessage({
        id: "generic.message.update-product-limitation.success",
        defaultMessage: "Product limitation successfully updated.",
      }),
    },
  });
