import { useCallback, useMemo } from "react";
import { useIntl } from "react-intl";

import { z } from "zod";

import { DayOfWeek as DayOfWeekEnum } from "~/types/translations-enums";

export const DAYS_OF_WEEK_ENUM = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
] as const;

export const DayOfWeekSchema = z.enum(DAYS_OF_WEEK_ENUM);

export type DayOfWeek = z.infer<typeof DayOfWeekSchema>;

export const dayOfWeekToNumberMapper: Record<DayOfWeek, DayOfWeekEnum> = {
  Sunday: DayOfWeekEnum.Sunday,
  Monday: DayOfWeekEnum.Monday,
  Tuesday: DayOfWeekEnum.Tuesday,
  Wednesday: DayOfWeekEnum.Wednesday,
  Thursday: DayOfWeekEnum.Thursday,
  Friday: DayOfWeekEnum.Friday,
  Saturday: DayOfWeekEnum.Saturday,
};

const useIntlDayOfWeek = () => {
  const intl = useIntl();

  const getIntlDayOfWeek = useCallback(
    (dayOfWeek: DayOfWeek) =>
      intl.formatMessage({
        id: `opening-times.days.day-${dayOfWeekToNumberMapper[dayOfWeek]}`,
        defaultMessage: dayOfWeek,
      }),
    [intl],
  );

  const dayOfWeekToNumber = useCallback(
    (dayOfWeek: DayOfWeek) => dayOfWeekToNumberMapper[dayOfWeek],
    [],
  );

  const numberToDayOfWeek = useCallback((dayOfWeekNumber: number) => {
    const daysOfWeek = Object.entries(dayOfWeekToNumberMapper);
    const dayOfWeek = daysOfWeek.find((entry) => entry[1] === dayOfWeekNumber);
    return dayOfWeek?.[0];
  }, []);

  const daysOfWeekList = useMemo(() => DAYS_OF_WEEK_ENUM, []);

  return { getIntlDayOfWeek, dayOfWeekToNumber, numberToDayOfWeek, daysOfWeekList };
};

export default useIntlDayOfWeek;
