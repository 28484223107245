import { useEffect } from "react";

import { IServiceFamilyState } from "@springtree/eva-sdk-react-recoil/lib/builders/build-service-family-state";
import { IServiceState } from "@springtree/eva-sdk-react-recoil/lib/builders/build-service-state";
import { IEvaServiceDefinition } from "@springtree/eva-services-core";
import { SerializableParam } from "recoil";

import { toast } from "~/components/suite-ui/toast";
import { useServiceError } from "~/hooks/suite-react-hooks/use-service-error";
import { ErrorTypes } from "~/types/error-types";

export interface IServiceFamilyStateArgs<T extends IEvaServiceDefinition> {
  serviceFamilyState: IServiceFamilyState<T>;
  parameter: SerializableParam;
  serviceState?: never;
}
export interface IServiceStateArgs<T extends IEvaServiceDefinition> {
  serviceState: IServiceState<T>;
  serviceFamilyState?: never;
  parameter?: never;
}

/**
 * Hook which executes a callback when a service responds with a 404 / EntityNotFound error.
 *
 * @param props serviceState or serviceFamilyState with parameter (forwarded to useServiceError)
 * @param callback function to call when the service responds with an 'EntityNotFound' error
 */
const useOn404 = <T extends IEvaServiceDefinition>(
  props: IServiceFamilyStateArgs<T> | IServiceStateArgs<T>,
  callback: (t: typeof toast) => void,
) => {
  const serviceStateError = useServiceError(props);

  useEffect(() => {
    if (
      serviceStateError &&
      !(serviceStateError instanceof Error) &&
      typeof serviceStateError !== "string"
    ) {
      if ((serviceStateError as any)?.Error?.Type === ErrorTypes.EntityNotFound) {
        callback(toast);
      }
    }
  }, [callback, serviceStateError]);
};

export default useOn404;
