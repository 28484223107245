import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listStationsLoaderQuery,
  serviceQuery: listStationsQuery,
  serviceQueryKeys: listStationsQueryKeys,
  useServiceQueryHook: useListStationsQuery,
  useServiceQueryWithRequest: useListStationsQueryWithRequest,
} = createServiceQuery(Core.ListStations);

export const {
  serviceLoaderQuery: getStationLoaderQuery,
  serviceQueryKeys: getStationQueryKeys,
  useServiceQueryHook: useGetStationQuery,
  useServiceQueryWithRequest: useGetStationQueryWithRequest,
} = createServiceQuery(Core.GetStation);

export const {
  serviceLoaderQuery: listStationsForOrganizationUnitLoaderQuery,
  serviceQuery: listStationsForOrganizationUnitQuery,
  serviceQueryKeys: listStationsForOrganizationUnitQueryKeys,
  useServiceQueryHook: useListStationsForOrganizationUnitQuery,
  useServiceQueryWithRequest: useListStationsForOrganizationUnitQueryWithRequest,
} = createServiceQuery(Core.ListStationsForOrganizationUnit);
