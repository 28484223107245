import { CoreManagement } from "@springtree/eva-services-core-management";

import { useReturnReasonsById } from "./use-return-reason-by-id";

import { listReturnReasonsQuery } from "~/models/reasons";
import { DEFAULT_LIMIT } from "~/util/base-values";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export const generateReturnReasonsLyraSearchListField = (
  initialFilters?: EVA.Core.Management.ListReturnReasonsFilter,
) =>
  LyraSearchListFieldGenerator<
    CoreManagement.ListReturnReasons,
    {
      ID?: number;
      Name?: string;
    },
    number
  >({
    getItemId: (item) => item.ID!,
    getLabel: (item) => item.Name!,
    selectRenderElements: (item) => ({
      label: item.Name,
    }),
    getItemFromResponse: (resp) =>
      resp?.Result?.Page?.map((ou) => ({
        ID: ou.ID,
        Name: ou.Name,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.return-reason",
      defaultMessage: "Return reason",
    }),
    useItemByID: (id) => {
      const { isLoading, item: data } = useReturnReasonsById(id as number);
      return { data, isLoading };
    },
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: listReturnReasonsQuery,
        getQueryRequest: (req) => req?.PageConfig?.Filter?.Name,
        setQueryRequest: (req, newValue) => ({
          ...req,
          PageConfig: {
            ...req?.PageConfig,
            Filter: {
              ...req?.PageConfig?.Filter,
              Name: newValue === "" ? undefined : newValue,
            },
          },
        }),
        initialRequest: { PageConfig: { Start: 0, Limit: DEFAULT_LIMIT, Filter: initialFilters } },
      }),
  });
