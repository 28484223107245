import { useMemo } from "react";
import { useIntl } from "react-intl";

import { FieldGroup, NumberField } from "@new-black/lyra";
import { Field, FieldProps } from "formik";
import { isNil } from "lodash";

import { NUMBER_FORMAT_OPTIONS } from "~/util/number-format-options";

interface IMinMaxValues {
  fieldType: "integer" | "decimal";
}

const MinMaxValues = ({ fieldType }: IMinMaxValues) => {
  const intl = useIntl();

  const fieldFormatOptions = useMemo(
    () => (fieldType === "decimal" ? NUMBER_FORMAT_OPTIONS.integer : NUMBER_FORMAT_OPTIONS.float),
    [fieldType],
  );

  return (
    <FieldGroup className="flex-row">
      <Field name="MinimumValue">
        {({ field, form }: FieldProps) => (
          <NumberField
            label={intl.formatMessage({
              id: "custom-field.input.minimum-value.label",
              defaultMessage: "Minimum value",
            })}
            {...field}
            formatOptions={fieldFormatOptions}
            value={field.value ?? null}
            onChange={(value) => {
              if (isNil(value) || Number.isNaN(value) || value < 0) {
                form.setFieldValue("MinimumValue", undefined);
                return;
              }
              form.setFieldValue("MinimumValue", value);
            }}
          />
        )}
      </Field>
      <Field name="MaximumValue">
        {({ field, form }: FieldProps) => (
          <NumberField
            label={intl.formatMessage({
              id: "custom-field.input.maximum-value.label",
              defaultMessage: "Maximum value",
            })}
            {...field}
            formatOptions={fieldFormatOptions}
            value={field.value ?? null}
            onChange={(value) => {
              if (isNil(value) || Number.isNaN(value) || (value && value < 0)) {
                form.setFieldValue("MaximumValue", undefined);
                return;
              }
              form.setFieldValue("MaximumValue", value);
            }}
          />
        )}
      </Field>
    </FieldGroup>
  );
};

export default MinMaxValues;
