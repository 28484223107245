import { useMemo } from "react";
import { useIntl } from "react-intl";

import { MultiSearchListField } from "@new-black/lyra";

import { ProductPropertyTypeSearchTypes } from "~/types/eva-core";
import { getProductPropertyTypeSearchTypeTanslation } from "~/types/translations-enums/product-property-type-search-types";
import enumToArray from "~/util/enum-to-array";

interface IProductPropertySearchTypeSearchListField {
  value?: number[];
  onChange: (val: number[]) => void;
  hideHintLabel?: boolean;
  isRequired?: boolean;
  errorMessage?: string;
}

export const ProductPropertySearchTypeSearchListField = ({
  errorMessage,
  hideHintLabel,
  isRequired,
  onChange,
  value,
}: IProductPropertySearchTypeSearchListField) => {
  const intl = useIntl();

  const translateProductPropertySearchType = useMemo(
    () => getProductPropertyTypeSearchTypeTanslation(intl),
    [intl],
  );

  const items = useMemo(
    () =>
      enumToArray(ProductPropertyTypeSearchTypes)?.map((entry) => ({
        ...entry,
        key: translateProductPropertySearchType(entry.value) ?? entry.key,
      })),
    [translateProductPropertySearchType],
  );

  const selectedValue = useMemo(
    () => items?.filter((item) => value?.includes(item.value)) ?? [],
    [items, value],
  );

  return (
    <MultiSearchListField
      isRequired={isRequired}
      hideHintLabel={hideHintLabel}
      getItemId={(item) => item.value}
      getLabel={(item) => item.key}
      selectRenderElements={(item) => ({ label: item.key })}
      value={selectedValue}
      items={items ?? []}
      onChange={(newValue) => onChange(newValue?.map((item) => item.value) ?? [])}
      label={intl.formatMessage({ id: "generic.label.search-type", defaultMessage: "Search type" })}
      errorMessage={errorMessage}
    />
  );
};
