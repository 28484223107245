import { useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { NumberField } from "@new-black/lyra";

import {
  loyaltyProgramRuleProductSetConditionKeys,
  TLoyaltyProgramRuleProductSetCondition,
  TLoyaltyProgramRuleProductSetConditionErrors,
} from "../";

import { IRequiredAmountRadioOption } from "~/components/shared/eva-product-set/eva-product-set.types";
import { useCurrencyPrecision } from "~/util/hooks/use-currency-precision";

const RequiredAmountOrQuantityFields = ({
  errors,
  initialValues,
}: {
  errors?: TLoyaltyProgramRuleProductSetConditionErrors;
  initialValues?: TLoyaltyProgramRuleProductSetCondition;
}) => {
  const intl = useIntl();
  const precision = useCurrencyPrecision();

  const [selectedOption] = useState(
    initialValues?.RequiredProductAmount
      ? IRequiredAmountRadioOption.AmountSpentOnProduct
      : IRequiredAmountRadioOption.QuantityOfProducts,
  );

  const formatOptions = useMemo(
    () =>
      ({
        style: "currency",
        currency: undefined,
        maximumFractionDigits: precision,
      } as const),
    [precision],
  );

  return (
    <>
      {/* // TODO: uncomment after BE adds support for `AmountSpentOnProduct` */}
      {/* <RadioButtonList
        name=""
        label={intl.formatMessage({
          id: "generic.label.type",
          defaultMessage: "Type",
        })}
        selectedValue={selectedOption}
        handleSelectedValueChange={(_, value) => {
          if (selectedOption !== value) {
            setSelectedOption(value as IRequiredAmountRadioOption);
          }
        }}
      >
        <RadioButtonLabel
          label={intl.formatMessage({
            id: "edit-discount.product-set.required-quantity",
            defaultMessage: "Required quantity of products",
          })}
          value={IRequiredAmountRadioOption.QuantityOfProducts}
          activeValue={selectedOption}
        />
        <RadioButtonLabel
          label={intl.formatMessage({
            id: "edit-discount.product-set.required-amount-spent",
            defaultMessage: "Required amount spent on products",
          })}
          value={IRequiredAmountRadioOption.AmountSpentOnProduct}
          activeValue={selectedOption}
        />
      </RadioButtonList> */}

      {selectedOption === IRequiredAmountRadioOption.QuantityOfProducts ? (
        <NumberField
          name={loyaltyProgramRuleProductSetConditionKeys.RequiredQuantityOfProducts}
          isRequired
          label={intl.formatMessage({
            id: "edit-discount.product-set.required-quantity",
            defaultMessage: "Required quantity of products",
          })}
          errorMessage={errors?.RequiredQuantityOfProducts}
          defaultValue={initialValues?.RequiredQuantityOfProducts}
        />
      ) : (
        <NumberField
          isRequired
          name={loyaltyProgramRuleProductSetConditionKeys.RequiredProductAmount}
          label={intl.formatMessage({
            id: "edit-discount.product-set.required-amount-spent",
            defaultMessage: "Required amount spent on products",
          })}
          formatOptions={formatOptions}
          errorMessage={errors?.RequiredProductAmount}
          defaultValue={initialValues?.RequiredProductAmount}
        />
      )}
    </>
  );
};

export default RequiredAmountOrQuantityFields;
