import { Core } from "@springtree/eva-services-core";
import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: getUserLoaderQuery,
  serviceQuery: getUserQuery,
  useServiceQueryHook: useGetUserQuery,
} = createServiceQuery(Core.GetUser);

export const {
  serviceLoaderQuery: getUserPhoneNumbersLoaderQuery,
  serviceQuery: getUserPhoneNumbersQuery,
  serviceQueryKeys: getUserPhoneNumbersQueryKeys,
  useServiceQueryHook: useGetUserPhoneNumbersQuery,
} = createServiceQuery(Core.GetUserPhoneNumbers);

export const {
  serviceLoaderQuery: searchUsersLoaderQuery,
  serviceQuery: searchUsersQuery,
  serviceQueryKeys: searchUsersQueryKeys,
  useServiceQueryHook: useSearchUsersQuery,
  useServiceQueryWithRecoilRequest: useSearchUsersQueryWithRecoilRequest,
  useServiceQueryWithRequest: useSearchUsersQueryWithRequest,
} = createServiceQuery(Core.SearchUsers, true, undefined, false);

export const {
  serviceLoaderQuery: listAddresBookLoaderQuery,
  serviceQuery: listAddresBookQuery,
  serviceQueryKeys: listAddresBookQueryKeys,
  useServiceQueryHook: useListAddressBookQuery,
  useServiceQueryWithRecoilRequest: useListAddressBookQueryWithRecoilRequest,
  useServiceQueryWithRequest: useListAddressBookQueryWithRequest,
} = createServiceQuery(Core.ListAddressBook, true);

export const {
  serviceLoaderQuery: listUserInteractionsLoaderQuery,
  serviceQuery: listUserInteractionsQuery,
  serviceQueryKeys: listUserInteractionsQueryKeys,
  useServiceQueryHook: useListUserInteractionsQuery,
  useServiceQueryWithRecoilRequest: useListUserInteractionsQueryWithRecoilRequest,
  useServiceQueryWithRequest: useListUserInteractionsQueryWithRequest,
} = createServiceQuery(Core.ListUserInteractions, true);

export const {
  serviceLoaderQuery: getUserInquiriesLoaderQuery,
  serviceQuery: getUserInquiriesQuery,
  serviceQueryKeys: getUserInquiriesQueryKeys,
  useServiceQueryHook: useGetUserInquiriesQuery,
  useServiceQueryWithRecoilRequest: useGetUserInquiriesQueryWithRecoilRequest,
  useServiceQueryWithRequest: useGetUserInquiriesQueryWithRequest,
} = createServiceQuery(Core.GetUserInquiries, true);

export const {
  serviceLoaderQuery: getUserInquiryLoaderQuery,
  serviceQuery: getUserInquiryQuery,
  serviceQueryKeys: getUserInquiryQueryKeys,
  useServiceQueryHook: useGetUserInquiryQuery,
  useServiceQueryWithRecoilRequest: useGetUserInquiryQueryWithRecoilRequest,
  useServiceQueryWithRequest: useGetUserInquiryQueryWithRequest,
} = createServiceQuery(Core.GetUserInquiry, true);

export const {
  serviceLoaderQuery: getUserCasesLoaderQuery,
  serviceQuery: getUserCasesQuery,
  serviceQueryKeys: getUserCasesQueryKeys,
  useServiceQueryHook: useGetUserCasesQuery,
  useServiceQueryWithRecoilRequest: useGetUserCasesQueryWithRecoilRequest,
  useServiceQueryWithRequest: useGetUserCasesQueryWithRequest,
} = createServiceQuery(CoreManagement.ListCases, true);

export const {
  serviceLoaderQuery: getUserSubscriptionsLoaderQuery,
  serviceQueryKeys: getUserSubscriptionsQueryKeys,
  useServiceQueryHook: useGetUserSubscriptionsQuery,
  useServiceQueryWithRecoilRequest: useGetUserSubscriptionsQueryWithRecoilRequest,
  useServiceQueryWithRequest: useGetUserSubscriptionsQueryWithRequest,
} = createServiceQuery(Core.GetUserSubscriptions, true);

export const {
  serviceLoaderQuery: getUserLoyaltySubscriptionsLoaderQuery,
  serviceQuery: getUserLoyaltySubscriptionsQuery,
  serviceQueryKeys: getUserLoyaltySubscriptionsQueryKeys,
  useServiceQueryHook: useGetUserLoyaltySubscriptionsQuery,
  useServiceQueryWithRecoilRequest: useGetUserLoyaltySubscriptionsQueryWithRecoilRequest,
  useServiceQueryWithRequest: useGetUserLoyaltySubscriptionsQueryWithRequest,
} = createServiceQuery(Core.GetUserLoyaltySubscriptions, true);

export const {
  serviceLoaderQuery: getCompanyForUserLoaderQuery,
  serviceQuery: getCompanyForUserQuery,
  serviceQueryKeys: getCompanyForUserQueryKeys,
  useServiceQueryHook: useGetCompanyForUserQuery,
} = createServiceQuery(Core.GetCompanyForUser, true, false);

export const {
  serviceLoaderQuery: listUserBoughtProductsLoaderQuery,
  serviceQuery: listUserBoughtProductsQuery,
  serviceQueryKeys: listUserBoughtProductsQueryKeys,
  useServiceQueryHook: useListUserBoughtProductsQuery,
  useServiceQueryWithRequest: useListUserBoughtProductsQueryWithRequest,
} = createServiceQuery(CoreManagement.ListUserBoughtProducts, true, false);

export const {
  serviceLoaderQuery: listUserPersonalizedPromotionsLoaderQuery,
  serviceQuery: listUserPersonalizedPromotionsQuery,
  serviceQueryKeys: listUserPersonalizedPromotionsQueryKeys,
  useServiceQueryHook: useListUserPersonalizedPromotionsQuery,
} = createServiceQuery(CoreManagement.ListUserPersonalizedPromotions, true, false);

export const {
  serviceLoaderQuery: getUserPersonalizedPromotionProductsLoaderQuery,
  serviceQuery: getUserPersonalizedPromotionProductsQuery,
  serviceQueryKeys: getUserPersonalizedPromotionProductsQueryKeys,
  useServiceQueryHook: useGetUserPersonalizedPromotionProductsQuery,
} = createServiceQuery(CoreManagement.GetUserPersonalizedPromotionProducts, true, false);

export const {
  serviceLoaderQuery: getUserBoughtProductLoaderQuery,
  serviceQuery: getUserBoughtProductQuery,
  serviceQueryKeys: getUserBoughtProductQueryKeys,
  useServiceQueryHook: useGetUserBoughtProductQuery,
} = createServiceQuery(CoreManagement.GetUserBoughtProduct, true, false);

export const {
  serviceLoaderQuery: getProductWarrantyLoaderQuery,
  serviceQuery: getProductWarrantyQuery,
  useServiceQueryHook: useGetProductWarrantyQuery,
} = createServiceQuery(Core.GetProductWarranty, true, false);
