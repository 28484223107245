import { useEffect, useMemo, useState } from "react";
import { useIntl } from "react-intl";

import { hooks } from "@springtree/eva-sdk-react-recoil";
import { useRecoilValue } from "recoil";

import { entityTranslationsFilterTermAtom, entityTranslationsState } from "../state";
import { EntityTranslationFieldIdentifiers } from "../types";

import { useHandleSWRFamily } from "~/hooks/suite-react-hooks/use-handle-swr";
import useSetRequest from "~/hooks/suite-react-hooks/use-set-request";
import useFuseInstance from "~/hooks/use-fuse-instance";

interface EntityTranslation extends EVA.Core.ListEntityTranslation {
  CountryName?: string;
  LanguageName?: string;
}

const useGetEntityTranslationsState = ({
  entityField,
  entityID,
  entityType,
}: EntityTranslationFieldIdentifiers) => {
  const intl = useIntl();

  const response = hooks.useGetState(
    entityTranslationsState.response({ entityField, entityID, entityType }),
  );

  const resultsInStore = useMemo(() => response?.Result?.Page, [response?.Result?.Page]);

  const [results, setResults] = useState<EntityTranslation[]>([]);

  useEffect(() => {
    if (resultsInStore) {
      setResults(
        resultsInStore.map((translation) => ({
          ...translation,
          CountryName: translation.CountryID
            ? intl.formatDisplayName(translation.CountryID, { type: "region" })
            : undefined,
          LanguageName: translation.LanguageID
            ? intl.formatDisplayName(translation.LanguageID, {
                type: "language",
              })
            : undefined,
        })),
      );
    }
  }, [intl, resultsInStore]);

  const newRequest = useMemo<EVA.Core.ListEntityTranslations | undefined>(() => {
    if (entityField && entityID && entityType) {
      return {
        PageConfig: {
          SortProperty: "CountryID",
          SortDirection: 0,
          Filter: {
            EntityField: entityField,
            EntityID: entityID,
            EntityType: entityType,
          },
        },
      };
    }
    return undefined;
  }, [entityField, entityID, entityType]);

  useSetRequest(entityTranslationsState.request({ entityField, entityID, entityType }), newRequest);

  const loading = hooks.useIsLoading({
    state: entityTranslationsState.response({
      entityField,
      entityID,
      entityType,
    }),
  });

  const loadingWithoutResponse = useMemo(() => !response && loading, [loading, response]);

  useHandleSWRFamily(entityTranslationsState, {
    entityField,
    entityID,
    entityType,
  });

  const fuseInstance = useFuseInstance(
    ["CountryID", "CountryName", "LanguageID", "LanguageName"],
    results,
  );

  const filterTerm = useRecoilValue(
    entityTranslationsFilterTermAtom({
      entityField,
      entityID,
      entityType,
    }),
  );

  const filteredResults = useMemo(() => {
    if (fuseInstance && filterTerm) {
      return fuseInstance
        .search({
          $or: [
            { CountryID: filterTerm },
            { CountryName: filterTerm },
            { LanguageID: filterTerm },
            { LanguageName: filterTerm },
          ],
        })
        .map((result) => ({ ...result.item }));
    }

    return results;
  }, [filterTerm, fuseInstance, results]);

  return {
    results,
    setResults,
    filteredResults,
    loading,
    loadingWithoutResponse,
  };
};

export default useGetEntityTranslationsState;
