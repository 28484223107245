import { IEvaServiceCallOptions } from "@springtree/eva-sdk-core-service";
import { CoreManagement } from "@springtree/eva-services-core-management";

import { intlAccessor } from "~/util/intl-accessor";
import { mutate } from "~/util/mutate";

export const mutateUpdateCustomField = (throwOnError?: boolean) => {
  const updateCustomFieldHandler = mutate({
    service: CoreManagement.UpdateCustomField,
    messages: {
      successMessage: intlAccessor.formatMessage({
        id: "custom-field.actions.update.success",
        defaultMessage: "Custom field successfully updated",
      }),
    },
  });

  const decoratedUpdateCustomFieldHandler = (
    request: EVA.Core.Management.UpdateCustomField | undefined,
    options?: IEvaServiceCallOptions | undefined,
  ) => updateCustomFieldHandler(request, options, throwOnError);

  return decoratedUpdateCustomFieldHandler;
};
