import { CoreManagement } from "@springtree/eva-services-core-management";

import { getSubscriptionHandlersQuery } from "~/models/subscriptions";
import { intlAccessor } from "~/util/intl-accessor";
import { SearchListFieldGenerator as LyraSearchListFieldGenerator } from "~/util/lyra-search-list-field-generator";

export interface ISubscriptionHandlerAutocompleteItem {
  key: string;
  value: string;
}

export const generateSubscriptionHandlerLyraSearchListField = ({
  frontendFilter,
}: {
  frontendFilter?: (handler: ISubscriptionHandlerAutocompleteItem) => boolean;
}) =>
  LyraSearchListFieldGenerator<
    CoreManagement.GetSubscriptionHandlers,
    ISubscriptionHandlerAutocompleteItem,
    string
  >({
    getItemId: (item) => item.value,
    getLabel: (item) => item.key,
    selectRenderElements: (item) => ({
      label: item.value,
    }),
    getItemFromResponse: (resp) =>
      resp?.Handlers?.map((handler) => ({
        value: handler,
        key: handler,
      })),
    defaultLabel: intlAccessor.formatMessage({
      id: "generic.label.handler",
      defaultMessage: "Handler",
    }),
    useItemByID: (id, items) => ({ data: items?.find((item) => item.value === id) }),
    useServiceQuery: () =>
      LyraSearchListFieldGenerator.useSearchListFieldService({
        refetchOnFocus: false,
        query: getSubscriptionHandlersQuery,
      }),
    frontendFilter,
  });
