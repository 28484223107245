import { ReactNode } from "react";

import { Dialog, DialogCard, DialogProps, Separator } from "@new-black/lyra";
import classNames from "clsx";

import { endPaneStyle, startPaneStyle } from "./styles";

export interface ILyraSplitDialogProps extends Omit<DialogProps, "children"> {
  startPaneContent: ReactNode;
  endPaneContent: ReactNode;
  paneProportions?: "1/3-2/3" | "2/3-1/3" | "1/2-1/2" | "1/4-3/4";
  className?: string;
}

const LyraSplitDialog = ({
  className,
  endPaneContent,
  maxWidth = "7xl",
  paneProportions = "1/3-2/3",
  startPaneContent,
  ...dialogProps
}: ILyraSplitDialogProps) => (
  <Dialog {...dialogProps} maxWidth={maxWidth}>
    <LyraSplitDialogContent
      startPaneContent={startPaneContent}
      endPaneContent={endPaneContent}
      className={className}
      paneProportions={paneProportions}
    />
  </Dialog>
);

export const LyraSplitDialogContent = ({
  className,
  endPaneContent: endPaneContent,
  paneProportions = "1/3-2/3",
  startPaneContent: startPaneContent,
}: Pick<
  ILyraSplitDialogProps,
  "className" | "startPaneContent" | "paneProportions" | "endPaneContent"
>) => (
  <DialogCard>
    <div className={classNames("flex min-h-[63.5dvh] w-full overflow-hidden", className)}>
      <div className={startPaneStyle({ proportions: paneProportions })}>{startPaneContent}</div>
      <Separator className="h-[unset] self-stretch" orientation="vertical" />
      <div className={endPaneStyle({ proportions: paneProportions })}>{endPaneContent}</div>
    </div>
  </DialogCard>
);

export default LyraSplitDialog;
