import { FormattedMessage } from "react-intl";

import { ConfirmationModal } from "~/components/suite-composite/confirmation-modal";

interface ITaskAssigneeWarningDialog {
  open: boolean;
  onClose: (accept: boolean) => void;
}

const TaskAssigneeWarningDialog = ({ onClose, open }: ITaskAssigneeWarningDialog) => (
  <ConfirmationModal
    isOpen={open}
    onCancel={() => onClose(false)}
    onConfirm={() => onClose(true)}
    maxWidth="xs"
    messages={{
      confirmButtonMessage: <FormattedMessage id="generic.label.yes" defaultMessage="Yes" />,
      cancelButtonMessage: <FormattedMessage id="generic.label.no" defaultMessage="No" />,
      titleMessage: <FormattedMessage id="generic.label.warning" defaultMessage="Warning" />,
      descriptionMessage: (
        <FormattedMessage
          id="task-assigned-warning-dialog.text"
          defaultMessage="This task is assigned to a different user, would you like to continue and assign it to yourself?"
        />
      ),
    }}
  />
);

export default TaskAssigneeWarningDialog;
