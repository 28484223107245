import { Dispatch } from "react";
import { FormattedMessage } from "react-intl";

import { Button } from "@new-black/lyra";

import { LoginAction, LoginState } from "./login.state";
import { LoginActionData } from "./login.types";
import { LoginWithSSO } from "./login-sso-form";

import { TextDivider } from "~/components/suite-ui/text-divider/text-divider";

type SSOViewProps = {
  providers?: EVA.Authentication.OpenID.AvailableOpenIDConfiguration[];
  loginState: LoginState;
  dispatchLoginState: Dispatch<LoginAction>;
  actionData?: LoginActionData;
  onLogin?: () => void;
};

export function SSOView({
  actionData,
  dispatchLoginState,
  loginState,
  onLogin,
  providers,
}: SSOViewProps) {
  return (
    <div className="flex flex-col">
      <LoginWithSSO
        providers={providers}
        processingProvider={loginState.processingSSOProvider}
        serviceError={actionData?.serviceError}
        onLogin={onLogin}
        onProcessing={(processingProvider?: string) =>
          dispatchLoginState({
            type: "setAll",
            state: { status: "processing", processingSSOProvider: processingProvider },
          })
        }
      />

      <div className="mt-6">
        <TextDivider>
          <FormattedMessage id="generic.label.or" defaultMessage="Or" />
        </TextDivider>
      </div>
      <Button
        className="mt-6"
        variant="secondary"
        fullWidth
        onPress={() => dispatchLoginState({ type: "setView", view: "main" })}
      >
        <FormattedMessage
          id="generic.label.back-to-previous-page"
          defaultMessage="Back to previous page"
        />
      </Button>
    </div>
  );
}
