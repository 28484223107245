import { useIntl } from "react-intl";
import { Column } from "react-table";

import { Table as LyraTable, TableColumnDef, TableProps } from "@new-black/lyra";

import useProductsListColumns from "./use-products-list-columns";

import Table from "~/components/suite-ui/table";
import { ITableProps } from "~/components/suite-ui/table/table-types";

interface IBaseProps {
  placeholderImageUrl?: string;
}

type MaterialTableRequiredProps = Required<
  Pick<ITableProps<any>, "data" | "skip" | "setSkip" | "limit" | "setLimit" | "total">
>;
type MaterialTableOptionalProps = Partial<
  Pick<ITableProps<any>, "columns" | "options" | "customActions">
>;
type MaterialProps = IBaseProps &
  MaterialTableRequiredProps &
  MaterialTableOptionalProps & {
    variant?: "material";
  };

type TLyraProductsOverviewTableRequiredProps = Required<
  Pick<TableProps<any>, "data" | "start" | "setStart" | "limit" | "setLimit" | "total">
>;
type TLyraProductsOverviewTableOptionalProps = Partial<
  Pick<TableProps<any>, "columns" | "aria-label">
>;
type LyraProps = IBaseProps &
  TLyraProductsOverviewTableRequiredProps &
  TLyraProductsOverviewTableOptionalProps & { variant: "lyra" };

export function ProductsOverviewTable({
  placeholderImageUrl,
  ...props
}: MaterialProps | LyraProps) {
  const intl = useIntl();

  const defaultTableColumns = useProductsListColumns(placeholderImageUrl);

  if (props.variant === "lyra") {
    return (
      <LyraTable
        data={props.data}
        start={props.start}
        limit={props.limit}
        total={props.total ?? 0}
        setLimit={props.setLimit}
        setStart={props.setStart}
        columns={props.columns ?? (defaultTableColumns.lyra as TableColumnDef<any>[])}
        aria-label={
          props["aria-label"] ??
          intl.formatMessage(
            {
              id: "generic.label.filtered-products-count",
              defaultMessage: "Filtered products ({count})",
            },
            { count: props.total },
          )
        }
      />
    );
  }

  return (
    <Table
      data={props.data}
      limit={props.limit}
      setLimit={props.setLimit}
      skip={props.skip}
      setSkip={props.setSkip}
      total={props.total ?? 0}
      columns={props.columns ?? (defaultTableColumns.material as Column<any>[])}
      options={{
        ...props.options,
        title:
          props.options?.title ??
          intl.formatMessage(
            {
              id: "generic.label.filtered-products-count",
              defaultMessage: "Filtered products ({count})",
            },
            { count: props.total },
          ),
        hideHeader: props.options?.hideHeader,
      }}
      customActions={props.customActions}
    />
  );
}
