import { z } from "zod";
import { zfd } from "zod-form-data";

import { rangeSchema, valuesSchema } from "~/components/suite-composite/product-filter-modal";

const filterOptionsSchema = z.object({
  Negation: z.boolean().optional(),
  ExactMatch: z.boolean().optional(),
  IncludeMissing: z.boolean().optional(),
});

const filtersSchema = filterOptionsSchema.and(z.union([valuesSchema, rangeSchema]));

export const loyaltyProgramProductLimitationDataSchema = (requiredFields?: {
  ProductIDs?: boolean;
  ProductSearchID?: boolean;
  Filters?: boolean;
}) =>
  z.object({
    ProductIDs: requiredFields?.ProductIDs
      ? zfd.json(z.array(z.number()))
      : zfd.json(z.array(z.number())).optional(),
    ProductSearchID: requiredFields?.ProductSearchID
      ? zfd.numeric(z.number())
      : zfd.numeric(z.number().optional()),
    Filters: requiredFields?.Filters ? zfd.json(filtersSchema) : zfd.json(filtersSchema).optional(),
  });

export const loyaltyProgramProductLimitationDataKeys =
  loyaltyProgramProductLimitationDataSchema().keyof().Values;

export type LoyaltyProgramProductLimitationDataForm = z.infer<
  ReturnType<typeof loyaltyProgramProductLimitationDataSchema>
>;

export type LoyaltyProgramProductLimitationDataFormErrors = Partial<
  Record<keyof LoyaltyProgramProductLimitationDataForm, string>
>;

export const loyaltyProgramProductLimitationQuantitiesSchema = z.object({
  OverallQuantityLimitPerOrder: zfd.numeric(z.number().optional()),
  OverallQuantityLimitPerUser: zfd.numeric(z.number().optional()),
  QuantityLimitPerOrder: zfd.numeric(z.number().optional()),
  QuantityLimitPerUser: zfd.numeric(z.number().optional()),
});

export const loyaltyProgramProductLimitationQuantitiesKeys =
  loyaltyProgramProductLimitationQuantitiesSchema.keyof().Values;
