import { useMemo } from "react";

import { useQuery } from "@tanstack/react-query";
import { uniq } from "lodash";

import { listOrganizationUnitsDetailedQuery } from "~/models/organization-units";

export const useOrganizationUnitDetailsById = (id?: number | number[]) => {
  const { data, isFetching: isLoading } = useQuery({
    ...listOrganizationUnitsDetailedQuery(
      (Array.isArray(id) && id.length > 0) || (!Array.isArray(id) && id)
        ? {
            PageConfig: {
              Limit: Array.isArray(id) ? id.length : 1,
              Filter: {
                IDs: uniq(Array.isArray(id) ? id : [id]),
              },
            },
          }
        : undefined,
    ),
    enabled: !!id,
    refetchOnWindowFocus: false,
  });
  const organizationUnit = useMemo(() => {
    if (!data || data?.Result?.Total !== 1) {
      return undefined;
    }
    const ouList = data?.Result?.Page;
    if (ouList.length !== 1) {
      return undefined;
    }
    const ou = ouList[0];
    return {
      ID: ou.ID,
      Name: ou.Name,
      BackendID: ou.BackendID,
    };
  }, [data]);

  const organizationUnits = useMemo(() => {
    if (!data || !Array.isArray(id) || data?.Result?.Total !== id.length) {
      return undefined;
    }
    const ouList = data?.Result?.Page;
    if (ouList.length !== id.length) {
      return undefined;
    }
    return ouList.map((ou) => ({
      ID: ou.ID,
      Name: ou.Name,
      BackendID: ou.BackendID,
    }));
  }, [data, id]);

  return { item: organizationUnit, items: organizationUnits, isLoading };
};
