import { CoreManagement } from "@springtree/eva-services-core-management";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listProductSearchTemplatesLoaderQuery,
  serviceQuery: listProductSearchTemplatesQuery,
  serviceQueryKeys: listProductSearchTemplatesQueryKeys,
  useServiceQueryHook: useListProductSearchTemplatesQuery,
  useServiceQueryWithRecoilRequest: useListProductSearchTemplatesQueryWithRecoilRequest,
  useServiceQueryWithRequest: useListProductSearchTemplatesQueryWithRequest,
} = createServiceQuery(CoreManagement.ListProductSearchTemplates);

export const {
  serviceLoaderQuery: getProductSearchTemplateByIDLoaderQuery,
  serviceQuery: getProductSearchTemplateByIDQuery,
  serviceQueryKeys: getProductSearchTemplateByIDQueryKeys,
  useServiceQueryHook: useGetProductSearchTemplateByIDQuery,
  useServiceQueryWithRecoilRequest: useGetProductSearchTemplateByIDQueryWithRecoilRequest,
  useServiceQueryWithRequest: useGetProductSearchTemplateByIDQueryWithRequest,
} = createServiceQuery(CoreManagement.GetProductSearchTemplateByID, true);
