import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listOrderFulfillmentLinesLoaderQuery,
  serviceQuery: listOrderFulfillmentLinesQuery,
  serviceQueryKeys: listOrderFulfillmentLinesQueryKeys,
  useServiceQueryHook: useListOrderFulfillmentLinesQuery,
} = createServiceQuery(Core.ListOrderFulfillmentLines);
