import { useMemo } from "react";
import { useIntl } from "react-intl";

import { TableColumnDef } from "@new-black/lyra";

import { ProductSummary } from "../product-summary";

import { ITableColumn } from "~/components/suite-ui/table/table-types";

export default function useProductsListColumns(placeholderImageUrl?: string) {
  const intl = useIntl();

  const materialColumns = useMemo<ITableColumn<any>[]>(
    () => [
      {
        Header: intl.formatMessage({
          id: "composite.products-overview.list.header.product",
          defaultMessage: "Product",
        }),
        accessor: "primary_image.blob",
        width: "8%",
        Cell: ({ row, value }) => (
          <ProductSummary
            productID={row.original?.product_id}
            customID={row.original?.custom_id}
            backendID={row.original?.backend_id}
            productName={row.original?.display_value}
            productImageBlobID={value}
            enlargeImage
            placeholderImageUrl={placeholderImageUrl}
            imageSize={37}
          />
        ),
      },
    ],
    [intl, placeholderImageUrl],
  );

  const lyraColumns = useMemo<TableColumnDef<any>[]>(
    () => [
      {
        header: intl.formatMessage({
          id: "composite.products-overview.list.header.product",
          defaultMessage: "Product",
        }),
        id: "primary_image",
        accessorKey: "primary_image",
        cell: ({ getValue, row }) => (
          <ProductSummary
            enlargeImage
            imageSize={37}
            customID={row.original?.custom_id}
            productID={row.original?.product_id}
            backendID={row.original?.backend_id}
            productImageBlobID={getValue()?.blob}
            productName={row.original?.display_value}
            placeholderImageUrl={placeholderImageUrl}
          />
        ),
      },
    ],
    [intl, placeholderImageUrl],
  );

  return { lyra: lyraColumns, material: materialColumns };
}
