import { IntlShape } from "react-intl";

import { ESynchronizationManager } from "~/features/synchronization/overview/synchronization-overview.types";

export const getSynchronizationManagerTranslator = (
  intl: IntlShape,
): ((status: ESynchronizationManager) => string | undefined) => {
  const translationMap: Record<ESynchronizationManager, string> = {
    [ESynchronizationManager.All]: intl.formatMessage({
      id: "synchronization.managers.All",
      defaultMessage: "All",
    }),
    [ESynchronizationManager.Roles]: intl.formatMessage({
      id: "synchronization.managers.Roles",
      defaultMessage: "Roles and rights",
    }),
    [ESynchronizationManager.Settings]: intl.formatMessage({
      id: "synchronization.managers.Settings",
      defaultMessage: "Settings",
    }),
    [ESynchronizationManager.Stencil]: intl.formatMessage({
      id: "synchronization.managers.Stencil",
      defaultMessage: "Stencils",
    }),
    [ESynchronizationManager.Cookbook]: intl.formatMessage({
      id: "synchronization.managers.Cookbook",
      defaultMessage: "Cookbook",
    }),
    [ESynchronizationManager.Symphony]: intl.formatMessage({
      id: "synchronization.managers.Symphony",
      defaultMessage: "Order orchestration",
    }),
  };

  return (status) => {
    return translationMap[status];
  };
};
