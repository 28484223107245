import { Core } from "@springtree/eva-services-core";

import { createServiceQuery } from "~/util/query";

export const {
  serviceLoaderQuery: listCountriesLoaderQuery,
  serviceQuery: listCountriesQuery,
  serviceQueryKeys: listCountriesKeys,
  useServiceQueryHook: useListCountriesQuery,
  useServiceQueryWithRequest: useListCountriesQueryWithRequest,
} = createServiceQuery(Core.ListCountries, true);

export const {
  serviceLoaderQuery: getCountrySubdivisionsLoaderQuery,
  serviceQuery: getCountrySubdivisionsQuery,
  serviceQueryKeys: getCountrySubdivisionsKeys,
  useServiceQueryHook: useGetCountrySubdivisionsQuery,
  useServiceQueryWithRequest: useGetCountrySubdivisionsQueryWithRequest,
} = createServiceQuery(Core.GetCountrySubdivisions, true);
